
export const forum_topic_data = [
    {
        "id": "ICT1011_1",
        "name": "ICT1011 Week 1",
        "module_id": "ICT1011"
    },
    {
        "id": "ICT1011_2",
        "name": "ICT1011 Week 2",
        "module_id": "ICT1011"
    },
    {
        "id": "ICT1011_3",
        "name": "ICT1011 Week 3",
        "module_id": "ICT1011"
    },
    {
        "id": "ICT1011_4",
        "name": "ICT1011 Week 4",
        "module_id": "ICT1011"
    },
    {
        "id": "ICT1011_5",
        "name": "ICT1011 Week 5",
        "module_id": "ICT1011"
    },
    {
        "id": "ICT1011_6",
        "name": "ICT1011 Week 6",
        "module_id": "ICT1011"
    },
    {
        "id": "ICT1011_7",
        "name": "ICT1011 Week 7",
        "module_id": "ICT1011"
    },
    {
        "id": "ICT1011_8",
        "name": "ICT1011 Week 8",
        "module_id": "ICT1011"
    },
    {
        "id": "ICT1011_9",
        "name": "ICT1011 Week 9",
        "module_id": "ICT1011"
    },
    {
        "id": "ICT1011_10",
        "name": "ICT1011 Week 10",
        "module_id": "ICT1011"
    },
    {
        "id": "ICT1011_11",
        "name": "ICT1011 Week 11",
        "module_id": "ICT1011"
    },
    {
        "id": "ICT1011_12",
        "name": "ICT1011 Week 12",
        "module_id": "ICT1011"
    },
    {
        "id": "ICT1011_13",
        "name": "ICT1011 Week 13",
        "module_id": "ICT1011"
    },
    {
        "id": "INF1001_1",
        "name": "INF1001 Week 1",
        "module_id": "INF1001"
    },
    {
        "id": "INF1001_2",
        "name": "INF1001 Week 2",
        "module_id": "INF1001"
    },
    {
        "id": "INF1001_3",
        "name": "INF1001 Week 3",
        "module_id": "INF1001"
    },
    {
        "id": "INF1001_4",
        "name": "INF1001 Week 4",
        "module_id": "INF1001"
    },
    {
        "id": "INF1001_5",
        "name": "INF1001 Week 5",
        "module_id": "INF1001"
    },
    {
        "id": "INF1001_6",
        "name": "INF1001 Week 6",
        "module_id": "INF1001"
    },
    {
        "id": "INF1001_7",
        "name": "INF1001 Week 7",
        "module_id": "INF1001"
    },
    {
        "id": "INF1001_8",
        "name": "INF1001 Week 8",
        "module_id": "INF1001"
    },
    {
        "id": "INF1001_9",
        "name": "INF1001 Week 9",
        "module_id": "INF1001"
    },
    {
        "id": "INF1001_10",
        "name": "INF1001 Week 10",
        "module_id": "INF1001"
    },
    {
        "id": "INF1001_11",
        "name": "INF1001 Week 11",
        "module_id": "INF1001"
    },
    {
        "id": "INF1001_12",
        "name": "INF1001 Week 12",
        "module_id": "INF1001"
    },
    {
        "id": "INF1001_13",
        "name": "INF1001 Week 13",
        "module_id": "INF1001"
    },
    {
        "id": "INF1002_1",
        "name": "INF1002 Week 1",
        "module_id": "INF1002"
    },
    {
        "id": "INF1002_2",
        "name": "INF1002 Week 2",
        "module_id": "INF1002"
    },
    {
        "id": "INF1002_3",
        "name": "INF1002 Week 3",
        "module_id": "INF1002"
    },
    {
        "id": "INF1002_4",
        "name": "INF1002 Week 4",
        "module_id": "INF1002"
    },
    {
        "id": "INF1002_5",
        "name": "INF1002 Week 5",
        "module_id": "INF1002"
    },
    {
        "id": "INF1002_6",
        "name": "INF1002 Week 6",
        "module_id": "INF1002"
    },
    {
        "id": "INF1002_7",
        "name": "INF1002 Week 7",
        "module_id": "INF1002"
    },
    {
        "id": "INF1002_8",
        "name": "INF1002 Week 8",
        "module_id": "INF1002"
    },
    {
        "id": "INF1002_9",
        "name": "INF1002 Week 9",
        "module_id": "INF1002"
    },
    {
        "id": "INF1002_10",
        "name": "INF1002 Week 10",
        "module_id": "INF1002"
    },
    {
        "id": "INF1002_11",
        "name": "INF1002 Week 11",
        "module_id": "INF1002"
    },
    {
        "id": "INF1002_12",
        "name": "INF1002 Week 12",
        "module_id": "INF1002"
    },
    {
        "id": "INF1002_13",
        "name": "INF1002 Week 13",
        "module_id": "INF1002"
    },
    {
        "id": "INF1003_1",
        "name": "INF1003 Week 1",
        "module_id": "INF1003"
    },
    {
        "id": "INF1003_2",
        "name": "INF1003 Week 2",
        "module_id": "INF1003"
    },
    {
        "id": "INF1003_3",
        "name": "INF1003 Week 3",
        "module_id": "INF1003"
    },
    {
        "id": "INF1003_4",
        "name": "INF1003 Week 4",
        "module_id": "INF1003"
    },
    {
        "id": "INF1003_5",
        "name": "INF1003 Week 5",
        "module_id": "INF1003"
    },
    {
        "id": "INF1003_6",
        "name": "INF1003 Week 6",
        "module_id": "INF1003"
    },
    {
        "id": "INF1003_7",
        "name": "INF1003 Week 7",
        "module_id": "INF1003"
    },
    {
        "id": "INF1003_8",
        "name": "INF1003 Week 8",
        "module_id": "INF1003"
    },
    {
        "id": "INF1003_9",
        "name": "INF1003 Week 9",
        "module_id": "INF1003"
    },
    {
        "id": "INF1003_10",
        "name": "INF1003 Week 10",
        "module_id": "INF1003"
    },
    {
        "id": "INF1003_11",
        "name": "INF1003 Week 11",
        "module_id": "INF1003"
    },
    {
        "id": "INF1003_12",
        "name": "INF1003 Week 12",
        "module_id": "INF1003"
    },
    {
        "id": "INF1003_13",
        "name": "INF1003 Week 13",
        "module_id": "INF1003"
    },
    {
        "id": "UDC1001_1",
        "name": "UDC1001 Week 1",
        "module_id": "UDC1001"
    },
    {
        "id": "UDC1001_2",
        "name": "UDC1001 Week 2",
        "module_id": "UDC1001"
    },
    {
        "id": "UDC1001_3",
        "name": "UDC1001 Week 3",
        "module_id": "UDC1001"
    },
    {
        "id": "UDC1001_4",
        "name": "UDC1001 Week 4",
        "module_id": "UDC1001"
    },
    {
        "id": "UDC1001_5",
        "name": "UDC1001 Week 5",
        "module_id": "UDC1001"
    },
    {
        "id": "UDC1001_6",
        "name": "UDC1001 Week 6",
        "module_id": "UDC1001"
    },
    {
        "id": "UDC1001_7",
        "name": "UDC1001 Week 7",
        "module_id": "UDC1001"
    },
    {
        "id": "UDC1001_8",
        "name": "UDC1001 Week 8",
        "module_id": "UDC1001"
    },
    {
        "id": "UDC1001_9",
        "name": "UDC1001 Week 9",
        "module_id": "UDC1001"
    },
    {
        "id": "UDC1001_10",
        "name": "UDC1001 Week 10",
        "module_id": "UDC1001"
    },
    {
        "id": "UDC1001_11",
        "name": "UDC1001 Week 11",
        "module_id": "UDC1001"
    },
    {
        "id": "UDC1001_12",
        "name": "UDC1001 Week 12",
        "module_id": "UDC1001"
    },
    {
        "id": "UDC1001_13",
        "name": "UDC1001 Week 13",
        "module_id": "UDC1001"
    },
    {
        "id": "ICT4012A_1",
        "name": "ICT4012A Week 1",
        "module_id": "ICT4012A"
    },
    {
        "id": "ICT4012A_2",
        "name": "ICT4012A Week 2",
        "module_id": "ICT4012A"
    },
    {
        "id": "ICT4012A_3",
        "name": "ICT4012A Week 3",
        "module_id": "ICT4012A"
    },
    {
        "id": "ICT4012A_4",
        "name": "ICT4012A Week 4",
        "module_id": "ICT4012A"
    },
    {
        "id": "ICT4012A_5",
        "name": "ICT4012A Week 5",
        "module_id": "ICT4012A"
    },
    {
        "id": "ICT4012A_6",
        "name": "ICT4012A Week 6",
        "module_id": "ICT4012A"
    },
    {
        "id": "ICT4012A_7",
        "name": "ICT4012A Week 7",
        "module_id": "ICT4012A"
    },
    {
        "id": "ICT4012A_8",
        "name": "ICT4012A Week 8",
        "module_id": "ICT4012A"
    },
    {
        "id": "ICT4012A_9",
        "name": "ICT4012A Week 9",
        "module_id": "ICT4012A"
    },
    {
        "id": "ICT4012A_10",
        "name": "ICT4012A Week 10",
        "module_id": "ICT4012A"
    },
    {
        "id": "ICT4012A_11",
        "name": "ICT4012A Week 11",
        "module_id": "ICT4012A"
    },
    {
        "id": "ICT4012A_12",
        "name": "ICT4012A Week 12",
        "module_id": "ICT4012A"
    },
    {
        "id": "ICT4012A_13",
        "name": "ICT4012A Week 13",
        "module_id": "ICT4012A"
    },
    {
        "id": "ICT1012_1",
        "name": "ICT1012 Week 1",
        "module_id": "ICT1012"
    },
    {
        "id": "ICT1012_2",
        "name": "ICT1012 Week 2",
        "module_id": "ICT1012"
    },
    {
        "id": "ICT1012_3",
        "name": "ICT1012 Week 3",
        "module_id": "ICT1012"
    },
    {
        "id": "ICT1012_4",
        "name": "ICT1012 Week 4",
        "module_id": "ICT1012"
    },
    {
        "id": "ICT1012_5",
        "name": "ICT1012 Week 5",
        "module_id": "ICT1012"
    },
    {
        "id": "ICT1012_6",
        "name": "ICT1012 Week 6",
        "module_id": "ICT1012"
    },
    {
        "id": "ICT1012_7",
        "name": "ICT1012 Week 7",
        "module_id": "ICT1012"
    },
    {
        "id": "ICT1012_8",
        "name": "ICT1012 Week 8",
        "module_id": "ICT1012"
    },
    {
        "id": "ICT1012_9",
        "name": "ICT1012 Week 9",
        "module_id": "ICT1012"
    },
    {
        "id": "ICT1012_10",
        "name": "ICT1012 Week 10",
        "module_id": "ICT1012"
    },
    {
        "id": "ICT1012_11",
        "name": "ICT1012 Week 11",
        "module_id": "ICT1012"
    },
    {
        "id": "ICT1012_12",
        "name": "ICT1012 Week 12",
        "module_id": "ICT1012"
    },
    {
        "id": "ICT1012_13",
        "name": "ICT1012 Week 13",
        "module_id": "ICT1012"
    },
    {
        "id": "INF1004_1",
        "name": "INF1004 Week 1",
        "module_id": "INF1004"
    },
    {
        "id": "INF1004_2",
        "name": "INF1004 Week 2",
        "module_id": "INF1004"
    },
    {
        "id": "INF1004_3",
        "name": "INF1004 Week 3",
        "module_id": "INF1004"
    },
    {
        "id": "INF1004_4",
        "name": "INF1004 Week 4",
        "module_id": "INF1004"
    },
    {
        "id": "INF1004_5",
        "name": "INF1004 Week 5",
        "module_id": "INF1004"
    },
    {
        "id": "INF1004_6",
        "name": "INF1004 Week 6",
        "module_id": "INF1004"
    },
    {
        "id": "INF1004_7",
        "name": "INF1004 Week 7",
        "module_id": "INF1004"
    },
    {
        "id": "INF1004_8",
        "name": "INF1004 Week 8",
        "module_id": "INF1004"
    },
    {
        "id": "INF1004_9",
        "name": "INF1004 Week 9",
        "module_id": "INF1004"
    },
    {
        "id": "INF1004_10",
        "name": "INF1004 Week 10",
        "module_id": "INF1004"
    },
    {
        "id": "INF1004_11",
        "name": "INF1004 Week 11",
        "module_id": "INF1004"
    },
    {
        "id": "INF1004_12",
        "name": "INF1004 Week 12",
        "module_id": "INF1004"
    },
    {
        "id": "INF1004_13",
        "name": "INF1004 Week 13",
        "module_id": "INF1004"
    },
    {
        "id": "INF1005_1",
        "name": "INF1005 Week 1",
        "module_id": "INF1005"
    },
    {
        "id": "INF1005_2",
        "name": "INF1005 Week 2",
        "module_id": "INF1005"
    },
    {
        "id": "INF1005_3",
        "name": "INF1005 Week 3",
        "module_id": "INF1005"
    },
    {
        "id": "INF1005_4",
        "name": "INF1005 Week 4",
        "module_id": "INF1005"
    },
    {
        "id": "INF1005_5",
        "name": "INF1005 Week 5",
        "module_id": "INF1005"
    },
    {
        "id": "INF1005_6",
        "name": "INF1005 Week 6",
        "module_id": "INF1005"
    },
    {
        "id": "INF1005_7",
        "name": "INF1005 Week 7",
        "module_id": "INF1005"
    },
    {
        "id": "INF1005_8",
        "name": "INF1005 Week 8",
        "module_id": "INF1005"
    },
    {
        "id": "INF1005_9",
        "name": "INF1005 Week 9",
        "module_id": "INF1005"
    },
    {
        "id": "INF1005_10",
        "name": "INF1005 Week 10",
        "module_id": "INF1005"
    },
    {
        "id": "INF1005_11",
        "name": "INF1005 Week 11",
        "module_id": "INF1005"
    },
    {
        "id": "INF1005_12",
        "name": "INF1005 Week 12",
        "module_id": "INF1005"
    },
    {
        "id": "INF1005_13",
        "name": "INF1005 Week 13",
        "module_id": "INF1005"
    },
    {
        "id": "INF1009_1",
        "name": "INF1009 Week 1",
        "module_id": "INF1009"
    },
    {
        "id": "INF1009_2",
        "name": "INF1009 Week 2",
        "module_id": "INF1009"
    },
    {
        "id": "INF1009_3",
        "name": "INF1009 Week 3",
        "module_id": "INF1009"
    },
    {
        "id": "INF1009_4",
        "name": "INF1009 Week 4",
        "module_id": "INF1009"
    },
    {
        "id": "INF1009_5",
        "name": "INF1009 Week 5",
        "module_id": "INF1009"
    },
    {
        "id": "INF1009_6",
        "name": "INF1009 Week 6",
        "module_id": "INF1009"
    },
    {
        "id": "INF1009_7",
        "name": "INF1009 Week 7",
        "module_id": "INF1009"
    },
    {
        "id": "INF1009_8",
        "name": "INF1009 Week 8",
        "module_id": "INF1009"
    },
    {
        "id": "INF1009_9",
        "name": "INF1009 Week 9",
        "module_id": "INF1009"
    },
    {
        "id": "INF1009_10",
        "name": "INF1009 Week 10",
        "module_id": "INF1009"
    },
    {
        "id": "INF1009_11",
        "name": "INF1009 Week 11",
        "module_id": "INF1009"
    },
    {
        "id": "INF1009_12",
        "name": "INF1009 Week 12",
        "module_id": "INF1009"
    },
    {
        "id": "INF1009_13",
        "name": "INF1009 Week 13",
        "module_id": "INF1009"
    },
    {
        "id": "UCS1001_1",
        "name": "UCS1001 Week 1",
        "module_id": "UCS1001"
    },
    {
        "id": "UCS1001_2",
        "name": "UCS1001 Week 2",
        "module_id": "UCS1001"
    },
    {
        "id": "UCS1001_3",
        "name": "UCS1001 Week 3",
        "module_id": "UCS1001"
    },
    {
        "id": "UCS1001_4",
        "name": "UCS1001 Week 4",
        "module_id": "UCS1001"
    },
    {
        "id": "UCS1001_5",
        "name": "UCS1001 Week 5",
        "module_id": "UCS1001"
    },
    {
        "id": "UCS1001_6",
        "name": "UCS1001 Week 6",
        "module_id": "UCS1001"
    },
    {
        "id": "UCS1001_7",
        "name": "UCS1001 Week 7",
        "module_id": "UCS1001"
    },
    {
        "id": "UCS1001_8",
        "name": "UCS1001 Week 8",
        "module_id": "UCS1001"
    },
    {
        "id": "UCS1001_9",
        "name": "UCS1001 Week 9",
        "module_id": "UCS1001"
    },
    {
        "id": "UCS1001_10",
        "name": "UCS1001 Week 10",
        "module_id": "UCS1001"
    },
    {
        "id": "UCS1001_11",
        "name": "UCS1001 Week 11",
        "module_id": "UCS1001"
    },
    {
        "id": "UCS1001_12",
        "name": "UCS1001 Week 12",
        "module_id": "UCS1001"
    },
    {
        "id": "UCS1001_13",
        "name": "UCS1001 Week 13",
        "module_id": "UCS1001"
    },
    {
        "id": "INF1006_1",
        "name": "INF1006 Week 1",
        "module_id": "INF1006"
    },
    {
        "id": "INF1006_2",
        "name": "INF1006 Week 2",
        "module_id": "INF1006"
    },
    {
        "id": "INF1006_3",
        "name": "INF1006 Week 3",
        "module_id": "INF1006"
    },
    {
        "id": "INF1006_4",
        "name": "INF1006 Week 4",
        "module_id": "INF1006"
    },
    {
        "id": "INF1006_5",
        "name": "INF1006 Week 5",
        "module_id": "INF1006"
    },
    {
        "id": "INF1006_6",
        "name": "INF1006 Week 6",
        "module_id": "INF1006"
    },
    {
        "id": "INF1006_7",
        "name": "INF1006 Week 7",
        "module_id": "INF1006"
    },
    {
        "id": "INF1006_8",
        "name": "INF1006 Week 8",
        "module_id": "INF1006"
    },
    {
        "id": "INF1006_9",
        "name": "INF1006 Week 9",
        "module_id": "INF1006"
    },
    {
        "id": "INF1006_10",
        "name": "INF1006 Week 10",
        "module_id": "INF1006"
    },
    {
        "id": "INF1006_11",
        "name": "INF1006 Week 11",
        "module_id": "INF1006"
    },
    {
        "id": "INF1006_12",
        "name": "INF1006 Week 12",
        "module_id": "INF1006"
    },
    {
        "id": "INF1006_13",
        "name": "INF1006 Week 13",
        "module_id": "INF1006"
    },
    {
        "id": "INF1007_1",
        "name": "INF1007 Week 1",
        "module_id": "INF1007"
    },
    {
        "id": "INF1007_2",
        "name": "INF1007 Week 2",
        "module_id": "INF1007"
    },
    {
        "id": "INF1007_3",
        "name": "INF1007 Week 3",
        "module_id": "INF1007"
    },
    {
        "id": "INF1007_4",
        "name": "INF1007 Week 4",
        "module_id": "INF1007"
    },
    {
        "id": "INF1007_5",
        "name": "INF1007 Week 5",
        "module_id": "INF1007"
    },
    {
        "id": "INF1007_6",
        "name": "INF1007 Week 6",
        "module_id": "INF1007"
    },
    {
        "id": "INF1007_7",
        "name": "INF1007 Week 7",
        "module_id": "INF1007"
    },
    {
        "id": "INF1007_8",
        "name": "INF1007 Week 8",
        "module_id": "INF1007"
    },
    {
        "id": "INF1007_9",
        "name": "INF1007 Week 9",
        "module_id": "INF1007"
    },
    {
        "id": "INF1007_10",
        "name": "INF1007 Week 10",
        "module_id": "INF1007"
    },
    {
        "id": "INF1007_11",
        "name": "INF1007 Week 11",
        "module_id": "INF1007"
    },
    {
        "id": "INF1007_12",
        "name": "INF1007 Week 12",
        "module_id": "INF1007"
    },
    {
        "id": "INF1007_13",
        "name": "INF1007 Week 13",
        "module_id": "INF1007"
    },
    {
        "id": "INF1008_1",
        "name": "INF1008 Week 1",
        "module_id": "INF1008"
    },
    {
        "id": "INF1008_2",
        "name": "INF1008 Week 2",
        "module_id": "INF1008"
    },
    {
        "id": "INF1008_3",
        "name": "INF1008 Week 3",
        "module_id": "INF1008"
    },
    {
        "id": "INF1008_4",
        "name": "INF1008 Week 4",
        "module_id": "INF1008"
    },
    {
        "id": "INF1008_5",
        "name": "INF1008 Week 5",
        "module_id": "INF1008"
    },
    {
        "id": "INF1008_6",
        "name": "INF1008 Week 6",
        "module_id": "INF1008"
    },
    {
        "id": "INF1008_7",
        "name": "INF1008 Week 7",
        "module_id": "INF1008"
    },
    {
        "id": "INF1008_8",
        "name": "INF1008 Week 8",
        "module_id": "INF1008"
    },
    {
        "id": "INF1008_9",
        "name": "INF1008 Week 9",
        "module_id": "INF1008"
    },
    {
        "id": "INF1008_10",
        "name": "INF1008 Week 10",
        "module_id": "INF1008"
    },
    {
        "id": "INF1008_11",
        "name": "INF1008 Week 11",
        "module_id": "INF1008"
    },
    {
        "id": "INF1008_12",
        "name": "INF1008 Week 12",
        "module_id": "INF1008"
    },
    {
        "id": "INF1008_13",
        "name": "INF1008 Week 13",
        "module_id": "INF1008"
    },
    {
        "id": "UDE1001_1",
        "name": "UDE1001 Week 1",
        "module_id": "UDE1001"
    },
    {
        "id": "UDE1001_2",
        "name": "UDE1001 Week 2",
        "module_id": "UDE1001"
    },
    {
        "id": "UDE1001_3",
        "name": "UDE1001 Week 3",
        "module_id": "UDE1001"
    },
    {
        "id": "UDE1001_4",
        "name": "UDE1001 Week 4",
        "module_id": "UDE1001"
    },
    {
        "id": "UDE1001_5",
        "name": "UDE1001 Week 5",
        "module_id": "UDE1001"
    },
    {
        "id": "UDE1001_6",
        "name": "UDE1001 Week 6",
        "module_id": "UDE1001"
    },
    {
        "id": "UDE1001_7",
        "name": "UDE1001 Week 7",
        "module_id": "UDE1001"
    },
    {
        "id": "UDE1001_8",
        "name": "UDE1001 Week 8",
        "module_id": "UDE1001"
    },
    {
        "id": "UDE1001_9",
        "name": "UDE1001 Week 9",
        "module_id": "UDE1001"
    },
    {
        "id": "UDE1001_10",
        "name": "UDE1001 Week 10",
        "module_id": "UDE1001"
    },
    {
        "id": "UDE1001_11",
        "name": "UDE1001 Week 11",
        "module_id": "UDE1001"
    },
    {
        "id": "UDE1001_12",
        "name": "UDE1001 Week 12",
        "module_id": "UDE1001"
    },
    {
        "id": "UDE1001_13",
        "name": "UDE1001 Week 13",
        "module_id": "UDE1001"
    },
    {
        "id": "INF2001_1",
        "name": "INF2001 Week 1",
        "module_id": "INF2001"
    },
    {
        "id": "INF2001_2",
        "name": "INF2001 Week 2",
        "module_id": "INF2001"
    },
    {
        "id": "INF2001_3",
        "name": "INF2001 Week 3",
        "module_id": "INF2001"
    },
    {
        "id": "INF2001_4",
        "name": "INF2001 Week 4",
        "module_id": "INF2001"
    },
    {
        "id": "INF2001_5",
        "name": "INF2001 Week 5",
        "module_id": "INF2001"
    },
    {
        "id": "INF2001_6",
        "name": "INF2001 Week 6",
        "module_id": "INF2001"
    },
    {
        "id": "INF2001_7",
        "name": "INF2001 Week 7",
        "module_id": "INF2001"
    },
    {
        "id": "INF2001_8",
        "name": "INF2001 Week 8",
        "module_id": "INF2001"
    },
    {
        "id": "INF2001_9",
        "name": "INF2001 Week 9",
        "module_id": "INF2001"
    },
    {
        "id": "INF2001_10",
        "name": "INF2001 Week 10",
        "module_id": "INF2001"
    },
    {
        "id": "INF2001_11",
        "name": "INF2001 Week 11",
        "module_id": "INF2001"
    },
    {
        "id": "INF2001_12",
        "name": "INF2001 Week 12",
        "module_id": "INF2001"
    },
    {
        "id": "INF2001_13",
        "name": "INF2001 Week 13",
        "module_id": "INF2001"
    },
    {
        "id": "INF2002_1",
        "name": "INF2002 Week 1",
        "module_id": "INF2002"
    },
    {
        "id": "INF2002_2",
        "name": "INF2002 Week 2",
        "module_id": "INF2002"
    },
    {
        "id": "INF2002_3",
        "name": "INF2002 Week 3",
        "module_id": "INF2002"
    },
    {
        "id": "INF2002_4",
        "name": "INF2002 Week 4",
        "module_id": "INF2002"
    },
    {
        "id": "INF2002_5",
        "name": "INF2002 Week 5",
        "module_id": "INF2002"
    },
    {
        "id": "INF2002_6",
        "name": "INF2002 Week 6",
        "module_id": "INF2002"
    },
    {
        "id": "INF2002_7",
        "name": "INF2002 Week 7",
        "module_id": "INF2002"
    },
    {
        "id": "INF2002_8",
        "name": "INF2002 Week 8",
        "module_id": "INF2002"
    },
    {
        "id": "INF2002_9",
        "name": "INF2002 Week 9",
        "module_id": "INF2002"
    },
    {
        "id": "INF2002_10",
        "name": "INF2002 Week 10",
        "module_id": "INF2002"
    },
    {
        "id": "INF2002_11",
        "name": "INF2002 Week 11",
        "module_id": "INF2002"
    },
    {
        "id": "INF2002_12",
        "name": "INF2002 Week 12",
        "module_id": "INF2002"
    },
    {
        "id": "INF2002_13",
        "name": "INF2002 Week 13",
        "module_id": "INF2002"
    },
    {
        "id": "INF2003_1",
        "name": "INF2003 Week 1",
        "module_id": "INF2003"
    },
    {
        "id": "INF2003_2",
        "name": "INF2003 Week 2",
        "module_id": "INF2003"
    },
    {
        "id": "INF2003_3",
        "name": "INF2003 Week 3",
        "module_id": "INF2003"
    },
    {
        "id": "INF2003_4",
        "name": "INF2003 Week 4",
        "module_id": "INF2003"
    },
    {
        "id": "INF2003_5",
        "name": "INF2003 Week 5",
        "module_id": "INF2003"
    },
    {
        "id": "INF2003_6",
        "name": "INF2003 Week 6",
        "module_id": "INF2003"
    },
    {
        "id": "INF2003_7",
        "name": "INF2003 Week 7",
        "module_id": "INF2003"
    },
    {
        "id": "INF2003_8",
        "name": "INF2003 Week 8",
        "module_id": "INF2003"
    },
    {
        "id": "INF2003_9",
        "name": "INF2003 Week 9",
        "module_id": "INF2003"
    },
    {
        "id": "INF2003_10",
        "name": "INF2003 Week 10",
        "module_id": "INF2003"
    },
    {
        "id": "INF2003_11",
        "name": "INF2003 Week 11",
        "module_id": "INF2003"
    },
    {
        "id": "INF2003_12",
        "name": "INF2003 Week 12",
        "module_id": "INF2003"
    },
    {
        "id": "INF2003_13",
        "name": "INF2003 Week 13",
        "module_id": "INF2003"
    },
    {
        "id": "INF2004_1",
        "name": "INF2004 Week 1",
        "module_id": "INF2004"
    },
    {
        "id": "INF2004_2",
        "name": "INF2004 Week 2",
        "module_id": "INF2004"
    },
    {
        "id": "INF2004_3",
        "name": "INF2004 Week 3",
        "module_id": "INF2004"
    },
    {
        "id": "INF2004_4",
        "name": "INF2004 Week 4",
        "module_id": "INF2004"
    },
    {
        "id": "INF2004_5",
        "name": "INF2004 Week 5",
        "module_id": "INF2004"
    },
    {
        "id": "INF2004_6",
        "name": "INF2004 Week 6",
        "module_id": "INF2004"
    },
    {
        "id": "INF2004_7",
        "name": "INF2004 Week 7",
        "module_id": "INF2004"
    },
    {
        "id": "INF2004_8",
        "name": "INF2004 Week 8",
        "module_id": "INF2004"
    },
    {
        "id": "INF2004_9",
        "name": "INF2004 Week 9",
        "module_id": "INF2004"
    },
    {
        "id": "INF2004_10",
        "name": "INF2004 Week 10",
        "module_id": "INF2004"
    },
    {
        "id": "INF2004_11",
        "name": "INF2004 Week 11",
        "module_id": "INF2004"
    },
    {
        "id": "INF2004_12",
        "name": "INF2004 Week 12",
        "module_id": "INF2004"
    },
    {
        "id": "INF2004_13",
        "name": "INF2004 Week 13",
        "module_id": "INF2004"
    },
    {
        "id": "UDE2001_1",
        "name": "UDE2001 Week 1",
        "module_id": "UDE2001"
    },
    {
        "id": "UDE2001_2",
        "name": "UDE2001 Week 2",
        "module_id": "UDE2001"
    },
    {
        "id": "UDE2001_3",
        "name": "UDE2001 Week 3",
        "module_id": "UDE2001"
    },
    {
        "id": "UDE2001_4",
        "name": "UDE2001 Week 4",
        "module_id": "UDE2001"
    },
    {
        "id": "UDE2001_5",
        "name": "UDE2001 Week 5",
        "module_id": "UDE2001"
    },
    {
        "id": "UDE2001_6",
        "name": "UDE2001 Week 6",
        "module_id": "UDE2001"
    },
    {
        "id": "UDE2001_7",
        "name": "UDE2001 Week 7",
        "module_id": "UDE2001"
    },
    {
        "id": "UDE2001_8",
        "name": "UDE2001 Week 8",
        "module_id": "UDE2001"
    },
    {
        "id": "UDE2001_9",
        "name": "UDE2001 Week 9",
        "module_id": "UDE2001"
    },
    {
        "id": "UDE2001_10",
        "name": "UDE2001 Week 10",
        "module_id": "UDE2001"
    },
    {
        "id": "UDE2001_11",
        "name": "UDE2001 Week 11",
        "module_id": "UDE2001"
    },
    {
        "id": "UDE2001_12",
        "name": "UDE2001 Week 12",
        "module_id": "UDE2001"
    },
    {
        "id": "UDE2001_13",
        "name": "UDE2001 Week 13",
        "module_id": "UDE2001"
    },
    {
        "id": "ICT2112_1",
        "name": "ICT2112 Week 1",
        "module_id": "ICT2112"
    },
    {
        "id": "ICT2112_2",
        "name": "ICT2112 Week 2",
        "module_id": "ICT2112"
    },
    {
        "id": "ICT2112_3",
        "name": "ICT2112 Week 3",
        "module_id": "ICT2112"
    },
    {
        "id": "ICT2112_4",
        "name": "ICT2112 Week 4",
        "module_id": "ICT2112"
    },
    {
        "id": "ICT2112_5",
        "name": "ICT2112 Week 5",
        "module_id": "ICT2112"
    },
    {
        "id": "ICT2112_6",
        "name": "ICT2112 Week 6",
        "module_id": "ICT2112"
    },
    {
        "id": "ICT2112_7",
        "name": "ICT2112 Week 7",
        "module_id": "ICT2112"
    },
    {
        "id": "ICT2112_8",
        "name": "ICT2112 Week 8",
        "module_id": "ICT2112"
    },
    {
        "id": "ICT2112_9",
        "name": "ICT2112 Week 9",
        "module_id": "ICT2112"
    },
    {
        "id": "ICT2112_10",
        "name": "ICT2112 Week 10",
        "module_id": "ICT2112"
    },
    {
        "id": "ICT2112_11",
        "name": "ICT2112 Week 11",
        "module_id": "ICT2112"
    },
    {
        "id": "ICT2112_12",
        "name": "ICT2112 Week 12",
        "module_id": "ICT2112"
    },
    {
        "id": "ICT2112_13",
        "name": "ICT2112 Week 13",
        "module_id": "ICT2112"
    },
    {
        "id": "ICT2113_1",
        "name": "ICT2113 Week 1",
        "module_id": "ICT2113"
    },
    {
        "id": "ICT2113_2",
        "name": "ICT2113 Week 2",
        "module_id": "ICT2113"
    },
    {
        "id": "ICT2113_3",
        "name": "ICT2113 Week 3",
        "module_id": "ICT2113"
    },
    {
        "id": "ICT2113_4",
        "name": "ICT2113 Week 4",
        "module_id": "ICT2113"
    },
    {
        "id": "ICT2113_5",
        "name": "ICT2113 Week 5",
        "module_id": "ICT2113"
    },
    {
        "id": "ICT2113_6",
        "name": "ICT2113 Week 6",
        "module_id": "ICT2113"
    },
    {
        "id": "ICT2113_7",
        "name": "ICT2113 Week 7",
        "module_id": "ICT2113"
    },
    {
        "id": "ICT2113_8",
        "name": "ICT2113 Week 8",
        "module_id": "ICT2113"
    },
    {
        "id": "ICT2113_9",
        "name": "ICT2113 Week 9",
        "module_id": "ICT2113"
    },
    {
        "id": "ICT2113_10",
        "name": "ICT2113 Week 10",
        "module_id": "ICT2113"
    },
    {
        "id": "ICT2113_11",
        "name": "ICT2113 Week 11",
        "module_id": "ICT2113"
    },
    {
        "id": "ICT2113_12",
        "name": "ICT2113 Week 12",
        "module_id": "ICT2113"
    },
    {
        "id": "ICT2113_13",
        "name": "ICT2113 Week 13",
        "module_id": "ICT2113"
    },
    {
        "id": "ICT2215_1",
        "name": "ICT2215 Week 1",
        "module_id": "ICT2215"
    },
    {
        "id": "ICT2215_2",
        "name": "ICT2215 Week 2",
        "module_id": "ICT2215"
    },
    {
        "id": "ICT2215_3",
        "name": "ICT2215 Week 3",
        "module_id": "ICT2215"
    },
    {
        "id": "ICT2215_4",
        "name": "ICT2215 Week 4",
        "module_id": "ICT2215"
    },
    {
        "id": "ICT2215_5",
        "name": "ICT2215 Week 5",
        "module_id": "ICT2215"
    },
    {
        "id": "ICT2215_6",
        "name": "ICT2215 Week 6",
        "module_id": "ICT2215"
    },
    {
        "id": "ICT2215_7",
        "name": "ICT2215 Week 7",
        "module_id": "ICT2215"
    },
    {
        "id": "ICT2215_8",
        "name": "ICT2215 Week 8",
        "module_id": "ICT2215"
    },
    {
        "id": "ICT2215_9",
        "name": "ICT2215 Week 9",
        "module_id": "ICT2215"
    },
    {
        "id": "ICT2215_10",
        "name": "ICT2215 Week 10",
        "module_id": "ICT2215"
    },
    {
        "id": "ICT2215_11",
        "name": "ICT2215 Week 11",
        "module_id": "ICT2215"
    },
    {
        "id": "ICT2215_12",
        "name": "ICT2215 Week 12",
        "module_id": "ICT2215"
    },
    {
        "id": "ICT2215_13",
        "name": "ICT2215 Week 13",
        "module_id": "ICT2215"
    },
    {
        "id": "INF2007_1",
        "name": "INF2007 Week 1",
        "module_id": "INF2007"
    },
    {
        "id": "INF2007_2",
        "name": "INF2007 Week 2",
        "module_id": "INF2007"
    },
    {
        "id": "INF2007_3",
        "name": "INF2007 Week 3",
        "module_id": "INF2007"
    },
    {
        "id": "INF2007_4",
        "name": "INF2007 Week 4",
        "module_id": "INF2007"
    },
    {
        "id": "INF2007_5",
        "name": "INF2007 Week 5",
        "module_id": "INF2007"
    },
    {
        "id": "INF2007_6",
        "name": "INF2007 Week 6",
        "module_id": "INF2007"
    },
    {
        "id": "INF2007_7",
        "name": "INF2007 Week 7",
        "module_id": "INF2007"
    },
    {
        "id": "INF2007_8",
        "name": "INF2007 Week 8",
        "module_id": "INF2007"
    },
    {
        "id": "INF2007_9",
        "name": "INF2007 Week 9",
        "module_id": "INF2007"
    },
    {
        "id": "INF2007_10",
        "name": "INF2007 Week 10",
        "module_id": "INF2007"
    },
    {
        "id": "INF2007_11",
        "name": "INF2007 Week 11",
        "module_id": "INF2007"
    },
    {
        "id": "INF2007_12",
        "name": "INF2007 Week 12",
        "module_id": "INF2007"
    },
    {
        "id": "INF2007_13",
        "name": "INF2007 Week 13",
        "module_id": "INF2007"
    },
    {
        "id": "ICT2114_1",
        "name": "ICT2114 Week 1",
        "module_id": "ICT2114"
    },
    {
        "id": "ICT2114_2",
        "name": "ICT2114 Week 2",
        "module_id": "ICT2114"
    },
    {
        "id": "ICT2114_3",
        "name": "ICT2114 Week 3",
        "module_id": "ICT2114"
    },
    {
        "id": "ICT2114_4",
        "name": "ICT2114 Week 4",
        "module_id": "ICT2114"
    },
    {
        "id": "ICT2114_5",
        "name": "ICT2114 Week 5",
        "module_id": "ICT2114"
    },
    {
        "id": "ICT2114_6",
        "name": "ICT2114 Week 6",
        "module_id": "ICT2114"
    },
    {
        "id": "ICT2114_7",
        "name": "ICT2114 Week 7",
        "module_id": "ICT2114"
    },
    {
        "id": "ICT2114_8",
        "name": "ICT2114 Week 8",
        "module_id": "ICT2114"
    },
    {
        "id": "ICT2114_9",
        "name": "ICT2114 Week 9",
        "module_id": "ICT2114"
    },
    {
        "id": "ICT2114_10",
        "name": "ICT2114 Week 10",
        "module_id": "ICT2114"
    },
    {
        "id": "ICT2114_11",
        "name": "ICT2114 Week 11",
        "module_id": "ICT2114"
    },
    {
        "id": "ICT2114_12",
        "name": "ICT2114 Week 12",
        "module_id": "ICT2114"
    },
    {
        "id": "ICT2114_13",
        "name": "ICT2114 Week 13",
        "module_id": "ICT2114"
    },
    {
        "id": "ICT2216_1",
        "name": "ICT2216 Week 1",
        "module_id": "ICT2216"
    },
    {
        "id": "ICT2216_2",
        "name": "ICT2216 Week 2",
        "module_id": "ICT2216"
    },
    {
        "id": "ICT2216_3",
        "name": "ICT2216 Week 3",
        "module_id": "ICT2216"
    },
    {
        "id": "ICT2216_4",
        "name": "ICT2216 Week 4",
        "module_id": "ICT2216"
    },
    {
        "id": "ICT2216_5",
        "name": "ICT2216 Week 5",
        "module_id": "ICT2216"
    },
    {
        "id": "ICT2216_6",
        "name": "ICT2216 Week 6",
        "module_id": "ICT2216"
    },
    {
        "id": "ICT2216_7",
        "name": "ICT2216 Week 7",
        "module_id": "ICT2216"
    },
    {
        "id": "ICT2216_8",
        "name": "ICT2216 Week 8",
        "module_id": "ICT2216"
    },
    {
        "id": "ICT2216_9",
        "name": "ICT2216 Week 9",
        "module_id": "ICT2216"
    },
    {
        "id": "ICT2216_10",
        "name": "ICT2216 Week 10",
        "module_id": "ICT2216"
    },
    {
        "id": "ICT2216_11",
        "name": "ICT2216 Week 11",
        "module_id": "ICT2216"
    },
    {
        "id": "ICT2216_12",
        "name": "ICT2216 Week 12",
        "module_id": "ICT2216"
    },
    {
        "id": "ICT2216_13",
        "name": "ICT2216 Week 13",
        "module_id": "ICT2216"
    },
    {
        "id": "ICT3219_1",
        "name": "ICT3219 Week 1",
        "module_id": "ICT3219"
    },
    {
        "id": "ICT3219_2",
        "name": "ICT3219 Week 2",
        "module_id": "ICT3219"
    },
    {
        "id": "ICT3219_3",
        "name": "ICT3219 Week 3",
        "module_id": "ICT3219"
    },
    {
        "id": "ICT3219_4",
        "name": "ICT3219 Week 4",
        "module_id": "ICT3219"
    },
    {
        "id": "ICT3219_5",
        "name": "ICT3219 Week 5",
        "module_id": "ICT3219"
    },
    {
        "id": "ICT3219_6",
        "name": "ICT3219 Week 6",
        "module_id": "ICT3219"
    },
    {
        "id": "ICT3219_7",
        "name": "ICT3219 Week 7",
        "module_id": "ICT3219"
    },
    {
        "id": "ICT3219_8",
        "name": "ICT3219 Week 8",
        "module_id": "ICT3219"
    },
    {
        "id": "ICT3219_9",
        "name": "ICT3219 Week 9",
        "module_id": "ICT3219"
    },
    {
        "id": "ICT3219_10",
        "name": "ICT3219 Week 10",
        "module_id": "ICT3219"
    },
    {
        "id": "ICT3219_11",
        "name": "ICT3219 Week 11",
        "module_id": "ICT3219"
    },
    {
        "id": "ICT3219_12",
        "name": "ICT3219 Week 12",
        "module_id": "ICT3219"
    },
    {
        "id": "ICT3219_13",
        "name": "ICT3219 Week 13",
        "module_id": "ICT3219"
    },
    {
        "id": "INF2005_1",
        "name": "INF2005 Week 1",
        "module_id": "INF2005"
    },
    {
        "id": "INF2005_2",
        "name": "INF2005 Week 2",
        "module_id": "INF2005"
    },
    {
        "id": "INF2005_3",
        "name": "INF2005 Week 3",
        "module_id": "INF2005"
    },
    {
        "id": "INF2005_4",
        "name": "INF2005 Week 4",
        "module_id": "INF2005"
    },
    {
        "id": "INF2005_5",
        "name": "INF2005 Week 5",
        "module_id": "INF2005"
    },
    {
        "id": "INF2005_6",
        "name": "INF2005 Week 6",
        "module_id": "INF2005"
    },
    {
        "id": "INF2005_7",
        "name": "INF2005 Week 7",
        "module_id": "INF2005"
    },
    {
        "id": "INF2005_8",
        "name": "INF2005 Week 8",
        "module_id": "INF2005"
    },
    {
        "id": "INF2005_9",
        "name": "INF2005 Week 9",
        "module_id": "INF2005"
    },
    {
        "id": "INF2005_10",
        "name": "INF2005 Week 10",
        "module_id": "INF2005"
    },
    {
        "id": "INF2005_11",
        "name": "INF2005 Week 11",
        "module_id": "INF2005"
    },
    {
        "id": "INF2005_12",
        "name": "INF2005 Week 12",
        "module_id": "INF2005"
    },
    {
        "id": "INF2005_13",
        "name": "INF2005 Week 13",
        "module_id": "INF2005"
    },
    {
        "id": "ICT3112_1",
        "name": "ICT3112 Week 1",
        "module_id": "ICT3112"
    },
    {
        "id": "ICT3112_2",
        "name": "ICT3112 Week 2",
        "module_id": "ICT3112"
    },
    {
        "id": "ICT3112_3",
        "name": "ICT3112 Week 3",
        "module_id": "ICT3112"
    },
    {
        "id": "ICT3112_4",
        "name": "ICT3112 Week 4",
        "module_id": "ICT3112"
    },
    {
        "id": "ICT3112_5",
        "name": "ICT3112 Week 5",
        "module_id": "ICT3112"
    },
    {
        "id": "ICT3112_6",
        "name": "ICT3112 Week 6",
        "module_id": "ICT3112"
    },
    {
        "id": "ICT3112_7",
        "name": "ICT3112 Week 7",
        "module_id": "ICT3112"
    },
    {
        "id": "ICT3112_8",
        "name": "ICT3112 Week 8",
        "module_id": "ICT3112"
    },
    {
        "id": "ICT3112_9",
        "name": "ICT3112 Week 9",
        "module_id": "ICT3112"
    },
    {
        "id": "ICT3112_10",
        "name": "ICT3112 Week 10",
        "module_id": "ICT3112"
    },
    {
        "id": "ICT3112_11",
        "name": "ICT3112 Week 11",
        "module_id": "ICT3112"
    },
    {
        "id": "ICT3112_12",
        "name": "ICT3112 Week 12",
        "module_id": "ICT3112"
    },
    {
        "id": "ICT3112_13",
        "name": "ICT3112 Week 13",
        "module_id": "ICT3112"
    },
    {
        "id": "ICT3113_1",
        "name": "ICT3113 Week 1",
        "module_id": "ICT3113"
    },
    {
        "id": "ICT3113_2",
        "name": "ICT3113 Week 2",
        "module_id": "ICT3113"
    },
    {
        "id": "ICT3113_3",
        "name": "ICT3113 Week 3",
        "module_id": "ICT3113"
    },
    {
        "id": "ICT3113_4",
        "name": "ICT3113 Week 4",
        "module_id": "ICT3113"
    },
    {
        "id": "ICT3113_5",
        "name": "ICT3113 Week 5",
        "module_id": "ICT3113"
    },
    {
        "id": "ICT3113_6",
        "name": "ICT3113 Week 6",
        "module_id": "ICT3113"
    },
    {
        "id": "ICT3113_7",
        "name": "ICT3113 Week 7",
        "module_id": "ICT3113"
    },
    {
        "id": "ICT3113_8",
        "name": "ICT3113 Week 8",
        "module_id": "ICT3113"
    },
    {
        "id": "ICT3113_9",
        "name": "ICT3113 Week 9",
        "module_id": "ICT3113"
    },
    {
        "id": "ICT3113_10",
        "name": "ICT3113 Week 10",
        "module_id": "ICT3113"
    },
    {
        "id": "ICT3113_11",
        "name": "ICT3113 Week 11",
        "module_id": "ICT3113"
    },
    {
        "id": "ICT3113_12",
        "name": "ICT3113 Week 12",
        "module_id": "ICT3113"
    },
    {
        "id": "ICT3113_13",
        "name": "ICT3113 Week 13",
        "module_id": "ICT3113"
    },
    {
        "id": "INF2006_1",
        "name": "INF2006 Week 1",
        "module_id": "INF2006"
    },
    {
        "id": "INF2006_2",
        "name": "INF2006 Week 2",
        "module_id": "INF2006"
    },
    {
        "id": "INF2006_3",
        "name": "INF2006 Week 3",
        "module_id": "INF2006"
    },
    {
        "id": "INF2006_4",
        "name": "INF2006 Week 4",
        "module_id": "INF2006"
    },
    {
        "id": "INF2006_5",
        "name": "INF2006 Week 5",
        "module_id": "INF2006"
    },
    {
        "id": "INF2006_6",
        "name": "INF2006 Week 6",
        "module_id": "INF2006"
    },
    {
        "id": "INF2006_7",
        "name": "INF2006 Week 7",
        "module_id": "INF2006"
    },
    {
        "id": "INF2006_8",
        "name": "INF2006 Week 8",
        "module_id": "INF2006"
    },
    {
        "id": "INF2006_9",
        "name": "INF2006 Week 9",
        "module_id": "INF2006"
    },
    {
        "id": "INF2006_10",
        "name": "INF2006 Week 10",
        "module_id": "INF2006"
    },
    {
        "id": "INF2006_11",
        "name": "INF2006 Week 11",
        "module_id": "INF2006"
    },
    {
        "id": "INF2006_12",
        "name": "INF2006 Week 12",
        "module_id": "INF2006"
    },
    {
        "id": "INF2006_13",
        "name": "INF2006 Week 13",
        "module_id": "INF2006"
    },
    {
        "id": "USI2001_1",
        "name": "USI2001 Week 1",
        "module_id": "USI2001"
    },
    {
        "id": "USI2001_2",
        "name": "USI2001 Week 2",
        "module_id": "USI2001"
    },
    {
        "id": "USI2001_3",
        "name": "USI2001 Week 3",
        "module_id": "USI2001"
    },
    {
        "id": "USI2001_4",
        "name": "USI2001 Week 4",
        "module_id": "USI2001"
    },
    {
        "id": "USI2001_5",
        "name": "USI2001 Week 5",
        "module_id": "USI2001"
    },
    {
        "id": "USI2001_6",
        "name": "USI2001 Week 6",
        "module_id": "USI2001"
    },
    {
        "id": "USI2001_7",
        "name": "USI2001 Week 7",
        "module_id": "USI2001"
    },
    {
        "id": "USI2001_8",
        "name": "USI2001 Week 8",
        "module_id": "USI2001"
    },
    {
        "id": "USI2001_9",
        "name": "USI2001 Week 9",
        "module_id": "USI2001"
    },
    {
        "id": "USI2001_10",
        "name": "USI2001 Week 10",
        "module_id": "USI2001"
    },
    {
        "id": "USI2001_11",
        "name": "USI2001 Week 11",
        "module_id": "USI2001"
    },
    {
        "id": "USI2001_12",
        "name": "USI2001 Week 12",
        "module_id": "USI2001"
    },
    {
        "id": "USI2001_13",
        "name": "USI2001 Week 13",
        "module_id": "USI2001"
    },
    {
        "id": "ICT3217_1",
        "name": "ICT3217 Week 1",
        "module_id": "ICT3217"
    },
    {
        "id": "ICT3217_2",
        "name": "ICT3217 Week 2",
        "module_id": "ICT3217"
    },
    {
        "id": "ICT3217_3",
        "name": "ICT3217 Week 3",
        "module_id": "ICT3217"
    },
    {
        "id": "ICT3217_4",
        "name": "ICT3217 Week 4",
        "module_id": "ICT3217"
    },
    {
        "id": "ICT3217_5",
        "name": "ICT3217 Week 5",
        "module_id": "ICT3217"
    },
    {
        "id": "ICT3217_6",
        "name": "ICT3217 Week 6",
        "module_id": "ICT3217"
    },
    {
        "id": "ICT3217_7",
        "name": "ICT3217 Week 7",
        "module_id": "ICT3217"
    },
    {
        "id": "ICT3217_8",
        "name": "ICT3217 Week 8",
        "module_id": "ICT3217"
    },
    {
        "id": "ICT3217_9",
        "name": "ICT3217 Week 9",
        "module_id": "ICT3217"
    },
    {
        "id": "ICT3217_10",
        "name": "ICT3217 Week 10",
        "module_id": "ICT3217"
    },
    {
        "id": "ICT3217_11",
        "name": "ICT3217 Week 11",
        "module_id": "ICT3217"
    },
    {
        "id": "ICT3217_12",
        "name": "ICT3217 Week 12",
        "module_id": "ICT3217"
    },
    {
        "id": "ICT3217_13",
        "name": "ICT3217 Week 13",
        "module_id": "ICT3217"
    },
    {
        "id": "INF2008_1",
        "name": "INF2008 Week 1",
        "module_id": "INF2008"
    },
    {
        "id": "INF2008_2",
        "name": "INF2008 Week 2",
        "module_id": "INF2008"
    },
    {
        "id": "INF2008_3",
        "name": "INF2008 Week 3",
        "module_id": "INF2008"
    },
    {
        "id": "INF2008_4",
        "name": "INF2008 Week 4",
        "module_id": "INF2008"
    },
    {
        "id": "INF2008_5",
        "name": "INF2008 Week 5",
        "module_id": "INF2008"
    },
    {
        "id": "INF2008_6",
        "name": "INF2008 Week 6",
        "module_id": "INF2008"
    },
    {
        "id": "INF2008_7",
        "name": "INF2008 Week 7",
        "module_id": "INF2008"
    },
    {
        "id": "INF2008_8",
        "name": "INF2008 Week 8",
        "module_id": "INF2008"
    },
    {
        "id": "INF2008_9",
        "name": "INF2008 Week 9",
        "module_id": "INF2008"
    },
    {
        "id": "INF2008_10",
        "name": "INF2008 Week 10",
        "module_id": "INF2008"
    },
    {
        "id": "INF2008_11",
        "name": "INF2008 Week 11",
        "module_id": "INF2008"
    },
    {
        "id": "INF2008_12",
        "name": "INF2008 Week 12",
        "module_id": "INF2008"
    },
    {
        "id": "INF2008_13",
        "name": "INF2008 Week 13",
        "module_id": "INF2008"
    },
    {
        "id": "INF2009_1",
        "name": "INF2009 Week 1",
        "module_id": "INF2009"
    },
    {
        "id": "INF2009_2",
        "name": "INF2009 Week 2",
        "module_id": "INF2009"
    },
    {
        "id": "INF2009_3",
        "name": "INF2009 Week 3",
        "module_id": "INF2009"
    },
    {
        "id": "INF2009_4",
        "name": "INF2009 Week 4",
        "module_id": "INF2009"
    },
    {
        "id": "INF2009_5",
        "name": "INF2009 Week 5",
        "module_id": "INF2009"
    },
    {
        "id": "INF2009_6",
        "name": "INF2009 Week 6",
        "module_id": "INF2009"
    },
    {
        "id": "INF2009_7",
        "name": "INF2009 Week 7",
        "module_id": "INF2009"
    },
    {
        "id": "INF2009_8",
        "name": "INF2009 Week 8",
        "module_id": "INF2009"
    },
    {
        "id": "INF2009_9",
        "name": "INF2009 Week 9",
        "module_id": "INF2009"
    },
    {
        "id": "INF2009_10",
        "name": "INF2009 Week 10",
        "module_id": "INF2009"
    },
    {
        "id": "INF2009_11",
        "name": "INF2009 Week 11",
        "module_id": "INF2009"
    },
    {
        "id": "INF2009_12",
        "name": "INF2009 Week 12",
        "module_id": "INF2009"
    },
    {
        "id": "INF2009_13",
        "name": "INF2009 Week 13",
        "module_id": "INF2009"
    },
    {
        "id": "UCM3001_1",
        "name": "UCM3001 Week 1",
        "module_id": "UCM3001"
    },
    {
        "id": "UCM3001_2",
        "name": "UCM3001 Week 2",
        "module_id": "UCM3001"
    },
    {
        "id": "UCM3001_3",
        "name": "UCM3001 Week 3",
        "module_id": "UCM3001"
    },
    {
        "id": "UCM3001_4",
        "name": "UCM3001 Week 4",
        "module_id": "UCM3001"
    },
    {
        "id": "UCM3001_5",
        "name": "UCM3001 Week 5",
        "module_id": "UCM3001"
    },
    {
        "id": "UCM3001_6",
        "name": "UCM3001 Week 6",
        "module_id": "UCM3001"
    },
    {
        "id": "UCM3001_7",
        "name": "UCM3001 Week 7",
        "module_id": "UCM3001"
    },
    {
        "id": "UCM3001_8",
        "name": "UCM3001 Week 8",
        "module_id": "UCM3001"
    },
    {
        "id": "UCM3001_9",
        "name": "UCM3001 Week 9",
        "module_id": "UCM3001"
    },
    {
        "id": "UCM3001_10",
        "name": "UCM3001 Week 10",
        "module_id": "UCM3001"
    },
    {
        "id": "UCM3001_11",
        "name": "UCM3001 Week 11",
        "module_id": "UCM3001"
    },
    {
        "id": "UCM3001_12",
        "name": "UCM3001 Week 12",
        "module_id": "UCM3001"
    },
    {
        "id": "UCM3001_13",
        "name": "UCM3001 Week 13",
        "module_id": "UCM3001"
    },
    {
        "id": "ICT4011_1",
        "name": "ICT4011 Week 1",
        "module_id": "ICT4011"
    },
    {
        "id": "ICT4011_2",
        "name": "ICT4011 Week 2",
        "module_id": "ICT4011"
    },
    {
        "id": "ICT4011_3",
        "name": "ICT4011 Week 3",
        "module_id": "ICT4011"
    },
    {
        "id": "ICT4011_4",
        "name": "ICT4011 Week 4",
        "module_id": "ICT4011"
    },
    {
        "id": "ICT4011_5",
        "name": "ICT4011 Week 5",
        "module_id": "ICT4011"
    },
    {
        "id": "ICT4011_6",
        "name": "ICT4011 Week 6",
        "module_id": "ICT4011"
    },
    {
        "id": "ICT4011_7",
        "name": "ICT4011 Week 7",
        "module_id": "ICT4011"
    },
    {
        "id": "ICT4011_8",
        "name": "ICT4011 Week 8",
        "module_id": "ICT4011"
    },
    {
        "id": "ICT4011_9",
        "name": "ICT4011 Week 9",
        "module_id": "ICT4011"
    },
    {
        "id": "ICT4011_10",
        "name": "ICT4011 Week 10",
        "module_id": "ICT4011"
    },
    {
        "id": "ICT4011_11",
        "name": "ICT4011 Week 11",
        "module_id": "ICT4011"
    },
    {
        "id": "ICT4011_12",
        "name": "ICT4011 Week 12",
        "module_id": "ICT4011"
    },
    {
        "id": "ICT4011_13",
        "name": "ICT4011 Week 13",
        "module_id": "ICT4011"
    },
    {
        "id": "ICT4012B_1",
        "name": "ICT4012B Week 1",
        "module_id": "ICT4012B"
    },
    {
        "id": "ICT4012B_2",
        "name": "ICT4012B Week 2",
        "module_id": "ICT4012B"
    },
    {
        "id": "ICT4012B_3",
        "name": "ICT4012B Week 3",
        "module_id": "ICT4012B"
    },
    {
        "id": "ICT4012B_4",
        "name": "ICT4012B Week 4",
        "module_id": "ICT4012B"
    },
    {
        "id": "ICT4012B_5",
        "name": "ICT4012B Week 5",
        "module_id": "ICT4012B"
    },
    {
        "id": "ICT4012B_6",
        "name": "ICT4012B Week 6",
        "module_id": "ICT4012B"
    },
    {
        "id": "ICT4012B_7",
        "name": "ICT4012B Week 7",
        "module_id": "ICT4012B"
    },
    {
        "id": "ICT4012B_8",
        "name": "ICT4012B Week 8",
        "module_id": "ICT4012B"
    },
    {
        "id": "ICT4012B_9",
        "name": "ICT4012B Week 9",
        "module_id": "ICT4012B"
    },
    {
        "id": "ICT4012B_10",
        "name": "ICT4012B Week 10",
        "module_id": "ICT4012B"
    },
    {
        "id": "ICT4012B_11",
        "name": "ICT4012B Week 11",
        "module_id": "ICT4012B"
    },
    {
        "id": "ICT4012B_12",
        "name": "ICT4012B Week 12",
        "module_id": "ICT4012B"
    },
    {
        "id": "ICT4012B_13",
        "name": "ICT4012B Week 13",
        "module_id": "ICT4012B"
    }
]