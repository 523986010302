const examination_data = [
    {
        title: 'LSM2212 Human Anatomy (Immune System) Quiz',
        description: 'Date & Time: Sun, 19 Nov 2023, 1200 Hrs',
        id: "LSM2212-quiz1",
        status: "new",
        experiment: true
    },
    {
        title: 'LSM2212 Human Anatomy (Nervous System) Quiz',
        description: 'Date & Time: Sun, 19 Nov 2023, 1230 Hrs',
        id: "LSM2212-quiz2",
        status: "new",
        experiment: true
    },
    {
        title: 'INF2002 Human Computer Interaction Quiz 1',
        description: 'Date & Time: Wed, 27 Sep 2023, 1100 Hrs',
        id: "inf2002-quiz1",
        status: "new"
    },
    {
        title: 'INF2003 Database Quiz 1',
        description: 'Date & Time: Thu, 28 Sep 2023, 1100 Hrs',
        id: "inf2003-quiz1",
        status: "new"
    },
    {
        title: 'INF2004 Embedded Systems Quiz 1',
        description: 'Date & Time: Fri, 29 Sep 2023, 1100 Hrs',
        id: "inf2004-quiz1",
        status: "new"
    },
    {
        title: 'INF2003 Database Quiz 2',
        description: 'Date & Time: Wed, 15 Nov 2023, 1100 Hrs',
        id: "inf2003-quiz2",
        status: "new"
    },
    {
        title: 'INF2002 Human Computer Interaction Quiz 2',
        description: 'Date & Time: Thu, 16 Nov 2023, 1100 Hrs',
        id: "inf2002-quiz2",
        status: "new"
    },
    {
        title: 'INF2001 Introduction to Software Engineering Quiz 1',
        description: 'Date & Time: Fri, 17 Nov 2023, 1100 Hrs',
        id: "inf2001-quiz1",
        status: "new"
    },
]

export default examination_data; 