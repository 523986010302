import React from 'react';
import { useNavigate } from "react-router-dom";
import {getCurrentUser} from "../data/storage";
import AppLayout from "../components/AppLayout";
import { Card } from 'antd';
import { Link } from "react-router-dom";
import { useEffect } from 'react';
import "./Home.css";
const Home = () =>{

    const navigate = useNavigate();

    useEffect(() => {
        if (!getCurrentUser())
            navigate("/login");
    }, []);

    return getCurrentUser() ?
        <AppLayout menuPath="/">
            <h1 className="homeTitle">Welcome, <strong>{getCurrentUser().name}</strong></h1>
            <div className="home-cards">
                <Link to="/revision">
                    <Card
                        style={{
                            width: 300,
                            height: 300
                        }}
                        headStyle={{
                            color: 'white',
                            fontSize: 35,
                            borderBottom: '3px solid white',
                            paddingBottom: 50,
                            paddingTop: 50,
                            textAlign: 'center'
                        }}
                        bodyStyle={{
                            color: 'white',

                        }}
                        title="Revision"
                        className="card-design"
                        bordered={true}
                    >
                        Revise your modules here!
                    </Card>
                </Link>
                <Link to="/examination">
                    <Card
                        style={{
                            width: 300,
                            height: 300
                        }}
                        headStyle={{
                            color: 'white',
                            fontSize: 35,
                            borderBottom: '3px solid white',
                            paddingBottom: 50,
                            paddingTop: 50,
                            textAlign: 'center'
                        }}
                        bodyStyle={{
                            color: 'white',

                        }}
                        title="Examination"
                        className="card-design"
                        bordered={true}
                    >
                        Take your exams here!
                    </Card>
                </Link>
                <Link to="/forum">
                    <Card
                        style={{
                            width: 300,
                            height: 300
                        }}
                        headStyle={{
                            color: 'white',
                            fontSize: 35,
                            borderBottom: '3px solid white',
                            paddingBottom: 50,
                            paddingTop: 50,
                            textAlign: 'center'
                        }}
                        bodyStyle={{
                            color: 'white',

                        }}
                        title="Forum"
                        className="card-design"
                        bordered={true}
                    >
                        Ask questions and you shall be rewarded!
                    </Card>
                </Link>
            </div>
        </AppLayout>: "";
};
export default Home;
