import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import Forum from "./pages/Forum";
import Reset from "./pages/Reset";
import {ConfigProvider, theme} from "antd";
import Revision from "./pages/Revision";
import Examination from "./pages/Examination";
import ExamQuestion from './pages/ExamQuestion';
import ExamResult from './pages/ExamResult';
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import RevisionTopic from "./pages/RevisionTopic";
import ResetApp from "./components/ResetApp";
import RevisionTopic2 from "./pages/RevisionTopic2";
import {getData, setData} from "./data/storage";
import {useEffect} from "react";
import {data} from "./data/data";

const App = () => {

    const version = "1.4";
    useEffect(() => {
        if (getData("version") !== version) {
            console.log("Updating app");
            localStorage.clear();
            for (const [key, value] of Object.entries(data)) setData(key, value);
            setData("version", version);
        }
    });

    return (
        <ConfigProvider theme={{
            algorithm: theme.defaultAlgorithm,
            components: {
                Select: {optionSelectedBg: "white"},
                Menu: {itemSelectedBg: "#d9cfc9"}
            },
            token: {
                colorPrimary: '#40402C', colorBgBase: '#f7f1ed', colorLink: 'white', colorLinkHover: '#babab8', colorFillContent: '#666666', colorTextDescription: '#5A5651', colorTextPlaceholder: '#5A5651'
            }
        }}>
            <div className="App">
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/revision" element={<Revision/>}/>
                    <Route path="/revision/:moduleCode" element={<Revision/>}/>
                    <Route path="/revision/:moduleCode/flip/:revisionTopic" element={<RevisionTopic2/>}/>
                    <Route path="/revision/:moduleCode/static/:revisionTopic" element={<RevisionTopic/>}/>
                    <Route path="/examination" element={<Examination/>}/>
                    <Route path="/examination/:examId" element={<ExamQuestion/>}/>
                    <Route path="/examination/:examId/result" element={<ExamResult/>}/>
                    <Route path="/forum" element={<Forum/>}/>
                    <Route path="/forum/:moduleId" element={<Forum/>}/>
                    <Route path="/forum/:moduleId/:forumTopicId" element={<Forum/>}/>
                    <Route path="/forum/:moduleId/:forumTopicId/:forumPostId" element={<Forum/>}/>
                    <Route path="/reset" element={<Reset/>}/>
                    <Route path="/logout" element={<Logout/>} />
                    <Route path="/login" element={<Login/>} />
                </Routes>
                <ResetApp/>
            </div>
        </ConfigProvider>
    )
}

export default App;
