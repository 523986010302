import React, {useState} from 'react';
import {Button, FloatButton, Modal} from "antd";
import {data} from "../data/data";
import {setData} from "../data/storage";
import {DeleteOutlined} from "@ant-design/icons";

const ResetApp = () => {

    const [open, setOpen] = useState(false);

    const clickOk = () => {
        localStorage.clear();
        for (const [key, value] of Object.entries(data))
            setData(key, value);
        setOpen(false);
    }

    return (
        <div>
            <FloatButton onClick={() => setOpen(true)}
                         icon={<DeleteOutlined />}
            />
            <Modal title={"Reset Application"}
                   open={open}
                   closeIcon={false}
                   footer={[
                       <Button onClick={() => setOpen(false)}>Cancel</Button>,
                       <Button onClick={() => clickOk()} type="primary" danger>Delete</Button>
                   ]}
            >
                <p>Are you sure you want to reset the application to defaults?</p>
                <p>ALL DATA WILL BE ERASED!</p>
            </Modal>
        </div>
    );
};

export default ResetApp;