
export const user_data = [
    {
        "id": "alain",
        "username": "alain",
        "password": "P@ssw0rd",
        "name": "ALAIN PIERRE RAYOS PARONDA",
        "experiment_group": "B"
    },
    {
        "id": "myatko",
        "username": "myatko",
        "password": "P@ssw0rd",
        "name": "KAUNG MYAT KO"
    },
    {
        "id": "cheehean",
        "username": "cheehean",
        "password": "P@ssw0rd",
        "name": "LIM CHEE HEAN",
        "experiment_group": "C"
    },
    {
        "id": "reness",
        "username": "reness",
        "password": "P@ssw0rd",
        "name": "RENESS RAVICHANDRAN"
    },
    {
        "id": "jinhao",
        "username": "jinhao",
        "password": "P@ssw0rd",
        "name": "TAN JIN HAO"
    },
    {
        "id": "bryan",
        "username": "bryan",
        "password": "P@ssw0rd",
        "name": "BRYAN",
        "experiment_group": "A"
    },
    {
        "id": "ruifeng",
        "username": "ruifeng",
        "password": "P@ssw0rd",
        "name": "RUI FENG",
        "experiment_group": "B"
      },
      {
        "id": "hongliang",
        "username": "hongliang",
        "password": "P@ssw0rd",
        "name": "HONG LIANG",
        "experiment_group": "C"
      },
      {
        "id": "wenqin",
        "username": "wenqin",
        "password": "P@ssw0rd",
        "name": "WEN QIN",
        "experiment_group": "D"
      },
      {
        "id": "junhao",
        "username": "junhao",
        "password": "P@ssw0rd",
        "name": "JUN HAO",
        "experiment_group": "A"
      },
      {
        "id": "chongyingee",
        "username": "chongyingee",
        "password": "P@ssw0rd",
        "name": "CHONG YING EE",
        "experiment_group": "B"
      },
      {
        "id": "neolokjun",
        "username": "neolokjun",
        "password": "P@ssw0rd",
        "name": "NEO LOK JUN",
        "experiment_group": "C"
      },
      {
        "id": "shuyujian",
        "username": "shuyujian",
        "password": "P@ssw0rd",
        "name": "SHU YU JIAN",
        "experiment_group": "D"
      },
      {
        "id": "tanpeiwen",
        "username": "tanpeiwen",
        "password": "P@ssw0rd",
        "name": "TAN PEI WEN",
        "experiment_group": "A"
      },
      {
        "id": "leeyingzhen",
        "username": "leeyingzhen",
        "password": "P@ssw0rd",
        "name": "LEE YING ZHEN",
        "experiment_group": "B"
      },
      {
        "id": "isaac",
        "username": "isaac",
        "password": "P@ssw0rd",
        "name": "ISAAC",
        "experiment_group": "A"
      },
      {
        "id": "michelle",
        "username": "michelle",
        "password": "P@ssw0rd",
        "name": "MICHELLE",
        "experiment_group": "D"
      },
      {
        "id": "reina",
        "username": "reina",
        "password": "P@ssw0rd",
        "name": "REINA",
        "experiment_group": "A"
      },
      {
        "id": "weijie",
        "username": "weijie",
        "password": "P@ssw0rd",
        "name": "Wei Jie",
        "experiment_group": "D"
      },
    {
        "id": "isaiah",
        "username": "isaiah",
        "password": "P@ssw0rd",
        "name": "Isaiah",
        "experiment_group": "A"
    },
    {
        "id": "carmen",
        "username": "carmen",
        "password": "P@ssw0rd",
        "name": "Carmen",
        "experiment_group": "B"
    },
    {
        "id": "jialiang",
        "username": "jialiang",
        "password": "P@ssw0rd",
        "name": "Jia Liang",
        "experiment_group": "C"
    },
    {
      "id": "jevitha",
      "username": "jevitha",
      "password": "P@ssw0rd",
      "name": "Jevitha",
      "experiment_group": "C"
    },
    {
      "id": "laxshini",
      "username": "laxshini",
      "password": "P@ssw0rd",
      "name": "Laxshini",
      "experiment_group": "D"
    },
    {  
        "id": "ernest",
        "username": "ernest",
        "password": "P@ssw0rd",
        "name": "ERNEST FOO YONG JIE",
        "experiment_group": "B"
    },
    {  
      "id": "jingkai",
      "username": "jingkai",
      "password": "P@ssw0rd",
      "name": "Jing Kai",
      "experiment_group": "A"
  },
  {
      "id": "eric",
      "username": "eric",
      "password": "P@ssw0rd",
      "name": "ERIC",
      "experiment_group": "B"
  }
]