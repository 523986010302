
export const setData = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
}

export const getData = (key) => {
    return JSON.parse(localStorage.getItem(key));
}

export const addItem = (key, item) => {
    setData(key, [...getData(key), item]);
}

export const getById = (key, id) => {
    return getData(key).find(item => item.id === id);
}

export const getCount = (key, itemKey, itemValue) => {
    return getData(key).filter(item => item[itemKey] === itemValue).length;
}

export const getNextId = (key) => {
    return Math.max(...getData(key).map(item => item.id)) + 1 || 1;
}

export const getFormattedCurrentDate = () => {
    const now = new Date();
    return now.getFullYear() + "-" + (now.getMonth() + 1).toString().padStart(2, "0") + "-" + now.getDate().toString().padStart(2, "0") + " " + now.getHours().toString().padStart(2, "0") + ":" + now.getMinutes().toString().padStart(2, "0") + ":" + now.getSeconds().toString().padStart(2, "0");
}

export const getCurrentUser = () => {
    if (!getData("version"))
        return undefined;
    return getById("users", sessionStorage.getItem("loggedInUser"));
}