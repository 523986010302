import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { data } from "../data/data";
import { setData } from "../data/storage";

const Reset = () => {
  const navigate = useNavigate();

  localStorage.clear();

  for (const [key, value] of Object.entries(data)) setData(key, value);

  useEffect(() => {
    navigate("/");
  });

  return <div></div>;
};

export default Reset;
