import AppLayout from "../components/AppLayout";
import { List, Button, Tabs } from "antd";
import examination_data from '../data/examination_data';
import {Link, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from 'react';
import "./Examination.css";
import {getById, getCurrentUser, getData} from "../data/storage";
import {data} from "../data/data";

const { TabPane } = Tabs;

const Examination = () => {

    const navigate = useNavigate();

    useEffect(() => {

        // Check the user's login status from sessionStorage
        const userLoginInfo = sessionStorage.getItem("loggedInUser");

        // If the user is not logged in, navigate to the login page
        if (!userLoginInfo) {
            navigate("/login");
        }
    });

     // Filter the examination data into New and Completed quizzes
     const examinations = getData("exam_data").filter(exam => getCurrentUser().experiment_group ? exam.experiment : true);
     const newQuizzes = [];
     const completedQuizzes = [];
     const userId = getCurrentUser().id;
     for (const exam of examinations) {
         // Get first question
         const question = getData("exam_questions").find(q => q.examination_id === exam.id);
         const feedback = getData("exam_feedback").find(f => f.exam_question_id === question.id && f.user_id === userId);
         if (feedback === undefined)
             newQuizzes.push(exam);
         else
             completedQuizzes.push(exam);
     }

     return (
        <AppLayout menuPath="/examination">
            <Tabs defaultActiveKey="new" type="card">
                <TabPane tab="New Quizzes" key="new">
                    <List
                        itemLayout="vertical"
                        pagination={{ pageSize: 5 }}
                        dataSource={newQuizzes}
                        renderItem={(item) => (
                            <List.Item>
                                <div className="exam-details" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <List.Item.Meta
                                        title={item.title}
                                        description={item.description}
                                    />
                                    <Link to={`/examination/${item.id}`}>
                                        <Button type="primary" className="start-button">
                                            Start
                                        </Button>
                                    </Link>
                                </div>
                            </List.Item>
                        )}
                    />
                </TabPane>
                <TabPane tab="Completed Quizzes" key="completed">
                    <List
                        itemLayout="vertical"
                        pagination={{ pageSize: 5 }}
                        dataSource={completedQuizzes}
                        renderItem={(item) => (
                            <List.Item>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <List.Item.Meta
                                        title={item.title}
                                        description={item.description}
                                    />
                                    <Link to={`/examination/${item.id}/result`}>
                                        <Button type="default" className="ViewResults-button">
                                            View Results
                                        </Button>
                                    </Link>
                                </div>
                            </List.Item>
                        )}
                    />
                </TabPane>
            </Tabs>
        </AppLayout>
    );
};

export default Examination;