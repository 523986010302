import React, { useEffect } from "react";
import {Layout, Menu} from "antd";
import {Content, Header, Footer} from "antd/es/layout/layout";
import {useNavigate} from "react-router-dom";
import "./AppLayout.css";

const AppLayout = ({ children, menuPath, hideNavigation }) => {
    const navigate = useNavigate();

    const menuItems = [
        { key: "/", label: "Home", style: {color:'white'} },
        { key: "/revision", label: "Revision", style: {color:'white'} },
        { key: "/examination", label: "Examination", style: {color:'white'} },
        { key: "/forum", label: "Forum", style: {color:'white'} },
    ];

    const userLoginInfo = sessionStorage.getItem("loggedInUser");

    if (userLoginInfo) {
        // User is logged in, change the last menu item to "Logout"
        menuItems.push({ key: "/logout", label: "Logout", style: { marginLeft: "auto", color:'white' }  });
    } else {
        // User is not logged in, keep the last menu item as "Login"
        menuItems.push({ key: "/login", label: "Login", style: { marginLeft: "auto", color: 'white' } });
    }

    const changePage = (e) => {
        navigate(e.key);
    }

    useEffect(() => {
        // If the user is not logged in, navigate to the login page
        if (!userLoginInfo) {
            navigate("/login");
        }
    }, [navigate, userLoginInfo]);

    return (
        <Layout
            className="layout"
            style={{ minHeight: "100vh", display: "flex", flexDirection: "column", backgroundColor:'#DAD0CA' }}
        >
            <Header style={{ padding: "0" }}>
                <Menu
                    theme="dark"
                    mode="horizontal"
                    inlineIndent={12}
                    items={hideNavigation ? [] : menuItems}
                    selectedKeys={[menuPath]}
                    onClick={changePage}
                    className="navbar-menu"
                />
            </Header>

            <Content style={{ padding: '0 50px', position: 'relative' }}>
                {children}
            </Content>

            <Footer
                style={{
                    textAlign: 'center',
                    backgroundColor: '#343431',
                    color: 'white'
                }}
            >
                Xamination Learning Platform © 2023
            </Footer>
        </Layout>
    );
};

export default AppLayout;