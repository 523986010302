
export const flashcard_data = [
    {
        "id": 1,
        "question": "What is the main function of the nervous system?",
        "answer": "The nervous system controls the body through electrical and chemical signals. Its roles include both sensory and motor functions, as well as the control of basic life processes. The nervous system involves both voluntary and involuntary control",
        "topic": "Nervous System",
        "toLink": "nervous_system"
    },
    {
        "id": 2,
        "question": "What are the defining features of a neuron?",
        "answer": "Neurons are the functional units of the nervous system. They snapse with other neurons and transmit electrical and chemical signals.",
        "topic": "Nervous System",
        "toLink": "nervous_system"
    },
    {
        "id": 3,
        "question": "Which part of the neuron contains the nucleus, as well as organelles like the endoplasmic reticulum and mitochondria?",
        "answer": "The cell body or soma serves as the location for the nucleus and organelles.",
        "topic": "Nervous System",
        "toLink": "nervous_system"
    },
    {
        "id": 4,
        "question": "Which part of the neuron receives incoming signals from the previous neurons in the form of neurotransmitter-containing vesicles?",
        "answer": "Dendrites receive signals from adjacent neurons. A neuron releases neurotransmitters, held inside membrance-bound vesicles, into the synapse in a calcium-dependent process. These vesicles reach the dendrites of another cell and activate its receptors.",
        "topic": "Nervous System",
        "toLink": "nervous_system"
    },
    {
        "id": 5,
        "question": "What is a synapse?",
        "answer": "A synapse is a region where one nerve cell adjoins another. Here, signals can pass between the two, generally from the axon of the presynaptic cell to the dendrite of the postsynaptic one.",
        "topic": "Nervous System",
        "toLink": "nervous_system"
    },
    {
        "id": 6,
        "question": "Which part of the neuron ends in a single terminal and can release neurotransmitters?",
        "answer": "The axon releases neurotransmitters at its terminal. These neurotransmitters can range from glutamate to acetylcholine, but all must be held in membrane-bound vesicles.",
        "topic": "Nervous System",
        "toLink": "nervous_system"
    },
    {
        "id": 7,
        "question": "What are the two divisions of the autonomic nervous system?",
        "answer": "The automatic nervous system is divided into the sympathetic and parasympathetic systems. The sympathetic system is commonly known to activate fight or flight responses, while the parasympathetic system relates to resting and digesting",
        "topic": "Nervous System",
        "toLink": "nervous_system"
    },
    {
        "id": 8,
        "question": "What is the function of the myelin sheath in the nervous system?",
        "answer": "The myelin sheath is a protective covering made of fatty material that surrounds the axons of some neurons in the nervous system. Its primary function is to increase the speed at which electrical impulses (action potentials) are conducted along the axon.",
        "topic": "Nervous System",
        "toLink": "nervous_system"
    },
    {
        "id": 1,
        "question": "What enzyme is found in tears and catalyzes the breakdown of bacterial cell walls?",
        "answer": "Specifically, lysozyme catalyzes the breaking of glycosidic linkages within peptidoglycan molecules. This role is part of the innate immune system.",
        "topic": "Immune System",
        "toLink": "immune_system"
    },
    {
        "id": 2,
        "question": "Which structures secrete a thick fluid that helps protect the digestive and respiratory tract against pathogens?",
        "answer": "Mucous membranes. These membranes secrete mucus, a protective fluid that cost pathogens and foreign particles, trapping them in a place. Mucus often contains enzymes that act to damage microbes.",
        "topic": "Immune System",
        "toLink": "immune_system"
    },
    {
        "id": 3,
        "question": "What large organ covers most of the body and serves as an early line of defense against pathogens?",
        "answer": "The skin. The skin is technically an organ! Skin serves as an innate protective mechanism against many pathogens and foreign substances.",
        "topic": "Immune System",
        "toLink": "immune_system"
    },
    {
        "id": 4,
        "question": "What is a pathogen?",
        "answer": "A pathogen is a disease-causing organism. The purpose of the immune system is to protect against pathogens, foreign particles, and the body's own damaged cells.",
        "topic": "Immune System",
        "toLink": "immune_system"
    },
    {
        "id": 5,
        "question": "Name the most abundant leukocyte found in the blood",
        "answer": "The most abundant leukocytes are neutrophilis, which constitute 50-70% of all white blood cells by number. As one of the first cell types to reach the site of an infection, neutrophilis are the main component of pus. Like macrophages, they can perform phagocytosis.",
        "topic": "Immune System",
        "toLink": "immune_system"
    },
    {
        "id": 6,
        "question": "Other than the skin and mucous membrane, what are the three components of the innate immune response?",
        "answer": "Three main components of the innate immune response are: The inflammatory response, Phagocytosis and Interferons.",
        "topic": "Immune System",
        "toLink": "immune_system"
    },
    {
        "id": 7,
        "question": "Give another name for innate immunity.",
        "answer": "Innate immunity is also known as nonspecific immunity.",
        "topic": "Immune System",
        "toLink": "immune_system"
    },
    {
        "id": 8,
        "question": "What is the difference between innate and specific immunity?",
        "answer": "Innate immunity provides a broad line of defense against fforeign material. Its response to pathogens is general, not specific to the identity of the pathogen. While specific immunity is highly specialized, using immunological memory of previous infections to respond to certain pathogens.",
        "topic": "Immune System",
        "toLink": "immune_system"
    },
    {
        "id": 1,
        "question": "What is the input and output of past, present and future interfaces?",
        "answer": "In the past, interfaces primarily used text-based input and output, such as command-line interfaces. Present day interfaces commonly use graphical user interfaces. Future interfaces may involve more diverse input methods, including voice commands, gestures, and brain-computer interfaces, and more immersive output, like augmented and virtual reality displays.",
        "topic": "History of HCI",
        "toLink": "History_of_HCI"
    },
    {
        "id": 2,
        "question": "What is the memex? What is the metaphor or conceptual model of the memex?",
        "answer": "The Memex is a hypothetical information storage and retrieval system described by Vannevar Bush in 1945. Its conceptual model is that of a personal device that allows users to store, access, and link information, creating a web of associative trails. It’s like an “external memory” that helps users organise and access their knowledge efficiently.",
        "topic": "History of HCI",
        "toLink": "History_of_HCI"
    },
    {
        "id": 3,
        "question": "What current day concepts did the memex inspire?",
        "answer": "The Memex inspired the idea of hypertext and the World Wide Web. Tim Berners-Lee’s invention of the web drew inspiration from the associative links and ease of navigation proposed by the Memex.",
        "topic": "History of HCI",
        "toLink": "History_of_HCI"
    },
    {
        "id": 4,
        "question": "What are possible future interfaces that could be developed?",
        "answer": "Possible future interfaces include brain-computer interfaces, immersive augmented and virtual reality, holographic displays, and more intuitive gesture-based interfaces. The development of AI and machine learning may enable more natural language and conversational interfaces.",
        "topic": "History of HCI",
        "toLink": "History_of_HCI"
    },
    {
        "id": 5,
        "question": "What is the taxonomy for mixing real and virtual worlds?",
        "answer": "The taxonomy classifies mixed reality along two axes: real-virtual (from entirely real to entirely virtual) and environment-object (from real objects to entirely virtual objects). This classification helps describe and categorise mixed reality experiences more precisely.",
        "topic": "History of HCI",
        "toLink": "History_of_HCI"
    },
    {
        "id": 1,
        "question": "What is User Centered Design (UCD)?",
        "answer": "User Centered Design is an iterative process, where the product is modified and tested repeatedly with users throughout the product lifecycle. It consists of an active involvement of users and emphasises a clear understanding of user and task requirements. It ensures the appropriate allocation between users and technology. It is also the iteration of design solutions. It is also a multi-disciplinary design.",
        "topic": "User Centered Design",
        "toLink": "User_Centered_Design"
    },
    {
        "id": 2,
        "question": "What are the User Centered Design (UCD) software development principles?",
        "answer": "There are 3 UCD principles. The first is to place an early focus on users and tasks. Secondly, it involves the empirical measurement and testing of product usage. Lastly, it is an iterative design with product designed, modified and tested repeatedly.",
        "topic": "User Centered Design",
        "toLink": "User_Centered_Design"
    },
    {
        "id": 3,
        "question": "What are the benefits of the usability activities at the design stage?",
        "answer": "It enables early user feedback and helps identify user needs and requirements.",
        "topic": "User Centered Design",
        "toLink": "User_Centered_Design"
    },
    {
        "id": 4,
        "question": "Why is it important to include the user during the design process?",
        "answer": "Including the user during the design process is essential because it ensures that the final product aligns with actual user needs and preferences, reducing the risk of costly misalignment and enabling the creation of user-friendly interfaces. User involvement leads to enhanced user satisfaction, as their input helps identify pain points and challenges, validates design decisions, and fosters innovation. It also contributes to greater efficiency and effectiveness, addresses ethical considerations, and ultimately increases market competitiveness.",
        "topic": "User Centered Design",
        "toLink": "User_Centered_Design"
    },
    {
        "id": 5,
        "question": "What is a persona? Why is it useful?",
        "answer": "A persona is a representation of the goals and behaviour of a hypothesised group of users (i.e. representative user, not real user). It is synthesised from the data collected from interviews or surveys with users. It is useful as it helps to focus and guide design decisions about the product with the target group in mind.",
        "topic": "User Centered Design",
        "toLink": "User_Centered_Design"
    },
    {
        "id": 1,
        "question": "What is the Hawthorne Effect?",
        "answer": "The Hawthorne Effect is when participants have the tendency to perform differently when they know that they are being observed. They may become more nervous and not behave normally. They may also be thinking more about being observed than doing the task.",
        "topic": "Needfinding",
        "toLink": "Needfinding"
    },
    {
        "id": 2,
        "question": "What is the difference between direct and indirect observation?",
        "answer": "Direct observation is a primary research technique where the researcher is the observer, while indirect observation is secondary research, relying on the reported observations of others.",
        "topic": "Needfinding",
        "toLink": "Needfinding"
    },
    {
        "id": 3,
        "question": "What is ethnography? How is it different from doing direct observation?",
        "answer": "It is a qualitative research method used in the field of social science and anthropology to study and understand the cultures, behaviours, and social practices of specific groups of people or communities. It involves spending a significant amount of time, for months or even years, in the field, which can be a physical location or a specific social setting. A variety of methods used: surveys, interviews and actively participating in the group to gain an insider’s perspective of the group.",
        "topic": "Needfinding",
        "toLink": "Needfinding"
    },
    {
        "id": 3,
        "question": "Why is needfinding required? What does it achieve?",
        "answer": "Needfinding is required as it helps to uncover the needs people have which they may or may not be aware of, helps guide innovation efforts, identifies someone to design for and also discovers the emotions that guides user behaviours.",
        "topic": "Needfinding",
        "toLink": "Needfinding"
    },
    {
        "id": 4,
        "question": "What are the 2 settings where direct observation can be done and what are the pros and cons of each setting?",
        "answer": "The 2 settings are a controlled setting and a field setting.\n" +
            "\n" +
            "Pros of controlled setting:\n" +
            "1. Control over variables\n" +
            "2. Accuracy in data collected\n" +
            "3. Results more easy replicated\n" +
            "\n" +
            "Cons of controlled setting:\n" +
            "1. Lack realism of natural environments\n" +
            "2. Resource-intensive\n" +
            "\n" +
            "Pros of field setting:\n" +
            "1. Natural behaviour as participants are in their natural environment\n" +
            "2. Increase in valuable information collected not found in controlled settings\n" +
            "\n" +
            "Cons of field setting:\n" +
            "1. Less control in variables\n" +
            "2. Difficult to recreate results",
        "topic": "Needfinding",
        "toLink": "Needfinding"
    },
    {
        "id": 5,
        "question": "What are the pros and cons of the following techniques (Direct Observation, Indirect Observation)",
        "answer": "Pros of Direct Observation: Rich qualitative data, focus on task without interruptions\n" +
            "Cons of Direct Observation: Hawthorne Effect, time consuming\n" +
            "\n" +
            "Pros of Indirect Observation: Can gather data without direct participant involvement; useful for studying sensitive or private behaviours; less intrusive.\n" +
            "Cons of Indirect Observation: Relies on secondary data sources, which may not be as accurate or up-to-date; limited control over data collection; potential for bias in data sources.",
        "topic": "Needfinding",
        "toLink": "Needfinding"
    },
    {
        "id": 1,
        "question": "Why do we use task analysis?",
        "answer": "Task analysis is the study of the way people perform their jobs and achieve their intended goals. The goal of it is to determine what users do, what things they use and the information they must know to achieve their tasks. It helps to better understand user needs, identify tasks the application must support or re-define content scope.",
        "topic": "Task Analysis",
        "toLink": "Task_Analysis"
    },
    {
        "id": 2,
        "question": "What is the difference between declarative and procedural knowledge?",
        "answer": "Declarative knowledge consists of objects and relationships. Procedural knowledge consists of task sequences, goals and subgoals.",
        "topic": "Task Analysis",
        "toLink": "Task_Analysis"
    },
    {
        "id": 3,
        "question": "What are the differences between hierarchical task analysis, knowledge based and entity modeling methods?",
        "answer": "Hierarchical task analysis focuses on how the task is done, the procedure to achieve the task, the goals and subtasks and the task sequence.\n" +
            "\n" +
            "Task Descriptive Hierarchy focuses on the knowledge required for the task, the taxonomy and categories and the organisation of knowledge.\n" +
            "\n" +
            "Entity based analyses focuses on relationships between objects, actions and the people who perform them.",
        "topic": "Task Analysis",
        "toLink": "Task_Analysis"
    },
    {
        "id": 4,
        "question": "Conduct a hierarchical task analysis of starting the ignition of a car. State any assumptions. What is the declarative and procedural knowledge that could be gathered from this task?",
        "answer": "Assumptions:\n" +
            "1. The car has a conventional key ignition system (not push-button or keyless entry).\n" +
            "2. The driver is already seated in the driver’s seat with the seatbelt fastened.\n" +
            "\n" +
            "HTA:\n" +
            "0: Starting the Ignition of the Car\n" +
            "1. Insert the Key into the Ignition Slot\n" +
            "2. Turn the Key to the Right\n" +
            "3. Hold the Key in the ‘Start’ Position\n" +
            "4. Release the Key After the Engine Starts\n" +
            "\n" +
            "Declarative Knowledge: car, key, ignition slot, engine\n" +
            "\n" +
            "Procedural Knowledge: insert the key, turn the key, hold the key, release the key when engine starts",
        "topic": "Task Analysis",
        "toLink": "Task_Analysis"
    },
    {
        "id": 5,
        "question": "For the task analysis of buying a drink from the vending machine, what is the declarative knowledge and procedural knowledge that could be gathered from this task?",
        "answer": "Declarative: vending machine, drink, coins\n" +
            "Procedural: buy a drink, put in coins, put in dollars",
        "topic": "Task Analysis",
        "toLink": "Task_Analysis"
    },
    {
        "id": 1,
        "question": "What is the definition of signifiers? Provide an example.",
        "answer": "Signifier is an indication that communicates how to use the object.\n" +
            "\n" +
            "Examples: home icons indicates you can go back to the home page, google assistant’s wave icon indicates that the user is speaking.",
        "topic": "Usability Principles",
        "toLink": "Usability Principles"
    },
    {
        "id": 2,
        "question": "What is the definition of mapping? Provide an example.",
        "answer": "Mapping is the relationship between the control and the action or result.\n" +
            "\n" +
            "Example: slider which increases in value when sliding right, stovetop where the knobs are mapped in the same order as the stovetops themselves",
        "topic": "Usability Principles",
        "toLink": "Usability Principles"
    },
    {
        "id": 3,
        "question": "What is the definition of feedback? Provide an example.",
        "answer": "Feedback is the information sent back to the user about what action they had taken or accomplished.\n" +
            "\n" +
            "Examples: state changes in button when button is clicked or hovered over, loading cursors",
        "topic": "Usability Principles",
        "toLink": "Usability Principles"
    },
    {
        "id": 4,
        "question": "What is the system image?",
        "answer": "The system image is the information conveyed by the product itself.",
        "topic": "Usability Principles",
        "toLink": "Usability Principles"
    },
    {
        "id": 5,
        "question": "What is the Gulf of Evaluation and Execution?",
        "answer": "The gulf of evaluation revolves around if the actions were performed correctly, and it is the expectations and the availability of the information specifying the state. The gulf of execution revolves around the difference between the user’s perceived execution actions and the required actions.",
        "topic": "Usability Principles",
        "toLink": "Usability Principles"
    },
    {
        "id": 1,
        "question": "What is ethics?",
        "answer": "Ethics are principles of right and wrong that individuals can use to guide their behaviours. It is also a process that guides them in making decisions in a situation with conflicting goals.",
        "topic": "Ethics",
        "toLink": "Ethics"
    },
    {
        "id": 2,
        "question": "Why is ethics important?",
        "answer": "Ethics is important because human behaviour is examined, personal information is collected and the results of human behaviour is examined.",
        "topic": "Ethics",
        "toLink": "Ethics"
    },
    {
        "id": 3,
        "question": "What are the main ethical principles?",
        "answer": "The first main ethical principle is Respect for Persons. We should protest the freedom and capacity of participants, protect the vulnerable and obtain informed consent from participants. Secondly, beneficence. We should secure the well-being of participants and minimise all possible harm. Third, justice. We should ensure fairness in the distribution of risks and benefits across participants.",
        "topic": "Ethics",
        "toLink": "Ethics"
    },
    {
        "id": 4,
        "question": "Give one example for each ethical responsibility during a user study.",
        "answer": "For safety, ensure that the setting is in a quiet environment with no one else around to ensure that it is a safe space for the participant to share their thoughts.\n" +
            "\n" +
            "For privacy, make sure all information is encrypted and no one can have unauthorised access to them. Make sure their face or name is not in the video.\n" +
            "\n" +
            "For informed consent, before starting the study, inform the participant that a recording will be done and get their consent for it.",
        "topic": "Ethics",
        "toLink": "Ethics"
    },
    {
        "id": 5,
        "question": "What are the candidate ethical principles?",
        "answer": "Golden Rule\n" +
            "Do unto others as you would have them do unto you\n" +
            "Kant’s Categorical Imperative\n" +
            "If an action is not right for everyone to take, no one should take it.\n" +
            "Descarte’s Rule of Change\n" +
            "If an action cannot be taken repeatedly, it is not right to take at all.\n" +
            "Utilitarianism\n" +
            "Take the action that achieves the greatest benefit.\n" +
            "Risk Aversion Principle\n" +
            "Take the action that produces the least harm.\n" +
            "Ethical “No Free Lunch” Rule\n" +
            "Assume virtually all tangible or non-tangible objects are owned by someone unless there is a specific declaration otherwise.",
        "topic": "Ethics",
        "toLink": "Ethics"
    },
    {
        "id": 1,
        "question": "What is a storyboard? What is a prototype? Explain the differences between a storyboard and a prototype.",
        "answer": "A storyboard is sequence of diagrams or drawings to convey task flow or use case of how a product can be used.\n" +
            "\n" +
            "Prototype is rapid approximation of a design idea and a limited representation of product design.\n" +
            "\n" +
            "A prototype is mainly to encourage interactive exploration of the designed product, inspire innovation and test feasibility/compatibility, illustrate the actual design idea, look & feel whereas the storyboard is mainly to allow stakeholders to get a clear view of the goal, gather early feedback and understand the flow and how or why the product should be used (such as the scenarios).",
        "topic": "Prototyping",
        "toLink": "Prototyping"
    },
    {
        "id": 2,
        "question": "What are the benefits and disadvantages of a video prototype?",
        "answer": "Benefits:\n" +
            "1. Ties interface design to task\n" +
            "2. Cheap and fast\n" +
            "\n" +
            "Disadvantages:\n" +
            "1. Limited flexibility\n" +
            "2. Lack of interactivity",
        "topic": "Prototyping",
        "toLink": "Prototyping"
    },
    {
        "id": 3,
        "question": "Why should prototypes be tested on users? At which stage of the UCD process should each fidelity of prototype be used? Why?",
        "answer": "Prototypes is used on users to gather early feedback which can be then used to further refine the prototype with rapid iteration.\n" +
            "\n" +
            "Lo-fi (e.g. Paper): Design Stage\n" +
            "This is mostly used to gather heuristic feedback.\n" +
            "\n" +
            "Hi-fi (e.g. Figma): Develop Stage\n" +
            "This is mostly used to get pre-product release heuristic feedback.",
        "topic": "Prototyping",
        "toLink": "Prototyping"
    },
    {
        "id": 4,
        "question": "What is a horizontal prototype? How is it different from a vertical prototype?",
        "answer": "A horizontal prototype is one that has many features but not much details involved in each of those features. It is different from a vertical design in the sense that a vertical design would have more details but lesser features.",
        "topic": "Prototyping",
        "toLink": "Prototyping"
    },
    {
        "id": 5,
        "question": "What are the pros and cons of using a Wizard of Oz prototype?",
        "answer": "A Wizard of Oz prototype simulates machine behaviour with human operators. The user thinks they are interacting with a computer, but a human is responding to the input. Usually consists of front end only. It is done early in the design to understand user requirements.\n" +
            "\n" +
            "Pros:\n" +
            "1. More “realistic” than paper prototype\n" +
            "2. Place user in the centre of development\n" +
            "3. Faster, cheaper, more iterative prototypes\n" +
            "\n" +
            "Cons:\n" +
            "1. May misrepresent otherwise imperfect technology\n" +
            "2. Need effort in training wizard",
        "topic": "Prototyping",
        "toLink": "Prototyping"
    },
    {
        "id": 1,
        "question": "Which of the following is not a true characteristic about meroblastic cleavage?",
        "answer": "Meroblastic cleavage is partial cleavage in zygotes with a high concentration of yolk. In developing embryos that undergo this process, cell divisions do not penetrate the yolk. Two types of meroblastic cleavage are discoidal and superficial cleavage. This process occurs in insects, some fish, and some types of birds.",
        "suggested_answer": "Cleavage planes extend all the way across the zygote",
        "options": [
            "It is partial cleavage",
            "It is found in insects",
            "Cleavage planes extend all the way across the zygote",
            "It is found in developing embryos with a high concentration of yolk"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 2,
        "question": "Animals are an example of which of the following categories of organisms?",
        "answer": "Animals are a type of heterotrophs. Heterotrophs are unable to make their own organic materials and so they must consume other organic materials in the form of autotrophs and other heterotrophs.",
        "suggested_answer": "Heterotrophs",
        "options": [
            "Autotrophs",
            "Biosphere producers",
            "Photoautotrophs",
            "Heterotrophs"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 3,
        "question": "Where do motor outputs from the brain exit the spinal cord?",
        "answer": "The ventral root of the spinal cord is located anteriorly, while the dorsal root is located posteriorly. Afferent neurons enter the spinal cord through the dorsal root, carrying signals from the body to the brain. Efferent neurons exit the spinal cord from the ventral root before interfacing with their target muscles.",
        "suggested_answer": "Ventral root",
        "options": [
            "Posterior root",
            "Anterior root",
            "Intervertebral foramen",
            "Ventral root",
            "Dorsal root"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 4,
        "question": "Which is true of an exergonic reaction?",
        "answer": "Exergonic reactions are reactions that release energy. Endergonic reactions are reactions that require energy to proceed. Exergonic reactions can occur spontaneously, or are thermodynamically favorable. Endergonic reactions cannot occur spontaneously, or are thermodynamically unfavorable. ",
        "suggested_answer": "Energy is released",
        "options": [
            "Energy is released",
            "Energy is required",
            "The reaction will not occur spontaneously",
            "The reaction will always be endothermic"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 5,
        "question": "In the stomach, the __________ cell secretes __________.",
        "answer": "The chief cells of the stomach secrete the proteolytic zymogen pepsinogen. This zymogen then becomes activated as pepsin in the stomach.",
        "suggested_answer": "chief cell . . . pepsinogen",
        "options": [
            "chief cell . . . pepsin",
            "parietal cell . . . hydrochloric acid",
            "parietal cell . . . pepsinogen",
            "chief cell . . . hydrochloric acid",
            "chief cell . . . pepsinogen"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 6,
        "question": "Acetyl-CoA enters the Krebs cycle and reacts with oxaloacetate to form what six-carbon molecule?",
        "answer": "The correct answer to this question is citrate.",
        "suggested_answer": "Citrate",
        "options": [
            "Glycinate ",
            "Citrate",
            "Malate",
            "Oxalate",
            "Nitrate"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 7,
        "question": "Which of the following enzymes does NOT directly contribute to protein digestion?",
        "answer": "Gastrin is secreted in the stomach, and stimulates the secretion of hydrochloric acid by parietal cells. The other three enzymes directly aid in the digestion of proteins. Trypsin and chymotrypsin are released from the pancreas into the duodenum, and pepsin digests proteins located in the stomach. Each of these has different cleavange targets within protein sequences and can be used as a restriction enzyme in experimental procedures.",
        "suggested_answer": "Gastrin",
        "options": [
            "Pepsin",
            "Gastrin",
            "Trypsin",
            "Chymotrypsin"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 8,
        "question": "What are the reactants of enzyme-catalyzed reactions known as?",
        "answer": "The reactants of enzyme-catalyzed reactions are known as substrates. Enzymes catalyze formation of products from their substrates. Inhibitors hinder the efficacy of enzymes, thereby decreasing the rate of reactions, and nucleotides are the monomers of DNA and RNA.",
        "suggested_answer": "Substrates",
        "options": [
            "Inhibitors",
            "Products",
            "Enzymes",
            "Substrates"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 9,
        "question": "Which of the following processes occurs during anaphase of mitosis?",
        "answer": "During metaphase, chromosomes line up along the center of the cell. At this point, each chromosome contains two sister chromatids.",
        "suggested_answer": "The cell briefly contains 92 chromosomes",
        "options": [
            "The cell briefly contains 92 chromosomes",
            "Chromosomes condense",
            "DNA is synthesized",
            "Centrioles separate",
            "Chromosomes decondense"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 10,
        "question": "Which structure separates the esophagus from the mouth?",
        "answer": "After entering the mouth, food is transferred to the pharynx before entering the esophagus. The pharynx is located immediately above the epiglottis, meaning that both food and air can enter the pharynx. The epiglottis covers the trachea to prevent food from entering, and forces food to travel down the esophagus instead. After food travels through the esophagus it enters the stomach. The pyloric sphincter then separates the stomach from the small intestine.",
        "suggested_answer": "Pharynx",
        "options": [
            "Stomach",
            "Pharynx",
            "Trachea",
            "Nasal sinus",
            "Pyloric sphincter"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 11,
        "question": "Which of the following steps is irreversible in the cell cycle?",
        "answer": "A cell cycle has three crucial checkpoints. The checkpoints function to verify cellular processes and materials that are required for subsequent steps in the cell cycle.",
        "suggested_answer": "II and III",
        "options": [
            "II only",
            "I and II",
            "II and III",
            "I only"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 12,
        "question": "Which of the following correctly describes the relationship between an antigen and an antibody?",
        "answer": "Antibodies are continuously made in the body in different shapes and forms. They are then sent into the blood stream to test for the presence of compatible antigens. Each antibody can only bind to one antigen, and each antigen can only bind to one antibody. Think of them like a codon-anticodon pair; there is only one possibility for them to form a perfectly complementary pair. Once the correct antibody binds to an antigen, they are tagged and used to stimulate production of more antibodies. The antibodies are only capable of binding and tagging the antigens. Cytotoxic T-cells are then able to recognize antibody binding patterns and actually destroy the infected cell.",
        "suggested_answer": "The antigen and antibody are perfectly complimentary to one another",
        "options": [
            "The antigen and antibody are perfectly complimentary to one another",
            "Antibodies attack antigens and neutralize their effects",
            "Each antigen has a number of different antibodies that will bind to it",
            "Antibodies are only made once antigens enter the body",
            "Each antibody recognizes a variety of different antigens"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 13,
        "question": "Which of the following biological molecules is most similar to proteins?",
        "answer": "Proteins are organic molecules made of amino acids that are capable of interfacing with certain substrates and facilitating cellular activities. Enzymes are a sub-group of proteins that are used to speed up reactions within the body. Enzymatic proteins are essential to many biological and cellular processes, such as cellular respiration, transcription, and DNA replication.",
        "suggested_answer": "Enzymes",
        "options": [
            "Triglycerides (fats)",
            "Carbohydrates",
            "Enzymes",
            "DNA",
            "RNA"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 14,
        "question": "What is the primary enzyme involved in DNA replication?",
        "answer": "DNA polymerase is the primary enzyme involved in DNA replication. It is responsible for adding nucleotides to the growing DNA chain in the 5' to 3' direction. This enzyme also has proofreading functionality, which allows it to remove nucleotides that are mispaired in the 3' to 5' direction and replace them with the correct nucleotide.",
        "suggested_answer": "DNA polymerase",
        "options": [
            "ATP synthase",
            "RNA polymerase",
            "Transciptase",
            "DNA polymerase"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 15,
        "question": "What are the two primary solutes that affect and produce osmolarity gradients within the nephron?",
        "answer": "One of the key adaptations of the mammalian kidney is the ability to conserve water through reabsorption and excretion of concentrated urine. This is accomplished by maintenance of an osmolarity gradient, suitable for extracting water from the filtrate. The two primary solutes are sodium, which is deposited in the renal medulla by the loop of Henle, and urea, which crosses the epithelium of the collecting duct in the inner medulla. The increased osmolarity of the interstitial fluid enables water to be extracted and conserved through aquaporin proteins in the collecting duct.",
        "suggested_answer": "Sodium and urea",
        "options": [
            "Bicarbonate and glucose",
            "Potassium and water",
            "Sodium and urea",
            "Ammonia and sodium"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 16,
        "question": "Which of the following choices is an element common to both DNA and RNA molecules?",
        "answer": "DNA is a macromolecule that has a double helical form. It has four nitrogenous base pairs (thymine, adenine, cytosine, and guanine) and a deoxyribose-phosphate backbone. The two strands of the helix are joined by hydrogen bonds. On the other hand, RNA molecules can be double-stranded (e.g. viruses) or single-stranded (e.g. mRNA), but RNA most commonly exists in a single-stranded form. RNA contains four base pairs (uracil, adenine, cytosine, and guanine) and a ribose sugar-phosphate backbone. Despite their differences, these molecules share one similarity: both DNA and RNA molecules can be found in the nucleus. DNA molecules reside solely within the nucleus. RNA is transcribed in the nucleus and, once targeted and post-transcriptionally modified, can leave via nuclear pores.",
        "suggested_answer": "They can both be found in the nucleus",
        "options": [
            "Both contain the nucleotide uracil",
            "Both are often single-stranded",
            "They can both be found in the nucleus",
            "There is deoxyribose sugar in both of their sugar-phosphate backbones"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 17,
        "question": "Which type of ligand is water soluble, and thus unable to enter a cell?",
        "answer": " The membrane of the cell is a phospholipid bilayer, which allows hydrophobic molecules to diffuse through it. Small, hydrophobic ligands are able to diffuse through the plasma membrane. Nitric oxide is lipophilic, readily dissolving in lipids, and can diffuse across the plasma membrane. Steroid hormones are hydrophobic, and can thus diffuse across the plasma membrane. Water-soluble ligands cannot diffuse across the plasma membrane to enter a cell.",
        "suggested_answer": "Water-soluble ligands",
        "options": [
            "steroid hormones",
            "Small, hydrophobic ligands",
            "Water-soluble ligands",
            "Nitric oxide"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 18,
        "question": "What is true about saltatory conduction?",
        "answer": "Saltatory conduction is a type of signal transmission in myelinated axons. Here, the action potential (wave of sodium ions) passes from one node of Ranvier to another, which increases the rate of transmission.",
        "suggested_answer": "All of these",
        "options": [
            "The signal is passed from one node of Ranvier to another",
            "It is the method of action potential transmission in myelinated axons",
            "It increases the rate of signal transmission ",
            "All of these"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 19,
        "question": "Which of the following glands contribute to the production of semen?",
        "answer": "The seminal vesicles secrete seminal fluid to promote and nourish sperm as they are released. The bulbourethral glands secrete a component of seminal fluid, while the prostate gland secretes alkaline fluid.",
        "suggested_answer": "All of the other choices are correct",
        "options": [
            "Bulbourethral glands",
            "Prostate gland",
            "Seminal vesicles",
            "All of the other choices are correct"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 20,
        "question": "In cellular respiration, in what organelle does the Krebs cycle take place?",
        "answer": "The Krebs cycle takes place in the mitochondria. The Golgi apparatus packages substances in order for them to be sent to their appropriate locations, and the chloroplast is where photosynthesis takes place. The nucleus contains DNA and is the site of transcription.",
        "suggested_answer": "Mitochondria",
        "options": [
            "Nucleus",
            "Chloroplast",
            "Mitochondria",
            "Golgi apparatus"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 21,
        "question": "The myelination in the peripheral nervous system is a achieved by:",
        "answer": "Myelination in the central nervous system is produced by oligodendrites. Whereas, myelination in the peripheral nervous system is produced by Schwann cells.",
        "suggested_answer": "Schwann cells",
        "options": [
            "Glial cells",
            "Schwann cells",
            "Oligodendrocytes",
            "The axon itself"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 22,
        "question": "Which division of the nervous system only innervates skeletal muscle?",
        "answer": "The nervous system has two principle divisions for function and two principle divisions for structure. Structurally, there are the central and peripheral nervous system divisions. Functionally, there are the somatic and autonomic divisions.",
        "suggested_answer": "Somatic nervous system",
        "options": [
            "Sympathetic nervous system",
            "Parasympathetic nervous system",
            "Somatic nervous system",
            "Peripheral nervous system"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 23,
        "question": "Which of the following best describes when a pregnancy is considered to be full term?",
        "answer": "A woman is said to be full term when she is at 39 to 41 weeks of pregnancy. Medical intervention to induce labor during this time is not ideal.",
        "suggested_answer": "39 to 41 weeks",
        "options": [
            "39 to 41 weeks",
            "37 to 38 weeks",
            "30 to 31 weeks",
            "33 to 35 weeks"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 24,
        "question": "Which is not a possible critical value of a chi square test?",
        "answer": "Critical values cannot be negative. The chi squared formula will not result in a negative value as the numerator is squared. ",
        "suggested_answer": "-1.8",
        "options": [
            "1.8",
            "180",
            "0",
            "-1.8"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 25,
        "question": "Which is not one of the kingdoms?",
        "answer": "There are eight taxonomic categories (Domain-> Kingdom-> Phylum-> Class-> Order-> Family-> Genus-> Species) with domain being most inclusive and each subsequent category becoming more specific. Bacteria are not a kingdom; bacteria are one of the three domains. Kingdom includes fungi, prokaryotes, protista, plantae, and animalia. ",
        "suggested_answer": "bacteria",
        "options": [
            "protista",
            "bacteria",
            "plantae",
            "fungi"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 26,
        "question": "Which of the following describes a covalent bond?",
        "answer": "There are two primary types of intramolecular bonds: ionic bonds and covalent bonds. In an ionic bond, an electron is transferred (donated) from one atom to the other, usually allowing both atoms to satisfy the octet rule. In a covalent bond, electrons are shared between two atoms in order to allow both to satisfy the octet rule. A covalent single bond consists of two shared electrons, while a double bond will consist of four and a triple bond will consist of six.",
        "suggested_answer": "Two or more electrons are shared between two atoms",
        "options": [
            "An electron is donated from one atom to another",
            "Two or more electrons are shared between two atoms",
            "An electron is shared between two atoms",
            "A proton is shared between two atoms",
            "A proton is donated from one atom to another"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 27,
        "question": "What section of the nephron is primarily responsible for concentrating the urine?",
        "answer": "The filtrate is processed as it moves throughout the nephron prior to entering the ureters to be expelled. The glomerulus is a capillary bed that deposits filtrate from the blood into the nephron. The proximal tubule does not alter the osmolarity of the primary filtrate. The loop of Henle then concentrates the urine, only to have the concentration lowered again in the distal convoluted tubule.",
        "suggested_answer": "Loop of Henle",
        "options": [
            "Distal convoluted tubule",
            "Proximal convoluted tubule",
            "Glomerulus",
            "Loop of Henle"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 28,
        "question": "Maternal immunity to some antigens may be conveyed in-utero. This is an example of which type of immunity?",
        "answer": "Natural passive immunity is conveyed from mother to child in-utero or through colostrum in breast milk. Natural passive immunity provides temporary immunity to many diseases.",
        "suggested_answer": "Natural passive immunity",
        "options": [
            "Immunity to viral infections",
            "Natural passive immunity",
            "Artificial active immunity",
            "Natural active immunity",
            "Artificial passive immunity"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 29,
        "question": "Who of the following scientists would postulate that giraffes have long necks because they wanted to eat the leaves of tall trees?",
        "answer": "Lamarck theorized that animals evolved into new forms based on need or utility, and thus he is the correct answer for this question.",
        "suggested_answer": "Lamarck",
        "options": [
            "Salk",
            "Lamarck",
            "Darwin",
            "Watson & Crick",
            "Leeuwenhoek "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 30,
        "question": "Which of the following best describes the role of epithelial tissue in the human body?",
        "answer": "Epithelial tissue lines the blood vessels, organs, and cavities in the human body. The primary function of epithelial tissue is to regulate secretion, absorption, and transport across surfaces. Epithelial tissue additionally plays a role in protection and detection of signals; therefore, the role of the epithelial tissue is to act as a regulatory barrier.",
        "suggested_answer": "Act as a regulatory barrier between two locations in the body",
        "options": [
            "Act as a regulatory barrier between two locations in the body",
            "Transmit chemical and electrical signals",
            "Contract to create movement ",
            " ",
            "Support and connect different types of tissues and organs"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 31,
        "question": "Which of the following is not found in nucleotides?",
        "answer": "Nucleotides are the monomers that make up nucleic acids. They are composed of a five-carbon sugar, a nitrogenous base, and a phosphate group. In building the polymer nucleic acid chain, the sugar and phosphate of one nucleotide align with those of another to build the phosphate-sugar backbone, while the nitrogenous bases will form hydrogen bonds across the helix to link two chains of nucleotides together. Phosphate groups carry negative charge; this gives the cell nucleus an overall negative charge and can be used to generate electrochemical gradients across the nuclear membrane.",
        "suggested_answer": "A carboxylic acid",
        "options": [
            "A five-carbon sugar",
            "A nitrogenous base",
            "A region of negative charge",
            "A carboxylic acid",
            "A phosphate group"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 32,
        "question": "Which of the following would be considered as a secondary producer?",
        "answer": "Secondary production is defined as the generation of biomass by heterotrophs, mainly through the transfer of organic compounds among trophic levels. Organisms that are primarily responsible for secondary production include animals, protists, and fungi.",
        "suggested_answer": "Antelope",
        "options": [
            "Kelp",
            "Cacti",
            "Antelope",
            "Flowering plant"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 33,
        "question": "Imagine that a population is in Hardy-Weinberg equilibrium. A certain gene presents as two different alleles, and 49% of the population is homozygous dominant.",
        "answer": "When a population is in Hardy-Weinberg equilibrium, we can quantitatively determine how the alleles are distributed in the population. P2 is equal to the proprtion of the population that is homozygous dominant based on the equation p2 + 2pq + q2 = 1. We also know that p + q = 1.",
        "suggested_answer": "9%",
        "options": [
            "51%",
            "42%",
            "9%",
            "Further information is needed to solve the problem"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 34,
        "question": "In which of the following structures of the human body can transitional stratified epithelia be found?",
        "answer": "Transitional epithelia are specialized stratified epithelial cell layers that are present in tissues that need to stretch; therefore, transitional epithelia are located in the urethra, ureter, and bladder.",
        "suggested_answer": "Skin",
        "options": [
            "Ureter",
            "Skin",
            "Urethra",
            "Bladder"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 35,
        "question": "Which of these statements is FALSE concerning mitosis and meiosis?",
        "answer": "Mitosis and meiosis share many procedural similarities, however, it is important to remember that mitosis makes identical cells while meiosis allows genetic variability between cells.",
        "suggested_answer": "Both of these processes allow genetic variation.",
        "options": [
            "Both use the same order of events: prophase, metaphase, anaphase, and telophase.",
            "Both processes require DNA synthesis beforehand.",
            "Both of these processes allow genetic variation.",
            "Meiosis results in a halving of chromosomes in the daughter cells, while mitosis reserves the same amount of genetic material in the daughter cells."
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 36,
        "question": "Amylase is a digestive enzyme responsible for breaking down which macromolecules found in food?",
        "answer": "The correct answer is carbohydrates because amylase is the enzyme that is able to break these macromolecules down into smaller parts. There are two kinds of amylase found in the body, salivary amylase in the mouth and pancreatic amylase in the pancreas. Both of these are involved in the breakdown of carbohydrates in digestion.",
        "suggested_answer": "Carbohydrates",
        "options": [
            "Proteins",
            "Carbohydrates",
            "None of these",
            "Nucleic acids",
            "Lipids"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 37,
        "question": "Which of the following is true about fungi?",
        "answer": "Hyphae is a branching filamentous structure found in multicellular fungi, and forms into a mass of hyphae called a mycelium. Hyphae can grow rapidly throughout the life of the fungus, and allows a greater absorption of nutrients. ",
        "suggested_answer": "Hyphae is a filamentous network that forms into a mycelium",
        "options": [
            "Hyphae is found only found in mycorrhizal fungi",
            "None of the choices are true",
            "Hyphae is a filamentous network that forms into a mycelium",
            "All of the choices are true",
            "Once hyphae is formed after spore germination, it stops development and begins to absorb nutrients"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 38,
        "question": "In hybrid sterility, at what stage does reproductive isolation occur?",
        "answer": "Hybrid sterility is an example of post-zygotic reproductive isolation. In the case of hybrid sterility, hybridization can occur between two species, however the resulting hybrids are unable to produce; the hybrids are sterile. ",
        "suggested_answer": "Post-zygotic",
        "options": [
            "Behavioral",
            "Post-zygotic",
            "Pre-zygotic",
            "Temporal"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 39,
        "question": "Which of the following is NOT a reason that Gregor Mendel chose to work with pea plants?",
        "answer": "Pea plants reproduce quickly and in large numbers. They can self-pollinate within a single plant, they can be cross-pollinated by insection, and they can be selectively cross-pollinated using a tool such as a cotton swab. Pea plants contain separate male and female parts, but each plant contains both. Sex-linked traits cannot be studied in organisms that do not have clearly separate male and female members. Phenotypically, there are no male and female members of the pea plant species, making it impossible to track traits that follow sex-linked expression.",
        "suggested_answer": "Pea plants can be used to study both somatic and sex-linked traits",
        "options": [
            "Pea plants can be selectively cross-pollinated",
            "Pea plants produce a large number of offspring per generation",
            "Pea plants have a relatively short intergenerational time",
            "Pea plants can be used to study both somatic and sex-linked traits"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 40,
        "question": "Which of the given disorders follows classical Mendelian inheritance?",
        "answer": "Cystic fibrosis occurs in individuals who are homozygous recessive for a single gene, following Mendelian inheritance patterns.",
        "suggested_answer": "Cystic fibrosis",
        "options": [
            "Cystic fibrosis",
            "Type I diabetes",
            "Down Syndrome",
            "Fragile X Syndrome"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 41,
        "question": "Horizontal gene transfer is an example of what mechanism of evolution?",
        "answer": "Horizontal gene transfer is a method of transferring genetic material from one organism to another and is common among bacteria. Horizontal gene transfer is an example of gene flow, a mechanism of evolution that involves the flow of genes among populations, species, and organisms. Natural selection involves uneven survival and evolutionary fitness due to the advantages granted to certain individuals through genetic variation. A mutation is an unplanned change in the DNA sequence of a cell or organism. Genetic drift is the change in an allele frequency in a population due to random sampling.",
        "suggested_answer": "Gene flow",
        "options": [
            "Natural selection",
            "Gene flow",
            "Genetic drift",
            "Mutation"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 42,
        "question": "During which of the following mitotic phases will a cell be haploid?",
        "answer": "Remember that a cell is defined as diploid if it possesses pairs of homologous chromosomes. During mitosis the cell always possesses homologous chromosomes. The segregation of chromosomes only involves the separation of sister chromatids to opposite sides of the cell, not the homologous chromosomes. The two daughter cells produced contain the same homologous pairs of chromosomes as the parent cell. A cell is therefore always diploid during mitosis and cytokinesis.",
        "suggested_answer": "None of these",
        "options": [
            "Metaphase",
            "Prophase",
            "None of these",
            "Anaphase"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 43,
        "question": " All of the following are known to be components of cell walls except which one?",
        "answer": "Actin is what makes up microfilaments. Chitin is indeed in cell walls of fungi. Cellulose is the main ingredient to plant cell walls and peptidoglycan are found in the cell walls of cyanobacteria and bacteria. Also, cellulose is made of polysaccharides, therefore, this is also an incorrect answer because it is a component of the cell wall.",
        "suggested_answer": "actin",
        "options": [
            "chitin",
            "actin",
            "cellulose",
            "peptidoglycans",
            "polysaccharides"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 44,
        "question": "Which of the following can be found in prokaryotes, but never in viruses?",
        "answer": "Prokaryotes create their own proteins, which means that ribosomes are present in the cell. Viruses, however, rely on the ribosomes of the host cell in order to create proteins. This means that ribosomes are not present in viruses, but are present in prokaryotes.",
        "suggested_answer": "Ribosomes",
        "options": [
            "Attachment proteins",
            "Ribosomes",
            "RNA",
            "DNA"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 45,
        "question": "In mitosis, which is the first phase in which chromatin coils and forms chromosomes and the nuclear membrane breaks down?",
        "answer": "Prophase is the first phase of mitosis in which chromatin coils and forms chromosomes, the spindle apparatus begins to form, and the nuclear membrane breaks down. The second, third, and fourth phases are metaphase, anaphase, and telophase, respectively.",
        "suggested_answer": "Prophase",
        "options": [
            "Prophase",
            "Metaphase",
            "Anaphase",
            "Telophase"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 46,
        "question": "Which of the following refers to the principle of alternation of generations in plants?",
        "answer": "Plants have a multicellular haploid stage called the gametophyte. Gametophytes () produce gametes () through mitosis, which combine to produce a zygote (). The zygote grows into a multicellular, diploid sporophyte (), which produces spores () through meiosis. Those spores give rise to multicellular gametophytes.",
        "suggested_answer": "A life cycle that includes a multicellular haploid stage",
        "options": [
            "The need for external pollinators",
            "The differences between a tree and a seed",
            "A life cycle that includes a multicellular haploid stage",
            "A life cycle which includes a unicellular haploid stage",
            "Gamete-producing sporophytes"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 47,
        "question": "Reverse transcriptase is __________.",
        "answer": "Reverse transcriptase is a viral enzyme used by some viruses to transcribe double-stranded DNA from their single-stranded RNA, which is the exact opposite of the normal transcription process of the cell. The double-stranded viral DNA is then able to integrate into the double-stranded DNA of the infected cell\u2019s genome so that whenever the cell divides, all of its daughter cells will carry the viral DNA. This viral DNA can eventually be transcribed by the cell\u2019s own enzymes to produce viral RNA. Some of the viral RNA will be translated into viral proteins, and new viruses will be assembled from these products.",
        "suggested_answer": "produced by viruses and used by the cell to transcribe viral DNA that will be inserted into the genome of the infected cell",
        "options": [
            "produced by viruses and used by the infected cell to transcribe viral RNAs that will be translated by the cell into viral proteins",
            "produced by viruses and is used to inhibit normal DNA transcription",
            "produced by viruses and used by the cell to transcribe viral DNA that will be inserted into the genome of the infected cell",
            "produced by the infected cell and used to transcribe RNAs that will be translated by the cell into antibodies against viruses",
            "produced by the infected cell and used to inhibit viral transcription"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 48,
        "question": "Where does glycolysis take place?",
        "answer": "Glycolysis is the first step of cellular respiration where glucose is broken down down into pyruvate. It occurs in the cytosol. In the presence of oxygen, pyruvate is exported to the mitochondria where it is further oxidized. In the absence of oxygen, pyruvate is converted into ethyl alcohol or lactic acid (fermentation) in the cytoplasm.",
        "suggested_answer": "Cytosol",
        "options": [
            "Cytosol",
            "Inner mitochondrial membrane",
            "Smooth endoplasmic reticulum",
            "Rough endoplasmic retculum",
            "Mitochondrial matrix"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 49,
        "question": "Which of the following proteins is essential for transcription?",
        "answer": "RNA polymerase is the protein that synthesizes an RNA complement to a gene. RNA polymerase binds the template DNA strand and recruits ribonucleotides to build a strand of heteronuclear RNA (htRNA). The htRNA then undergoes modification to become mature mRNA before exiting the nucleus into the cytosol for translation.",
        "suggested_answer": "RNA polymerase",
        "options": [
            "Trypsin",
            "DNA polymerase",
            "RNA polymerase",
            "Primase"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 50,
        "question": "What directionality does the transcribed RNA strand have after transcription?",
        "answer": "The term directionality refers to the chemical orientation of a molecule. In DNA and RNA, directionality is described as 3\u2019 (three prime) or 5\u2019 (five prime). 3\u2019 refers to the third carbon group in a sugar ring, which terminates in a hydroxyl group, and 5\u2019 refers to the fifth carbon in the sugar ring. In transcription, RNA polymerase reads the template DNA strand in a 3\u2019 to 5\u2019 direction. Reading the template DNA strand in this direction allows transcription to proceed without primers and Okazaki fragments. This yields an RNA molecule that is formed in a mirrored 5\u2019 to 3\u2019 direction.",
        "suggested_answer": "5' to 3'",
        "options": [
            "3' to 5'",
            "7' to 3'",
            "3' to 7'",
            "5' to 3'"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 51,
        "question": "How does a reducing agent function in cellular respiration?",
        "answer": "A reducing agent works as an electron donor, so it is ultimately giving away electrons to another molecule and ultimately making that other molecule more negative in charge, or reduced in charge. Note that a reducing agent is itself oxidized. Also recall the mnemonic OIL RIG (Oxidation Is Loss of electrons, Reduction Is Gain of electrons).",
        "suggested_answer": "Giving electrons as an electron donor",
        "options": [
            "Giving electrons as an electron receptor",
            "Taking electrons as an electron receptor",
            "Giving electrons as an electron donor",
            "None, reducing agents are not used in the cellular respiration pathway",
            "Taking electrons as an electron donor"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 52,
        "question": "Which of the following organelles is present only in animal cells?",
        "answer": "Lysosomes are organelles responsible for programmed cell death and the site of intracellular digestion. They do not occur in plant cells. Chloroplasts occur only in plant cells. Peroxisomes, mitochondria, and golgi apparatus are found in all eukaryotic cells, which includes plants.",
        "suggested_answer": "Lysosome",
        "options": [
            "Golgi apparatus",
            "Lysosome",
            "Peroxisome",
            "Chloroplast",
            "Mitochondrion"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 53,
        "question": "In which of the following cell cycle phases does DNA replication occur?",
        "answer": "The \"S\" phase, known as the synthesis phase, is the portion of the cell cycle where DNA is replicated. The other stages listed do not contain DNA replication. G0 is a cell cycle arrest phase, where a cell remains dormant, awaiting signals to re-enter the cell cycle.",
        "suggested_answer": "S phase",
        "options": [
            "M Phase",
            "G0 phase",
            "S phase",
            "G1 phase",
            "G2 phase"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 54,
        "question": "General nomenclature classifies humans by the name homo sapiens. This means that homo belongs to which level of classification?",
        "answer": "The order of classification from top to bottom is: kingdom, phylum, class, order, family, genus, species. Binomial nomenclature includes the two most specific classifications of an organism. Homo therefore falls into the genus level of classification.",
        "suggested_answer": "Genus",
        "options": [
            "Kingdom",
            "Family",
            "Species",
            "Order",
            "Genus"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 55,
        "question": "Which of the following is correct regarding the given reaction?",
        "answer": "During the redox reaction chlorine becomes the negatively charged chloride ion, so we know that it has gained an electron. This means that it undergone reduction and is an oxidizing agent. Sodium loses an electron since it becomes positively charge, thus we know it has been oxidized and is the reducing agent.",
        "suggested_answer": " is the oxidizing agent",
        "options": [
            " is the oxidizing agent",
            " is the reducing agent",
            " is the oxidizing agent",
            " is undergoing oxidation"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 56,
        "question": "Where does the electron transport chain occur in prokaryotic cells and eukaryotic cells, respectively?",
        "answer": "Prokaryotes, such as bacteria, do not contain membrane-bound organelles such as the nucleus, mitochondria, and chloroplasts that we might find in a eukaryotic cell. Since we know the electron transport chain (ETC) is important in providing cellular energy, prokaryotes must still perform some form of this process. The complexes of the ETC are found in the actual cellular membrane that separates the cell from the environment in prokaryotes, while they are found in the inner mitochondrial membrane in eukaryotes. Eukaryotes are then able to generate a proton gradient between the two mitochondrial membranes within the intermembrane space, while prokaryotes can generate a gradient between the cell membrane and cell wall.",
        "suggested_answer": "The cellular membrane; the mitochondria",
        "options": [
            "The mitochondria; the mitochondria",
            "The mitochondria; the cellular membrane",
            "The chloroplasts; the mitochondria",
            "The chloroplasts; the cellular membrane",
            "The cellular membrane; the mitochondria"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 57,
        "question": "Which of the following choices best describes the impact of an influx of intracellular calcium on sperm cells during capacitation?",
        "answer": "During capacitation, sperm cells undergo biochemical changes that allow for fertilization of the oocyte. One of these changes is an influx of intracellular calcium, which leads to an increase in intracellular cAMP levels. The high levels of calcium and cAMP promote hyperactivation increased motility through deeper tail bends. This change in sperm tail movement is accompanied by the adoption of a swinging movement by the head of the cell. These changes in motility patterns help sperm cell movement within the female reproductive tract.",
        "suggested_answer": "It increases sperm cell motility ",
        "options": [
            "It increases sperm cell motility ",
            "It destabilizes the sperm's plasma membrane",
            "It aids in chemotaxis towards the oocyte",
            "It triggers the acrosomal reaction "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 58,
        "question": "In the process of spermiogenesis, the acrosome is derived from which of the following organelles?",
        "answer": "Spermiogenesis is the final stage of spermatogenesis. In mature spermatozoa, the acrosome is the head of the cell and, in humans, contains digestive enzymes for ovum penetration. The acrosome develops during spermiogenesis when the Golgi apparatus surrounds the nucleus. ",
        "suggested_answer": "Golgi apparatus",
        "options": [
            "Endoplasmic reticulum",
            "Golgi apparatus",
            "Centriole",
            "Cytoplasm"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 59,
        "question": "Which of the following choices describes the effects of the output on signaling pathways in negative feedback systems?",
        "answer": "Negative feedback is one method that organisms use to maintain homeostasis. In these systems, the rate of the process decreases as the amount of output increases. In other words, the output downregulates the process that created it. An example of this is the regulation of blood glucose. When blood glucose levels rise, the pancreas secretes more insulin to convert glucose to glycogen for storage. This lowers the blood glucose level. If the level falls too much, then the pancreas secretes more glucagon to convert glycogen to glucose, which raises blood glucose levels. ",
        "suggested_answer": "Downregulation",
        "options": [
            "Upregulation",
            "Downregulation",
            "None of these",
            "Complete inhibition"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 60,
        "question": "Mitochondria and chloroplasts have their own DNA, which is similar to the DNA of",
        "answer": "Bacteria, a prokaryote, has circular DNA, as do mitochondria and chloroplasts. This provides support for the Endosymbiotic Theory, which states that the mitochondria and chloroplast in eukaryotic cells were once aerobic bacteria (prokaryote) that were ingested by a large anaerobic bacteria (prokaryote).",
        "suggested_answer": "bacteria",
        "options": [
            "protozoa",
            "bacteria",
            "humans",
            "fungi"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 61,
        "question": "Which of the following is composed of a glycerol molecule bound to three fatty acids?",
        "answer": "Lipids are the fat molecules of biology and are frequently composed of fatty acids. Glycerol is a short 3-carbon chain that is often used to bind different fatty acids together and form lipid structures. When glycerol binds to three fatty acid chains, it forms a triglyceride. Triglycerides are used to store energy in the body.",
        "suggested_answer": "Triglyceride",
        "options": [
            "Phosphate",
            "Nucleotide",
            "Carbohydrate",
            "Steroid",
            "Triglyceride"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 62,
        "question": "Which of the following DNA primers has the lowest melting point?",
        "answer": "Cytosine and guanine form three hydrogen bonds with each other, while adenine and tyrosine only form two hydrogen bonds. This means that strands of DNA with a higher percentage of cytosine and guanine will have higher melting points.",
        "suggested_answer": "TGACAAACTTGT",
        "options": [
            "CATGCGTAGATC",
            "GCCACAGGTGGA",
            "AGGTGGACACCG",
            "TGACAAACTTGT",
            "GCGGATGTGTCC"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 63,
        "question": "Lichens are formed by a symbiosis between a fungus and a __________.",
        "answer": "Green algae are the photosynthetic component of the lichen relationship. The fungus aids by gathering nutrients and providing structural protection.",
        "suggested_answer": "green alga",
        "options": [
            "dinoflagellate",
            "green alga",
            "slime mold",
            "zygomycete",
            "moss"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 64,
        "question": "If an animal cell is placed in a solution that is hypotonic to the cell, the cell will __________.",
        "answer": "The cell will take in water and most likely burst, killing the cell. When a cell is placed in a solution hypotonic to it, water will flow from the hypotonic surroundings into the hypertonic cell. This causes the cell to grow with water intake until it bursts like a water balloon.  ",
        "suggested_answer": "gain water, grow in size and likely burst",
        "options": [
            "not change in size",
            "gain water, grow in size and likely burst",
            "lose water, shrink in size and likely die",
            "Cannot be determined without knowing the type of solute in the surrounding solution"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 65,
        "question": "Which of the following are true regarding animal cells?",
        "answer": "Some of the main differences between plant and animal cells are the presence of a cell wall in plant cells, which maintains a regular and rigid cell shape, and chloroplasts, which are the sites of photosynthesis in plant cells. By contrast, animal cells do not have a cell wall, allowing their cell shape to be more irregular and more dependent on cytoskeletal filaments. Animal cells do not perform photosynthesis and therefore do not have chloroplasts. Some, not all, animal cells contain many small vacuoles. ",
        "suggested_answer": "They have many small vacuoles",
        "options": [
            "The have a fixed cell shape",
            "The have chloroplasts",
            "They have many small vacuoles",
            "They have cell walls"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 66,
        "question": "During the pyruvate decarboxylation reaction, acetyl CoA is produced through which type of bond linking an acetyl group to coenzyme A?",
        "answer": "During the pyruvate decarboxylation reaction , a thioester bond links the acetyl group of pyruvate with coenzyme A to produce acetyl CoA.",
        "suggested_answer": "thioester bond",
        "options": [
            "acetylase bond",
            "hydrogen bond",
            "thioester bond",
            "ionic bond"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 67,
        "question": "If polyspermy does occur, which of the following symptoms will the resulting organism exhibit?",
        "answer": "Polyspermy, or the fertilization of an egg by more than one sperm cell, creates an inviable zygote that will not develop.",
        "suggested_answer": "The zygote is inviable",
        "options": [
            "The offspring will develop learning disabilities",
            "The zygote is inviable",
            "The offspring will possess developmental delays",
            "The offspring will experience stunted physical growth "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 68,
        "question": "In the Hardy-Weinberg theorem, refers to what?",
        "answer": "In the Hardy-Weinberg theory, the two equations used are:",
        "suggested_answer": "The frequency of the dominant allele",
        "options": [
            "The frequency of the recessive allele",
            "The frequency of the homozygous recessive genotype",
            "The frequency of the homozygous dominant genotype",
            "The frequency of the dominant allele"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 69,
        "question": "Pollination is a process in which pollen is transferred between which of the following two structures of an angiosperm?",
        "answer": "Pollination is the process in angiosperms through which pollen is transferred from the male anther to the female stigma. Pollination can be either abiotic (i.e. by the wind) or biotic (i.e. by an animal). This process precedes fertilization.",
        "suggested_answer": "From the anther to the stigma",
        "options": [
            "From the stigma to the anther",
            "From the pollen tube to the ovary ",
            "From the anther to the stigma",
            "From the stamen to the ovary "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 70,
        "question": "Which of the following best describes where fertilization takes place in in vitro fertilization (IVF)?",
        "answer": "In vitro fertilization, or IVF, is the fertilization of the oocyte outside of the human body in a culture. In this process, an oocyte is extracted from a female and exposed to ejaculate containing sperm cells. The fertilization, therefore, occurs outside of the female reproductive system. The zygote is then implanted in a female uterus for gestation and development. IVF is a process used in cases of infertility, surrogacy, and other cases of reproductive difficulties.",
        "suggested_answer": "Outside of the human body",
        "options": [
            "Outside of the human body",
            "Inside the uterus",
            "Inside the fallopian tubes",
            "In the ovaries "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 71,
        "question": "Which of the following would be considered a lethal means of controlling bacterial endospores?",
        "answer": "Spores can survive most methods of control and are resistant to cold and heat. Pasteurization is a heating process that destroys some pathogenic microorganisms in foods and beverages, but does not kill spores. Boiling is not considered a reliable lethal control because the destruction of bacterial spores cannot be guaranteed. Cold temperature is a non-lethal means of microbial control that can slow multiplication or induce stasis, but cold is only effective while conditions are maintained.",
        "suggested_answer": "None of these answers",
        "options": [
            "Freezing",
            "Pasteurization",
            "Boiling",
            "None of these answers",
            "All of these answers"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 72,
        "question": "Which of the following best describes the theory behind the evolutionary adaptation of morning sickness in pregnant women?",
        "answer": "Morning sickness is the feeling of nausea and vomiting that occurs in many women during the first trimester of pregnancy. The physiological causes behind morning sickness include low blood sugar, an increased sensitivity to odors, and increased levels of estrogen and gonadotropin-releasing hormone. It is believed that morning sickness evolved in order to prevent the mother from ingesting toxins that could harm the developing fetus. This is supported by the fact that during the time morning sickness occurs, the fetus is highly sensitive to toxins.",
        "suggested_answer": "Prevents mothers from ingesting toxins",
        "options": [
            "Stimulates the pituitary gland",
            "Prevents mothers from ingesting toxins",
            "Exercises abdominal muscles",
            "Prevents the mother from gaining weight"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 73,
        "question": "The smooth endoplasmic reticulum in hepatocytes can expand as a response to which of the following?",
        "answer": "The smooth endoplasmic reticulum contains enzymes responsible for the metabolism of small chemical molecules such as alcohol, pharmaceutical drugs, and environmental contaminants. Expansion of the smooth endoplasmic reticulum can occur under conditions of new and continued exposure to a given chemical or stimulus as an adaptation to metabolize and eliminate the drug or chemical.",
        "suggested_answer": "Consumption of prescribed drugs to treat a health condition",
        "options": [
            "An increase in aerobic exercise",
            "Consumption of prescribed drugs to treat a health condition",
            "A diet that is high in fats",
            "Recovery from a physical injury"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 74,
        "question": "An opportunistic pathogen that is an acidophile would cause the biggest concern if entering the body through which natural barrier?",
        "answer": "The genito-urinary, respiratory, and digestive systems, as well as skin and mucous membranes, are natural physical barriers against infection.",
        "suggested_answer": "The digestive system",
        "options": [
            "The digestive system",
            "Mucous membranes",
            "The genito-urinary system",
            "The respiratory system",
            "The organism would be equally dangerous regardless of the portal of entry"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 75,
        "question": "Which of the following is not one of the four basic components of an amino acid?",
        "answer": "A nitrogenous base is a part of the DNA/RNA structure. They include adenine, guanine, cytosine, thymine, and/or uracil. All other answer choices are parts of amino acids.",
        "suggested_answer": "Nitrogenous base",
        "options": [
            "Basic amino group",
            "Hydrogen atom",
            "Side chain",
            "Acidic carboxyl group",
            "Nitrogenous base"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 76,
        "question": "Which of the following best identifies the correct sequence of phases in the uterine cycle?",
        "answer": "The menstrual cycle is the process of preparing the human female body for potential pregnancy. The cycle can be divided into two stages the ovarian cycle and the uterine cycle. The uterine cycle has three phases that are called menstruation, the proliferative phase, and the secretory phase.",
        "suggested_answer": "Menstruation, proliferative phase, and secretory phase",
        "options": [
            "Proliferative phase, menstruation, and secretory phase",
            "Secretory phase, menstruation, and proliferative phase",
            "Proliferative phase, secretory phase, and menstruation",
            "Menstruation, proliferative phase, and secretory phase"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 77,
        "question": "Which of the following molecules provides the energy for elongation of the polypeptide chain during translation?",
        "answer": "During the elongation stage of translation, GTP is used to provide the energy to translocate a tRNA molecule from the A-site to the P-site. GTP is also required to move the ribosome down the mRNA strand to the next codon.",
        "suggested_answer": "GTP",
        "options": [
            "ADP",
            "ATP",
            "GDP",
            "GTP"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 78,
        "question": "Plant and animal cells mostly differ in which way?",
        "answer": "The correct answer to this question is Only plant cells have cell walls",
        "suggested_answer": "Only plant cells have cell walls",
        "options": [
            "Only plant cells have ribosomes attached to the endoplasmic reticulum",
            "Only plant cells have a flagella",
            "Only plant cells have cell walls",
            "Only plant cells have plasma membranes with cholesterol",
            "Only plant cells have plasma membranes with cholesterol"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 79,
        "question": "Which of the following messengers mediate long-distance cell signaling in animal cells?",
        "answer": "In animal cells, hormones mediate long distance cell signaling. Hormones are chemical messengers secreted by cells that travel through the circulatory system to the target cell receptors. Hormones communicate between diverse cell types and initiate diverse transduction pathways. Hormones are used for long distance cell signaling in both plant and animal cells. ",
        "suggested_answer": "Hormones",
        "options": [
            "Ligands",
            "Vesicles",
            "Hormones",
            "Ions"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 80,
        "question": "Water has a higher boiling point than hydrogen sulfide due to which type of bonding?",
        "answer": "Hydrogen bonding occurs between a hydrogen atom on one molecule and a very electronegative atom namely oxygen, nitrogen, or fluorine on a neighboring molecule. This electrostatic force results in a stronger intermolecular bond than would otherwise be present without the hydrogen bond. A stronger intermolecular bond results in a higher boiling point.",
        "suggested_answer": "Hydrogen bonding",
        "options": [
            "Hydrogen bonding",
            "Ionic bonding",
            "London dispersion forces",
            "Heisenberg bonding",
            "Covalent bonding"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 81,
        "question": "Which of the following best describes the process of nitrogen fixation?",
        "answer": "Nitrogen fixation can be described as the process in the nitrogen cycle in which bacteria fix atmospheric nitrogen into ammonia and usable forms of organic nitrogen.",
        "suggested_answer": "Bacteria fixes atmospheric nitrogen into ammonia",
        "options": [
            "Plants absorb nitrogen from the soil",
            "Nitrates are converted into nitrogen gas by bacteria",
            "Organic nitrogen is converted into ammonia by bacteria",
            "Bacteria fixes atmospheric nitrogen into ammonia"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 82,
        "question": "ATP synthase is found in the region of mitochondria with the highest concentration of __________.",
        "answer": "ATP synthase is an enzyme that facilitates the generation of energy (ATP) in cells. It uses the proton gradient created by the electron transport chain to create ATP through oxidative phosphorylation. ATP synthase is an integral membrane protein in the inner membrane of mitochondria. Recall that all membranes are mostly made up of phospholipids (a type of lipid).",
        "suggested_answer": "lipids",
        "options": [
            "lipids",
            "proteins",
            "nucleic acids",
            "carbohydrates"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 83,
        "question": "Which of the following organ(s) will contain red bone marrow after 25 years old? ",
        "answer": "Continued hematopoiesis only occur in axial bones, the femur (leg) and in the humerus bone (arm). ",
        "suggested_answer": "Femur ",
        "options": [
            "Spleen ",
            "Kidney ",
            "None of these",
            "Femur ",
            "Pelvic Bone"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 84,
        "question": "Presence of nuclear membrane indicates that the cell is in which of the following phases?",
        "answer": "Remember that the nuclear membrane is disassembled during prophase of mitosis. It remains absent through the duration of mitosis until it begins to reassemble during telophase. The nuclear membrane is thus absent during prophase, metaphase, and telophase.",
        "suggested_answer": "Interphase",
        "options": [
            "Prophase",
            "Metaphase",
            "Interphase",
            "Telophase"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 85,
        "question": "Which of the following levels of protein structure is defined as the sequence of amino acids?",
        "answer": "The amino acid sequence is the primary structure of a protein, which is held together by peptide bonds. The secondary structure involves hydrogen bonding between the backbones of amino acids. Tertiary structure describes the unique folding pattern of a polypeptide as a result of intermolecular forces such as hydrogen bonds, hydrophobic interactions and covalent bonds such as disulfide bridges. Tertiary structure is the result of the amino acid side chains interacting with each other. Quaternary structure is the interaction of two or more polypeptide chains with each other.",
        "suggested_answer": "Primary structure",
        "options": [
            "Primary structure",
            "Tertiary structure",
            "Quaternary structure",
            "Secondary structure"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 86,
        "question": "Two populations of the same species over time grow distant from one another. At what point will these two populations be considered different species?",
        "answer": "Speciation is the event that occurs when two populations of a particular species can no longer interbreed. Speciation is not defined by physical barriers or by the time that two populations are separate from one another. In fact, two populations of the same species can be apart any distance or time, and if they can still interbreed they are considered the same species even if they look completely different.",
        "suggested_answer": "When they are no longer able to interbreed",
        "options": [
            "When they are no longer able to interbreed",
            "When there is a physical barrier, such as a river, between the two populations",
            "When the two populations have not been in contact with one another for two hundred years",
            "When the populations begin to eat different foods",
            "When the distance between them exceeds the carrying capacity"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 87,
        "question": "Which cells of the immune system are largely responsible for phagocytosis?",
        "answer": "Macrophages and neutrophils are the major cells of the immune system responsible for phagocytosis. Phagocytosis means ingestion of material. Therefore these cells work as \"garbage collectors\" within the body. Cells destined for destruction are tagged for phagocytosis by special molecules.",
        "suggested_answer": "Macrophages",
        "options": [
            "Antibodies",
            "Macrophages",
            "B cells",
            "T helper cells"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 88,
        "question": "Which of the following best describes cardiac muscle tissue contractions?",
        "answer": "Cardiac muscle tissue is located only in the heart and contractions in this type of tissue are critical in propelling blood through the circulatory system. Contraction is involuntary, rhythmic, and continues through the entire life of a human.",
        "suggested_answer": "All of these",
        "options": [
            "All of these",
            "Contractions are involuntary ",
            "Contractions are rhythmic and continue for the entire life of a human being",
            "Contraction propels blood out of the atria and ventricles"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 89,
        "question": "In order for a solute to pass through a cell membrane via diffusion, it must be all of the following except __________.",
        "answer": "Only small, uncharged molecules that are nonpolar can cross the cell membrane via diffusion. For example, oxygen molecules can easily diffuse across the cell membrane. Ions like potassium and sodium can cross the cell membrane through other mechanisms, such as active transport or through ion channels, but they cannot cross via the mechanism of diffusion.",
        "suggested_answer": "charged",
        "options": [
            "small",
            "nonpolar",
            "lipophilic",
            "charged",
            "hydrophobic"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 90,
        "question": "Which of the following best describes the process of deposition?",
        "answer": "Deposition is the conversion of water from a gaseous to a solid phase; therefore, deposition is the opposite process of sublimation.",
        "suggested_answer": "It is both the conversion of water from a gaseous to a solid phase and it is the opposite of sublimation",
        "options": [
            "It is neither the conversion of water from a gaseous to a solid phase nor is it is the opposite of sublimation",
            "It is both the conversion of water from a gaseous to a solid phase and it is the opposite of sublimation",
            "It is the opposite of sublimation",
            "It is the conversion of water from a gaseous to a solid phase"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 91,
        "question": "Which of the following statements is a commonality between T-cell receptors and B-cell receptors?",
        "answer": "T-cells generally serve to recognize antigens and escort them to B-cells to elicit a response (helper T-cells) or to recognize antibodies bound to infected cells (cytotoxic T-cells). B-cells, in contrast, will directly secrete antibodies against the antigen after binding of the antigen at the membrane.",
        "suggested_answer": "Both receptors have variable regions that form an antigen binding site",
        "options": [
            "Both receptors recognize antigens in conjunction with major histocompatibility complex (MHC) proteins",
            "Both receptors are able to bind to intact antigens",
            "Both receptors consist of both heavy chains and two light chains to form the antigen binding domain ",
            "Both receptors have variable regions that form an antigen binding site"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 92,
        "question": "Which of the following changes to the zona pellucida that prevent polyspermy are made in mammalian cortical reactions?",
        "answer": "In mammals, the cortical reaction involves a very similar influx of intracellular calcium ions and exocytosis of cortical granules. The exocytosis releases the contents into the zona pellucida, where glycoproteins are cleaved to prevent further sperm cell binding. The hyaline layer then forms around the fertilized egg.",
        "suggested_answer": "Proteolytic cleavage of glycoproteins that bind to sperm cells",
        "options": [
            "Modification of vitelline envelope tethering ",
            "Shrinking of perivitelline space ",
            "Proteolytic cleavage of glycoproteins that bind to sperm cells",
            "Extracellular increase of calcium ions"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 93,
        "question": "What structure can be found in all living cells?",
        "answer": "Unlike eukaryotes, prokaryotes have no membrane-bound organelles. This means that they lack a nucleus, mitochondria, and other advanced cell structures.",
        "suggested_answer": "Ribosomes",
        "options": [
            "Mitochondria",
            "Nucleus",
            "Ribosomes",
            "Cell wall",
            "Nucleolus "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 94,
        "question": "Which of the following choices is not an an example of phagocytosis in multicellular organisms?",
        "answer": "Phagocytosis is a form of endocytosis in which a cell takes up solid material through the invagination of the plasma membrane to form intracellular vesicles. In multicellular organisms, the process of phagocytosis is utilized in nutrient uptake, immune system response, and in cell recycling. Cells perform phagocytosis to uptake solid nutrients into the cell. The immune system uses phagocytosis to consume foreign material for eventual degradation. In the continual process of cell recycling, old and dead cell material is taken up and reused by cells.",
        "suggested_answer": "Uptake of hormones",
        "options": [
            "Uptake of foreign material",
            "Uptake of hormones",
            "Uptake of dead cell material ",
            "Endocytosis of solid nutrient particles into cells"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 95,
        "question": "Where does the process of spermatogenesis occur in humans?",
        "answer": "Spermatogenesis the formation of spermatozoa occurs in the seminiferous tubules of the testes. Seminiferous tubules are composed of Sertoli cells and spermatogenic cells. There is a high concentration of testosterone present in these tubules. High testosterone concentrations support sperm development. The epididymis is the site of sperm maturation. Last, the seminal vesicles are small glands that produce the majority of the seminal fluid. ",
        "suggested_answer": "Seminiferous tubules",
        "options": [
            "Epididymis",
            "Seminiferous tubules",
            "Ejaculatory duct",
            "Seminal vesicle"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 96,
        "question": "What are processes in respiration are lysosomes involved in?",
        "answer": "Lysosomes are largely involved in breaking things down, because they contain acid hydrolase enzymes that break down waste materials and cellular debris. They can be described as the stomach of the cell. Lysosomes do not produce these other things. Things like ribosomes, Golgi apparatus, and the mitochondria are involved in these other processes.",
        "suggested_answer": "the degradation of cellular substances",
        "options": [
            "the degradation of cellular substances",
            "the production of fats",
            "the production of proteins",
            "the production of polysaccharides",
            "the production of ATP"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 97,
        "question": "What type of respiration is involved in the Krebs cycle?",
        "answer": "The Krebs cycle involves aerobic respiration because oxygen is used. Anaerobic respiration involves fermentation because oxygen is lacking and the compound does not enter the Krebs cycle. Glycolysis is the first step in respiration, which precedes the Krebs cycle and is fully anaerobic. Note that oxygen is not directly required for the Krebs cycle (it is not a reactant), however, without oxygen, the electron transport chain will not proceed and thus the Krebs cycle will also be halted.",
        "suggested_answer": "Aerobic",
        "options": [
            "Fermentation",
            "Aerobic",
            "Anaerobic",
            "Lactic acid"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 98,
        "question": "What is the function of DNA ligase?",
        "answer": "DNA ligase is an enzyme responsible for repairing nicks in the sugar-phosphate backbone of DNA and for fusing Okazaki fragments during DNA replication. It accomplishes this task by resynthesizing the phosphodiester bonds that hold the backbone together",
        "suggested_answer": "Create phosphodiester bonds ",
        "options": [
            "Separate double-stranded nucleic acids",
            "Create phosphodiester bonds ",
            "Synthesize RNA primers",
            "Transcribe DNA into RNA"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 99,
        "question": "Which of the following is best described as an example of a selective barrier in a cell?",
        "answer": "The plasma membrane functions as a selective barrier to allow the flow of oxygen, nutrients, and wastes to and from the cell. The selectively permeable plasma membrane encloses the cytoplasm. The cytoplasm in turn contains the nucleus, mitochondria, and other organelles.",
        "suggested_answer": "Plasma membrane",
        "options": [
            "Plasma membrane",
            "Mitochondria",
            "Cytoplasm",
            "Nucleus"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 100,
        "question": "How does hyperventilating change the blood chemistry in the human body?",
        "answer": "",
        "suggested_answer": "Hyperventilating will release more and result in an increase in the blood's pH",
        "options": [
            "Hyperventilating will not affect the blood's pH.",
            "Hyperventilating will release more and result in an increase in the blood's pH",
            "Hyperventilating will retain more and result in a decrease in the blood's pH",
            "Hyperventilating will release more and result in a drop in the blood's pH",
            "Hyperventilating will retain more and result in an increase in the blood's pH"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 101,
        "question": "Which of the following is not a condition for Hardy-Weinberg equilibrium to remain in effect?",
        "answer": "Random mating must occur in the population in order for the equilibrium to remain. If nonrandom mating occurred, allele frequency in the population would change. The alleles frequency of those mating the most would increase, while that of those mating less would decrease.",
        "suggested_answer": "Nonrandom mating must occur",
        "options": [
            "The population must be large",
            "No selection may occur",
            "No gene flow may occur",
            "No mutations may occur",
            "Nonrandom mating must occur"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 102,
        "question": "Which of these is not found in the central nervous system?",
        "answer": "Though the pineal gland is found within the skull, it is not composed of nervous tissue and is not considered part of the central nervous system. Instead, it is an endocrine organ that interacts with the nervous tissue of the brain that surrounds it.",
        "suggested_answer": "Pineal gland",
        "options": [
            "Microglia",
            "Cerebellum",
            "Pineal gland",
            "All of these are found in the central nervous system",
            "Thalamus"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 103,
        "question": "Which of the following choices is not an example of an animal hormone?",
        "answer": "Hormones are chemical messengers responsible for long distance cell signaling. Hormones are involved in diverse signaling pathways and have a variety of effects on cellular activities; therefore, there are many types of hormones. Animal hormones can be organized into classes based on their chemical makeup peptide hormones, steroid hormones, lipid-based hormones, and amino acid-derived hormones. Insulin and growth hormones are both in the class of peptide hormones, and they are responsible for promoting the absorption of glucose from the bloodstream and promoting cell growth and reproduction, respectively. Testosterone is a steroid hormone that controls the development of male reproductive features.",
        "suggested_answer": "Tyrosine kinase",
        "options": [
            "Tyrosine kinase",
            "Testosterone",
            "Growth hormone",
            "Insulin"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 104,
        "question": "Which of the following statements is true?",
        "answer": "The primary difference between prokaryotic and eukaryotic cells is that prokaryotic cells lack membrane-bound organelles and have circular DNA, while eukaryotic cells contain membrane-bound organelles and linear strands of DNA. Because prokaryotic cells lack membrane enclosed organelles, cells do not have nuclei. Rather, the cell\u2019s DNA is located in the nucleoid, a nucleus-like region within the cell. Eukaryotic cells generally comprise more complex organisms, such as plants, humans, and animals. Prokaryotic cells generally comprise bacteria and smaller organisms. All cells have ribosomes.",
        "suggested_answer": "Prokaryotic cells lack membrane-bound organelles ",
        "options": [
            "Prokaryotic cells lack membrane-bound organelles ",
            "Both prokaryotic and eukaryotic cells have DNA contained in a nucleus ",
            "Prokaryotic and eukaryotic cells have circular DNA ",
            "Plant cells lack ribosomes ",
            "Both prokaryotic and eukaryotic cells have membrane-bound organelles "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 105,
        "question": "Which of the following is not a risk factor for miscarriage?",
        "answer": "Miscarriage is the death and subsequent abortion of an embryo before it is able to survive outside of the mother. Miscarriage is more common early in pregnancy and common symptoms include vaginal bleeding and cramping. There are many risk factors associated with miscarriage including advanced maternal age, tobacco use, and obesity. In order to prevent miscarriage, expecting mothers are advised to avoid risk factors and practice good prenatal care.",
        "suggested_answer": "Use of prenatal vitamins",
        "options": [
            "Advanced maternal age",
            "Use of prenatal vitamins",
            "Tobacco use",
            "Obesity "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 106,
        "question": "How many do and produce respectively?",
        "answer": "Each produces 3 molecules in the electron transport chain while each produces 2 molecules. Each glucose molecule results in the formation of 10 molecules, which go on to produce 30 . Each glucose molecule results in the formation of 2 molecules, which go on to produce 4 . Note that some references may indicate that each produces 2.5 , while each produces 1.5 . These are theoretical maximums and depend on the organism, cell type, and cellular environment.",
        "suggested_answer": "3 and 2",
        "options": [
            "2 and 1",
            "2 and 3",
            "1.5 each",
            "3 and 2",
            "1 and 2"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 107,
        "question": "How does human initiated deforestation impact ecosystems?",
        "answer": "Deforestation can be defined as the removal of forests by humans. Deforestation has many negative environmental impacts including soil erosion, a decline in biodiversity, and an increase in greenhouse gas emissions.",
        "suggested_answer": "None of these",
        "options": [
            "None of these",
            "Decrease in soil erosion",
            "Increase in biodiversity",
            "Decrease in greenhouse gas emissions"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 108,
        "question": "The exchange of oxygen and carbon dioxide occurs in which of the following structures?",
        "answer": "Capillaries are minute blood vessels that connect the arterial and venous systems. The walls of these vessels are extremely thin, allowing easy diffusion of gases, nutrients, and waste particles between the capillary and adjacent cells. Oxygen diffuses into cells from the capillary, and carbon dioxide diffuses into the capillary from the cells.",
        "suggested_answer": "Capillaries",
        "options": [
            "Arteries",
            "Lymph ducts",
            "Capillaries",
            "Sinusoids",
            "Veins"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 109,
        "question": "In some heart conditions, one might hear an extra sound between the lub and the dub sounds. Which of the following choices is the most plausible explanation for the extra sound?",
        "answer": "The first sound (lub) occurs when the mitral and the tricuspid valves close. The second sound (dub) occurs when the pulmonary and the aortic valves close. The mitral and tricuspid valves close after both the left and the right atrias have contracted and have released blood into the ventricles. Transitioning towards the second heart sound, the ventricles contract and release blood into the systemic and pulmonary circulatory systems. If an extra noise is heard between the lub and the dub sounds, then it meant that while the ventricle is contracting, some blood is being pushed back into the atria(s) because the tricuspid valve and/or the mitral valve(s) did not completely close, resulting in regurgitation. ",
        "suggested_answer": "The mitral valve did not close completely",
        "options": [
            "The mitral valve did not close completely",
            "The mitral valve closed, opened, and then closed again",
            "The tricuspid valve opened too late ",
            "The aortic valve did not close completely",
            "The pulmonary valve did not close completely"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 110,
        "question": "Which of the following is a characteristic of acquired immunity?",
        "answer": "Acquired, or adaptive, immunity is a second immune defense system and develops slowly after exposure to an initial infection. As a result, the immune system is \"trained\" and capable of recognizing many specific components or antigens from the pathogen. Acquired immunity stores the information from an initial infection in preparation for reintroduction of the pathogen; it does not immediately respond to the initial infection.",
        "suggested_answer": "Recognition of traits specific to a particular pathogen",
        "options": [
            "Response is driven by phagocytic cells",
            "Recognition of shared traits by a wide array of pathogens",
            "Acquired immunity is a rapid response",
            "Use of basophils and mast cells to create inflammation",
            "Recognition of traits specific to a particular pathogen"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 111,
        "question": "Which of the following types of RNA is double stranded?",
        "answer": "Many types of RNA exist within cells, some are single stranded and others are double stranded. The following are among the types of single stranded RNA: mRNA (messenger RNA), tRNA (transfer RNA), and rRNA (ribosomal RNA). Messenger or mRNA carries genetic information from DNA to ribosomes for protein synthesis. Transfer or tRNA carries amino acids to ribosomes that match with mRNA codons. Last, ribosomal or rRNA codes for ribosomes, which are necessary for protein synthesis. An example of a double stranded RNA molecule is viral RNA. Viral RNA is the genetic material of many viruses and has a structure of two complementary strands.",
        "suggested_answer": "Viral RNA",
        "options": [
            "Viral RNA",
            "rRNA",
            "mRNA",
            "tRNA"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 112,
        "question": "The primary function of the stamen is to __________.",
        "answer": "The stamen is the male part of the flower which produces and distributes pollen. The female part of the flower is called the pistil and it receives pollen in order to fertilize the ovule. Roots are the structures that collect water and nutrients from the soil. Colorful petals and sugar-rich nectar are among the objects that are used are used to attract pollinators. Sepals are the leaf-like structures that protect the developing bud of a flower.",
        "suggested_answer": "produce and distribute pollen",
        "options": [
            "receive pollen in order to fertilize the ovule",
            "protect the developing bud of a flower",
            "attract pollinators",
            "produce and distribute pollen",
            "collect water and nutrients from the soil"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 113,
        "question": "Which of the following is an example of passive transport?",
        "answer": "Osmosis is an example of passive transport. Osmosis does not use energy. Instead, it acts using the concentration gradient. The sodium-potassium pump, proton pump, and electrogenic pump are all examples of active transport. This means that they can pump solutes against their concentration gradients using cellular energy.",
        "suggested_answer": "Osmosis",
        "options": [
            "Osmosis",
            "Sodium-potassium pump",
            "Proton pump",
            "Electrogenic pump"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 114,
        "question": "In which two phases can nondisjunction occur?",
        "answer": "Nondisjunction of homologous chromosomes may occur in anaphase I and nondisjunction of sister chromatids may occur in anaphase II. ",
        "suggested_answer": "Anaphase I and anaphase II",
        "options": [
            "Telophase I and telophase II",
            "Metaphase I and metaphase II ",
            "Anaphase I and anaphase II",
            "Metaphase I and anaphase II",
            "Anaphase I and metaphase II"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 115,
        "question": "Which of the following choices is the term for a protein whose native state is active?",
        "answer": "When a protein is active in it\u2019s native state, it is said to be constitutively active. In the case of protein receptors, a receptor is constitutively active when it is active without binding to a ligand. ",
        "suggested_answer": "Constitutively active",
        "options": [
            "Antagonist",
            "Agonist",
            "Dormant",
            "Constitutively active"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 116,
        "question": "In order to pump blood efficiently, cardiac muscle cells on both the left and the right side of the heart must be stimulated simultaneously. Which of the following cellular junctions is credited with allowing cardiac muscle cells to pump simultaneously?",
        "answer": "Gap junctions allow the same action potential to be experienced by multiple neighboring cardiac muscle cells via electrical synapses. This simultaneous electrical stimulus allows for a more unified and powerful contraction by the heart.",
        "suggested_answer": "Gap junctions",
        "options": [
            "Adherens junctions",
            "Gap junctions",
            "Tight junctions",
            "Desmosomes",
            "Actin filaments"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 117,
        "question": "Which of the following best describes how a sperm's axoneme develops during spermiogenesis?",
        "answer": "Spermiogenesis is the final stage of spermatogenesis. In the process of spermiogenesis, spermatids develop into spermatozoa and form axoneme the internal structure of the spermatozoa tails. It develops by way of microtubule growth from a single centriole that constructs the internal structure of the sperm tail. Mature spermatozoa have axonemes comprised of nine outer microtubule doublets and two central microtubules. These structures aid in motility.",
        "suggested_answer": "Microtubule growth from a single centriole",
        "options": [
            "Rearrangement of the Golgi apparatus",
            "Microtubule growth from a single centriole",
            "DNA condensation",
            "Mitochondrial clustering"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 118,
        "question": "Which of the following diseases is related to prenatal care?",
        "answer": "Fetal alcohol syndrome (FAS) is a disease that occurs when the individual\u2019s mother drank alcohol during the pregnancy in which they were conceived. There are many symptoms including brain damage, intellectual disabilities, and heart disease. These symptoms come from the passage of ethanol to the fetus from the placenta. The ethanol impairs nervous system development and negative impacts on other organ and developmental systems.",
        "suggested_answer": "Fetal alcohol syndrome",
        "options": [
            "Down syndrome",
            "Hemolytic disease",
            "Fetal alcohol syndrome",
            "Hepatitis B"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 119,
        "question": "Polymers of amino acids are called __________.",
        "answer": "Polymers of amino acids are called polypeptides. A protein is made up of one or more polypeptide chains that has folded and coiled in specific 3D configurations. Nucleic acids are polymers of nucleotides. Examples of nucleic acids are RNA and DNA. Ribosomes are the site of protein synthesis and are made of rRNA and protein.",
        "suggested_answer": "polypeptides",
        "options": [
            "carbohydrates",
            "nucleic acids",
            "polypeptides",
            "ribosomes"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 120,
        "question": "While playing intense basketball you feel some cramps in your muscles, what is likely the cause of these cramps?",
        "answer": "The correct answer to this question is lactic acid fermentation.",
        "suggested_answer": "Lactic acid fermentation",
        "options": [
            "An abundance of oxygen delivery to the muscles",
            "Oxidative phosphorylation",
            "Alcohol fementation",
            "Chemiosmotic coupling",
            "Lactic acid fermentation"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 121,
        "question": "Which of the following best describes how energy is stored during cellular respiration?",
        "answer": "Energy is stored in the form of glucose. Cells, in turn, tap into glucose reserves to fuel cellular respiration. The carbon in glucose also serves as raw materials for the synthesis of other molecules such as amino acids.",
        "suggested_answer": "Glucose",
        "options": [
            "Lipids",
            "Glucose",
            "None of these",
            "Sucrose"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 122,
        "question": "Which of the following reaction is the most common type of catabolic reaction in the human body?",
        "answer": "Catabolism means to break something down, usually from a larger starting point to one or more smaller byproducts. Hydrolysis is the most common reaction in the human body. Hydrolysis means \"breaking\" through the addition of a water molecule. You will see hydrolysis as an important reaction in many pathways in the human body.",
        "suggested_answer": "Hydrolysis",
        "options": [
            "Condensation",
            "Hydrolysis",
            "Addition",
            "Dehydration",
            "Elimination"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 123,
        "question": "Which of the following best represents the generic term for a muscle cell?",
        "answer": "Muscle cells are often referred to as myocytes. Myocytes are tubular cells that develop during a process called myogenesis and specialize based on their association with specific muscle types.",
        "suggested_answer": "Myocyte",
        "options": [
            "Myoblast",
            "Sarcomere",
            "Myofibril",
            "Myocyte"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 124,
        "question": "DNA can be found in which of the following cellular compartments of a eukaryotic cell?",
        "answer": "While most of a cell's DNA is located in the nucleus, some DNA is found in the mitochondria and chloroplasts. The DNA found in plasmids supports the endosymbiotic theory. The cytoplasm of eukaryotes does not contain free floating DNA like in prokaryotes.",
        "suggested_answer": "Nucleus, mitochondria, and chloroplasts",
        "options": [
            "Nucleus only",
            "Nucleus, mitochondria, and chloroplasts",
            "Nucleus and mitochondria only",
            "Nucleus, mitochondria, chloroplasts and cytoplasm"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 125,
        "question": "Interphase is divided in which of the following subphases? ",
        "answer": "Interphase is divided into G1, S, and G2 phases. It is during interphase that the cell grows by making proteins and cytoplasmic organelles. During S phase, the DNA is replicated, yielding sister chromatids. G2 involves the last bit of growth, and also, DNA proofreading and repair. ",
        "suggested_answer": "G1 phase, S phase, and G2 phase",
        "options": [
            "G1 phase, S phase, and G2 phase",
            "G1 phase and G2 phase",
            "None of these",
            "G1 phase, G2 phase, and G3 phase",
            "S1 phase and S2 phase"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 126,
        "question": "Of the following, which is the most likely to affect the population growth of species in a density-dependent manner?",
        "answer": "Disease is a biotic factor, while the other choices are abiotic factors. Density-dependent factors are biotic in nature, and may involve things such as mating, food, competition, and disease. Density dependent factors will affect different populations differently depending on how many organisms are present in a given area. In the case of disease, consider that the flu will spread more easily in a densely-populated city than it will in a loosely-populated desert.",
        "suggested_answer": "Disease",
        "options": [
            "Hurricanes",
            "Disease",
            "Earthquakes",
            "Floods",
            "Frost"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 127,
        "question": "What is the name of the valve separating the right atrium from the right ventricle?",
        "answer": "The atria and ventricles of the heart are separated by two valves, one on each side of the heart. The left atrium and left ventricle is separated by the mitral valve, also known as the bicuspid valve. The right atrium and right ventricle is separated by the tricuspid valve, named for its three flaps that work together to form the valve.",
        "suggested_answer": "Tricuspid valve",
        "options": [
            "Bicuspid valve",
            "Tricuspid valve",
            "Mitral valve",
            "None of these",
            "Right semilunar valve"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 128,
        "question": "Which of the following hypotheses best describes the evolution of organelles in eukaryotes?",
        "answer": "The current hypothesis for the evolution of eukaryotes is the theory of endosymbiosis. In this theory, one cell engulfs another cell, creating a symbiotic relationship between the host cell and engulfed cell. The internal endosymbiotic cell is inherited through the generations and is the precursor to organelles. ",
        "suggested_answer": "Endosymbiotic relationship between a host cell and an engulfed cell",
        "options": [
            "None of these",
            "Endosymbiotic relationship between a host cell and an engulfed cell",
            "Prokaryotic genetic material mutated to synthesize organelles",
            "Eukaryotes evolved organelles independently of prokaryotes"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 129,
        "question": "Penicillin is an antibiotic that works by blocking the formation of peptidoglycan. This antibiotic disrupts bacterial cell growth by inhibiting the production of which of these?",
        "answer": "Bacteria contain a cell wall made of peptidoglycan. This peptidoglycan is what is used when looking at the \"Gram stain\" of a bacteria. By inhibiting the formation of peptidoglycan, penicillin works by blocking the production of bacterial cell walls. Bacteria cannot survive without their cell walls.",
        "suggested_answer": "Bacterial cell walls",
        "options": [
            "Bacterial proteins",
            "Bacterial nucleic acids",
            "Bacterial cell walls",
            "Bacterial plasma membranes",
            "Bacterial flagella"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 130,
        "question": "Which of the following is an accurate comparison between sieve tube elements and vessel elements?",
        "answer": "Vessel elements are cells in phloem tissue that are technically dead. Upon maturation, the living material in the cells disappears while the cell wall remains; the cells are dead; however, sieve tube elements contain living tissue.",
        "suggested_answer": "Sieve tube elements are living cells while vessel elements are technically dead",
        "options": [
            "Sieve tube elements are living cells while vessel elements are technically dead",
            "Vessel elements transport nutrients while sieve tube elements transports mostly water",
            "Sieve tube elements are in xylem tissue and vessel elements are in phloem tissue",
            "Vessel elements are living cells and sieve tube elements are technically dead"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 131,
        "question": "Substances A and B are both neutral in charge at the beginning of a reaction. After undergoing a redox reaction, substance A is positively charged and substance B is negatively charged. Which of the following is true? ",
        "answer": "Since substance A ends up being positively charged, that means it must have lost or donated a negatively charged electron, thus it is oxidized and, is the reducing agent. Substance B ends up being negatively charged, so that means it gained an negatively charged electron, thus it is reduced and, is the oxidizing agent. ",
        "suggested_answer": "Substance A is the reducing agent and substance B is the oxidizing agent",
        "options": [
            "Substance A is the oxidizing agent and substance B is the reducing agent",
            "Both substance A and B are oxidizing agents",
            "Substance A is the reducing agent and substance B is the oxidizing agent",
            "Both substance A and B are reducing agents"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 132,
        "question": "Which of the following is an example of a decomposer?",
        "answer": "Within ecosystems, there is always a transfer of energy, usually from one organism to another. There is also energy transfer between abiotic and biotic factors. The caterpillar and lion are examples of consumers because they are unable to make their own food and must rely on other living organisms for energy. Consumers are subdivided into herbivores, omnivores, and carnivores. Producers are those living things in the ecosystem that generally produce energy using photosynthesis or light. Organisms that break down dead or decaying matter for energy are called decomposers. These are most likely bacteria, fungi (such as mushrooms), and some worms.",
        "suggested_answer": "Mushroom",
        "options": [
            "Caterpillar",
            "Lion",
            "Flower",
            "Mushroom"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 133,
        "question": "Where does the Calvin cycle take place in the chloroplasts?",
        "answer": "The Calvin cycle takes place in the stroma area in the chloroplasts. The mitochondria is another organelle, and the nucleus contains the DNA. The thylakoids are in the chloroplasts and contain the pigment chlorophyll in which the light reactions occur.",
        "suggested_answer": "Stroma",
        "options": [
            "Nucleus",
            "Mitochondria",
            "Thylakoid",
            "Stroma"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 134,
        "question": "Output from the heart can be altered mainly by changing which two variables?",
        "answer": "Multiplying stroke volume by heart rate gives another measure called cardiac output. Stroke volume will be influenced by variables such as resistance in the arteries and contractility of the heart muscle cells, while heart rate will be influenced by variables such as emotional state and age. Both stroke volume and heart rate increase with exercise.",
        "suggested_answer": "Stroke volume and heart rate",
        "options": [
            "Stroke volume and heart rate",
            "Breathing rate and stroke volume",
            "Blood pressure and heart rate",
            "Stroke volume and blood pressure"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 135,
        "question": "In human female gametogenesis __________ occurs which results in only one functional gamete called a(n) __________ due to __________.",
        "answer": "Human female gametogenesis occurs through meiosis and results in three non-functional daughter cells known as polar bodies and one functional and much larger daughter cell called an ovum. Unequal division of cytoplasm during meiosis I and meiosis II towards only one of the daughter cells results in the ovum. Non-disjunction is the unequal assortment of chromosomes, not cytoplasm. Human female gametogenesis results in ovum while human male gametogenesis results in sperm. Mitosis does not result in gamete daughter cells but identical daughter cells as mitosis occurs in somatic (body or non-germ cells) cells. ",
        "suggested_answer": "meiosis . . . ovum . . . unequal division of cytoplasm",
        "options": [
            "meiosis . . . ovum . . . nondisjunction ",
            "meiosis . . . sperm . . . unequal division of cytoplasm",
            "mitosis . . . ovum . . . unequal division of cytoplasm",
            "meiosis . . . ovum . . . unequal division of cytoplasm",
            "meiosis . . . sperm . . . nondisjunction"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 136,
        "question": "Which of the following provides the correct sequence of signaling for a hormone cascade pathway?",
        "answer": "Groups of hormones from the hypothalamus, anterior pituitary, and individual endocrine glands are often organized into a hormone cascade pathway. The flow of such a cascade occurs as follows:",
        "suggested_answer": "Hypothalamus, anterior pituitary, endocrine gland, target cells",
        "options": [
            "Hypothalamus, anterior pituitary, endocrine gland, target cells",
            "Anterior pituitary, hypothalamus, endocrine gland, target cells",
            "Anterior pituitary, endocrine gland, hypothalamus, target cells",
            "Hypothalamus, endocrine gland, anterior pituitary, target cells"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 137,
        "question": "Which of the following describes a nucleon?",
        "answer": "A nucleon is defined as a particle that is found in the nucleus of an atom. The nucleus is composed of proton particles and neutron particles. These are densely bound together to form a nucleus; thus, these two subatomic particles are nucleons.",
        "suggested_answer": "A proton or a neutron",
        "options": [
            "A proton and neutron unit",
            "A proton or a neutron",
            "Electrons",
            "Protons",
            "Neutrons"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 138,
        "question": "Which part of the male reproductive system is responsible for sperm storage and maturation?",
        "answer": "Sperm cells are stored and mature in the epididymis until they are transported to the urethra through the vas deferens. The testes are the site of sperm production, while the seminal vesicles produce fluids that are later incorporated into semen.",
        "suggested_answer": "Epididymis",
        "options": [
            "Epididymis",
            "Testes",
            "Vas deferens",
            "Seminal vesicles"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 139,
        "question": "During which of the following pregnancy stages can the mother begin to feel fetal movement?",
        "answer": "Human pregnancy can be divided into three trimesters, each categorized by different symptoms and stages of fetal development. During the second trimester of pregnancy, which occurs between 13 and 28 weeks, fetal movement can be felt. This is often called quickening, which is the stirring of the fetus.",
        "suggested_answer": "Second trimester",
        "options": [
            "First trimester",
            "Second trimester",
            "Third trimester",
            "Fetal movement cannot be felt during pregnancy"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 140,
        "question": "The lock and key model of enzyme substrate binding posits that ",
        "answer": "The lock and key model states that the active site of an enzyme precisely fits a specific substrate. The induced fit model states that the active site of an enzyme will undergo a conformational change when binding a substrate, to improve the fit.",
        "suggested_answer": "The enzyme and substrate have complementary geometric shapes and are specific to one another",
        "options": [
            "Enzymes cannot be reused",
            "The active site of the enzyme undergoes conformational change when the substrate binds",
            "There is broad specificity of enzymes",
            "The enzyme and substrate have complementary geometric shapes and are specific to one another"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 141,
        "question": "The urinary tract consists of which of the following?",
        "answer": "Blood is filtered from the renal artery into the kidneys, where it is processed into urine. Each of the two kidneys is connected to the bladder via a ureter. The bladder has only one exit, the urethra, which carries the urine out of the body.",
        "suggested_answer": "One bladder, two kidneys, two ureters, one urethra",
        "options": [
            "One bladder, two kidneys, two ureters, one urethra",
            "One bladder, two kidneys, one ureter, two urethras",
            "Two bladders, two kidneys, one ureter, one urethra",
            "Two bladders, two kidneys, two ureters, one urethra",
            "Cannot be determined without knowing the subject's sex"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 142,
        "question": "Which of the following is true regarding the morphology of epithelial columnar cells?",
        "answer": "Epithelial columnar cells represent one type of epithelial cell morphology. Columnar cells are taller than they are wide, have elongated nuclei, and are pillar-like. Epithelial columnar cells play a role in the digestive system and are important in sensory reception.",
        "suggested_answer": "All of these",
        "options": [
            "They are pillar-like",
            "Height is greater than the width",
            "All of these",
            "Nuclei are elongated"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 143,
        "question": "GLUT1 is a type of protein used to facilitate the transport of glucose across the plasma membrane. What protein classification accurately describes GLUT1?",
        "answer": "GLUT1 is a protein that allows glucose to pass into a cell through the membrane. Glucose is a large polar molecule, meaning that it will require a protein in order to diffuse across the membrane. GLUT1 must span the entire length of the plasma membrane in order to provide a \"passage\" for glucose to diffuse. As a result, GLUT1 is classified as an integral protein, or a protein that fully transverses the membrane.",
        "suggested_answer": "Integral protein",
        "options": [
            "Enzyme",
            "Integral protein",
            "Extrinsic protein",
            "Peripheral protein"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 144,
        "question": "Which of the following structures/functions is not created using microtubules?",
        "answer": "Muscle contraction is accomplished using the function of microfilaments, namely actin and myosin. Microtubules are not a key player in muscle contraction, but are used to create the mitotic spindles, flagella, and cilia. Microtubules are a key element of the cytoskeleton, and are generally involved in structural aspects of the cell.",
        "suggested_answer": "Muscle contraction",
        "options": [
            "Cilia",
            "Mitotic spindles",
            "Eukaryotic flagella",
            "Muscle contraction"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 145,
        "question": "Which of the following best describes the pith: a tissue type present in vascular plants?",
        "answer": "Pith is a type of tissue located in the stems of vascular plants. It stores and transports nutrients throughout the plant. The pith is composed of parenchyma cells.",
        "suggested_answer": "It is a tissue in vascular plants that transports nutrients throughout the plant",
        "options": [
            "Pith is located in leaves",
            "It contains no parenchyma cells",
            "It is a tissue in non-vascular plants that transports nutrients throughout the plant",
            "It is a tissue in vascular plants that transports nutrients throughout the plant"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 146,
        "question": "Which of the following types of selection best describes the process in which fitness depends on phenotype frequency?",
        "answer": "Frequency-dependent selection is a type of natural selection in which the fitness of a phenotype depends on frequency. This includes positive frequency-dependent selection fitness of a phenotype increases when it is common and negative frequency-dependent selection fitness of a phenotype decreases when it is common.",
        "suggested_answer": "Frequency-dependent selection",
        "options": [
            "Sexual selection",
            "Disruptive selection",
            "Frequency-dependent selection",
            "Directional selection"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 147,
        "question": "When comparing two solutions with different solute concentrations, which of the following best defines the solution with the higher concentration of solute?",
        "answer": "Hypertonic is the correct answer for the solution with more solute in it. The root \"hyper\" means more; therefore, a hypertonic solution will have more solute. A hypotonic solution will have less solute in it. Isotonic solutions will have equal concentrations of solutes between the two solutions.",
        "suggested_answer": "Hypertonic",
        "options": [
            "More soluble",
            "Hypertonic",
            "Isotonic",
            "Hypotonic"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 148,
        "question": "Where does transfer of oxygen to cells occur?",
        "answer": "Capillaries are the smallest blood vessels, which allow transport of oxygen and other small molecules. There are capillaries involved in gas exchange in the lungs, but it does not involve transfer of oxygen to the cells. Rather, it involves uptake of oxygen by red blood cells from the air inside the alveoli, and removal of carbon dioxide from the blood into the air in the alveoli to be exhaled. All other blood vessels have walls that are too thick to allow transport of any substances across them. The heart is the muscular pump of the circulatory system, which provides the pressure required to drive blood flow.",
        "suggested_answer": "Capillaries",
        "options": [
            "Arteries",
            "Lungs",
            "Capillaries",
            "Veins"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 149,
        "question": "Which of the following structures forms during primary neurulation?",
        "answer": "Neurulation is the process of neural system development in vertebrates. This process can be divided into two stages: primary neurulation and secondary neurulation. The process of primary neurulation is the formation of the neural tube from the neural plate. The neural plate develops from the ectoderm is a structure that becomes the neural tube. This development occurs through the invagination of the neural plate and convergence of neural fold cells, pushing down neural plate cells. The neural folds eventually touch and separate from the neural plate to complete neural tube formation.",
        "suggested_answer": "Neural tube",
        "options": [
            "Mesoderm",
            "Endoderm",
            "Medullary cord",
            "Neural tube"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 150,
        "question": "Which of the following statements about quantum numbers is false?",
        "answer": "The location of a given electron is described by a set of four quantum numbers. The principle quantum number, , gives the energy level of the electron. It essentially describes the size of the electron shell, and can be any integer value. As this value increases, the size of the orbital increases and is located farther from the atom nucleus. The azimuthal (angular momentum) quantum number, , refers to the subshell of the energy level. Each subshell has a different shape, corresponding to spdf configurations. can be any value from zero to . The magnetic quantum number, , gives the orientation of the subshell given by the azimuthal quantum number. These values correspond to orientations along the x-axis, y-axis, or z-axis. Each orientation constitutes a different orbital and can hold exactly two electrons. The magnetic quantum number can be any integer greater than and less than , with the total number of possible orbitals given by . is the electron spin quantum number. This number is used to distinguish between the two electrons in a single orbital.",
        "suggested_answer": "The angular momentum quantum number, , tells us the direction of the electrons spinning around the nucleus",
        "options": [
            "The angular momentum quantum number, , tells us the direction of the electrons spinning around the nucleus",
            "As the principal quantum number, , increases, the distance of electrons from the nucleus increases",
            "The magnetic quantum number, , tells us the three-dimensional orientation of the atomic orbital in space",
            "A maximum of ten electrons can occupy a orbital",
            "The smaller the value of the principal quantum number, , the lower the energy of the electrons in this shell"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 151,
        "question": "Which of the given options occurs last during an action potential?",
        "answer": "Once the cell reaches threshold, an action potential is fired. Sodium, a positive ion, enters the cell, and causes the charge on the membrane to rise, or depolarize. After a certain point, sodium gates close. Potassium, another positive ion, then leaves the cell, and the charge on the membrane decreases. As the potassium ions exit, the membrane potential plunges even lower than the resting potential, causing it to become hyperpolarized. At this point, the sodium/potassium pump works to repolarize the cell to return to the resting membrane potential.",
        "suggested_answer": "Potassium gates close",
        "options": [
            "The cell reaches threshold",
            "Potassium gates open",
            "Potassium gates close",
            "Sodium gates close",
            "The cell depolarizes"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 152,
        "question": "Which chambers of the heart contain oxygen-poor blood?",
        "answer": "The right side of the heart contains oxygen-poor blood. The right atrium receives oxygen-poor blood from the body through the superior and inferior vena cavae. This blood then flows into the right ventricle and then out the pulmonary valve into the lungs where the blood becomes oxygen-rich. The oxygen-rich blood goes to the right atrium then to the right ventricle where it is pumped to the system to provide oxygen to the cells of the body.",
        "suggested_answer": "Right atrium and right ventricle",
        "options": [
            "Right ventricle and left ventricle ",
            "Left atrium and right ventricle",
            "Left atrium and left ventricle",
            "Right atrium and right ventricle",
            "Right atrium and left atrium"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 153,
        "question": "Which of the following conditions can disrupt normal protein folding?",
        "answer": "Disruption of normal protein folding or denaturation protein unfolding occurs under certain environmental conditions. Denaturation is defined as the loss of quaternary, tertiary, and secondary folding through the disruption of protein subunits and bonds. The environmental conditions that cause denaturation include the following: extreme temperatures, chemical interference, and extreme pH levels. Denatured proteins may sometimes refold if conditions stabilize; however, this does not typically happen.",
        "suggested_answer": "All of these",
        "options": [
            "All of these",
            "Chemical interference",
            "pH",
            "Temperature"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 154,
        "question": "Which type of mutation does not change an organism's phenotype despite changing its genotype?",
        "answer": "Silent mutations will change a DNA sequence without affecting the phenotype of the organism. This can occur either in an intron, which will not be translated, or by replacing a single nucleotide with another nucleotide without changing the amino acid recruited by the codon. Silent mutations often result from the degenercy of codons.",
        "suggested_answer": "Silent",
        "options": [
            "Nonsense",
            "Silent",
            "Frameshift",
            "Missense"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 155,
        "question": "Ribosomes are the site of protein synthesis in both eukaryotes and prokaryotes. In eukaryotes, ribosomes can either be \"free\" or \"bound.\" To which larger organelle are bound ribosomes attached?",
        "answer": "While free ribosomes are present independently in the cytosol, bound ribosomes are attached to the rough endoplasmic reticulum. Their presence gives this organelle its \"rough\" appearance and distinguishes it from the smooth endoplasmic reticulum, which does not contain ribosomes. The nuclear envelope, which surrounds and protects the eukaryotic nucleus, is also the site of some bound ribosomes and is connect to the rough endoplasmic reticulum. The Golgi apparatus, lysosomes, and mitochondria have no ribosomes bound to their membranes.",
        "suggested_answer": "Rough endoplasmic reticulum",
        "options": [
            "Rough endoplasmic reticulum",
            "Smooth endoplasmic reticulum",
            "Mitochondria",
            "Lysosomes",
            "Golgi apparatus"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 156,
        "question": "What is a clade?",
        "answer": "A clade is a group of related species all descending from a common ancestor, including that ancestor. They have at least one shared derived trait unique to the clade and differentiating them from all other clades. Clades can be nested within each other: the mammalian clade is part of the vertebrate clade, which is part of the animal clade.",
        "suggested_answer": "An ancestor and all of its descendants",
        "options": [
            "The set of descendants from a common ancestor that have become extinct",
            "An ancestor and all of its descendants",
            "All of the descendants of a common ancestor, but not the ancestor itself",
            "An ancestor and none of its descendants",
            "An ancestor and a specific subset of its descendants"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 157,
        "question": "Where does the citric acid cycle take place in eukaryotic cells?",
        "answer": "The citric acid cycle, also known as the Kreb's cycle, occurs within the mitochondria of eukaryotic cells. In prokaryotic cells, it occurs in the cytosol.",
        "suggested_answer": "Mitochondria",
        "options": [
            "Endoplasmic reticulum",
            "Golgi body",
            "Mitochondria",
            "The nucleus",
            "The cytosol"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 158,
        "question": "Which of the following molecules acts as a signal sequence on apoptotic cells that attracts phagocytic cells to clean up the dead cell?",
        "answer": "Phosphatidylserine is a phospholipid membrane component of the cell actively held facing the cytosolic side of the cell by an enzyme called flippase. This is an important distinction from all other phospholipids because all others are free to flip back and forth from both sides of the membrane. However, when a cell is undergoing the process of apoptosis, phosphatidylserine is no longer forced to stay on the cytosolic side; when it flips and is shown on the outer surface of the cell, it acts as a signal for the phagocytic cells to engulf and destroy the cell. ",
        "suggested_answer": "Phosphatidylserine",
        "options": [
            "Microfilaments",
            "Double-stranded DNA",
            "Phosphatidylserine",
            "Low intracellular calcium concentration",
            "Cytochrome C"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 159,
        "question": "Which of the following is not true regarding the assimilation stage of the nitrogen cycle?",
        "answer": "In the process of assimilation, organic nitrogen is incorporated into plants through the roots. The type of nitrogen incorporated may be nitrate ions, nitrite ions, or ammonium ions. Symbiotic bacteria may assist this process.",
        "suggested_answer": "Atmospheric nitrogen is converted into organic nitrogen",
        "options": [
            "Plants absorb nitrogen from the soil",
            "Symbiotic bacteria may aid with absorption of nitrogen from the soil",
            "Nitrogen is incorporated into plants through roots as nitrate ions, nitrite ions, and ammonium ions",
            "Atmospheric nitrogen is converted into organic nitrogen"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 160,
        "question": "Which of the following are catabolic reactions?",
        "answer": "Catabolic reactions are those that breakdown organic molecules into smaller molecules and release energy. Glycolysis involves the breakdown of glucose into pyruvate, , and . Citric acid cycle is a series of reaction that take acetyl-CoA and oxaloacetate and produce carbon dioxide, , , and . Thus, the Krebs cycle is both catabolic and anabolic. Photosynthesis and protein synthesis are both anabolic reactions. In contrast, while oxidative phosphorylation does produce energy in the form of , is neither catabolic nor anabolic because it simply transfers energy in the form of the electron carrier into and does breakdown or produce organic molecules.",
        "suggested_answer": "I only",
        "options": [
            "I, II, and III",
            "II, IV, and V",
            "I and II",
            "I only",
            "III only"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 161,
        "question": "Which of the following processes allows for the fusion of the genetic material of the sperm and egg?",
        "answer": "After sperm cell and oocyte plasma membrane fusion, the sperm pronucleus moves into the oocyte cytoplasm. The sperm and egg pronuclei migrate towards the center of the cell while performing DNA replication, where both nuclear membranes dissolve. The chromosomes are prevented from dispersing by a mitotic spindle that tethers the maternal and paternal genetic material together. The cell then begins mitosis, which fuses the genetic material into a single diploid genome.",
        "suggested_answer": "Mitosis",
        "options": [
            "Mitosis",
            "All of meiosis",
            "Meiosis I",
            "Meiosis II"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 162,
        "question": "Which of the following best explains how marine biomes are distinct from freshwater biomes?",
        "answer": "Marine biomes are aquatic ecosystems that possess a high concentration of salts. Examples of marine biomes include oceans, coral reefs, and estuaries. Marine biomes contain large quantities of algae that supply most of the Earth\u2019s oxygen content.",
        "suggested_answer": "Marine biomes have high salt concentration while freshwater biomes have low salt concentration",
        "options": [
            "Freshwater biomes have high salt concentration while marine biomes have low salt concentration",
            "Freshwater biomes are greater oxygen sinks than marine biomes",
            "Freshwater biomes contain algae that supplies most of the Earth\u2019s oxygen, while marine biomes do not",
            "Marine biomes have high salt concentration while freshwater biomes have low salt concentration"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 163,
        "question": "What property of carbon most contributes to its ability to form such a diverse range of molecules?",
        "answer": "Carbon has four valance electrons, allowing it to form a wide range of bonds with other atoms.",
        "suggested_answer": "It has four valance electrons",
        "options": [
            "It can form a quadruple bond",
            "It has a small atomic mass",
            "It is magnetic",
            "It has four valance electrons",
            "It tends to form ionic bonds in organic molecules"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 164,
        "question": "Signals travel through a neuron as __________, and travel from neuron to neuron as __________.",
        "answer": "Neurotransmitters and hormones are both chemical signals, but hormones are used in the endocrine system, released from glands into the blood, while neurotransmitters are released from the axon terminals of neurons to signal other neurons. Signals travel across neurons as electrical signals caused by the movement of large numbers of atomic ions across the membrane via protein channels. Charged proteins would be too large to quickly move through the channels in such large numbers.",
        "suggested_answer": "an electrical signal using atomic ions . . . a chemical signal using neurotransmitters",
        "options": [
            "a chemical signal using hormones . . . an electrical signal using neurotransmitters",
            "a chemical signal using neurotransmitters . . . an electrical signal using charged proteins",
            "an electrical signal using atomic ions . . . a chemical signal using hormones",
            "An electrical signal via charged proteins . . . a chemical signal using neurotransmitters",
            "an electrical signal using atomic ions . . . a chemical signal using neurotransmitters"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 165,
        "question": "Amino acids are most closely related to which macromolecule?",
        "answer": "An amino acid is the monomer unit of the polymer known as a polypeptide. Polypeptide chains form the primary structure of proteins.",
        "suggested_answer": "Polypeptide",
        "options": [
            "Polypeptide",
            "Disaccharide",
            "Nucleic acid",
            "Triglyceride"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 166,
        "question": "Which anterior pituitary hormone regulates blood glucose control by the pancreas?",
        "answer": "The maintenance of blood glucose homeostasis is not controlled by the pituitary gland. It is controlled through a continuous sensory and feedback mechanism by the pancreas and its two hormones, glucagon and insulin. When blood glucose levels rise above a threshold point, insulin is produced/released to drive uptake of glucose from the blood. Conversely, when blood glucose levels drop below a threshold point, glucagon is released to promote release of glucose into the bloodstream from glucagon stores.",
        "suggested_answer": "None of the answers provided",
        "options": [
            "Adrenocorticotropic hormone (ACTH)",
            "Glucagon",
            "None of the answers provided",
            "Antidiuretic hormone"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 167,
        "question": "Which of the following is true regarding large ice sheets?",
        "answer": "Ice sheets are large areas of ice, including glaciers and ice caps. These represent large reserves of sequestered water that remain on the Earth\u2019s surface for a very long time (i.e. those located in Antarctica). While ice sheets represent sequestered water, they are only a part of the water cycle due to the constantly changing climactic conditions of Earth.",
        "suggested_answer": "All of these",
        "options": [
            "All of these",
            "They are large reserves of water",
            "Ice sheets are a part of the water cycle",
            "Many, including Antarctica, have sequestered water for a long period of time"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 168,
        "question": "Which of the following terms best describes a phospholipid that has both a hydrophilic region and a hydrophobic region?",
        "answer": "An amphipathic molecule is one that has both hydrophilic and hydrophobic regions. In the case of phospholipids, the tail is hydrophobic and the head is hydrophilic. ",
        "suggested_answer": "Amphipathic molecule",
        "options": [
            "Amphipathic molecule",
            "Complex molecule",
            "Ionic molecule",
            "Dualistic molecule"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 169,
        "question": "Which of the following best describes the correct order of stages in the ovarian cycle of the menstrual cycle?",
        "answer": "The menstrual cycle is the development of the female reproductive system for potential pregnancy. The cycle can be divided into two parts the ovarian cycle and the uterine cycle. The ovarian cycle has three phases the follicular phase, ovulation, and the luteal phase.",
        "suggested_answer": "Follicular phase, ovulation, and luteal phase",
        "options": [
            "Follicular phase, luteal phase, and ovulation",
            "Ovulation, luteal phase, and follicular phase",
            "Luteal phase, ovulation, and follicular phase",
            "Follicular phase, ovulation, and luteal phase"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 170,
        "question": "In a cell membrane-bound protein, the majority of hydrophobic amino acids residues are found where?",
        "answer": "Since the interior of cell membranes are made up of the hydrophobic tails of the phospholipids, proteins that are bound in membranes need region that contains high amounts of hydrophobic amino acids residues that can contact the hydrophobic tails molecules and keep it stable. Therefore, in a membrane-bound protein, one would expect the majority hydrophobic amino acid residues to be in the portion of the protein that is buried within the cell membrane.",
        "suggested_answer": "In the portion of the protein that is buried in the membrane",
        "options": [
            "In the portion of the protein that sticks out of the membrane",
            "In the portion of the protein that is buried in the membrane",
            "In the cytosol",
            "On the outside of the protein",
            "On the portion of the protein that faces inside the cell"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 171,
        "question": "Which of the following best describes the composition of glycerol molecules?",
        "answer": "Glycerol is composed of an alcohol attached to three carbons each bearing a hydroxyl group.",
        "suggested_answer": "An alcohol with three carbons each bearing a hydroxyl group",
        "options": [
            "An alcohol with two carbons each bearing a hydroxyl group",
            "An alcohol with one carbon bearing a hydroxyl group",
            "An alcohol with three carbons each bearing a hydroxyl group",
            "An alcohol with three carbons without any hydroxyl group"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 172,
        "question": "In photosynthesis, what is the final acceptor of electrons along the electron transfer chain?",
        "answer": "After electrons have been stripped from water by Photosystem II and passed along the membrane to Photosystem I, they are finally passed on to NADP+, which is reduced to NADPH.",
        "suggested_answer": "NADP+",
        "options": [
            "Photosystem I",
            "Photosystem II",
            "NADP+",
            "Oxygen"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 173,
        "question": "An ecologist observes a population of snakes on an island for one month every year. After the eleventh month, he sees that the snake population has been decimated, and decides to wait for the snakes to repopulate before coming back for further observation. When he returns five years later, he finds a very homogenous looking population of snakes. What is the name of the effect he observed?",
        "answer": "A bottleneck effect is the term used to describe the loss of genetic variation that occurs after outside forces destroy most of a population. The few individuals left to reproduce pass their traits on to all of their offspring, which then may thrive without the competition of a large population. Eventually, there may be a large, very genetically similar population based on the traits of the few original survivors.",
        "suggested_answer": "The bottleneck effect",
        "options": [
            "Genetic drift",
            "Extinction",
            "The disaster effect",
            "The bottleneck effect",
            "The founder effect"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 174,
        "question": "Which of the following best describes the difference between a primary and secondary spermatocyte?",
        "answer": "Spermatocytes are male gametocytes located in the seminiferous tubules of the testes. Primary spermatocytes are diploid and form when spermatogonia immature germ cells enter into mitosis. Primary spermatocytes can then enter meiosis and produce haploid secondary spermatocytes after meiosis I.",
        "suggested_answer": "The secondary spermatocyte is haploid and the primary spermatocyte is diploid",
        "options": [
            "The secondary spermatocyte forms during meiosis II and the primary spermatocyte forms during mitosis",
            "The secondary spermatocyte forms during mitosis and the primary spermatocyte forms during meiosis II",
            "The secondary spermatocyte is haploid and the primary spermatocyte is diploid",
            "The secondary spermatocyte is diploid and the primary spermatocyte is haploid"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 175,
        "question": "Where is bile stored when digestion is not occurring?",
        "answer": "The liver produces bile, which travels down the bile duct to be released into the small intestine, specifically the duodenum. When the duct is not open, bile backs up and gets stored in the gall bladder. Once the duct is open, when the small intestine senses the presence of food, the gall bladder will release the bile to emulsify fats during digestion.",
        "suggested_answer": "Gall bladder",
        "options": [
            "Spleen",
            "Gall bladder",
            "Kidney",
            "Appendix",
            "Adrenal glands"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 176,
        "question": "Which is not true about the theory of punctuated equilibrium?",
        "answer": "Punctuated equilibrium is one theory within evolutionary biology that seeks to explain the rate of evolution over time. This theory describes most species being in a state of stasis, with little change occurring. When change does occur, however, it is rapid. The theory of punctuated equilibrium stands in opposition to the theory of gradualism and is supported by the fossil record.",
        "suggested_answer": "Times of change are slow and continuous ",
        "options": [
            "It stands in contrast to gradualism ",
            "Species are typically in a state of stasis",
            "Times of change are rapid and rare",
            "Times of change are slow and continuous "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 177,
        "question": "Which of the following best describes when transcription initiates in developing embryos?",
        "answer": "In the developing embryo, transcription initiates at the end of cleavage, during a stage called the midblastula transition. ",
        "suggested_answer": "At the end of cleavage",
        "options": [
            "At the time of implantation",
            "At the end of cleavage",
            "At the time of fertilization",
            "During gastrulation"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 178,
        "question": "Which does not occur during the light dependent reaction?",
        "answer": "Carbon fixation, catalyzed by Rubisco, occurs during the first step of the light independent reaction. ",
        "suggested_answer": "carbon fixation is aided by the enzyme Rubisco",
        "options": [
            " is split, producing H+ ions, oxygen, and electrons",
            "carbon fixation is aided by the enzyme Rubisco",
            "NADPH is produced",
            "ATP is produced"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 179,
        "question": "Peptide neurohormones are primarily synthesized in what cellular structure?",
        "answer": "Peptide nuerohormones are synthesized in the rough endoplasmic reticulum of the cell body of the neuron. They are then packaged in the Golgi complex and transported along the axon to the nerve endings. Since peptide hormones must be transported out of the cell in vesicles, they are not likely to be synthesized by cytoplasmic ribosomes. These ribosomes are primarily involved in synthesizing cytoplasmic proteins that do not leave the cell.",
        "suggested_answer": "Rough endoplasmic reticulum",
        "options": [
            "Rough endoplasmic reticulum",
            "Nucleolus",
            "Smooth endoplasmic reticulum",
            "Nucleus",
            "Cytoplasmic ribosomes"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 180,
        "question": "A point mutation on a gene results in a premature stop codon being transcribed during DNA transcription. How will the protein translated by the mutated RNA template compare to one translated from the original gene?",
        "answer": "When a point mutation on the DNA strand creates a premature stop codon the RNA template will not be completely translated, resulting in a protein with a lower molecular weight due to fewer amino acid residues. As a result, the protein will also likely be nonfunctional. This is an example of a nonsense point mutation.",
        "suggested_answer": "A nonfunctional protein with a lower molecular weight",
        "options": [
            "The two proteins will be identical",
            "A nonfunctional protein with a lower molecular weight",
            "A nonfunctional protein with the same molecular weight",
            "A slightly altered protein with the same molecular weight",
            "A slightly altered protein with a lower molecular weight"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 181,
        "question": "Which of the following would the strongest type of bond present in a sample of water?",
        "answer": "The correct answer is covalent bonds because they are the strongest of all bonds present in a water sample. Although hydrogen bonding is present in water it is not the strongest bond in a sample of water. The bonds that make up the water molecule themselves are strongest. Ionic bonds do not exist in water.",
        "suggested_answer": "Covalent bond",
        "options": [
            "Ionic bond",
            "Hydrogen bond",
            "Covalent bond",
            "Van der Waals interactions",
            "Dipole-dipole interactions"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 182,
        "question": "Which of the following phase labels are used for all diatomic elements?",
        "answer": "Diatomic elements are commonly referred to as diatomic gases because whenever they are by themselves, they bond to themselves (that is where the \"2\" subscript comes from). Anytime diatomic elements have that \"2\" subscript, they have the gas (g) phase label. For example, .",
        "suggested_answer": "(g)",
        "options": [
            "(g)",
            "(r)",
            "(l)",
            "(aq)",
            "(s)"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 183,
        "question": "Which statement correctly describes the process of alternative splicing?",
        "answer": "Alternative splicing is the process where many different proteins can be formed from a primary transcript. This can be done in a number of ways, including using different splice sites, maintaining introns, and splicing out exons. Alternative splicing is an important process because it increases cellular efficiency; if many proteins can result from the same primary transcript, then the genome doesn\u2019t need to code for as many unique transcripts.",
        "suggested_answer": "Alternative splicing can create a variety of proteins from the same primary transcript",
        "options": [
            "In alternative splicing, some primary RNA transcripts are spliced and others are not",
            "There are different alternative splicing processes between prokaryotes and eukaryotes",
            "Alternative splicing can create a variety of proteins from the same primary transcript",
            "There are different methods of alternative splicing (spliceosomes, self-splicing, etc.)"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 184,
        "question": "A researcher discovers a highly bizarre eukaryotic species whose mature mRNA do not contain poly-A tails. Which of the following proteins might this species also lack?",
        "answer": "PABP (poly-A-binding protein) binds to the poly-A tail in the 3' UTR of eukaryotic mRNA. It is likely that this species would not have a PABP homolog because it does not have a poly-A tail.",
        "suggested_answer": "PABP",
        "options": [
            "eEF1",
            "eIF2",
            "PABP",
            "eIF4E"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 185,
        "question": "What is the purpose of chromatin?",
        "answer": "Chromatin serves as a scaffold for DNA and helps regulate gene activity. Chromatin can be condensed, as euchromatin, or loosely packed, as heterochromatin. The compactness of chromatin determines its level of activity. When it is loosely packed, it can be acted upon by DNA replication and/or transcription enzymes, and those genes may be expressed. Centromeres hold sister chromatids together. ",
        "suggested_answer": "To maintain structure of the chromosome and assist in controlling the activity of the genes",
        "options": [
            "To hold the sister chromatids together",
            "To maintain structure of the chromosome only",
            "To assist in controlling the activity of the genes only",
            "To maintain structure of the chromosome and assist in controlling the activity of the genes"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 186,
        "question": "Which of the following is true of the sporophyte of a plant?",
        "answer": "The sporophyte produces spores, which grow into gametophytes. The gametophyte produces both ovules and pollen, which unite to form a zygote. The zygote grows into the next sporophyte. The plant life cycle continues to alternate between these stages. In flowering plants, the gametophyte stage has been greatly reduced compared to the sporophyte stage.",
        "suggested_answer": "It produces spores, which grow into gametophytes",
        "options": [
            "It produces spores, which grow into gametophytes",
            "It produce seeds, which grow into gametophytes",
            "It produce zygotes, which grow into gametophytes",
            "None of these",
            "It produce zygotes, which grow into sporophytes"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 187,
        "question": "Where in the human body are the cells that undergo meiosis located?",
        "answer": "Meiosis is the process that forms haploid gametes, or sex cells, that will go on to form zygotes after fertilization. Meiosis is performed by germ line cells, which, in humans, are located in the gonads (ovaries of females and in the testicles of males).",
        "suggested_answer": "Gonads",
        "options": [
            "Bone marrow",
            "Gonads",
            "Small intestine",
            "All cells can undergo meiosis"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 188,
        "question": "During an action potential, depolarization of a neuron is caused by which of the following ion movements? ",
        "answer": "An electrical stimulus causes voltage-gated sodium channels in a neuron to open. Sodium then travels down its concentration gradient through the channels, into the cell. With the movement of sodium into the cell, the cell depolarizes (its membrane potential becomes more positive). The gradient that drives depolarization is established by the sodium-potassium pump, which causes two primary effects: the resting membrane potential is negative and there is a large concentration of sodium outside of the cell. When sodium channels open, sodium ions flow down both the electrical gradient formed by the negative membrane potential and the chemical gradient formed by ion concentrations.",
        "suggested_answer": "Influx of sodium ions",
        "options": [
            "Efflux of sodium ions",
            "Influx of potassium ions",
            "Influx of chlorine ions",
            "Influx of sodium ions",
            "Efflux of potassium ions"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 189,
        "question": "On a cellular level, why do heterotrophs need to eat food?",
        "answer": "Glucose is the primary molecule that heterotrophs use to make energy at a cellular level. Heterotrophs use glucose for a starting material in both fermentation and cellular respiration. However fermentation is performed without oxygen (anaerobic), while cellular respiration requires oxygen as a final electron receptor at the end of the electron transport chain.",
        "suggested_answer": "They need the electron carriers made from the breakdown of glucose, along with oxygen, to produce ATP in the process of cellular respiration.",
        "options": [
            "Heterotrophs do not need to eat food.",
            "They need the electron carriers made from the breakdown of proteins, along with oxygen, to produce ATP in the process of cellular respiration.",
            "They need the electron carriers made from the breakdown of glucose, along with oxygen, to produce ATP in the process of cellular respiration.",
            "They need the electron carriers made from the breakdown of proteins, along with oxygen, to produce ATP in the process of fermentation. ",
            "They need the electron carriers made from the breakdown of glucose, along with oxygen, to produce ATP in the process of fermentation. "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 190,
        "question": "Lamarck\u2019s ideas on biological evolution contained correct and incorrect notions. Which of his ideas is correct?",
        "answer": "While Lamarck had many revolutionary ideas on evolution, his most correct assumption was the importance of environmental changes on evolutionary adaptations. His theories on the inheritance of acquired traits and essentialsm do not fit into our current knowledge of evolution, DNA, and inheritance.",
        "suggested_answer": "Evolution is related to changes in adaptation to the environment",
        "options": [
            "Nervous fluids are passed on from generation to generation",
            "Evolution is related to changes in adaptation to the environment",
            "Acquired traits can be passed on to offspring",
            "Living forms become perfect with time"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 191,
        "question": "Which of these is a predictive power of evolutionary theory?",
        "answer": "Each of the above is true, but the predictive power of evolution lies in its ability to form testable hypotheses. Natural selection and descent with modification can be most visibly observed in bacterial populations, where new generations can arise in as little as forty minutes. Biologists in every field form hypotheses based on how a mechanism, organism, or process is adaptive to its environment.",
        "suggested_answer": "Evolution can be used to form hypotheses about how bacterial strains are likely to mutate over time.",
        "options": [
            "Evolution provides a framework for observations to form a unifying explanation for a wide range of phenomena.",
            "Evolution can be supported by observational evidence.",
            "None of these",
            "Evolution provides explanations about the origins of the diversity of life.",
            "Evolution can be used to form hypotheses about how bacterial strains are likely to mutate over time."
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 192,
        "question": "What functions does the autonomic nervous system control?",
        "answer": "The autonomic nervous system is a part of the peripheral nervous system that controls the function of internal organs including the heart, lungs, and gastrointestinal organs. This is done through the use of nerves that activate and/or inactivate glands and muscles.",
        "suggested_answer": "Function of internal organs",
        "options": [
            "None of these",
            "Control of body movements",
            "Function of internal organs",
            "Proper functioning of the brain"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 193,
        "question": "Which of the following contribute to genetic diversity?",
        "answer": "Meiosis, which includes independent assortment of homologous chromosomes and chromosomal crossover, contributes to genetic diversity. Independent assortment of chromosomes is the random distribution of one chromosome per homologous chromosomal pair to each daughter cell during anaphase I. Chromosomal crossover, which begins in prophase I, results in genetic recombination between each tetrad (homologous chromosomes). Nondisjunction is not a correct option as this typically results in large scale genetic abnormalities and infertility which subsequently does not contribute to a population's long-term genetic diversity. ",
        "suggested_answer": "Meiosis, independent assortment of homologous chromosomes, and chromosomal crossover",
        "options": [
            "Meiosis, nondisjunction, and chromosomal crossover",
            "Mitosis, nondisjunction, and chromosomal crossover",
            "Meiosis, independent assortment of sister chromatids, and chromosomal crossover ",
            "Mitosis, independent assortment of homologous chromosomes, and chromosomal crossover",
            "Meiosis, independent assortment of homologous chromosomes, and chromosomal crossover"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 194,
        "question": "Which part of the neuron receives information?",
        "answer": "Neurotransmitters bind to receptors on the dendrites, which causes an electrical signal to be sent to the cell body. The cell body then transfers this signal to the axon hillock before an action potential is sent down the axon. The axon terminates at the synaptic cleft, where it releases neurotransmitters to the dendrites of the next neuron.",
        "suggested_answer": "Dendrites",
        "options": [
            "Dendrites",
            "Synaptic cleft",
            "Axon",
            "Cell body",
            "Axon hillock"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 195,
        "question": "Which of the following best describes the order of the tissues associated with the basement membrane?",
        "answer": "The basement membrane is a matrix of cells that separates the outer tissue from the connective tissue. It functions as a barrier to prevent deep penetration and also anchors down the outer tissues (e.g. the epithelium).",
        "suggested_answer": "Outer tissue, basement membrane, connective tissue",
        "options": [
            "Outer tissue, basement membrane, connective tissue",
            "Basement membrane, outer tissue, bone",
            "Basement membrane, connective tissue, outer tissue",
            "Basement membrane, outer tissue, connective tissue"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 196,
        "question": "What are nodes of Ranvier?",
        "answer": "Nodes of Ranvier are unmyelinated portions of the axon, located in areas of the myelin sheath. The junctions between neurons are called synaptic clefts, where neurotransmitters from one neuron are released to cause a change in the receiving neuron.",
        "suggested_answer": "Unmyelinated portions of axons",
        "options": [
            "Unmyelinated portions of axons",
            "Junctions between neurons",
            "Projections off of the neuron cell body",
            "Myelinated portions of axons"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 197,
        "question": "Which of the following would not be expected during pregnancy?",
        "answer": "The corpus luteum is a structure in the ovary that secretes progesterone, and is preserved during pregnancy with the help of human chorionic gonadotropin (HCG). If fertilization does not take place, the corpus luteum will degenerate into the corpus albicans. As a result, corpus albicans formation only takes place if the woman is not pregnant.",
        "suggested_answer": "Formation of the corpus albicans",
        "options": [
            "Secretion of human chorionic gonadotropin (HCG)",
            "Formation of the corpus albicans",
            "Maintenance of the corpus luteum",
            "Blastocyst implantation",
            "Formation of the placenta"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 198,
        "question": "In which phase of meiosis does crossing over occur?",
        "answer": "Crossing over occurs during prophase I of meiosis I. Crossing over is the physical exchange of chromosome parts, resulting in recombinant chromosomes and increased genetic variability. In order for this to occur, there is a requirement that the two homologous chromosomes be aligned next to one another, which occurs in prophase I of meiosis during tetrad formation.",
        "suggested_answer": "Prophase I",
        "options": [
            "Prophase II",
            "Metaphase I",
            "Telophase I",
            "Prophase I",
            "Anaphase I"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 199,
        "question": "Which of the following describes a property of a heterotroph?",
        "answer": "Heterotrophs get energy from organic molecules. They are unable to fix carbon dioxide from the atmosphere into organic molecules, nor do they make oxygen as a byproduct of metabolism. They can consume autotrophs and heterotrophs (organic molecules) to get energy and carbon. ",
        "suggested_answer": "It oxidizes organic molecules to get its energy",
        "options": [
            "It makes organic molecules from ",
            "It uses sunlight filtered through pigment to get energy",
            "It emits ",
            "It oxidizes organic molecules to get its energy",
            "It obtains energy from the plants it consumes"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 200,
        "question": "Which of the following molecules is produced during the Krebs cycle?",
        "answer": "A turn of the Krebs cycle produces one ATP, three NADH, one FADH2, and two CO2.",
        "suggested_answer": "I and II",
        "options": [
            "II and III",
            "I and II",
            "I only",
            "II only"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 201,
        "question": "In a population where the allele frequency shifts by random chance, the mechanism of evolution at work is __________.",
        "answer": "Genetic drift occurs as a result of chance events causing changes in the allele frequency of a population. It doesn't favor the most fit individuals, but occurs at random.",
        "suggested_answer": "genetic drift",
        "options": [
            "migration",
            "mutation",
            "natural selection",
            "genetic drift"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 202,
        "question": "Which of the following describes a single genetic locus that controls more than one trait?",
        "answer": "The ability of a gene to affect an organism is multiple ways is called pleiotropy. During post-transcriptional modification, introns are removed from the mRNA sequence and exons are spliced together to create the desired protein product. By splicing the gene in different ways, different proteins can be produced, which will affect different traits.",
        "suggested_answer": "Pleiotropic",
        "options": [
            "Somatic",
            "Pleiotropic",
            "Polysomatic",
            "Epistatic",
            "Polygenic"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 203,
        "question": "In mitosis, what is the fourth phase in which the chromatids unwind and become elongated and the nuclear membrane starts to form around the DNA?",
        "answer": "Telophase is the fourth and final phase of mitosis in which the new chromatids unwind, the spindle apparatus breaks down, the cells becomes elongated, and a nuclear membrane starts to form around the decondensed DNA (chromatin). Prophase is the first phase of mitosis, metaphase is the second phase, and anaphase is the third phase.",
        "suggested_answer": "Telophase",
        "options": [
            "Telophase",
            "Anaphase",
            "Metaphase",
            "Prophase"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 204,
        "question": "Which of the following segments of the digestive system has the primary job of digesting food?",
        "answer": "The duodenum, in the small intestine, is a site of protein, carbohydrate, and fat digestion.",
        "suggested_answer": "Duodenum",
        "options": [
            "Colon",
            "Duodenum",
            "Jejunum",
            "Ileum"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 205,
        "question": "What is the chemical symbol for potassium?",
        "answer": "The symbol for potassium comes from the latin word for potassium, kalium. That is why the chemical symbol for potassium is .",
        "suggested_answer": "K",
        "options": [
            "Na",
            "Po",
            "K",
            "P",
            "Pt"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 206,
        "question": "Which of the following is least likely to cause protein denaturation?",
        "answer": "Denaturation of a protein means that the structure of the protein has changed, rendering it non-functional. The presence of an enzyme can alter the structure of a substrate protein, but is only likely to affect a small region of the protein structure. In contrast, most denaturing processes involve environmental changes that affect the protein as a whole.",
        "suggested_answer": "Presence of an enzyme",
        "options": [
            "Drop in pH",
            "Presence of an enzyme",
            "Temperature change",
            "Change in basicity"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 207,
        "question": "Which of the following is NOT true of genetic drift?",
        "answer": "Genetic drift is the random process of alleles being passed from parents to offspring. Increasing genetic diversity in a population requires introducing a greater number of alleles, which can only occur through mutations or addition of unrelated members to the population. Genetic drift only affects how already-existing alleles are passed down.",
        "suggested_answer": "It can increase the genetic diversity of a population",
        "options": [
            "It can lead to alleles being fixed in a population",
            "It can lead to loss of alleles from a population",
            "It can increase the genetic diversity of a population",
            "It results from the random transmission of alleles from parents to offspring in a population"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 208,
        "question": "Which of the following best describes what annual growth rings in trees represent?",
        "answer": "Cutting into the trunk of a tree to create a horizontal cross section reveals rings, called annual growth rings. These rings show new vascular cambium growth over the course of a year. Differences in growth speed change the size of the growth rings.",
        "suggested_answer": "New vascular cambium growth",
        "options": [
            "New primary xylem growth",
            "New cork cambium growth",
            "New vascular cambium growth",
            "New primary phloem growth"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 209,
        "question": "Which of the following characteristics is true for secondary oocytes?",
        "answer": "Secondary oocytes are haploid cells that are produced by primary oocytes through meiosis in a process called ootidogenesis. Secondary oocytes remain arrested in the metaphase II stage of meiosis until fertilization, when ootidogenesis is completed. The process of ootidogenesis also produces polar bodies.",
        "suggested_answer": "They are produced by a process called ootidogenesis",
        "options": [
            "They are formed through mitosis",
            "They arrest development prior to puberty ",
            "They are diploid",
            "They are produced by a process called ootidogenesis"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 210,
        "question": "Non-disjunction can result in which of the following?",
        "answer": "Non-disjunction occurs when sister chromatids fail to separate during meiosis, leading subsequent daughter cells to have an unequal number of chromosomes. This can result in a cell having one extra chromosome (trisomy), or missing one chromosome (monosomy).",
        "suggested_answer": "Both monosomy and trisomy",
        "options": [
            "Neither monosomy, nor trisomy can be caused by non-disjunction",
            "Both monosomy and trisomy",
            "Trisomy",
            "Monosomy"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 211,
        "question": "Which of the following is true regarding tropical rainforest biomes?",
        "answer": "Tropical rainforest biomes have the greatest biodiversity of all biomes. They have only two seasons (rainy and dry) and the soil in these environments is generally nutrient-poor. Tropical rainforests also have a multi-layered canopy that allows little light to penetrate to the ground.",
        "suggested_answer": "They have the greatest biodiversity",
        "options": [
            "They have the greatest biodiversity",
            "There is little rainfall",
            "There are three seasons: rainy, dry, and winter",
            "Soil is nutrient-rich"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 212,
        "question": "Which of the following is true about enzymes?",
        "answer": "Enzymes are all proteins, however there are some RNA molecules that have been found to catalyze reactions, but they are termed ribozymes, not enzymes. They speed up reactions by lowering the activation energy of a reaction and do not change the energy states of the reactants or products. ",
        "suggested_answer": "They lower the activation energy of a reaction.",
        "options": [
            "They are not catalysts. ",
            "They are lipids. ",
            "They lower the activation energy of a reaction.",
            "They lower the energy of reactants.",
            "They lower the energy of products. "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 213,
        "question": "Metabolic pathways that release energy by breaking down complex molecules to simpler compounds are referred to as __________.",
        "answer": "Catabolic pathways release energy by breaking down complex molecules. Anabolic pathways build molecules from simpler ones.",
        "suggested_answer": "catabolic",
        "options": [
            "destructive",
            "anabolic",
            "degradative",
            "catabolic"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 214,
        "question": "Which of the following is not a usually function of the roots of a plant?",
        "answer": "Roots are usually underground and not exposed to sunlight, do not usually contain chlorophyll, thus they do not perform photosynthesis. However, roots do provide stabiity, obtain water and nutrients from the soil, and store carbohydrates.",
        "suggested_answer": "Performance of photosynthesis",
        "options": [
            "None of these",
            "Performance of photosynthesis",
            "Storage of carbohydrates",
            "Providing stability",
            "Obtaining water and nutrients from the soil"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 215,
        "question": "What is the function of collagenous fibers?",
        "answer": "Collagenous fibers are composed primarily of collagen. Collagenous fibers bind bones together and connect different tissues to one another. Examples of collagenous fibers include tendons, cartilage, and blood vessels.",
        "suggested_answer": "Bind bones together and provide structural support",
        "options": [
            "Selective absorption of extracellular fluid",
            "Bind bones together and provide structural support",
            "Act as scaffolding for cells",
            "Allow for the recoil of organs"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 216,
        "question": "What happens during metaphase II of meiosis?",
        "answer": "During metaphase II, sister chromosomes line up at the metaphase plate, like during the metaphase of mitosis. Mitotic spindles are attached to the kinetochores during this stage. Homologous chromosomes have already been separated during anaphase I.",
        "suggested_answer": "Sister chromatids line up at the metaphase plate",
        "options": [
            "The nuclear membrane forms ",
            "Sister chromatids line up at the metaphase plate",
            "Homologous tetrads line up at the metaphase plate",
            "Chromosomes segregate to opposite poles"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 217,
        "question": "Which of the following is not a eukaryote?",
        "answer": "Remember that even fungi have well-seen nuclei and that the presence of a nucleus is what makes a eukaryote a eukaryote. Archaea are their own domain and they are as small as bacteria and other prokaryotes but are quite different. They live in extreme environments and often we cannot study them due to that but we have studied some such as the halophiles (salt-loving archaea).",
        "suggested_answer": "Archaea",
        "options": [
            "Fungi",
            "Archaea",
            "Animals",
            "Protists",
            "Plants"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 218,
        "question": "Which of the following is not an immune cell?",
        "answer": "Keratinocytes are not immune cells. Rather, they secrete a protein called keratin which is a large proportion of the extracellular matrix and makes up hair, nails, skin, and other parts of the body. All other cells are immune cells. ",
        "suggested_answer": "Keratinocytes",
        "options": [
            "Basophils",
            "Keratinocytes",
            "Macrophage",
            "B-cells",
            "Neutrophils"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 219,
        "question": "Cristae are located in __________.",
        "answer": "Cristae are found in mitochondria. Mitochondria are enclosed by an envelope of two membranes. The inner membrane is convoluted with infoldings called cristae. This is an adaption that increases the surface area of the inner membrane of the mitochondria so that ATP synthesis can be maximized. The chloroplast equivalent of cristea are the thylakoid membranes. The stroma is the matrix equivalent of the chloroplast. The plasma membrane is not nearly as convoluted as the inner mitochondrial membrane.",
        "suggested_answer": "mitochondria",
        "options": [
            "the stroma",
            "the plasma membrane",
            "chloroplasts",
            "mitochondria"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 220,
        "question": "Which of the following is true regarding the endothelium?",
        "answer": "The endothelium is a specialized type of epithelium that lines the lymphatic and circulatory system. The endothelium originates from the mesoderm during embryogenesis and develops into squamous cells. Within the lymphatic and circulatory systems, endothelial cells function as barriers, in blood clotting, and in inflammation.",
        "suggested_answer": "It is both the lining of the lymphatic system and a specialized type of epithelium",
        "options": [
            "It is a specialized type of epithelium",
            "It is both the lining of the lymphatic system and a specialized type of epithelium",
            "It is the lining of the lymphatic system",
            "It is neither the lining of the lymphatic system nor a specialized type of epithelium"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 221,
        "question": "Glycolysis takes place inside the cytoplasm of a cell. Which is not a product or reactant of this process?",
        "answer": "Oxygen is neither a product nor reactant in glycolysis. Glycolysis is unique in that it takes place within the cytoplasm of the cell and does not require oxygen. It is an anaerobic reaction that produces pyruvate, NAD, and ATP. Know the products and reactants of glycolysis.",
        "suggested_answer": "Oxygen",
        "options": [
            "ATP",
            "Pyruvate",
            "Oxygen",
            "Glucose",
            "All of the answer choices are involved in glycolysis."
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 222,
        "question": "Which of the following identifies when the premenstrual syndrome (PMS) manifests during the menstrual cycle of human females?",
        "answer": "Premenstrual syndrome (PMS) is a set of physical and emotional symptoms that occur 1 to 2 weeks before menstruation. The exact cause of PMS is not known but most likely involves hormonal changes, with the severity of PMS being influenced by environmental factors. Common symptoms include acne, bloating, and mood changes.",
        "suggested_answer": "1 to 2 weeks prior to menstruation",
        "options": [
            "At the time of menstruation",
            "At ovulation",
            "At the time of implantation",
            "1 to 2 weeks prior to menstruation"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 223,
        "question": "Which of the following hormones is produced in the posterior pituitary gland?",
        "answer": "The correct answer is oxytocin because this hormone is produced in the posterior pituitary gland along with antidiuretic hormone (ADH). Oxytocin is responsible for uterine contraction during labor in a positive feedback look mechanism.",
        "suggested_answer": "Oxytocin",
        "options": [
            "Growth hormone",
            "Epinephrine",
            "Melatonin",
            "Prolactin",
            "Oxytocin"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 224,
        "question": "Which of the following can be defined as the tissue in the interior of the leaf?",
        "answer": "The mesophyll is the interior of the leaf. Chloroplasts are mainly found within the mesophyll and leaves are the major sites of photosynthesis in plants.",
        "suggested_answer": "Mesophyll",
        "options": [
            "Mesophyll",
            "Stomata",
            "Grana",
            "Chlorophyll"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 225,
        "question": "Which of the following best describes the synapse?",
        "answer": "The synapse is the junction between two nerve cells through which neurotransmitters conduct an electrical signal.",
        "suggested_answer": "The junction between two nerve cells",
        "options": [
            "Myelinated portions of the axon",
            "It is a term used to describe the neuron in its entirety",
            "The junction between two nerve cells",
            "The area of the neuron where the nucleus is located"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 226,
        "question": "Which of the following would result in a cell with an abnormal number of chromosomes after meiosis?",
        "answer": "Crossing over, or recombination, is a process that takes place in the earlier stages of meiosis and promotes genetic diversity. During recombination, genetic material is exchanged between two homologous chromosomes. The chromosomes ultimately contain the same amount of genetic material after recombination, and are properly separated during subsequent divisions.",
        "suggested_answer": "Nondisjunction",
        "options": [
            "Cleavage of the securin protein",
            "Nondisjunction",
            "Recombination",
            "Separation of sister chromatids"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 227,
        "question": "If a cell is placed in a hypotonic solution, what will happen to the cell?",
        "answer": "A hypotonic solution is a solution in which there is a lower concentration of solutes in the solution than in the cell. Thus, water (the solvent) will enter the cell, causing the cell to swell.",
        "suggested_answer": "The cell will swell",
        "options": [
            "The cell will shrink",
            "The cell will swell",
            "The cell will maintain its size",
            "The cell will fuse with surrounding cells",
            " "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 228,
        "question": "Which of the following is a method plants use to attract potential pollinators?",
        "answer": "Attracting pollinators is key for many plants' success. There are a variety of strategies plants use such as the creation of nectar or odor as well as bright colors to attract pollinators. Also, some plants have ultraviolet patterns on their flowers to direct animals toward their nectar to facilitate pollination.",
        "suggested_answer": "All of these",
        "options": [
            "All of these",
            "Formation of bright or colorful flowers",
            "Production of nectar in its flowers",
            "Production of a foul or sweet odor"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 229,
        "question": "What differentiated plant cell is being described?",
        "answer": "As described in the beginning of this question, collenchyma cells are found in young stems and petioles (leaves) and function to provide flexible support to the plant. This is because chollenchyma cells lack secondary cell walls and do not produce lignin to harden them this protein is characteristic of sclerenchyma cells, which are also used to provide support/strength to the plant. ",
        "suggested_answer": "Collenchyma cells",
        "options": [
            "Parenchyma cells",
            "Sclerenchyma cells",
            "Sieve plate cells",
            "Collenchyma cells"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 230,
        "question": "Monomers of sugars form disaccharides and polysaccharides via what type of bonding?",
        "answer": "A phosphodiester bond occurs in DNA and RNA between nucleotides to form nucleic acids. Ester linkage occurs in fats and lipids between a carboxyl group and the carbon chain. A peptide bond occurs between two amino acids to form a peptide bond. Hydrogen bonding is the interaction of a hydrogen of one molecule with either a oxygen, nitrogen, or fluorine of another molecule.",
        "suggested_answer": "Glycosidic linkage",
        "options": [
            "Glycosidic linkage",
            "Peptide bond",
            "Hydrogen bond",
            "Ester linkage",
            "Phosphodiester bond"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 231,
        "question": "A maltose molecule can be broken down into two molecules of glucose via what reaction?",
        "answer": "Maltose is a disaccharide and glucose is a monosaccharide so the question is asking what will break the disaccharide into its respected monosaccharide. Hydrolysis is the addition of a water molecule to break the glycosidic linkage of a sugar. Dehydration reaction is a type of condensation reaction that will ultimately join two monosaccharides together via the removal of a water molecule. Polymerization is a general term with regards of monomers forming polymers through a reaction. Hydration reactions are the addition of a water molecule but do not necessarily break any bonds but are used form an alcohol.",
        "suggested_answer": "Hydrolysis",
        "options": [
            "Polymerization",
            "Hydration",
            "Dehydration",
            "Condensation",
            "Hydrolysis"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 232,
        "question": "In which phase of mitosis do the chromatin fibers become tightly coiled, the nuclei disappear, and the mitotic spindle begins to form?",
        "answer": "All these events occur in prophase, the first stage of mitosis. Metaphase involves the duplicated chromosomes being aligned along the metaphase plate, with each pair of sister chromatids attached to spindle fibers. During anaphase, the sister chromatids are pulled apart to opposite poles of the cell. Telophase is essentially the opposite of prophase: the DNA decondenses, the nuclear envelope begins to reform, and the mitotic spindle begins to disappear.  ",
        "suggested_answer": "Prophase",
        "options": [
            "Anaphase",
            "Prophase",
            "Telophase",
            "Metaphase"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 233,
        "question": "Which regulatory mechanism functions to increase water reabsorption and maintain homeostasis when blood pressure is below normal?",
        "answer": "There are two main systems designed to regulate water reabsorption in the kidney: antidiuretic hormone (vasopressin) and the renin-angiotensin-aldosterone system (RAAS). The RAAS system includes a combination of substances that raise blood pressure through arteriole constriction and sodium reabsorption. It employs a specialized tissue that supplies blood to the glomerulus, called the juxtaglomerular apparatus (JGA), which produces the enzyme renin when stimulated by a drop in blood pressure.",
        "suggested_answer": "Renin-angiotensin-aldosterone system (RAAS)",
        "options": [
            "Atrial natriuretic peptide",
            "Adrenocorticotropic hormone",
            "Renin-angiotensin-aldosterone system (RAAS)",
            "Substance P"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 234,
        "question": "What is the net production of ATP molecules in glycolysis?",
        "answer": "The net production of ATP is 2. ",
        "suggested_answer": "2",
        "options": [
            "2",
            "3",
            "1",
            "4",
            "5"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 235,
        "question": "In mammalian reproduction, what is the stigma?",
        "answer": "The increase in luteinizing hormone (LH) concentration during the follicular stage of the estrous cycle leads to the release of proteolytic enzymes from the follicle. These enzymes degrade the follicle tissue and create a hole called the stigma. The secondary oocyte exits the follicle from the stigma in a process called ovulation.",
        "suggested_answer": "The hole that forms in the follicle to allow for oocyte release",
        "options": [
            "The layer of cells surrounding the released oocyte ",
            "The entrance to the fallopian tubes",
            "The hole that forms in the follicle to allow for oocyte release",
            "The resulting diploid organism that develops after the fusion of an egg and sperm "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 236,
        "question": "A group of researchers in a laboratory have found what they believe to be an unknown organism. They notice that this organism lacks a nuclear membrane and mitochondria. Using this information, which of the following structures would this organism most likely possess?",
        "answer": "The organism is likely a prokaryotic organism, since it lacks a nuclear membrane and mitochondria. Prokaryotes lack all membrane-bound organelles, including nuclei, mitochondria, endoplasmic reticulum, chloroplasts, and lysosomes.",
        "suggested_answer": "Ribosomes",
        "options": [
            "Ribosomes",
            "Endoplasmic reticulum",
            "Lysosomes",
            "Cilia",
            "Chloroplasts"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 237,
        "question": "Histones are most closely linked to which of the following processes?",
        "answer": "Histones are the packaging proteins on which long DNA molecules are coiled before mitosis. In this respect they are involved in cell division, but they are most closely linked to chromosome condensation. During interphase DNA is mostly packaged as euchromatin. Euchromatin is loosely packed and is capable of being transcribed, making it essential for protein synthesis and cell growth during the G1 and G2 phases. During mitosis the DNA condenses into tightly-packed heterochromatin in the form of chromosomes, which allow for cell division. The difference in packaging between euchromatin and heterochromatin comes from histone proteins. During chromosome formation, DNA becomes tightly wound around the histones to create the chromosome structure.",
        "suggested_answer": "Chromosome formation",
        "options": [
            "Crossing over of genetic information",
            "Synthesis of ATP in the nucleolus",
            "Eukaryotic cell division",
            "Chromosome formation"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 238,
        "question": "If A is a dominant allele conferring brown hair and a is the recessive allele conferring blonde hair, a heterozygote as what genotype?",
        "answer": "A genotype is the genetic makeup of alleles coding for a characteristic. In this example, a heterozygote has one recessive and one dominant allele, making the genotype Aa. The phenotype is the observable expression of the genotype. Since a heterozygote has one dominant allele and one recessive allele, the dominant allele is exhibited, thus the phenotype is brown hair.",
        "suggested_answer": "Aa",
        "options": [
            "AA",
            "Blonde hair",
            "Brown hair",
            "Aa"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 239,
        "question": "Which of the following molecular changes leading to ovulation is caused by a spike in luteinizing hormone (LH)?",
        "answer": "The slow increase in estrogen concentration leading up to ovulation triggers the secretion of luteinizing hormone (LH) from the anterior pituitary gland. The spike in LH initiates signal transduction pathways that release proteolytic enzymes. These enzymes create a hole, or stigma, in the follicle that allows the secondary oocyte to exit.",
        "suggested_answer": "The release of proteolytic enzymes to form the stigma",
        "options": [
            "A release of hormones that develop the zona pellucida",
            "The maturation of the secondary oocyte",
            "The release of proteolytic enzymes to form the stigma",
            " Meiotic recombination of the secondary oocyte"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 240,
        "question": "Why is negative feedback inhibition important when it comes to containing population sizes?",
        "answer": "Negative feedback inhibition prevents populations from continually increasing in size. Without these such factors, population sizes would not be controlled. For example, perch feed on and find shelter in kelp. Where there are too many perch, there is not enough kelp to sustain them all (for food and shelter), and the fish population decreases in size. When there are less perch, there is enough kelp for them to feed on and hide in. Because conditions are favorable, the perch numbers increase. This negative feedback inhibition is density dependent and controls population size dynamics. ",
        "suggested_answer": "Negative feedback inhibition prevents populations from continually increasing in size",
        "options": [
            "Negative feedback inhibition continually supplies populations with resources necessary for life ",
            "Negative feedback inhibition is density independent and sustains populations in the face of environmental challenges",
            "Negative feedback inhibition is density dependent and sustains populations in the face of environmental challenges",
            "Negative feedback inhibition prevents populations from continually increasing in size",
            "Negative feedback inhibition sustains populations and allows them to continue increasing in size "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 241,
        "question": "Which of the following best describes the action of an enzyme?",
        "answer": "Enzymes are biological molecules that help catalyze reactions by lowering the energy of activation and increasing the rate of a reaction. They can do this by a number of mechanisms including: providing a template for substrates to join together in an efficient manner; distorting a substrate so it approaches the unstable/transition state; and providing a microenvironment conducive to a reaction. Inhibitors and activators can affect enzymes activity by slowing down and increasing enzyme activity respectively.",
        "suggested_answer": "Catalyze reactions by lowering energy of activation",
        "options": [
            "They represent the product of a reaction",
            "Catalyze reactions by lowering energy of activation",
            "Slows down reactions so in order to obtain more prducts from the substrate",
            "All of these"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 242,
        "question": "Which of the following choices identifies the plant structure that corresponds to gap junctions in animal cells?",
        "answer": "Plasmodesmata are the plant structures that are analogous to gap junctions in animal cells. Plasmodesmata are protein channels between the cell walls of plant cells. They facilitate communication and the transport of solutes and small proteins between plant cells.",
        "suggested_answer": "Plasmodesmata",
        "options": [
            "Membrane pore",
            "Pilus",
            "Plasmodesmata",
            "Synapse"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 243,
        "question": "Which body part would you not expect to develop from the ectoderm?",
        "answer": "The ectoderm is generally credited with the formation of the skin, teeth, nervous system, and sense organs. The lining of the digestive tract is formed from the endoderm.",
        "suggested_answer": "The intestinal lining",
        "options": [
            "The cerebellum",
            "The eyes",
            "The skin",
            "The intestinal lining",
            "The spinal cord"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 244,
        "question": "Which of the following is not composed of epithelial cells?",
        "answer": "Epithelial cells compose the outside of the body, namely skin and the lining of systems that connect to the outside of the body, such as the respiratory, excretory, and digestive tracts. The stomach is part of the digestive tract, as are the intestines. Knowing that the digestive tract is lined with epithelial cells allows us to eliminate the lining of the intestine from the answer choices. Similarly, the alveoli are a part of the respiratory system and nephrons are part of the excretory system.",
        "suggested_answer": "Lining of the chambers of the heart",
        "options": [
            "Nephron tubules",
            "Alveoli",
            "Lining of the chambers of the heart",
            "Skin",
            "Lining of intestinal tracts"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 245,
        "question": "The high concentration of __________ present in the stomach activates pepsinogen, allowing it to become active pepsin.",
        "answer": "Hydrochloric acid, produced by the parietal cells of the stomach, activates the zymogen pepsinogen. ",
        "suggested_answer": "hydrochloric acid",
        "options": [
            "saliva",
            "bile",
            "hydrochloric acid",
            "ATP",
            "undigested food"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 246,
        "question": "Of the following answer choices, which most accurately describes the mechanism that the stems of plants use to grow toward light?",
        "answer": "Plants grow so as to maximize the elongation of their stems as much as possible. Cells on the lighter side of the stem are already being provided with photosynthetic energy, while cells on the darker side are receiving less of this energy input. This causes the cells on the darker side to elongate toward the energy source. When one side of the stem is longer than the other, it causes a curve in the growth, resulting in a directionality of the growth of the stem.",
        "suggested_answer": "Cells on the darker side of the stem elongate more than cells on the lighter side",
        "options": [
            "The plant will grow away from other plants so as to minimize competition",
            "Photosynthetic processes dictate the growth",
            "Contractile cells on the light side of the stems dictate the growth toward light",
            "The growth of the stem is determined by metabolic processes, which are stimulated by light striking the stems",
            "Cells on the darker side of the stem elongate more than cells on the lighter side"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 247,
        "question": "Which of the following best describes the order of information processing in a neuron?",
        "answer": "A neuron receives input from other neurons at the dendrites. Neurotransmitters released by other neurons bind to receptors on the dendrites, which carry the signal to the cell body. The signal is then amplified in the cell body before being transferred to the axon. Once the signal transitions to the axon, it is considered an action potential. The signal eventually reaches the end of the axon, where the synaptic vesicles are located, and stimulates release of neurotransmitters to the next neuron's dendrites.",
        "suggested_answer": "Dendrite, cell body, axon, synaptic terminal",
        "options": [
            "Dendrite, axon, cell body, synaptic terminal",
            "Dendrite, axon, synaptic terminal, cell body",
            "Dendrite, cell body, axon, synaptic terminal",
            "Synaptic terminal, dendrite, cell body, axon",
            "Dendrite, synaptic terminal, cell body, axon"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 248,
        "question": "Which of the following best describes how type IIb skeletal muscle tissue differs from type IIa skeletal muscle tissue?",
        "answer": "Type IIb skeletal muscle tissue is a subtype of skeletal muscle tissue that is anaerobic and has fewer mitochondria than type IIa.",
        "suggested_answer": "Type IIb tissue is anaerobic while type IIa tissue is aerobic",
        "options": [
            "Type IIb tissue has many mitochondria while type IIa tissue has fewer mitochondria",
            "Type IIb tissue is anaerobic while type IIa tissue is aerobic",
            "Type IIb tissue is aerobic while type IIa tissue is anaerobic",
            "Type IIb tissue has no mitochondria while type IIa tissue has many mitochondria"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 249,
        "question": "A student notices that she can lyse plant cells under a microscope using an enzyme she found in class. She performs some additional experiments and determines that the enzyme is breaking down the cell wall of these cells causing them to lyse. She thinks that this same enzyme could be used to lyse bacterial cells. Will this enzyme work on bacteria? Why or why not?",
        "answer": "Bacteria have a cell wall made up of peptidoglycan, while plant cells have a cell wall made up of cellulose. The enzyme used by this student is cleaving the cellulose in the plant cell wall, causing these cells to lyse. This enzyme would not be able to cleave peptidoglycan because the molecules are structurally and chemically different.",
        "suggested_answer": "No, because the plant cell wall and bacterial cell what are made up of different molecules",
        "options": [
            "No, because the bacteria are too small for this enzyme to affect it",
            "Yes, because bacteria have cells walls that are made up of the same molecules as plant cell walls",
            "No, because the plant cell wall and bacterial cell what are made up of different molecules",
            "No, because bacteria don't have cell walls",
            "No, because bacteria have cell walls that are made up of chitin"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 250,
        "question": "Antibiotics should be used for which of the following pathogens?",
        "answer": "Antibiotics should be used to treat bacterial infections only. West Nile Virus is a virus and C. albicans is a fungus. The common cold is caused by an infection by a virus, most often rhinovirus and human coronavirus. K. pneumonia on the other hand, causes pneumonia, and a person will suffer from deep cough and congestion for months or until treated.",
        "suggested_answer": "Klebsiella pneumoniae",
        "options": [
            "Klebsiella pneumoniae",
            "All of these",
            "Candida albicans",
            "Common cold",
            "West nile virus"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 251,
        "question": "In chemotherapy, it is common to use drugs that can stabilize or depolymerize microtubules. Of the following statements, which is correct about these drugs?",
        "answer": "Microtubules are polymers of tubulin, and are components of the cytoskeleton. They are involved in mitosis, during which they are involved in the formation of the mitotic spindles. The drugs in chemotherapy are used in the disruption of mitotic processes. By disrupting microtubules, spindle fibers will be unable to form and the cell will be unable to complete anaphase.",
        "suggested_answer": "The drugs disrupt mitosis",
        "options": [
            "The drugs disrupt the condensation of chromosomes",
            "The drugs disrupt tumor movement",
            "The drugs stimulate an immune response",
            "The drugs disrupt mitosis",
            "The drugs disrupt endocytotic processes"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 252,
        "question": "What is the function of an ubiquitin ligase?",
        "answer": "Ubiquitin ligases add ubiquitin to their ligands. The addition of ubiquitin acts as a signal that a protein has become ineffective and is ready for degradation. When multiple ubiquitin residues have been added to a protein molecule, it is transported to the lysosome in the cell to be digested.",
        "suggested_answer": "Add an ubiquitin to its ligand",
        "options": [
            "Add an ubiquitin to its ligand",
            "Remove an ubiquitin from its ligand",
            "Remove a phosphate from its ligand",
            "Add a phosphate to its ligand"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 253,
        "question": "A human cell has 22 chromosomes in addition to a Y chromosome. It is __________.",
        "answer": "Since the cell has a total of 23 chromosomes it must be a gamete. Recall that the diploid number for humas is 2n=46. The presence of a Y chromosome means that it corresponds to a male gamete so it must be a sperm cell. ",
        "suggested_answer": "a sperm",
        "options": [
            "a male somatic cell",
            "a sperm",
            "a female somtic cell",
            "a female sex cell",
            "an ovum"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 254,
        "question": "The unique three-dimensional shape of a protein is called its __________.",
        "answer": "Conformation is the the term for the three dimensional structure of proteins. Though the types of proteins are incredibly diverse they are all polymers made up of the same set of 20 amino acids. A protein's molecular weight involves the sum of all the atoms and their abundances, molecular weight is often used to approximate the size of a protein when determining if it will pass through a pore or channel in a membrane. The amino acid sequence is the primary structure, and is held together by peptide bonds.",
        "suggested_answer": "conformation",
        "options": [
            "conformation",
            "sequence",
            "primary structure",
            "molecular weight",
            "peptide configuration"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 255,
        "question": "Which of the following contributes to the formation of the blastocoel during blastula development?",
        "answer": "The blastocoel is a fluid-fill cavity region of the blastocyst. The blastocoel is formed through cell polarization, when trophoblasts and embryoblasts differentiate. The trophoblasts are the outer layer of cells that compact to become watertight. Embryoblasts polarize to one end of the cell and form gap junctions for cell-cell communication and developmental coordination. The trophoblasts then secrete fluid into the cavity, which forms the blastocoel.",
        "suggested_answer": "All of these",
        "options": [
            "All of these",
            "Compaction of trophoblasts",
            "Gap junctions between embryoblasts",
            "Cell polarization"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 256,
        "question": "In which component of the digestive tract does no digestion occur?",
        "answer": "The esophagus is responsible for transporting food from the mouth to the stomach via peristalsis, which consists of smooth muscle contractions, but does not help digest the food content in the process. The mouth, stomach, and small intestine, however, all help in the process of food digestion. The mouth introduces enzymes in the saliva, the stomach introduces pepsin, and the small intestine introduces a number of other enzymes for digestion of macromolecules.",
        "suggested_answer": "Esophagus",
        "options": [
            "Stomach",
            "Esophagus",
            "Small intestine",
            "Mouth"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 257,
        "question": "Which of the following best describes where mitochondria are localized in spermatozoa?",
        "answer": "Mitochondria cluster around the axoneme during spermiogenesis. They supply the energy needed for sperm movement, which is achieved through movement of the flagellum.",
        "suggested_answer": "Around the axoneme",
        "options": [
            "Near condensed DNA",
            "At the acrosome",
            "Around the axoneme",
            "At the plasma membrane"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 258,
        "question": "Which of the following is not a Hardy-Weinberg assumption?",
        "answer": "One of the five main assumptions is that mutations are negligible. This makes sense because, if a population is in Hardy-Weinberg equilibrium, evolution is not occurring. A low rate of mutations would help keep a population in equilibrium.",
        "suggested_answer": "Mutation frequencies are high",
        "options": [
            "Natural selection is not in operation",
            "Random mating occurs",
            "Mutation frequencies are high",
            "The population is large"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 259,
        "question": "Which of the following is true regarding competitive and noncompetitive inhibition?",
        "answer": "Statement I is false because increasing the substrate concentration will only help overcome competitive inhibition. Noncompetitive inhibition can only be overcome if the inhibitor is removed from the enzyme.",
        "suggested_answer": "III only",
        "options": [
            "II only",
            "II and III",
            "III only",
            "I only"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 260,
        "question": "Which model of enzyme substrate binding posits that there is a transition state that develops before the reactants undergo change",
        "answer": "The lock and key model states that the active site of an enzyme precisely fits a specific substrate. The induced fit model states that the active site of an enzyme will undergo a conformational change when binding a substrate, to improve the fit.",
        "suggested_answer": "Induced fit",
        "options": [
            "Induced fit",
            "Lock and key",
            "Catalysis",
            "Conformational change"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 261,
        "question": "One species of organisms living in the same valley begin to diverge from each other over time and eventually two distinct species emerge that no longer mate with members of the other group. What type of speciation is this?",
        "answer": "Sympatric speciation occurs when a species of organisms becomes two different species whilst inhabiting the same area. Geographic barriers do not play a role in their divergence from one another. Allopatric speciation occurs because of a geographical barrier such as a mountain range. Sympathetic is not an evolutionary term and allosteric refers to specific sites on molecules in molecular biology and biochemistry.",
        "suggested_answer": "Sympatric speciation",
        "options": [
            "Allopatric speciation",
            "Allosteric speciation",
            "Sympathetic speciation",
            "Sympatric speciation",
            "None of these - this is not an example of speciation"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 262,
        "question": "Which of the following is directly produced during gene transcription by RNA polymerase?",
        "answer": "When RNA polymerase binds to a template strand of DNA, it recruits complementary ribonucleotides to form a strand of RNA. This strand of RNA, however, is incomplete and must undergo post-transcriptional modification to become a mature mRNA product. The initial RNA transcript is known as heteronuclear RNA, or htRNA.",
        "suggested_answer": "htRNA",
        "options": [
            "rRNA",
            "mRNA",
            "miRNA",
            "htRNA"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 263,
        "question": "All of the following biomolecules form true polymers except __________.",
        "answer": "Fatty acids occur as free molecules in trace amounts in living organisms. They usually occur as a component of several types of lipid molecules, and are not true monomers, even though 1-3 of them can be attached to a polar head group such as glycerol or a phosphate group. The rest of the choices listed form polymers. Amino acids form proteins, sugars such as glucose form carbohydrates, and nucleotides form both DNA and RNA.",
        "suggested_answer": "fatty acids",
        "options": [
            "glucose",
            "fatty acids",
            "sugars",
            "nucleotides",
            "amino acids"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 264,
        "question": "Which of the following is not an example of a collagenous fiber in connective tissue?",
        "answer": "Collagenous fibers connect tissue types and bind elements of the musculoskeletal system to one another. Types of collagenous fibers in connective tissue include blood vessels, ligaments, and tendons. Stratified epithelium is comprised of layers of epithelial cells, which function as a covering/lining to a body structure that is subject to lots of friction.",
        "suggested_answer": "Stratified epithelium",
        "options": [
            "Blood vessels",
            "Tendons",
            "Ligaments",
            "Stratified epithelium"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 265,
        "question": "Which of the following terms describes a relationship between two different species, in which both species benefit?",
        "answer": "Symbiosis describes a relationship in which both parties benefit. It has been theorized that eukaryotic cells evolved from a symbiotic relationship in which one prokaryote engulfed another. The engulfed cell gained protection from the environment, while the engulfing cell gained an energy source from the engulfed cell.",
        "suggested_answer": "Symbiosis",
        "options": [
            "Amensalism",
            "Synnecrosis",
            "Parasitism",
            "Commensalism",
            "Symbiosis"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 266,
        "question": "Which of the following ions are intracellularly released during the fertilized egg\u2019s cortical reaction?",
        "answer": "After fertilization and sperm cell penetration of the oocyte, the cortical reaction takes place. During the cortical reaction, a release of intracellular calcium ions triggers the exocytosis of cortical granules. Cortical granules are vesicles in the cortex of the oocyte that contain enzymes that prevent polyspermy. Exocytosis of the cortical granules releases their contents into the extracellular matrix the zona pellucida in mammals creating changes to prevent further sperm penetration.",
        "suggested_answer": "Calcium ions",
        "options": [
            "Sodium ions",
            "Potassium ions",
            "Calcium ions",
            "Chlorine ions"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 267,
        "question": "Which blood vessel type can be constricted in order to reroute blood?",
        "answer": "Vasoconstriction is a function of the smooth muscle that surrounds the vasculature. In order to reroute blood, the vessel needs to have a substantial amount of smooth muscle.",
        "suggested_answer": "Arteriole",
        "options": [
            "Capillary",
            "Venule",
            "Artery",
            "Arteriole"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 268,
        "question": "Which of the following best describes how monozygotic twins are produced?",
        "answer": "Monozygotic twins are produced when a zygote divides into two embryos. This process is spontaneous and may result from blastocyst collapse. The resulting twins are genetically identical because they are produced from the same gametes.",
        "suggested_answer": "One zygote splits into two embryos during development ",
        "options": [
            "Nondisjunction",
            "Two eggs are released and fertilized ",
            "One zygote splits into two embryos during development ",
            "Fertilization of one egg by two sperm "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 269,
        "question": "In facilitated diffusion, what occurs in the cell?",
        "answer": "In facilitated diffusion, molecules, such as glucose move through protein channels from an area of higher concentration to an area of lower concentration. It is not active transport, using energy. Movement of molecules through the cell membrane without use of a protein is known as simple diffusion; osmosis is a type of simple diffusion.",
        "suggested_answer": "Molecules, such as glucose, move through protein channels from an area of higher concentration to lower concentration.",
        "options": [
            "Protein pumps use energy to pump molecules, such as glucose across the cell membrane.",
            "Molecules, such as glucose move from an area of lower concentration to an area of higher concentration.",
            "Osmosis occurs.",
            "Molecules, such as glucose, move through protein channels from an area of higher concentration to lower concentration."
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 270,
        "question": "In humans, are all types of connective tissue fibrous?",
        "answer": "In the human body, not all types of connective tissue contain fibers. Examples of non-fibrous connective tissues are blood, adipose tissue, and bone which are made of plasma and cells, fat, and mineralized extracellular matrix, respectively.",
        "suggested_answer": "No, not all connective tissue is fibrous, including blood and adipose tissue",
        "options": [
            "Yes, all connective tissue contains at least one type of fiber (collagenous, reticular or elastic)",
            "Yes, all connective tissue contains all three types of fibers (collagenous, reticular, and elastic)",
            "No, not all connective tissue is fibrous, including blood and adipose tissue",
            "No, not all connective tissue if fibrous because in some areas of the body the ground substance degrades fibers"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 271,
        "question": "In what part of the cell does glycolysis occur?",
        "answer": "Glycolysis is an anaerobic process that occurs in the cytosol or the cytoplasm of the cell. This process does not require any organelles to take place. The mitochondria is where the Krebs cycle and the electron transport chain occur.",
        "suggested_answer": "Cytoplasm",
        "options": [
            "Endoplasmic reticulum",
            "Cytoplasm",
            "Inner mitochondrial membrane",
            "Golgi apparatus",
            "Mitochondrial matrix"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 272,
        "question": "A mother with type A blood and a father with type B blood have a child. What blood type is impossible for that child to have?",
        "answer": "The mother's possible genotypes for blood are AO and AA, while the father's are BO and BB; therefore, the child could have any blood type because we could receive an O allele from either parent.",
        "suggested_answer": "All are possible",
        "options": [
            "All are possible",
            "O",
            "A",
            "B",
            "AB"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 273,
        "question": "Which of the following molecules can freely diffuse through the plasma membrane?",
        "answer": "Membranes are permeable to small, nonpolar molecules like oxygen. Though oxygen is commonly involved in polar bonds, the diatomic molecule has no net dipole, allowing it to cross the membrane. This function is essential for gas exchange, in which oxygen passes from the alveoli of the lungs into adjacent capillaries.",
        "suggested_answer": "Oxygen",
        "options": [
            "Glucose",
            "Potassium ions",
            "Oxygen",
            "Cholesterol"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 274,
        "question": "Researchers analyze a rare virus. They find that the virus contains reverse transcriptase and has a latent period of three years. Based on this information what can you conclude?",
        "answer": "Remember that reverse transcriptase is an enzyme that facilitates the generation of DNA from RNA. A virus containing reverse transcriptase must initially possess an RNA genome. Once inside the host cell the virus generates complementary DNA from the RNA template. You can eliminate the choices involving DNA.",
        "suggested_answer": "Upon infection, the virus injects RNA into the cell and goes into the lysogenic cycle",
        "options": [
            "Upon infection, the virus injects RNA into the cell and goes into the lytic cycle",
            "Upon infection, the virus injects RNA into the cell and goes into the lysogenic cycle",
            "Upon infection, the virus injects DNA into the cell and goes into the lysogenic cycle",
            "Upon infection, the virus goes into the lysogenic cycle, but the type of genetic material cannot be determined",
            "Upon infection, the virus injects DNA into the cell and goes into the lytic cycle"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 275,
        "question": "What is the main structural component of a plant cell wall?",
        "answer": "Cellulose, a polymer of glucose, is the main component of plant cell walls. ",
        "suggested_answer": "Cellulose",
        "options": [
            "Chitin",
            "Actin and myosin",
            "Collagen",
            "Cellulose",
            "Peptidoglycan"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 276,
        "question": "Which of the following hormones do most pregnancy tests look for?",
        "answer": "Most pregnancy tests look for human chorionic gonadotropin (hCG). The hormone hCG is secreted by the outer layer of the blastocyst, called the synctiotrophoblast, and later the placenta.",
        "suggested_answer": "Human chorionic gonadotropic (hCG)",
        "options": [
            "Human chorionic gonadotropic (hCG)",
            "Follicle-stimulating hormone (FSH)",
            "Estrogen",
            "Progesterone"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 277,
        "question": "Why is oxygen essential for the electron transport chain?",
        "answer": "Oxygen serves as the terminal electron acceptor for the electron transport chain. Electrons are donated by NADH molecules and passed through several different proteins to generate the proton gradient in the intermembrane space. Upon reaching the final protein, the electron is bonded to an oxygen molecule to create water. Without oxygen, there would be nowhere for the electrons to go after being pumped through the electron transport chain, and aerobic cellular respiration would be impossible.",
        "suggested_answer": "It serves as the terminal electron acceptor",
        "options": [
            "It serves as the primary electron donor",
            "It is part of the chemiosmotic gradient",
            "It serves as the terminal electron acceptor",
            "It is essential for transporting pyruvate into the mitochondria"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 278,
        "question": "What is the organelle in plant cells that contains chlorophyll?",
        "answer": "Chloroplasts are the organelles that contains chlorophyll. Mitochondria produce ATP and are not directly involved in capturing light and photosynthesis. The Golgi apparatus is involved in packaging substances, and Smooth endoplasmic reticulum are involved in lipid production.",
        "suggested_answer": "Chloroplasts",
        "options": [
            "Smooth endoplasmic reticulum",
            "Mitochondria",
            "Chloroplasts",
            "Golgi apparatus"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 279,
        "question": "An electrical synapse uses __________ to transmit electrical signals between neurons.",
        "answer": "There are two types of synapses: chemical and electrical. Chemical synapses use chemical signals called neurotransmitters to transmit nerve signals between neurons, whereas electrical synapses use electrical signals. These electrical signals are transmitted through a gap junction that connects adjacent neurons. Intercalated discs in cardiac muscle contain gap junctions for the purpose of propagating electrical signals to cause systole.",
        "suggested_answer": "gap junctions",
        "options": [
            "gap junctions",
            "anchoring junctions",
            "desmosomes",
            "tight junctions"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 280,
        "question": "In the human body, each cell has a specific number of chromosomes. Whenever a cell has exactly one chromosome more than usual in its nucleus, this is referred to as which of the following?",
        "answer": "The abnormal number of chromosomes in a cell is referred to as aneuploidy. This event occurs when the chromosomes do not separate properly in cell division. Only one chromosome is affected, leaving one too many or too few copies.",
        "suggested_answer": "Aneuploidy",
        "options": [
            "Polyploid",
            "Alloploid",
            "Haploid",
            "Triploid",
            "Aneuploidy"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 281,
        "question": "During mitosis, which protein motor is involved in moving sister chromatids to opposite poles of the dividing cell?",
        "answer": "Motor proteins are a class of proteins that use energy from ATP hydrolysis to move along a substrate. Two classes of motor proteins control movement along microtubules: kinesins and dyneins. Kinesins and dyneins differ from one another in the direction of their movement; kinesins walk towards the positive end of the microtubule and dyneins move towards the negative end. During mitosis, mitotic spindles originate from the centrosomes. The end of the microtubule at the centrosome is the negative end, while the end attached to the kinetochore is the positive end. Thus, to move a sister chromatid to a cell pole, towards the negative end of a microtubule, a dynein is involved. ",
        "suggested_answer": "Dynein",
        "options": [
            "Myosin",
            "Kinesin",
            "Prestin",
            "Dynein"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 282,
        "question": "Which portion of the respiratory system extends from the larynx to the bronchi? ",
        "answer": "Anterior to the esophagus, the trachea begins at the larynx (voice box) and extends down towards the lungs where it splits into the bronchi. The trachea is a conducting structure, meaning no gas exchange occurs in the trachea. Instead, it is held open by \"C\" shaped rings of cartilage to allow maximum airflow between the lungs and the air in the environment. ",
        "suggested_answer": "Trachea",
        "options": [
            "Alveoli",
            "Pharynx",
            "Trachea",
            "Bronchioles",
            "Esophagus"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 283,
        "question": "Which of the following types of data are used to generate phylogenetic trees?",
        "answer": "Phylogenetics is the study of relationships between organisms and groups of organisms. This is done through the production of phylogenetic trees, which are used to describe these relationships. To make phylogenetic trees, scientists use molecular sequencing and/or morphological similarities in order to characterize the relationships between organisms.",
        "suggested_answer": "Both molecular sequencing data and morphological similarities",
        "options": [
            "Both molecular sequencing data and morphological similarities",
            "Neither molecular sequencing data nor morphological similarities",
            "Molecular sequencing data",
            "Morphological similarities"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 284,
        "question": "Which of the following best represents a difference between mitosis and meiosis?",
        "answer": "The processes of mitosis and meiosis have many characteristics in common (i.e. cytokinesis, chromosome condensation, and nuclear membrane reformation); however, there are key differences that distinguish the processes from one another. One of these differences is the cell type that result from each respective process. Mitosis results in two daughter cells with the same number of chromosomes as the parent cell. Meiosis, on the other hand, yields four daughter cells with half of the number of chromosomes as the parent cell haploid cells.",
        "suggested_answer": "The resulting number of chromosomes per cell",
        "options": [
            "The resulting number of chromosomes per cell",
            "Chromosome condensation",
            "The process of cytokinesis",
            "Nuclear membrane reformation"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 285,
        "question": "Which of the following describes the role of chemiosmosis in cellular respiration?",
        "answer": "Oxidative phosphorylation is composed of electron transport and chemiosmosis. Chemiosmosis occurs when ions cross a selectively permeable membrane down their concentration gradient. In cellular respiration, hydrogen ions (protons) move down their concentration gradient through a membrane protein to produce ATP. The gradient of protons is established by the electron transport portion of oxidative phosphorylation, which is used to transfer protons into the intermembrane space. Protein complexes I, II, III, and IV help protons to cross the membrane.",
        "suggested_answer": "Oxidative phosphorylation generates ATP by movement of protons down their electrochemical gradient ",
        "options": [
            "Glycolysis generates ATP by movement of protons down their electrochemical gradient ",
            "Oxidative phosphorylation generates ATP by movement of protons down their electrochemical gradient ",
            "Substrate-level phosphorylation transports electrons between complexes I, II, III, and IV ",
            "Oxidative phosphorylation produces NADH",
            "Substrate-level phosphorylation generates ATP by movement of protons down their electrochemical gradient "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 286,
        "question": "Finches with medium beak size feed on seeds. These birds aren't specialized to feed on insect larvae or cactus fruit like finches with short or long beaks. What type of selection would be observed if deforestation removed all the trees from the area where these birds obtained seeds?",
        "answer": "In this example the extreme beak sizes are favored over the mean; however the mean beak size does not change. There is selection against the mean, without affecting the value of the mean. This type of selection is known as disruptive selection.",
        "suggested_answer": "Disruptive selection",
        "options": [
            "Disruptive selection",
            "Sexual selection",
            "Directional selection",
            "Stabilizing selection",
            "Multi-directional selection"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 287,
        "question": "What muscle type is responsible for digestive peristalsis?",
        "answer": "There are three types of muscle tissue: skeletal muscle, smooth muscle, and cardiac muscle. Skeletal muscle is under voluntary control and is mainly responsible for locomotion and skeletal movement. Smooth muscle is involuntary and surrounds the viscera to aid in vascular constriction and organ movement. Cardiac muscle is found only in the heart and is responsible for pumping the blood.",
        "suggested_answer": "Smooth muscle only",
        "options": [
            "Smooth muscle and skeletal muscle",
            "Smooth muscle only",
            "Voluntary muscle only",
            "Skeletal muscle only",
            "All muscle types may undergo peristalsis "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 288,
        "question": "How might Grave's disease cause hyperthyroidism?",
        "answer": "Hyperthyroidism is an over-secretion of the thyroid hormones, resulting in an increase in metabolism and energy consumption. Grave's disease is the most common cause of hyperthyroidism. It is an autoimmune disorder in which the body's immune system produces antibodies that bind to the thyroid-stimulating hormone (TSH) receptor. The binding of the antibody to the receptor serves as a non-hormonal activation of the receptor and thyroid hormone continues to be produced, even when TSH is not present. The normal negative feedback mechanism to maintain thyroid hormone levels is bypassed.",
        "suggested_answer": "Antibody binding and activation of thyroid-stimulating hormone receptors ",
        "options": [
            "A cellular immune response selectively destroys thyroid-stimulating hormone producing cells in the pituitary",
            "A cellular immune response selectively destroys cell in the parathyroid glands",
            "Antibody binding and inactivation of thyroid-stimulating hormone",
            "Antibody binding and activation of thyroid-stimulating hormone receptors "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 289,
        "question": "Beginning at the stomach, what is the order of the parts of the small intestine?",
        "answer": "When digested food exits the stomach, it passes through the pyloric sphincter and enters the duodenum, the first region of the small intestine. The duodenum is the introduction point for the pancreatic digestive enzymes, as well as the neutralization of stomach acid. The majority of chemical digestion occurs in the duodenum. The middle region of the small intestine is the jejunum, and the final region is the ileum. These regions are primarily responsible for nutrient absorption and have the largest concentration of microvilli. After passing through the ileum, the digested matter enters the large intestine.",
        "suggested_answer": "Duodenum, jejunum, ileum",
        "options": [
            "Ileum, duodenum, jejunum",
            "Duodenum, jejunum, ileum",
            "Jejunum, ileum, duodenum",
            "Duodenum, ileum, jejunum"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 290,
        "question": "An action potential will only be initiated if __________.",
        "answer": "Threshold potential is defined as the potential that must be reached in order for an action potential to be initiated by a neuron. Threshold potential is around -55mV in humans, which is slightly higher than the resting potential of -70mV. Once this threshold is reached, the electrical signal will propagate as the membrane depolarizes to a positive potential.",
        "suggested_answer": "the neuron reaches the threshold potential",
        "options": [
            "a stimulus occurs",
            "the muscle reaches the threshold potential",
            "the neuron is depolarized to -60mV",
            "the neuron reaches the threshold potential",
            "the muscle contracts"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 291,
        "question": "Which of the following best represents a difference between the fast and slow blocks to polyspermy?",
        "answer": "Both the fast and slow blocks to polyspermy prevent the penetration of a second sperm cell into the oocyte. The fast block to polyspermy is the initial and immediate block upon sperm binding. The fast block is an electrical block caused when oocyte membrane depolarization is started by an influx of calcium ions. The slow block is characterized by a change in zona pellucida makeup through the cortical reaction, which prevents sperm cell binding. The cortical reaction is triggered by the release of intracellular calcium.",
        "suggested_answer": "Fast block involves sodium ion transport and the slow block involves calcium ion transport",
        "options": [
            "Sperm cells can overcome the fast block to polyspermy but not the slow block",
            "The fast block involves an increase in intracellular ion concentration and the slow block does not",
            "None of these",
            "Fast block involves sodium ion transport and the slow block involves calcium ion transport"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 292,
        "question": "White mice are homozygous for a recessive pigmentation trait. Brown mice are homozygous dominant for the same pigmentation trait, and beige mice are heterozygous for the trait. In a particular ecosystem, natural selection favors the beige mice because they blend in with the brush and leaves. What concept explains to this type of selection? ",
        "answer": "Heterozygote advantage occurs when heterozygotes at a particular locus, such as pigmentation, have greater fitness than do both kinds of homozygotes. If the heterozygote favors an intermediate phenotype, such as beige mice, it is also stabilizing selection. Frequency-dependent selection occurs when the fitness of a phenotype depends on how common it is in the population. Together, heterozygote advantage and frequency-dependent selection lead to a balancing system. The founder effect occurs when a few individuals of a population become isolated and form a new population whose gene pool differs from the original population. Directional selection occurs when conditions favor individuals at one extreme of a phenotypic range, thereby shifting a population\u2019s frequency curve for the phenotypic character in one direction or another.",
        "suggested_answer": "Heterozygote advantage",
        "options": [
            "Directional selection",
            "Balancing selection",
            "Founder effect",
            "Frequency-dependent selection",
            "Heterozygote advantage"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 293,
        "question": "What percentage of the F2 generation will be homozygous? ",
        "answer": "The F1 generation will be entirely heterozygous, thus the F2 generation is the result of a heterozygous self-cross. A Punnet square reveals that 75% of the generation will be purple (PP or Pp) and 25% will be white (pp). Of the three purple flowers in the punnett square, two of them are heterozygous for color (Pp). The other flower is homozygous for the purple allele (PP). In addition, the white flower is homozygous for the recessive white allele (pp).",
        "suggested_answer": "50%",
        "options": [
            "25%",
            "50%",
            "Further information is needed to determine the answer",
            "100%"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 294,
        "question": "Which of the following best describes how not having ADH will affect a person's blood pressure and blood osmolarity?",
        "answer": "When the blood pressure is low or when the body\u2019s osmolarity is high, the posterior pituitary releases ADH. The reabsorption of water will increase the blood\u2019s volume and result in an increase in blood pressure. With the reabsorption of water, the osmolarity is lowered. Recall that osmolarity is the amount of solutes (e.g. sodium, potassium, chloride, etc.) over the amount of solvent (e.g. water). When water is reabsorbed, the water\u2019s volume increases; therefore, the osmolarity is decreased. Without ADH, blood pressure becomes low and without enough water the osmolarity of the blood increases hyperosmolarity.",
        "suggested_answer": "Without ADH the blood pressure will be low and the solute concentration will be hyperosmotic",
        "options": [
            "Without ADH the blood pressure will be high and the solute concentration will be hyperosmotic",
            "Without ADH the blood pressure will be high blood pressure and the solute concentration will be hypo-osmotic",
            "None of these",
            "Without ADH the blood pressure will be low and the solute concentration will be hyperosmotic",
            "Without ADH the blood pressure will be low and the solute concentration will be hypo-osmotic"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 295,
        "question": "Where in the human body can smooth muscle tissue be found?",
        "answer": "Smooth muscle tissue is a categorization of muscle tissue that is involved in involuntary contractions. Smooth muscle tissue can be found within the walls of organs such as the bladder, intestines, and stomach.",
        "suggested_answer": "The walls of organs",
        "options": [
            "Only in the heart",
            "The walls of organs",
            "Anchored to bones",
            "Within bone marrow "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 296,
        "question": "Which of the following cell types secrete fluid into the cavity during the formation of the blastocoel during blastula development?",
        "answer": "The blastocoel is a fluid-filled cavity that is a part of the blastocyst. A compacted trophoblast cell layer surrounds the cavity, making it watertight. Trophoblast cells then secret fluid into the cavity.",
        "suggested_answer": "Trophoblast",
        "options": [
            "Trophoblast",
            "Embryoblast",
            "Epiblast",
            "Synctiotrophoblast"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 297,
        "question": "Which of the following describes ionic bonds?",
        "answer": "There are two primary types of intramolecular bonds: ionic bonds and covalent bonds. In an ionic bond, an electron is transferred (donated) from one atom to the other, usually allowing both atoms to satisfy the octet rule. In a covalent bond, electrons are shared between two atoms in order to allow both to satisfy the octet rule.",
        "suggested_answer": "An electron is donated from one atom to another",
        "options": [
            "A proton is donated from one atom to another",
            "An electron is shared between two atoms",
            "A proton is shared between two atoms",
            "Ionic bonds are weak, transient bonds between two water molecules",
            "An electron is donated from one atom to another"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 298,
        "question": "Where are electrons located in an atom?",
        "answer": "Electrons are located in an electron cloud, which surrounds a densely packed center known as the nucleus. The nucleus is composed of protons and neutrons. Note that the valence shell contains electrons, but only refers to the region of the electron cloud farthest from the nucleus.",
        "suggested_answer": "In the electron cloud",
        "options": [
            "In the nucleus",
            "In the valence shell",
            "In the electron nucleus",
            "In the electron cloud",
            "In the dense nucleus"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 299,
        "question": "Which law of thermodynamics states that there will always be an increase in entropy due to some energy not being able to be utilized in a reaction and lost from the system?",
        "answer": "The Second Law of Thermodynamics states that during an reaction that some energy will not be completely transferred and will ultimately be lost as heat to increase the entropy of the universe.",
        "suggested_answer": "Second law",
        "options": [
            "Third law",
            "Zeroth law",
            "First law",
            "Second law",
            "Fourth law"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 300,
        "question": "What is the nucleotide triplet found on mRNA strands called?",
        "answer": "The mRNA strand is translated into a protein using triplets, or three nucleotides. Each triplet is called a codon. Messenger or mRNA codons bind to complementary anti-codons on tRNA molecules, which carry the corresponding amino acids.",
        "suggested_answer": "Codon",
        "options": [
            "P-site",
            "Release factor",
            "Anti-codon",
            "Codon"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 301,
        "question": "Which of the following types of molecules has the most energy available per unit of carbon?",
        "answer": "Fats have an incredibly high potential to produce a lot of energy when broken down. This is because they are very saturated, which means they have a lot of bonded hydrogens. They also have a lot of carbon-carbon bonds, which have a lot of potential energy stored. When you break down a fat, especially one that has fourteen or more carbons in the chain, you release the energy from every carbon-carbon and carbon-hydrogen bond.",
        "suggested_answer": "Fats",
        "options": [
            "Sugars",
            "Alcohols",
            "Amino acids",
            "Fats",
            "Proteins"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 302,
        "question": "Which of the following is the term that best describes a DNA strand that is used as a template for transcription? ",
        "answer": "RNA polymerase binds and transcribes the non-coding strand.\" It acts as a template that is used to generate a RNA transcript that is complementary to the DNA strand that was transcribed. The term coding strand refers to the DNA strand with the identical sequence to the newly synthesized RNA. The coding strand can also be called the non-template strand or the sense strand. ",
        "suggested_answer": "Non-coding strand",
        "options": [
            "Non-template strand",
            "Coding strand",
            "Sense strand",
            "Non-coding strand"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 303,
        "question": "The Golgi apparatus is responsible for which function of the cell?",
        "answer": "The Golgi apparatus is a series of flattened membrane sacs found in the cell. It receives vesicles filled with proteins from the rough endoplasmic reticulum. The Golgi apparatus is responsible for recognizing proteins based on their signal sequences and sending concentrations of similar proteins to various parts of the cell. It can also deliver proteins out of the cell using secretory vesicles. The membrane sacs of the Golgi apparatus are constantly used and regenerated to create vesicles of packaged proteins.",
        "suggested_answer": "Protein packaging",
        "options": [
            "Protein packaging",
            "Protein storage",
            "Protein synthesis",
            "Protein degradation"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 304,
        "question": "Which of the following is not considered an organelle?",
        "answer": "All of these structures are organelles except the ribosome. The ribosome is found in both prokaryotic and eukaryotic cells. It is the site of protein synthesis (translation). Ribosomes may be free-floating in the cytoplasm, or attached to the endoplasmic reticulum in eukaryotes. Since ribosomes are not membrane-bound, they do not fit the classical definition of an organelle.",
        "suggested_answer": "Ribosome",
        "options": [
            "Ribosome",
            "Chloroplast",
            "Peroxisome",
            "Nucleus",
            "Mitochondria"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 305,
        "question": "Charles Darwin's voyage to the Galapagos islands and study of the beak shape of finches was integral to his research and subsequent ideas about __________ through __________. ",
        "answer": "Charles Darwin studied finches in the Galapagos, which prompted his work on natural selection. This was observed as only the finches who were evolutionarily prepared reproduced, thus increasing their traits in the gene pool ",
        "suggested_answer": "evolution . . . natural selection",
        "options": [
            "natural selection . . . evolution",
            "temporal isolation . . . predator-prey relationships",
            "sympatric speciation . . . evolution",
            "phenotypic degradation . . . selection pressures",
            "evolution . . . natural selection"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 306,
        "question": "ATP and GTP provide energy for several cellular processes, and are composed of all except which of the following components?",
        "answer": "ATP stands for adenosine triphosphate and GTP stands for guanosine triphosphate. Both of them are nucleic acids, meaning that they must contain a pentose sugar, a nitrogenous base, and phosphate groups. Both ATP and GTP contain three phosphate groups. The only difference between ATP and GTP is their nitrogenous base. ATP contains adenine whereas GTP contains guanine. Recall that adenine and guanine are both purines.",
        "suggested_answer": "A pyrimidine",
        "options": [
            "Three phosphate groups",
            "A pentose sugar",
            "A purine",
            "A pyrimidine"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 307,
        "question": "James was stranded on an island. He was thirsty and decided to drink the water from the sea (which has a very high osmolarity). After drinking the water, his body became even more dehydrated and he began urinating more frequently. Which of the follow choices best explains why James urinated more frequently and became more dehydrated despite drinking the seawater?",
        "answer": "Ocean water has a higher osmolarity (more units of solute per unit of solvent) than human blood. When James drank the ocean water, it was absorbed into his circulatory system and it pulled water from the cells. Water flows from an area of low osmolarity to an area of high osmolarity. When water was pulled from the cells, the fluid volume in James\u2019 blood increased. As blood reached James\u2019 kidneys, the extra fluid from the tissues was filtered into the urine and caused him to urinate more frequently. ",
        "suggested_answer": "The seawater pulled water from James' cells, which left him more dehydrated and caused him to urinate more",
        "options": [
            "The seawater was not processed by the kidneys because James' body lacked electrolytes",
            "None of these",
            "The seawater could not be processed by the kidneys because James' malnourished body lacked proper amounts of glucose in the pancreas",
            "James' body was malnourished and unable to absorb the seawater, which left as urine",
            "The seawater pulled water from James' cells, which left him more dehydrated and caused him to urinate more"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 308,
        "question": "The wings of a bird and the wings of a beetle are considered __________.",
        "answer": "Structures that are similar as a result of convergent evolution are referred to as analogous structures, such as the wings of beetles and birds. These animals do not share a common ancestor, and developed the trait for wings independent of one another. Homologous structures arise when two organisms share a trait due to linkage with a common ancestor. For example, legs of a dog and the legs of a cat are considered homologous.",
        "suggested_answer": "analogous",
        "options": [
            "taxonomic",
            "phylogenetic",
            "binomial",
            "analogous",
            "homologous"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 309,
        "question": "Which of the following terms best describes the type of cleavage that occurs in developing mammalian embryos?",
        "answer": "Developing mammalian embryos exhibit rotational cleavage. In this type of cleavage, the first cell division occurs on meridionally. The next cell division in the two resulting daughter cells occurs meridionally in one and equatorially in the other.",
        "suggested_answer": "Rotational",
        "options": [
            "Rotational",
            "Spiral",
            "Bilateral",
            "Radial"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 310,
        "question": "Which of these are characteristic of transcription in eukaryotes? ",
        "answer": "Prokaryotic transcription occurs in the cytosol, since prokaryotes lack a nucleus. This allows ribosomes to interact with RNA even while it is still be synthesized.",
        "suggested_answer": "III only",
        "options": [
            "I and IV",
            "I and III",
            "III only",
            "I, II, and IV",
            "II only"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 311,
        "question": "Which element has 42 protons?",
        "answer": "Remember, the number of protons is equal to the atomic number of the element. Look at the periodic table and find the element with atomic number 42, it corresponds to molybdenum.",
        "suggested_answer": "Molybdenum",
        "options": [
            "Gold",
            "Mercury",
            "Niobium",
            "Molybdenum",
            "Technetium"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 312,
        "question": "Fight or flight is a decision that all animals must make when confronted with a threat. Regardless of which one, an animal chooses, it will activate the sympathetic nervous system, instead of the parasympathetic nervous system. Which of the the following answers contain actions that will happen in a fight or flight situation?",
        "answer": "\"Dilated pupils, accelerated heart rate, relaxed bladder\" is the correct answer here because it is the only choice is entirely comprised of sympathetic nervous system actions. Some red herrings in the other answers is the fact that constricted pupils is a parasympathetic action, as is stimulation of the salivary gland.",
        "suggested_answer": "Dilated pupils, accelerated heart rate, relaxed bladder",
        "options": [
            "Constricted pupils, accelerated heart rate, contracted bladder",
            "Dilated pupils, accelerated heart rate, relaxed bladder",
            "Relaxed skeletal muscle, inhibited digestive activity, dilated pupils",
            "Contracted bladder, relaxed bronchi, simulated salivation",
            "Constricts pupils, secretes epinephrine and norepinephrine, and contracts bronchi"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 313,
        "question": "A segment of prokaryotic DNA that binds transcription factors, usually as repressors that prevent transcription, are best known as __________.",
        "answer": "The correct answer is operator. In most operons, repressors bind operators to prevent transcription of downstream genes.",
        "suggested_answer": "Operators",
        "options": [
            "Enhancers",
            "Operators",
            "Untranslated regions",
            "Exons",
            "Promoters"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 314,
        "question": "Which of the following is able to transfer a phosphate group and activate a protein?",
        "answer": "A protein kinase phosphorylates proteins and activates them by adding a phosphate group. Phosphodiesterase breaks phosphodiester bonds. Phosphotases remove phosphate groups and dephosphorylate a protein. G proteins are usually bound to a receptor and can begin a cascade of reactions when activated. Adenylyl cyclase converts ATP to cAMP. ",
        "suggested_answer": "Protein kinase",
        "options": [
            "G protein",
            "Adenylyl cyclase",
            "Protein kinase",
            "Phosphodiesterase",
            "Phosphotase"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 315,
        "question": "Which of the following are categorizations of muscle tissue?",
        "answer": "Muscle is a major type of tissue in the human body. They contract and enable movement. The three major types of muscle tissues include skeletal, cardiac, and smooth tissues.",
        "suggested_answer": "All of these",
        "options": [
            "Smooth",
            "Muscle",
            "Skeletal",
            "All of these"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 316,
        "question": "Which of the following is not a function of the cell membrane?",
        "answer": "The cell membrane is a lipid bilayer that is hydrophilic on the outside and hydrophobic on the inside. Proteins are embedded in the membrane to assist in regulating the passage of various compounds into and out of the cell. Only certain molecules can cross the membrane without protein assistance. The cell tightly regulates the production of membrane proteins to maintain the environment of the cell, but cannot do anything to regulate the molecules that cross the membrane without protein assistance.",
        "suggested_answer": "Regulate carbon dioxide levels in the cell",
        "options": [
            "Regulate calcium ion content in the cell",
            "Regulate water content in the cell",
            "Regulate carbon dioxide levels in the cell",
            "Regulate sodium ion levels in the cell",
            "Regulate glucose levels in the cell"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 317,
        "question": "The amino acid leucine has several possible codons, due to the degeneracy of the genetic code. This means that there is more than one possible __________ for leucine.",
        "answer": "Each tRNA contains the anticodon for a specific mRNA codon and carries the amino acid corresponding to that codon to ribosomes during translation. mRNA is produced by transcription from DNA, and ribosomes translate it into proteins. Multiple codons can code for a single amino acid, and so there can be several tRNA anticodons that could be used for a single amino acid.",
        "suggested_answer": "tRNA",
        "options": [
            "mRNA",
            "ribosome",
            "DNA",
            "tRNA",
            "rRNA"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 318,
        "question": "What the products of the light independent reactions?",
        "answer": "Light independent reactions occur in the stroma of the chloroplasts. Light dependent reactions occur in the thylakoid membrane.",
        "suggested_answer": "glucose, NADP+, H2O",
        "options": [
            "glucose, NADPH, H2O",
            "glucose, NADP+, H2O",
            "CO2, NADPH, ATP",
            "O2, NADP+, ATP"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 319,
        "question": "Which of the following is true regarding seed development in angiosperms?",
        "answer": "Angiosperm seeds are enclosed within ovaries, which prevent self-fertilization and allow for selective pollination.",
        "suggested_answer": "Seeds are enclosed within the ovary",
        "options": [
            "Seeds are \"naked\" and not enclosed in an ovary",
            "Seeds are enclosed within the ovary",
            "They often exist in cone form",
            "Seeds develop on leaves or scales"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 320,
        "question": "What type of microorganism is composed only of a protein capsule encasing genetic material?",
        "answer": "A virus is the smallest of the microorganisms, consisting genetic material and a protein coat. The virus takes over the host's cellular functions to reproduce, and carries no organelles of its own.",
        "suggested_answer": "Virus",
        "options": [
            "Helminth",
            "Protozoa",
            "Bacteria",
            "Fungus",
            "Virus"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 321,
        "question": "Which law of inheritance is incorrectly matched to its explanation?",
        "answer": "The laws of inheritance include the laws of segregation (each gamete receives only one copy of each gene from its parent), dominance (in a heterozygote individual, only the dominant allele will influence the phenotype), and independent assortment (inheritance of one gene does not influence inheritance of another gene)",
        "suggested_answer": "Law of segregation; each gamete receives both copies of gene from its parent",
        "options": [
            "Law of dominance; in a heterozygote individual, only the dominant allele will influence the phenotype",
            "Law of segregation; each gamete receives only one copy of each gene from its parent",
            "Law of segregation; each gamete receives both copies of gene from its parent",
            "Law of independent assortment; inheritance of one gene does not influence inheritance of another gene"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 322,
        "question": "Which element is isoelectronic with a bromide ion?",
        "answer": "Two atoms are isoelectric if they each have the same number of electrons. In its ground state, bromine has 35 electrons, equal to the number of protons in order to have a neutral atom. In order to become a bromide ion, the bromine atom must gain one electron. This gives it a charge of negative one, but allows it to satisfy the octet rule, making it a very stable ion. With the ionization, the atom now has 36 electrons.",
        "suggested_answer": "Krypton",
        "options": [
            "Iodine",
            "None of these",
            "Chlorine",
            "Krypton",
            "Selenium"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 323,
        "question": "Which of the following choices connects the developing fetus to the maternal placenta?",
        "answer": "During fetal development, the umbilical cord is a structure that connects the fetus to the maternal placenta. The umbilical cord contains umbilical arteries and the umbilical vein, allowing oxygen, nutrient, and waste exchange between the mother and fetus. It develops from the yolk sac and allantois in the fifth week of development and is clamped or cut following childbirth.",
        "suggested_answer": "Umbilical cord",
        "options": [
            "Fetal villi",
            "Umbilical cord",
            "Endometrial villi",
            "Amniotic sac"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 324,
        "question": "Which of the following best describes the function of the sepal in angiosperms?",
        "answer": "The sepal is a protective structure in angiosperms. When the flower is still in the bud, the sepal offers protection and later supports the bloom.",
        "suggested_answer": "Protection of the flower when it is still in the bud",
        "options": [
            "Production of pollen",
            "Attracts pollinators",
            "Protection of the flower when it is still in the bud",
            "Houses ovules awaiting fertilization"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 325,
        "question": "Different fish living in the same pond mate at different times during the year. What type of reproductive isolation is this?",
        "answer": "This is an example of temporal isolation. Temporal isolation means that different species are kept reproductively isolated because they mate at different times.",
        "suggested_answer": "Temporal isolation",
        "options": [
            "Behavioral isolation",
            "Mechanical isolation",
            "Gametic isolation",
            "Habitat isolation",
            "Temporal isolation"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 326,
        "question": "When muscle contraction occurs, calcium ions bond to which of the following structures?",
        "answer": "The calcium ions bind to troponin, which causes the release of the tropomyosin from actin as a result of the troponin-tropomyosin complex detaching. This makes the binding site on actin available for myosin to attach to. The sarcoplasmic reticulum stores calcium for its quick release to facilitate muscle contraction.",
        "suggested_answer": "Troponin",
        "options": [
            "Actin",
            "Troponin",
            "Tropomyosin",
            "Myosin",
            "Sarcoplasmic reticulum"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 327,
        "question": "What can the researcher conclude about the neuron five seconds after the initial stimulus in both trials?",
        "answer": "Absolute refractory period of a neuron is the period of time during which no amount of external stimulus will generate an action potential. Relative refractory period is the period of time during which only a large stimulus will generate an action potential.",
        "suggested_answer": "The neuron is in its relative refractory period",
        "options": [
            "The neuron was temporarily dead",
            "The neuron is in its absolute refractory period",
            "As time increases, this neuron requires more stimulation to generate action potential",
            "The neuron is in its relative refractory period"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 328,
        "question": "An important identifying difference between eukaryotic and prokaryotic cells is that __________.",
        "answer": "Eukaryotic cells have a nuclei; prokaryotic cells have no nuclei.",
        "suggested_answer": "eukaryotes have nuclei",
        "options": [
            "prokaryotes are considerably larger and more complex",
            "prokaryotes possess membrane-bound organelles; eukaryotes do not",
            "All other answers are correct",
            "eukaryotes have nuclei",
            "prokaryotes have flagella; eukaryotes do not"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 329,
        "question": "How do enzymes speed up a reaction?",
        "answer": " Enzymes speed up reactions through lowering the activation energy, of the energy needed to break bonds of reactants. The activation energy is lowered through stabilizing the transition state; the active site of the enzyme better fits the substrate, allowing bonds to more readily be broken, requiring less energy.",
        "suggested_answer": "Stabilization of transition state, lowering activation energy",
        "options": [
            "Decrease yield of reaction",
            "Stabilization of transition state, lowering activation energy",
            "Stabilization of transition state, increasing activation energy",
            "Increase enthalpy of reaction"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 330,
        "question": "A microscopic view of a cell shows a dense, rigid exterior. Which of the following types of cells is least likely being observed?",
        "answer": "The dense, rigid exterior is most likely describing a cell wall. Cell walls are used to protect cells and help them maintain their shape. Plant cells, fungal cells, and most prokaryotic cells have a cell wall structure, though the composition of the cell walls varies between cell types. Plants, such as ferns, use cellulose in their cell walls. Fungi, such as mushrooms, generally use chitin. Prokaryotic cell walls vary in composition, but bacteria generally use peptidoglycan, which can help in identifying bacteria via Gram staining.",
        "suggested_answer": "A liver cell",
        "options": [
            "A fern cell",
            "A liver cell",
            "A bacterial cell",
            "A mushroom cell"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 331,
        "question": "Label the diagram.",
        "answer": "At the ends of the mitotic spindle are the centrosomes, the areas where the microtubules come together and are organized. You may see a cross there which representes the centrioles, a main part of the centrosome. B, then, is the mitotic spindle which is made up of microtubules. Now, in the chromosome the point where the DNA is highly condensed and allows the sister chromatids to attach to each other is the centromere. On the outskirts of the centromere are the kinetochores which allow the microtubules to attach and eventually pull the chromosomes apart (think of movement, kinetics.) Finally, as this is the metaphase stage, the dotted line represents the metaphase plate where the chromosomes line up. There is no dotted line in reality, it just helps to use one to understand the concept.",
        "suggested_answer": "A: Centrosome",
        "options": [
            "A: Centrosome",
            "B: Microtubules (mitotic spindle)",
            "C: Kinetochore",
            "D: Centromere",
            "E: Metaphase plate",
            "A: Centrosome",
            "B: Microtubules (mitotic spindle)",
            "C: Centromere",
            "D: Kinetochore",
            "E: Metaphase plate",
            "A: Centromere",
            "B: Microtubules (mitotic spindle)",
            "C: Centrosome",
            "D: Kinetochore",
            "E: Metaphase plate",
            "A: Microtubules",
            "B: Metaphase plate",
            "C: Centromere",
            "D: Kinetochore",
            "E: Centrosome",
            "None of these"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 332,
        "question": "The chemical compound 2,4-dinitrophenol can disrupt the process of oxidative phosphorylation in the mitchondrial electron transport chain by causing which effect?",
        "answer": "In ATP synthesis, the proton gradient is an interconvertible form of energy in electron transport. 2,4-dinitrophenol is an inhibitor of ATP production in cells with mitochondria. Its mechanism of action involves carrying protons across the mitochondrial membrane, which leads to the consumption of energy without ATP production.",
        "suggested_answer": "Dispersion of the proton gradient",
        "options": [
            "Removing the F0 subunit from the ATP synthase complex",
            "Binding to nucleotide carriers",
            "Binding to ubiquinone",
            "Oxidative inhibition",
            "Dispersion of the proton gradient"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 333,
        "question": "Which of the following lacks a nucleus?",
        "answer": "Prokaryotic cells do not have a nucleus. The genetic material for prokaryotes is concentrated in a region called the nucleoid but there is no membrane separating this region from the rest of the cell unlike in eukaryotic cells, which do have membrane-bound nuclei. Animal cells and plant cells are eukaryotes. ",
        "suggested_answer": "Prokaryotic cells",
        "options": [
            "Prokaryotic cells",
            "Eukaryotic cells",
            "Plant cells",
            "Animal cells"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 334,
        "question": "Where in the chloroplasts does the Calvin Cycle of photosynthesis take place?",
        "answer": "The Calvin Cycle occurs in the stroma, the aqueous fluid-filled area of the chloroplast. The stroma can be seen as analogous to the cytoplasm of a cell, in that it is the liquid in which all other substructures reside. The other processes of photosynthesis, the light-dependent reactions, take place in the thylakoid, a membrane-bound substructure within the chloroplast.",
        "suggested_answer": "Stroma",
        "options": [
            "Thylakoid membrane",
            "Intermembrane space",
            "Stroma",
            "Thylakoid lumen",
            "Grana"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 335,
        "question": "Larger arteries have less smooth muscle per volume when compared to smaller arteries (also called arterioles). What would you expect as a result of this distinction?",
        "answer": "Smooth muscle is typically innervated by the sympathetic nervous system. Because arterioles have more smooth muscle per volume, they are able to respond to sympathetic innervation more efficiently than larger arteries. As a result, the smaller arteries are used to regulate blood pressure as well as reroute blood direction by adjusting arteriole diameter accordingly.",
        "suggested_answer": "Arterioles are able to regulate blood pressure more efficiently than large arteries.",
        "options": [
            "Capillaries will have an even larger amount of smooth muscle per volume, because they are even smaller than arterioles.",
            "The smooth muscle is responsible for keeping the arterioles constricted at all times. ",
            "Arterioles are able to regulate blood pressure more efficiently than large arteries.",
            "Smaller arteries have more muscle tone, and are able to tolerate more pressure than large arteries."
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 336,
        "question": "For glycolysis to proceed as the first step of cellular respiration, what must be present?",
        "answer": "Glycolysis starts with the break down of one molecule of glucose via energy input from to form 2 molecules of , 4 molecules of , 2 pyruvate molecules, and molecules. This step will continue regardless of whether oxygen is present or not.",
        "suggested_answer": "2 molecules of and 1 molecule of glucose",
        "options": [
            "Oxygen",
            "Oxygen, 2 molecule of , and 1 molecule of sucrose",
            "2 molecules of ",
            "1 molecule of glucose",
            "2 molecules of and 1 molecule of glucose"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 337,
        "question": "Which of the given options represents the highest level of biological organization?",
        "answer": "Biological organization extends from the level of subatomic particles all the way up to the biosphere. Within the fields of molecular and cell biology, the atomic, molecular, and cellular levels of biology are most important. In the fields of physiology and anatomy, organs, organ systems, and organisms feature prominently.",
        "suggested_answer": "Biosphere",
        "options": [
            "Earth",
            "Ecosystem",
            "Biosphere",
            "Atmosphere",
            "Biome"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 338,
        "question": "Which of the following is not a step for the elongation process of translation? ",
        "answer": "The three steps for the elongation process of translation are codon recognition, peptide bond formation, and translocation. These steps essentially correspond to the different tRNA positions in the ribosome. tRNA enters and matches the codon of the mRNA strand. A peptide bond is then formed between the tRNA amino acid and the ribosomal amino acid chain. The empty tRNA and peptide strand then shift to make room for the next residue to enter to ribosome structure.",
        "suggested_answer": "RNA splicing",
        "options": [
            "RNA splicing",
            "Codon recognition",
            "Translocation",
            "All of these are involved in elongation",
            "Peptide bond formation "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 339,
        "question": "Obesity can sometimes be caused by the slowing of the thyroid gland's production of thyroid hormones III and IV (T3 and T4), a condition known as hypothyroidism. Conversely, hyperthyroidism occurs when the thyroid overproduces T3 and T4. If T3 and T4 receptors became insensitive to the hormones, what effect would this have on the body?",
        "answer": "The question tells us that slowed output of thyroid hormones can lead to obesity. One can safely assume that receptor insensitivity will have a similar effect. In either scenario, the T3 and T4 hormones are unable to elicit the proper response form the body. Similar to individuals with hypothyroidism, individuals with insensitive receptors would show slowed metabolism and increased weight gain.",
        "suggested_answer": "Slower metabolism and weight gain",
        "options": [
            "Slower metabolism and weight gain",
            "Faster metabolism and weight gain",
            "Faster metabolism and weight loss",
            "Slower metabolism and weight loss"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 340,
        "question": "Which of the following components of acrosomal content aids the sperm cell's approach towards the oocyte?",
        "answer": "The acrosome of sperm cells is a cap on the anterior end of the cell that contains digestive enzymes (i.e. acrosin and hyaluronidase) and surface antigens. During the acrosomal reaction, the acrosome fuses with the sperm\u2019s plasma membrane and releases the acrosomal contents from the cell. The digestive enzymes break down the layers surrounding the oocyte and allow the sperm cell to approach the oocyte plasma membrane.",
        "suggested_answer": "Digestive enzymes",
        "options": [
            "Steroids",
            "Calcium ions",
            "Digestive enzymes",
            "Glycoproteins"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 341,
        "question": "Which scenario is an example of allopatric speciation?",
        "answer": "Allopatric speciation occurs when a geographical barrier, like a river, mountain, or canyon, separates members of a population. This barrier prevents the individuals on one side from reproducing with the individuals on the other. In addition, selecting forces may act differently on the two sides of the barrier. This separation eventually results in two distinct species.",
        "suggested_answer": "A river separates members of a squirrel population that used to occupy the same geographical area",
        "options": [
            "Certain members of a human population have more offspring than others",
            "Houseflies from a certain region migrate and interbreed with a different housefly population in a neighboring area",
            "A disease ravages a large fox population, killing all members that did not have a genetic resistance to the disease",
            "Hawks with thin, sharp beaks primarily eat fish and small rodents, while hawks with larger beaks tend to eat reptiles and larger birds",
            "A river separates members of a squirrel population that used to occupy the same geographical area"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 342,
        "question": "A researcher has discovered two species of frogs that live in the same location. In the lab he is able to generate a viable hybrid by combining gametes from each species, however, no such hybrids exist in the wild. Which of the following methods of reproductive isolation might explain this phenomenon?",
        "answer": "Out of the choices, only temporal isolation can explain this phenomenon. Temporal isolation describes a type of reproductive isolation that comes about because the two species mate at different times.",
        "suggested_answer": "Temporal isolation",
        "options": [
            "Hybrid breakdown",
            "Habitat isolation",
            "Temporal isolation",
            "Gametic isolation"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 343,
        "question": "Which of the following channels are open during depolarization?",
        "answer": "All three channels provided in the question are open during depolarization. Open voltage-gated sodium channels characterize depolarization. The flow of sodium ions into the cytosol (facilitated by these channels) causes the cell to depolarize.",
        "suggested_answer": "I, II, and III",
        "options": [
            "I and II",
            "I, II, and III",
            "II only",
            "I and III"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 344,
        "question": "Which of the following intermolecular interactions is easiest to disrupt via a change in temperature?",
        "answer": "Hydrogen bonding can easily be disrupted by changes in temperature. It is important to note that hydrogen bonding is not a true example of a chemical bond, but rather an intermolecular force. Hydrogen bonds are essential for the formation of protein structure and DNA base pairing. When proteins and DNA are exposed to heat, they degrade as these hydrogen bonds are broken.",
        "suggested_answer": "Hydrogen bonds ",
        "options": [
            "Covalent bonds ",
            "Hydrogen bonds ",
            "Ionic bonds ",
            "Peptide bonds "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 345,
        "question": "Prokaryotic cells have __________.",
        "answer": "When comparing prokaryotes to eukaryotes, the main fact to remember is that prokaryotes do not contain membrane-bound organelles. This means they have no mitochondria, Golgi apparatus, or endoplasmic reticulum. They also lack a membrane-bound nucleus, although they do have genetic material located in a nucleoid region. Prokaryotic cells are surrounded by a plasma membrane, and some types of bacteria have cell walls as well.",
        "suggested_answer": "a plasma membrane encasing the cell, but no membrane-bound organelles",
        "options": [
            "a nucleoid region, but no plasma membrane encasing the cell",
            "a plasma membrane encasing the cell, but no membrane-bound organelles",
            "a Golgi apparatus, but no membrane-bound nucleus",
            "mitochondria, but no nucleus",
            "a membrane-bound nucleus, but no lysosomes"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 346,
        "question": " Which of the following is not true regarding zygote mortality?",
        "answer": "Zygote mortality is an example of post-zygotic reproductive isolation. In this type of reproductive isolation, gametes from two species may fuse, however the zygote is inviable shortly after fertilization due to improper development.",
        "suggested_answer": "Fertilization does not occur",
        "options": [
            "Fertilization occurs between gametes ",
            "After fertilization, the resulting zygote is inviable ",
            "It is an example of post-zygotic reproductive isolation ",
            "Fertilization does not occur"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 347,
        "question": "Which of the following is true regarding the process of sublimation?",
        "answer": "Sublimation is the process in which water converts from a solid to a gaseous phase without passing through the intermediate liquid phase. Sublimation is an endothermic reaction: it requires the absorption of energy in the form of heat.",
        "suggested_answer": "All of these",
        "options": [
            "It is an endothermic reaction",
            "All of these",
            "It is the conversion of water from a solid to a gaseous state",
            "In sublimation, water doesn't pass through the intermediate liquid phase"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 348,
        "question": "Which of the following is not involved in an eco-chemical cycle?",
        "answer": "Water, carbon, nitrogen, sulfur, and phosphorus have cyclic pathways that allowing them to flow within an ecosystem. Iron is not involved in very many ecological processes, and does not follow a defined chemical cycle.",
        "suggested_answer": "Iron",
        "options": [
            "Water",
            "Phosphorus",
            "Carbon",
            "Iron",
            "Nitrogen"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 349,
        "question": "What intermolecular forces can be found in a molecule of ethene?",
        "answer": "Ethene is an organic molecule composed of two carbon atoms, joined by a double bond, and four hydrogen atoms.",
        "suggested_answer": "London dispersion forces only",
        "options": [
            "London dispersion forces and dipole-dipole attraction",
            "London dispersion forces and hydrogen bonding",
            "London dispersion forces, hydrogen bonding, and dipole-dipole attraction",
            "Dipole-dipole attraction and ionic bonding",
            "London dispersion forces only"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 350,
        "question": "Which of the following best describes what happens to the frequency of a certain allele in the process of negative selection?",
        "answer": "Negative selection is a type of natural selection in which the frequency of an allele decreases.",
        "suggested_answer": "It decreases in frequency",
        "options": [
            "There is no change in the allele's frequency",
            "It increases in frequency",
            "The allele disappears from the gene pool",
            "It decreases in frequency"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 351,
        "question": "Which of the following contributes to the phenomenon of biological magnification?",
        "answer": "Because energy transfer between trophic levels is only about 10% efficient, it takes several organisms at a lower trophic level to support one organism at a higher trophic level. If each fish (lower level organism) has 10 toxin molecules in its body, and each eagle (higher level organism) eats 10 fish, the eagle now has 100 toxin molecules in its body. Molecules stored in an organism accumulate at higher trophic levels; this accumulation is known as biological magnification.",
        "suggested_answer": "The biomass at higher trophic levels needs a larger amount of biomass at lower levels to support it",
        "options": [
            "The biomass at higher trophic levels needs a smaller amount of biomass at lower levels to support it",
            "The biomass at higher trophic levels needs a larger amount of biomass at lower levels to support it",
            "Decomposers consume at every trophic level",
            "Plants cannot absorb toxins from the environment",
            "The biomass at higher trophic levels needs to be equal to the amount of biomass at lower levels to support it"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 352,
        "question": "Which of the following best explains the difference between a detritivore and a decomposer?",
        "answer": "Both detritivores and decomposers play important roles in the food chain and energy exchanges within an ecosystem. Detritivores consume decomposing plant and animal tissue (i.e. vultures). Decomposers break down decomposing tissue using biochemical reactions rather than ingestion (i.e. fungi).",
        "suggested_answer": "Detritivores ingest decomposing tissue while decomposers break it down",
        "options": [
            "Decomposers ingest decomposing tissue while detritivores break it down",
            "Detritivores break down plant material while decomposers break down animal tissue",
            "Detritivores ingest decomposing tissue while decomposers break it down",
            "Decomposers break down plant material while detritivores break down animal tissue"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 353,
        "question": "In which of the following processes does water flow through Earth\u2019s mantle?",
        "answer": "The theory of plate tectonics describes the movement of the Earth\u2019s crust and mantle. In plate tectonics, water moves through the Earth\u2019s mantle and returns to the surface by volcanoes.",
        "suggested_answer": "Plate tectonics",
        "options": [
            "Plate tectonics",
            "Percolation",
            "Condensation",
            "Sublimation"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 354,
        "question": "Which of the following choices best describes where the secondary oocyte travels in the female reproductive tract after it is released from the follicle?",
        "answer": "The release of the secondary oocyte from the follicle is called ovulation. During this process, a hole called the stigma is formed and it allows the secondary oocyte to leave the follicle surrounded by a layer of cells called the cumulus oophorus. After its release, the secondary oocyte enters the fallopian tube.",
        "suggested_answer": "Fallopian tube",
        "options": [
            "Uterus",
            "Vagina",
            "Fallopian tube",
            "Ovary"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 355,
        "question": "Which of the following statements about the hormone secretin is false?",
        "answer": "Secretin is a hormone released by the duodenum in response to an influx of acid. The hormone targets the stomach, to reduce the amount of acid that can pass through to the small intestine, and the pancreas, to increase secretion of bicarbonate, which neutralizes the acidity.",
        "suggested_answer": "All of these choices are correct",
        "options": [
            "All of these choices are correct",
            "It is released when acid enters the duodenum, where it is secreted",
            "It inhibits motility in the stomach",
            "It stimulates bicarbonate secretion in the pancreas"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 356,
        "question": "Vasopressin is released when sensory neurons in the hypothalamus detect a rise in the osmolarity of the blood. When secreted, this hormone causes changes in water reabsorption by the kidneys. This is an example of what type of signaling?",
        "answer": "Neuroendocrine signaling describes the process when specialized neural sensors, usually in the brain, detect changes in the body. These sensors stimulate the release of a hormone to help correct deviations. The hormone can travel via the bloodstream to affect distant tissues.",
        "suggested_answer": "Neuroendocrine signaling",
        "options": [
            "Autocrine signaling",
            "Neuroendocrine signaling",
            "Paracrine signaling",
            "Synaptic signaling"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 357,
        "question": "Glycolysis occurs in which part of the cell?",
        "answer": "Glycolysis occurs in the cytosol. Recall that glycolysis is the process by which glucose is broken down into two molecules of pyruvate, which, under aerobic conditions, is further oxidized in the Krebs cycle and electron transport. The mitochondria is the site of the Krebs cycle and electron transport in eukaryotes, while these processes occur in the cytosol and across the cell membrane in prokaryotes.",
        "suggested_answer": "Cytosol",
        "options": [
            "Cytosol",
            "Golgi apparatus",
            "Mitochondrial matrix",
            "Intermembrane space",
            "Cell membrane"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 358,
        "question": "Parietal cells in the stomach secrete which of these?",
        "answer": "In the stomach, both hydrochloric acid and pepsinogen are secreted by gastric pits. Parietal cells in these pits secrete hydrochloric acid while chief cells in these pits secrete pepsinogen. Note that pepsinogen is inactive, and gets activated into pepsin via hydrochloric acid.",
        "suggested_answer": "Hydrochloric acid",
        "options": [
            "Pepsin",
            "Pepsinogen",
            "Bile salts",
            "Mucus",
            "Hydrochloric acid"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 359,
        "question": " DNA is __________ to create two __________ per __________, which are connected at the __________.",
        "answer": "In preparation for cell division, DNA is copied (replicated) to create chromosomes with two identical sides (sister chromatids), which are connected at the centromere. DNA must be replicated before prophase, in S phase of interphase. Do not be confused with the terms centrioles, centrosomes, and centromere. Centrioles are located at centrosomes, which are found at opposite poles of the cell during meiosis stages of prophase, metaphase, anaphase, and telophase. The question is asking about interphase, which is prior to the meiosis/mitosis stages. ",
        "suggested_answer": "replicated . . . sister chromatids . . . chromosome . . . centromere ",
        "options": [
            "replicated . . . homologous chromosome . . . chromatid . . . centriole",
            "replicated . . . sister chromatids . . . chromosome . . . centromere ",
            "replicated . . . homologous chromatids . . . chromosome . . . centriole",
            "replicated . . . sister chromosomes . . . chromatid . . . centromere",
            "replicated . . . homologous chromosomes . . . chromatid . . . centromere "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 360,
        "question": "Which of the following is the mechanism of action of vasopressin?",
        "answer": "Vasopressin, also called antidiuretic hormone (ADH), is part of the hormonal control of urine excretion and functions to enhance reabsorption of water, limiting the excretion of water in urine. Vasopressin is released when osmoreceptor cells in the hypothalamus detect a rise in the osmolarity (solute concentration) of the blood above a threshold level. Upon release, vasopressin reaches the kidney and binds to receptors on cells in the collecting duct, which stimulates release of aquaporin water channels from storage vesicles within the cells. Aquaporin channels are selectively permeable to water, and allow the flow of water out of the filtrate/urine. This water is then reclaimed by the body and used to increase blood volume, increase blood pressure, and reduce blood osmolarity.",
        "suggested_answer": "Mobilize aquaporin receptors from storage vesicles",
        "options": [
            "Inhibit the function of sodium ion channels",
            "Internalize aquaporin receptors",
            "Stimulate release of potassium ions in the proximal tubule",
            "Mobilize aquaporin receptors from storage vesicles"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 361,
        "question": "The hypothalamus plays a central role in vertebrates by integrating the endocrine and nervous systems. What gland receives signals from the hypothalamus?",
        "answer": "Signals from the hypothalamus go to the posterior pituitary gland, which is located at the base of the hypothalamus. The pituitary consists of two lobes (anterior and posterior), which function independently. The anterior and posterior pituitary produce a collection of hormones that are key to endocrine signaling throughout the body. ",
        "suggested_answer": "Pituitary gland",
        "options": [
            "Pituitary gland",
            "Pineal gland",
            "Thyroid gland",
            "Adrenal gland"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 362,
        "question": "Which of these hormones stimulates secretion of cortisol?",
        "answer": "Cortisol is a hormone secreted by the adrenal cortex. The adrenal cortex is first stimulated by adrenocorticotropic hormone, which is secreted from the anterior pituitary gland.",
        "suggested_answer": "Adrenocorticotropic hormone (ACTH)",
        "options": [
            "Cortisone",
            "Adrenocorticotropic hormone (ACTH)",
            "Thyroid stimulating hormone (TSH)",
            "Human growth hormone (hGH)",
            "Follicle stimulating hormone (FSH)"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 363,
        "question": "Which of the following best describes what the trophoblast develops into?",
        "answer": "The trophoblast is the outer layer of cells that make up the blastocyst. These cells provide nutrients to the developing embryo, and eventually develop into the placenta. During the later stages of cleavage, the trophoblast becomes distinguished from the inner layer of cells, which will develop into the embryo. There is a layer of fluid between the trophoblast and inner cell mass.",
        "suggested_answer": "The placenta",
        "options": [
            "The placenta",
            "The spinal cord",
            "The skin",
            "The muscle"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 364,
        "question": "Point mutations __________.",
        "answer": "Point mutations replace a single nucleotide for a different one. This can change a certain codon to code for a different amino acid (missense), the same amino acid (silent), or lead to a stop codon (nonsense). Nonsense mutations are the most severe type of point mutation, as they will cause early termination of the protein.",
        "suggested_answer": "All of the answer choices are correct",
        "options": [
            "All of the answer choices are correct",
            "include nonsense mutations, which change an amino acid to a stop codon, affecting the entire polypeptide structure",
            "include missense mutations, which only affect the codon in which the mutation occurs",
            "include silent mutations, which have no effect on the organism's phenotype"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 365,
        "question": "Terrestrial animals must combat the drying out of respiratory surfaces due to evaporation. What is one strategy they use?",
        "answer": "By folding the respiratory surface into the body, terrestrial animals increase the humidity of the environment of the respiratory surface, which will minimize evaporation and maintain moisture. Note that water loss via evaporation and perspiration accounts for the majority of water loss in terrestrial mammals.",
        "suggested_answer": "Folding the respiratory surface into the body",
        "options": [
            "Increased perspiration of cells on respiratory surface",
            "Increased metabolism",
            "Folding the respiratory surface into the body",
            "Excretion of fluids from walls of lungs",
            "Having very low respiratory rates"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 366,
        "question": "What is the resulting molecule if a triacylglycerol has one of its fatty acid chains replaced with a phosphate group?",
        "answer": "A fat (triglyceride/triacylglycerol) consists of three fatty acids linked to glycerol. A phospholipid consists of two fatty acids and a phosphate group linked to glycerol. Thus removing a fatty acid from a triglyceride and replacing it with a phosphate group will yield a phospholipid. Diglycerides contain similar structures to triglycerides, but only have two fatty acid chains bound to the glycerol head. Steroids are lipids, but do not contain fatty acids. Rather they contain four hydrocarbon rings, and they are all derived from cholesterol. The saturation of a fatty acid refers to the presence of hydrogens anywhere they can potentially be present. A saturated fatty acid is said to be saturated with hydrogens.",
        "suggested_answer": "Phospholipid",
        "options": [
            "Phospholipid",
            "Diglyceride",
            "Steroid",
            "Saturated fatty acid"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 367,
        "question": "Which of the following is not involved in the process of translation?",
        "answer": "Transcription leads to the production of hnRNA (heterogeneous nuclear RNA), which primarily consists of pre-mRNA and must go through processing and modification to form mRNA and leave the nucleus.",
        "suggested_answer": "hnRNA",
        "options": [
            "hnRNA",
            "All of these are involved in the process of translation",
            "mRNA",
            "tRNA",
            "rRNA"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 368,
        "question": "During the process of crossing over, what aids in the repair of DNA double stranded breaks?",
        "answer": "During crossing over, double stranded breaks allow for chromatid invasion and genetic recombination. These double stranded breaks cannot be maintained, as they may result in genome rearrangement. The invading chromatid strand anneals within this double stranded breaks, thus repairing them. ",
        "suggested_answer": "Invading chromatid DNA strand",
        "options": [
            "Primase",
            "Complementary DNA strand ",
            "DNA polymerase",
            "Invading chromatid DNA strand"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 369,
        "question": "An amino acid typically contains which of the following functional groups?",
        "answer": "Amino acids are the building blocks of proteins. The general structure of an amino acid consists of a carboxylic acid and an amine group bonded to a carbon that contains. The carbon contains an R group that varies depending on the amino acid.",
        "suggested_answer": "Carboxylic acid and amine",
        "options": [
            "Carboxylic acid and alcohol",
            "Aldehyde and amine",
            "Carboxylic acid and amine",
            "Alcohol and amine",
            "Carboxylic acid and aldehyde"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 370,
        "question": "What aspect of the environment did Darwin hypothesize had caused the Galapagos mockingbirds to differentiate into four different species?",
        "answer": "Speciation occurs when two populations are separated from each other and accumulate different changes due to different environments. When the birds migrated to the Galapagos and made homes on each island, they became physically different from each other over time. This was one observation to the theory of natural selection.",
        "suggested_answer": "Each species was found on a different island. Because the populations were separated from each other, they acquired different changes from each other over time.",
        "options": [
            "Because each species had a different food source, each parent bird acquired advantageous changes during its lifetime for accessing that food source, which it passed onto its offspring.",
            "What Darwin had mistaken to be new species were actually the same species with slight colorations depending on the environment.",
            "Each bird species had been created separately from the other, with little variation over time.",
            "None of these",
            "Each species was found on a different island. Because the populations were separated from each other, they acquired different changes from each other over time."
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 371,
        "question": "A random mutation occurs in the DNA of a cell, altering one of the enzymes necessary for DNA replication and making it nonfunctional. As a result, the DNA remains supercoiled, preventing replication from taking place. Which enzyme has been altered?",
        "answer": "The role of the topoisomerase enzyme is to unwind the DNA, allowing enzymes such as DNA helicase access to the nucleotide sequence. DNA helicase is responsible for breaking the hydrogen bonds responsible for holding double-stranded DNA together. This creates the replication fork and allows DNA polymerase access to the nitrogenous base sequence. The role of DNA polymerase is to place nucleotides once the DNA has been unwound, synthesizing the daughter DNA strands. DNA ligase binds nucleotide fragments together during synthesis. Telomerase is responsible for lengthening the telomeres at the ends of chromosomes.",
        "suggested_answer": "Topoisomerase",
        "options": [
            "Topoisomerase",
            "DNA polymerase",
            "DNA ligase",
            "Telomerase",
            "DNA helicase"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 372,
        "question": "Which of the following best describes the specialized role of squamous cells, as compared to other epithelial cell morphologies?",
        "answer": "Squamous cells are flat and wide. Both stratified and simple squamous epithelia will form smooth and flat surfaces that are specialized for the simple diffusion across the layer. Squamous cells are found in lung epithelia, the lining of blood vessels, and in the lining of body cavities.",
        "suggested_answer": "Line surfaces for simple diffusion",
        "options": [
            "Mucus secretion",
            "Secretion and absorption",
            "Line surfaces for simple diffusion",
            "Sensory reception"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 373,
        "question": "Hematopoietic stem cells allow for the development of which of the follow cells? ",
        "answer": "As mentioned in the passage, hematopoietic stem cells lead to the development of cells which will circulate in the circulatory system. White blood cells, red blood cells, and platelets all circulate in the circulatory system. Although platelets are themselves not cells, they are fragments of cells that are created by hematopoietic stem cells.",
        "suggested_answer": "I, II, and III",
        "options": [
            "I only",
            "II only",
            "I and II only",
            "III only",
            "I, II, and III"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 374,
        "question": "Which of the following processes in eukaryotic cellular respiration can occur in an anaerobic environment?",
        "answer": "Glycolysis is the first step of cellular respiration and, in the process of splitting glucose into two pyruvate molecules, does not require oxygen.",
        "suggested_answer": "Glycolysis",
        "options": [
            "Pyruvate decarboxylation",
            "Glycolysis",
            "Oxidative phosphorylation",
            "Citric acid cycle"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 375,
        "question": "Which of these is believed to be the first type of multicellular animals?",
        "answer": "It is believed that sponges were the first animals to utilize multicellularity. Sponges only have a few cell types, including: choanocytes, porocytes, and pinacocytes. Choanocytes have flagella that beat continuously to move water, and microvilli, which are specialized for absorbing nutrients. Porocytes are tubular cells that form the pores of sponges. Pinacocytes produce collagen and provide structural support to the sponge. Although algae likely developed multicellularity early on, they are not animals. Arthropods and mammals are both multicellular and animals, but evolved after sponges.",
        "suggested_answer": "Sponges",
        "options": [
            "Sponges",
            "Algae ",
            "Mammals",
            "Arthropods"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 376,
        "question": "Which of the following is a trait unique to cancer cells?",
        "answer": "Cancer cells are not controlled by cyclins and cyclin-dependent kinases, which regulate the cell cycle. They would not proceed through interphase if the system of checkpoints was still in place. The system of chemical signaling is mostly destroyed, preventing the cancer cells from being controlled by surrounding normal cells. Cancer cells, like virtually all body cells, utilize glucose for energy.",
        "suggested_answer": "They have escaped form cell cycle controls and inhibitions",
        "options": [
            "They have escaped form cell cycle controls and inhibitions",
            "They do not undergo interphase",
            "They communicate with other cells through chemical signalling",
            "Density-dependent growth factors limit their division",
            "They do not use glucose as an energy source"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 377,
        "question": "Frameshift mutations _________.",
        "answer": "Frameshift mutations involve the insertion or deletion of a nucleotide in a DNA sequence, changing the reading frame of the entire nucleotide sequence after the mutation. As a result, every subsequent codon is also affected, creating a change in the organism's phenotype.",
        "suggested_answer": "affect multiple codons",
        "options": [
            "only affect the codon in which the mutation occurred",
            "can lead to degeneracy, and have no effect on phenotype",
            "affect multiple codons",
            "often extend the length of the resulting polypeptide",
            "None of the other choices are correct"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 378,
        "question": "Characteristics of a good antibiotic include which of the following?",
        "answer": "A good antibiotic should target a biological system or function of the bacteria that is different than the host; an example being peptidoglycan in the cell wall of any bacteria. The antibiotic should be broad spectrum, or in other words have the ability to treat several types of bacterial infections regardless of the type of bacteria. The antibiotic should be able to be taken orally or intravenously for ease of administration. In addition most antibiotics are a dry form that can be stored at room temperature or above in order to make it ease to administer in underdeveloped countries or areas of poverty. The side effects should be negligible to the condition. Side effects are in direct proportion to the damage the chemical is doing to the host. If side effects are to harsh, other complications to the host, including death, could result.",
        "suggested_answer": "Targeting a biological function different than the host, uptake by the bacteria, ease of administration to patient, little to no side effects to the host",
        "options": [
            "Targeting a biological function different than the host, the type of bacteria, ease of administration to patient, little to no side effects to the host",
            "Targeting a biological function different than the host, uptake by the bacteria, ease of administration to patient, little to no side effects to the host",
            "Targeting a biological function different than the host, uptake by the bacteria, ease of administration to patient, harsh side effects on the host",
            "Targeting a biological function different than the host, uptake by the bacteria, the patient's blood type, little to no side effects to the host",
            "Targeting a biological function similar to that of the host, uptake by the bacteria, ease of administration to patient, little to no side effects to the host"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 379,
        "question": "Nonpolar molecules will typically pass though the plasma membrane via which mechanism?",
        "answer": "Nonpolar molecules can pass through the plasma membrane with relative ease. Even larger nonpolar molecules, such as steroid hormones, can pass through the plasma membrane easily. Passing through the membrane without the need for assisting proteins is known as passive diffusion.",
        "suggested_answer": "Passive diffusion",
        "options": [
            "Passive diffusion",
            "Secondary active transport",
            "Active transport",
            "Facilitated diffusion"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 380,
        "question": "Which of the following is not true of a DNA molecule?",
        "answer": "DNA is a polymer composed of nucleotide monomers. Each nucleotide is formed from a deoxyribose sugar, a phosphate, and a nitrogenous base. There are two types of nitrogenous bases: purines and pyrimidines. The purines are adenine and guanine, while the pyrimidines are thymine and cytosine (and uracil). Adenine will always bind thymine and cytosine will always bind guanine. Uracil is only found in RNA, and is absent from DNA.",
        "suggested_answer": "Adenine and thymine are held together by phosphodiester bonds",
        "options": [
            "Adenine and thymine are held together by phosphodiester bonds",
            "Complementary strands are held together by hydrogen bonds",
            "Uracil is not a component of the molecule",
            "A purine or pyrimidine is bound to each sugar-phosphate group"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 381,
        "question": "In a MAP kinase phosphorylation signaling cascade, where does the transduction pathway terminate?",
        "answer": "The MAP kinase signaling system is a common method of cellular signaling that regulates transcription of genes within a cell. The pathway begins when a ligand binds to a membrane receptor. The activated receptor activates an associated Ras protein, which is a GTPase that is activated when bound to GTP. The active Ras protein then donates a phosphate group to a MAP kinase protein and activates it. This begins a MAP kinase phosphorylation cascade. MAP kinases phosphorylate other MAP kinases in a serial fashion, which allows for signal amplification. After a series of MAP kinase phosphorylation events, specific MAP kinases phosphorylate transcription factors, regulating their activity and gene expression. Thus, the pathway ends with transcription factor regulation.",
        "suggested_answer": "Transcription factor regulation",
        "options": [
            "Active MAP kinase molecules",
            "Inhibition of DNA-binding motifs",
            "Transcription factor regulation",
            "Cytoplasmic target protein activation"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 382,
        "question": "What is one of the primary differences between the purines and pyrimidines of DNA?",
        "answer": "One of the primary differences between purines and pyrimidines is that purines consist of two rings, whereas the pyrimidines consist of one ring. One way to help remember the differences between purines and pyrimidines is that pyrimidines are like pyramids: sharp. Sharp things CUT (Cytosine Uracil Thymine). Note that uracil is only present in RNA, while thymine is only present in DNA. All nitrogenous bases bond to the sugar in the DNA backbone. The sugar is also connected to a phosphate group via a phosphodiester bond.",
        "suggested_answer": "Purines consist of two rings, whereas pyrimidines consist of one ring",
        "options": [
            "Purines consist of two rings, whereas pyrimidines consist of one ring",
            "Purines have a lipid chain, whereas pyrimidines do not",
            "Purines consist of a single ring, whereas pyrimidines consist of two rings",
            "The purines bond to the phosphates on the DNA backbone, whereas the pyrimidines bond to sugar"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 383,
        "question": "What is the purpose of the nasal passage?",
        "answer": "The nasal passage's main function is to heat or cool air before it enters the lungs. The cilia, mucous and hair also help filter air since the respiratory system is very sensitive to allergens and infection. Also, the lungs need to be kept moist and lubricated, so dry air is damaging. No gas exchange occurs in the nasal passage, it is merely a conduction zone through which air must travel before it gets to the alveoli where gas exchange occurs with the pulmonary capillaries.",
        "suggested_answer": "The nasal passage warms or cools air to body temperature and filters air",
        "options": [
            "The nasal passage allows transfer of oxygen to the nose and face",
            "The nasal passage filters air",
            "The nasal passage warms or cools air to body temperature and filters air",
            "The nasal passage is largely vestigial in humans"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 384,
        "question": "Which of the following is not indicative of a menstrual disorder?",
        "answer": "Menstrual disorders include a heavy flow (menorrhagia), irregular cycles (amenorrhea, polymenorrhea, metrorhagia, and oligomenorrhea), and no ovulation (anovulation).",
        "suggested_answer": "Ovulation occurs midway through the cycle",
        "options": [
            "Ovulation occurs midway through the cycle",
            "Heavy flow",
            "Ovulation does not occur",
            "Irregular cycles"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 385,
        "question": "Which of the following is not secreted by the pancreas into the duodenum?",
        "answer": "Pepsin is a protein degrading enzyme that is released by the stomach. All the other choices are released by the pancreas into the duodenum.",
        "suggested_answer": "Pepsin",
        "options": [
            "Bicarbonate",
            "Bile salts",
            "Pepsin",
            "Trypsin",
            "Lipases"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 386,
        "question": "What is the phase of cell division that divides the cytoplasm?",
        "answer": "Cell division has two phases, nuclear division and division of the cytoplasm. The nuclear division, also known as karyokinesis, involves division of genetic material, involving the phases prophase, metaphase, anaphase, and telophase. Cytokensis begins during late anaphase and is the phase during which the cytoplasm divides.",
        "suggested_answer": "Cytokinesis",
        "options": [
            "Metaphase",
            "Prophase",
            "Telophase",
            "Cytokinesis",
            "Nuclear division"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 387,
        "question": "Prokaryotes and eukaryotes are defined by a series of differences. Which of the following structures is found in one class of cell, but not the other?",
        "answer": "Prokaryotes do not have membrane-bound organelles, so they would not have mitochondria.",
        "suggested_answer": "Mitochondria",
        "options": [
            "Ribosomes",
            "Plasma membrane",
            "Mitochondria",
            "Cell wall"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 388,
        "question": "Why are villi found in the small intestine, but not in the stomach?",
        "answer": "The villi are helpful in increasing the surface area of the small intestine. This greatly increases the absorptive capabilities of the small intestine. The stomach has no villi, and does not contribute to absorption of macromolecules.",
        "suggested_answer": "The small intestine uses the villi to increase its surface area and facilitate absorption. Absorption does not occur in the stomach.",
        "options": [
            "The small intestine uses the villi to increase its surface area and facilitate absorption. Absorption does not occur in the stomach.",
            "The villi allow multiple areas where digestive enzymes can be released by the small intestine. The stomach does not release digestive enzymes.",
            "The small intestine uses villi to adhere to the food particles entering from the stomach, while the stomach has no need for such adhesion.",
            "The villi act in a cilia-like motion and move food down the small intestine. Movement in the stomach is generated by smooth muscle contractions."
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 389,
        "question": "Somatic motor neurons are found in which section of the spinal cord?",
        "answer": "The nervous system has two principle divisions for function and two principle divisions for structure. Structurally, there are the central and peripheral nervous system divisions. Functionally, there are the somatic and autonomic divisions.",
        "suggested_answer": "Ventral horn",
        "options": [
            "Ventral horn",
            "Dorsal root ganglion",
            "Dorsal horn",
            "Lateral horn"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 390,
        "question": "When two genes interact, and one gene masks the expression of a second gene, the first gene is __ to the second or ___ gene",
        "answer": "Epistasis describes the interaction of genes, where the epistatic gene masks the effects of another gene, called the hypostatic gene.",
        "suggested_answer": "epistatic, hypostatic",
        "options": [
            "epistatic, hypostatic",
            "recessive, dominant",
            "dominant, recessive",
            "hypostatic, epistatic"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 391,
        "question": "Which cellular structure is least likely to bear a membrane protein that moves hydrogen ions out of the organelle?",
        "answer": "The lysosome is an organelle that is used to digest broken cellular machinery or foreign particles. It maintains an acidic environment inside by pumping hydrogen ions in, not out. This environment helps denature the things it needs to digest, and is the most effective pH for the digestive enzymes that are inside.",
        "suggested_answer": "Lysosome",
        "options": [
            "Mitochondria",
            "Golgi body",
            "Lysosome",
            "Chloroplast",
            "Nucleus"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 392,
        "question": "Which scenario(s) will result in the activation of inactive bone marrows? ",
        "answer": "The activation of inactive bone marrows occur when the increase production of red blood cells is required. An major injury that result massive bleeding will reactive inactive bone marrows. Of the answer choices, a rupture of the abdominal aorta will result in massive internal bleeding. ",
        "suggested_answer": "I only",
        "options": [
            "III only",
            "I only",
            "II and III",
            "I and II",
            "II only"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 393,
        "question": "Which statement describes the inheritance of mitochondrial DNA?",
        "answer": "When a sperm and egg fuse to form a zygote, the nucleus of the sperm enters the cytoplasm of the egg. As a result, the father's genome is passed onto the offspring, but no cellular organelles from the sperm are transferred. Any DNA contained in the mitochondria must come from the mother's egg, and could not have come from the cytoplasm of the sperm. The offspring will inherit all mitochondrial DNA from the mother.",
        "suggested_answer": "Mitochondrial DNA is solely inherited from the mother",
        "options": [
            "Mitochondrial DNA is solely inherited from the mother",
            "Half of the mitochondrial DNA is inherited from the mother and half is inherited from the father",
            "Mitochondrial DNA is developed by the fetus, and is unrelated to either parent",
            "Mitochondrial DNA is derived from an undefined mix between the mitochondrial DNA of the parents",
            "Mitochondrial DNA is solely inherited from the father"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 394,
        "question": "Bacteria convert dead organic tissue into which of the following forms of nitrogen?",
        "answer": "During ammonification, saprobiotic bacteria convert dead organic nitrogen in the soil into ammonium.",
        "suggested_answer": "Ammonium",
        "options": [
            "Nitrite",
            "Ammonium",
            "Nitrogen gas",
            "Nitrate"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 395,
        "question": "Which of the following best describes the location of the vascular cambium in plants?",
        "answer": "The vascular cambium is a type of secondary meristem tissue located between the xylem and phloem of the stems and roots. Secondary meristem tissues are responsible for lateral growth; more specifically, the vascular cambium is responsible for secondary xylem and phloem development.",
        "suggested_answer": "Between the xylem and phloem ",
        "options": [
            "In the tips of leaves",
            "Between the xylem and phloem ",
            "None of these",
            "In the periderm"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 396,
        "question": "Which statement describes the pressure in the lungs during exhalation?",
        "answer": "This question requires a basic understanding of general chemistry and/or general physics. Remember that gas will only move from an area of high pressure to low pressure; thus, if air is moving out of the lungs, the pressure inside of the lungs must be greater than the pressure outside of the lungs. The point at which air does not move in or out of the lungs is a signal that the pressure of the gas inside of the lungs is equal to that of atmospheric pressure.",
        "suggested_answer": "Lung pressure must be higher than atmospheric pressure",
        "options": [
            "Lung pressure should not change during the process",
            "Lung pressure must be equal to atmospheric pressure",
            "Lung pressure must be higher than atmospheric pressure",
            "Lung pressure must be lower than atmospheric pressure"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 397,
        "question": "What is an autosome?",
        "answer": "Autosomes are all the chromosomes that do not determine an individual's sex. X and Y chromosomes both determine sex, and are sex chromosomes. The other 22 pairs of chromosomes are autosomes. ",
        "suggested_answer": "A non-sex chromosomes",
        "options": [
            "A non-sex chromosomes",
            "The X chromosome",
            "Both the X and Y chromosomes",
            "The Y chromosome"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 398,
        "question": "Which of the following best describes intercalated disks?",
        "answer": "Intercalated disks are structures that connect cardiac muscle cells, allowing them to work together and communicate within the heart. Intercalated disks are made up of different types of cell junctions and are observable under the microscope. This makes cardiac muscle tissue distinguishable.",
        "suggested_answer": "Structures that connect cardiac muscle cells",
        "options": [
            "Portions of the cell that contain high quantities of myoglobin",
            "Structures that connect cardiac muscle cells",
            "They are observable characteristics of striated muscle tissue",
            "They form the boundaries of actin filaments"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 399,
        "question": "Which of the following is true regarding a synapse?",
        "answer": "Neurotransmitters, such as acetylcholine, are chemical signals that transmit action potentials from one neuron to another. This process occurs at the synapse, where a neurotransmitter is released from the presynaptic neuron. This neurotransmitter travels across the synaptic cleft and binds to a receptor on the postsynaptic neuron. Statement I is thus false.",
        "suggested_answer": "II only",
        "options": [
            "II and III",
            "I and III",
            "II only",
            "I only"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 400,
        "question": "Which of the following is NOT an assumption required for Hardy-Weinberg equilibrium?",
        "answer": "Hardy-Weinberg states that for a population to be in equilibrium, it must not be experiencing migration, genetic drift, mutation, or selection. By this definition, population size cannot fluctuate.",
        "suggested_answer": "Population size must fluctuate",
        "options": [
            "No mutations",
            "No migration",
            "Random mating",
            "No selection is occurring",
            "Population size must fluctuate"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 401,
        "question": "Kinetochores fibers form in which phase of mitosis?",
        "answer": "Kinetochore fibers allow the microtubules from the spindle apparatus to attach to the chromatids. Kinetochores are formed in prometaphase. These will help move the chromosomes in preparation for metaphase, when the chromosomes will need to be lined up on the metaphase plate. ",
        "suggested_answer": "Prometaphase",
        "options": [
            "Anaphase",
            "Prophase",
            "Metaphase",
            "Prometaphase"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 402,
        "question": "The trait for tall pea plants is dominant to the trait for short pea plants. Which of the following experimental set-ups could be used to prove this conclusion?",
        "answer": "A test-cross is used to determine the genotypes of unknown organisms, but can also be valuable to distinguish dominant and recessive traits. A test-cross is when an unknown organism is crossed with a homozygous recessive organism. If the unknown organism is homozygous dominant, then all offspring will show the dominant phenotype.",
        "suggested_answer": "Cross a homozygous tall plant with a homozygous short plant and observe the offspring phenotypes",
        "options": [
            "Cross a homozygous tall plant with a homozygous short plant and observe the offspring phenotypes",
            "Cross a homozygous tall plant with a heterozygous short plant and observe the offspring phenotypes",
            "Cross a heterozygous tall plant with a homozygous short plant and observe the offspring phenotypes",
            "Cross a homozygous tall plant with a heterozygous tall plant and observe the offspring phenotypes"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 403,
        "question": "What would be the sequence of mRNA translated from the DNA segment 5' - ACGTCA - 3' ?",
        "answer": "mRNA contains uracil instead of thymine, therefore your answer should not contain the base thymine. Remember, DNA is read in the 3' to 5' direction and the corresponding strand is created in the 5' to 3' direction. It is important to pay attention to polarity of the strands. Adenine pairs with thymine, and/or uracil. Guanine pairs with cytosine.",
        "suggested_answer": "5' UGACGU 3'",
        "options": [
            "5' TGCAGT 3'",
            "5' UGCAGU 3'",
            "5' TACTTU 3'",
            "5' UCGAGU 3'",
            "5' UGACGU 3'"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 404,
        "question": "Nitrogen : Nucleic Acids :: Phosphorous : ______________.",
        "answer": "Nitrogen is essential to create all the nucleic acids, and phosphorous is essential to create phospholipids (an obvious choice), ATP and ADP (they are the same class of molecule, and the P stands for phosphate), and DNA (for the phosphate-sugar backbone).",
        "suggested_answer": "All answer choices",
        "options": [
            "Phospholipids",
            "All answer choices",
            "ATP",
            "ADP",
            "DNA"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 405,
        "question": "Which of the following is not true regarding RNA?",
        "answer": "RNA stands for ribonucleic acid, and each RNA nucleotide contains one phosphate, one nitrogenous base (either adenine, uracil, cytosine, or guanine), and one ribose sugar. RNA does not contain a deoxyribose sugar as seen in DNA.",
        "suggested_answer": "RNA nucleotides contain one deoxyribose sugar ",
        "options": [
            "RNA is made by transcribing DNA",
            "RNA nucleotides contain one ribose sugar",
            "RNA nucleotides contain one deoxyribose sugar ",
            "RNA contains uracil instead of thymine",
            "RNA is single stranded"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 406,
        "question": "Which of the following best represent characteristics of type IIa skeletal muscle tissues?",
        "answer": "Type IIa skeletal muscle tissue is a subtype of skeletal muscle tissue that is related to fast twitch muscles. Type IIa tissues are aerobic and have many mitochondria.",
        "suggested_answer": "They are both aerobic and contain many mitochondria",
        "options": [
            "They are both aerobic and contain many mitochondria",
            "They feature aerobic activity",
            "They don't have many mitochondria and are anaerobic",
            "They contain many mitochondria"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 407,
        "question": "Which blood vessels supply oxygen-rich blood to the heart? ",
        "answer": "The coronary arteries supply blood to the heart. These vessels wrap around the heart muscle. Heart attacks often occur when these blood vessels become clogged, thus inhibiting blood flow to the heart, resulting in necrosis of cardiac cells. Note that the blood in the chambers of the heart is not involved in any nutrient exchange within the heart, rather, it must be pumped through capillaries where blood can supply nutrients to cells.",
        "suggested_answer": "Coronary arteries",
        "options": [
            "Coronary veins",
            "Superior vena cava",
            "Aorta ",
            "Pulmonary arteries",
            "Coronary arteries"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 408,
        "question": "What is not true regarding the origin of replication?",
        "answer": "The origin of replication is the sequence of DNA where replication is initiated. The origin of replication often has a high content of adenine and thymine nucleotides because they are only bound together by two hydrogen bonds, making the helix easier to open and unwind. There are multiple origins of replication on each chromosome in eukaryotes, while there is only one origin of replication in prokaryotes. The origin of replication binds to initiator proteins that make up the pre-replication complex, which initiates replication.",
        "suggested_answer": "It is the location where primers are added",
        "options": [
            "Replication can continue in either direction from the origin of replication",
            "The origin of replication binds to initiator proteins",
            "It is the sequence in the genome where DNA replication begins",
            "It is the location where primers are added"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 409,
        "question": "Enzymes are a type of which of the following organic molecules?",
        "answer": "Enzymes are proteins that act as catalysts for metabolism. Enzymes are essential in proper functioning of metabolism because without them chemical traffic throughout the metabolic pathways would become backed up.",
        "suggested_answer": "Protein",
        "options": [
            "Carbohydrate",
            "Lipid",
            "Protein",
            "Sugar"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 410,
        "question": "When a red blood cell is placed into a hypertonic solution it will __________.",
        "answer": "A hypertonic solution implies that there is a higher concentration of solute outside of the cell than inside. To balance this difference in concentration, water from inside the cell will diffuse across the membrane and the cell will shrivel. If the solution were hypotonic to the cell, the opposite would happen: the cell would take in water from the solution, causing it to swell and potentially burst (lyse).",
        "suggested_answer": "lose water to the extracellular environment",
        "options": [
            "not be affected, since it lacks a nucleus",
            "lose ions to the extracellular environment",
            "gain water from the extracellular environment",
            "lyse",
            "lose water to the extracellular environment"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 411,
        "question": "Where is the hormone melatonin produced?",
        "answer": "Melatonin is produced in the pineal gland which is a gland in the brain responsible for the body\u2019s circadian rhythms and sleep/wake cycle. The concentration of melatonin peaks at night and causes us to fall asleep. Melatonin is degraded while we sleep so that when we wake up in the morning we start to produce it again throughout the day.",
        "suggested_answer": "Pineal gland",
        "options": [
            "Posterior pituitary",
            "Pineal gland",
            "Anterior pituitary",
            "Adrenal gland",
            "Pancreas"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 412,
        "question": "Under anaerobic conditions, a eukaryotic cell will not undergo the Krebs cycle. Why is this?",
        "answer": "The role of the Krebs cycle is to produce the intermediates NADH and FADH2, which will serve as electron donors in the electron transport chain (ETC). At the same time, the ETC creates NAD+ and FADH+ as byproducts. The products can then be turned around to continue fueling the Krebs cycle. Since the ETC will not function in an anaerobic environment, neither will the Krebs cycle. The reactants will not be replenished, and the cycle will be unable to continue.",
        "suggested_answer": "Lack of NAD+ and FADH+",
        "options": [
            "None of the other answers",
            "The end product for the Krebs cycle is oxygen",
            "Lack of NAD+ and FADH+",
            "Lack of NADH and FADH2",
            "The reactant for the Krebs cycle is oxygen"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 413,
        "question": "Protein glycosylation plays an important role in the proper formation and delivery of many cell created proteins. Which of the following is one of the locations this crucial process is carried out?",
        "answer": "Glycosylation is an enzymatic process that occurs in the rough endoplasmic reticulum and the Golgi apparatus as a co-translational or post-translational modification. There are also O-Linked Glycosylation reactions that can also occur in the nucleus and cytoplasm. These glycans serve a variety of structural and functional roles of both membrane and secretory proteins and are therefore very important to the integrity of the proteins.",
        "suggested_answer": "Lumen of the Golgi apparatus",
        "options": [
            "Ribosome",
            "Nucleolus",
            "Lumen of the Golgi apparatus",
            "Lumen of the lysosome",
            "Mitochondria"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 414,
        "question": "What is the purpose of connective tissue in the human body?",
        "answer": "Connective tissue is a category of tissue in the human body that supports and connects different organs and tissues in the body. Other tissue categories in the human body include epithelial, which lines the cavities and surfaces of the organs in the body, muscle, which contracts to produce motion, and nervous tissue, which transmits information through electrical and chemical signals.",
        "suggested_answer": "Supports and connects different organs and tissues in the body",
        "options": [
            "Supports and connects different organs and tissues in the body",
            "Transmits information through electrical signals",
            "Produces motion",
            "Lines the cavities and surfaces of organs in the body"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 415,
        "question": "Which of the following is one of the primary functions of carbohydrates?",
        "answer": "Carbohydrates, such as glucose and fructose, are primarily used in metabolic pathways to provide energy for the cell. Glucose is the first reactant necessary for driving glycolysis and subsequent cellular respiration in the cell, with the goal of producing ATP.",
        "suggested_answer": "Energy storage",
        "options": [
            "Maintain genetic fidelity",
            "Catalyze chemical reactions",
            "Protect cell organelles",
            "Energy storage"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 416,
        "question": "Which of the following traits are specific to angiosperms?",
        "answer": "Angiosperms are a class of land plant that produces seeds. Angiosperms can be distinguished from gymnosperms by characteristics that include flowers, the presence of endosperm, and fruit production.",
        "suggested_answer": "All of these",
        "options": [
            "Fruit production",
            "Flowers",
            "All of these",
            "Endosperm"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 417,
        "question": "Which of the following is not a characteristic of B-cell receptors?",
        "answer": "Each B-cell receptor is a Y-shape molecule that, upon maturation, can become a secretory form (an antibody). Each receptor is comprised of four polypeptide chains: two identical heavy chains and two identical light chains. The four chains are linked together by disulfide bridges. Both heavy and light chains consist of constant regions, as well as variable regions. The variable regions of each chain provide the specificity for antigen binding, which generates a signaling cascade within the B-cell.",
        "suggested_answer": "They consist of two polypeptide chains",
        "options": [
            "They consist of two polypeptide chains",
            "Upon further maturation, the B-cell receptor can become a secreted molecule",
            "They have transmembrane domains that anchor into the B-cell plasma membrane",
            "They consist of two identical heavy chains and two identical light chains"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 418,
        "question": "An electron acceptor is also known as a(n) __________.",
        "answer": "When a substance acts as an electron acceptor it is an oxidizing agent. This is because it causes the oxidation of the substance from which it accepted the electron upon accepting an electron. If the substance acts as an electron donor then it is a reducing agent. Recall the mnemonic: OIL RIG; Oxidation Is Loss of electrons, Reduction Is Gain of electrons.",
        "suggested_answer": "oxidizing agent",
        "options": [
            "reductant",
            "oxidizing agent",
            "reducing agent",
            "oxidant"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 419,
        "question": "Which of the following types of cell transport requires energy? ",
        "answer": "Active transport is when a particle moves against its concentration gradient through a selective membrane with the aid of energy. Moving down its concentration gradient and from a high to low concentration is simple diffusion. The movement of water down its concentration gradient is osmosis and moving a particle or ion through a protein is facilitated transport. These are all examples of passive transport. ",
        "suggested_answer": "The transport of a particle across a selectively permeable membrane against its concentration gradient. ",
        "options": [
            "The transport of a particle across a selectively permeable membrane with the assistance of the membrane's transport proteins. ",
            "The transport of a potassium ion across a selectively permeable membrane from an area of high concentration to low concentration. ",
            "The transport of a particle across a selectively permeable membrane down its concentration gradient. ",
            "The diffusion of a water across a selectively permeable membrane against its concentration gradient. ",
            "The transport of a particle across a selectively permeable membrane against its concentration gradient. "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 420,
        "question": "According to the character table above, which of the following would define a clade?",
        "answer": "A clade is defined by a organisms that possess a shared derived trait. In other words, we need to find a set of organisms in the table that includes all organisms with a certain listed trait, while excluding any that lack that specific trait. The lion, fish, and sea urchin are the only clade listed. They are all triploblastic, segmented deuterostomes, and none of the other species share those characteristics. The trait of being a deuterostome is the shared derived trait that defines this clade.",
        "suggested_answer": "Lion, fish, sea urchin",
        "options": [
            "Scorpion, sea urchin",
            "Fish, sea urchin, scorpion",
            "Fish, sea urchin",
            "Sea urchin, jellyfish",
            "Lion, fish, sea urchin"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 421,
        "question": "Which of the following best describes when a female most fertile during the menstrual cycle?",
        "answer": "During the menstrual cycle, the time when fertility peaks is at ovulation and a few days preceding it. At this point, sperm can survive in the female reproductive tract until oocyte release.",
        "suggested_answer": "Ovulation and a few days preceding it",
        "options": [
            "Ovulation and a few days preceding it",
            "3 days after menstruation",
            "Follicular phase",
            "During menstruation"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 422,
        "question": "In which cellular compartment does glycolysis take place?",
        "answer": "Glycolysis (the process of breaking down glucose) takes place in the cytoplasm, or cytosol the aqueous portion of the cytoplasm. It is in the cytoplasm where the enzymes required for glycolysis are found.",
        "suggested_answer": "Cytoplasm (Cytosol)",
        "options": [
            "Golgi apparatus",
            "Intermembrane space",
            "Cytoplasm (Cytosol)",
            "Inner mitochondrial membrane",
            "Mitochondrial matrix"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 423,
        "question": "Which of the following occurs during meiosis, but does not occur during mitosis?",
        "answer": "During mitosis we do not see separation of the homologous chromosomes. This is the reason that meiosis results in a reduction of ploidy and that mitosis does not. Separation of homologous chromosomes occurs after the formation of tetrads, during anaphase I of meiosis.",
        "suggested_answer": "Segregation of homologous chromosomes",
        "options": [
            "Separation of sister chromatids",
            "Cytokinesis",
            "Segregation of homologous chromosomes",
            "Chromosome condensation"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 424,
        "question": "What is an example of information that biologists can gather from studying coprolites?",
        "answer": "Coprolites are fossilized fecal matter from an animal. By studying their contents, biologists can gather information on what the animal ate, and what species were there to be eaten by the animal.",
        "suggested_answer": "Each of these are examples of information biologists can gather from studying coprolites.",
        "options": [
            "Each of these are examples of information biologists can gather from studying coprolites.",
            "None of these; as coprolites are not a part of the actual animal, they hold very little research value.",
            "They can be studied to determine what sorts of plants or animals lived in the same time period.",
            "They can be studied to determine an extinct animal's size.",
            "They can be studied to determine what an extinct animal's diet consisted of."
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 425,
        "question": "Which of the following is true?",
        "answer": "Aerobic processes require oxygen, while anaerobic processes can continue in the absence of oxygen. Glycolysis can function under aerobic or anaerobic conditions to produce small amounts of ATP. The Krebs cycle and electron transport chain, however, require oxygen as the final electron acceptor in the electron transport chain. These processes cannot continue in anaerobic environments.",
        "suggested_answer": "Glycolysis can occur under aerobic or anaerobic conditions",
        "options": [
            "Fermentation can only occur under aerobic conditions",
            "Glycolysis can only occur under aerobic conditions",
            "The Krebs cycle can occur under aerobic or anaerobic conditions",
            "Glycolysis can occur under aerobic or anaerobic conditions"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 426,
        "question": "Which type of isolation between populations is due to barriers related to time, such as differences in mating periods or differences in the time of day that individuals are most active.",
        "answer": "Temporal isolation is between populations due to barriers related to time, such as differences in mating periods or differences in the time of day that individuals are most active. Geographic isolation between populations is due to physical barriers, not time. It wouldn't be both of them because only temporal isolation deals with time, versus geographic isolation is based on the physical barrier between populations such as mountains, rivers, or, for example, insects living on different trees in the jungle. Reproductive isolation is the inability to interbreed between species for various reasons like sterile offspring, physical incompatibility, or different mating rituals.",
        "suggested_answer": "Temporal isolation",
        "options": [
            "Behavioral isolation",
            "Geographic isolation",
            "Two of these",
            "Temporal isolation",
            "Reproductive isolation"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 427,
        "question": "Which of the following is characteristic of RNA?",
        "answer": "RNA and DNA are very similar in composition, but differ in structure and function. DNA is used to code for genetic material, while RNA is used to generate protein products. Since DNA has a long-term goal of storing information and RNA has a short-term goal of increasing production, it makes sense that DNA is a permanent molecule and RNA is transient. Soon after translation, mRNA is degraded by ribonuclease (RNase). The transient nature of RNA is also linked to its stability. DNA must be very stable to avoid problems with gene storage. DNA is double-stranded to help enhance stability. In contrast, RNA can afford to be less stable and is easily degraded, partially due to its single-stranded structure.",
        "suggested_answer": "RNA has a hydroxyl group on the 2\u2019 carbon of ribose",
        "options": [
            "RNA is less stable than DNA",
            "RNA has a hydroxyl group on the 2\u2019 carbon of ribose",
            "RNA is typically double-stranded",
            "RNA is a permanent molecule in most cells"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 428,
        "question": "Which of the following is a target of follicle-stimulating hormone (FSH)?",
        "answer": "Follicle-stimulating hormone (FSH) is a tropic hormone produced in the anterior pituitary. Its primary functions are to regulate development, growth, pubertal maturation, and reproductive processes of the human body. ",
        "suggested_answer": "Testes",
        "options": [
            "Adrenal gland",
            "Anterior pituitary",
            "Mammary glands",
            "Testes"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 429,
        "question": "The relative refractory period is a period during the generation of an action potential during which __________.",
        "answer": "During the generation of an action potential, the cell will undergo two refractory periods. The first is referred to as the absolute refractory period, during which no stimulus, regardless of size, will generate another action potential. This is followed by the relative refractory period, during which an action potential will be generated only if an abnormally large stimulus is encountered. During the relative refractory period, the cell is hyperpolarized due to the removal of potassium ions from the cell interior, which results in a more negative membrane potential than the cell would have at rest.",
        "suggested_answer": "only an abnormally large stimulus will create an action potential",
        "options": [
            "the cell cannot create another action potential",
            "the cell is depolarized",
            "the cell will create an action potential by even the smallest stimulus",
            "only an abnormally large stimulus will create an action potential",
            "a normal stimulus can create another action potential"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 430,
        "question": "What is the primary function of the occipital lobe?",
        "answer": "Each answer corresponds to one lobe of the cerebrum. The frontal lobe is responsible for conscious thought, as well as many other functions. The parietal lobe is responsible for integrating tactile information. The temporal lobe has the functions of interpreting smell and sound. The occipital lobe is associated with processing visual information.",
        "suggested_answer": "Integrating visual input",
        "options": [
            "Integrating visual input",
            "Conscious thought",
            "Integrating tactile input",
            "Integrating auditory input"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 431,
        "question": "What are the net products of glycolysis?",
        "answer": "Glycolysis creates ATP and NADH through substrate level phosphorylation. The net products are 2 ATP, 2 NADH, and 2 pyruvate molecules. More ATP and high energy electron carriers are produced in the subsequent stages of the metabolic pathway such as pyruvate processing and the citric acid cycle. ",
        "suggested_answer": "2 ATP and 2 NADH",
        "options": [
            "38 ATP",
            "2 ATP and 6 FADH2",
            "2 ATP and 2 NADH",
            "2 ATP and lactic acid",
            "4 ATP and 2 NADH"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 432,
        "question": "What are the two classification groups that are representative of the scientific name for an organism?",
        "answer": "Genus and species are the two taxonomic classifications that are used to represent the scientific name of an organism. The hierarchy of the binomial classification system is (from the top tier to the bottom): kingdom, phylum, class, order, family, genus, species. Kingdom gives the broadest classification of an organism, while species gives the most specific. The most specific classifications are used to name the organism.",
        "suggested_answer": "Genus and species",
        "options": [
            "Class and phylum",
            "Genus and kingdom",
            "Class and kingdom",
            "Species and kingdom",
            "Genus and species"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 433,
        "question": "Which of the following enzymes breaks down starches and carbohydrates?",
        "answer": "Amylase is the enzyme that breaks down starches and carbohydrates. There are two types, salivary amylase and pancreatic amylase. Salivary amylase is produced in the mouth by the salivary glands and it is one of the first enzymes that begins the process of food digestion.",
        "suggested_answer": "Amylase",
        "options": [
            "Trypsin",
            "Lipase",
            "Nuclease",
            "Peptidase",
            "Amylase"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 434,
        "question": "Which of the following statements are true for all ligands?",
        "answer": "Ligands are molecules that form complexes with other molecules, proteins, or genetic material. The binding of a ligand causes a conformational change to the complex that leads to a response. This response by associated molecules is not limited to activation, but also includes inhibition, partial activation, and inhibition of constitutive activity. Ligands can be either selective or non-selective, meaning that they can bind to only specific molecules or to many receptors, respectively.",
        "suggested_answer": "Binding to a receptor causes a conformation change",
        "options": [
            "Ligands only bind to proteins",
            "Ligands always activate receptors",
            "Ligands bind to specific receptors",
            "Binding to a receptor causes a conformation change"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 435,
        "question": "If a DNA sequence is mutated from AGCTAA to AGCCTAA, what kind of mutation has occurred?",
        "answer": "An insertion mutation is a mutation due to an additional nucleotide base pair being added to a DNA sequence. In this case, a cytosine nucleotide has been inserted.",
        "suggested_answer": "Insertion",
        "options": [
            "Insertion",
            "Point mutation",
            "Deletion",
            "Substitution"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 436,
        "question": "Beyond bacteria, which of the following acts as an alternative method for nitrogen fixation?",
        "answer": "An alternative method of nitrogen fixation is a lightening strike; however, this is rare and soil-dwelling bacteria perform most of the Earth\u2019s nitrogen fixation.",
        "suggested_answer": "Lightening strike",
        "options": [
            "Lightening strike",
            "Earthquake",
            "Fertilizers ",
            "Roots"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 437,
        "question": "Which of the following is true of genetic drift?",
        "answer": "Genetic drift describes the random selection of alleles that are passed from one generation to the next due to independent assortment in gametogenesis. Genetic drift cannot create new alleles, so it cannot increase genetic diversity (the number of alleles in a population). It can, however, decrease genetic diversity if an allele of a low frequency is not passed down to subsequent generations due to pure chance.",
        "suggested_answer": "It can decrease genetic diversity in a population ",
        "options": [
            "It is necessary in order for a population to be in Hardy-Weinberg equilibrium",
            "It can decrease genetic diversity in a population ",
            "It can increase genetic diversity in a population",
            "It plays a much larger role in determining the genetic makeup of populations than natural selection"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 438,
        "question": "Which is not true about the myelin sheath on neurons?",
        "answer": "The myelin sheath on neurons is a fatty sheath provided by Schwann cells in the peripheral nervous system and oligodendrocytes in the central nervous system that increases the speed of signal transmission through saltatory conduction. Myelin makes matter in the brain appear white. Areas of the axon that aren\u2019t myelinated are called nodes of Ranvier.",
        "suggested_answer": "Myelinated areas of a neuron are called nodes of Ranvier",
        "options": [
            "Myelin is what makes white matter appear white",
            "Myelin is provided by Schwann cells",
            "Myelin allows for saltatory conduction",
            "Myelinated areas of a neuron are called nodes of Ranvier"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 439,
        "question": "Which of the following does not describe a detergent action against bacteria?",
        "answer": "Detergents, or soaps, are non-lethal microbial controls. They emulsify lipids and oils, making it easier to wash them away. The action of washing away substances is strictly a mechanical mode of action. Detergents provide only static control, and have no killing power.",
        "suggested_answer": "Lethal control",
        "options": [
            "Emulsification",
            "Lethal control",
            "Removes lipids",
            "Static control",
            "Mechanical mode of action"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 440,
        "question": "Which structure could be found in a prokaryotic cell?",
        "answer": "Prokaryotic cells differ from eukaryotic cells in that they lack any membrane-bound organelles, including a nucleus. Instead, prokaryotic cells simply have an outer plasma membrane, DNA nucleoid structure, and ribosomes.",
        "suggested_answer": "Plasma membrane",
        "options": [
            "Rough endoplasmic reticulum",
            "Plasma membrane",
            "Centrioles",
            "Peroxisomes",
            "Mitochondria"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 441,
        "question": "Which of the following best describes the consumers within the biosphere?",
        "answer": "Heterotrophs are the consumers of the biosphere in that they do not produce organic material but rather consume other organisms. Animals are an example of heterotrophs. Autotrophs produce their own organic material from inorganic materials. Plants are an example of autotrophs.",
        "suggested_answer": "Heterotrophs",
        "options": [
            "Autotrophs",
            "Heterotrophs",
            "Detritivores",
            "None of these"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 442,
        "question": "What is a centromere?",
        "answer": "Centromeres are the areas of chromosomes where sister chromatids are associated. They also have the important function of serving as the attachment site for microtubules of the mitotic spindle. Centromeres are not made of protein or microtubules.",
        "suggested_answer": "The site of association of sister chromatids",
        "options": [
            "An organelle that organizes microtubules",
            "A protein that holds sister chromatids together",
            "A protein made mostly of tubulin",
            "The site of association of sister chromatids"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 443,
        "question": "In regard to cellular membranes, what does it mean to be selectively permeable?",
        "answer": "A cell must exchange molecules and ions with its surroundings. This process is controlled by the selective permeability of the plasma membrane. Passive transport requires no energy from the cell; molecules like water can diffuse into and out of the cell through the phospholipid bilayer freely by way of osmosis. Other molecules and ions, like sodium, are actively transported across the phospholipid bilayer. This requires ATP created by the cell. Active transport moves solutes against their concentration gradients, which is why it requires energy. ",
        "suggested_answer": "Molecules and ions outside the cell are selected to enter the cell via active or passive transport through the phospholipid bilayer",
        "options": [
            "Polarization of the cell membrane allows for passive transport of all foreign molecules or ions",
            "Molecules and ions are always kept to the exterior of the phospholipid bilayer",
            "Molecules and ions can pass freely through the phospholipid bilayer",
            "Molecules and ions outside the cell are selected to enter the cell via active or passive transport through the phospholipid bilayer",
            "Polarization of the cell membrane allows for no entrance of foreign molecules or ions"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 444,
        "question": "Which of the following terms is best defined as a mature, motile, and haploid sperm cell produced during spermatogenesis?",
        "answer": "Mature sperm cells that are haploid, motile, and produced during spermatogenesis are called spermatozoa. Spermatids are haploid; however, they are immature. Spermatids will turn into spermatozoa once they mature.",
        "suggested_answer": "Spermatozoa",
        "options": [
            "Spermatogonium",
            "Spermatid",
            "Secondary spermatocyte",
            "Spermatozoa"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 445,
        "question": "In the Krebs cycle, what is the gas released as waste?",
        "answer": "Carbon dioxide is the gas produced in the Krebs cycle, which animals exhale. Oxygen is used as an electron acceptor, while nitrogen is not a waste gas. Carbon monoxide is not a waste product in the Krebs cycle. ",
        "suggested_answer": "Carbon dioxide",
        "options": [
            "Nitrogen",
            "Oxygen",
            "Carbon dioxide",
            "Carbon monoxide"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 446,
        "question": "Which of the following best describes runoff?",
        "answer": "Runoff, or surface runoff, is the flow of water over the Earth\u2019s surface. Runoff occurs when there is an excess of water on the surface due to excessive precipitation. The excessive precipitation leads to the full saturation of soil, resulting in water flowing over the surface. ",
        "suggested_answer": "The flow of water over the Earth's surface",
        "options": [
            "Transformation of water from liquid to gaseous phase",
            "The flow of water underground",
            "The changing of water vapor to ice",
            "The flow of water over the Earth's surface"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 447,
        "question": "Where is chlorophyll located?",
        "answer": "Chlorophyll is used to capture sunlight during the light dependent reaction; the excited electrons then flow down the electron transport chain located on the thylakoid membrane.",
        "suggested_answer": "Thylakoid membrane",
        "options": [
            "Thylakoid stroma",
            "Intermembrane space",
            "Thylakoid membrane",
            "Mitochondrial membrane"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 448,
        "question": "A culture of cells is grown on a special medium containing substance \"Y\". Substance \"Y\" is a poison that blocks the electron transport chain. The addition of substance \"Y\" to the culture of cells will likely result in which of the following?",
        "answer": "The electron transport chain (ETC) is responsible for oxidative phosphorylation, resulting in the production of ATP. The ATP is produced by a proton gradient made as electrons are transported throughout the chain. Inhibition of this process by \"Substance Y\" will block the formation of a proton gradient. Blocking the proton gradient will not allow for oxidative phosphorylation to occur, thus greatly decreasing the amount of ATP produced.",
        "suggested_answer": "ATP production that will decrease",
        "options": [
            "Oxygen consumption that will increase",
            "Lactic acid will be converted to ethanol",
            "ATP production that will decrease",
            "ATP production that will increase",
            "ATP production will remain the same"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 449,
        "question": "What would happen to a cell that contains 4.0 moles of solute inside it if it were placed in a cup of water with 2.0 moles of sodium chloride?",
        "answer": "This question is tricky because we need to remember that if there are 2.0 moles of NaCl in the water, then there are 4.0 moles of solute because it will dissociate to one and one ; therefore, this is an isotonic environment and there will be no net movement of water. When a compound dissociates in solution, it is the ion concentration that will affect the movement of water, rather than the amount of initial solid.",
        "suggested_answer": "Nothing",
        "options": [
            "Nothing",
            "It would swell",
            "It would shrivel",
            "Half of the NaCl would move into the cell to make even concentrations",
            "It would burst"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 450,
        "question": "Which of the following is not a feature of type I skeletal muscle tissue?",
        "answer": "Type I skeletal muscle tissue is a red oxygenated muscle tissue that carries oxygen and is involved in aerobic activity. As a result, type I tissue is a slow twitch muscle. Type I skeletal muscle tissue contains many mitochondria.",
        "suggested_answer": "It is classified as fast twitch muscle tissue",
        "options": [
            "It is involved in aerobic activities",
            "It has many mitochondria",
            "It is classified as fast twitch muscle tissue",
            "It is \"red\" muscle tissue"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 451,
        "question": "Which of the following is not true about water-soluble hormones?",
        "answer": "Water-soluble hormones can be either polypeptides (proteins) or tyrosine-derivatives. The binding of a water-soluble hormone to a receptor occurs on the cell membrane, and receptor binding triggers a physiological response through a signal transduction cascade. Peptide hormones include insulin, growth hormone, oxytocin, adrenocorticotropic hormone, and many more. Tyrosine-derivative hormones include epinephrine and the thyroid hormones (T3 and T4).",
        "suggested_answer": "They act as local regulators",
        "options": [
            "Tyrosine-derivative hormones are water-soluble",
            "They act as local regulators",
            "Their receptors are found on the cell membrane",
            "Binding to its receptor can initiate a signal transduction cascade"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 452,
        "question": "Which of the following characteristics affects the function of an enzyme?",
        "answer": "Temperature, pH, and substrate concentration all affect the function of an enzyme; therefore, the correct answer is all of these.",
        "suggested_answer": "All of these",
        "options": [
            "Substrate concentration",
            "All of these",
            "pH",
            "Temperature",
            "None of these"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 453,
        "question": "Viral genomes may consists of which of the following nucleic acids?",
        "answer": "The nucleic acids that make up viral genomes are very diverse. Viral genome can consist of both double and single stranded DNA as well as double and single stranded RNA.",
        "suggested_answer": "I, II, III, and IV",
        "options": [
            "I, II, and III",
            "II, III, and IV",
            "I, II, III, and IV",
            "I and II",
            "I and IV"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 454,
        "question": "How many molecules are produced from the citric acid cycle per acetyl-CoA? ",
        "answer": "Two molecules are produced per one \"turn\" around the cycle. Note that each glucose results in two pyruvate, which convert to two acetyl-CoA and power the citric acid cycle for two turns.",
        "suggested_answer": "2",
        "options": [
            "2",
            "5",
            "1",
            "3",
            "4"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 455,
        "question": "Which of the following is not a mechanism of evolution?",
        "answer": "The mechanisms of evolution include gene flow, mutation, genetic drift, and natural selection. All of these mechanisms change allele frequencies in groups of reproducing organisms.",
        "suggested_answer": "Sexual reproduction",
        "options": [
            "Sexual reproduction",
            "Gene flow",
            "Mutation",
            "Genetic drift"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 456,
        "question": "Dinitrophenol (DNP) is a known uncoupling agent, which is capable of inhibiting the mitochondria's ability to maintain a proton gradient. How might this affect the function of the mitochondria?",
        "answer": "ATP synthase, the enzyme responsible for ATP production on the inner mitochondrial membrane, depends on the proton gradient produced by the electron transport chain (ETC). If the proton gradient is disrupted, not as many ATP can be produced.",
        "suggested_answer": "Decreased ATP production",
        "options": [
            "Increased FADH2 production",
            "Increased NADH production",
            "Increased ATP production",
            "No change to ATP production",
            "Decreased ATP production"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 457,
        "question": "A cell placed in an environment in which there are more solutes outside the cell than inside the cell will __________ and is said to be in a __________ environment.",
        "answer": "We need to know the different between hypertonic, hypotonic, and isotonic. An environment is said to be hypertonic is there are more solute outside of the cell than inside. Since these solutes do not cross the cellular membrane very easily, but water can cross, water will travel out of the cell to balance the concentrations. This would cause the cell to shrink and shrivel.",
        "suggested_answer": "shrivel . . . hypertonic",
        "options": [
            "swell . . . hypertonic",
            "swell . . . hypotonic",
            "lyse . . . isotonic",
            "shrivel . . . hypertonic",
            "shrivel . . . hypotonic"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 458,
        "question": "Which of the following structures is responsible for inducing neurulation?",
        "answer": "Neurulation is induced in embryos through secreted growth factors and inhibitory factors from the notochord. The notochord is a rod-shaped structure located below the neural tube of chordate embryos. Notochords are important in signaling neurulation and organogenesis in embryos. The notochord secretes both growth and inhibitory factors in the regulation of neurulation.",
        "suggested_answer": "Notochord",
        "options": [
            "Neural plate",
            "Notochord",
            "Ectoderm",
            "Neural crest cells"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 459,
        "question": "While in the lab, a research student was interested in how temperature changes the fluidity of the plasma membrane. At physiologic pH, which of the following change to fluidity would be expected if we added more cholesterol?",
        "answer": "Cholesterol is a major component of the plasma membrane fluidity. If we add more of this molecule to a cell membrane in normal conditions, we would hinder the movement of other lipids and essentially cause them to be more compressed. This compression would lead to a decrease in the fluidity of the membrane.",
        "suggested_answer": "Decreased fluidity",
        "options": [
            "Increased fluidity",
            "No change in fluidity",
            "Cholesterol is not found in the cell membrane",
            "Decreased fluidity",
            "Membrane would be destroyed"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 460,
        "question": "What strategies might a plant use to disperse its seeds?",
        "answer": "Seed dispersal is key to plant success and plants use a wide variety of strategies to facilitate this process. Plants use all the listed strategies in order to spread their seeds. They also make seeds with adhesive properties that stick to animals that pass by. The seeds are then brushed off some time later at a different location. ",
        "suggested_answer": "All of these",
        "options": [
            "The seeds may float and be carried by rain or streams",
            "All of these",
            "The seeds may be blown by the wind",
            "The seeds may survive animal digestion and be relocated when the animal defecates"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 461,
        "question": "Which of the following groups is contained in ATP?",
        "answer": "Adenosine triphosphate contains an adenine group, a ribose sugar, and three phosphates. ATP is known as the energy molecule since there is a lot of potential energy stored in the bonds between each of the three phosphate groups.",
        "suggested_answer": "Ribose",
        "options": [
            "Arginine",
            "Glucose",
            "Ribose",
            "Tyrosine",
            "Alanine"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 462,
        "question": "Prokaryotes reproduce via __________.",
        "answer": "Prokaryotes utilize binary fission for cellular division. Binary fission involves asexual reproduction of prokaryotes. First the cell grows and duplicates its genome, and any plasmids. The cell polarizes itself, then begins forming a cell wall to divide the cell into two slightly smaller cells. Eukaryotes use mitosis, and meiosis. Sexual reproduction occurs in some phyla of eukaryotes.",
        "suggested_answer": "binary fission",
        "options": [
            "sexual reproduction",
            "meiosis",
            "binary fission",
            "mitosis"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 463,
        "question": "Which organ stores the compound responsible for emulsifying lipids during digestion?",
        "answer": "Fats are generally composed of nonpolar lipids, making them hydrophobic and insoluble. In order to digest fats, they must be grouped together to increase their surface area and allow for enzyme interaction, digestion, and absorption.",
        "suggested_answer": "Gall bladder",
        "options": [
            "Pancreas",
            "Small intestine",
            "Liver",
            "Gall bladder"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 464,
        "question": "In the following scenarios, which organism is likely an ectotherm?",
        "answer": "An ectotherm relies on external sources to regulate its body temperature. The snake lies on the dark rock to absorb heat energy from the sun, to warm its body during the cool day. Animals that hibernate, such as bears, cannot be ectotherms. Hibernating animals downregulate their body\u2019s metabolism and thus body temperature; ectotherms cannot control their body temperature. Humans are endotherms, and like other endotherms, shiver when cold to increase their body temperature through expending energy (shivering thermogenesis). Dogs are endotherms, and pant when hot to cool their body temperature by expelling heat.",
        "suggested_answer": "A snake laying on a dark rock during a cool day ",
        "options": [
            "A bear hibernating during winter",
            "A dog panting when exposed to hot weather",
            "A human shivering when exposed to cold weather",
            "A snake laying on a dark rock during a cool day "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 465,
        "question": "What is the functional unit of a muscle cell?",
        "answer": "A muscle consists of millions of tiny subunits called sarcomeres. This is the functional unit of the muscle cell responsible for shortening and causing contractile force. Sarcomeres combine to form myofibrils, which form together to become a single muscle fiber, or muscle cell. Multiple muscle fibers form a muscle belly, the macrostructure of the muscle.",
        "suggested_answer": "Sarcomere",
        "options": [
            "Sarcomere",
            "Muscle belly",
            "Endomysium",
            "Sarcolemma",
            "Sarcoplasm"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 466,
        "question": "How many chromosomes does a human germ cell contain during metaphase I and telophase II, respectively?",
        "answer": "For this question you have to carefully track the chromosomes through meiosis. A human cell in metaphase I will have formed the tetrads and would have aligned the genetic material along the metaphase plate. The sister chromatids are still attached to one another, so they only count as one chromosome per pair of chromatids. There are a total of 46 chromosomes in metaphase I, each comprised of two sister chromatids. There are 23 homologous pairs, each containing two complete chromosomes.",
        "suggested_answer": "There are 46 chromosomes during metaphase I and 46 chromosomes during telophase II",
        "options": [
            "There are 46 chromosomes during metaphase I and 23 chromosomes during telophase II",
            "There are 23 chromosomes during metaphase I and 23 chromosomes during telophase II",
            "There are 23 chromosomes during metaphase I and 46 chromosomes during telophase II",
            "There are 46 chromosomes during metaphase I and 46 chromosomes during telophase II"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 467,
        "question": "What products of the light dependent reaction are necessary for the light independent reaction to occur?",
        "answer": " ATP and NADPH are produced during the light dependent reaction, and are reactants necessary for the light independent reaction (Calvin Cycle) to occur. During the light independent reaction, ATP and NADPH are used to convert phosphoglycerate molecules (PGA) to glyceraldehyde-3-phosphate molecules (G3P). G3P is then used to produce glucose.",
        "suggested_answer": "ATP and NAPH",
        "options": [
            "ADP and NADP+",
            "ATP and NADP+",
            "ATP and NAPH",
            "ADP and NADP+"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 468,
        "question": "Identify the molecule that has the chemical formula:",
        "answer": " is the molecular formula for glucose. Most carbohydrates such as glucose and sucrose have a molecular formula that is a multiple of .",
        "suggested_answer": "Glucose",
        "options": [
            "Fructose",
            "Glucose",
            "Sucrose",
            "Chitin "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 469,
        "question": "Which of the following sets of two hormones are found in combination birth control pills?",
        "answer": "Combination birth control pills contain estrogen and progesterone. Supplementing progesterone levels inhibit luteinizing hormone (LH) and follicle-stimulating hormone (FSH) secretion, follicle development, and ovulation. Estrogen supplementation decreases FSH, thereby inhibiting follicle development and ovulation. The combination pill decreases breakthrough bleeding but has more withdrawal symptoms.",
        "suggested_answer": "Progesterone and estrogen",
        "options": [
            "Gonadotropin-releasing hormone (GnRH) and estrogen",
            "Luteinizing hormone (LH) and progesterone",
            "Follicle-stimulating hormone (FSH) and gonadotropin-releasing hormone (GnRH)",
            "Progesterone and estrogen"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 470,
        "question": "Which of the following best explains the difference between infiltration and subsurface flow?",
        "answer": "Infiltration is a process in the water cycle in which water moves from the ground\u2019s surface into the soil. Subsurface flow is another process where water flows below the ground surface; therefore, the difference between the two is the origin of the flowing water.",
        "suggested_answer": "In infiltration, water moves into the soil and in subsurface flow water moves below the ground surface",
        "options": [
            "Both infiltration and subsurface flow take place on the ground surface",
            "In subsurface flow, water moves into the soil and in infiltration, water moves below the ground surface",
            "Infiltration takes place under the ground surface while subsurface flow takes place on the ground surface",
            "In infiltration, water moves into the soil and in subsurface flow water moves below the ground surface"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 471,
        "question": "Which of the following choices will most likely be found in the aminoacyl site of the large ribosomal unit?",
        "answer": "The large ribosomal subunit has three sites that interact with tRNA molecules the peptidyl P site, the aminoacyl A site, and the exit E site. The P-site holds the tRNA corresponding to the most recently added amino acid, which is attached to the growing polypeptide chain by a peptide bond. The A-site holds the tRNA with the next amino acid to be added to the chain. Finally, the E-site holds the free tRNA without an amino acid that was previously in the P-site.",
        "suggested_answer": "The tRNA molecule with the next amino acid to be added to the polypeptide chain",
        "options": [
            "The growing polypeptide chain",
            "The amino-acid free tRNA",
            "Initiation factors",
            "The tRNA molecule with the next amino acid to be added to the polypeptide chain"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 472,
        "question": "Which of the following is not an example of surgical menopause?",
        "answer": "Surgical menopause is a process in which a surgical procedure induces menopause. The procedures that do this are an oophorectomy (removal of the ovaries), hysterectomy (removal of the uterus), and salpingo-oophorectomy (removal of the fallopian tubes). These surgeries create a decrease in hormone levels that bring about menopausal symptoms.",
        "suggested_answer": "Removal of the breasts",
        "options": [
            "Removal of the uterus",
            "Removal of the breasts",
            "Removal of the ovaries",
            "Removal of the fallopian tube(s)"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 473,
        "question": "Which of the following will you not find inside the capsid of a virion?",
        "answer": "The capsid of a virion is the region that contains the viral DNA or RNA and the necessary enzymes that facilitate infection of a host cell. When a virion infects a cell, the contents of the capsid are injected into the host cell. This marks the onset of infection. A capsid contains nucleic acids (DNA or RNA) and amino acids (enzymes).",
        "suggested_answer": "III only",
        "options": [
            "II and III",
            "III only",
            "I and II",
            "II only"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 474,
        "question": "When would the creation of a Down Syndrome gamete occur?",
        "answer": "Down Syndrome results from trisomy 21, in which an individual has three copies of chromosome 21 in their genome. The cause for the extra chromosome is a nondisjunction event, resulting in an uneven splitting of the genome during meiosis.",
        "suggested_answer": "Anaphase I",
        "options": [
            "Telophase II",
            "Prophase I",
            "Anaphase I",
            "Metaphase II"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 475,
        "question": "Which phase of water in the most dense?",
        "answer": "Water, unlike many other compounds, has several special properties due to its hydrogen bonding between molecules. The hydrogen bonds are relatively strong, leading water to have very low vapor pressure and high surface tension. A side effect of the hydrogen bonding, however, is that when water crystallizes, the molecules will inevitably align so that the hydrogen bonds are maintained. The solid lattice structure of water molecules is, thus, not very tightly packed. The structure is ideal to optimize intermolecular forces, rather than space and volume. The density of the solid (ice) is thus less than the density of the liquid water.",
        "suggested_answer": "Liquid",
        "options": [
            "Solid",
            "Water has the same density in any of its phases",
            "Supercritical fluid",
            "Gas",
            "Liquid"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 476,
        "question": "Which process occurs in both cellular respiration and photosynthesis?",
        "answer": "In both cellular respiration and photosynthesis, chemiosmosis occurs. Chemiosmosis is the process in which the creation of a proton gradient leads to the transport of proton down its concentration gradient to produce ATP. This occurs in the electron transport chain in both mitochondrias and chloroplast. In the photosynthesis it occurs when the electron is transported from photosystem II to photosystem I. ",
        "suggested_answer": "chemiosmosis",
        "options": [
            "chemiosmosis",
            "glycolysis",
            "Oxidation of Glucose",
            "lactic acid fermentation",
            "Calvin Cycle"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 477,
        "question": "In regard to the theory of evolution, fitness most likely refers to which of the following?",
        "answer": "In evolutionary terms, fitness refers to the reproductive success or reproductive potential of an organism its contribution to its species' gene pool.",
        "suggested_answer": "Reproductive success",
        "options": [
            "Flexibility of an individual",
            "Physical abilities of an individual",
            "All of these",
            "Reproductive success"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 478,
        "question": "After passing through the bicuspid valve, blood enters which structure?",
        "answer": "Blood flows from the vena cavae into the right atrium, then into the right ventricle through the tricuspid valve. From here, blood is pumped into the pulmonary artery through the pulmonary semilunar valve. Oxygen-rich blood returns to the left atrium via the pulmonary veins. Blood flows from the right atrium into the right ventricle through the bicuspid valve. From the left ventricle, blood is pumped into the aorta, through the aortic semilunar valve.",
        "suggested_answer": "Left ventricle",
        "options": [
            "Right ventricle",
            "Left atrium",
            "Pulmonary artery",
            "Left ventricle",
            "Aorta"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 479,
        "question": "Which of the following could be a segment of DNA?",
        "answer": "DNA is a double stranded molecule that is antiparallel, meaning the 5' end of one strand matches up with the 3' end of the other strand. Nitrogenous bases hold the strands together via hydrogen bonding. These bases are adenine (A), thymine (T), guanine (G), and cytosine (C). Adenine (A) can only pair with thymine (T), and guanine (G) can only pair with cytosine (C).",
        "suggested_answer": "5'-CTAGAG-3'",
        "options": [
            "5'-CTAGAG-3'",
            "3'-GATCTC-5'",
            "5'-CTCGTG-3'",
            "3'-AATGTA-5'",
            "5'-AGATAG-3'",
            "3'-GATCTC-5'",
            "5'-CTCGTG-3'",
            "3'-GATCTC-5'",
            "5'-GTCGAT-3'",
            "3'-GATCTC-5'"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 480,
        "question": "A DNA template is composed of the following nucleotide sequence:",
        "answer": "When finding the mRNA transcript from a template, there are two things to keep in mind:",
        "suggested_answer": "5'-AAUGCGUA-3'",
        "options": [
            "5'-AAUGCGUA-3'",
            "5'-UACGCAUU-3'",
            "5'-AUGCGUAA-3'",
            "5'-AATGCGTA-3'",
            "5'-TTACGCAT-3'"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 481,
        "question": "Which is not a required input for the Calvin Cycle?",
        "answer": "The Calvin Cycle, or light independent reaction, does not utilize sunlight as an input. During the light independent reaction, or the Calvin Cycle, carbon fixation first occurs. CO2 produced during the light dependent reaction reacts with RuBP, ultimately producing PGA. This reaction is catalyzed by the enzyme Rubisco. Then, PGA is converted to G3P, using ATP and NADPH produced during the light dependent reaction to allow this reaction to occur. The G3P is ultimately converted to glucose. Every 3 cycles of the Calvin Cycle (light independent reaction), 6 molecules of G3P are produced; only 1 is used to produce glucose. The remaining 5 molecules of G3P are used to regenerate RuBP to allow the Calvin Cycle to continue.",
        "suggested_answer": "sunlight",
        "options": [
            "sunlight",
            "NADPH",
            "ATP",
            "CO2"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 482,
        "question": "In the process of denitrification, which of the following forms of nitrogen is returned to the atmosphere?",
        "answer": "Denitrification is the final process of the nitrogen cycle in which soil-dwelling denitrifying bacteria convert nitrates back into nitrogen gas. The nitrogen gas is released back into the atmosphere.",
        "suggested_answer": "Nitrogen gas",
        "options": [
            "Nitrogen gas",
            "Nitrite",
            "Nitrate",
            "Ammonium"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 483,
        "question": "What is the term for the structural arrangement of chromatids undergoing crossing over during meiosis?",
        "answer": "During prophase I of meiosis, homologous chromosomes exchange genetic material in a process called crossing over. Double stranded breaks and recombinase enzymes facilitate chromatid invasion and annealing. The tetrahedral structure formed through strand invasion between two chromosomes is called the Holliday junction.",
        "suggested_answer": "Holliday junction",
        "options": [
            "Telomere",
            "Kinetochore",
            "Holliday junction",
            "Equatorial plane"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 484,
        "question": "Photosynthesis is commonly divided into how many primary stages?",
        "answer": "The correct answer is two major stages. The first stage is known as the light reactions where solar energy is converted to chemical energy. The second stage is the Calvin cycle that synthesizes sugar.",
        "suggested_answer": "Two stages",
        "options": [
            "Three stages",
            "Four stages",
            "One stage",
            "Two stages"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 485,
        "question": "Which of the following is not true of human gametes?",
        "answer": "Gametes are haploid sex cells produced from germ line cells during mitosis. During human fertilization, male and female haploid gametes fuse to form a diploid zygote. ",
        "suggested_answer": "They arise from somatic cell lines",
        "options": [
            "Male and female gametes fuse during fertilization",
            "Gametes are produced during meiosis",
            "They are haploid",
            "They arise from somatic cell lines"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 486,
        "question": "After deoxygenated blood enters the heart at the right atrium, what path does it take?",
        "answer": "The circulatory system is composed of two primary regions: the systemic circuit and the pulmonary circuit.",
        "suggested_answer": "It follows the pulmonary circuit",
        "options": [
            "It follows the systemic circuit",
            "It follows the pulmonary circuit",
            "It exits the heart through the pulmonary veins, then returns through the pulmonary arteries",
            "It travels through the right side of the heart, flows to the left side of the heart, and then enters the lungs before returning to the body"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 487,
        "question": "As time progressed following the catastrophe, the genetic structure of the Freg populations began to differ from each other since they were no longer reproducing with each other. What is this process called?",
        "answer": "Allopatric speciation is the correct answer. Allopatric speciation is when one species results in two species as a result of environmental barriers. In this instance, the separation of the planet is a physical barrier that leads to the Freg becoming two different species.",
        "suggested_answer": "Allopatric speciation",
        "options": [
            "Allopatric speciation",
            "Evolutionary segregation",
            "None of these",
            "Genetic conversion",
            "Sympatric speciation"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 488,
        "question": "What is the correct name for ?",
        "answer": "The compound name is strontium chloride because this is an ionic compound with no transition metal. Remember: ionic compounds do not have prefixes (\"di,\" \"tri,\" or \"penta\"). When assigning oxidation numbers for the atoms of this molecule, we find that strontium has a charge of and chlorine has a charge of . Since this is a neutral molecule, two atoms of chlorine are needed to balance one atom of strontium. Since strontium is not a transition metal, it can only have a charge of , so there is no need to specify a charge in Roman numerals, which is the case for transition metal-containing compounds.",
        "suggested_answer": "Strontium chloride",
        "options": [
            "Distrontium chloride",
            "Strontium dichloride",
            "Strontium (II) chloride",
            "Strontium chloride",
            "Strontium (II) chloride (I)"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 489,
        "question": "Which of the following best describes the place where all secretions that form the ejaculate combine?",
        "answer": "Prior to ejaculation, secretions from the bulbourethral gland, prostate gland, epididymis, and seminal vesicles enter the ejaculatory duct. The ejaculatory duct is the place where semen mixes and is stored until ejaculation.",
        "suggested_answer": "Ejaculatory duct",
        "options": [
            "Epididymis",
            "Ejaculatory duct",
            "Urethra",
            "Glans penis "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 490,
        "question": "Which of the following stages of cellular respiration generates ATP, regardless of the presence of oxygen?",
        "answer": "Glycolysis is the process that converts glucose to pyruvate. It produces a total of four ATP, but consumes two ATP, for a net yield of two ATP. Glycolysis is not dependent on the presence of oxygen and can occur in either aerobic or anaerobic environments.",
        "suggested_answer": "Glycolysis",
        "options": [
            "Glycolysis",
            "Oxidative phosphorylation",
            "Electron transport chain",
            "Citric acid cycle",
            "Krebs cycle"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 491,
        "question": "Which of the following is a polymer composed of sugar monomers?",
        "answer": "Carbohydrates and proteins are polymers (a long chain molecule generally composed of repeating single units), but glucose and lipids are not polymers (though glucose has the ability to polymerize). Proteins are polymers of amino acids. Carbohydrates are polymers of sugar monomers or monosaccharides. Lactose is a disaccharide or two sugar monomers connected. ",
        "suggested_answer": "Carbohydrates",
        "options": [
            "Lactose",
            "Lipids",
            "Carbohydrates",
            "Glucose",
            "Proteins"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 492,
        "question": "How is metaphase of mitosis defined? ",
        "answer": "Metaphase is defined when chromosomes move towards the equator of the cell.",
        "suggested_answer": "Chromosomes move towards the center or equator of the cell. Chromatids have not separated.",
        "options": [
            "Centrioles begin to move towards opposite poles of the cell.",
            "Two distinct cells begin to form and separate from one another.",
            "DNA begins to replicate.",
            "Sister chromatids begin to separate and form independent chromosomes.",
            "Chromosomes move towards the center or equator of the cell. Chromatids have not separated."
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 493,
        "question": "Which of the following statements about kidney structure and function is true?",
        "answer": "Cells and large proteins are too large to cross the capillary membranes of the glomerulus, and cannot be filtered into Bowman's capsule. After this initial step of filtration, urine in the nephron is only affected by secretion and reabsorbtion of water, ions, and other small molecules. Finally, each kidney is composed of about one million nephrons.",
        "suggested_answer": "No filtration occurs after Bowman's capsule, only reabsorbtion and secretion",
        "options": [
            "No filtration occurs after Bowman's capsule, only reabsorbtion and secretion",
            "All of the answer choices are correct",
            "Each kidney is composed of approximately one thousand nephrons",
            "Cells and large proteins are filtered into urine from the glomerulus"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 494,
        "question": "What is the difference between chlorine-35 and chlorine-37?",
        "answer": "Chlorine-35 and chlorine-37 are isotopes of each other. Isotopes are atoms of the same element that have different masses due to different numbers of neutrons.",
        "suggested_answer": "Chlorine-37 has more neutrons",
        "options": [
            "Chlorine-37 has more electrons",
            "Chlorine-37 has more neutrons",
            "Chlorine-37 has a smaller atomic mass",
            "Chlorine-37 has more protons",
            "They are identical. They are just different different atoms in the same sample"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 495,
        "question": "Which of the following choices is not a true characteristic of the promoter sequence?",
        "answer": "Promoter sequences are regions of DNA located upstream of transcription start sites. Transcription factors bind to the promoter sequence, which promotes the binding of RNA polymerase and initiation of transcription. Together, along with activators and repressors, these make up the pre-initiation complex. A well-characterized promoter sequence is called the TATA box, which is present within promoters of of human genes.",
        "suggested_answer": "It is the start of transcription ",
        "options": [
            "A well-characterized example of a promoter is the TATA box",
            "It binds to transcription factors ",
            "It is the start of transcription ",
            "It is a part of the pre-initiation complex"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 496,
        "question": "Which of the following is a true characteristic of Hox genes?",
        "answer": "Hox genes are a class of genes that control body plan development during embryogenesis. The Hox gene DNA sequence is called a homeobox and codes for transcription factors.",
        "suggested_answer": "All of these",
        "options": [
            "Hox genes control body plan development during embryogenesis",
            "All of these",
            "They code for transcription factors",
            "They include a homeobox"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 497,
        "question": "Where does transcription take place in eukaryotic cells?",
        "answer": "Transcription is the process of synthesizing RNA from a DNA template. In eukaryotic cells, chromosomal DNA is contained within the nucleus. Transcription requires access to this DNA, and therefore must occur in the nucleus. Resulting RNA molecules are then shuttled out of the nucleus to be used in other processes.",
        "suggested_answer": "The nucleus",
        "options": [
            "The rough endoplasmic reticulum",
            "Ribosomes",
            "The cytoplasm",
            "The nucleus"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 498,
        "question": "What is a distinct feature of a C4 plant?",
        "answer": "Carbon fixation converts inorganic carbon dioxide into organic carbon compounds, such as glucose and cellulose. This is a characteristic function of both C3 and C4, and is a primary purpose of light independent reactions.",
        "suggested_answer": "Bundle-sheath cells",
        "options": [
            "Closed stomata during the day",
            "Light independent reactions",
            "Bundle-sheath cells",
            "Carbon fixation"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 499,
        "question": "Through which sphincter will a bolus pass to enter the stomach? Is this under control of the parasympathetic of sympathetic division of the autonomic nervous system?",
        "answer": "The contraction and relaxation of a layer of smooth muscle at the junction of the esophagus (-esophageal) and the stomach (gastro-) allows for the bolus to pass into the stomach upon relaxation, and by contraction keeps the bolus, acid, and other stomach contents from regurgitating into the esophagus from the stomach. This sphincter is under vagus nerve control, thus, the parasympathetic nervous system. ",
        "suggested_answer": "Gastroesophageal sphincter, which is under control of the parasympathetic nervous system",
        "options": [
            "Gastroesophageal sphincter, which is under control of the parasympathetic nervous system",
            "Urethral sphincter, which is under control of the sympathetic nervous system",
            "Pyloric sphincter, which is under control of the parasympathetic nervous system",
            "Gastroesophageal sphincter, which is under control of the sympathetic nervous system",
            "Pyloric sphincter, which is under control of the sympathetic nervous system "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 500,
        "question": "How many bonds can a carbon atom make?",
        "answer": "In its ground state carbon has four valence electrons, two its full s subshell and two in a partially filled p subshell. Normally, this would indicate that carbon forms two bonds, since only two of the electrons are in orbitals that are not already paired. Carbon, however, is able to form hybrid orbitals by combining the three p orbitals and one s orbital to form four identical sp3 orbitals, each containing one electron. This means that carbon can form four bonds, allowing it to achieve a stable octet.",
        "suggested_answer": "Four",
        "options": [
            "Five",
            "One",
            "Four",
            "Two ",
            "Three"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 501,
        "question": "Bone, blood, ligaments, and cartilage are classified as which type of tissue?",
        "answer": "Connective tissue binds, supports, and fills spaces in the body. It consists of cells surrounded by an extracellular matrix. The make-up of this matrix is essential to defining the properties of the given connective tissue type. The extracellular matrix of blood is aqueous and filled with platelets and protein factors, while the matrix of bone is composed of hydroxyapatite crystal.",
        "suggested_answer": "Connective tissue",
        "options": [
            "Nervous tissue",
            "Muscle tissue",
            "Connective tissue",
            "Embryonic tissue",
            "Epithelial tissue"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 502,
        "question": "There are __________ hydrogen bonds between the nucleotides adenine and thymine and __________ hydrogen bonds between the nucleotides cytosine and guanine. ",
        "answer": "Hydrogen bonds between nucleotides of different strands are crucial for the stability of DNA. Adenine and thymine pair up with two hydrogen bonds between the nucleotides. On the other hand, cytosine and guanine pair up with three hydrogen bonds between the nucleotides.",
        "suggested_answer": "two . . . three",
        "options": [
            "one . . . three",
            "two . . . three",
            "three . . . two",
            "two . . . two",
            "three . . . three"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 503,
        "question": "In the heart, blood is oxygen-rich in the __________, and oxygen-poor in the __________.",
        "answer": "Blood is oxygenated in the lungs and pumped through the pulmonary veins to the left atrium of the heart. The blood is then pumped to the left ventricle, which pumps the oxygen-rich blood throughout the body. The oxygen-poor blood returns to the heart by entering the right atrium, and remains oxygen poor as it is pumped into the right ventricle and through the pulmonary arteries until it is oxygenated again in the lungs.",
        "suggested_answer": "left atrium . . . right atrium and right ventricle",
        "options": [
            "right atrium . . . left ventricle",
            "left atrium . . . right atrium and right ventricle",
            "pulmonary arteries . . . pulmonary veins",
            "left ventricle . . . aorta",
            "superior vena cava . . . right ventricle "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 504,
        "question": "At which level of protein structure does the formation of disulfide bridges occur?",
        "answer": "The formation of disulfide bridge occurs in the tertiary and/or quaternary level of protein structure. This involves two sulfur atoms sharing a lone pair of electrons to form a covalent bond, which enhances the integrity of the protein's structure. The amino acid that is involved in forming disulfide bridges is cysteine.",
        "suggested_answer": "Two of these",
        "options": [
            "Two of these",
            "Tertiary structure",
            "Primary structure",
            "Secondary structure",
            "Quaternary structure"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 505,
        "question": "The function of an alveolus is most evident in the basic anatomy of which type of alveolar cell?",
        "answer": "The vast majority of the surface area of an alveolus is made up of type 1 alveolar cells, which are squamous (flat), thin epithelial cells that allow rapid gas exchange between the air inside the alveoli and blood in the surrounding capillaries. The healthy adult human has millions of alveoli in his/her lungs, providing a huge total surface area across which gas can diffuse, letting oxygen into the bloodstream and carbon dioxide out.",
        "suggested_answer": "The very thin type 1 alveolar cells",
        "options": [
            "The cuboidal or round type 2 alveolar cells",
            "The very thin type 1 alveolar cells",
            "The phagocytic alveolar macrophages",
            "Clara cells"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 506,
        "question": "What is the correct order of the spinal meninges starting from the outside going inwards towards the spinal cord (superficial to deep)?",
        "answer": "The correct order of the spinal meninges from superficial to deep (outside to inside) is dura mater, arachnoid mater, and pia mater. The dura mater is the most outer layer that is closely associated with the skill and vertebral column. Deep to the dura mater is the arachnoid mater, which contains the cerebrospinal found in the subarachnoid space. Underneath the subarachnoid space lies the pia mater, which is very closely associated with the brain and spinal cord. ",
        "suggested_answer": "Dura mater, arachnoid mater, pia mater",
        "options": [
            "Arachnoid mater, pia mater, dura mater",
            "Pia mater, arachnoid mater, dura mater",
            "Pia mater, dura mater, arachnoid mater",
            "Dura mater, arachnoid mater, pia mater",
            "Dura mater, pia mater, arachnoid mater"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 507,
        "question": "Which organ secretes proteolytic zymogens (inactive enzymes) that are present in small intestine?",
        "answer": "The pancreas is responsible for secreting proteolytic zymogens, such as trypsinogen, into the small intestine. This is triggered by the presence of undigested food (chyme) in the duodenum.",
        "suggested_answer": "The pancreas",
        "options": [
            "The small intestine",
            "The pancreas",
            "The large intestine",
            "The liver",
            "The stomach"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 508,
        "question": "Which portion of a neuron receives neurotransmitters from a previous neuron, signaling the need for propagation of action potential?",
        "answer": "Neurotransmitters from presynaptic cells are received by the dendrites of postsynaptic cells. There are myriad dendrites on each neuron that then propagate this signal to the soma (or cell body), allowing the signal to be passed down the axon to another neuron.",
        "suggested_answer": "Dendrites",
        "options": [
            "Dendrites",
            "Synaptic terminal",
            "Soma",
            "Axon"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 509,
        "question": "Which of the following are types of connective tissue?",
        "answer": "Tissues are groups of cells that can be grouped together and classified by their form and function. There are four major types of tissues: connective, epithelial, muscular, and nervous.",
        "suggested_answer": "Adipose, cartilage, bone, blood",
        "options": [
            "Adipose, neurons",
            "Adipose, cartilage, bone, blood",
            "Cartilage, bone, skin",
            "Bone, blood, skin",
            "Blood, neurons"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 510,
        "question": "Where is the metaphase plate located?",
        "answer": "Metaphase is the stage of mitosis in which sister chromatids line up at the metaphase plate and mitotic spindles interact with the kinetochores of the chromatids. The metaphase plate is the area where sister chromatids line up. This line is imaginary, but is equidistant from both cell poles. The contractile ring will begin forming at a similar location, but the chromosomes lining up at the metaphase plate and the formation of the contractile ring are events in mitosis that are temporally separate, and thus, never actually interact. ",
        "suggested_answer": "Equidistant from the two cell poles",
        "options": [
            "At the centrosomes",
            "Equidistant from the two cell poles",
            "On the contractile ring",
            "Where the nucleus once was "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 511,
        "question": "If a DNA sequence is mutated from AGCTAA to AGCAAA, what type of mutation has occurred?",
        "answer": "A substitution mutation occurs when a base pair is exchanged for a different base pair. In this case, a thymine nucleotide has been substituted for an adenine nucleotide.",
        "suggested_answer": "Substitution",
        "options": [
            "Substitution",
            "Insertion",
            "Frameshift",
            "Deletion"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 512,
        "question": "Which of the following best represents an example of sexual selection?",
        "answer": "Sexual selection is selection for traits that confer greater reproductive success. This includes selection for traits that better attract mates.",
        "suggested_answer": "Selection for brightly colored plumage to attract female birds",
        "options": [
            "Selection for brightly colored plumage to attract female birds",
            "Selection for antibiotic resistance in microorganisms",
            "Selection for traits that aid in predator avoidance",
            "Selection for mechanisms that prevent polyspermy"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 513,
        "question": "Which of the following terms is used to describe the different levels of a food chain?",
        "answer": "Trophic level is the term used to a specific point on the food chain. An organism\u2019s trophic level is the place they occupy in the food chain of a particular ecosystem.",
        "suggested_answer": "Trophic levels",
        "options": [
            "Biomass",
            "Biodiversity",
            "Community",
            "Trophic levels"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 514,
        "question": "Which of the following choices best characterizes the primary components of an amino acid?",
        "answer": "Amino acids are compounds that make up proteins and polypeptide chains. They are made up of an amine group , a carboxylic acid group , and a variable side chain. The amine group is called the N terminus and the carboxylic acid group is called the C terminus. The N terminus of one amino acid and the C terminus of another amino acid can form a peptide bond through a condensation reaction.",
        "suggested_answer": "Amine group, carboxylic acid group, and variable side chain ",
        "options": [
            "Carboxylic acid group, alcohol group, and citric acid group ",
            "Amine group, alcohol group, and variable side chain",
            "Amine group, carboxylic acid group, and variable side chain ",
            "Amine group, citric acid group, and variable side chain "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 515,
        "question": "What is the function of the molecules NADH and FADH2 during the electron transport chain (ETC)?",
        "answer": "NADH and FADH2 are electron carriers that have the important function of actually bringing electrons to the electron transport chain. Proteins embedded in the inner membrane of the mitochondria oxidize these molecules. The proteins then transfer the electrons through a series of processes in order to pump protons into the intermembrane space, creating an electrochemical gradient. The final protein in the chain passes the electron to an oxygen molecule to generate water, and the protons in the intermembrane space can then be used to drive the function of ATP synthase to create ATP/",
        "suggested_answer": "Donate electrons to electron transport proteins",
        "options": [
            "Directly synthesize ATP",
            "Accept electrons at the end of the electron transport chain",
            "Donate electrons to electron transport proteins",
            "They are products of glycolysis and the Krebs cycle and are not used by the electron transport chain"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 516,
        "question": "Which of the following best describes what happens to the acrosome during the acrosomal reaction?",
        "answer": "The acrosomal reaction in sperm cells is another process that aids in fusing the sperm and oocyte. After binding to glycoproteins in the zona pellucida, the acrosome cap of the sperm cell fuses with the sperm plasma membrane. This fusion causes the release of acrosomal contents, which include enzymes that allow the sperm cell to move further towards the oocyte.",
        "suggested_answer": "It fuses with the plasma membrane for release",
        "options": [
            "Its contents are ingested by the oocyte via phagocytosis",
            "It fuses with lysosomes for degradation",
            "It is digested by the sperm cell",
            "It fuses with the plasma membrane for release"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 517,
        "question": "The Golgi apparatus plays a role in which of the following cell signaling processes?",
        "answer": "The Golgi apparatus is an organelle that modifies and packages proteins for export. The Golgi receives proteins in vesicles from the endoplasmic reticulum on the cis face. Protein modification by enzymatic activity occurs within the Golgi. The modified proteins are then packaged into vesicles on the trans face, some of which are destined for extracellular transport via exocytosis. ",
        "suggested_answer": "Exocytosis",
        "options": [
            "Exocytosis",
            "Ion gated channels",
            "Apoptosis",
            "None of these"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 518,
        "question": "Which of these physiological changes occurs when the sympathetic nervous system is activated?",
        "answer": "The sympathetic nervous system is activated during times of stress, and is responsible for initiating the \"fight or flight\" response. Part of this response in an increase in heart rate, allowing better conduction of blood and delivery of oxygen throughout the body.",
        "suggested_answer": "Heart rate increases",
        "options": [
            "Reproductive processes are heightened",
            "Pupils constrict",
            "Heart rate increases",
            "Rate of digestion increases",
            "Glucose is converted to glycogen"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 519,
        "question": "Which of the following describes how parapatric speciation differs from peripatric speciation?",
        "answer": "In parapatric speciation, the two diverging species still have geographic contact but there is selection against genetic exchange due to a heterozygote disadvantage. Parapatric speciation differs from peripatric speciation because there is no contact between diverging populations in peripatric speciation.",
        "suggested_answer": "There can be contact between the two diverging populations in parapatric speciation",
        "options": [
            "Parapatric speciation is a subgroup of peripatric speciation",
            "Parapatric speciation and peripatric speciation do not differ",
            "There can be contact between the two diverging populations in parapatric speciation",
            "There is no contact between the two diverging populations in parapatric speciation"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 520,
        "question": "A sensory/receptor cell in the somatic nervous system would likely come from an __________, while a sensory/receptor cell in the autonomic nervous system would likely come from a(n) __________.",
        "answer": "The somatic nervous system is responsible for chiefly voluntary, conscious behavior, such as choosing a reaction to external stimuli sensed by one of the traditional five senses. The autonomic nervous system is responsible for involuntary bodily function, and can respond to sensations such as gas concentrations in the blood, changes in blood pressure, and slight deviations in body temperature, among many others. ",
        "suggested_answer": "external receptor such as an eye, ear, or nose . . . internal receptor such as a chemoreceptor, baroreceptor, or thermoreceptor",
        "options": [
            "internal receptor such as an eye, ear, or nose . . . external receptor such as a chemoreceptor, baroreceptor, or thermoreceptor",
            "external receptor such as an eye, ear, or nose . . . internal receptor such as a chemoreceptor, baroreceptor, or thermoreceptor",
            "eye . . . nose",
            "internal receptor such as a chemoreceptor, baroreceptor, or thermoreceptor . . . external receptor such as an eye, ear, or nose"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 521,
        "question": "A fatty acid that has two double bonds between its carbon atoms is called __________.",
        "answer": "An unsaturated fatty acid has one or more double bonds in its hydrocarbon tail. These double bonds are formed by the removal of hydrogen atoms from the carbon tail, resulting in a kink in the tail. As such, they are not fully saturated with hydrogen atoms. Cholesterol is a type of lipid that contains four hydrocarbon rings in its structure. Hydrogenated fatty acids are unnatural saturated fatty acids that are modified for their physical properties.  ",
        "suggested_answer": "an unsaturated fatty acid",
        "options": [
            "a hydrogenated fatty acid",
            "an unsaturated fatty acid",
            "a saturated fatty acid",
            "good cholesterol"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 522,
        "question": "Which is of the following is not an adaptation/modification that enabled plants to move from aquatic to terrestrial environments as they evolved?",
        "answer": "Thylakoid membranes are found within chloroplasts, which are used for photosynthesis. Plants found in both aquatic and terrestrial environments photosynthesize, so these membranes cannot be considered adaptations uniquely benefiting terrestrial plants.",
        "suggested_answer": "Thylakoid membranes",
        "options": [
            "Cutin",
            "Roots and root hairs",
            "Stomata",
            "Thylakoid membranes"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 523,
        "question": "What is the function of a kinase?",
        "answer": "The addition and removal of phosphate groups can serve critical functions in the regulation of protein activity. The binding or uncoupling of phosphate groups frequently serves to activate or deactivate proteins.",
        "suggested_answer": "Add phosphates to ligands",
        "options": [
            "Change the structure of the ligand",
            "Add phosphates to ligands",
            "Add ubiquitin to the ligand",
            "Remove phosphates from ligands"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 524,
        "question": "What is the purpose of tRNA?",
        "answer": "tRNA is a special type of RNA that has the function of forming bonds with amino acids and bringing them to ribosomes to complete translation. tRNA carries anticodons, allowing it to bind to mRNA in the active site of a ribosome. It can then transfer its amino acid residue to the ribosome, where it is incorporated into the growing polypeptide chain. The tRNA molecule is the released from the ribosome and recycled.",
        "suggested_answer": "To bring amino acids to ribosomes",
        "options": [
            "To aid in the transcription of genes",
            "To bring information from within the nucleus to the cytoplasm",
            "To form part of the ribosome",
            "To bring amino acids to ribosomes"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 525,
        "question": "A scientist is given two solutions, solution A and solution B. Solution A has a higher number of total solutes, but solution B has a greater variety solutes. In comparison to solution A, solution B is __________.",
        "answer": "Solution B is hypotonic to solution A. Solution B is hypotonic because it has an overall lower number of solutes in it even though it has more types of solutes in it. Diversity of the solutes does not not matter when comparing concentrations of solutions, only the overall number of solutes matters. Tonicity can be thought of as relative concentration. Remember that a solution cannot simply be hypertonic or hypotonic; its concentration must be compared to that of another solution.",
        "suggested_answer": "hypotonic",
        "options": [
            "hypotonic",
            "acidic",
            "hypertonic",
            "isotonic"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 526,
        "question": "__________ are surveillance mechanisms that halt the progress of the cell cycle if any of the various events involved not working correctly or are not happening in the correct order.",
        "answer": "Checkpoints are what monitor and control the process of the cell cycle and lower incidence of problems with cell growth and replication. The major three checkpoints in the cell cycle are the checkpoint before the S phase at the end of G1 phase to check for DNA damage. The second checkpoint is in the G2 phase before the M phase. The third major checkpoint in the cell cycle in at the end of M phase before anaphase occurs. Checkpoints are the points of control in the cell cycle regulation that are regulated by cyclin dependent kinases and cyclin levels; each checkpoint has its unique cyclins.",
        "suggested_answer": "Checkpoints",
        "options": [
            "Allosteric inhibitors",
            "Monitors",
            "Checkpoints",
            "Activation energies",
            "Chaperones"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 527,
        "question": "What type of differentiated plant cell is described?",
        "answer": "As described in the background to the question, sclerenchyma cells are specialized to support the plant as it grows. These cells have thick secondary walls that are further strengthened by the hardening agent called lignin. As a result, these cells are highly rigid and inflexible. ",
        "suggested_answer": "Sclerenchyma cells",
        "options": [
            "Collenchyma cells",
            "Secondary meristems",
            "Sclerenchyma cells",
            "Parenchyma cells"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 528,
        "question": "Which of the following base pairs is found only in RNA?",
        "answer": "Uracil is the base pair that is used in ribonucleic acid (RNA) in the place of thymine. The other three bases adenine, guanine and cytosine are found in both DNA and RNA.",
        "suggested_answer": "Uracil",
        "options": [
            "Adenine",
            "Cytosine",
            "Thymine",
            "Uracil",
            "Guanine"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 529,
        "question": "Gregor Mendel\u2019s major contribution to the study of inheritance was to show that __________.",
        "answer": "Gregor Mendel's famous work on pea plants built our first understandings of inheritance. He identified that \"discrete particles\", which we now call genes and alleles, are passed to offspring in numerous of combinations. These different combinations create variation in a population.",
        "suggested_answer": "individual particles in various combinations can produce traits",
        "options": [
            "individual particles in various combinations can produce traits",
            "a dominant allele is stronger than a recessive allele",
            "DNA exists",
            "dominant alleles appear more in male pea plants than in female pea plants"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 530,
        "question": "In glycolysis, what three-carbon compound is produced?",
        "answer": "Pyruvic acid is the three-carbon compound produced in glycolysis, the first stage in cellular respiration. Acetyl-CoA is a two-carbon molecule that is the result of the pyruvate dehydrogenase complex that follows glycolysis. Glucose is broken down in respiration, and ribose is a five-carbon sugar in RNA.",
        "suggested_answer": "Pyruvic acid",
        "options": [
            "Glucose",
            "Acetyl-CoA",
            "Ribose",
            "Pyruvic acid"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 531,
        "question": "Respiration and photosynthetic processes are ecologically linked to one another in which of the following processes?",
        "answer": "Both cellular respiration and photosynthesis are involved in the carbon cycle and the oxygen cycle. They both require carbon and oxygen in order to complete their mechanisms of action.",
        "suggested_answer": "Carbon cycle and oxygen cycle",
        "options": [
            "Water cycle",
            "Phosphorous cycle",
            "Nitrogen cycle",
            "Carbon cycle",
            "Carbon cycle and oxygen cycle"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 532,
        "question": "Gene flow occurs __________.",
        "answer": "Gene flow occurs when new individuals of the same species are added into a population. Think of it as new genes flowing into the existing gene pool.",
        "suggested_answer": "when new individuals are added to a population",
        "options": [
            "when new individuals are added to a population",
            "when natural forces act on existing variation",
            "when two populations mix, but do not mate",
            "when a population is split"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 533,
        "question": "Which of the following is the term used to describe reproduction in bacteria?",
        "answer": "Binary fission is the process by which bacteria use to reproduce. Transduction and conjugation and transformation are all processes by which bacteria exchange genetic material (usually in the form of plasmids). Reproduction occurs rapidly though binary fission. ",
        "suggested_answer": "Binary fission ",
        "options": [
            "Binary fission ",
            "Translation",
            "Transformation",
            "Conjugation",
            "Transduction"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 534,
        "question": "For each glucose molecule that undergoes glycolysis, how many acetyl CoA molecules are produced at the end of pyruvate decarboxylation?",
        "answer": "During glycolysis, for each molecule of glucose, two molecules of pyruvate are produced ( glucose+ NAD+ + 2 ADP + 2Pi-> 2 pyruvate+ 2 ATP + 2NADH+. These 2 molecules of pyruvate then undergo the pyruvate decarboxylation reaction: 2(pyruvate+ CoA-SH+ NAD+ -> NADH+ CO2+ acetyl CoA).",
        "suggested_answer": "2",
        "options": [
            "4",
            "1",
            "32",
            "2"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 535,
        "question": "Which of the following correctly pairs the signal to the type of nervous pathway?",
        "answer": "Efferent pathways carry signals away from the central nervous system. Essentially, they are signals that your brain sends to tell your body to do something, like blinking. Afferent signals come from outside stimuli and tell your brain what they are sensing, such as temperature. Afferent neurons bring stimuli to the brain, where the signal is integrated and processed. The brain then coordinates a response via efferent signals back to the rest of the body.",
        "suggested_answer": "Blinking follows an efferent pathway",
        "options": [
            "Breathing follows an afferent pathway",
            "Cold water on your hand follows an efferent pathway",
            "Blinking follows an efferent pathway",
            "Walking follows an afferent pathway",
            "A bug walking on your leg follows an efferent pathway"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 536,
        "question": "Which of the following is not a function of the liver?",
        "answer": "Methane is produced in the cecum as a result of blind outpocketing, during which E. coli organisms release the gas as a biproduct of digestion. Detoxing chemicals from the blood, forming urea, and producing bile are just three of the functions of the liver.",
        "suggested_answer": "Produces methane",
        "options": [
            "Forms urea",
            "Produces methane",
            "Produces bile",
            "Detoxes chemicals from the blood"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 537,
        "question": "Which of the following best characterizes the process of osmosis?",
        "answer": "Osmosis is defined as a form of passive transport since it does not require an expenditure of energy. Osmosis follows a concentration gradient while active transport acts against the concentration gradient. Also, active transport requires energy and passive transport does not.",
        "suggested_answer": "Passive transport",
        "options": [
            "Passive transport",
            "Semi-active transport",
            "Active transport",
            "ATP-driven transport"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 538,
        "question": "Given this reaction which of the following is correct? ",
        "answer": "During the redox reaction chlorine becomes the negatively charged chloride ion, so we know that it has gained an electron. This means that it underwent reduction and is an oxidizing agent. Sodium loses an electron since it becomes positively charge, thus we know it has been oxidized and is the reducing agent.",
        "suggested_answer": " is the reducing agent",
        "options": [
            " is undergoing reduction",
            " is undergoing oxidation",
            " is the reducing agent ",
            " is the reducing agent"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 539,
        "question": "Which of the following organs is Jim missing?",
        "answer": "The role of the gall bladder is to store bile salts, but it does not produce them. Rather, bile salts are produced by the liver and transported to the gall bladder for storage.",
        "suggested_answer": "Gall bladder",
        "options": [
            "Gall bladder",
            "Liver",
            "He is not missing any organs. Rather, he has an infected large intestine.",
            "Pancreas"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 540,
        "question": "Which scenario best describes genetic drift?",
        "answer": "Genetic drift is a change in allele frequencies in a population through random chance. It occurs over time and isn't a result of more fit organisms passing on their genes. In all but one of the answer choices there is some selective pressure that causes a change in the population that is not related to random chance. Thus, the example with the exact same selective pressures is the only scenario that could result in genetic drift. Predation can cause one allele to die out and another to prosper if one allele causes the organism to be better camouflaged. If individuals in a population have a preference for one type of allele then that allele will prosper. Sources of food can also cause an organism to change. For instance, if on one side of the lake the dominant food source is algae and on the other side smaller, quicker fish are the source of food, then over time the fish on either side of the hypothetical lake could diverge. One gaining a better ability to scoop algae and the other becoming very agile.",
        "suggested_answer": "Two populations of fish are separated by a strip of land that divides the lake into two bodies of water. For all practical purposes each side of the like is identical; the selective pressures are identical. Over time the populations become quite different.",
        "options": [
            "Two populations of fish are separated by a strip of land that divides the lake into two bodies of water. For all practical purposes each side of the like is identical except the females on one side only mate with males with a certain coloration. Over time the populations become quite different.",
            "None of these",
            "Two populations of fish are separated by a strip of land that divides the lake into two bodies of water. For all practical purposes each side of the like is identical; the selective pressures are identical. Over time the populations become quite different.",
            "Two populations of fish are separated by a strip of land that divides the lake into two bodies of water. For all practical purposes each side of the like is identical except the sources of food. Over time the populations become quite different.",
            "Two populations of fish are separated by a strip of land that divides the lake into two bodies of water. For all practical purposes each side of the like is identical except there are different predators on either side. Over time the populations become quite different."
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 541,
        "question": "Water often travels between cells and the environment via osmosis, where it diffuses from areas with low solute concentration (where more water is present) to areas with higher solute concentration (where there is relatively less water). Which answer choice is an example of osmosis?",
        "answer": "To select the correct answer, you must understand the difference between hypertonic, hypotonic, and isotonic. A hypertonic solution, such as ocean water with a high salt content, contains more solute than a normal cell. Water will flow out of the cell and into the environment in an attempt to equalize the amount of solute in the two \"compartments.\" An isotonic solution has the same relative amount of solute as the cell to which it is being compared, so no concentration gradient exists and no net diffusion will occur. Finally, a hypotonic solution contains less solute than the cell too which it is being compared.",
        "suggested_answer": "A normal cell is placed in pure water; water from the environment flows into the cell and causes it to swell",
        "options": [
            "A cell is placed in a hypertonic environment; water flows into the cell and causes it to swell",
            "A normal cell is placed in pure water; water from the environment flows into the cell and causes it to swell",
            "A normal cell is placed in pure water; water from the cell flows out into the surrounding liquid and causes the cell to shrink",
            "A cell is placed in an isotonic environment and immediately loses a great deal of its water content to its surroundings"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 542,
        "question": "Scientists and paleontologists use what method in order to determine the age of an organic object?",
        "answer": "The key to answering this question is organic material. Once an organism dies, it ceases to participate in carbon exchanges with the atmosphere. What carbon is left in the organism then decreases at a stable rate which allows scientists to reliable date the era that the organism died in.",
        "suggested_answer": "carbon dating",
        "options": [
            "relative dating",
            "index dating",
            "retroactive dating",
            "Cliff face dating",
            "carbon dating"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 543,
        "question": "Which of the following is most affected by natural selection?",
        "answer": "Influenza (the flu virus) is a real-life example of natural selection that is most evident during an annual time period when the influenza virus infection peaks. The repeated or cyclical nature of flu outbreaks is caused by the natural selection of the virus for traits that allow it to evade the immune system and replicate, a process that actually results in new distinct strains of influenza virus.",
        "suggested_answer": "Influenza",
        "options": [
            "Merlot grapes",
            "Influenza",
            "Angora rabbits",
            "Poodles"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 544,
        "question": "Which of the following is responsible for the production of germ cells?",
        "answer": "\"Germ cells\" is simply another term for gametes, and thus refers to either sperm or ovum.",
        "suggested_answer": "Ovaries",
        "options": [
            "Uterus",
            "Fallopian tubes",
            "Endometrium",
            "Ovaries"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 545,
        "question": "During embryogenesis, epithelial tissue originates from which of the following germ layers?",
        "answer": "During embryological development, epithelial tissue develops from all three germ layers (endoderm, mesoderm, and ectoderm).",
        "suggested_answer": "All of these ",
        "options": [
            "Ectoderm",
            "All of these ",
            "Endoderm",
            "Mesoderm"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 546,
        "question": "Which of the following definitions is correct about protein structure?",
        "answer": "There are four levels of protein structure. Primary structure involves the linear arrangement of amino acids. It is simply the linear sequence of amino acids created by the ribosome during translation. Secondary structure involves the hydrogen bonding of the backbone and can form alpha-helices and beta-pleated sheets. Tertiary structure involves the interaction between amino acid side chains, or R-groups. These interactions can be hydrogen bonds, hydrophobic interactions, or disulfide bridges. Quaternary structure involves the interaction between two or more folded subunits, and is not present in every protein structure.",
        "suggested_answer": "Secondary structure involves hydrogen bonding of the protein backbone. Secondary structure can form beta-pleated sheets and alpha-helices. ",
        "options": [
            "Primary structure involves the interaction between two or more folded subunits.",
            "Tertiary structure involves hydrogen bonding of the protein backbone. Tertiary structure can form beta-pleated sheets and alpha-helices. ",
            "Secondary structure involves the interaction between amino acid side chains.",
            "Secondary structure involves hydrogen bonding of the protein backbone. Secondary structure can form beta-pleated sheets and alpha-helices. ",
            "Quaternary structure involves the linear arrangement of amino acids."
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 547,
        "question": "Which enzyme has a proofreading ability during DNA replication?",
        "answer": "Proofreading is an important part of the DNA replication process to ensure that if mismatched base pairs are incorporated into the newly synthesized DNA strands, they get replaced with correct base paired nucleotides. Mismatched base pairs have the potential to cause disease. DNA polymerases have proofreading abilities. They are able to remove mismatched nucleotides from the end of a newly synthesized strand. Post-replication repair mechanisms also exist to prevent damage and error.",
        "suggested_answer": "DNA polymerase",
        "options": [
            "DNA gyrase",
            "DNA polymerase",
            "Primase",
            "DNA helicase"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 548,
        "question": "Some organisms, such as green plants, are capable of obtaining energy directly from photosynthesis. These organisms are termed __________.",
        "answer": "Autotrophs are organisms that produce complex organic compounds from substances present in the surroundings. These organisms may do this through the use of energy from light or from inorganic chemical reactions.",
        "suggested_answer": "autotrophs",
        "options": [
            "homotrophs",
            "heterotrophs",
            "primary consumers",
            "herbivores",
            "autotrophs"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 549,
        "question": "Which of the following are made of protein? ",
        "answer": "Steroids fall into the lipid category, characterized by a carbon skeleton composed of four fused rings Cholesterol is a type of steroid; it is synthesized in the liver, and is necessary for the production of sex hormones. Triacylglycerol is also a type of lipid, composed of three fatty acid molecules and a glycerol (also known as a triglyceride). Starch is a polymer of glucose monomers. Its primary function is to store energy. A protein is a molecule that is composed of polypeptides, folded into a 3D structure. Each protein is composed of a combination of amino acids. Proteins make up over 50% of dry mass of a cell and have many different functions like speeding up chemical reactions, defense, storage, transport, cellular communication, movement, and structural support. Enzymes are types of proteins that speed up chemical reactions, and are never consumed during reactions.",
        "suggested_answer": "Enzymes",
        "options": [
            "Steroids",
            "Enzymes",
            "Cholesterol ",
            "Starch",
            "Triacylglycerols"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 550,
        "question": "Ammonia is a toxic form of excreted nitrogenous waste. What would be a key reason that an organism would preferentially excrete ammonia instead of urea or uric acid?",
        "answer": "There is a key trade-off between energy consumption and toxicity in the excretion of nitrogenous wastes. Ammonia is the simplest form of the waste product, and requires very little energy to produce; however, it is highly toxic and must be diluted to extremely low concentrations in order to be safe to the cells. Many aquatic animals excrete ammonia because of their proximity to water. Access to large amounts of water means that these organisms can safely excrete dilute ammonia without needing to use energy in conversions.",
        "suggested_answer": "Energy is required to convert ammonia to a less toxic form",
        "options": [
            "Less water is lost when ammonia is excreted",
            "Ammonia is selectively reabsorbed better than urea or uric acid during excretion",
            "Ammonia excretion benefits its environment and habitat",
            "Energy is required to convert ammonia to a less toxic form"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 551,
        "question": "Choose the correct name for the following compound.",
        "answer": " The molecule is a molecular compound because it contains two non-metals; therefore, it contains no ionic bonds. We use prefixes to describe the subscript for each element in molecular compounds. The molecule in this question has a subscript of two for each of the elements, so the prefix di- should be added to both sulfur and chlorine.",
        "suggested_answer": "Disulfur dichloride ",
        "options": [
            "Disulfur dichloride ",
            "Sulfur chlorate",
            "Sulfur chloride",
            "Diasulfur diachloride",
            "Sulfur dichloride "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 552,
        "question": "Which product, secreted by the kidney, helps regulate blood pressure?",
        "answer": "The kidneys are able to help regulate blood pressure by releasing the hormone renin. Renin initiates a cascade that ends with the adrenal cortex releasing aldosterone. Aldosterone increases the reabsorption of sodium ions in the distal convoluted tubule and collecting duct. The increase in surrounding ion concentration resulting from the reabsorption pulls water out of the nephron via osmosis, concentrating the urine and promoting water retention.",
        "suggested_answer": "Renin",
        "options": [
            "Antidiuretic hormone",
            "Cortisol",
            "Aldosterone",
            "Renin"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 553,
        "question": "Which of these does not give rise to the versatility of protein functions found in nature?",
        "answer": "All of these affect protein function and give rise to the many functions of proteins. The order in which the individual residues (amino acids) are bonded contributes to the overall shape of a protein due to interactions between each amino acid side chain. This order matters so that the proper side chains can interact. The secondary structure of a protein consists of alpha helices and beta pleated sheets. Both of which play widely different roles structurally in cells. The quaternary structure categorizes interactions between different subunits of protein. Several subunits come together to perform a function they otherwise could not.",
        "suggested_answer": "None of these",
        "options": [
            "Quaternary structure",
            "Properties of side chains of each amino acid",
            "None of these",
            "Secondary structure",
            "Primary structure"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 554,
        "question": "Skeletal muscle tissue cannot perform which of the following types of muscle contractions?",
        "answer": "Skeletal muscle tissue is involved in voluntary skeletal movement. Skeletal muscle can be divided into type I, type IIa, type IIx, and type IIb categories. Type I tissues exhibit slow contractions and type II are involved in fast contractions.",
        "suggested_answer": "Involuntary",
        "options": [
            "Involuntary",
            "Voluntary",
            "Fast contraction",
            "Slow contraction"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 555,
        "question": "Bacterial plasmids can __________.",
        "answer": "Plasmids are small pieces of DNA that are not part of a bacteria's genome. The genes contained on plasmids are not necessary for proper function of the bacteria. However, bacterial plasmids can carry genes to confer antibiotic resistance, and commonly do. Plasmids can be transferred between bacteria via conjugation, and can be integrated into their genomic DNA.. Plasmids are usually present in more than one copy per cell.",
        "suggested_answer": "All of these",
        "options": [
            "integrate into the bacterial genome",
            "All of these",
            "be present in multiple copies",
            "transfer between bacterial cells",
            "carry antibiotic resistance genes"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 556,
        "question": "Which of the following are true regarding primers used in DNA replication?",
        "answer": "In the process of DNA replication, DNA polymerase can only add nucleotides to an already existing DNA strand. To overcome this difficulty in synthesizing new DNA strands, the cell utilizes RNA primers. RNA primers are short strands of RNA synthesized by the enzyme primase. Primase is a type of RNA polymerase that does not need a free hydroxyl as a substrate, like DNA polymerase. Thus, it can lay down RNA nucleotides using the DNA parent strands as templates to provide DNA polymerase with the substrate it needs to begin elongation. RNA primers are also used in lagging strand synthesis, since DNA polymerase can only add nucleotides to the end of a nucleotide. Thus all nucleic acid polymerization occurs in the direction. DNA polymerase I removes the RNA primers, replacing them with DNA, then DNA ligase seals the gaps between DNA on the lagging strand.",
        "suggested_answer": "RNA primers allow DNA polymerase to add nucleotides to the hydroxyl end ",
        "options": [
            "RNA polymerase adds RNA primers to the new strands",
            "Each newly synthesized strand receives several primers",
            "The primers are DNA primers ",
            "RNA primers allow DNA polymerase to add nucleotides to the hydroxyl end "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 557,
        "question": "Which of the following best describes the effect of increased progesterone levels from the use of progesterone birth control pills?",
        "answer": "Progesterone birth control pills function to increase progesterone levels in the individual. This inhibits the secretion of gonadotropin-releasing hormone (GnRH), which, in turn, inhibits luteinizing hormone (LH) and follicle-stimulating hormone (FSH) secretion. The drops in LH and FSH levels halt follicle development, thereby preventing ovulation.",
        "suggested_answer": "All of these",
        "options": [
            "Inhibits follicle development",
            "Inhibits gonadotropin-releasing hormone (GnRH)",
            "Inhibits follicle-stimulating hormone (FSH)",
            "All of these",
            "Prevents ovulation"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 558,
        "question": "Which of the following best represents a component of biodiversity?",
        "answer": "Biodiversity refers to the different types of life present on Earth. This can refer to the variety of species in an ecosystem, the genetic variation across the planet, and ecosystem variation.",
        "suggested_answer": "All of these",
        "options": [
            "Variety of organisms in an ecosystem",
            "All of these",
            "Ecosystem variation",
            "Genetic variation across the planet"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 559,
        "question": "What is the fluid in the anterior chamber of the eye called?",
        "answer": "The eye is divided into two chambers by the lens: the anterior and posterior chambers. The anterior chamber is filled with aqueous humor, while the posterior chamber is filled with vitreous humor. Aqueous humor nourishes the eye and maintains intraocular pressure. Aqueous humor is also important in the refraction of light, which allows humans to distinguish clear images and colors.",
        "suggested_answer": "Aqueous humor",
        "options": [
            "Vitreous humor",
            "All of these refer to the same structure",
            "Hippocratic humor",
            "Aqueous humor",
            "Galen humor"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 560,
        "question": "Which of the following impedes the precipitation stage of the water cycle?",
        "answer": "In the precipitation stage of the water cycle, condensed water vapor falls to the Earth\u2019s surface; however, a tree canopy, for instance, may block this precipitation, preventing the precipitation from reaching the Earth\u2019s surface. The intercepted precipitation then evaporates back into the Earth\u2019s atmosphere.",
        "suggested_answer": "Interception",
        "options": [
            "Interception",
            "Runoff",
            "Snowmelt",
            "Infiltration"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 561,
        "question": "An action potential is fired down the membrane of a neuron. At one specific region, the voltage across the membrane peaks at . What is the state of the sodium and potassium voltage-gated channels in the axon immediately after this region of charge passes?",
        "answer": "The period of time immediately after an action potential passes will be characterized by an immediate reduction in membrane potential, followed by hyperpolarization. At this point in time, the sodium voltage-gated channels are inactivated, halting the overshoot and influx of sodium ions into the cell. These channels remain inactivated, in order to avoid having the voltage-gated sodium channels open again to trigger another action potential. This is considered the absolute refractory period. The voltage-gated potassium channels are slower to open than the voltage-gated sodium channels. By the time the sodium overshoot has peaked, the voltage-gated potassium channels are open, allowing an efflux of potassium out of the cell. The efflux is responsible for lowering the membrane potential and eventually causing hyperpolarization.",
        "suggested_answer": "Voltage-gated sodium channels are inactivated and voltage-gated potassium channels are open",
        "options": [
            "Voltage-gated sodium channels are close and voltage-gated potassium channels are inactivated",
            "Voltage-gated sodium channels are inactivated and voltage-gated potassium channels are closed",
            "Voltage-gated sodium channels are open and voltage-gated potassium channels are open",
            "Voltage-gated sodium channels are inactivated and voltage-gated potassium channels are open",
            "Voltage-gated sodium channels are closed and voltage-gated potassium channels are closed"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 562,
        "question": "During depolarization the sodium channels move sodium ions __________ the cell and cause the membrane potential to __________.",
        "answer": "Depolarization is the first step in an action potential. In this step the voltage-gated sodium channels open due to an external stimulus. These channels permit rapid flow of sodium ions into the cell. This changes the polarity of the cell and causes the membrane potential to increase. The increase in membrane potential is attributed to the increased amounts of positive ions inside the cell. Recall that the membrane potential is defined as the potential inside the cell minus the potential outside the cell. An increase in positive ions inside the cell will increase the potential inside the cell and, subsequently, increase the membrane potential.",
        "suggested_answer": "into . . . increase",
        "options": [
            "into . . . increase",
            "out of . . . increase",
            "into . . . decrease",
            "out of . . . decrease"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 563,
        "question": "Which of the following is true regarding enzymes?",
        "answer": "An enzyme lowers the energy of the transition state, which makes the chemical reaction proceed faster. Enzymes speed up many chemical reactions in processes like DNA synthesis and glycolysis. They are also proteins, so they're composed of amino acids.",
        "suggested_answer": "Enzymes decrease the activation energy of a reaction by lowering the energy of the transition state",
        "options": [
            "Enzymes are not involved in DNA synthesis",
            "All enzymes are made up of lipids",
            "Enzymes decrease the activation energy of a reaction by lowering the energy of the transition state",
            "Enzymes increase the activation energy of a reaction by lowering the energy of the transition state",
            "Enzymes cause chemical reactions to slow down using cofactors"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 564,
        "question": "Which of the following best describes what happens to the corpus luteum when the oocyte is fertilized?",
        "answer": "After the oocyte is fertilized, the outer layers of the blastocyst secrete human chorionic gonadotropin (hCG). The hCG stimulates the corpus luteum to keep secreting progesterone in order to maintain a thick endometrial lining to support the developing embryo.",
        "suggested_answer": "It is maintained by human chorionic gonadotropin (hCG)",
        "options": [
            "It is maintained by human chorionic gonadotropin (hCG)",
            "It travels down the fallopian tube",
            "It degrades into the corpus albicans",
            "It is maintained by estrogen "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 565,
        "question": "What is the correct order of the spinal meninges from superficial to deep?",
        "answer": "The correct answer is dura mater, arachnoid and lastly the pia mater. The meninges surround the brain and spinal cord in the central nervous system and are made up of three layers. The outermost layer is the dura mater, beneath it lies the arachnoid and below that is the pia mater.",
        "suggested_answer": "Dura mater, arachnoid mater, pia mater",
        "options": [
            "Arachnoid mater, dura mater, pia mater",
            "Dura mater, arachnoid mater, pia mater",
            "Pia mater, dura mater, arachnoid mater",
            "Dura mater, pia mater, arachnoid mater",
            "Arachnoid mater, pia mater, dura mater"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 566,
        "question": "Which of the following is the basis for independent assortment of homologous chromosomes?",
        "answer": "Independent assortment depends upon random alignment of homologous chromosomes in metaphase I which will result in randomly assorted chromosomes into two daughter cells at the end of meiosis I. Crossover, or recombination, occurs in prophase I which contributes to genetic diversity, but does not affect random alignment of homologous chromosomes. Spindles align chromosomes randomly in metaphase I, not anaphase I during which they are already \"aligned\" and are moving towards opposite poles of the cell.",
        "suggested_answer": "Homologous chromosomes line up randomly at the metaphase plate in metaphase I ",
        "options": [
            "Recombination occurs in telophase I ",
            "Chromosomes are paired into tetrads in prophase I ",
            "Spindles align chromosomes randomly in anaphase I ",
            "Homologous chromosomes line up randomly at the metaphase plate in metaphase I ",
            "Homologous chromosomes undergo crossover in prophase I "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 567,
        "question": "The __________ generation is dominant in the bryophyte life cycles, and the __________ generation is dominant in seedless vascular plants.",
        "answer": "Bryophytes are nonvascular plants, such as mosses. Gametophytes are species that have haploid cells during their mature lives, while sporophytes are predominantly diploid during their adult phases.",
        "suggested_answer": "gametophyte . . . sporophyte",
        "options": [
            "gametophyte . . . sporophyte",
            "sporophyte . . . sporophyte",
            "gametophyte . . . gametophyte",
            "sporophyte . . . gametophyte"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 568,
        "question": "Squid and octapi have evolved eyes similar to those that humans have. Unlike humans, however, they do not have an optical blind spot, leading many to use the squid eye as evidence that the human eye is suboptimal. The human eye is fundamentally different in structure from that of a squid, however they serve the same purpose. This is an example of __________.",
        "answer": "The answer is homoplasy, which is another term for convergent evolution. Humans and squid evolved the eye independently, which explains the differences in structure despite similar function.",
        "suggested_answer": "homoplasy",
        "options": [
            "synapomorphy",
            "homoplasy",
            "autapamorphy",
            "apomorphy"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 569,
        "question": "Which of the following are located in the cortex of the kidney?",
        "answer": "The glomerulus, proximal tubule, distal tubule are all located in the cortex (outer portion) of the kidney, where the osmolarity of the interstitial fluid is relatively low. Both limbs of the loop of Henle and the collecting duct are located in the medulla (central portion) of the kidney, where the osmolarity of the interstitial fluid is much greater.",
        "suggested_answer": "Glomerulus, proximal tubule, and distal tubule",
        "options": [
            "Glomerulus and proximal tubule",
            "Glomerulus, proximal tubule, and distal tubule",
            "Ascending limb, descending limb, and collecting duct",
            "Loop of Henle"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 570,
        "question": "Which of the following is not an indicator of pregnancy?",
        "answer": "Common symptoms and indicators of pregnancy include swelling, increased urination, and back pain. Vaginal tearing is not an indicator or symptom of pregnancy, instead it is a possible occurrence during childbirth when the child exits the vagina.",
        "suggested_answer": "Vaginal tearing",
        "options": [
            "Back pain",
            "Vaginal tearing",
            "Swelling",
            "Increased urination"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 571,
        "question": "In a redox reaction, the addition of an electron to a substance is called __________.",
        "answer": "A redox or oxidation-reduction reaction involves the transfer of electrons from one substance to another. The loss of an electron from a substance is called oxidation. The gain of electrons to another substance is called reduction. A mnemonic that is often useful in remembering this is: OIL RIG, where Oxidation Is Loss of electrons and Reduction Is Gain of electrons.",
        "suggested_answer": "reduction",
        "options": [
            "reduction",
            "electron compensation",
            "oxidation",
            "electron transfer"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 572,
        "question": "What is the purpose of the G1 phase of the cell cycle?",
        "answer": "The G1 phase of the cell cycle serves the important function of giving the cell time to grow and replicate organelles. Formation of the mitotic spindle does not occur until prophase of mitosis, and DNA synthesis takes place during the S phase of the cell cycle. The G1 phase ensures that both daughter cells have adequate cellular machinery and organelles to survive after mitosis.",
        "suggested_answer": "Growth and replication of the organelles",
        "options": [
            "DNA Synthesis",
            "No purpose has yet been characterized",
            "Growth and replication of the organelles",
            "Formation of the mitotic spindle"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 573,
        "question": "Which of the following is not a part of the pathway of food through the digestive tract?",
        "answer": "Food travels in this order through the digestive tract: mouth, pharynx, esophagus, stomach, small intestine, large intestine, rectum, anus.",
        "suggested_answer": "Larynx",
        "options": [
            "Mouth",
            "Stomach",
            "Pharynx",
            "Esophagus",
            "Larynx"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 574,
        "question": "In the DNA strand, with which nitrogenous base does guanine pair?",
        "answer": "In the DNA strand, guanine bonds to cytosine. It does not bond to the other nitrogenous bases. Guanine and cytosine form three hydrogen bonds to keep complementary strands of DNA together.",
        "suggested_answer": "Cytosine",
        "options": [
            "Uracil",
            "Adenine",
            "Thymine",
            "Cytosine"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 575,
        "question": "Which of the following principles is credited to Mendel?",
        "answer": "Mendel's work focused heavily on identifying mechanisms and patterns in genetic inheritance. He is credited with three essential laws: the law of segregation, the law of independent assortment, and the law of dominance.",
        "suggested_answer": "Law of Segregation",
        "options": [
            "Law of Diploidy",
            "Law of Genomic Variability",
            "Law of Natural Selection",
            "Law of Segregation"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 576,
        "question": "Which of the following is true in regard to cooperation?",
        "answer": "Cooperation is an outcome of evolution that features beneficial interactions between species, leading to the species evolving together. Cooperation is thought to have evolved from kin selection where organisms work together to raise another\u2019s kin for the benefit of the population.",
        "suggested_answer": "Cooperation involves beneficial interactions between species",
        "options": [
            "Kin selection directly opposes cooperation",
            "It involves only conflict between species",
            "One species evolves independently ",
            "Cooperation involves beneficial interactions between species"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 577,
        "question": "If a phospholipid's phosphate group is replaced with another fatty acid chain, the result is a __________.",
        "answer": "A phospholipid has two fatty acid chains and a phosphate group attached to glycerol. By replacing the phosphate group of a phospholipid with another fatty acid, the result is a glycerol with three fatty acid chains which makes it a fat (triglyceride). Saturated fatty acids are single chains of fatty acids, the resulting molecule has three fatty acids, which each could be saturated or unsaturated. Steroids have structures that consist of four fused carbon rings. ",
        "suggested_answer": "fat (triglyceride)",
        "options": [
            "wax",
            "saturated fatty acid",
            "fat (triglyceride)",
            "steroid"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 578,
        "question": "Bacteria normally reproduce asexually by binary fission. However bacteria can directly transfer DNA between each other in a form of sexual reproduction. What is this process called?",
        "answer": "There are three processes in which bacteria can acquire new novel genes/alleles for genes. Transformation happens when bacteria uptake naked DNA from the environment and assemble it into their genome. Transduction happens when a phage transfer DNA from one bacteria to another. Transduction is the direct transfer of DNA from a donor bacteria to a recipient bacteria and is often referred to as \"bacterial sex.\"",
        "suggested_answer": "Conjugation",
        "options": [
            "Crossing over",
            "Transformation",
            "Conjugation",
            "Meiosis",
            "Transduction"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 579,
        "question": "Which of the following can be best described as a method of pollination?",
        "answer": "Pollination is defined as the transfer of pollen from the male anther to the female stigma of an angiosperm. There are abiotic and biotic methods of pollinations. Abiotic pollination includes wind and water while in biotic pollination another organism facilitates pollination.",
        "suggested_answer": "All of these",
        "options": [
            "Water",
            "Wind",
            "All of these",
            "Another organism"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 580,
        "question": "Why is the significance of lactic acid fermentation in anaerobic respiration? ",
        "answer": "Since there is no more oxygen, we undergo anaerobic respiration, which in humans is lactic acid fermentation. Lactic acid does not produce any energy, so it doesn't solve the ATP crisis nor does it produce ATP. It is true that lactic acid fermentation does use up pyruvate, but so does the citric acid cycle. The importance is that lactic acid fermentation turns NADH into NAD+ so that it can be recycled into glycolysis to create ATP. ",
        "suggested_answer": "Lactic acid fermentation replenishes NAD+ so that glycolysis can continue.",
        "options": [
            "Lactic acid fermentation uses up pyruvate.",
            "Since oxygen is no longer present to accept the electrons in the ETC, it provides an alternative path to make ATP. ",
            "Lactic acid fermentation creates ATP.",
            "Lactic acid fermentation gives a short term solution to the ATP crisis. ",
            "Lactic acid fermentation replenishes NAD+ so that glycolysis can continue."
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 581,
        "question": "Which of the following is not a geographic barrier to speciation?",
        "answer": "Plant polyploidy the presence of more than one genotype is not a geographic barrier. Plant polyploidy helps enable speciation.",
        "suggested_answer": "Plant polyploidy",
        "options": [
            "Oceans",
            "Canyons caused by erosion",
            "Mountain ranges",
            "Plant polyploidy",
            "Newly formed streams"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 582,
        "question": "Which of the following is not a basic biological building block?",
        "answer": "Enzymes are proteins, which are composed of amino acids. Biomolecules are made of polymers. The monomers in nucleic acids are nucleotides. The monomers in carbohydrates are monosaccharides. Lipids are biomolecules, but their structure does not follow as clear of a pattern as the other three. Depending on the textbook, we may consider fatty acids, or hydrocarbons as \"monomers\" of lipids. There is some ambiguity with lipids because their structures are diverse and are not always chains of repeating subunits.",
        "suggested_answer": "Enzyme",
        "options": [
            "Fatty acid",
            "Amino acid",
            "Monosaccharide",
            "Enzyme",
            "Nucleotide"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 583,
        "question": "Which of the following nitrogenous bases is used to produce RNA, but not DNA?",
        "answer": "Uracil is bound to adenine in the production of RNA, while thymine is used in its place in the production of DNA. Adenine, guanine, and cytosine are all used in the production of both RNA and DNA.",
        "suggested_answer": "Uracil",
        "options": [
            "Adenine",
            "Uracil",
            "Thymine",
            "Guanine"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 584,
        "question": "An animal that eats plants and seeds is a __________.           ",
        "answer": "Herbivores are plant-eating organisms. They are classified in the primary consumer trophic level. An easy way to remember this is to break down the word. Herbs are types of plants, so when we see herb in herbivore think of plants. Carnivores eat other animals. Autotrophs and producers need not eat anything to get energy, rather, they make their own.",
        "suggested_answer": "herbivore ",
        "options": [
            "producer ",
            "herbivore ",
            "autotroph",
            "carnivore "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 585,
        "question": "During what stage of embryonic development do cells differentiate into primary germ layers?",
        "answer": "Germ layers are primary layers of cells that form during embryogenesis. In mammals, they are the endoderm, ectoderm, and mesoderm. In embryo development, the germ layers differentiate during gastrulation.",
        "suggested_answer": "Gastrulation",
        "options": [
            "Implantation",
            "Gastrulation",
            "Blastula development",
            "Fertilization"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 586,
        "question": "When the concentration of solutes differs on the two sides of a membrane permeable only to water, what will occur?",
        "answer": "When there is a concentration gradient, water will move across a membrane unassisted by ATP or channel proteins. In contrast, solutes (the dissolved substances) cannot cross the membrane unassisted.",
        "suggested_answer": "Water will move across the membrane by osmosis.",
        "options": [
            "The solutes will move across the membrane from a region of higher concentration to the region of lower concentration.",
            "Water will move across the membrane by plasmolysis.",
            "Water will move across the membrane by active transport.",
            "Water will move across the membrane by facilitated diffusion.",
            "Water will move across the membrane by osmosis."
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 587,
        "question": "Patient A has a disorder which resulted in a nondisjunction of chromosome 6. Which might this disorder lower the chances of developing an autoimmune disease? ",
        "answer": "Since the genes in chromosome 6 is codominance, each gene will be expressed. With nondisjunction, there will be an extra chromosome. This will result in all three genes being expressed. The expression of all three genes will increase the diversity of the MHC I pool. Recall MHC I is responsible for notifying which cells are related to the body to prevent autoimmunity. ",
        "suggested_answer": "Increase variability of MHC I binding site ",
        "options": [
            "Increase variability of MHC I binding site ",
            "Decrease variability of MHC II binding site ",
            "Increase variability of MHC II binding site ",
            "Decrease variability of MHC I binding site ",
            "None of these"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 588,
        "question": "If a cell is dropped into a solution that is hypertonic to the cell, what happens?",
        "answer": "A hypertonic solution has a higher concentration of solute than the solute in the cell. Water diffuses to the higher concentration of solute, so water diffuses out of the cell, and the cell shrivels up. One way to think of this is that water follows \"salt,\" as salt is the most common biological solute.",
        "suggested_answer": "Water diffuses out of the cell.",
        "options": [
            "Water diffuses out of the cell.",
            "The cell has an equilibrium of water in and water out.",
            "Water diffuses into the cell.",
            "No change in diffusion occurs."
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 589,
        "question": "The total sum of an organism's chemical processes is called __________.",
        "answer": "An organism's metabolism is the sum of its chemical processes. Catabolism and anabolism are different aspects of metabolism. Catabolism refers to the breakdown of organic molecules, producing useable forms of energy, such as ATP. Anabolism refers to the use of energy to build macromolecules such as proteins, nucleic acids and carbohydrates. ",
        "suggested_answer": "metabolism",
        "options": [
            "anabolism",
            "metabolism",
            "catabolism",
            "biochemical process"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 590,
        "question": "Which of the following best describes what the Miller-Urey experiment demonstrated regarding the origins of life?",
        "answer": "The Miller-Urey experiment was performed in the 1950s to test if Earth\u2019s early atmosphere was capable of synthesizing organic compounds needed to support the origin of life. In the experiment, molecules believed to be present in Earth\u2019s early atmosphere were placed in a closed system and electricity was run through it. This resulted in the production of amino acids and organic compounds; therefore, this supported the hypothesis that the conditions on early Earth could have aided in the synthesis of organic compounds.",
        "suggested_answer": "Organic compounds could be generated under conditions similar to Earth\u2019s early atmosphere",
        "options": [
            "Organic compounds could be generated under conditions similar to Earth\u2019s early atmosphere",
            "Earth\u2019s early atmosphere spontaneously synthesized DNA",
            "That early Earth did not exhibit the proper amount of energy needed to synthesize organic compounds",
            "That Earth\u2019s early atmosphere by itself could not have supported the formation of complex organic compounds"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 591,
        "question": "If the hydrogen bonds in a protein molecule were disturbed, which level of structure would be most affected?",
        "answer": "Proteins have four levels of structure: primary, secondary, tertiary, and quaternary. These refer to the types of binding and folding that occur in the molecule that cause it to take on a stable shape. Hydrogen bonds occur between parts of the molecule containing slightly positive hydrogen, and other parts that may be slightly negative (generally containing oxygen). These bonds stabilize the protein's secondary structure, allowing more complicated folding into tertiary and quaternary structures. Alpha-helices and beta-pleated sheets form the common secondary protein structures.",
        "suggested_answer": "Secondary",
        "options": [
            "Secondary",
            "Tertiary",
            "Primary",
            "Primary and quaternary",
            "Quaternary"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 592,
        "question": "Which of the following statements regarding nephron activity and environment is false?",
        "answer": "ATP is needed to help power the excretion of urine through the nephron. The thick ascending limb, for example, is thicker because it contains more mitochondria than other portions of the loop of Henle. The changing environment of the nephron is a product of the continual reabsorbtion of water and ions throughout the excretion process.",
        "suggested_answer": "The excretion process of urine does not require energy",
        "options": [
            "The renal cortex is hypotonic",
            "The inner renal medulla is hypertonic",
            "The excretion process of urine does not require energy",
            "Filtrate has a higher ion concentration than blood when traveling down the thin descending limb"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 593,
        "question": "A scientist takes a sample of human epithelial cells, injects it with a dye that highlights the genetic information, and places the sample under the microscope. What will the genetic information for the majority of the cells look like?",
        "answer": "The DNA will appear uncondensed and restricted to the nucleus. This is because a majority of the cells found in the sample will be in interphase, the longest stage of the cell cycle. Since it is the longest phase in the cell cycle, a majority of cells will be undergoing this phase at any given sample time. It is a point when the cell is undergoing normal cell processes and preparing for replication. During this phase, the DNA is uncondensed, allowing for transcription and giving DNA replication enzymes easy access to the nucleotide sequence.",
        "suggested_answer": "Uncondensed and restricted to the nucleus",
        "options": [
            "Condensed into 23 discrete chromosomes and restricted to the nucleus",
            "Uncondensed and restricted to the nucleus",
            "Condensed into a single circular chromosome and floating in the cytoplasm",
            "Condensed into 46 discrete chromosomes and restricted to the nucleus",
            "Condensed into 23 discrete chromosomes and floating in the cytoplasm"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 594,
        "question": "While walking home from class one afternoon, you take a shortcut that leads you in front of a house that is guarded by a huge, barking dog. Unfortunately, you are terrified of dogs! Though the dog is unable to escape its yard, as you walk past you notice yourself breathing more quickly than normal and you can feel your heart racing and your palms sweating. Which of your glands is most likely responsible for your physiological reaction to the dog?",
        "answer": "The adrenal gland is almost certainly responsible for your reaction, as this gland contains the adrenal medulla, which is capable of releasing epinephrine. During stressful situations, the sympathetic nervous system triggers the fight-or-flight response. During this response, sympathetic nerves stimulate the adrenal medulla, which then releases epinephrine. Epinephrine raises blood sugar levels and increases blood pressure, brain oxygen consumption, and heart rate. It prepares an individual to either fight a threat (such as a dog), or run away from it.",
        "suggested_answer": "Adrenal medulla",
        "options": [
            "Pineal gland",
            "Anterior pituitary",
            "Adrenal medulla",
            "Adrenal cortex"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 595,
        "question": "Is it possible to form a compound from only one element?",
        "answer": "Elements are defined by the number of protons in a given atom, and the atom is the smallest unit of an element. In contrast, a compound is defined by the identity and organization of multiple atoms, with the smallest unit of a compound being a molecule.",
        "suggested_answer": "Yes; diatomic gases are compounds that consist of only one element",
        "options": [
            "Yes; water is a compound that consists of only one element",
            "Yes; benzene rings contain only carbon",
            "No; a compound must contain multiple elements",
            "Yes; compounds and elements are synonyms",
            "Yes; diatomic gases are compounds that consist of only one element"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 596,
        "question": "During pregnancy in mammals, which of the following hormones is directly responsible for maintaining the lining of the uterus?",
        "answer": "Progesterone is a steroid hormone that supports gestation in mammalian pregnancy. After the eighth week of pregnancy, it is produced by the placenta and helps to decrease the maternal immune response to prepare for pregnancy.",
        "suggested_answer": "Progesterone",
        "options": [
            "Growth hormone",
            "Prolactin",
            "Melatonin",
            "Glucocorticoids",
            "Progesterone"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 597,
        "question": "What does the threshold potential precede?",
        "answer": "The threshold potential of a cell is a specific membrane potential that is needed in order to initiate action potential. Therefore, threshold potential precedes action potential. Note that reaching the threshold potential triggers a positive feedback loop, causing more sodium to rush into the cell, further depolarizing the membrane potential.",
        "suggested_answer": "Action potential",
        "options": [
            "Membrane potential",
            "Resting potential",
            "Action potential",
            "Voltage"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 598,
        "question": "Gregor Mendel, an Augustinian friar, studied genetics through what types of experiments?",
        "answer": "Gregor Mendel studied genetics through the crossbreeding of pea plants. Through his studies, he proposed laws of heredity (the law of segregation, the law of independent assortment, and the law of dominance), that are now called the laws of Mendelian inheritance. Darwin famously studied finches on the Galapagos Islands.",
        "suggested_answer": "Crossbreeding pea plants",
        "options": [
            "Calculated economic impact of population growth ",
            "Crossbreeding pea plants",
            "Studying museum specimens",
            "Studying finches"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 599,
        "question": "Which of the following statements about enzymes is correct?",
        "answer": "The correct answer to this question is they function under a narrow pH range.",
        "suggested_answer": "They function under a narrow pH range",
        "options": [
            "They are polymers of carbohydrates",
            "They function under a narrow pH range",
            "They always require a coenzyme",
            "They are consumed in the reaction",
            "They are used to create ATP"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 600,
        "question": "For each molecule of glucose metabolized, the citric acid cycle produces __________. ",
        "answer": "Glycolysis generates four ATP per molecule of glucose and uses up two of those in the process, while oxidative phosphorylation produces thirty-two ATP per molecule of glucose. Fermentation produces NAD+, which is used in glycolysis. The citric acid cycle produces NADH and FADH2, which are used in the electric transport chain. Note that for each turn of the citric acid cycle, three NADH molecules and one FADH2 molecule is produced, but each glucose produces two pyruvate molecules, which are converted to two acetyl-CoA molecules. Each acetyl-CoA molecule results in one full turn of the citric acid cycle. The electron transport chain produces an electrochemical gradient across the inner membrane of the mitochondria, which provides the energy used by oxidative phosphorylation to produce thirty-two ATP per molecule of glucose.",
        "suggested_answer": "six NADH molecules and two FADH2 molecules",
        "options": [
            "three NADH molecules and one FADH2 molecule",
            "six NADH molecules and two FADH2 molecules",
            "one NAD+ molecule",
            "thirty-two ATP molecules",
            "an electrochemical gradient"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 601,
        "question": "Which of the following is a hormone that is directly regulated by the nervous system? ",
        "answer": "Epinephrine, or adrenaline, is regulated by the nervous system when an environmental stress occurs. Nerve signals travel through the spinal cord to the adrenal medulla, which secretes epinephrine and norepinephrine. Release of epinephrine is not caused by a tropic hormone or other cell signal, but by direct neural stimulation.",
        "suggested_answer": "Epinephrine",
        "options": [
            "Melatonin",
            "Estrogen",
            "Epinephrine",
            "Calcitonin"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 602,
        "question": "Which of the following structures is not found within the lungs?",
        "answer": "The lungs contain the bronchioles, the alveoli, and part of the bronchi. The trachea carries inhaled air into the bronchi, but it is not actually enclosed by the bronchi the lungs only enclose structures that arise after the bronchi branch away from the trachea.",
        "suggested_answer": "Trachea",
        "options": [
            "Alveoli",
            "Bronchioles",
            "Trachea",
            "Bronchi"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 603,
        "question": "When stimulated, the vagus nerve slows down heart rate. We can assume that the vagus nerve is classified under which branch of the nervous system?",
        "answer": "Neuroglia are the support cells of the nervous system, and do not conduct action potentials. The central nervous system is composed of only the brain and spinal cord; not nerves. The somatic system controls voluntary movements, so heart rate would not be under its control. The sympathetic nervous system is commonly assigned the \"fight or flight\" responses, while the parasympathetic nervous system is responsible for \"resting and digesting.\" Since the body is returning to a rested state by lowering heart rate, we would assign this action to the parasympathetic nervous system.",
        "suggested_answer": "Parasympathetic nervous system",
        "options": [
            "Central nervous system",
            "Neuroglia",
            "Parasympathetic nervous system",
            "Somatic nervous system",
            "Sympathetic nervous system"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 604,
        "question": "Which of the following is not an example of an advantage gained through the vascularization of plants?",
        "answer": "Swimming sperm is a feature of avascular and early vascular plants, who needed to remain in moist environments in order to retain water. ",
        "suggested_answer": "Swimming sperm",
        "options": [
            "Extensive root and shoot systems",
            "Large size",
            "Swimming sperm",
            "Larger photosynthetic area",
            "Dominant sporophyte generation"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 605,
        "question": "Which of the following are present in both bacteria and fungi?",
        "answer": "Bacteria and fungi both have DNA, a plasma membrane, and a cell wall. The bacterial cell wall is made of peptidoglycan, whereas the fungal cell wall is made of chitin. Bacteria do not have nuclei and are only unicellular, while some fungi are multicellular, they are eukaryotes, and thus all have nuclei. Furthermore, eukaryotes have membrane-bound organelles. Since bacteria are prokaryotes, they lack membrane-bound organelles, but they still have ribosomes for protein synthesis (ribosomes are not membrane-bound). ",
        "suggested_answer": "A cell wall, DNA, and a plasma membrane",
        "options": [
            "A cell wall, DNA, and a plasma membrane",
            "A plasma membrane, organelles, and multicellularity",
            "A cell wall, mitochondria, and unicelluarity",
            "A nucleus, organelles, and unicellularity",
            "A cell wall, a plasma membrane, and a Golgi apparatus"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 606,
        "question": "What are the two major classifications of barriers to reproduction?",
        "answer": "Barriers to reproduction are behaviors and physiological processes that maintain distinct species and prevent hybridization. There are two major classifications of barriers to reproduction: pre-zygotic barriers and post-zygotic barriers. Pre-zygotic barriers prevent hybridization before fertilization and post-zygotic barriers prevent it after fertilization.",
        "suggested_answer": "Pre-zygotic and post-zygotic ",
        "options": [
            "Reproductive isolation and post-zygotic ",
            "Reproductive isolation and inviability ",
            "Pre-zygotic and post-zygotic ",
            "Pre-zygotic and inviability "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 607,
        "question": "Which of the following is caused by the luteal surge?",
        "answer": "The luteal surge is characterized by a sharp increase in estradiol (estrogen) levels, which then causes an increase in luteinizing hormone levels. This event causes ovulation to take place.",
        "suggested_answer": "Ovulation",
        "options": [
            "Menstruation",
            "Fertilization",
            "Ovulation",
            "Oogenesis"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 608,
        "question": "Which of the following is true regarding meristem tissue in plants?",
        "answer": "Meristem tissue contains undifferentiated cells and allows for plant growth. Apical meristems are found in areas of growth, which include the shoot and roots cells in these tissues divide rapidly.",
        "suggested_answer": "All of these",
        "options": [
            "There is a shoot and root apical meristem",
            "It is present in areas of growth",
            "It contains undifferentiated cells",
            "All of these"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 609,
        "question": "A __________ is considered an autotroph, whereas a __________ is considered a heterotroph.",
        "answer": "Autotrophs are organisms that are capable of generating biological energy from inorganic sources, such as sunlight in the case of photoautotrophs. Cyanobacteria are thought to be the prokaryotic precursor to chloroplasts through the endosymbiotic theory because they are able to perform photosynthesis. Cyanobacteria are, thus, photoautotrophs. Green algae are another type of photoautotroph.",
        "suggested_answer": "cyanobacteria . . . cow",
        "options": [
            "cow . . . cyanobacteria",
            "mushroom . . . cow",
            "green algae . . . cyanobacteria",
            "cyanobacteria . . . cow"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 610,
        "question": "Which of the following choices is not one of the processes associated with post-transcriptional modification?",
        "answer": "Post-transcriptional modification is the stage where the recently synthesized primary RNA transcript undergoes changes to become a mature RNA molecule. Post-transcriptional modifications ensure that the correct RNA transcripts are produced and that the correct proteins are translated. Post-transcriptional modification includes the processes of polyadenylation, 5\u2019 capping, and splicing. Polyadenylation is the addition of adenine bases (the poly-A tail) to the 3\u2019 end of the RNA primary transcript. The poly-A tail is important in export, stability, and translation of the transcript. 5\u2019 capping is the addition of guanine bases to the 5\u2019 end of the primary transcript. The 5\u2019 cap aids in export and translation of the transcript and also protects it from degradation. Splicing is the removal of non-coding regions, or introns, from the primary transcript.",
        "suggested_answer": "Protein folding",
        "options": [
            "Protein folding",
            "5' capping",
            "Splicing",
            "Polyadenylation"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 611,
        "question": "Which of the following is true regarding interneurons?",
        "answer": "Interneurons are a class of neuron that create neural circuits between sensory neurons, motor neurons, or the central nervous system. Interneurons are components of the central nervous system and can be further categorized into local and relay interneurons.",
        "suggested_answer": "All of these",
        "options": [
            "They are a part of the central nervous system ",
            "They create neural circuits",
            "All of these",
            "There are two types of interneurons: local and relay"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 612,
        "question": "Which of the following is true of human yolk, or ooplasm?",
        "answer": "In a human egg cell the yolk ooplasm located at the cell center in an area containing the nucleus and nucleolus. The ooplasm is composed of fatty granules that support embryo development through the early stages of embryogenesis. In human oocytes, there is a relatively small amount of ooplasm compared to the embryos of other species.",
        "suggested_answer": "There is only a small amount in each oocyte",
        "options": [
            "Ooplasm is located at the cell cortex",
            "Ooplasm lacks fat",
            "There is only a small amount in each oocyte",
            "The ooplasm sustains oocyte growth through the entire process of embryogenesis"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 613,
        "question": "Which of the following is a hormone secreted by the posterior pituitary?",
        "answer": "The posterior pituitary only secretes oxytocin and vasopressin (antidiuretic hormone). The hypothalamus actually creates these hormones and stores them in the posterior pituitary. ",
        "suggested_answer": "Oxytocin",
        "options": [
            "Growth hormone",
            "Adrenocorticotropic hormone",
            "Thyroid-stimulating hormone",
            "Oxytocin",
            "Prolactin"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 614,
        "question": "Which of the following affect the frequency of chromosomes of two linked genes crossing over?",
        "answer": "The recombination frequency depends upon the distance between the genes; a larger distance between the genes increases the probability of crossing over occurring.",
        "suggested_answer": "Distance between the genes",
        "options": [
            "Size of the gene",
            "Size of the chromosome",
            "Whether the genes are dominant or recessive",
            "Distance between the genes"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 615,
        "question": "Compared to ectotherms, endotherms have a",
        "answer": "An ectotherm relies on external sources to regulate its body temperature. Endotherms maintain a relatively constant body temperature, independent of their external environment. To regulate their body temperature, endotherms have a higher ",
        "suggested_answer": "Higher metabolic rate",
        "options": [
            "Lower metabolic rate",
            "Higher metabolic rate",
            "Shorter lifespan",
            "Greater fluctuation in body temperature"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 616,
        "question": "Which molecular mechanism is not a possible cause of nondisjunction?",
        "answer": "Nondisjunction is the improper segregation of chromosomes during meiosis or mitosis. The molecular causes behind nondisjunction are (1) increased rate of nondisjunction in female cells, (2) failure to properly pass the spindle assembly checkpoint, and (3) weakening of the cohesion complex at the centromere due to age.",
        "suggested_answer": "Error in cytoplasmic partitioning",
        "options": [
            "Maternal gametes have a higher chance of exhibiting nondisjunction",
            "Failure to pass the spindle assembly checkpoint",
            "Error in cytoplasmic partitioning",
            "Older age of an individual leads to weaker cohesin complexes at the centromere"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 617,
        "question": "Which of the following is true regarding all type II skeletal muscle tissues?",
        "answer": "Type II skeletal muscle tissues are all fast twitch muscles, but vary in their use of oxygen.",
        "suggested_answer": "They are fast twitch muscles",
        "options": [
            "They are fast twitch muscles",
            "They are \"white\" muscles",
            "They are anaerobic",
            "They are aerobic "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 618,
        "question": "Which of the following is expelled through the vagina during menstruation?",
        "answer": "Menstruation is the part of the female reproductive cycle in which the uterine lining is expelled through the vagina when the released oocyte is not fertilized. The onset of menstrual periods is called menarche and the cessation is called menopause. Menstruation occurs due to a decrease in progesterone levels and is often accompanied by cramping.",
        "suggested_answer": "Uterine lining",
        "options": [
            "Placenta",
            "Ovaries",
            "Fallopian tube lining",
            "Uterine lining"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 619,
        "question": "Which of the following nucleic acids codes for viral genomes?",
        "answer": "A virus may contain any of these four types of genetic material. The type of nucleic acid, and whether it is single- or double-stranded, determines how the virus integrates with the host organism. Different classes of viruses have different mechanisms for infection.",
        "suggested_answer": "Any of the other answer choices",
        "options": [
            "Double-stranded RNA",
            "Single-stranded DNA",
            "Any of the other answer choices",
            "Single-stranded RNA",
            "Double-stranded DNA"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 620,
        "question": "Which of the following events will occur first during the initiation of a muscle contraction?",
        "answer": "There are seven steps involved in the signaling cascade from the initiation of contraction to the subsquent relaxation of muscle fibers.",
        "suggested_answer": "Motor neurons release acetylcholine",
        "options": [
            "Calcium is released from the sarcoplasmic reticulum",
            "Motor neurons release acetylcholine",
            "Calcium ions bind to troponin",
            "T-tubules close, resulting in activation of the Ca2+ ATPase pump"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 621,
        "question": "Which of the following choices best describes the factor that opens ion channels by inducing receptors to initiate \"conformational change\"?",
        "answer": "When inactive, ion gated receptors are closed. When a ligand binds, the channel undergoes a conformational change and opens: creating a tunnel. This conformational change does not last for a long period of time; the ligand soon dissociates and the ion channel closes.",
        "suggested_answer": "Ligand binding to the channel protein",
        "options": [
            "Phosphorylation of the ion channel",
            "Intracellular ion concentration",
            "Ligand binding to the channel protein",
            "Extracellular ion concentration"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 622,
        "question": "Which of the following is an example of an anabolic reaction?",
        "answer": "An anabolic reaction is one in which larger molecules are made from combining smaller molecules. Even without knowing the exact mechanics of the reactions given in the answer choices, we know that we are looking for a reaction in which multiple molecules combine to form a single molecule.",
        "suggested_answer": "Acetyl CoA combining with oxaloacetate to form citrate",
        "options": [
            "Glucose-6-phosphate being isomerized to form fructose-6-phosphate",
            "Citrate being converted to ketoglutarate",
            "Glucose being converted to two pyruvate molecules",
            "Acetyl CoA combining with oxaloacetate to form citrate"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 623,
        "question": "Which of the following is not true about alleles?",
        "answer": "An allele is a genetic variant of a given trait. Alleles for the given trait within an individual are located at the same genetic locus. The resulting observable characteristic is called a phenotype. Humans are diploid organisms, meaning that they have two copies of each gene, where each copy may be the same or different alleles.",
        "suggested_answer": "Humans have four alleles for each characteristic",
        "options": [
            "Alleles for the same characteristic are located at the same locus",
            "Alleles are variations of the same gene",
            "Humans have four alleles for each characteristic",
            "Alleles are responsible for different phenotypes"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 624,
        "question": "What is the term for chromosomal contact that precedes the crossing over event during meiosis?",
        "answer": "During meiosis, the chiasma is the point of chromatid contact that precedes crossing over. The crossing over event then occurs at this point. Synapsis refers to the pairing of homologs during prophase I of meiosis.",
        "suggested_answer": "Chiasma",
        "options": [
            "Synapsis",
            "Chiasma",
            "Centrosome",
            "Kinetochore"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 625,
        "question": "Which of the following is true about absolute and relative refractory periods?",
        "answer": "Absolute refractory period occurs because of the inactivation of sodium channels. Since the sodium channels are inactivated, the neuron can\u2019t depolarize and initiate another action potential. Relative refractory period occurs due to the slow inactivation of potassium channels. The voltage-gated potassium channels take a longer time to inactivate, which causes the cell to hyperpolarize. The cell becomes more negative than resting membrane potential as positive potassium ions exit the cell down their gradient. Since the cell has a more negative membrane potential, a larger stimulus is required to reach the threshold.",
        "suggested_answer": "Relative refractory period occurs due to the slow inactivation of potassium channels",
        "options": [
            "Absolute refractory period occurs due to the slow inactivation of potassium channels",
            "Relative refractory period occurs due to the slow inactivation of potassium channels",
            "Absolute refractory period occurs due to the slow inactivation of sodium channels",
            "Relative refractory period occurs due to the slow inactivation of sodium channels"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 626,
        "question": "Specialized cells line the insides of our blood vessels. These cells help control vasoconstriction and vasodilation and play an important role in the permeability of blood vessels. These cells can be calssified as which of the following?",
        "answer": "Endothelial cells line the insides of blood vessels and lymphatic vessels and have many important functions, including but not limited to those described in the question. One additional function of endothelial cells is involvement in blood clotting.",
        "suggested_answer": "Endothelial cells",
        "options": [
            "Endothelial cells",
            "Nerve cells",
            "Red blood cells",
            "Epithelial cells"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 627,
        "question": "Substance A is losing an electron to substance B. In this case, __________.",
        "answer": "Since substance A is losing an electron it is undergoing oxidation (oxidation is loss of electrons). Substance B is gaining an electron and so it is undergoing reduction (reduction is gain of electrons). Substance A is the reducing agent because it causes the reduction of substance B, and substance B is the oxidizing agent because it causes the oxidation of substance A.",
        "suggested_answer": "substance A is undergoing oxidation and substance B is undergoing reduction",
        "options": [
            "both substances are being oxidized",
            "both substances are being reduced",
            "substance A is undergoing reduction and substance B is undergoing oxidation",
            "substance A is undergoing oxidation and substance B is undergoing reduction"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 628,
        "question": "Hows does the act of breathing relate to cellular respiration in humans and other mammals. ",
        "answer": "The final electron acceptor of the electron transport chain in cellular respiration is oxygen. Additionally carbon dioxide is produced as a waste product during the citric acid cycle phase of cellular respiration.",
        "suggested_answer": "With each breath, oxygen is taken in and used for cellular respiration while carbon dioxide is exhaled as a waste product.",
        "options": [
            "There is no relationship between breathing an cellular respiration.",
            "With each breath, oxygen is taken in and used for cellular respiration while nitrogen is exhaled as a waste product.",
            "With each breath, oxygen is taken in and used for cellular respiration while carbon dioxide is exhaled as a waste product.",
            "With each breath, carbon dioxide is taken in and used for cellular respiration while oxygen is exhaled as a waste product.",
            "With each breath, nitrogen is taken in and used for cellular respiration while carbon dioxide is exhaled as a waste product."
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 629,
        "question": "Which of the following is a correct example of post-transcriptional modification of eukaryotic RNA?",
        "answer": "The only correct post-transcriptional modification from the answer choices is the addition of a 5' (methyl guanosine) cap. The remaining answer choices are either false, or examples of post-translational modifications.",
        "suggested_answer": "Addition of a 5' Cap",
        "options": [
            "Transport of the transcript into the nucleus from the cytoplasm",
            "Splicing out of exons from the primary transcript",
            "Addition of a 3' Poly-G tail",
            "Acetylation at the N-terminus",
            "Addition of a 5' Cap"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 630,
        "question": "Which of the following structures are present in all eukaryotic cells?",
        "answer": "Cells are encased in a semi-permeable phospholipid bilayer called the cell (plasma) membrane. Cell membranes serve to organize and protect the cell. Eukaryotic cells also have internal membranes, which enclose organelles, including the nuclear membrane. Plant, and fungal cells have an additional membrane, called the cell wall, outside of the cell membrane that offers additional support, protection, and rigidity. ",
        "suggested_answer": "Cell membrane and nuclear membrane",
        "options": [
            "Cell wall only",
            "Cell membrane only",
            "Nuclear membrane only",
            "Cell membrane and nuclear membrane"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 631,
        "question": "Where does a microtubule from the mitotic spindle attach to a sister chromatid?",
        "answer": "Mitotic spindles attach to the kinetochore region of the centromere, the area of a chromosome that links sister chromatids. Centromeres are not always in the center of a chromosome, sister chromatids can be metacentric, acrocentric, or telocentric where the centromere is located in the middle, towards one end of a chromosome, or at the end of a chromosome arm, respectively. In eukaryotes, centromeres contain repeating DNA sequences in a highly compacted state. The kinetochore is a protein complex that assembles at the centromere that is able to bind to both the centromeric DNA and to mitotic spindles. Each sister chromatid has it\u2019s own kinetochore.",
        "suggested_answer": "Kinetochore",
        "options": [
            "Centriole",
            "Kinetochore",
            "Kinesin",
            "Centrosome"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 632,
        "question": "A lipid that consists of a long chain of hydrocarbons with the maximum number of hydrogens possible is referred to as __________.",
        "answer": "Saturated fatty acids contain the maximum number of hydrogens on their hydrocarbon tails. These molecules form long, straight chains that can be very densely packed. By contrast, unsaturated fatty acids, do not contain the maximum number of hydrogens on their hydrocarbon tails, and thus have \"kinks\" in their tails. This does not allow for dense packing and storage of unsaturated fatty acids, and thus make them easier to burn.",
        "suggested_answer": "a saturated fatty acid",
        "options": [
            "a triglyceride",
            "a saturated fatty acid",
            "a vitamin",
            "a steroid"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 633,
        "question": "One of the functions of the liver is __________.",
        "answer": "The liver has a large number of functions, including metabolizing toxins, synthesizing proteins, and storing glucose in the form of glycogen. The only answer choice that describes liver functions is the production of bile, triglycerides, and cholesterol.",
        "suggested_answer": "to produce bile, triglycerides, and cholesterol",
        "options": [
            "to produce insulin in its specialized beta cells",
            "to act as the main storage center for bile",
            "to produce bile, triglycerides, and cholesterol",
            "to secrete epinephrine and norepinephrine",
            "to physically digest food"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 634,
        "question": "What model describes DNA replication?",
        "answer": "There are three models of DNA replication: the dispersive model, the conservative model, and the semiconservative model. The dispersive model postulates that parental DNA cut into segments, each of which acts as a template for newly synthesized fragments. Together, DNA helices reassemble, containing daughter and parental DNA segments mixed together. The conservative model hypothesizes that the parental DNA double helix acts as a template for the daughter DNA molecule. This model results in one parental double helix and one daughter DNA molecule. The model that describes DNA replication is the semiconservative model. In this model, parental DNA strands separate and act as templates for daughter strands, resulting in two DNA molecules each with one parent and one daughter DNA strand. Thus, each newly synthesized DNA molecule has one parent strand bound to one daughter strand.",
        "suggested_answer": "Semiconservative",
        "options": [
            "Dispersive",
            "Semiconservative",
            "Conservative",
            "Any of these, depending on the cell type"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 635,
        "question": "The sodium-potassium pump is an example of which of the following?",
        "answer": "The sodium-potassium pump moves sodium to the outside of the cell and potassium to the inside of the cell. Since the pump moves the ions in opposite directions, the pump is classified as an antiporter. If the ions moved in the same direction it would be classified as a symporter.",
        "suggested_answer": "Antiporter because it transports sodium and potassium ions in the opposite direction",
        "options": [
            "Symporter because it transports sodium and potassium ions in the same direction",
            "Antiporter because it transports sodium and potassium ions in the opposite direction",
            "Antiporter because it transports sodium and potassium ions in the same direction",
            "Symporter because it transports sodium and potassium ions in the opposite direction"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 636,
        "question": "Immunoglobulins (also known as antibodies) are produced by which of the following?",
        "answer": "B-cells produce antibodies (a subset of adaptive immunity called humoral immunity). The usual sequence involves B-cell activation via interleukins from helper T-lymphocytes, which cause the B-cell to undergo mitosis, creating numerous clones that will differentiate into plasma cells (rapid antibody producers) or memory B-cells. ",
        "suggested_answer": "B-lymphocytes, usually with input from helper T-lymphocytes",
        "options": [
            "B-lymphocytes, usually with input from helper T-lymphocytes",
            "Natural killer cells",
            "Dendritic cells",
            "Helper T-lymphocytes, usually with input from B-lymphocytes"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 637,
        "question": "During which of the following phases will a human somatic cell contain the least amount of DNA?",
        "answer": "Remember that DNA is replicated during the S phase of the cell cycle. Immediately after mitosis, the cell contains a single chromatid for each chromosome, for a total of 46 chromatids. This period corresponds to the G1 phase, during which the cell produces proteins and grows.",
        "suggested_answer": "G1 phase",
        "options": [
            "Prophase",
            "Metaphase",
            "G1 phase",
            "G2 phase"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 638,
        "question": "If a colony of round or oval-shaped bacteria are arranged in a random grape-like cluster, they must be:",
        "answer": "The two most common bacterial arrangements are Staph- and Strep-. Staph- is a non-symmetrical arrangement like a cluster of grapes. Strep- indicates a chain of bacteria.",
        "suggested_answer": "Staphylococci",
        "options": [
            "Streptobacilli",
            "Staphylococci",
            "Spirochetes",
            "There is no way to predict based on the information provided.",
            "Streptococci"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 639,
        "question": "Which of the following best describe ganglia?",
        "answer": "Ganglia are described as clusters of nerve cells that are found in the autonomic nervous system.",
        "suggested_answer": "Clusters of nerve cells",
        "options": [
            "The part of a neuron that transmits electrical signals to other nerve cells",
            "Specialized neurons located only in the heart",
            "Clusters of nerve cells",
            "The neuron cell body "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 640,
        "question": "Which of the following proteins are not situated within the core of the nucleosome?",
        "answer": "Histones are composed of several proteins, and are used to compact DNA within the nucleus. When DNA is wrapped around a group of eight histones, the resulting structure is a nucleosome.",
        "suggested_answer": "H1",
        "options": [
            "H4",
            "H2A",
            "H2B",
            "H3",
            "H1"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 641,
        "question": "A harmless species of butterfly mimics the coloration of a poisonous species. This is an example of which of the following?",
        "answer": "Batesian mimicry involves a harmless species mimicking an unpalatable or harmful species, such as the butterfly in the example. Mullerian mimicry involves two unpalatable or harmful species resembling each other. This also helps predators learn which types of species to avoid. Cryptic coloration is more simply known as camouflage. Aposematic coloration is warning coloration, such as bright colors on poisonous tree frogs or venomous snakes. ",
        "suggested_answer": "Batesian mimicry",
        "options": [
            "Cryptic coloration ",
            "Aposematic coloration ",
            "Communal camouflage ",
            "Mullerian mimicry",
            "Batesian mimicry"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 642,
        "question": "The parasympathetic nervous system is a subdivision of which branch of the nervous system?",
        "answer": "There are two major criteria that can be used to define nervous system categorizations. The first is location in the body. The central nervous system consists only of the brain and spinal cord, while the peripheral nervous system extends throughout the rest of the body. The central nervous system is enclosed by the blood-brain barrier, separating it from the rest of the body. The second classifier is mechanism of control. Some nervous pathways can be controlled voluntarily (somatic), while others are involuntary (autonomic).",
        "suggested_answer": "The peripheral autonomic nervous system",
        "options": [
            "The central nervous system",
            "The peripheral somatic nervous system",
            "The spinal cord",
            "The peripheral autonomic nervous system"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 643,
        "question": "Of the following, which is most likely to pass through the cell membrane via passive transport?",
        "answer": "Passive transport is simple diffusion, without the aid of any protein channels. Since the plasma membrane is composed of a lipid bilayer, the molecules that most readily cross it are small and hydrophobic.",
        "suggested_answer": "A steroid molecule",
        "options": [
            "A peptide hormone",
            "A steroid molecule",
            "A charged ion",
            "Glucose",
            "Water, when traveling from a highly hypertonic environment into a cell"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 644,
        "question": "Which of the following macromolecules is found only in plants and is described as a polysaccharide consisting entirely of glucose molecules?",
        "answer": "Starch is a storage polysaccharide in plants. It is a polymer consisting solely of glucose. Glucose is a source of fuel for cells; therefore, starch is stored for energy.",
        "suggested_answer": "Starch",
        "options": [
            "Starch",
            "Disaccharide",
            "Glycogen",
            "Chitin"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 645,
        "question": "Which of the following solution compositions would result in the highest net weight gain of the dialysis tubing?",
        "answer": "In order for the bag to gain weight, water needs to enter the tubing. The dialysis tubing must be placed in a hypotonic solution a solution containing a higher percentage of water (lower concentration of ions) than the dialysis solution. This will cause water to enter the more concentrated solution within the dialysis tubing, in an attempt to reach equilibrium",
        "suggested_answer": "Dialysis tubing: 50% sodium solution",
        "options": [
            "Dialysis tubing: 50% sodium solution",
            "Beaker: Pure water",
            "Dialysis tubing: pure water",
            "Beaker: pure water",
            "Dialysis tubing: 85% sodium solution",
            "Beaker: 50% sucrose solution",
            "Dialysis tubing: 45% sodium solution",
            "Beaker: 35% sodium solution",
            "Dialysis tubing: 50% sucrose solution",
            "Beaker: 50% sodium solution"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 646,
        "question": "How many lobes does the right lung have?",
        "answer": "The right lung contains three lobes: upper, middle, and lower. The left lungs contains two lobes: upper and lower. The left lung is designed to be smaller than the right in order to accommodate the heart, which is situated slightly to the left.",
        "suggested_answer": "Three",
        "options": [
            "Four",
            "Three",
            "Two",
            "One",
            "Five"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 647,
        "question": "What human activities do not impact the carbon cycle?",
        "answer": "Modern human activities greatly impact the carbon cycle. Increase in land usage decreases the presence of natural ecosystems and the ability of organisms to remove carbon from the atmosphere. Pollution damages organisms, which reenters the terrestrial biosphere and increases erosion. Deforestation directly removes carbon rom ecosystems and decreases the amount of carbon absorbed from the atmospheric biosphere.",
        "suggested_answer": "Clean energy use",
        "options": [
            "Clean energy use",
            "Deforestation",
            "Pollution",
            "Increase in land use"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 648,
        "question": "Cellular respiration primarily takes place in which organelle?",
        "answer": "Cellular respiration divided into three steps: glycolysis, citric acid cycle (also known as the Krebs or TCA cycle), and the electron transport chain (also known as oxidative phosphorylation). While glycolysis takes place in the cytosol, the other two steps occur in the mitochondria.",
        "suggested_answer": "Mitochondria",
        "options": [
            "Peroxisome",
            "Cytoplasm",
            "Cytosol",
            "Mitochondria",
            "Nucleus"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 649,
        "question": "In which phase of mitosis do the sister chromatids first separate? ",
        "answer": "Separation of the sister chromatids by the microtubules pulling them to opposite poles occurs in anaphase. Also, during late anaphase, cytokinesis begins. This is the process of the cell dividing, since the nucleus has already divided (karyokinesis).",
        "suggested_answer": "Anaphase",
        "options": [
            "Anaphase",
            "Prophase",
            "Metaphase",
            "Telophase"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 650,
        "question": "The negative charge inside cells is primarily maintained by __________.",
        "answer": "If there was no expenditure of energy when determining the voltage across the plasma membrane, there would be equal electrical charge on both sides of the bilayer as the ions travel to reach equilibrium. This means that ATP must be used in order to establish a resting potential, keeping the ions away from electrical equilibrium.",
        "suggested_answer": "the sodium-potassium pump",
        "options": [
            "the plasma membrane being impermeable to water",
            "the sodium-potassium pump",
            "the membrane being less permeable to potassium ions than to sodium ions",
            "the membrane being less permeable to sodium ions than to potassium ions"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 651,
        "question": "What experiment confirmed the physical nature of an atom?",
        "answer": "The experiment that confirms the atom structure is Rutherford's gold foil experiment. In the experiment, alpha particles were directed toward a thin piece of gold foil and reflected radiation was detected. The result of the experiment showed very little reflection, and most of the radiation passed straight through the foil. The conclusion drawn was that the majority of atomic volume consists of a loose electron cloud, and only a small region is occupied by a dense nucleus capable of reflecting the alpha radiation.",
        "suggested_answer": "Rutherford's gold foil experiment",
        "options": [
            "Rutherford's copper foil experiment",
            "Rutherford's iron foil experiment",
            "Rutherford's silver foil experiment",
            "Rutherford's bronze foil experiment",
            "Rutherford's gold foil experiment"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 652,
        "question": "How is Disease J inherited?",
        "answer": "The first generation shows an affected father and an unaffected mother. They produce both affected and unaffected children in the second generation, meaning that the disease cannot be recessive; if it were recessive, none of the second generation could be affected due to dominant alleles inherited from the mother. We can also conclude that the affected father is heterozygous.",
        "suggested_answer": "Autosomal dominant",
        "options": [
            "X-linked recessive",
            "Autosomal recessive",
            "X-linked dominant",
            "Autosomal dominant"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 653,
        "question": "In which of the following groups of organisms does indeterminate cleavage take place?",
        "answer": "Indeterminate cleavage takes place in most deuterostomes. It is a type of cleavage in which embryonic cells can be separated and successfully develop into different organisms.",
        "suggested_answer": "Deuterostomes",
        "options": [
            "Deuterostomes",
            "Protostomes",
            "Placental mammals ",
            "Chordates"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 654,
        "question": "What causes water's high heat capacity?",
        "answer": "Cohesion is the result of increased strength of hydrogen bonding between many water molecules. This increased strength requires a great amount of heat in order to break the hydrogen bonds between molecules, in order for these molecules to become vapor. Cohesion and hydrogen bonding are the cause for water's low vapor pressure, high boiling point, and high heat capacity.",
        "suggested_answer": "Cohesion",
        "options": [
            "Low density as a solid",
            "Cohesion",
            "Adhesion",
            "Low boiling point"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 655,
        "question": "Which of the following cell types does not contain G protein-coupled receptors?",
        "answer": "G protein-coupled receptors are only found in eukaryotes including yeast cells and animal cells. Bacteria cells are prokaryotes, and therefore do not contain G protein-coupled receptors. Even though yeast cells are single-celled, they possess all the characteristics of eukaryotic cells.",
        "suggested_answer": "Bacteria cell",
        "options": [
            "Rabbit leukocyte",
            "Yeast cell",
            "Bacteria cell",
            "Human myocyte"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 656,
        "question": "Which of these is not a characteristic of prokaryotic organisms?",
        "answer": "A membrane-bound nucleus is not present in prokaryotic organisms. All other structures can be found in prokaryotes.",
        "suggested_answer": "Nucleus",
        "options": [
            "Cell wall",
            "Lipid bilayer",
            "DNA and RNA",
            "Ribosomes",
            "Nucleus"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 657,
        "question": "Which of the following hormones is not involved in the menstrual cycle?",
        "answer": "Prolactin is a hormone responsible for the production of milk in the mammary glands of mammals. It is not involved in the menstrual cycle, which is controlled by fluctuating levels of the hormones estrogen, progesterone, luteinizing hormone (LH), and follicle-stimulating hormone (FSH).",
        "suggested_answer": "Prolactin",
        "options": [
            "Luteinizing hormone (LH)",
            "Progesterone",
            "Prolactin",
            "Follicle-stimulating hormone (FSH)",
            "Estrogen"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 658,
        "question": "Which part of the developing blastocyst becomes the placenta in mammals?",
        "answer": "The trophoblast is a layer of cells that surround the blastocyst. The trophoblast becomes the outer layer of the placenta and further differentiates into syncytiotrophoblast and cytotrophoblast cells that contribute to placentation.",
        "suggested_answer": "Trophoblast",
        "options": [
            "Trophoblast",
            "Yolk",
            "Embryoblast",
            "Blastocoel"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 659,
        "question": "In a chi squared test, the greater the difference between the observed and expected frequencies of a trait, the ___ the p-value.",
        "answer": " A larger difference between observed and expected values will result in a larger chi square value. Using the critical values table, for a given degrees of freedom, as chi square value increases, p-value decreases. ",
        "suggested_answer": "lower",
        "options": [
            "more positive",
            "more negative",
            "greater",
            "lower"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 660,
        "question": "Which of the following carbohydrates does not have a molecular formula of ?",
        "answer": "Carbohydrates have an empirical formula of . The most common types of carbohydrates are pentoses (5-carbon carbohydrates) and hexoses (6-carbon carbohydrates). All 6-carbon carbohydrates share the molecular formula .",
        "suggested_answer": "Ribose",
        "options": [
            "Ribose",
            "Glucose",
            "Galactose",
            "Mannose"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 661,
        "question": "What is an allele? ",
        "answer": "Alleles are simply different versions of the same gene that encode for variations of the same characteristic. For example, different eye colors are encoded by different alleles of the same gene.  ",
        "suggested_answer": "One of several alternative versions of the same gene",
        "options": [
            "A type of gene",
            "One of several alternative versions of the same gene",
            "A genetic feature unique to plants ",
            "A copy of a chromosome"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 662,
        "question": "Brown fur (B) in mice is dominant over white fur (bb). You have two brown mice, and when they are bred together you obtain 3 brown-haired offspring and one white-haired offspring. What must have been the genotypes of these brown-haired parents?",
        "answer": "Since B is completely dominant over b, and you obtained a 3:1 ratio of phenotypes, the parents must have been heterozygous, therefore you can represent their genotypes as Bb.",
        "suggested_answer": "Bb x Bb",
        "options": [
            "BB x Bb",
            "Bb x Bb",
            "BB x bb",
            "bb x bb",
            "BB x BB"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 663,
        "question": "Where does blood go after it leaves the glomerulus?",
        "answer": "The kidney-nephron system is one of the few portal systems in the human body. Portal systems link two arteriole or capillary systems together, in this case the afferent and efferent arterioles. The efferent arterioles leave the glomerulus after it has been filtered into Bowman's capsule, and follow along the nephron to pick up any reabsorbed material from the nephrons via the vasa recta capillary bed. Both the glomerulus and vasa recta are groups of capillaries, which qualifies the renal circulation as a portal system.",
        "suggested_answer": "It runs parallel to the nephron via the efferent arterioles",
        "options": [
            "It runs parallel to the nephron via the efferent venules",
            "It re-enters systemic circulation",
            "It returns to the heart via the inferior vena cava",
            "It runs parallel to the nephron via the efferent arterioles",
            "It re-enters pulmonary circulation"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 664,
        "question": "How does a ribosome detect that the correct amino acid is being added during translation?",
        "answer": "Amino acid sequence is determined by the sequence of codons on mRNA. tRNA is responsible for bringing new amino acids to the ribosome. Interactions between the codons on mRNA and the anticodons on tRNA are what allow the formation of the appropriate peptide bonds.",
        "suggested_answer": "Appropriate base pairing between the mRNA and tRNA",
        "options": [
            "Appropriate base pairing between mRNA and rRNA ",
            "Translation factors",
            "Appropriate base pairing between the mRNA and tRNA",
            "It doesn\u2019t; correct sequences are checked later by chaperones during protein folding"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 665,
        "question": "__________ is the development of similar characteristics in unrelated species, resulting from adaptations to similar environmental conditions.",
        "answer": "Convergent evolution is the development of characteristics in unrelated species, resulting from adaptations to similar environmental conditions whereas the other choices identify other variations of evolution. Divergent evolution is the loss of similar structures in related species due to different environmental conditions. Speciation is the process by which new species evolve as a result of environmental conditions. Genetic drift is the change in allele frequencies within a population due to the imperfections of random sampling techniques. Coevolution is the phenomenon characterized by the evolution of two organisms simultaneously as a result of their interaction with each other. ",
        "suggested_answer": "Convergent evolution",
        "options": [
            "Coevolution",
            "Speciation",
            "Divergent evolution",
            "Convergent evolution",
            "Genetic drift"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 666,
        "question": "Name the compound and determine the type of molecule.",
        "answer": "The given compound is the formula for gaseous hydrogen. Hydrogen atoms have only one valence electron and will form covalent bonds with other hydrogen atoms to fill the s orbital, generating a covalent bond. This tells us that it is a molecular bond, since no ionic bonds are present.",
        "suggested_answer": "Hydrogen; diatomic molecular compound",
        "options": [
            "Hydrogen; diatomic molecular compound",
            "Hydrogen monohydride; binary ionic compound",
            "Hydrogen monohydride; diatomic molecular compound",
            "This molecule cannot exist",
            "Hydrogen; binary molecular compound"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 667,
        "question": "During which of the following stages of embryogenesis is the developing embryo called a zygote?",
        "answer": "The term zygote is sued to describe a fertilized egg. The zygote is diploid and formed by the fusion of the sperm and egg gametes. The developing organism is called the zygote until it is composed of 16 cells during cleavage, when it is called the morula.",
        "suggested_answer": "After fertilization",
        "options": [
            "Only after implantation",
            "After fertilization",
            "During the entirety of cleavage",
            "During gastrulation"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 668,
        "question": "Which of the following is true about the nephron and urine production?",
        "answer": "Sodium is reclaimed through passive transport in the thin ascending limb and is reclaimed by active transport in the thick ascending limb, distal tubule, and collecting duct. Each location of sodium resorption uses a different transport protein and mechanism.",
        "suggested_answer": "Active transport is used to reclaim sodium in certain parts of the nephron",
        "options": [
            "The descending limb and the collecting duct use the same physiological mechanisms to concentrate the filtrate",
            "Active transport is used to reclaim sodium in certain parts of the nephron",
            "The osmolarity of the filtrate always matches the osmolarity of the surrounding interstitial fluid",
            "The filtrate is at its lowest osmolarity at the bottom of the descending limb"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 669,
        "question": "Which of the following best describe methods that are used to prevent premature births?",
        "answer": "Premature birth is the leading cause of death in newborns. While the exact causes of premature birth are not completely understood, certain methods may work to prevent it. These methods include nutritional supplements, avoiding stress, screening for complications, and reduction in activity.",
        "suggested_answer": "All of these",
        "options": [
            "Avoiding stress",
            "Screening for complications",
            "Reducing the mother's activity",
            "Nutritional supplements",
            "All of these"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 670,
        "question": "During which step of cell division does crossing over occur?",
        "answer": "When chromatids \"cross over,\" homologous chromosomes trade pieces of genetic material, resulting in novel combinations of alleles, though the same genes are still present. Crossing over occurs during prophase I of meiosis before tetrads are aligned along the equator in metaphase I.",
        "suggested_answer": "Prophase I",
        "options": [
            "Prophase II",
            "Prophase I",
            "Metaphase II",
            "Metaphase I"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 671,
        "question": "If a person has low blood calcium levels, what would you expect the level of PTH to be in the body?",
        "answer": "A critical concept to understand about hormones in the body is negative feedback. Remember that hormones are not causing the body's condition, but rather, are responding to the body's condition. As a result, parathyroid hormone would be higher than normal in order to increase the concentration of calcium currently in the blood, correcting the existing deficiency.",
        "suggested_answer": "Parathyroid hormone would be higher than normal in the blood.",
        "options": [
            "Parathyroid hormone would be lower than normal in the blood.",
            "Normal levels of parathyroid hormone would be seen as a different hormone works to restore normal calcium levels.",
            "Normal levels of parathyroid hormone would be seen as it works to restore normal calcium levels.",
            "Parathyroid levels will fluctuate until normal calcium levels are restored.",
            "Parathyroid hormone would be higher than normal in the blood."
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 672,
        "question": "Calcium ions are necessary for the formation of cross-bridges between the myosin head of the thick filament and the actin subunits of the thin filament. In order to end cross-bridge cycling calcium ions must follow which of the listed processes?",
        "answer": "Calcium ions facilitate the translocation of the troponin-tropomyosin complex, which inhibits cross-bridge formation between the myosin head (thick filament) and actin (thin filament). In order to inhibit muscle contraction, the troponin-tropomyosin must slide back into its original position, thereby inhibiting physical and chemical contact between the thick and thin filaments. In order to accomplish this, the calcium ions must be actively pumped out of the cytoplasm and into the sarcoplasmic reticulum (SR). It is important to remember that in a muscle fiber, the SR acts as a reservoir to store calcium ions. In order for them to be stored in a concentration greater than that in the cytoplasm, active transport must be involved to pump the ions against their gradient.",
        "suggested_answer": "Return to the sarcoplasmic reticulum via active transport",
        "options": [
            "Return to the extracellular space via active transport",
            "Return to the sarcoplasmic reticulum via ribosomal transport",
            "Return to the blood via ribosomal transport",
            "Return to the sarcoplasmic reticulum via active transport"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 673,
        "question": "Why is it necessary to receive a flu shot every year in order to avoid getting the flu?",
        "answer": "Influenza mutates rapidly, and can mutate into a new virus and evade previous vaccines. As a result, new vaccines must be created in order to provide the best defense against the influenza virus for that year. The vaccine you get one year is not going to be the same vaccine you get the next year.",
        "suggested_answer": "Influenza undergoes genetic mutations throughout the year which allows it to evade previously created cellular defenses.",
        "options": [
            "Your body must be introduced to influenza yearly in order for your body to continue creating antibodies.",
            "Influenza destroys the body's antibody reserves and yearly vaccinations are needed to restore antibody levels.",
            "Influenza undergoes genetic mutations throughout the year which allows it to evade previously created cellular defenses.",
            "Your body must be resupplied with fresh antibodies every year in order to maintain immunity to the flu."
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 674,
        "question": "Which of these animals would be least likely to undergo allopatric speciation?",
        "answer": "Allopatric speciation occurs when two groups of organisms are separated by a physical or geographic barrier. Common examples of these barriers include mountain ranges, oceans, and even large rivers. The isthmus of Panama is a prime example of a geographical barrier and it separates the Atlantic and Pacific oceans. ",
        "suggested_answer": "Bird",
        "options": [
            "Raccoon",
            "Prehistoric human",
            "Snake",
            "Bird",
            "E. coli"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 675,
        "question": "Which of the following cells is found in the peripheral nervous system?",
        "answer": "While both oligodendrocytes and Schwann cells produce myelin sheaths that insulate nervous system signals, only the Schwann cells are found in the peripheral nervous system.",
        "suggested_answer": "Schwann cells",
        "options": [
            "Ependymal cells",
            "Oligodendrocytes",
            "Microglia",
            "Astrocytes",
            "Schwann cells"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 676,
        "question": "The law of independent assortment states that ______________________. ",
        "answer": "This is the definition of the law of independent assortment; during meiosis, the inheritance of one gene does not influence whether another, separate gene will also be inherited by that gamete.",
        "suggested_answer": "inheritance of a specific gene is not influenced by other genes",
        "options": [
            "each gamete receives one allele for each gene from each parent",
            "inheritance of a specific gene is not influenced by other genes",
            "genes that are on the same chromosome are inherited together",
            "genes that are further apart will be less likely to be inherited together "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 677,
        "question": "Which of the following does not regulate cyclin-dependent kinases (CDK's) to influence a cell's progress through the cell cycle?",
        "answer": "Cell lysis is not regulated by cyclin-dependent kinases (CDK). Cyclin concentration, CDK phosphorylation/unphosphorylation, and subcellular localization all influence a cell's progress through the cell cycle. The main control of the cell cycle speed and regulation is cyclin concentration, which is responsible for regulating CDK activity. CDK is inactive if cyclin concentration is low, and is active if cyclin concentration is high. Also, CDK control phosphorylation/unphosphorylation depending on its activity level based on cyclin concentration, this process also control the cell cycle progression. Lastly, subcellular localization controls concentration by sequestering cyclin/CDK complexes in different locations to control concentration levels that affect cell cycle progression.",
        "suggested_answer": "IV only",
        "options": [
            "I, II, III, and IV",
            "II only",
            "I and II",
            "IV only",
            "I, II, and III"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 678,
        "question": "Which of the following describes an ionic bond?",
        "answer": "Ionic bonds occur when there is a large electronegativity difference between two atoms, such as elemental between fluorine and potassium. The transfer of one electron will fill the valence electron shell of both atoms. Remember that ionic bonds result in ions, or charged species. A covalent bond is a chemical bond in which two atoms share one or more electrons, but the electron(s) is/are never fully donated or accepted.",
        "suggested_answer": "A type of chemical bond in which one or more electrons are transferred from one atom to another in order for both atoms to have a full valence shell",
        "options": [
            "A type of chemical bond in which one or more electrons are transferred from one atom to another in order for both atoms to have a full valence shell",
            "A type of chemical bond in which two anions combine to form a single compound",
            "None of these",
            "A type of chemical bond that involves the sharing of one or more electron pairs between atoms",
            "A type of electrostatic interaction between polar molecules that occurs when a hydrogen atom binds to a highly electronegative atom such as oxygen, fluroine, or nitrogen"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 679,
        "question": "Individuals with Klinefelter syndrome are phenotypically male, but experience reduced sperm production and breast development in adolescence. Klinefelter individuals have two X-chromosomes and one Y-chromosome (they are XXY instead of XY). What meiotic error gives rise to this condition?",
        "answer": "Aneuploidy is a chromosomal condition in which there are an abnormal number of chromosomes in the cells of the body. Aneuploidy typically refers to monosomy (one chromosome copy) or trisomy (three chromosome copies), and arises due to nondisjunction during meiosis and gametogenesis. Nondisjuction causes one daughter cell to receive three or four chromatids, and the other to receive one or zero. If this gamete is used to form a zygote, all cells in the resulting offspring will carry the abnormal chromosome number.",
        "suggested_answer": "Aneuploidy",
        "options": [
            "Aneuploidy",
            "Duplication",
            "Translocation",
            "Polyploidy",
            "Monosomy"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 680,
        "question": "Which of the following choices does not cause infertility?",
        "answer": "Infertility is the inability to reproduce. There are many causes for infertility and many unexplained cases. The causes include sexually transmitted diseases, ovulation problems, and low semen quality.",
        "suggested_answer": "Alcohol use",
        "options": [
            "Low semen quality ",
            "Ovulation problems ",
            "Sexually transmitted diseases",
            "Alcohol use"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 681,
        "question": "What is the cell division error that causes trisomy 21, or Down syndrome?",
        "answer": "Trisomy 21, or Down syndrome results when a human individual inherits three copies of chromosome 21, instead of the normal two copies (one maternal and one paternal). This is caused by nondisjunction, or the improper segregation of chromosomes during cell division. In the case of trisomy 21, nondisjunction leads to a failure of chromosome 21 segregation during meiosis (most of the time during anaphase I). This results in either an egg or sperm carrying two copies of chromosome 21 instead of one. The zygote formed by fertilization develops into an individual that has three copies of chromosome 21. Trisomy 21 manifests in physical growth delays, intellectual disabilities, and distinctive facial features. ",
        "suggested_answer": "Nondisjunction",
        "options": [
            "Telomere shortening",
            "Organelle partitioning",
            "Nucleotide deletion",
            "Nondisjunction"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 682,
        "question": "Which statement best describes why life on earth is carbon based? ",
        "answer": "Life is \"carbon-based\" or predominantly carbon because it can form stable bonds with itself, but also with a variety of other types of elements. Electronegativity increases from left to right on the periodic table, but also from bottom to top. While carbon is relatively high and right on the periodic chart, there are still elements like oxygen or fluorine (the most electronegative) that have a great pull for electrons. While carbon makes up a lot of the universe, it pales in comparison to hydrogen which is the most common element (three fourths of the mass of our universe). Therefore ratios do not matter. The polar and nonpolar nature of molecules are important for the functions of life (like membranes), but were it not for the bonding of carbon to itself, the nonpolar molecules would not be able to form. Thus, its bonding versatility is the main reason for life being carbon based.",
        "suggested_answer": "Its bonding versatility",
        "options": [
            "The relationship between carbon and water",
            "Abundance of carbon",
            "Its bonding versatility",
            "The relative ratio of other elements to carbon",
            "The electronegativity of carbon"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 683,
        "question": "What would be the ratio of offspring with yellow fur to offspring with black fur if the unknown parent was actually heterozygous for fur color? ",
        "answer": "If the dog with the unknown genotype is heterozygous, our cross would be: Aa x aa. In this model, A represents the dominant yellow-fur allele and a represents the recessive black-fur allele. We the black furred dog has to be genotype aa because he displays the recessive phenotype. The offspring produced from this cross would be 2 Aa and 2 aa genotypes, based on a punnet square.",
        "suggested_answer": "50% yellow and 50% black",
        "options": [
            "75% yellow and 25% black",
            "100% black",
            "50% yellow and 50% black",
            "100% yellow"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 684,
        "question": "Which of the following theories representing Lamarckian evolution is false?",
        "answer": "The principal of use and disuse suggests that organs you use get bigger while those you don't use get smaller or even disappear entirely. Inheritance of acquired characteristics sugegsts that proficiencies and traits can be passed down to succeeding generations. Lamarckian evolution is largely based on these two ideals.",
        "suggested_answer": "Theory of genetic chance",
        "options": [
            "Principle of use and disuse",
            "Inheritance of acquired characteristics",
            "All of the choices are correct",
            "Theory of genetic chance"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 685,
        "question": "Which of the following is not true of the Golgi apparatus? ",
        "answer": "The Golgi apparatus is derived from the endoplasmic reticulum and consists of a series of flattened sacs. The function of the Golgi apparatus is to package and transport proteins to the cell surface within vesicles, which pinch off from the Golgi. These vesicles then interface with the cell membrane, releasing their contents into the extracellular space or integrating membrane proteins into the cell membrane.",
        "suggested_answer": "It accounts for the difference between the rough and smooth endoplasmic reticulum",
        "options": [
            "It is a derivative of the endoplasmic reticulum",
            "It functions in exocytosis",
            "It accounts for the difference between the rough and smooth endoplasmic reticulum",
            "It sends proteins to the cell surface within vesicles"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 686,
        "question": "Water's ability to create intermolecular hydrogen bonds results in its inability to break up which of the following compounds?",
        "answer": "Nonpolar compounds will not be adequately dissolved in aqueous solutions. Lipids are nonpolar compounds that are mainly insoluble in water. This causes lipids to congregate together, rather than be broken apart in aqueous solutions. Lipids will generally come together to form globs or balls called micelles.",
        "suggested_answer": "Lipids",
        "options": [
            "Ions",
            "Amino acids",
            "Sugars",
            "Lipids"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 687,
        "question": "Which of the following is a key component of a plant's vascular system?",
        "answer": "The vascular system in plants is designed to transport materials (water, nutrients, food) between the roots and shoots. There are two primary types of tissue dedicated to these processes. Xylem transports water and dissolved minerals upward from the roots; phloem transports sugars the products of photosynthesis from where they are synthesized to where they are needed, such as roots and new growth areas of leaves and fruits.",
        "suggested_answer": "Xylem",
        "options": [
            "Xylem",
            "Pericycle",
            "Cuticle",
            "Parenchyma"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 688,
        "question": "Which is not part of an ATP molecule?",
        "answer": "ATP consists of 3 phosphate groups, a ribose sugar, and adenine.",
        "suggested_answer": "threonine",
        "options": [
            "adenine",
            "threonine",
            "alpha phosphate, beta phosphate, and gamma phosphate groups",
            "ribose sugar"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 689,
        "question": "What are the products of photosynthesis?",
        "answer": "The photosynthesis equation can be written as:",
        "suggested_answer": "Glucose and oxygen",
        "options": [
            "Oxygen and carbon dioxide",
            "Carbon dioxide and light",
            "Glucose and oxygen",
            "Water and carbon dioxide",
            "Light and glucose"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 690,
        "question": "Which of the following highlights a key difference between meiosis and mitosis?",
        "answer": "Meiosis includes two divisions, resulting in two pairs of haploid cells, while mitosis only involves a single division.",
        "suggested_answer": "Meiosis involves two divisions, while mitosis involves only one",
        "options": [
            "Meiosis involves two divisions, while mitosis involves only one",
            "All answer choices are correct",
            "There is no metaphase stage in meiosis",
            "Genetic mutations can only occur in meiosis; mitosis always results in identical daughter and parent cells"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 691,
        "question": "Which of the following molecules is not present in DNA?",
        "answer": "Uracil is a nitrogenous base that is only found in single-stranded RNA it is not found in DNA. Thymine pairs with adenine in DNA, whereas in RNA, uracil pairs with adenine.",
        "suggested_answer": "Uracil",
        "options": [
            "Phosphate",
            "Uracil",
            "Thymine",
            "All of these",
            "Deoxyribose sugar"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 692,
        "question": "Which of the following is an endergonic reaction?",
        "answer": "An endergonic reaction requires input of energy; delta G will be positive. Hydrolysis of ATP, cellular respiration, and catabolism (breakdown of a large molecule) are exergonic processes. The Na+/K+ pump is endergonic, as it requires energy. This process is coupled with ATP hydrolysis to allow it to proceed.",
        "suggested_answer": "Na+/K+ pump",
        "options": [
            "Na+/K+ pump",
            "Hydrolysis of ATP",
            "Cellular respiration",
            "Catabolism"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 693,
        "question": "Which of the following are referred to as the major sites of photosynthesis in most plants?",
        "answer": "The leaves are the major sites of photosynthesis in most plants. The chloroplasts are located in the mesophyll interior tissues of the leaves and are the primary sites of photosynthesis.",
        "suggested_answer": "Leaves",
        "options": [
            "Leaves",
            "Stems",
            "Flowers",
            "Roots"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 694,
        "question": "Which of the following is the term used for the model that describes the cell membrane as a mix of proteins bobbing about in a fluid bilayer of phospholipids?",
        "answer": "The fluid mosaic model describes the mosaic structure of the cell membrane. According to this model, the cell membrane is composed of a variety of protein molecules randomly bobbing around in a sea of phospholipids. Due to phospholipid polarity, they naturally form a bilayer in accordance with their dual hydrophobic (tails) and hydrophilic (heads) natures.",
        "suggested_answer": "Fluid mosaic model",
        "options": [
            "Mosaic membrane model",
            "Fluid mosaic model",
            "Fluid protein model",
            "Fluid membrane model"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 695,
        "question": "Which of the following parts of the female body is where the fetus is surgically removed during caesarian sections?",
        "answer": "A caesarian section is a method to deliver the fetus surgically. During the procedure, an incision is made in the mother\u2019s abdomen and uterus to remove the fetus. Caesarian sections are used in the case of pregnancy and labor complications, but they can also be undertaken electively.",
        "suggested_answer": "Uterus",
        "options": [
            "Vagina",
            "Birth canal",
            "Uterine wall",
            "Uterus",
            "None of these"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 696,
        "question": "If two parents are heterozygous for a trait and they have children, what is the percentage of the children that are heterozygous for the trait?",
        "answer": "For simplicity, we will assign the letter \"a\" for the gene of interest. Thus, the heterozygous genotype is Aa. You may sketch a punnet square of the cross: Aa x Aa to help illustrate that the combinations result in 50% chance of heterozygous offspring.",
        "suggested_answer": "50%",
        "options": [
            "100%",
            "50%",
            "75%",
            "25%"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 697,
        "question": "Which of these is not a major function of proteins in the body?",
        "answer": "Though proteins may be found on the cellular membrane, they are not a primary component. The cell membrane is known as the \"phospholipid bilayer,\" as it is primarily composed of a double layer of lipids. Proteins may be attached to the surface or fully integrated into the bilayer, and serve as a means of signaling, transport, and adhesion.",
        "suggested_answer": "Primary component of cellular membrane",
        "options": [
            "Transport biological macromolecules",
            "Primary component of cellular membrane",
            "Facilitate muscle contraction",
            "Catalyze cellular reactions",
            "Send biological signals to distant parts of the body"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 698,
        "question": "What is the function of lateral meristems?",
        "answer": "In addition to growing in height, woody plants also grow in thickness. This is the function of lateral meristems. Lateral meristems are comprised of the vascular cambrium, and by cork cambrium that form vascular cylinders. The vascular cambrium adds layers of secondary xylem and phloem (wood), whereas the cork cambrium replaces the outer epidermis with a thicker and tougher layer called periderm.",
        "suggested_answer": "Provide secondary growth in woody plants",
        "options": [
            "Maintain water homeostasis",
            "Stimulate root hair growth",
            "Provide secondary growth in woody plants",
            "Provide nutrients to apical meristems"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 699,
        "question": "What type of bonding occurs between the two parallel strands of nucleic acids in DNA?",
        "answer": "The bonding that occurs between two parallel strands of nucleic acids in DNA is hydrogen bonding. As you know, hydrogen bonding occurs between molecules containing fluorine, nitrogen and oxygen with other fluorine, nitrogen and oxygen atoms. This is a fairly weak bond but there are so many hydrogen bonds along a strand of DNA making the attachment between the two quite strong, but the two strands can still be separated as needed (during replication and transcription). Adenine and thymine form two hydrogen bonds, while cytosine and guanine form three hydrogen bonds.",
        "suggested_answer": "Hydrogen bonding",
        "options": [
            "Covalent bonding",
            "Ionic bonding",
            "Single bond",
            "Double bond",
            "Hydrogen bonding"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 700,
        "question": "What causes air to enter human lungs?",
        "answer": "Increasing the volume of a container (in this case, the lungs) while keeping the contents (air molecules) the same will decrease the pressure. If no barrier is present (as when holding your breath), pressure will tend to equalize between areas of differing pressure. In order to equalize the pressure, air molecules from outside the body rush into the expanded lungs. The concentration of oxygen does not impact inhalation, and cilia are not used to inhale.",
        "suggested_answer": "The contraction of the diaphragm increases the volume of the lungs, decreasing the pressure relative to the air outside the body.",
        "options": [
            "The contraction of the diaphragm increases the volume of the lungs, decreasing the pressure relative to the air outside the body.",
            "Cillia in the trachea guide the flow of air into the lungs.",
            "The decreased concentration of in the lungs decreases the density of that air, and denser air outside the body \"sinks\" into the lungs.",
            "Air diffuses into the lungs due to the decreased content relative to the air outside our body.",
            "Smooth muscle in the trachea guides the flow of air into the lungs."
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 701,
        "question": "Which one of the following scientists carried out experiments using pea plants to discover the fundamental principles of genetics?",
        "answer": "Gregor Mendel carried out experiments using pea plants to discover the fundamental principles of genetics. Louis Pasteur was a microbiologist who is known for his work with vaccines and pasteurization. Both Francis Crick and James Watson are credited for their discovery of the structure of DNA.",
        "suggested_answer": "Gregor Mendel",
        "options": [
            "Louis Pasteur",
            "James Watson",
            "Gregor Mendel",
            "Francis Crick"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 702,
        "question": "A small molecule passes through the phospholipid bilayer without consuming ATP in the process. This is an example of which of the following?",
        "answer": "Passive transport occurs when a molecule or ion moves down its concentration gradient and therefore requires no energy. More specifically, in facilitated diffusion, a type of passive transport, a transport protein speeds the movement of water or a solute across a membrane and down its concentration gradient. Active transport, on the other hand, uses energy or ATP to move solutes against their concentration gradients.",
        "suggested_answer": "Passive transport; no energy is required when a molecule moves down its concentration gradient",
        "options": [
            "Passive transport; no energy is required when a molecule moves up its concentration gradient",
            "Active transport; no energy is required when a molecule passes through the cell membrane via exocytosis ",
            "Passive transport; no energy is required when a molecule moves down its concentration gradient",
            "Active transport; no energy is required when a molecule moves down its concentration gradient",
            "Active transport; no energy is required when a molecule moves up its concentration gradient "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 703,
        "question": "What is true regarding temporal isolation?",
        "answer": "Temporal isolation is a pre-zygotic barrier to reproduction. In instances of temporal isolation, fertile individuals do not meet one another, thereby isolating them reproductively. This includes distinct habitats, physical barriers, and different timing of sexual maturity.",
        "suggested_answer": "It is a barrier that prevents fertile individuals from meeting ",
        "options": [
            "It is a post-zygotic barrier to reproduction ",
            "It is a barrier resulting from different mating behaviors ",
            "It is a barrier that prevents fertile individuals from meeting ",
            "Resulting zygotes are inviable"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 704,
        "question": "Charles Darwin described four key observations pertaining to natural selection from his voyage around the world. Which of the following is NOT one of Darwin\u2019s observations of nature?",
        "answer": "During his travels, Darwin essentially developed the fundamental principles of evolution. Included in these observations were that traits are inherited from parent to offspring, members of a population exhibit genetic diversity (numerous traits), and that organisms are capable of producing more offspring than the environment can support. The fourth observation was that, owing to a lack of food or other resources, many offspring do not survive. Together, these observations helped shape Darwin's theories of natural selection and biological fitness to explain the trends that he had seen.",
        "suggested_answer": "Individuals of a population evolve through interaction with their environment",
        "options": [
            "Members of a population often vary greatly in their traits",
            "Individuals of a population evolve through interaction with their environment",
            "Traits are inherited from parents to offspring",
            "All species are capable of producing more offspring than their environment can support"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 705,
        "question": "Which of the following organelles directly requires oxygen to maintain optimal function?",
        "answer": "The mitochondria are membrane-bound organelles involved in the process of cellular respiration. Specifically, the Krebs cycle takes place in the matrix of the mitochondria and the electron transport chain takes place along the inner mitochondrial membrane. During aerobic respiration, oxygen is used as the final electron receptor of the electron transport chain and generates water as a byproduct. Without oxygen, the mitochondria cannot perform oxidative phosphorylation, and the cell must rely on glycolysis for energy.",
        "suggested_answer": "Mitochondria",
        "options": [
            "Ribosomes",
            "Nucleolus",
            "Smooth endoplasmic reticulum",
            "Plasmids",
            "Mitochondria"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 706,
        "question": "Which of the following type(s) of cell movement are involved in the development of the neural plate into the neural tube?",
        "answer": "During the formation of the neural tube, the neural plate a thick layer of differentiated columnar cells change shape and undergo certain cell movements. The formation of the neural tube is called primary neurulation. In a complex process, the cells of the neural plate change shape, invaginating the cell layer to form the neural groove. Convergence moves the cells of the neural folds towards the developing neural groove. Eventually, the two edges of the neural plate touch and join together to form the neural tube. ",
        "suggested_answer": "Convergence and invagination",
        "options": [
            "Invagination",
            "Convergence",
            "Convergence and invagination",
            "Neither convergence nor invagination"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 707,
        "question": "What type of bonding is found in a disulfide bridge?",
        "answer": "Disulfide bridges are formed between the sulfhydryl (-SH) groups of two cysteine residues. These bonds are covalent, and are important in stabilizing the tertiary structure of many proteins.",
        "suggested_answer": "Covalent",
        "options": [
            "Hydrogen",
            "Ionic",
            "Covalent",
            "London dispersion forces"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 708,
        "question": "The nucleolus __________.",
        "answer": "The nucleolus is a subdivision of the nucleus; thus, it is only found in eukaryotes. It is the site of ribosome assembly. The nucleolus is made of RNA and proteins.",
        "suggested_answer": "is a part of the nucleus responsible for assembling ribosomes",
        "options": [
            "is only found in prokaryotes",
            "is a part of the nucleus responsible for assembling ribosomes",
            "None of the other answer choices is true.",
            "is found in prokaryotes and eukaryotes",
            "contains all the DNA of the cell"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 709,
        "question": "Which of the following best describes where epithelial tissue is found in the human body?",
        "answer": "Epithelial tissue is one of the major types of tissues in the human body. Epithelial cells function in secretion, absorption, protection, and transport. As a result, epithelial tissue is located at the interface of structures and tissues including the lining of body cavities, the surface of blood vessels, and the surface of organs.",
        "suggested_answer": "All of these",
        "options": [
            "Lining of body cavities",
            "Surface of organs",
            "All of these",
            "Surface of blood vessels"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 710,
        "question": "Which of the following may result from damage to the front lobe?",
        "answer": "The brain is often divided into four lobes based on anatomy and physiology: the frontal lobe, parietal lobe, occipital lobe, and temporal lobe. Each lobe controls various aspects of cognition and motor skills. The frontal lobe is associated with reasoning, speech, movement, and emotions. The parietal lobe is associated with orientation and recognition. The occipital lobe is associated with visual processing. The temporal lobe is associated with auditory processing and memory.",
        "suggested_answer": "Impairment of speech",
        "options": [
            "Auditory impairment",
            "Impairment of speech",
            "Visual impairment",
            "Numbness in the extremities",
            "Spastic muscle activity"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 711,
        "question": "Where does fertilization typically take place in the female?",
        "answer": "In the majority of cases, fertilization occurs in the fallopian tubes of the female. The egg is released from the ovary and travels into the fallopian tube during ovulation. Sperm travel to the fallopian tube to fertilize the egg, creating a zygote. The zygote begins to divide, forming a blastocyst before it reaches the uterus where it becomes embedded in the endometrium via the process of implantation. Certain pregnancy complications can arise if the blastocyst implants while still in the fallopian tube, resulting in ectopic pregnancy.",
        "suggested_answer": "Fallopian tubes",
        "options": [
            "Ovary",
            "Uterus",
            "Cervix",
            "Fallopian tubes"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 712,
        "question": "After a large meal, an individual's blood sugar increases. This signals an increase in which of the following hormones?",
        "answer": "After a large meal the body must quickly remove glucose from the blood. Insulin facilitates the entry of glucose into a variety of body cells, particularly hepatocytes in the liver. Once inside the cell, glucose is either stored as a polymer (glycogen) or reduced into carbon dioxide and water to produce a usable form of energy (ATP) for the cell. Glycogen is stored in the liver until blood glucose levels become low, at which point it can be converted back to glucose and released to maintain homeostasis. This process, known as glycogenolysis, is stimulated by the release of glucagon. Together, insulin and glucagon create a negative feedback loop to regulate glucose in the blood.",
        "suggested_answer": "Insulin",
        "options": [
            "Epinephrine",
            "Adrenocorticotropic hormone (ACTH)",
            "Glucagon",
            "Insulin",
            "Thyroxine (T4)"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 713,
        "question": "The division of a cell's cytoplasm is called __________.",
        "answer": "Cytokinesis is the division of the cytoplasm. Mitosis, also known as karyokinesis, is the division of the nucleus. Note that cytokinesis follows mitosis, and begins during late anaphase.",
        "suggested_answer": "cytokinesis",
        "options": [
            "cellular cleaving ",
            "cytokinesis",
            "mitosis",
            "karyokinesis",
            "cytoplasmic separation"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 714,
        "question": "A botanist finds a pea plant growing in his backyard and wants to know its genotype for color, knowing that the allele for green is dominant to the allele for yellow. He breeds the unknown plant with a known homozygous dominant pea plant. If the F2 generation is three-fourths green and one-fourth yellow, what was the genotype of the unknown plant?",
        "answer": "The possible genotypes of the unknown plant are GG, Gg, or gg. To create the F1 generation, it is crossed with a plant with genotype GG.",
        "suggested_answer": "Homozygous recessive",
        "options": [
            "Homozygous dominant",
            "Either homozygous dominant or heterozygous",
            "Impossible to tell",
            "Heterozygous",
            "Homozygous recessive"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 715,
        "question": "Action potentials move down myelinated axons via saltatory conduction. This is because __________.",
        "answer": "The tiny gaps in between myelin sheaths are referred to as nodes of Ranvier. The action potential will jump from node to node as it moves down the axon.",
        "suggested_answer": "they jump from gap to gap in between the myelin sheaths",
        "options": [
            "additional impulses boost their speed periodically",
            "they jump from the middle of the gaps to the middle of the myelin sheaths",
            "they jump from gap to gap in between the myelin sheaths",
            "they speed up dramatically in areas with more myelin wrapping, depending on the thickness of the sheath"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 716,
        "question": "Which of the following eukaryotic cellular processes does not occur in bacterial cells?",
        "answer": "Similar to eukaryotes, bacterial cells are in accordance with the central dogma of molecular biology. The flow of genetic information involves replication, transcription, and translation. Bacterial cells have to replicate their genetic material (replication), undergo transcription to convert DNA to mRNA, and go through translation to produce proteins from the mRNA.",
        "suggested_answer": "Mitosis",
        "options": [
            "Translation",
            "Replication",
            "Transcription",
            "Mitosis"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 717,
        "question": "In mitosis, which is the second phase in which spindle fibers move the chromosomes to the center of the cell where they are aligned?",
        "answer": "Metaphase is the second phase of mitosis in which spindle fibers move the chromosomes to the center of the cell where they are aligned. Prophase is the first phase of mitosis, anaphase is the third phase, and telophase is the fourth and final phase.",
        "suggested_answer": "Metaphase",
        "options": [
            "Prophase",
            "Telophase",
            "Metaphase",
            "Anaphase"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 718,
        "question": "Which element has 17 electrons?",
        "answer": "Since atoms are neutral, the number of electrons must equal the number of protons (to balance the negative and positive charges). Thus the element with 17 electrons also has 17 protons. The number of protons is equal to the atomic number of the element. Use the periodic table to find the element with atomic number 17, chlorine. Note that ions are not neutral, thus they have unequal numbers of protons and electrons. Also, isotopes of the same element contain differing numbers of neutrons, which also affects the weight of the isotope.",
        "suggested_answer": "Chlorine",
        "options": [
            "Silicon",
            "Sulfur",
            "Chlorine",
            "Argon",
            "Phosphorus"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 719,
        "question": "Which pituitary hormone is responsible for regulating the water volume in blood?",
        "answer": "Antidiuretic hormone (ADH) is secreted by the posterior pituitary and acts on the kidney tubules, increasing the water retention in blood and decreasing the water volume in urine. It works by increasing the number of aquaporins (water channels in the plasma membrane) in the collecting duct of the kidney nephrons. Water flows down its osmotic gradient out of tubule, and into the blood for circulation.",
        "suggested_answer": "Antidiuretic hormone",
        "options": [
            "Glucagon",
            "Antidiuretic hormone",
            "Aldosterone",
            "Oxytocin",
            "Insulin"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 720,
        "question": "__________ is released in response to low blood sugar levels, while __________ is released in response to high blood sugar levels.",
        "answer": "Insulin is responsible for lowering blood sugar levels, and is therefore released when the body's blood sugar levels are too high. Glucagon is released in response to low blood sugar levels, and acts to increase sugar concentrations.",
        "suggested_answer": "glucagon . . . insulin",
        "options": [
            "thyroid hormones . . . glucagon",
            "insulin . . . thyroid hormones",
            "glucagon . . . insulin",
            "insulin . . . glucagon"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 721,
        "question": "The most abundant lipids in biological membranes are __________.",
        "answer": "Phospholipids are the most abundant because their structure makes it possible for them to form membranes since they have hydrophilic and hydrophobic regions. In the phospholipid bilayer of a cell, the hydrophilic heads are exposed to the intracellular and extracellular compartments, which contain cytosol, and extracellular fluid, both of which are aqueous. The tails of the phospholipids are hydrophobic, and thus \"hide\" from the aqueous environments on either side of the cell membrane. This structure allows small, uncharged, and fat-soluble molecules to diffuse across the membrane. ",
        "suggested_answer": "phospholipids",
        "options": [
            "fats",
            "hydrolipids",
            "sterols",
            "phospholipids"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 722,
        "question": "Amino acids possess both __________ and __________.",
        "answer": "Amino acids are made up of carboxyl and amino groups. Hence their name, amino acids describes the functional groups found in all proteins, regardless of their R-groups. Carboxyl groups are also known as carboxylic acid groups. Glycerol is found in lipids, specifically fats where it is linked to fatty acid chains. Hydroxyl groups are also known as alcohol groups and are not present in all amino acids, although, some R-groups contain hydroxyl functional groups. ",
        "suggested_answer": "carboxyl . . . amino groups",
        "options": [
            "hydroxyl . . . amino groups",
            "glycerol . . . carbonyl groups",
            "glycerol . . . amino groups",
            "carboxyl . . . amino groups"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 723,
        "question": "Which of the given choices is an example of a mutualistic relationship?",
        "answer": "Mutualistic relationships exist when both organisms benefit from a shared relationship. In the correct answer choice, plants provide energy to the fungi through carbohydrates and the fungi provides nitrogenous compounds to the plant.",
        "suggested_answer": "Plant roots and fungi",
        "options": [
            "Barnacle and a mollusk shell",
            "Plant roots and fungi",
            "Human blood vessels and S. mansoni (worm)",
            "Cat and mouse"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 724,
        "question": "The presence of __________ in the brain indicates a modification to __________.",
        "answer": "Folds are present in the human brain due to the rapid evolution of brain-related tasks while the skull shape and size remained relatively constant; therefore, folds are present as modifications to limited surface-area-to-volume ratios in the skull cavity.",
        "suggested_answer": "folds. . . surface-area-to-volume ratios",
        "options": [
            "multiple lobes. . . surface-area-to-volume ratios",
            "the corpus callosum. . . circulation patterns",
            "white matter. . . electro-chemical gradients",
            "folds. . . surface-area-to-volume ratios",
            "gray matter. . . sunlight exposure"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 725,
        "question": "Skin can be stretched and pulled with great force without breaking or tearing. Which cellular junction is most responsible for keeping skin resilient and intact?",
        "answer": "Desmosomes are the primary junction that helps keep tissues that are under a lot of stress, like skin, intact. Although tight junctions typically accompany desmosomes, their primary function is to prevent substances from passing between cells. Gap junctions are used for communication between cells, and intercalated discs are only found in cardiac muscle tissue.",
        "suggested_answer": "Desmosomes",
        "options": [
            "Tight junctions",
            "Desmosomes",
            "Intercalated discs",
            "Gap junctions"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 726,
        "question": "A population of wolves is living in an area when a tornado strikes and kills most of the wolves. The population of the wolves has been greatly reduced. Which of the following best describes the impact of the disaster on the genetic makeup of the population of wolves?",
        "answer": "The Bottleneck effect happens when an event occurs that drastically reduces the population of a species. The remaining individuals most likely do not represent the genetics of the population before the catastrophic event, since it becomes a much smaller sample size. ",
        "suggested_answer": "Bottleneck effect",
        "options": [
            "Bottleneck effect",
            "Gene flow",
            "Cannot be determined from the information",
            "Founder effect",
            "Hardy-Weinberg principle"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 727,
        "question": "With respect to action potentials, what is responsible for depolarization? ",
        "answer": "First it is important to realize that there is a higher concentration of potassium ions inside of the cell and a higher concentration of sodium ions outside of the cell. When depolarization occurs, sodium ion channels open. With this information, we can get rid of any answer choices that do not mention sodium ions. Now we must see if sodium ions enter or exit the cell. Since there is a higher concentration of sodium ions outside of the cell, sodium ions will enter the cell by going down the concentration gradient. ",
        "suggested_answer": "Sodium ions enter the cell",
        "options": [
            "Calcium ions enter the cell",
            "Potassium ions enter the cell",
            "Sodium ions exit the cell",
            "Sodium ions enter the cell",
            "Potassium ions exit the cell"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 728,
        "question": "Which of these hormones is responsible for the secretion of testosterone in males?",
        "answer": "Luteinizing hormone is responsible for the secretion of testosterone from leydig cells in the testes.",
        "suggested_answer": "Luteinizing hormone (LH)",
        "options": [
            "Human growth hormone (hGH)",
            "Follicle stimulating hormone (FSH)",
            "Oxytocin",
            "Luteinizing hormone (LH)"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 729,
        "question": "Which of the following is an example of an atavistic trait?",
        "answer": "An atavistic trait is a rare appearance of an ancestral structure in an evolved organism. Since dolphins evolved from four-legged mammals, a dolphin born with a pair of hind flippers shows is an atavism, an evolutionary throwback.",
        "suggested_answer": "Dolphins (very rarely) born with hind flippers, resulting from their four-limbed ancestors.",
        "options": [
            "Dolphins (very rarely) born with hind flippers, resulting from their four-limbed ancestors.",
            "A rhino's horn, which evolved separately from the horn structures of other animals such as rams or oxen.",
            "A crow's ability to recognize human faces, possibly as a result of both species having evolved together.",
            "The human appendix, which has taken on a modified reduced function from its ancestral form.",
            "Humans often born with the ability to wiggle their ears, resulting from their mammalian ancestors who would orient their ears for better hearing."
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 730,
        "question": "What do somites, formed from mesoderm during somitogenesis, go on to form? ",
        "answer": "Somites go on to form skeletal muscle, vertebrae, and the dermis. ",
        "suggested_answer": "All of these structures",
        "options": [
            "Skeletal muscle",
            "None of these structures",
            "Vertebrae",
            "The dermis",
            "All of these structures"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 731,
        "question": "Where does the citric acid (Krebs) cycle occur in human cells?",
        "answer": "The citric acid (Krebs) cycle takes place in the mitochondrial matrix. The Krebs cycle involves using acetyl-CoA as a substrate to produce high energy electron carriers and to later participate in electron transport, ultimately yielding . Glycolysis is the process by which glucose is split into two molecules of pyruvate, and occurs in the cytoplasm. The electron transport chain performs its function in the inner mitochondrial membrane. The nucleus and ribosomes are not part of the citric acid cycle.",
        "suggested_answer": "Mitochondrial matrix",
        "options": [
            "Mitochondrial matrix",
            "Nucleus",
            "Inner mitochondrial membrane",
            "Ribosomes",
            "Cytoplasm"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 732,
        "question": "Which macromolecule is responsible for the presence of the ammonia byproduct during digestion and absorption?",
        "answer": "In order to answer this question, visualize the macromolecules as their monomers. The chemical formula for ammonia is , so the monomer will need to include nitrogen. Carbohydrates are chains of monosaccharides and are only composed of carbon, hydrogen, and oxygen. Fatty acids are nonpolar molecules, and are composed of only carbon and hydrogen. Proteins are composed of amino acid monomers. Amino acids have an amino terminus, which includes a nitrogen atom. As a result, only proteins have the nitrogen atom which can be used in the byproduct of ammonia.",
        "suggested_answer": "Proteins",
        "options": [
            "Fatty acids",
            "Proteins",
            "Carbohydrates",
            "All macromolecules create an ammonia byproduct"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 733,
        "question": "Based on the passage, where is the interaction between the MHC II molecule and the particular antigen occur? ",
        "answer": "According to the passage, after the MHC II molecules are fully synthesized in the endoplasmic reticulum, they are transported to the endosome. Foreign molecules are transported into the cell where they are degraded in the endosome as well. From there, the degraded pathogen interact with the MHC II molecule. ",
        "suggested_answer": "II only",
        "options": [
            "I only",
            "I, II, and III",
            "II only",
            "III only",
            "II and III"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 734,
        "question": "Which of the following structures would be found in a tracheophyte, but not in a bryophyte?",
        "answer": "All plants can be classified as either bryophytes or tracheophytes. Plants that contain transport vessels (xylem and phloem) are tracheophytes, while those without transport vessels are bryophytes. All plants contain cell walls and chloroplasts, but only a tracheophyte would contain xylem. Plasmids are structures that are almost exclusively found in bacteria or protozoans.",
        "suggested_answer": "Xylem",
        "options": [
            "Plasmids",
            "Chloroplasts",
            "Cell walls",
            "Xylem"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 735,
        "question": "What are the distinct, threadlike structures that contain the genetic information that is passed from one generation of cells to the next?",
        "answer": "The chromosomes are the threadlike structures that contain genetic information. Rough endoplasmic reticulum is involved in protein synthesis. Smooth endoplasmic reticulum is involved in lipid synthesis. The Golgi apparatus is involved in modification and delivering of proteins within the cell and for secretion outside the cell.",
        "suggested_answer": "Chromosomes",
        "options": [
            "Rough endoplasmic reticulum",
            "Chromosomes",
            "Golgi apparatus",
            "Smooth endoplasmic reticulum"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 736,
        "question": "In forest A, moss 1 only grows above the shrubbery, while moss 2 grows below the shrubbery. In forest B (similar to forest A), moss 2 grows both above and the shrubbery; here, moss 1 is non-existent. Which of the following terms refers to the area moss 2 occupies in forest A?",
        "answer": "An ecological niche is the sum of a species\u2019 use of its environment, including both biotic and abiotic factors. Resource partitioning is the differentiation of niches; it is impossible for two species to have the same niche and use the same resources. Resource partitioning allows for differentiation of niches between similar species so they can coexist. A fundamental niche is described in forest B, where moss 2 grows both above and below the shrubbery. It is the niche that could be potentially occupied by a species. The realized niche is the term that refers to the area of moss 2 in forest A. The realized niche is the portion of the fundamental niche that an organism actually occupies. We know that moss 2 can potentially occupy areas above and below shrubbery, but in forest A, it only occupies the area below the shrubbery (its realized niche). ",
        "suggested_answer": "Realized niche",
        "options": [
            "Ecological niche",
            "Resource partitioning ",
            "Realized niche",
            "Character displacement ",
            "Fundamental niche"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 737,
        "question": "Which of the following is not true regarding photosynthesis?",
        "answer": "In photosynthesis, water, carbon dioxide, and energy in the form of sunlight are inputs, and the outputs are glucose and oxygen. A nonspontaneous reaction is one that will not proceed without the net input of energy (in this case, sunlight).",
        "suggested_answer": "Water is a product",
        "options": [
            "Water is a product",
            "Carbon dioxide is a reactant",
            "Glucose is a product",
            "It is a nonspontaneous reaction"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 738,
        "question": "Which of these does not contribute to the termination of RNA transcription in bacteria?",
        "answer": "The sigma factor is an important part of initiation for prokaryotic transcription. Once transcription has been initiated, however, the sigma factor is released during elongation. RNA polymerase synthesizes the RNA product until it is interrupted in one of two ways. In rho-dependent termination, a rho factor protein interferes with RNA polymerase binding and causes it to release the DNA strand. In rho-independent termination, structural features of the DNA cause RNA polymerase to become detached. The structures include hairpin loops, which generate steric hindrance, and adenine-rich sequences, which lead to weak binding of the RNA product to the DNA template.",
        "suggested_answer": "Reattachment of the sigma factor",
        "options": [
            "Disruption of uracil-adenine RNA-DNA hybrid",
            "Rho factor",
            "Hairpin loops",
            "Reattachment of the sigma factor"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 739,
        "question": "A developing cell can pass the G1 checkpoint in the cell cycle if sufficient conditions are present for its further growth. This process is regulated by a number of proteins, including cyclins and their associated kinases. Imagine that a cell is arrested at the G1 checkpoint and never proceeds further. Which of these statements properly describes the situation?",
        "answer": "The stages of the cell cycle begin with G1, during which the cell grows and prepares for chromosome replication by synthesizing proteins and cellular structures. The chromosomes do not actually duplicate themselves until the next step, the S phase. After the S phase comes G2, another resting/growth period, and if the cell is properly developed it can then finally enter mitosis. If the cell stops at the G1 checkpoint, it has been arrested before it can enter the S phase and its chromosomes will be unable to replicate.",
        "suggested_answer": "The cell has not yet replicated its chromosomes, and will not be able to do so because it cannot enter the S phase",
        "options": [
            "The cell has already undergone prophase of mitosis, but will not enter metaphase",
            "The cell has not yet replicated its chromosomes, and will not be able to do so because it cannot enter the S phase",
            "The cell has already replicated its chromosomes, but will not enter mitosis",
            "The cell has already replicated its chromosomes, but will not proceed into the S phase"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 740,
        "question": "Which of the following is produced by stamens in angiosperms?",
        "answer": "Stamens are a part of the male reproductive system in angiosperms. Stamens have a filament and an anther, which is attached to the top of the filament. Anthers produce pollen through the process of meiosis.",
        "suggested_answer": "Pollen",
        "options": [
            "Ovules",
            "Seeds",
            "Sap",
            "Pollen"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 741,
        "question": "Which of the following is an outcome of artificial selection?",
        "answer": "Artificial selection is a process by which humans have modified species over many generations by selecting and breeding individuals that possess desired traits. The result of such a selection is a narrowing of the traits originally present from its ancestors. The goal of artificial selection is generally to make certain desired traits fixed in the population, with an allele frequency of 100%. These traits are relatively arbitrary, and may be wild type, recessive, advantageous, or disadvantageous. The key factor is a decrease in genetic variability in a small population.",
        "suggested_answer": "Artificial selection can result in dramatic changes to a species in a relatively short period of time",
        "options": [
            "Artificial selection increases the compatibility between organisms and their environment",
            "Artificial selection increases the variation of traits within a population ",
            "Artificial selection can result in dramatic changes to a species in a relatively short period of time",
            "Artificial selection tends to favor wild type traits"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 742,
        "question": "Insulin helps regulate blood sugar at homeostatic levels by stimulating which of the following processes?",
        "answer": "Insulin works by stimulating adipocytes (fat cells), hepatocytes (liver cells), and skeletal muscle to translocate glucose transporter-rich vesicles to the cell membrane. This allows the diffusion of glucose from the blood into these tissue types after a carbohydrate-rich meal. Without insulin, even if an individual has high blood glucose levels, these three cell types would essentially be deprived of the circulating glucose.",
        "suggested_answer": "Promoting the entry of glucose into cells",
        "options": [
            "Stimulating the breakdown of glycogen to glucose",
            "Promoting the entry of glucose into cells",
            "Activating hormone production in the posterior pituitary gland",
            "Stimulating the release of glucose from the liver"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 743,
        "question": "Enzymes such as trypsin and chymotrypsin in the small intestine break __________ into __________.",
        "answer": "Trypsin and chymotrypsin belong to a family of enzymes that are \"proteolytic\". This means that they target proteins and break them down into their building blocks called amino acids.",
        "suggested_answer": "proteins . . . amino acids",
        "options": [
            "proteins . . . amino acids",
            "carbohydrates . . . diasaccharides",
            "carbohydrates . . . monosaccharides",
            "fats . . . lipids",
            "nucleic acids . . . nucleotides"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 744,
        "question": "Where does the Krebs cycle take place?",
        "answer": "The Krebs cycle takes place in the mitochondrial matrix. The products of glycolysis, which takes place in the cytosol, are brought to the mitochondria for the Krebs cycle and electron transport chain. The electron carriers generated during the Krebs cycle (NADH and FADH2) are then used in the electron transport chain, which takes place on the inner membrane of the mitochondria.",
        "suggested_answer": "Mitochondrial matrix",
        "options": [
            "Mitochondrial matrix",
            "On the inner membrane of the mitochondria",
            "In the cytosol",
            "Intermembrane space of the mitochondria"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 745,
        "question": "The shape of an animal cell is determined by which of the following?",
        "answer": "The cytoskeletion determines cell shape. The cytoskeleton consists of three different types of filamentous proteins: microfilaments, intermediate filaments, and microtubules. Elements of the cytoskeleton can interact with the cell membrane and cell junctions to alter the cell's overall structure.",
        "suggested_answer": "Cytoskeleton",
        "options": [
            "None of these answers",
            "Cytoskeleton",
            "Plasma membrane",
            "Cell wall",
            "Cholesterol"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 746,
        "question": "Oxpeckers eat bugs and parasites off zebras. Additionally, when a predatory animal is nearby, the oxpeckers make noise, alerting zebras to the presence of predators. This relationship between the oxpecker and zebra can best be described as",
        "answer": "The zebra benefits because the oxpecker rids the zebra of harmful parasites and the zebra receives a warning from the oxpeckers when predators approach; the oxpecker benefits because the parasites serve as a food source. In this scenario, both organisms benefit from the relationship. Commensalism is a relationship in which one organism benefits and the other organism is neither benefited nor harmed. Mutualism is a relationship in which both organisms benefit. Competition is a relationship where both organisms are harmed, as they seek the same limited resource. Predation is a relationship in which one organism hunts the other organism for food. ",
        "suggested_answer": "mutualistic",
        "options": [
            "commensalistic",
            "predatory",
            "competition",
            "mutualistic"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 747,
        "question": "During which phase of mitosis do sister chromatids separate?",
        "answer": "During anaphase, the third stage of mitosis, the spindle fibers cause sister chromatids to break apart, and they are subsequently moved to opposite sides of the cell.",
        "suggested_answer": "Anaphase",
        "options": [
            "Prophase",
            "Telophase",
            "Metaphase",
            "Anaphase"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 748,
        "question": "Which of the following are characteristics of companion cells in vascular plants?",
        "answer": "Companion cells are a type of specialized parenchyma cells in vascular plants. They contain a large number of ribosomes and mitochondria and perform cellular functions for sieve-tube elements, which they are connected to by plasmodesmata.",
        "suggested_answer": "They have a large number of ribosomes and mitochondria and are a specialized type of parenchyma cells",
        "options": [
            "They have a large number of ribosomes and mitochondria and are a specialized type of parenchyma cells",
            "They do not contain a nucleus and are a specialized type of parenchyma cells",
            "They do not contain a nucleus",
            "They have a large number of ribosomes and mitochondria"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 749,
        "question": "Which of the following helps cool the body temperature?",
        "answer": "When the body temperature is too high, peripheral vasodilation can help exchange heat from the body to the environment. Warm blood from the center of the body is pumped to the extremities, which have a high surface area. The surface area is used to allow the heat from the blood to dissipate before it returns to the center of the body.",
        "suggested_answer": "Peripheral vasodilation",
        "options": [
            "Peripheral vasoconstriction",
            "Peripheral vasodilation",
            "Shivering",
            "None of these",
            "Hyperventilation"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 750,
        "question": "A given molecule has a structure containing a carbon skeleton of four fused rings. Which of the following is it most likely to be?",
        "answer": "Cholesterol is class of lipids known as steroids. All steroids have four fused carbon rings in their structures. Fats, fatty acids and phospholipid do not contain any ring structures. Fatty acids are components of both fats and phospholipids and are long chains of hydrocarbons. Phospholipids contain two fatty acids attached to a polar head and a phosphate group.",
        "suggested_answer": "Cholesterol",
        "options": [
            "Phospholipid",
            "Fat",
            "Fatty acid",
            "Cholesterol"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 751,
        "question": "Why does fluid reenter the capillary from the interstitium at the venule end of the capillary bed?",
        "answer": "Hydrostatic pressure is the force of the fluid volume against a membrane, while osmotic pressure is related to the protein concentration on either side of a membrane pulling water toward the region of greater concentration.",
        "suggested_answer": "Hydrostatic pressure is lower at the venule end of the capillary",
        "options": [
            "Osmotic pressure is greater at the venule end of the capillary",
            "Hydrostatic pressure is lower at the venule end of the capillary",
            "Hydrostatic pressure is greater at the venule end of the capillary",
            "Osmotic pressure is lower at the venule end of the capillary"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 752,
        "question": "Polymers of amino acids are __________.",
        "answer": "Polypeptides are are polymers of amino acids. Proteins consist of one or more polypeptide chains folded in a certain shape. Polysaccharides are polymers of monosaccharides and do not contain amino acids. Cellulose is a polysaccharide that is a major component of plant cell walls.",
        "suggested_answer": "polypeptides",
        "options": [
            "polysaccharides",
            "proteins",
            "cellulose",
            "polypeptides"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 753,
        "question": "During what phase of meiosis do tetrads align along the equatorial plate?",
        "answer": "Meiosis involves two cell divisions. During the first division, pairs of homologous chromosomes align at the center of the cell and are separated into two daughter cells. During the second division, single chromosomes align at the cell center (as they would during mitosis) and sister chromatids are separated into the daughter cells.",
        "suggested_answer": "Metaphase I",
        "options": [
            "Prophase I",
            "Metaphase I",
            "Metaphase II",
            "Prophase II"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 754,
        "question": "Which of the following does not affect the activity of enzymes?",
        "answer": "Enzyme activity can be affected by environmental factors such as temperature and pH. This is because proteins denature and lose their shape at high temperatures and extreme pHs. Most enzymes prefer to act under a temperature close to body temperature. Optimal pH is usually physiologic at pH 6 to 8; however, digestive enzymes prefer lower pH around 2 to 3 (e.g. pepsin, which makes sense because pepsin works in acidic conditions within the stomach). ",
        "suggested_answer": "Activation energy",
        "options": [
            "Inhibitors",
            "Temperature",
            "pH",
            "Activation energy"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 755,
        "question": "The property of water that contributes to its ability to stick to certain surfaces is called __________.",
        "answer": "Water is a polar molecule, and thus can adhere to different surfaces; thus, adhesion is the correct answer here. Cohesion is close, as cohesion describes the ability of water to stick to itself due to its polarity. We want the property that allows water to stick to other surfaces, not to itself. Polymerization involves chains of similar molecules, and does not occur in water. Parsimony is the principle that the simplest explanation is usually the reality of a situation (such as when tracing evolutionary histories). Gravity does not play into the properties of water.",
        "suggested_answer": "Adhesion",
        "options": [
            "Parsimony",
            "Gravity",
            "Polymerization",
            "Adhesion",
            "Cohesion"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 756,
        "question": "What structure surrounds the glomerulus and serves as the site of filtrate production?",
        "answer": "Together, the glomerulus and Bowman's capsule form the structure known as the renal corpuscle. Blood in the capillaries of the glomerulus is forced against the walls of the vessels, where specialized epithelium and cell junctions allows fluids and small solutes to diffuse across the walls of the glomerulus and into Bowman's capsule. This process is highly dependent on pressure differentials; higher hydrostatic pressure in the glomerulus and greater solute concentration in Bowman's capsule will work to remove water and fluids from the capillary. Blood cells and large proteins are unable to pass through filtration and remain in circulation. Ions, small sugars, amino acids, and nitrogenous wastes pass through filtration, and are either reabsorbed back into the blood or excreted in the filtrate.",
        "suggested_answer": "Bowman's capsule",
        "options": [
            "Distal tubule",
            "Renal pelvis",
            "Bowman's capsule",
            "Proximal tubule"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 757,
        "question": "In eukaryotic cells, the electron transport proteins are located in the inner mitochondrial membrane. Where are they located in bacterial cells?",
        "answer": "Since bacterial cells lack mitochondria, the proteins that make up the electron transport chain reside in the plasma membrane and pump protons from the cytosol into the periplasm. There is no nuclear membrane in bacterial cells since bacteria lack nuclei.",
        "suggested_answer": "In the plasma membrane",
        "options": [
            "In the periplasm",
            "In the cell wall",
            "In the nuclear membrane",
            "In the plasma membrane",
            "In the cytosol"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 758,
        "question": "The wing structures of bats and birds are examples of which of the following?",
        "answer": "Bird wings and bat wings arose independently of one another, as they do not share a common ancestor with wings, therefore this is convergent evolution.",
        "suggested_answer": "Convergent evolution",
        "options": [
            "Genetic drift",
            "Convergent evolution",
            "Homology",
            "Divergent evolution",
            "Allopatric speciation"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 759,
        "question": "Which of the following are able to pass through a gap junction from one cell to another?",
        "answer": "Gap junctions are protein channels that span the intercellular space that connect two cells. They allow cytoplasmic exchange in animal cells. The diameter of gap junctions limits what is able to travel though them from one cell to another. Ions, amino acids, and small molecules can flow through gap junctions; however, proteins, polysaccharides, and nucleic acids cannot. Gap junctions allow the transfer of small molecules to direct communication and cellular activities.",
        "suggested_answer": "Ions",
        "options": [
            "Polysaccharides",
            "Enzymes",
            "Genetic material",
            "Ions"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 760,
        "question": "Which of the following items is not found in prokaryotic cells?",
        "answer": "The correct answer is mitochondria. This organelle is found in eukaryotic cells, which means that prokaryotic cells generate ATP through other means. All other items listed are found in prokaryotic cells.",
        "suggested_answer": "Mitochondria",
        "options": [
            "Vesicles",
            "Microtubules",
            "Ribosomes",
            "Cell wall",
            "Mitochondria"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 761,
        "question": "Which of the following terms refers to all of the changes that occur within a cell?",
        "answer": "Metabolism is the sum of all of the chemical and physical processes in a cell. Cell metabolism refers to any change in the cell.",
        "suggested_answer": "Metabolism",
        "options": [
            "Biochemistry",
            "Replication",
            "Homeostasis",
            "Synthesis",
            "Metabolism"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 762,
        "question": "Cell walls can be found in all of the following groups of organisms except __________.",
        "answer": "The only group organisms listed without cell walls are mammals. All of the other organisms may or may not have cell walls. For example, some fungi, but not all, have cell walls made of chitin. Bacteria, but not Archaea, have cell walls made of peptidoglycan, and plants have cell walls made of cellulose. A small subset of protists may also contain cell walls.",
        "suggested_answer": "mammals",
        "options": [
            "fungi",
            "bacteria",
            "plants",
            "protists",
            "mammals"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 763,
        "question": "Which of the following amino acids is responsible for the initiation of the translation of a polypeptide?",
        "answer": "The start codon for any strand of RNA begins with the codon that codes for the amino acid methionine. This is the first amino acid in a polypeptide chain. The abbreviation for methionine is: Met.",
        "suggested_answer": "Methionine",
        "options": [
            "Methionine",
            "Alanine",
            "Asparagine",
            "Leucine",
            "Valine"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 764,
        "question": "Amy has osteoporosis, which is a disease that affects the bone. It occurs in individuals with low calcium levels in their bones. The bones become weak, brittle and may break. Which of the following hormones would she need to produce to help reduce the problems associated with osteoporosis?",
        "answer": "Calcitonin is the hormone in charge of taking calcium ions from the bloodstream and incorporating them into the matrix of the bone. This allows the bone to produce hydroxyapatite crystals from the calcium, which can strengthen the matrix of the bone and reduce the effect of osteoporosis. Parathyroid hormone and calcitriol (vitamin D) have opposite effects as calcitonin - they increase the blood calcium levels through breaking down bone and increasing the absorption of calcium in the gut. Growth hormone is responsible for normal growth and development of the body, especially bones and skeletal muscles. It is not involved in regulation of minerals such as calcium. Calmodulin is a calcium binding protein that regulates many intracellular processes. ",
        "suggested_answer": "Calcitonin",
        "options": [
            "Calmodulin",
            "Parathyroid hormone",
            "Growth hormone",
            "Calcitriol",
            "Calcitonin"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 765,
        "question": "Why do biologists not normally distinguish between micro- and macro- evolution?",
        "answer": "Both \"microevolution\" and \"macroevolution\" are driven by the same biological process: natural selection. While the former refers to change within species and the latter refers to change to a new species, the line between what constitutes a species change is difficult to define. Since both terms refer to the same process of natural selection, the term evolution applies to both, whether as a single adaptation or a series of adaptations that culminate into a single species.",
        "suggested_answer": "The line between \"micro\" and \"macro\" is mostly arbitrary and depends on the individual's definition.",
        "options": [
            "The line between \"micro\" and \"macro\" is mostly arbitrary and depends on the individual's definition.",
            "\"Micro\" vs. \"Macro\" evolution is an outdated principle of Lamarckian evolution.",
            "The terms \"micro\" and \"macro\" do not apply to the biological process.",
            "\"Micro\" and \"macro\" are terms only used to try to disprove evolutionary theory.",
            "They are differences between population change and speciation, and the term \"species\" is impossible to define."
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 766,
        "question": "Which of the following structures is found only in the peripheral nervous system?",
        "answer": "The central nervous system houses the brain and spinal cord only. The peripheral nervous system refers to all other nervous structures in the body.",
        "suggested_answer": "Free nerve ending",
        "options": [
            "Free nerve ending",
            "White matter",
            "Gray matter",
            "Interneurons",
            "Oligodendrocytes"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 767,
        "question": "The various species of Galapagos finches utilize their beaks to access food resources in many ways. Those with longer beaks use their beaks to penetrate cactus fruit and eat the inside, while those with short beaks tear apart the base of the cactus and eat insect larvae and pupae.",
        "answer": "The answer is directional selection. This environmental change will cause a shift in the mean beak size of the population towards smaller beaks because their food resource was not affected. There is selection against long beak size only.",
        "suggested_answer": "Directional selection",
        "options": [
            "Directional selection",
            "Sexual selection",
            "Disruptive selection",
            "Stabilizing selection"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 768,
        "question": "Which of the following must be true for natural selection to take place in a population?",
        "answer": "In order for natural selection to take place, members of a population must have different biological fitnesses (a measure of how likely it is that an individual will pass on its genes through reproduction). Different biological fitnesses are a result of different phenotypes. Organisms with phenotypes better suited to their environments will have a higher fitness. ",
        "suggested_answer": "Phenotypic variation is based on differences in genotypes",
        "options": [
            "Adaptation to different environmental niches",
            "The population must be in competition with another population",
            "Phenotypic variation is based on differences in genotypes",
            "There must be sexual dimorphism in the population"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 769,
        "question": "Which of the following is the first phase of mitosis?",
        "answer": "The order of mitosis is the following:",
        "suggested_answer": "Prophase",
        "options": [
            "Anaphase",
            "Prophase",
            "Prometaphase",
            "Telophase",
            "Metaphase"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 770,
        "question": "Scientists are trying to figure out the dominance hierarchy for a newly discovered plant. They have found that when a true-breeding yellow plant is crossed to a true-breeding green plant, the resulting offspring have distinct spots of yellow and green. What is the most likely explanation for this result?",
        "answer": "In the resulting offspring, both phenotypes are displayed equally. This is a classic example of codominance. If an intermediate phenotype was observed, incomplete dominance would be the correct answer.",
        "suggested_answer": "Codominance",
        "options": [
            "Yellow is the dominant allele",
            "Green is the dominant allele",
            "Incomplete dominance",
            "Codominance"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 771,
        "question": "To what region of the centromere do microtubules attach to during mitosis?",
        "answer": "During prophase of mitosis, microtubules attach to the centromere region of the chromosome. Remember that the centromere is the region that connects the two sister chromatids that compose the chromosome. Specifically, the specialized region of the centromere that the microtubules attach to is called the kinetochore. The centrioles are the structures that make up the centrosomes, which are the microtubule organizing centers from which microtubules originate.",
        "suggested_answer": "Kinetochore",
        "options": [
            "Sister chromatid",
            "Kinetochore",
            "Metaphase plate",
            "Centriole",
            "Mitotic spindle"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 772,
        "question": "Choose the incorrect statement regarding gas exchange in the alveoli of the lungs.",
        "answer": "We know that systemic arteries carry oxygenated blood away from the heart and that these vessels pass through tissues, allowing oxygen to diffuse into the tissues and carbon dioxide to diffuse out into the bloodstream. We also know that veins carry blood back to the heart, which by this time is oxygen-poor due to its gas exchange with the tissues of the body. The partial pressure of oxygen in veins must be lower than it is in arteries, since the veins carry deoxygenated blood.",
        "suggested_answer": "The partial pressure of oxygen is lower in systemic arteries than in systemic veins",
        "options": [
            "In the lungs, oxygen diffuses from the alveoli into the blood vessels",
            "The partial pressure of carbon dioxide is lower in the systemic arteries than it is in the body tissues",
            "Gases move between alveoli and blood vessels via passive diffusion",
            "The partial pressure of oxygen is lower in systemic arteries than in systemic veins",
            "Carbon dioxide diffuses from the blood vessels into the alveoli for expiration"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 773,
        "question": "Which of the following is not a true characteristic of spermatogonia?",
        "answer": "Spermatogonia are diploid undifferentiated male germ cells located in the seminiferous tubules of the testes. Spermatogonia are important in the process of spermatogenesis; they turn into primary spermatocytes via growth and maturation.",
        "suggested_answer": "They are haploid",
        "options": [
            "They are undifferentiated",
            "They are haploid",
            "They are germ line cells",
            "They develop into primary spermatocytes through mitosis"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 774,
        "question": "Which organism would be considered the most biologically fit?",
        "answer": "The most biologically fit organism is one that produces the most fertile offspring. Lifespan can correlate to the number of offspring produced, but is not a direct factor in determining fitness.",
        "suggested_answer": "Lives 36 years and produces 6 offspring",
        "options": [
            "Lives 94 years and produces 5 offspring",
            "Lives 45 years and produces 3 offspring",
            "Lives 70 years and produces no offspring",
            "Lives 27 years and produces 1 offspring",
            "Lives 36 years and produces 6 offspring"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 775,
        "question": "What DNA fragment would complement 5\u2019 ATCGGTCAAT 3\u2019 ?",
        "answer": "The correct answer must complementary base pair with the fragment in the 3\u2019 to 5\u2019 direction because the strands run anti-parallel to each other. Only three of the given options that will run anti-parallel, but only one complements the DNA properly.",
        "suggested_answer": "3\u2019 TAGCCAGTTA 5\u2019",
        "options": [
            "5\u2019 GCTAACTGGC 3\u2019",
            "5\u2019 TAGCCAGTTA 3\u2019",
            "3\u2019 TAGCCAGTTA 5\u2019",
            "3\u2019 GCTAACTGGC 5\u2019",
            "3\u2019 ATCGGTCAAT 5\u2019"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 776,
        "question": "A seed that normally plants itself in the countryside is carried by the wind and plants itself in a forest. There, the seed grows and multiplies, competitively excluding many other native plant species. In the forest, this seed is an example of which of the following?",
        "answer": "Invasive species are organisms that become established outside their native range. In this example, the seed\u2019s native range is the countryside. However, when it is carried by the wind to the forest (an environment outside its native range), it plants itself and establishes a population there. The seed is an example of an invasive species. ",
        "suggested_answer": "Invasive species",
        "options": [
            "Invasive species",
            "Herbivory ",
            "Homologous structures",
            "Species diversity ",
            "Parasitism"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 777,
        "question": "A lizard warms himself up on a rock that has been out in the sun. When the sun sets, his body temperature drops back down. Is the lizard endothermic or exothermic?",
        "answer": "Endothermic organisms can regulate their own body temperature via internal regulation mechanisms independent of environmental factors. For example, a dog is able to cool itself down in the heat of day by panting. Exothermic organisms cannot internally regulate their own body temperature. Rather, they have external regulation, and they depend on environmental factors. The lizard mentioned cannot regulate his own body temperature. In order to warm up, he must find sunlight and heat to increase his body temperature; in order to cool down he may find shade or seek refuge underground. Exothermic organisms are extremely dependent on climate to carry out life functions. ",
        "suggested_answer": "Exothermic; the lizard's body temperature is governed by external, environmental factors",
        "options": [
            "Endothermic; the lizard's body temperature is governed by external, environmental factors",
            "Exothermic; the lizard regulates his own body temperature ",
            "Endothermic; the lizard regulates his own body temperature",
            "Exothermic; the lizard's body temperature is governed by external, environmental factors",
            "Exothermic; the lizard can regulate his own body temperature with help from external environmental factors"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 778,
        "question": "Which of the following best describes the difference between intraspecific and interspecific competition?",
        "answer": "Intraspecific competition occurs between members of the same species while interspecific competition occurs between different species.",
        "suggested_answer": "Interspecific competition is a type of competition between different species and intraspecific competition is a type of competition within the same species",
        "options": [
            "Intraspecific competition encompasses interspecific competition",
            "Interspecific competition is a type of competition between different species and intraspecific competition is a type of competition within the same species",
            "Interspecific competition is a type of competition within the same species and intraspecific competition is a type of competition between different species",
            "Intraspecific competition is only indirect while interspecific competition cannot be indirect"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 779,
        "question": "The lymphatic system is involved in all except which of the following processes?",
        "answer": "Red blood cells generally do not leave the circulatory system; thus there are very few opportunities for lymphatic vessels to pick up red blood cells in the extracellular space.",
        "suggested_answer": "Transport red blood cells for destruction in the spleen",
        "options": [
            "It is a crucial component of the immune system",
            "Transport red blood cells for destruction in the spleen",
            "Maintain blood volume by reducing fluid loss to the extracellular compartment",
            "Transport fats and fat-soluble vitamins that are absorbed via the digestive tract"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 780,
        "question": "Which of the following are correct regarding the central dogma of biology?",
        "answer": "DNA encodes information for the production of messenger RNA which then interacts with the cell's protein-synthesizing machinery to produce proteins. Ribosomes are the sites of polypeptide synthesis but are not coded for by RNA. The central dogma of biology is DNA RNA protein",
        "suggested_answer": "DNA directs the synthesis of mRNA, which in turn directs the production of polypeptides",
        "options": [
            "RNA assists with the replication of DNA which encodes information for proteins",
            "DNA directs the synthesis of mRNA, which in turn directs the production of polypeptides",
            "RNA signals DNA to activate protein production",
            "RNA encodes the information for the assembly of ribosomes, which are the sites of protein production"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 781,
        "question": "According to the RNA world hypothesis, which of the following characteristics of RNA were critical for development into more complex compounds and life forms?",
        "answer": "According to the RNA world hypothesis, RNA was a precursor for all other molecules the origins of life on early Earth. The characteristics of RNA support the hypothesis that RNA may be the precursor to current life. These characteristics include the ability of RNA to self-replicate, catalyze simple reactions, and store information.",
        "suggested_answer": "All of these",
        "options": [
            "Information storage",
            "Self-replication",
            "Ability to catalyze reactions",
            "All of these"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 782,
        "question": "Which of the following is true regarding herbivores?",
        "answer": "Herbivores are organisms that eat plant material. They are considered as the primary consumers in an ecosystem. An example of an herbivore is a deer, which feeds on flowers and grass.",
        "suggested_answer": "Herbivores eat plant material and are primary consumers",
        "options": [
            "Herbivores eat plant material and are secondary consumers",
            "Herbivores are secondary consumers",
            "Herbivores eat plant material and are primary consumers",
            "Herbivores are primary consumers"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 783,
        "question": "Sodium leak channels, potassium leak channels, and sodium-potassium pumps are essential to maintaining the resting membrane potential. What is true regarding these three proteins?",
        "answer": "The activity of the sodium-potassium pump creates an electrochemical gradient. There are more sodium ions outside the cell and more potassium ions inside the cell. Recall from diffusion that molecules will always want to go from a region of high concentration to a region of low concentration; therefore, diffusion will drive sodium ions into the cell and potassium ions out of the cell.",
        "suggested_answer": "Potassium leak channels move potassium ions outside the cell, whereas the sodium-potassium pump moves potassium ions inside the cell",
        "options": [
            "Potassium leak channels move potassium ions outside the cell, whereas the sodium-potassium pump moves potassium ions inside the cell",
            "Sodium leak channels move sodium ions outside the cell, whereas the sodium-potassium pump moves sodium ions inside the cell",
            "Sodium leak channels and the sodium-potassium pump move sodium ions outside the cell",
            "Potassium leak channels and the sodium-potassium pump move potassium ions outside the cell"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 784,
        "question": "Which of these describes the gas exchange that occurs in the alveoli?",
        "answer": "Diffusion is the spontaneous process by which substances move from areas of high to low concentration. During diffusion in alveoli, the high levels of that are in the blood vessels surrounding the alveoli causes it to diffuse out of the blood vessels and into the alveoli where there are low levels of (atmospheric air is about ). In the same way, high levels of oxygen in the alveoli diffuses into the area of low oxygen concentration within the blood vessels. ",
        "suggested_answer": "Exchange of carbon dioxide and oxygen between the blood vessels and the alveoli ",
        "options": [
            "Exchange of oxygen and hydrogen between the blood vessels and the alveoli",
            "Exchange of molecules across the pulmonary cell surfaces",
            "Exchange of carbon dioxide and oxygen between the blood vessels and the alveoli ",
            "The process of cleansing the air inhaled through the bronchi",
            "The process of bringing air into the lungs"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 785,
        "question": "What is the correct order of the stages of mitosis?",
        "answer": "Mitosis is in the order of this anagram: PMAT.",
        "suggested_answer": "Prophase, prometaphase, metaphase, anaphase, telophase, cytokinesis.",
        "options": [
            "Anaphase, cytokinesis, metaphase, prophase, telophase, prometaphase.",
            "Cytokinesis, prophase, prometaphase, metaphase, anaphase, telophase.",
            "Prophase, prometaphase, metaphase, telophase, anaphase, cytokinesis.",
            "Prophase, prometaphase, metaphase, anaphase, telophase, cytokinesis.",
            "Anaphase, prophase, prometaphase, metaphase, telophase, cytokinesis."
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 786,
        "question": "Without the action of telomerase, what would happen to telomeres after consecutive rounds of DNA replication?",
        "answer": "Telomeres are the regions at the ends of chromosomes that contain repetitive sequences of DNA. Telomeres are non-coding, and instead serve to protect the chromosome from deterioration and degradation. In DNA replication, there exists an end replication problem, which describes the inability to replicate the entire chromosome. The lagging strand cannot be copied in its entirety due to a lack of strand to attach another primer. Thus, the newly synthesized DNA molecule is shorter than the parent molecule. Because the telomeres are at the ends of chromosomes, telomere sequences are shortened with each consecutive round of DNA replication. However, telomerase solves this \"end replication problem.\" It is a ribonucleoprotein, which means it contains RNA and protein to carry out its enzymatic function. It adds repetitive sequences to the ends of the DNA strand so that the chromosomes do not shorten over time.",
        "suggested_answer": "They would shorten",
        "options": [
            "They would be maintained",
            "They would shorten",
            "The sequence changes",
            "They would lengthen"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 787,
        "question": "Which of the following are assumptions made by the Hardy-Weinberg principle?",
        "answer": "The Hardy-Weinberg principle is a theory that describes how allele frequencies may change within a population absent of evolutionary mechanisms. The theorem is based on certain assumptions regarding the population in question. These assumptions include random mating, large population size, sexual reproduction, and the absence of migration, mutation and selection. It is exceedingly rare for all the Hardy-Weinberg assumptions to be met in nature, but this theory is a tool used to study allele frequencies within populations. ",
        "suggested_answer": "All of these",
        "options": [
            "All of these",
            "No migration, mutation, and selection",
            "Random mating and sexual reproduction",
            "Large population size"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 788,
        "question": "Which of the following RNA molecules transports amino acids to ribosomes to be used to assemble the protein molecule?",
        "answer": "mRNA is messenger RNA, it carries the genetic code that determines the protein. snRNA directs splicing of pre-mRNA. tRNA transports the amino acids used for assembling proteins. rRNA is a component of ribosomes (the other component is protein). pre-mRNA- single strand of RNA processed to form mRNA.",
        "suggested_answer": "tRNA",
        "options": [
            "tRNA",
            "snRNA",
            "pre-mRNA",
            "mRNA",
            "rRNA"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 789,
        "question": "Which of the following statements regarding translation is false?",
        "answer": "While there is only one start codon (AUG), but there are three different stop codons (UGA, UAG, and UAA) that can each signal for the end of translation, or termination. During the translation process, tRNA is used to bring amino acids (corresponding to the codons in the mRNA sequence) to the ribosome, which become attached via peptide bonds to form a polypeptide.",
        "suggested_answer": "There is only one stop codon (UAG) that signals the end of translation",
        "options": [
            "Adjacent amino acids in a sequence are joined via peptide bonds",
            "tRNA works to bring amino acids corresponding to the codons in the mRNA sequence to the ribosome",
            "There is only one start codon (AUG) that signals the beginning of translation",
            "There is only one stop codon (UAG) that signals the end of translation"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 790,
        "question": "A man has a copy of an X-linked dominant trait. Is it possible for his son to also possess the trait?",
        "answer": "Although X-linked dominant traits tend to be rare, it is certainly possible that the boy would inherit it from an affected mother. A father only gives a Y chromosome to his sons. This means that the boy could not inherit the X-linked dominant trait from his father.",
        "suggested_answer": "Yes, but only if he inherits it from his mother",
        "options": [
            "None of these",
            "Yes, he can inherit it from his mother or his father",
            "No",
            "Yes, but only if he inherits it from his father",
            "Yes, but only if he inherits it from his mother"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 791,
        "question": "Which of the following structures is NOT part of the cardiac conducting system?",
        "answer": "The chordae tendinae (tendinous chords or heart strings) are physical structures located in the heart lumen that connect the muscular wall of the heart to the tricuspid and mitral valves via papillary muscles.",
        "suggested_answer": "Chordae tendinae",
        "options": [
            "Atrioventricular bundle",
            "Chordae tendinae",
            "Purkinje fibers  ",
            "Sinoatrial node "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 792,
        "question": "The muscle cells that line the walls of arteries are an example of __________.",
        "answer": "The three types of muscle in the body are smooth, skeletal, and cardiac muscle. The type of muscle lining artery walls is smooth muscle, which is also present in many organs such as the intestines and the urinary bladder. Unlike skeletal muscle, smooth muscle control is involuntary and, unlike both skeletal and cardiac muscle, it is non-striated. Smooth muscle around the arteries and arterioles is important for regulating blood pressure and directing blood flow in the body.",
        "suggested_answer": "smooth muscle",
        "options": [
            "artery walls are not lined with muscle cells",
            "skeletal muscle",
            "smooth muscle",
            "striated muscle",
            "cardiac muscle"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 793,
        "question": "When in meiosis does crossing over occur?",
        "answer": "Crossing over occurs during prophase I when parts of the homologous chromosomes overlap and switch their genes.",
        "suggested_answer": "Prophase I",
        "options": [
            "Telophase I",
            "Prophase I",
            "Interphase",
            "Anaphase I",
            "Metaphase I"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 794,
        "question": "Which of the following is does not describe an endocrine interaction?",
        "answer": "Endocrine interactions involve a molecule (hormone) being secreted into the bloodstream to trigger a response in target cells in a different location. Reduction of blood-glucose levels by insulin and stimulation of cortisol release by adrenocorticotropic hormone are both examples of endocrine function.",
        "suggested_answer": "Epinephrine is secreted into a synapse to excite a post-synaptic neuron",
        "options": [
            "Adrenocorticotropic hormone is secreted to stimulate secretion of cortisol",
            "All of these are endocrine interactions",
            "Insulin is secreted by the pancreas to lower blood-glucose levels",
            "Epinephrine is secreted into a synapse to excite a post-synaptic neuron"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 795,
        "question": "The covalent bond that links two amino acids together is called __________.",
        "answer": "The covalent bond between two amino acids is called a peptide bond. This is formed by positioning two amino acids so the carboxyl group of one is adjacent to the amino group of another. An enzyme then joins the two via a dehydration synthesis reaction. Ester bonds link fatty acids to glycerol heads, and phosphodiester bonds are formed between the sugar and phosphate backbone of a nucleic acid.",
        "suggested_answer": "a peptide bond",
        "options": [
            "a peptide bond",
            "an ester bond",
            "an amino linkage",
            "a phosphodiester bond"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 796,
        "question": "Which of the following factors determine the distribution of the earth's habitats and ecosystems?",
        "answer": "The earth's habitats and ecosystems are dispersed based on air circulation, rainfall, and topography. For example, when looking at the globe, we may notice that the world's major grasslands are always leeward of major mountain ranges, or that tropical rain forests are located around the equator. Why do grasslands fall on the leeward side of mountain ranges, and tropical rainforests at the equator? As the air travels up the mountain, it condenses and precipitation occurs. Once the air reaches the leeward side, it is dry, resulting in the grasslands and plains. Tropical rainforests are located at the equator, an area that gets constant sunlight and rainfall regardless of the earth's tilt. The dispersion of the earth's habitats and ecosystems is very dependent on such environmental factors. ",
        "suggested_answer": "I, II, and III",
        "options": [
            "I, II and IV",
            "I, II, and III",
            "II, III, IV, and V",
            "I, III, and V",
            "II, IV, and V"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 797,
        "question": "In terms of trophic structure, the cascade model follows which of the following viewpoints?",
        "answer": "The cascade model, or top-down model, states that there is a unidirectional influence from higher to lower trophic levels. This model follows the idea that predation controls community organization. Predators limit the number of herbivores, and herbivores limit plants, and plants limit the amount of nutrients available in the soil. ",
        "suggested_answer": "There is a unidirectional influence from higher to lower trophic levels ",
        "options": [
            "There is a unidirectional influence from higher to lower trophic levels ",
            "There is a unidirectional influence from lower to higher trophic levels ",
            "Lower trophic levels affect higher trophic levels unpredictably ",
            "Higher trophic levels affect higher trophic levels unpredictably ",
            "Trophic relationships are unpredictable and are dependent on environmental factors "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 798,
        "question": "Which of the following is an example of genetic drift?",
        "answer": "Genetic drift occurs when the frequency of alleles in a population change by random chance. Change in allele frequency based on biological fitness is natural selection, not genetic drift. The change in allele frequency due to members moving from one population to another describes migration. A brand new allele that did not exist in the parents is the result of mutation.",
        "suggested_answer": "A massive storm randomly kills many individuals in a population, changing the frequency of alleles in that population.",
        "options": [
            "An organism is born with traits not attributed to the alleles of its parents, but a brand new allele.",
            "A massive storm randomly kills many individuals in a population, changing the frequency of alleles in that population.",
            "All of these are examples of genetic drift.",
            "An allele increases in frequency due to the increase in fitness it provides the organism.",
            "Individuals from one population move and mate with members of a different population."
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 799,
        "question": "The endomembrane system, which is responsible for regulation of protein traffic and performs metabolic functions, consists of what combinations of organelles?",
        "answer": "The endomembrane system consists of the nuclear envelope, endoplasmic reticulum, Golgi apparatus, lysosomes, vacuoles, and plasma membrane. Though the endoplasmic reticulum has ribosomes embedded into it, they are not considered a part of the endomembrane system. Also, the mitochondria are responsible for metabolic functions, but it does not remain continuous with the endomembrane system physically or through vesicle transport. Though the endomembrane system does have a structural role, the cytoskeleton and centrosome are not considered in that structural role as they also are not continuous with the endomembrane system.",
        "suggested_answer": "Nuclear envelope, endoplasmic reticulum, Golgi apparatus, lysosomes, vacuoles, plasma membrane",
        "options": [
            "Nuclear envelope, endoplasmic reticulum, Golgi apparatus, lysosomes, vacuoles, plasma membrane",
            "Nuclear envelope, endoplasmic reticulum, ribosomes, vacuoles, Golgi apparatus, cytoskeleton",
            "Cytoskeleton, mitochondria, ribosomes, nuclear envelope, plasma membrane, centrosome",
            "Plasma membrane, vacuoles, ribosomes, Golgi apparatus, endoplasmic reticulum, mitochondria",
            "Nuclear envelope, endoplasmic reticulum, ribosomes, lysosomes, vacuoles, mitochondria"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 800,
        "question": "Which of the following is not an example of a reproductive barrier?",
        "answer": "A reproductive barrier is something that prevents two populations from interbreeding, eventually leading to speciation. The cicada populations are an example of temporal isolation--the two species do not interbreed because they do not breed at the same time. The horse and donkey example shows hybrid sterility--the two species can produce offspring, but the offspring cannot have offspring of their own. The bird species are an example of behavioral isolation--the two species have different courtship rituals (dance vs. plumage) which leads to minimal interbreeding. ",
        "suggested_answer": "For a certain species of walrus, males defend large groups of females called harems. Typically, one male's harem will consist of about 20 females, and he will mate with all of them. Some male walruses don't have harems.",
        "options": [
            "For a certain species of walrus, males defend large groups of females called harems. Typically, one male's harem will consist of about 20 females, and he will mate with all of them. Some male walruses don't have harems.",
            "Horses and donkeys are different species, but can breed with each other to produce mules. However, mules are sterile (they cannot have offspring of their own). ",
            "Two species of birds live in the same area. Species A has dull gray plumage and has developed an elaborate courtship dance. Species B has vibrant orange plumage and has no courtship dance. Females from Species A choose mates based on their dance, and females from Species B choose mates based on their plumage.",
            "Two species of cicada live in the same area. Species A is sexually mature every 7 years. Species B is sexually mature every 2 years."
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 801,
        "question": "You observe a muscle cell that is not multinucleated. What type of muscle cell can it be?",
        "answer": "There are three primary types of muscle: skeletal, smooth, and cardiac.",
        "suggested_answer": "Cardiac muscle or smooth muscle",
        "options": [
            "Skeletal muscle",
            "Cardiac muscle or smooth muscle",
            "Smooth muscle or skeletal muscle",
            "Cardiac muscle",
            "Skeletal muscle or cardiac muscle"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 802,
        "question": "How many G3P molecules are produced after 3 cycles of the Calvin Cycle; and how many of these G3P molecules are used to produce glucose after 3 cycles of the Calvin Cycle?",
        "answer": "Every 3 cycles of the Calvin Cycle (light independent reaction), 6 molecules of G3P are produced; only 1 is used to produce glucose. The remaining 5 molecules of G3P are used to regenerate RuBP to allow the Calvin Cycle to continue.",
        "suggested_answer": "6; 3",
        "options": [
            "6; 1",
            "6; 3",
            "1; 1",
            "3; 1"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 803,
        "question": "Which of the following defines a community in ecology?",
        "answer": "Ecology is essentially the study of all the living things on the planet and their relationships with each other. Ecology can be organized into levels: population, community, ecosystem, and biosphere. A population is a group of organisms that belong to the same species, share an area, and can reproduce with each other. A community is composed of the different species that can be found in a geographical location. An ecosystem encompasses all abiotic and biotic factors in an area. The biosphere refers to those parts of the earth that are able to support and sustain life.",
        "suggested_answer": "Group of different species that are found in the same location and interact with each other.",
        "options": [
            "Group of different species that are found in the same location and interact with each other.",
            "All the living things and nonliving things that share a common location and rely on each other for survival.",
            "Are all the components of planet earth that are able to support life.",
            "Organisms that are part of the same species, share a geographical location and can reproduce with each other."
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 804,
        "question": "If you touch a hot pan, __________ neurons tell your brain \"it's hot!\" and __________ neurons are used to quickly move your hand away",
        "answer": "Afferent neurons are sensory neurons that carry nerve impulses from sensory stimuli towards the central nervous system and brain, while efferent neurons are motor neurons that carry neural impulses away from the central nervous systme and towards muscles to cause movement.",
        "suggested_answer": "afferent . . . efferent",
        "options": [
            "parasympathetic . . . sympathetic",
            "peripheral nervous system . . . central nervous system",
            "afferent . . . efferent",
            "efferent . . . afferent",
            "sympathetic . . . parasympathetic"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 805,
        "question": "Which of the following describes cell activity during the G1 phase of the cell cycle?",
        "answer": "During G1, the cell undergoes growth as it increases in size and produces organelles. This is followed by DNA replication is S phase, further growth in G2, and mitosis in M phase.",
        "suggested_answer": "The cell increases in size and produces new organelles",
        "options": [
            "The cell increases in size and produces new organelles",
            "The cell undergoes mitosis",
            "The cell is in a resting phase",
            "DNA is replicated"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 806,
        "question": "Which of the following is true regarding the structure of stratified epithelia?",
        "answer": "Stratified epithelia consist of two or more layers of epithelial cells. They plan an important role in protection from chemical and mechanical forces. Stratified epithelia are specialized into keratinized and transitional layers.",
        "suggested_answer": "It is composed of two or more layers of epithelial cells",
        "options": [
            "It is composed of two or more layers of epithelial cells",
            "It is a layer of cells made of epithelial cells of multiple morphologies",
            "It is a single layer of epithelial cells",
            "Stratified epithelia are layers of cuboidal cells"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 807,
        "question": "A population must meet all of the following conditions to be in Hardy-Weinberg equilibrium except __________.",
        "answer": "Under the Hardy-Weinberg Principle, there is no change in the frequency of alleles or genotypes in a population because there are no changes to the population, such as: natural selection, mutations, migration, or chance events. In addition, mating must be random. Therefore, is it easy to see that most populations do not meet the requirements of this principle. ",
        "suggested_answer": "natural selection must occur",
        "options": [
            "no migration",
            "Mating must be random",
            "natural selection must occur",
            "no mutations",
            "no natural selection"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 808,
        "question": "Following a large meal, which of the following biological changes occurs?",
        "answer": "Following a large uptake of glucose, the body will begin to release insulin to facilitate storage of the glucose molecules within the cells of the liver, as well as skeletal muscles.",
        "suggested_answer": "Insulin levels spike, facilitating the storage of glucose into cells",
        "options": [
            "Gluconeogenesis activity increases",
            "Insulin levels spike, facilitating the storage of glucose into cells",
            "Glucagon levels spike, promoting the breakdown of glycogen",
            "Glycogenolysis begins to occur",
            "Insulin levels drop, facilitating the release of glucose from cells"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 809,
        "question": "There are two alleles for fur color in a population of mice: white and black. If these alleles demonstrate incomplete dominance, what is the expected phenotypic ratio of offspring generated from a cross of two heterozygous parents?",
        "answer": "We are told that the two parents are heterozygous. This allows us to set up the given cross fairly easily.",
        "suggested_answer": "1 black : 2 gray : 1 white",
        "options": [
            "3 black : 1 white",
            "1 AA : 2 Aa : 1 aa",
            "3 Aa : 1 aa",
            "1 black : 2 gray : 1 white"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 810,
        "question": "How many fatty acid chains are contained in a typical molecule of \"body fat?\"",
        "answer": "Body fat, also known as triglycerides or triacylglycerols have three fatty acid chains. These hydrocarbon tails are hydrophobic, and they are each attached to the glycerol head, which is hydrophilic via ester linkages. Adipose tissue is a type of connective tissue that plays a structural and protective role, provides stored energy, and helps regulate body temperature. ",
        "suggested_answer": "Three",
        "options": [
            "Two",
            "Four",
            "One",
            "Three"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 811,
        "question": "What is a commonality between excretory systems of worms, insects, and vertebrates?",
        "answer": "The overall organ systems that perform excretory functions are highly variable between animal groups. All animals, however, share some common features. Water and ion balances must be tightly regulated by reabsorption mechanisms, in order to maintain organismal homeostasis and functions. To assist in this process, the vessels for filtrate transport must have a large surface area. Proteins, pumps, and channels along this surface serve to moderate water and ion transport.",
        "suggested_answer": "All of these answers",
        "options": [
            "All of these answers",
            "A large surface area",
            "Reabsorption mechanisms for water and ions",
            "A complex network of tubules "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 812,
        "question": "Which of the following types of soil bacteria convert ammonia into nitrate molecules during nitrification?",
        "answer": "Nitrification takes place in the soil and is the process in which ammonium is converted into nitrate. Nitrifying bacteria facilitate this process.",
        "suggested_answer": "Nitrifying bacteria",
        "options": [
            "None of these",
            "Saprobiotic bacteria",
            "Denitrifying bacteria",
            "Nitrifying bacteria"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 813,
        "question": "What type of muscle makes up the human heart? ",
        "answer": "The heart is composed of cardiac muscle. This is the only location in the entire body that contains this type of muscle. Skeletal muscles are those attached to bones, and are under voluntary control. While contraction and relaxation of smooth muscle is involuntary, it is not found in the heart. Cardiac muscle is unique in that the cells are connected via gap junctions, which allows rapid electrical conduction between them, producing wavelike contractions of the heart, essential to its function.",
        "suggested_answer": "Cardiac muscle only",
        "options": [
            "Smooth and cardiac muscle ",
            "Cardiac muscle only",
            "Smooth muscle only",
            "Endocrine muscle",
            "Skeletal muscle"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 814,
        "question": "In a redox reaction, the loss of electrons from a substance is called __________.",
        "answer": "A redox or oxidation-reduction reaction involves the transfer of electrons from one substance to another. The loss of an electron from a substance is called oxidation. The gain of electrons to another substance is called reduction. A mnemonic that is often useful in remembering this is: OIL RIG, where Oxidation Is Loss of electrons and Reduction Is Gain of electrons.  ",
        "suggested_answer": "oxidation",
        "options": [
            "oxidation",
            "reduction",
            "electron transfer",
            "electron compensation"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 815,
        "question": "Which of the following best describes the impact of purifying selection?",
        "answer": "Purifying selection is a type of natural selection impacts genetic diversity. Purifying selection removes variation from a population, meaning that there is a decrease in genetic diversity.",
        "suggested_answer": "It removes variation from the population",
        "options": [
            "It removes variation from the population",
            "It increases frequency of an allele",
            "It is the same as disruptive selection ",
            "It increases genetic diversity"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 816,
        "question": "Phosphodiester bond forms between which two molecules?",
        "answer": "By looking at the name \"phosphodiester bond\" you should realize that a phosphate group is involved. A phosphodiester bond occurs between the phosphate group and a pentose sugar in the DNA backbone. The phosphate group from one nucleotide binds to the 3\u2019 carbon on pentose sugar from the other nucleotide.",
        "suggested_answer": "A phosphate group and the 3\u2019 carbon on pentose sugar",
        "options": [
            "A phosphate group and the 5\u2019 carbon on pentose sugar",
            "A phosphate group and the 3\u2019 carbon on pentose sugar",
            "A phosphate group and a nitrogenous base",
            "The 5\u2019 carbon on pentose sugar and a nitrogenous base"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 817,
        "question": "In a neuron, the sodium (Na+)/potassium (K+) pump is most active during the process of:",
        "answer": "The sodium/potassium pump brings back the cell to its resting potential. The influx of sodium causes the depolarization of the cell. The efflux of potassium causes the repolarization. Finally, the excess efflux of potassium results in hyperpolarization.",
        "suggested_answer": "None of the other answers are correct",
        "options": [
            "Depolarization",
            "None of the other answers are correct",
            "Hyperpolarization",
            "Repolarization"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 818,
        "question": "How would you expect plants in deserts to differ from those in rainforests?",
        "answer": "Desert plants would have different water retention and utilization strategies. They would likely use C4 or CAM photosynthesis. The C4 and CAM pathways are specific adaptations to arid conditions. They allow higher water retention, which is needed in the desert but not the rainforest. Since the main difference between these two environments is the abundance of water, even if the other options were true, they are minor differences in comparison to the need to utilize water differently. ",
        "suggested_answer": "They would have different water retention and utilization strategies",
        "options": [
            "Desert plants would have larger spines",
            "Desert plants would have no woody bark due to lack of herbivores",
            "They would have different water retention and utilization strategies",
            "Desert plants would have more seeds than plants in the rainforests"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 819,
        "question": "The mRNA codon for methionine is 5' AUG 3'. What is the corresponding anticodon found on the methionine tRNA?",
        "answer": "The anticodon of any codon will be the RNA nucleotides that complement the codon sequence. In RNA, adenine (A) complements uracil (U) while cytosine (C) complements guanine (G). Hence, for the codon 5' AUG 3', the complements will be 3' UAC 5'. Note that many of the incorrect answers contain thymine (T), a nucleotide not found in RNA.",
        "suggested_answer": "3' UAC 5'",
        "options": [
            "3' UAC 5'",
            "5' UAG 3'",
            "3' TAC 5'",
            "3' AUG 5'",
            "5' ATG 3'"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 820,
        "question": "What is the main way in which carbon dioxide is altered in the bloodstream?",
        "answer": "Carbon dioxide is not very soluble in water, so it is carried in the blood almost exclusively as carbonic acid. While some carbon dioxide is bound to hemoglobin, the majority of it is carried in the blood as the bicarbonate ion, which is the blood's main buffer. Note the following chemical equilibria:",
        "suggested_answer": "Carbon dioxide combines with water in the bloodstream to make carbonic acid",
        "options": [
            "Carbon dioxide combines with water in the bloodstream to make carbonic acid",
            "Carbon dioxide is transported as a carboxyl group in the bloodstream",
            "Carbon dioxide turns into sodium bicarbonate in the bloodstream",
            "Carbon dioxide is bound to hemoglobin in the bloodstream"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 821,
        "question": "Which of the following best describes the primary difference between the savanna and temperate grassland biomes?",
        "answer": "The key difference between the savanna and temperate grassland biomes is that seasonal fires are very important to savanna biodiversity but not for the temperate grassland\u2019s ecology.",
        "suggested_answer": "Seasonal fires are important to savanna biodiversity but they are not in temperate grasslands",
        "options": [
            "The savanna is dominated by grasses while the temperate grassland has scattered trees",
            "Seasonal fires are important to temperate grassland biodiversity but they are not in the savanna",
            "The savanna can be further categorized into prairies and steppes while the temperate grassland cannot",
            "Seasonal fires are important to savanna biodiversity but they are not in temperate grasslands"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 822,
        "question": "In comparison to fermentation, the aerobic pathway of glucose metabolism yields more of what?",
        "answer": "The correct answer to this question is ATP.",
        "suggested_answer": "ATP",
        "options": [
            "Acetaldehyde",
            "Ammonium",
            "Ethanol",
            "ATP",
            "Pyruvate"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 823,
        "question": "Starch is commonly found in which of the following organisms?",
        "answer": "Starch is a storage polysaccharide in plants. It is a polymer consisting solely of glucose. Glucose is a source of fuel for cells; therefore, starch is stored for energy.",
        "suggested_answer": "Plants",
        "options": [
            "Bacteria",
            "Insects",
            "Plants",
            "Animals"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 824,
        "question": "Crossing over is a phenomenon that happens during Meiosis I in the attempt to create genetic diversity. Crossing over typically occurs between which of the following structures?",
        "answer": "Crossing over occurs when chromosomal homologs exchange information during metaphase of Meiosis I. During this stage, homologous chromosomes line up on the metaphase plate and exchange genetic information.",
        "suggested_answer": "Homologous chromosomes",
        "options": [
            "Homologous chromosomes",
            "Sister chromatids",
            "Chromatin",
            "Tetrads"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 825,
        "question": "What is the arrangement of microtubules that make up a centriole?",
        "answer": "Centrioles are composed of nine triplets of microtubules arranged in a ring. A pair of centrioles makes up a centrosome, which is important in the formation of mitotic spindles in mitosis and meiosis and in the organization of microtubules in the cytoplasm. Cilia are also made of microtubules, but arranged in a different way. Cilia have nine pairs of microtubules around the edge of the cilium, and another pair of microtubules in the middle of the cilium.",
        "suggested_answer": "Nine triplets",
        "options": [
            "Nine triplets",
            "Nine microtubules",
            "Nine pairs plus one pair in the center",
            "Nine pairs"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 826,
        "question": "What aspect of growth hormone differentiates it from other anterior pituitary hormones?",
        "answer": "Non-tropic hormones are hormones that directly stimulate target cells to induce effects. For example, aldosterone acts directly on the kidney to promote reabsorption of sodium, which causes an increase in blood pressure. This differs from tropic hormones, which act on other endocrine glands to stimulate (or inhibit) release of a second hormone. For example, thyroid-stimulating hormone is responsible for stimulating the release for thyroid hormone by acting on the thyroid gland.",
        "suggested_answer": "It produces both tropic and non-tropic effects",
        "options": [
            "It produces both tropic and non-tropic effects",
            "It provides negative feedback directly to the hypothalamus",
            "It is the only glycoprotein hormone",
            "It is a steroid hormone"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 827,
        "question": "Which type of cell-surface receptor, when bound by a ligand, begins a cycle where GDP is exchanged for GTP, causing the alpha subunit to separate from the beta and gamma subunits, and causing a cellular response?",
        "answer": "G-protein linked receptors are a type of cell-surface receptor that, when unbound by a ligand, consists of an alphaGDP subunit and a beta gamma subunit. When a ligand binds, GDP is exchanged for GTP, which causes the alphaGTP subunit to dissociate from the receptor and the beta gamma subunit. Then, the alphaGTP and beta gamma subunits can activate other molecules in the cell.",
        "suggested_answer": "G-protein linked receptor",
        "options": [
            "Enzyme-linked receptor",
            "G-protein linked receptor",
            "GTP hydrolyzation receptor",
            " ",
            "Ion channel- linked receptor"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 828,
        "question": "How do enzymes speed up a chemical reaction?",
        "answer": "Enzymes work by lowering the activation energy of a reaction, which can occur either by bringing reactants closer together or by destabilizing the transition state. They do not affect the equilibirum of the reaction, meaning they do not affect the amount of reactants or products. They simply increase the speed at which products can be formed by reducing the amount of energy needed to power the reaction.",
        "suggested_answer": "They lower the activation energy of a reaction",
        "options": [
            "They lower the activation energy of a reaction",
            "They increase the temperature of the reaction, allowing it to occur faster",
            "They shift the equilibrium in favor of the products, allowing more product to be created",
            "They tightly bind to the transition state, speeding up the reaction by removing transition states and leaving only products",
            "They increase the concentration of one or more of the reactants"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 829,
        "question": "Which of the following is FALSE about the process of blood filtration and urine creation?",
        "answer": "As filtrate travels down the descending limb of the loop of Henle, water passively leaves the filtrate as the descending limb passes through portions of the nephron that contain a more concentrated interstitial fluid. Water always travels from places of high water concentration (low osmolarity) to low water concentration (high osmolarity); thus, the water will passively flow out of the filtrate and into the interstitium.",
        "suggested_answer": "Both sodium and water are moved via active and passive transport, depending on their location in the nephron",
        "options": [
            "The thin ascending limb is impermeable to water",
            "Both sodium and water are moved via active and passive transport, depending on their location in the nephron",
            "Water always flows from areas of low solute concentration to high solute concentration",
            "The descending limb is permeable to water, which is reabsorbed in this region"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 830,
        "question": "Which of the following is true of mutations?",
        "answer": "There are four major types of mutations: deletions, duplications, inversions, and translocations. Deletions occurs when a chromosomal fragment is removed and not replaced. Duplications occur when a chromosomal fragment is aberrantly copied. Inversions occur when a chromosomal fragment reattaches to its original chromosome in reverse orientation. Translocations occur when a chromosomal fragment reattaches to a different part of the same chromosome, or a different chromosome altogether.",
        "suggested_answer": "A translocation has occurred if a chromosomal fragment is removed and reattaches to a nonhomologous chromosome ",
        "options": [
            "A translocation has occurred if a deleted chromosomal fragment is removed and reattaches to its original chromosome in reverse orientation ",
            "A translocation has occurred if a chromosomal fragment is removed and reattaches to a nonhomologous chromosome ",
            "An inversion has occurred if a chromosomal fragment is removed and reattaches elsewhere",
            "An inversion has occurred if a deleted chromosomal fragment attaches to a sister chromatid",
            "A deletion has occurred if a chromosomal fragment is removed and reattaches elsewhere "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 831,
        "question": "Which of the following ends of the embryo during secondary neurulation?",
        "answer": "Neurulation, or the formation and development of the neural tube, can be divided into primary and secondary neurulation. During primary neurulation, the neural tube forms from the neural plate. During secondary neurulation, the neural tube develops from the caudal end of the embryo. Eventually both neural tubes connect.",
        "suggested_answer": "Caudal",
        "options": [
            "Caudal",
            "Right",
            "Cranial",
            "Left"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 832,
        "question": "The lac operon contains the lac repressor, which binds to the operator to inhibit transcription. What type of operon is this, and how can transcription of the genes proceed?",
        "answer": "The lac operon is inducible, meaning that it is normally turned off or not transcribing genes (due to the repressor binding to the operator region). However, transcription can proceed if an inducer molecule (in the case of the lac operon this will be allolactose) binds to the repressor, preventing the repressor from binding to the operator region and thus allow RNA polymerase to transcribe the genes.",
        "suggested_answer": "Inducible; an inducer binding to the repressor will allow transcription ",
        "options": [
            "Inducible; an inducer binding to the operator will allow transcription",
            "Repressible; a repressor binding to the repressor will allow transcription",
            "Inducible; an inducer binding to the repressor will allow transcription ",
            "Repressible; a repressor binding to the repressor will allow transcription"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 833,
        "question": "A student selects a random pea plant from Mendel's garden. She notes that the plant has red leaves, but all other plants in the garden have green leaves. She presents the plant to Mendel, who claims that he has never seen a plant with red leaves before. What phenomenon is most likely responsible for the red leaf phenotype?",
        "answer": "The red leaf phenotype represents a new allele in the population. None of the other plants have this trait and there are no other known red-leaf plants in the region. Most likely, the new phenotype is the result of a mutation. All alleles start as mutations and spread as the mutation is inherited by more individuals in the population.",
        "suggested_answer": "A mutation, resulting in a new allele",
        "options": [
            "Incomplete dominance",
            "A mutation, resulting in a new allele",
            "A recombination event, resulting in a new allele",
            "A recessive allele"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 834,
        "question": "Which of the following terms best describes the process of polar body formation?",
        "answer": "Polar bodies are haploid cells produced during ootidogenesis. They are smaller than secondary oocytes due to asymmetric cell division. During asymmetrical cell division a smaller volume of cytoplasm is partitioned into the polar bodies than to the secondary oocytes. This makes polar bodies not viable for fertilization. Polar bodies are often degraded, but they can also remain in the human body.",
        "suggested_answer": "Asymmetrical cellular division",
        "options": [
            "Nondisjunction",
            "Symmetrical cellular division",
            "Asymmetrical cellular division",
            "Apoptosis"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 835,
        "question": "Which best describes the structure of the alveoli?",
        "answer": "The alveoli's structure maximizes the efficient transfer of gas from air to the capillaries and vice versa. Therefore the contact point between air and the capillaries needs to be as thin as possible so gas has only a short distance to diffuse. Alveoli are therefore made up of a thin layer of epithelial cells that are in direct contact with endothelial cells in the capillaries.",
        "suggested_answer": "A thin layer of epithelial cells directly connected to endothelial cells in capillaries",
        "options": [
            "A thick layer of epithelial cells separated from endothelial cells in capillaries by interstitial fluid",
            "A thin layer of epithelial cells directly connected to endothelial cells in capillaries",
            "Tiny sacks that fill up with blood to facilitate the exchange of oxygen and nutrients",
            "A thin layer of epithelial cells separated from endothelial cells in capillaries by interstitial fluid",
            "A thick layer of epithelial cells directly connected to endothelial cells in capillaries"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 836,
        "question": "Which base pair can be found in a DNA molecule?",
        "answer": "Within a DNA molecule, there are specific nucleotide binding patterns, a phenomenon called complementary base pairing. Specific pyrimidine nucleotides can only bind to specific purine bases: cytosine binds to guanine via three hydrogen bonds and adenine binds to thymine via hydrogen bonds. Normally, within a DNA molecule, no other base pair combinations exist. These specific complementary base pairs allow DNA to take the form of a double helix. The double helix can be most simply described as a twisted ladder; the base pairs and their hydrogen bonds represent the rungs, and the sugar-phosphate backbone represents the sides of the ladder.",
        "suggested_answer": "Guanine-cytosine",
        "options": [
            "Guanine-cytosine",
            "Adenine-guanine",
            "Guanine-thymine",
            "Cytosine-thymine"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 837,
        "question": "During embryonic development, which stage begins after the formation of 128 cells and terminates with germ layer differentiation? ",
        "answer": "The blastula is defined as beginning following the cleavage stages. Following seven cleavages 128 cells have formed, and will later begin to form the differentiated layers of endoderm, mesoderm and ectoderm before going into the gastrula stage.",
        "suggested_answer": "Blastula stage",
        "options": [
            "Blastula stage",
            "Gastrula stage",
            "Cleavage stage",
            "Zygote stage",
            "Somitogenesis stage"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 838,
        "question": "What is the best definition for osmosis?",
        "answer": "In osmosis, water diffuses through selectively permeable membranes to regions where the concentration of solute is higher (hypertonic). Osmosis is not the movement of the solute particles.",
        "suggested_answer": "Diffusion of water through a selectively permeable membrane.",
        "options": [
            "Diffusion of solute particles to a hypotonic region.",
            "Diffusion of solute particles to a hypertonic region.",
            "Diffusion of water through a selectively permeable membrane.",
            "Active transport of solute particles."
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 839,
        "question": "Which of the following molecules is the final electron acceptor in the electron transport chain during cellular respiration?",
        "answer": "Oxygen is the final electron acceptor in the electron transport chain, showing the need for aerobic conditions to undergo such a process. ATP is produced as a product of the electron transport chain, while glucose and CO2 play a role in earlier processes of cellular respiration.",
        "suggested_answer": "O2",
        "options": [
            "CO2",
            "O2",
            "ATP",
            "Glucose"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 840,
        "question": "Substance A is gaining an electron from substance B. In this case, __________.",
        "answer": "Since substance B is losing an electron it is undergoing oxidation (oxidation is loss of electrons). Substance A is gaining an electron and so it is undergoing reduction (reduction is gain of electrons). Substance B is the reducing agent because it causes the reduction of substance A, and substance A is the oxidizing agent because it causes the oxidation of substance B.",
        "suggested_answer": "substance A is undergoing reduction and substance B is undergoing oxidation",
        "options": [
            "both substance A and B are being reduced",
            "substance A is undergoing reduction and substance B is undergoing oxidation",
            "both substance A and B are being oxidized",
            "substance A is undergoing oxidation and substance B is undergoing reduction"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 841,
        "question": "Evaporation is a stage in the water cycle that involves a phase change of water. Which of the following best describes this phase change?",
        "answer": "Evaporation is one of three main stages of the water cycle. In this process, water changes phases from the liquid to the gas phase.",
        "suggested_answer": "Liquid to gas",
        "options": [
            "Solid to gas",
            "Solid to liquid",
            "Liquid to gas",
            "Gas to liquid"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 842,
        "question": "Which of the following lists only structures that are part of the central nervous system?",
        "answer": "The components of the central nervous system are the brain and the spinal cord only. The remaining structures of the nervous system belong to the peripheral nervous system. The hemispheres of the brain and the spinal cord are part of the central nervous system. The hippocampus, cerebellum, hypothalamus, thalamus, and cerebral cortex are structures within the brain. The hippocampus is responsible for memory storage and the cerebellum is associated with balance and coordination. The cerebrum is responsible for processing information and higher thought. These structures are also part of the central nervous system.",
        "suggested_answer": "Cerebellum, hippocampus, spinal cord",
        "options": [
            "Spinal cord, cerebral cortex, cranial nerves",
            "Cranial nerves, parathyroid glands, cerebellum",
            "Cerebellum, hippocampus, spinal cord",
            "Left and right hemispheres of the brain, spinal cord, adrenal cortex",
            "Thalamus, hypothalamus, thymus"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 843,
        "question": "Which of the following best describes fungi? ",
        "answer": "Fungi are heterotrophs. A heterotroph is an organism that gains its nutrients by feeding on other organisms, or substances produced by them. Fungi obtain nutrients through absorption; some fungi act as decomposers that break down dead organic matter, some can be parasites, and others can exist in a mutually beneficial relationship with a host. ",
        "suggested_answer": "heterotroph",
        "options": [
            "Chemoautotroph",
            "Lithoautotroph",
            "Phototroph",
            "heterotroph",
            "Autotroph"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 844,
        "question": "When does chromosome duplication occur?",
        "answer": "It is important to note that chromosome duplication happens before mitosis, so interphase is correct. More specifically, it happens in the S stage of interphase.",
        "suggested_answer": "Interphase",
        "options": [
            "Interphase",
            "Prometaphase",
            "G0 Stage",
            "Prophase",
            "Metaphase "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 845,
        "question": "What property of water allows it to have an unusually high boiling point?",
        "answer": "Boiling point is generally determined by a few factors that are directly related to molecular weight and intermolecular forces. In general, lighter molecules have lower boiling points and molecules with stronger intermolecular forces have higher boiling points.",
        "suggested_answer": "It can form hydrogen bonds",
        "options": [
            "It has a low molecular weight",
            "It is in a bent shape",
            "It is a small molecule that can pack tightly",
            "It contains oxygen",
            "It can form hydrogen bonds"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 846,
        "question": "Forest 1 has A, B, C, and D trees. There are five A trees, nine B trees, ten C tree, and six D trees. Forest 2 has A, C, and D trees. There are ten A trees, fourteen C trees, and seven D trees. Forest 1 demonstrates __________ over forest 2. ",
        "answer": "Species diversity is the variety of organisms that make up a community. There are two parts of species diversity: species richness and relative abundance. Species richness refers to the number of different species in the community. In this example, forest 1 has greater species richness and diversity over forest 2. ",
        "suggested_answer": "species diversity and species richness",
        "options": [
            "species diversity and species richness",
            "species richness only",
            "species diversity, species richness, and greater biomass",
            "species diversity only",
            "greater biomass only"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 847,
        "question": "During the reaction catalyzed by ATP synthase, protons flow from __________.",
        "answer": "ATP Synthase work by converting the energy in the protons electrochemical gradient into production of ATP. This gradient is oriented across in the inner mitochondrial membrane so that protons is at a higher concentration in the intermembrane space than the matrix, and thus will flow from the intermembrane space to the matrix. This gradient is produced by the electron transport chain pumping protons from the matrix across the inner membrane to the intermembrane space.",
        "suggested_answer": "the mitochondrial intermembrance space to the mitochondrial matrix",
        "options": [
            "the cytosol to the mitochondrial intermembrance space",
            "the cytosol to the mitochondrial matrix",
            "the mitochondrial intermembrance space to the mitochondrial matrix",
            "the chloroplast intermembrance space to the chloroplast stroma",
            "the mitochondrial matrix to the mitochondrial intermembrance space"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 848,
        "question": "What does \"n\" mean when one says that cells are \"2n\" or \"n\"?",
        "answer": "It's important to know that \"n\" means how many sets or copies of chromosomes rather than how many chromosomes in general. If the latter were true, then \"2n\" would mean a cell has twice as many chromosomes as a cell that is \"n.\" In reality, all cells have all chromosomes, including gamates.",
        "suggested_answer": "\"n\" refers to the amount of chromosome copies in a cell. ",
        "options": [
            "\"n\" refers to the amount of nuclei in the cell.",
            "\"n\" refers to the number of daughter cells a cell can produce.",
            "\"n\" refers to the amount of chromosomes in a cell.",
            "\"n\" refers to the amount of nitrogen in the DNA of cells.",
            "\"n\" refers to the amount of chromosome copies in a cell. "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 849,
        "question": "Which of these is not found in prokaryotic cells?",
        "answer": "Prokaryotes do not contain membrane-bound organelles so they do not have a Golgi apparatus. Prokaryotic cells have cell membranes that include proteins and sugars that facilitate the passage and/or exclusion of certain substances into and out of the cell. Also, prokaryotic cells have ribosomes since they must also do translation, the act of translating RNA to protein in the cytoplasm. The cytosol is the area inside the cell membrane of the cell. The Golgi apparatus is membrane-bound and is thus only found in eukaryotic cells.",
        "suggested_answer": "Golgi apparatus",
        "options": [
            "Cytosol ",
            "Cell membrane ",
            "Membrane proteins",
            "Golgi apparatus",
            "Ribosome"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 850,
        "question": "Which of the following molecules would be inadequately absorbed during gall bladder obstruction?",
        "answer": "The gall bladder is important because of its storage and release of bile during digestion. Bile is important in the emulsification (or breaking up) of nonpolar fats in the aqueous small intestinal fluid. Cystic duct obstruction would result in bile being unable to reach the small intestine, and triglycerides would be unable to be properly emulsified and absorbed.",
        "suggested_answer": "Fatty acids",
        "options": [
            "All molecule types will be inadequately absorbed",
            "Fatty acids",
            "Monosaccharides",
            "Amino acids"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 851,
        "question": "Which cellular process produces ATP?",
        "answer": "Cellular respiration is the process through which ATP is produced through the oxidation of glucose.",
        "suggested_answer": "Cellular respiration",
        "options": [
            "Na+/K+ pump",
            "Cellular respiration",
            "DNA synthesis",
            "mitosis"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 852,
        "question": "Which of the following does NOT play a role in the emulsification of dietary lipids?",
        "answer": "Both bile salts and phospholipids are excreted from the bile duct into the upper small intestine (duodenum) to emulsify lipids. They are both amphipathic molecules, which means they have non-polar/lipophilic ends that can bind to lipids and polar/hydrophilic ends that can bind to water, thus emulsifying the lipids that they contact.",
        "suggested_answer": "Lipase",
        "options": [
            "Phospholipids",
            "Lipase",
            "Small intestine motility",
            "Bile salts"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 853,
        "question": "Which of the following is not one of the ways that Sertoli cells promote sperm maturation?",
        "answer": "Sertoli cells are located in the seminiferous tubules and aid in spermiogenesis. There are many ways that Sertoli cells facilitate the process of sperm maturation including the following: the contribution of testicular fluid, phagocytosis of excess cytoplasm, and the protection of sperm from the immune system. Leydig cells interstitial cells are responsible for secretion of testosterone.",
        "suggested_answer": "Secreting testosterone",
        "options": [
            "Protecting sperm from the immune system",
            "Contributing to testicular fluid",
            "Secreting testosterone",
            "Riding the sperm of excess cytoplasm through phagocytosis"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 854,
        "question": "Only about 10% of the energy stored in a trophic level can be converted to matter in the next trophic level. Which of the following is not a consequence of this fact?",
        "answer": "Though all of these statements are true, the fact that species can occupy different trophic levels depending on what they're eating is not a consequence of the fact that only 10% of all stored energy can ascend from one trophic level to the next. These facts are related, but one does not cause the other.",
        "suggested_answer": "A species can occupy different trophic levels, depending on what it is eating; for example, an omnivore is a primary consumer when it eats plant leaves, but could be a secondary or tertiary consumer when it eats other animal species.",
        "options": [
            "Producers always have the greatest biomass of any trophic level",
            "A species can occupy different trophic levels, depending on what it is eating; for example, an omnivore is a primary consumer when it eats plant leaves, but could be a secondary or tertiary consumer when it eats other animal species.",
            "Food chains almost never have more than four or five trophic levels",
            "Fluctuating population sizes at different trophic levels cause longer food chains to be less stable than shorter food chains"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 855,
        "question": "Which of the following is not true regarding the cork cambium?",
        "answer": "The cork cambium is a type of secondary meristem tissue found in the periderm. Cork cambium controls lateral growth; specifically, it is the source of secondary epidermis growth.",
        "suggested_answer": "It is the source of secondary xylem and phloem growth",
        "options": [
            "It is located in the periderm",
            "It is a type of secondary meristem ",
            "It is the source of secondary epidermis growth ",
            "It is the source of secondary xylem and phloem growth"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 856,
        "question": "Which of the following cells would not be part of the immune response when a pathogen is encountered by the body for the first time?",
        "answer": "The body has a generalized group of phagocytic cells that can attack microbes that have made it past the skin. Macrophages and neutrophils are the first cells to respond to an infection. Monocytes will later migrate from the bloodstream into the body tissues and phagocytize pathogens. T-cells are part of the acquired immune system and are only present after a specific pathogen had been previously encountered in the body.",
        "suggested_answer": "T-cells",
        "options": [
            "Macrophages",
            "Neutrophils",
            "T-cells",
            "Monocytes"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 857,
        "question": "Crossover of homologous chromosomes in meiosis occurs during which phase?",
        "answer": "The crossing over of homologous chromosomes occurs in prophase I of meiosis. Prophase I of meiosis is characterized by the lining up of homologous chromosomes close together to form a structure known as a tetrad. A tetrad is composed of four chromatids.",
        "suggested_answer": "Prophase I of meiosis",
        "options": [
            "Prophase I of meiosis",
            "Prophase II of meiosis",
            "Anaphase II of meiosis",
            "Anaphase I of meiosis"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 858,
        "question": "Which of the following is an example of a producer?",
        "answer": "A producer is an organism that makes its own food from the energy of the sun via photosynthesis. Green algae is a producer; it has chlorophyll pigments and can make glucose via photosynthesis. A frog is a consumer; a worm is a decomposer; a fungus is a saprophyte; a bear is a consumer.",
        "suggested_answer": "Green algae",
        "options": [
            "Fungus",
            "Frog",
            "Worm",
            "Green algae",
            "Bear"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 859,
        "question": "A student is asked to suggest a possible reason that snowshoe hares have white fur. The student proposes that snowshoe hares needed white fur, so over many generations natural selection acted to lighten their fur color until it was almost snow-white. Is this a reasonable theory?",
        "answer": "It is incorrect to think of natural selection as a response to a \"need.\" Natural selection will select among variants in a population, resulting in evolution. Individuals and populations cannot influence the course of natural selection based on wants or needs.",
        "suggested_answer": "No. It is incorrect to think about the changes that occur by natural selection as responses to a population's needs. Natural selection selects among variants in a population based on reproductive success. ",
        "options": [
            "No. It is incorrect to think about the changes that occur by natural selection as responses to a population's needs. Natural selection selects among variants in a population based on reproductive success. ",
            "Yes. Natural selection provides what organisms needs to survive and reproduce, increasing their fitness over time.",
            "Yes. The fitness of the hares will continue to increase, so traits that the hares need to increase their fitness will be selected for.",
            "There is not enough information to determine if the student's theory is reasonable."
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 860,
        "question": "Which of the following is not true regarding lagging stand synthesis?",
        "answer": "The lagging strand is the strand of parent DNA that runs in the opposite direction that the replication fork opens. Because DNA polymerase adds nucleotides in direction, RNA primers are added along the length of the newly single parent DNA strand to provide a hydroxyl group onto which DNA polymerase adds nucleotides. DNA polymerase adds nucleotides to the RNA primer until encountering another primer. These segments of newly synthesized DNA are called Okazaki fragments. DNA polymerase I removes RNA primers and replaces them with DNA. Ligase seals the gaps between DNA, forming a continuous strand.",
        "suggested_answer": "It is synthesized continuously ",
        "options": [
            "It requires many RNA primers",
            "It is synthesized in Okazaki fragments ",
            "It is synthesized continuously ",
            "Due to parent template strand orientation, synthesis runs in the opposite direction that the replication fork opens"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 861,
        "question": "Which of the following is true regarding female women post menopause?",
        "answer": "Menopause is a stage when female humans stop ovulating and menstruating; therefore, they can no longer have children. Menopause typically occurs between 45 to 55 years. Common symptoms include hot flashes, vaginal dryness, and mood changes. Menopause occurs due to the end of estradiol and progesterone production by the ovaries.",
        "suggested_answer": "There is a cessation of ovulation and fertility ",
        "options": [
            "There is a cessation of ovulation and fertility ",
            "They have a thick endometrial lining ",
            "They ovulate once per month",
            "They exhibit increased fertility due to a higher oocyte release rate"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 862,
        "question": "A lipid with three fatty acids linked to glycerol is a __________.",
        "answer": "A fat is a lipid with three fatty chains linked by an ester linkage to glycerol.",
        "suggested_answer": "triglyceride",
        "options": [
            "steroid",
            "diacylglycerol",
            "triglyceride",
            "phospholipid"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 863,
        "question": "What would happen to a group of lipids in a polar solvent?",
        "answer": "Lipids are composed of hydrocarbon chains and are very nonpolar. Polar solvents interact well with polar solutes, but do not solvate nonpolar solutes. When lipids are placed in a polar solvent, they will group together to minimize surface contact with the solvent. These droplets of lipids, or micelles, act like containers for the lipid, keeping them grouped together instead of being distributed through the solvent.",
        "suggested_answer": "The lipids would group together",
        "options": [
            "There is no way to predict what would happen",
            "The lipids would interact readily with the solvent",
            "The lipids would group together",
            "The lipids would precipitate out of the solvent"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 864,
        "question": "Which of the following occurs during depolarization?",
        "answer": "Depolarization results from the opening of voltage-gated sodium channels during the initiation of an action potential. Sodium has an electrochemical gradient that causes it to enter the cell when the channels open, resulting in a net flow of positive ions into the cell that increases the membrane potential. This increase is known as depolarization.",
        "suggested_answer": "Sodium diffuses into the cell",
        "options": [
            "Sodium diffuses into the cell",
            "Potassium diffuses into the cell",
            "Sodium diffuses out of the cell",
            "Calcium diffuses into the cell",
            "Potassium diffuses out of the cell "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 865,
        "question": "Where does the Calvin Cycle take place?",
        "answer": "The Calvin Cycle (dark reactions) take place in the stroma of the chloroplasts, which is the aqueous space inside the organelle. ",
        "suggested_answer": "Stroma",
        "options": [
            "Mitochondrial lumen",
            "Cytoplasm",
            "Thylakoid membrane",
            "Stroma",
            "Chloroplast outer membrane"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 866,
        "question": "The citric acid cycle takes place in the __________.",
        "answer": "The citric acid cycle takes place in the mitochondrial matrix.",
        "suggested_answer": "mitochondrial matrix",
        "options": [
            "intermembrane space",
            "inner mitochondrial membrane",
            "cytosol",
            "mitochondrial matrix"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 867,
        "question": "Which biotechnology method will allow you to distinguish between identical twins?",
        "answer": "Fingerprints are different in all individuals, even identical twins. Due to RNA processing, or post-transcription modification, the grooves of a finger are different even in individuals with identical DNA.",
        "suggested_answer": "Standard fingerprinting",
        "options": [
            "Standard fingerprinting",
            "Southern blot",
            "DNA fingerprinting",
            "Restriction fragment length polymorphism (RFLP) Analysis"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 868,
        "question": "A strand of RNA is composed of 20% adenine and 30% guanine.",
        "answer": "Chargaff's rule only applies to DNA. RNA is single-stranded, and thus, no base pairing occurs.",
        "suggested_answer": "Cannot determine from the given information",
        "options": [
            "20% Adenine, 30% Guanine, 30% Thymine, 20% Cytosine",
            " ",
            "Cannot determine from the given information",
            "20% Adenine, 30% Guanine, 30% Uracil, 20% Cytosine",
            "20% Adenine, 30% Guanine, 20% Thymine, 30% Cytosine",
            " ",
            "20% Adenine, 30% Guanine, 20% Uracil, 30% Cytosine",
            " "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 869,
        "question": "Name the following compound:",
        "answer": "When naming covalent compounds, it is important to use a prefix before each element in order to designate how many atoms are in the compound. One key exception is when you only have one atom at the beginning of the compound (such as in this question). You will never start a covalent compound with \"mono-\". The prefix for five is \"penta-\", so the name of this covalent compound is phosphorus pentafluoride. The name indicates that there is one phosphorus atom bound to five fluorine atoms.",
        "suggested_answer": "Phosphorus pentafluoride",
        "options": [
            "Phosphorus tetrafluoride",
            "Monophosphorus pentafluoride",
            "Phosphorus pentafluoride",
            "Phosphorus fluoride"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 870,
        "question": "Which of the following cells are responsible for speeding up the action potentials along the axon of a neuron?",
        "answer": "The correct answer is Schwann cells. Schwann cells are cells that produce the myelination present on the outer covering of the axon of the neuron. This lipid-rich material helps facilitate the movement of the action potential along the axon from the axon hillock to the axon terminal branches.",
        "suggested_answer": "Schwann cells",
        "options": [
            "Glial cells",
            "Astrocytes",
            "Schwann cells",
            "Myocytes",
            "Ependymal cells"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 871,
        "question": "In messenger RNA, each codon specifies a particular __________.",
        "answer": "In messenger RNA, each codon is three nucleotides that codes for a particular amino acid during translation. Purines and pyrimidines are types of nucleotides on DNA and RNA. The genetic code is redundant, but each codon only codes for one amino acid.",
        "suggested_answer": "amino acid",
        "options": [
            "nucleotide",
            "amino acid",
            "purine",
            "pyrimidine"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 872,
        "question": "Bacterial ribosomes contain a __________ and __________ subunit. This forms a __________ ribosome.",
        "answer": "Recall that bacterial ribosomes contain a 30S and a 50S subunit. The ribosome itself is a 70S ribosome, as the combination of the two subunits results in a smaller mass than the two separate units. Eukaryotic ribosomes contain a 40S and 60S subunit and form an 80S ribosome.",
        "suggested_answer": "30S . . . 50S . . . 70S",
        "options": [
            "30S . . . 50S . . . 70S",
            "30S . . . 50S . . . 80S",
            "40S . . . 60S . . . 100S",
            "40S . . . 60S . . . 80S"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 873,
        "question": "A postsynaptic neuron has undergone a mutation that renders its SNARE proteins nonfunctional. What is the result of this mutation?",
        "answer": "The SNARE proteins are responsible for allowing vesicles filled with neurotransmitter to fuse with the cell membrane at the synaptic cleft, resulting in exocytosis. Without these proteins, the neurotransmitter cannot propagate the signal to any other cells.",
        "suggested_answer": "Neurons are unable to deliver neurotransmitter into the synaptic cleft",
        "options": [
            "Neurons are unable to deliver neurotransmitter into the synaptic cleft",
            "Neurons are unable to synthesize neurotransmitter",
            "Neurons are unable to maintain resting membrane potential",
            "Action potentials are unable to propagate down the postsynaptic axon",
            "None of the other answers"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 874,
        "question": "Which of the following best represent features of neuron cells?",
        "answer": "Neuron cells are functional units of nervous tissue that transmit electrical signals. Neurons typically are composed of a soma, dendrites, and an axon. The soma is the body of the cell, the dendrites are branched projections that receive signals, and the axon conducts signals away from the cell body.",
        "suggested_answer": "Axons and dendrites",
        "options": [
            "Axons and dendrites",
            "Axons and sarcoplasmic reticulum",
            "Axons",
            "Sarcoplasmic reticulum"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 875,
        "question": "Which of the following describes where motor neuron signals originate and end?",
        "answer": "Motor neurons efferent neurons transmit information that produces movement. This signal originates in the spinal cord and terminates in muscles, resulting in movement.",
        "suggested_answer": "They originate in the spinal cord and end in the muscles",
        "options": [
            "They originate in the spinal cord and end in the brain",
            "They originate in the spinal cord and end in the muscles",
            "They originate in the muscles and end in the spinal cord",
            "They originate in the brain and end in the spinal cord"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 876,
        "question": "Thousands of years ago, the environment on M311 was almost entirely dry and the Freg had evolved to suit that environment. Over time, the environment became more water-based and the Freg developed webbed feet and gills. However, as a result of the asteroid catastrophe, the climate returned to a more hot, dry, desert-like climate. As a result, the Freg began to to lose their webbed feet and gills. What is this process called?",
        "answer": "The correct answer to this question is devolution. Devolution refers to when a species reverts to an earlier, more primitive physical form as a result of environmental demands.",
        "suggested_answer": "Devolution",
        "options": [
            "Devolution",
            "Hydrocorporeal restoration",
            "Regressive growth",
            "Amphibiatory genesis",
            "Anti-evolution"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 877,
        "question": "How would a desert tortoise's plasma membrane differ from the plasma membrane of a dog that lives in an air conditioned house?",
        "answer": "The plasma membrane is able to maintain the appropriate level of fluidity by manipulating a variety of factors. More cholesterol in the membrane reduces its permeability, which is useful in hot conditions. Desert animals would also be expected to have very few unstaurated fatty acids, and the fatty acid tails would be longer. All of these factors would help the plasma membrane not be too fluid in the hot sun.",
        "suggested_answer": "The tortoise would have more cholesterol in its membrane compared to the dog.",
        "options": [
            "The tortoise would have shorter fatty acid tails in its membrane compared to the dog.",
            "The tortoise would have more cholesterol in its membrane compared to the dog.",
            "The tortoise would have more unstaurated fatty acid tails in its membrane compared to the dog.",
            "The tortoise would have less phospholipids in its bilayer compared to the dog."
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 878,
        "question": "Which is a characteristic of the kingdom animalia?",
        "answer": "Organisms within the kingdom animalia have eukaryotic cells, are multicellular, and are heterotrophic (receives its nutrition through consuming other organisms). ",
        "suggested_answer": "Heterotrophic",
        "options": [
            "Heterotrophic",
            "Prokaryotic cells",
            "Autotrophic",
            "Unicellular"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 879,
        "question": "What level of protein structure is influenced primarily by hydrogen bonding?",
        "answer": "The formation of -helices and -pleated sheets constitute the secondary structure of a protein. These conformations are reinforced by hydrogen bonds between the atoms in the polypeptide chain.",
        "suggested_answer": "Secondary structure",
        "options": [
            "Primary structure",
            "Secondary structure",
            "Tertiary structure",
            "Quaternary structure"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 880,
        "question": "Which of the following is NOT true of the cytoplasmic protein structures known as tonofibrils?",
        "answer": "Tonofibrils are groups of keratin tonofilaments (intermediate filaments) most commonly found in the epithelial tissues, not endocrine tissues, and which play an important structural role in cell makeup.",
        "suggested_answer": "They are primarily found in endocrine tissues.",
        "options": [
            "The protein filaggrin is thought to hold them together.",
            "They are primarily found in endocrine tissues.",
            "They are primarily made of kertain tonofilaments.",
            "They are most typically anchored to the cytoskeleton.",
            "They converge at desmosomes and hemidesmosomes."
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 881,
        "question": "Which of the following is not true of protists?",
        "answer": "All of these answers are true, except the statement that protists are definitionally unicellular. Protists are an incredibly diverse group of organisms that are difficult to classify because of the vast differences that exist between protist species; it is easier to classify protists based on what they are not, rather than what they are. Multicellular protists include species of seaweeds called kelp. Though kelp resemble plants, they are actually multicellular groups of protists that lack true stems, roots or leaves (though kelp often have structures resembling these plant structures).",
        "suggested_answer": "All protists are unicellular by definition",
        "options": [
            "A \"protist\" is broadly recognized as a eukaryote that is not an animal, fungus, or plant",
            "Protists are diverse in part due to endosymbiosis, a process by which small, unicellular organisms are engulfed by larger unicellular organisms and eventually evolve to become organelles",
            "All protists are unicellular by definition",
            "Malaria, red algae, green algae, and dinoflagellates are all examples of protists"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 882,
        "question": "Which of the following terms is defined as the process by which water breaks polymers down into monomers?",
        "answer": "Polymers are broken down into monomers via hydrolysis. Hydrolysis is the reverse of a condensation synthesis reaction. In hydrolysis, adding water molecules breaks the bonds between monomers. In this reaction polymers are broken down when the hydrogen of the water molecules attaches to one monomer and the hydroxyl attaches the next monomer.",
        "suggested_answer": "Hydrolysis",
        "options": [
            "Hydrolysis",
            "Condensation synthesis reaction",
            "Anabolic reaction",
            "None of these"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 883,
        "question": "Which of the following is not true regarding adaptation?",
        "answer": "Adaptation can be defined as the gradual outcome of evolution that makes an organism or group of organisms better suited to live in a given habitat. Adaptation is a result of natural selection and increases the chance of survival and reproduction. This outcome can result from either a loss of an ancestral feature or the development of a new trait.",
        "suggested_answer": "They result in the divergence of species",
        "options": [
            "They increase the chance of survival and reproduction",
            " ",
            "It makes an organism better suited to live in a given habitat",
            "It is produced by natural selection",
            "They result in the divergence of species"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 884,
        "question": "What is the role of centrosomes in mitosis?",
        "answer": "Centrosomes are organelles composed of two centrioles and function as microtubule organizing centers. Centrosomes are duplicated during S phase of interphase, and migrate to opposite poles of the dividing cell to form the mitotic spindle apparatuses. The mitotic spindles originate at the centrosomes, where microtubule regulation occurs.",
        "suggested_answer": "Organization of microtubules",
        "options": [
            "Separation of chromosomes to opposite poles",
            "Arrangement of sister chromatids at the metaphase plate ",
            "Organization of microtubules",
            "To aid in cytokinesis"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 885,
        "question": "In an area with primary succession occurring, which type of wildlife would be likely to grow first?",
        "answer": "Primary succession occurs in an environment without previous life, or a barren habitat. The first organisms to grow (called pioneer species) are fast growing, hardy species.",
        "suggested_answer": "Lichen",
        "options": [
            "Pine trees",
            "Perennials",
            "Oak trees",
            "Lichen"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 886,
        "question": "Upon being approached by a bear in the wilderness, you notice your heart beat faster. Which part of the nervous system is responsible for this feeling?",
        "answer": "The sympathetic nervous system is one branch of the autonomic nervous system in the body. The autonomic nervous system is not under conscious control. The sympathetic system is responsible for our \"fight or flight\" response. An increase in heart rate when presented with the dangers of being around a bear is due to the sympathetic nervous system. The increase in heart rate is your body's way of increasing blood supply to the body to help you escape (\"flight\") from the bear and return to safety.",
        "suggested_answer": "Sympathetic nervous system",
        "options": [
            "Sympathetic nervous system",
            "Enteric nervous system",
            "Somatic nervous system",
            "Parasympathetic nervous system"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 887,
        "question": "Which of the following scientists hypothesized that organisms can pass down traits acquired during their lifetimes?",
        "answer": "Lamarck hypothesized, amongst other things, that organisms could pass down traits acquired during life from generation to generation. Under Lamarck's theory of use and disuse, a man who worked in a factory and developed strong arms would pass the strong arms trait to his offspring. This hypothesis has largely been rejected by modern genetics.",
        "suggested_answer": "Jean-Baptiste Lamarck",
        "options": [
            "Carolus Linnaeus",
            "Gregor Mendel",
            "Charles Darwin ",
            "Jean-Baptiste Lamarck"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 888,
        "question": "Which digestive system organ secretes enzymes vital for lipid digestive, and where are these enzymes introduced into the digestive tract?",
        "answer": "Lipase is synthesized and secreted from the pancreas into the duodenum of the small intestine. Lipase plays a key role in the digestion of lipids.",
        "suggested_answer": "Enzymes from the pancreas are introduced into the duodenum",
        "options": [
            "Enzymes from the liver are introduced into the stomach",
            "Enzymes from the pancreas are introduced into the duodenum",
            "Enzymes from the pancreas are introduced into the stomach",
            "Enzymes from the liver are introduced into the colon"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 889,
        "question": "Which of the following choices best describes how antibiotics inhibit translation?",
        "answer": "Antibiotics are able to inhibit protein synthesis in prokaryotes in a number of ways. The specific method of inhibition depends on the antibiotic that is used. Examples of the antibiotics that target translation are rifamycin, linezolid, and tetracyclines. Rifamycin inhibits RNA polymerase and the resulting synthesis of mRNA. Linezolid blocks the formation of the translation initiation complex. Tetracyclines obstruct the aminoacyl A site of ribosomes. Inhibiting translation is an effective way to kill bacteria and treat bacterial infections. Antibiotics specifically target prokaryotic cells, ensuring no harm to the host eukaryotic cells. ",
        "suggested_answer": "All of these methods",
        "options": [
            "Antibiotics inhibit DNA polymerase from synthesizing mRNA",
            "They block the aminoacyl site of ribosomes",
            "They prevent the formation of the translation initiation complex ",
            "All of these methods"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 890,
        "question": "Which of the following could be included in the diet of an omnivore?",
        "answer": "Omnivores are organisms that have a diet that includes of the ingestion of both plant and animal tissues. They have also been known to ingest bacteria and fungi.",
        "suggested_answer": "All of these",
        "options": [
            "Fungi",
            "All of these",
            "Animal tissue",
            "Plant tissue"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 891,
        "question": "Which endocrine gland in the brain regulates the release of hormones from the anterior pituitary?",
        "answer": "The hypothalamus releases hormones that control the release of hormones from the anterior pituitary, which in turn controls the release of hormones from other endocrine glands. The anterior pituitary is also controlled by several negative feedback systems based on hormones released throughout the body and their effects.",
        "suggested_answer": "Hypothalamus",
        "options": [
            "Pineal gland",
            "Posterior pituitary",
            "Thyroid gland",
            "Hypothalamus"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 892,
        "question": "Which of the following correctly illustrates how plants undergo photosynthesis?",
        "answer": "Plants are considered photoautotrophs, meaning that they can use the electromagnetic energy from sunlight to generate organic chemical energy. The process for this conversion is photosynthesis, which takes place in the chloroplasts of the plant cells. Sunlight excites electrons, which donate energy to form NADH and ATP. These compounds enter the Calvin cycle, which converts carbon dioxide to glyceraldehyde-3-phosphate, a sugar that can easily be converted to glucose and used for energy.",
        "suggested_answer": "Plants use the energy of the sun to convert carbon dioxide and water into glucose and oxygen",
        "options": [
            "Plants use the energy of the sun to convert glucose and oxygen into carbon dioxide and water",
            "Plants use the energy of the sun to convert glucose into ATP",
            "Plants use the energy of the sun to convert ATP into glucose",
            "Plants use the energy of the sun to break down glucose for energy",
            "Plants use the energy of the sun to convert carbon dioxide and water into glucose and oxygen"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 893,
        "question": "Which of the following definitions does the term menarche refer to?",
        "answer": "Menarche is the term that refers to the onset of menstrual bleeding in female humans. The timing of menarche is influenced by variety of factors including genetics and environmental factors. Age of menarche is typically between 12 to 15 years old.",
        "suggested_answer": "When menstrual periods begin in human females",
        "options": [
            "When menstrual periods begin in human females",
            "Contractions preceding vaginal delivery ",
            "Implantation of the embryo in human females",
            "When menstrual periods stop in human females"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 894,
        "question": "List the order of major events in the viral multiplication cycle of a lytic bacteriophage.",
        "answer": "Because the question refers to a bacteriophage, we know that the two answers beginning with endocytosis are incorrect; endocytosis begins the multiplication cycle of an enveloped virus. Bacteriophages are naked viruses, contained within a protein capsule with no outer membrane.",
        "suggested_answer": "Adsorption, penetration, synthesis, assembly, release",
        "options": [
            "Adsorption, penetration, synthesis, assembly, release",
            " Endocytosis, replication, assembly, budding, exocytosis",
            "Endocytosis, replication, assembly, budding",
            "Absorption, penetration, synthesis, assembly, release",
            "Adsorption, penetration, synthesis, lysis"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 895,
        "question": "Which correctly lists all the valves of the heart?",
        "answer": "There are four valves in the heart. The tricuspid valve separates the right atrium from the right ventricle. The pulmonary valve separates the right ventricle from the pulmonary arteries. The bicuspid valve separates the left atrium from the left ventricle. The aortic valve separates the left ventricle from the aorta. These valves separate the chambers of the heart, and prevent back flow from one chamber to the other. The tricuspid valve is also known as the right atrioventricular valve, and the bicuspid valve is also known as the left atrioventricular valve, and the mitral valve.",
        "suggested_answer": "Tricuspid valve, pulmonary valve, bicuspid valve, and aortic valve",
        "options": [
            "Tricuspid valve, bicuspid valve, and aortic valve",
            "Tricuspid valve and aortic valve",
            "Tricuspid valve, bicuspid valve, aortic valve, and brachial valve",
            "Tricuspid valve, pulmonary valve, and aortic valve",
            "Tricuspid valve, pulmonary valve, bicuspid valve, and aortic valve"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 896,
        "question": "If B is a dominant allele conferring a purple flower and b is the recessive allele conferring a white flower, a homozygote recessive plant will have what phenotype?",
        "answer": "The phenotype is the observable characteristic of an individual. In this example, a homozygous recessive plant will have a white flower phenotype and a genotype of aa.",
        "suggested_answer": "White flowers",
        "options": [
            "aa",
            "Aa",
            "White flowers",
            "Purple flowers"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 897,
        "question": "Which of the following is true regarding the association between intercurrent diseases and pregnancy?",
        "answer": "Intercurrent diseases are diseases that are not related to being pregnant (they were pre-existing) but may worsen during pregnancy. These diseases pose a risk to the developing fetus and special attention needs to be given to the drugs taken to treat the exiting illness because they may negatively impact the fetus. In the case of infections, there is a risk of vertical transmission to the fetus.",
        "suggested_answer": "The diseases can worsen as a result of the pregnancy",
        "options": [
            "The diseases can worsen as a result of the pregnancy",
            "None of these",
            "These diseases do not impact the pregnancy",
            "They are not pre-existing",
            "Drugs taken to treat the disease have no impact on the fetus"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 898,
        "question": "The start codon (AUG) codes for which of the following amino acids?",
        "answer": "The start codon (AUG) codes for the amino acid methionine. The start codon is the nucleotide triplet on the mRNA strand that signals the start of the codons to be translated. Each codon triplet binds to a complementary anticodon triplet on a tRNA molecule that carries a corresponding amino acid. ",
        "suggested_answer": "Methionine",
        "options": [
            "Alanine",
            "Methionine",
            "Histidine",
            "Lysine"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 899,
        "question": "Which of the following is an example of a polysaccharide?",
        "answer": "Chitin is a type of polysaccharide that is present in the exoskeletons of arthropods, and is the primary substance of the cell wall of fungi. In general, polysaccharides are chains of simple sugars. Another example of a polysaccharide is starch. Waxes are types of lipids. Hemoglobin is a protein, which is made of amino acids. DNA is a nucleic acid, which is a polymer of nucleotides.",
        "suggested_answer": "Chitin",
        "options": [
            "Hemoglobin",
            "Chitin",
            "Wax",
            "DNA"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 900,
        "question": "Eukaryotes are capable of producing ATP with or without oxygen. In comparison, prokaryotes __________.",
        "answer": "One way to divide prokaryotes is into aerobes and anaerobes. Aerobes are organisms that can survive and grow in the presence of oxygen while anaerobes did not require oxygen for survival and growth. All aerobes can produce ATP with or without oxygen (though they may need oxygen for survival. However some anaerobes are harmed by the presence of oxygen (obligate anaerobes). These anaerobes can produce ATP through glycolysis or anaerobic respiration, where another molecule besides oxygen is used as the final electron acceptor for the electron transport chain.",
        "suggested_answer": "None of these; it depends on the type of prokaryote.",
        "options": [
            "only produce ATP when oxygen is present",
            "None of these; it depends on the type of prokaryote.",
            "only produce ATP when oxygen is not present",
            "are also capable of producing ATP with and without oxygen",
            "do not produce ATP"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 901,
        "question": "Which of the following types of bonds characterizes the secondary structure of protein folding?",
        "answer": "The secondary structure of protein folding is two-dimensional and can take two forms: alpha helices and beta-pleated sheets. The secondary structure is characterized by hydrogen bonds between peptide groups.",
        "suggested_answer": "Hydrogen bonds",
        "options": [
            "Salt bridges",
            "Hydrogen bonds",
            "Peptide bonds",
            "Disulfide bonds"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 902,
        "question": "Why are organisms at higher trophic levels more susceptible to the effects of biological magnification than are organisms at lower trophic levels?",
        "answer": "Biological magnification is most commonly a problem with compounds that are toxic, slow to degrade, and can accumulate in organisms' bodies such as DDT and methylmercury. For example, consider methylmercury. Methylmercury breaks down into less toxic forms very slowly over time and it is excreted from organisms' bodies relatively slowly. Though aquatic plankton may only absorb relatively small or trace amounts of methylmercury over their life spans, they are likely unable to excrete the chemical at the rate that they absorb it into their bodies. Small fish that feed on the plankton must eat large amounts of plankton to survive in doing so, they are exposed to all of the methylmercury that has accumulated in the bodies of the plankton. They, too, are unable to excrete this methylmercury at the rate they absorb it. When larger fish feed on the small fish, these larger fish are exposed to and absorb the methylmercury contained in the small fish. They are likely unable to excrete the majority of this methylmercury at the rate that they continue consuming mercury found in the small fish. This process continues upwards through the trophic levels, until organisms at high trophic levels, such as birds of prey (or humans!) are exposed to high levels of methylmercury that have been concentrated/magnified up through the trophic levels.",
        "suggested_answer": "Toxins found at trace levels in low trophic levels become concentrated (magnified) through the higher trophic levels. If an organism is unable to excrete toxins at the rate it absorbs them, then it will store excess toxins which will later be absorbed by whatever higher trophic level organism preys on it.",
        "options": [
            "Organisms at higher trophic levels tend to eat a larger proportion of their own weight in food, which means that they have a greater risk of being exposed to and concentrating biological toxins within their tissues.",
            "Toxins found at trace levels in low trophic levels become concentrated (magnified) through the higher trophic levels. If an organism is unable to excrete toxins at the rate it absorbs them, then it will store excess toxins which will later be absorbed by whatever higher trophic level organism preys on it.",
            "All other answers are correct",
            "Animals at higher trophic levels are often larger than animals at lower trophic levels; as a result, they have more surface area through which to absorb environmental toxins. "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 903,
        "question": "Which of the following is not a type of mutation?",
        "answer": "Mutations are changes in a cell\u2019s genomic DNA sequence. Types of mutation include insertion, deletion, translocation, inversion, and duplication. An insertion is the addition of a nucleotide somewhere in the DNA; a deletion is the opposite. A translocation mutation involves the physical movement of DNA segments, and can occur within a chromosome or between different chromosomes. Genetic drift, on the other hand, is a type of sampling error that leads to the perceived allele frequency changing among generations.",
        "suggested_answer": "Genetic drift",
        "options": [
            "Insertion",
            "Translocation",
            "Inversion",
            "Genetic drift"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 904,
        "question": "Which of the following statements best represents antigen presentation in an acquired immune response to a pathogen?",
        "answer": "An acquired immune response to a secondary infection by a pathogen results in presentation of antigen to residual memory helper T-cells, memory T-cells, and memory B-cells. Upon recognizing the antigen, memory T-cells can become cytotoxic T-cells and target the infected region. The process of presentation promotes direct expansion of the existing population of immune cells already capable of responding to the pathogen. Immunological memory provides a more rapid response time to combat the pathogen during the second exposure.",
        "suggested_answer": "Antigen from the pathogen is presented simultaneously to memory helper T-Cells, memory T-cells, and memory B-cells",
        "options": [
            "Antigen from the pathogen is presented simultaneously to memory helper T-Cells, memory T-cells, and memory B-cells",
            "Antigen from the pathogen is presented specifically to memory T-cells to activate cytotoxic T-cells to clear the infection",
            "Antigen from the pathogen is presented to memory helper T-cells to stimulate a new round of B-cell clonal selection and antibody response",
            "Antigen presenting-cells present antigens memory helper T-cells, followed by memory B-cells followed, by memory T-cells"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 905,
        "question": "The unique sequence of amino acids in a protein is its __________.",
        "answer": "The primary structure of a protein is its amino acid sequence. The coils and folds of a protein are its secondary structure. Irregular contortions in the protein structure due to interactions between amino acid side chains is the tertiary structure. The overall structure when two or more polypeptides aggregated is the quaternary structure. ",
        "suggested_answer": "primary structure",
        "options": [
            "primary structure",
            "tertiary structure",
            "secondary structure",
            "quaternary structure"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 906,
        "question": "What is the end result of translation?",
        "answer": "Translation is the process of making a polypeptide chain from an mRNA template. No new molecules of RNA or DNA are synthesized during this process. tRNA is used to bring amino acids to the ribosome, binding an anticodon to the exposed codon of mRNA. The amino acid is then released from the tRNA and added to the growing chain of amino acids attached to the ribosome. When the ribosome reaches a stop codon, it releases the mRNA strand and amino acid sequence. The amino acid sequence is the final result of translation, and is known as a polypeptide.",
        "suggested_answer": "A polypeptide chain",
        "options": [
            "A polypeptide chain",
            "An enzyme",
            "A molecule of RNA",
            "A molecule of DNA",
            "A transport vesicle"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 907,
        "question": "What is the difference between anaphase I and anaphase II?",
        "answer": "During anaphase I, it is just like mitosis. There is a pair of homologous chromosomes (two X's) and they separate into two daughter cells. Anaphase II is a continuation of cellular division so instead of separating a pair of homologous chromosomes it separates sister chromatids of one chromosome (one X) into two daughter cells.",
        "suggested_answer": "During anaphase I, the pair of homologous chromosomes separate from each other and then in anaphase II the sister chromatids separate from each other.",
        "options": [
            "During anaphase I, the pair of homologous chromosomes separate from each other and then in anaphase II the sister chromatids separate from each other.",
            "During anaphase I, the chromosomes line up along the metaphase plate and in anaphase II, the chromosomes separate from each other.",
            "During anaphase I, the pair of homologous chromosomes separate from each other and then in anaphase II a different pair of homologous chromosomes separate from each other.",
            "There are no differences between the two.",
            "During anaphase I, the sister chromatids separate from each other and in anaphase II the pair of homologous chromosomes separate from each other."
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 908,
        "question": "The axon hillock connects what two areas of a neuron?",
        "answer": "The axon hillock is the area of a neuron that connects the soma and axon. This is also the area of the of the neuron that \"decides\" whether an action potential would be initiated or not, depending on whether the cell has reached threshold or not. ",
        "suggested_answer": "Axon and soma",
        "options": [
            "Axon and soma",
            "Dendrite and synapse",
            "Soma and dendrite",
            "Axon and dendrite"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 909,
        "question": "Which of the following statements is true?",
        "answer": "Electrons are notably smaller than nucleons (protons and neutrons), however, they have an equal amount of charge to protons. This is why an atom with the same amount of protons as electrons is considered electrically neutral.",
        "suggested_answer": "Electrons are smaller in size than nucleons, but have equal charge",
        "options": [
            "Electrons are larger than protons, which means they must have less charge than protons",
            "Electrons are smaller in size than nucleons, but have equal charge",
            "The nucleus of an atom is electrically neutral",
            "Electrons are close in proximity to the atomic nucleus",
            "Electrons are smaller than protons, which means they have less charge than protons"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 910,
        "question": "The parietal cells of the stomach secrete acid through the use of a __________.",
        "answer": "The parietal cells use a proton pump to release hydrogen ions into the stomach in the form of hydrochloric acid. This pump is the target of PPI (proton pump inhibitor) medications that work to reduce stomach acid.",
        "suggested_answer": "proton pump",
        "options": [
            "proton pump",
            "ATP synthase",
            "hydrochloric acid channel",
            "sodium-potassium pump",
            "None of these"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 911,
        "question": "Which choice below properly matches the stage of mitosis with its description?",
        "answer": "The four mitotic stages included among these choices are prophase, metaphase, anaphase, and telophase, which occur in that order. The initial stage, prophase, involves chromosome condensation from chromatin and the formation of the spindle apparatus from microtubules. In metaphase, chromosome pairs line up in the center of the cell at a location called the metaphase plate. Anaphase involves the shortening of the microtubular spindle fibers, which forces chromosome pairs to migrate to opposite ends of the cell. Finally, telophase involves the formation of two new nuclei. Following telophase is cytokinesis, the final separation of the cytoplasm and other organelles of the two cells, and the sealing of the plasma membrane. Some references will include interphase, a period of rest, chromosome replication, and cellular checkpoints. Though not a part of mitosis itself, interphase separates successive mitotic divisions and acts as a time of cellular rest. In any case, the only properly matched definition above was that of telophase, the final stage of mitosis.",
        "suggested_answer": "Telophase: two new nuclei form, each containing a group of migrated chromosomes",
        "options": [
            "Telophase: two new nuclei form, each containing a group of migrated chromosomes",
            "Metaphase: chromosome pairs separate and migrate to opposite ends of the cell, propelled by the shortening mitotic spindle",
            "Anaphase: chromosomes replicate and the cell proceeds through multiple pre-mitotic checkpoints",
            "Prophase: pairs of chromosomes line up along the center of the cell",
            "Anaphase: chromatin condenses and a structure made up of microtubules forms"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 912,
        "question": "Which is a species?",
        "answer": "Arthropoda is a phylum, eukarya is a domain, and protista is a kingdom. There are eight taxonomic categories (Domain-> Kingdom-> Phylum-> Class-> Order-> Family-> Genus-> Species) with domain being most inclusive and each subsequent category becoming more specific.",
        "suggested_answer": "Felis catus",
        "options": [
            "Arthropoda",
            "Felis catus",
            "Protista",
            "Eukarya"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 913,
        "question": "Cell-mediated immunity is provided by:",
        "answer": "Cell mediated immunity is provided by Helper T cells as well as cytotoxic T cells.",
        "suggested_answer": "T cells",
        "options": [
            "T cells",
            "NK cells",
            "Antibodies",
            "B cells"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 914,
        "question": "The somatic nervous system is a part of which broader division of the nervous system?",
        "answer": "The somatic nervous system is a part of the peripheral nervous system and controls voluntary body movements. The central nervous system involves only the brain and spinal cord. The autonomic nervous system is not under voluntary control.",
        "suggested_answer": "Peripheral nervous system",
        "options": [
            "Autonomic nervous system",
            "None of these",
            "Central nervous system",
            "Peripheral nervous system"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 915,
        "question": "Which of the following is a monosaccharide?",
        "answer": "Glucose is a monosaccharide, consisting of one 6-carbon ring. Sucrose and maltose are disaccharides, and starch is a polysaccharide chain of glucose units.",
        "suggested_answer": "Glucose",
        "options": [
            "Maltose",
            "Sucrose",
            "Glucose",
            "Starch"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 916,
        "question": "A fruit develops from the __________ of a flower.",
        "answer": "Once a plant is fertilized, the ovary will ripen around the developing seed of a plant and become a fruit. These fruits perform many functions including protection, provision of nutrients for the developing plant embryo, and aid in dispersal. The pedicel is the stem that connects the flower to the rest of the plant. The stigma is the sticky tip of the pistil that receives pollen from the environment. The stamen is the male section of the flower and the anther is the tip of the stamen that disperses pollen.",
        "suggested_answer": "ovary",
        "options": [
            "anther",
            "stigma",
            "stamen",
            "pedicel",
            "ovary"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 917,
        "question": "Which of the following are true regarding phloem?",
        "answer": "Phloem is located in the bark of trees and transports sugars (such as sucrose) and organic compounds to the rest of the plant from the leaves. The other plant vascular tissue is xylem. Xylem is located outer wood of trees, and transports water from the roots to the leaves of trees.",
        "suggested_answer": "II and III",
        "options": [
            "II and III",
            "II only",
            "I only",
            "I and II",
            "I and III"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 918,
        "question": "What type of selection has occurred?",
        "answer": "Directional selection is natural selection that favors a phenotype at an \"extreme\" for a particular trait. In this example, we are looking at the height trait. Individuals with taller phenotypes exhibit greater fitness due to their ability to reach the food source more easily. The new environment favors taller giraffes, and thus, after many generations, the phenotype of the population as a whole will be skewed towards the tall end of the height spectrum.",
        "suggested_answer": "Directional selection",
        "options": [
            "Extreme selection",
            "Destabilizing selection",
            "Disruptive selection",
            "Directional selection",
            "Stabilizing selection"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 919,
        "question": "Which immune cells contribute most to the humoral immune response?",
        "answer": "The humoral response refers to the antiquated term \"humors\", meaning body fluids, as used in ancient and medieval medicine. In response to antigen exposure, B-cells release antibodies into the extracellular fluid and throughout the body, thus eliciting a \"humoral response.\"",
        "suggested_answer": "B cells",
        "options": [
            "Phagocytes",
            "Helper T cells",
            "Basophils",
            "B cells",
            "Natural killer T cells"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 920,
        "question": "Logistic growth in a population is modeled by the equation:",
        "answer": "In exponential growth, the growth rate is modeled as the reproductive rate times the number of individuals . By adding the term , we arrive at the equation for logistic growth. As increases, the value of (and thus of the entire equation) will decrease to the point where . After this, the growth rate will become negative! The result is that the population will be limited to a size of . This limit is known as the carrying capacity.",
        "suggested_answer": "The carrying capacity",
        "options": [
            "The carrying capacity",
            "The birth rate",
            "Time",
            "The death rate",
            "The fitness of the organisms"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 921,
        "question": "What is the best description for isotopes of elements?",
        "answer": "Isotopes are the same element that have the same number of protons and electrons. The difference is in their atomic weight because they have different numbers of neutrons.",
        "suggested_answer": "The same elements that have the same number of electrons and different numbers of neutrons.",
        "options": [
            "Elements that have different numbers of electrons.",
            "The same elements that have the same number of electrons and different numbers of neutrons.",
            "Elements that have different numbers of protons and different numbers of neutrons.",
            "Elements that have the same number of electrons and the same number of neutrons."
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 922,
        "question": "The __________ cell secretes __________ in order to reduce the pH of the stomach to increase food digestion.",
        "answer": "The parietal cells of the stomach begin secreting hydrochloric acid even upon the smell of food (before consumption takes place) in order to begin the digestion process. These cells are common targets of antacid medications to reduce acid reflux. ",
        "suggested_answer": "parietal . . . hydrochloric acid",
        "options": [
            "parietal . . . hydrofluoric acid",
            "chief . . . carbonic acid",
            "parietal . . . hydrochloric acid",
            "chief . . . hydrochloric acid",
            "parietal . . . bile"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 923,
        "question": "Which of the following statements about adult human heart anatomy are false?",
        "answer": "The right ventricle pumps oxygen-poor blood into the pulmonary circulation, not oxygen-rich blood. This blood leaves the right ventricle through the pulmonary artery and reaches the lungs. All other answer choices are true statements.",
        "suggested_answer": "The right ventricle pumps oxygen-rich blood into the pulmonary circulation",
        "options": [
            "The left ventricle pumps oxygen-rich blood into the systemic circulation",
            "The left atrium sends oxygen-rich blood to the left ventricle",
            "The right ventricle pumps oxygen-rich blood into the pulmonary circulation",
            "The left atrium receives oxygen-rich blood from the pulmonary circulation",
            "The right atrium receives oxygen-poor blood from the systemic circulation"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 924,
        "question": "The endometrium is a membrane in which of the following female reproductive parts?",
        "answer": "The endometrium is a mucus membrane in the uterus of the female reproductive tract. The endometrium changes thickness based on hormone levels and is shed during the menstrual cycle if fertilization does not occur.",
        "suggested_answer": "Uterus",
        "options": [
            "Ovary",
            "Cervix",
            "Vagina",
            "Uterus"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 925,
        "question": "What the products of the light independent reactions?",
        "answer": "The products of the light independent reaction are glucose, NADP+, and H2O. The reactants are CO2, NADPH, and ATP. ",
        "suggested_answer": "glucose, NADP+, H2O",
        "options": [
            "O2, NADP+, ATP",
            "CO2, NADPH, ATP",
            "glucose, NADP+, H2O",
            "glucose, NADPH, H2O"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 926,
        "question": "Which of the four heart chambers is the biggest and provides the greatest contractile force? ",
        "answer": "The left ventricle is the chamber responsible for pumping oxygen-rich blood throughout the entire system, as opposed to the right ventricle, which only pumps oxygen-poor blood to the lungs. This requires the cardiac muscle that makes up the walls of the left ventricle to be much thicker, and thus stronger, than that of the rest of the heart chambers. ",
        "suggested_answer": "Left ventricle",
        "options": [
            "Left ventricle",
            "Left atrium",
            "Right atrium",
            "Right ventricle",
            "Aorta"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 927,
        "question": "How many direct ATP are made if fructose-1,6-bisphosphate is put through glycolysis?",
        "answer": "The conversion of glucose to two pyruvate molecules in glycolysis produces a net total of two direct ATP. When fructose-1,6-bisphosphate enters glycolysis, it bypasses the two steps involved that normally cost one ATP each, therefore, there is no required input and the net total is four produced ATP.",
        "suggested_answer": "4",
        "options": [
            "4",
            "3",
            "0",
            "1",
            "2"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 928,
        "question": "Which of the following structures is created from the infolding of epithelial tissues?",
        "answer": "The infolding of epithelial tissue forms glands that function in the exocrine and endocrine systems. Glands secrete products that are taken up by the circulatory system for delivery.",
        "suggested_answer": "Glands",
        "options": [
            "Glands",
            "Stratified epithelia",
            "Keratin",
            "Cell junctions"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 929,
        "question": "Which of the following is not true regarding the function of axons in nerve cells?",
        "answer": "Axons are the parts of neurons that transmit electrical signals away from the cell body and to other neurons. The axon branches off of the soma and is long with a constant radius. Parts of the axon are myelinated, meaning that it is insulated to allow for salutatory conduction. Gaps in insulation are referred nodes of Ranvier.",
        "suggested_answer": "They receive electrical signals from other neurons",
        "options": [
            "Axons can be myelinated or unmyelinated",
            "They transmit electrical signals away from the cell body ",
            "They receive electrical signals from other neurons",
            "Axons have nodes of Ranvier"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 930,
        "question": "Which of the following is produced by the pituitary gland?",
        "answer": "The hormones secreted by the pituitary gland are FLAT PEG: Follicle-stimulating hormone, luteinizing hormone, adrenocorticotropic hormone, thyroid-stimulating hormone, prolactin, endorphins, and growth hormone. Testosterone is produced in the testes in males, and in the adrenal gland in both males and females. Insulin is produced by the pancreas. Estrogen is produced by the ovaries in females, and in the adrenal gland in both males and females.",
        "suggested_answer": "Follicle-stimulating hormone",
        "options": [
            "Follicle-stimulating hormone",
            "Testosterone",
            "Epinephrine",
            "Insulin",
            "Estrogen"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 931,
        "question": "In the cAMP second messenger system, what enzyme catalyzes the synthesis of the second messenger cAMP?",
        "answer": "The cAMP second messenger system is involved in many signaling pathways, such as the regulation of glycogen, growth hormone, and lipid metabolism. In the cAMP system, ligand binding activates a G protein-coupled receptor and the associated intracellular G protein. The activated G protein then stimulates the enzyme adenylate cyclase to produce cAMP second messenger molecules from ATP. The cAMP molecules activate protein kinases that, in turn, activate a variety of target proteins through phosphorylation. Cyclic AMP or cAMP systems are capable of transducing a variety of signals. ",
        "suggested_answer": "Adenylate cyclase",
        "options": [
            "Protein kinase",
            "Adenylate cyclase",
            "Tyrosine kinase",
            "Phosphatase"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 932,
        "question": "Which of the following is not a difference between mitosis and meiosis?",
        "answer": "The processes of mitosis and meiosis have many differences between them. These differences include the genetic recombination event called crossing over unique to meiosis, the fact that only mitotic daughter cells are genetically identical to parent cells, and the paring of homologous chromosome pairs during metaphase I of meiosis. One characteristic that is common to both processes is that they occur in all animals.",
        "suggested_answer": "That the process occurs in all animals",
        "options": [
            "Pairing of homologous chromosomes at the metaphase plate",
            "The crossing over event ",
            "That the process occurs in all animals",
            "That the daughter cells are genetically identical to the parent cells"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 933,
        "question": "Which of the following was not part of Darwin's theory of evolution?",
        "answer": "The inheritance of traits through discrete units called alleles was not a part of Darwin's theory of evolution. A monk named Gregor Mendel developed the theory of inheritance through alleles. Darwin's theory did not propose a method of inheritance, and Darwin was unaware of Mendel\u2019s work. The works of Darwin and Mendel were later combined by scientists to create the modern theory of evolution.",
        "suggested_answer": "The inheritance of traits in discrete units called alleles",
        "options": [
            "The inheritance of traits in discrete units called alleles",
            "Organisms of the same species display a variety of different traits",
            "Over time, traits that are beneficial to an organism in a given environment are likely to accumulate in a population",
            "None of these - Darwin did not develop a theory of evolution",
            "Organisms that are best adapted to their environments are most likely to survive and reproduce"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 934,
        "question": "Where are lipids most commonly found in cells?",
        "answer": "Lipids are primarily found in membranes. This includes both the plasma membrane and membranes surrounding particular organelles. Lipids are very useful in membranes because their nonpolar nature helps them act as a barrier between the cell and the outside environment.",
        "suggested_answer": "Membranes",
        "options": [
            "The cytosol",
            "The extracellular matrix",
            "Organelle matrices",
            "Membranes"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 935,
        "question": "How does burning fossil fuels impact the carbon cycle?",
        "answer": "Industrialization has increased the burning of fossil fuels, such as coal and oil. This releases carbon from the geosphere into the atmosphere, increasing the carbon in the atmosphere. Carbon dioxide and methane are big contributors to the greenhouse effect and global warming.",
        "suggested_answer": "Releases carbon from the geosphere into the atmospheric biosphere",
        "options": [
            "Removes carbon from the cycle altogether",
            "It doesn't impact the carbon cycle",
            "Releases carbon from the geosphere into the atmospheric biosphere",
            "Releases carbon into the geosphere"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 936,
        "question": "In anaerobic conditions, pyruvate is converted into __________ in an effort to regenerate __________.",
        "answer": "Anaerobic respiration occurs when there is not enough oxygen necessary for pyruvate to be shunted to the citric acid cycle. In the absence of oxygen, lactate is formed. The purpose of forming lactate is to also regenerate , as can be used in a cyclical fashion to continue the process of cellular respiration. When oxygen supply returns, the pyruvate will be shunted away from the production of lactate and instead go to the citric acid cycle.",
        "suggested_answer": "lactate . . . ",
        "options": [
            "lactate . . . ATP",
            "alcohol . . . ",
            "lactate . . . ",
            "lactate . . . carbon dioxide"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 937,
        "question": "What is the functional unit of the kidney?",
        "answer": "The functional unit of the kidney is the nephron. Blood is filtered into the nephron to create filtrate. As the filtrate flows through the nephron tubules, its concentration is tightly regulated and ions and water are added and removed. The end result is a highly-concentrated filtrate that is transported to the bladder for excretion.",
        "suggested_answer": "A nephron",
        "options": [
            "A chief cell",
            "A nephron",
            "A sarcomere",
            "A neuron"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 938,
        "question": "In a dihybrid cross of seed color and seed shape, Y (yellow) is dominant to y (green) and R (rounded) is dominant to r (wrinkled). An individual with genotype YyRr is crossed with an individual with genotype YYRr. The inheritance of the alleles for seed color does not affect inheritance of genes for seed shape. Which law supports this statement?",
        "answer": "The law of independent assortment states that inheritance of one gene does not influence inheritance of another gene. Thus, inheritance of seed color does not affect the inheritance of seed shape.",
        "suggested_answer": "Law of independent assortment",
        "options": [
            "Law of segregation",
            "Law of dominance",
            "Law of random mating",
            "Law of independent assortment"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 939,
        "question": "Which of the following reasons could explain why sympatric speciation is more common in plants than in animals?",
        "answer": "Sympatric speciation implies a speciation event while the populations exist within the same geographical region.",
        "suggested_answer": "Plants are more likely to be able to self-fertilize ",
        "options": [
            "Plants are more likely to be able to self-fertilize ",
            "Plants can become geographically isolated more easily",
            "Plants are less prone to chromosomal abnormalities ",
            "Animal populations move around too much"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 940,
        "question": "Which of the following best describes the direct effect of uterine contractions during labor?",
        "answer": "Uterine contractions during labor help the fetus descend into the birth canal and aid cervical dilation.",
        "suggested_answer": "Both cervical dilation and fetal descent",
        "options": [
            "Fetal descent",
            "Neither fetal descent nor cervical dilation",
            "Cervical dilation",
            "Both cervical dilation and fetal descent"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 941,
        "question": "Disease A is inherited through an autosomal recessive process. What is the probability that two unaffected carriers of this disease will produce an offspring that inherits disease A?",
        "answer": "The correct answer is 25% because each parent has a 50% chance of giving up their recessive allele to the offspring. There are two parents so the probability will be:",
        "suggested_answer": "25%",
        "options": [
            "0%",
            "75%",
            "50%",
            "100%",
            "25%"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 942,
        "question": "What does \"temporal summation\" mean in regards to the generation of action potentials?",
        "answer": "Temporal summation refers to the phenomenon that an individual neuron will fire with such a high frequency that previous changes in potential have not yet normalized before a new one begins. This summative effect can cause the generation of an action potential, once the threshold potential is surpassed.",
        "suggested_answer": "Increased firing rate of an individual neuron to generate an action potential",
        "options": [
            "Activating several unique motor units to generate an action potential ",
            "Increased firing rate of an individual neuron to generate an action potential",
            "Adding together the total amount of action potentials produced over a length of time",
            "Over time neurons will produce action potentials with no stimulus"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 943,
        "question": "Which statement is true regarding phospholipids?",
        "answer": "Phospholipids are the major component of cell membranes (the lipid bilayer), composed of two fatty acid tails attached to a glycerol head. The third hydroxyl group that is joined to a phosphate gives the cell a negative charge. The heads are hydrophilic, or water loving and the tails are hydrophobic, or water fearing. The hydrophilic heads create a selectively permeable gate into and out of the cell. Between the hydrophilic heads are the hydrophobic tails. Thus, small, uncharged, lipophilic molecules can diffuse into and out of the cell.",
        "suggested_answer": "Phospholipid tails are hydrophobic",
        "options": [
            "Phospholipid tails are hydrophobic",
            "Phospholipids maintain a positive charge, creating polarization",
            "Phospholipids are synthesized on ribosomes",
            "Phospholipids make up the cytosol of the cell",
            "Phospholipids are composed of three fatty acids attached to a glycerol "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 944,
        "question": "Which of the following choices correctly describes the VSEPR shape of the water molecule, ?",
        "answer": "In the water molecule, there are four electron pairs. Two of them are bonded and two of them are lone pairs. This causes the water molecule to have a tetrahedral shape (it is important to note that it is a bent tetrahedral shape due to the two lone pairs).",
        "suggested_answer": "Tetrahedral",
        "options": [
            "Trigonal bipyramidal",
            "Linear",
            "Tetrahedral",
            "None of the answer choices correctly describe the VSEPR shape of the water molecule",
            "Trigonal planar"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 945,
        "question": "What is the purpose of microvilli in the small intestine?",
        "answer": "Microvilli are found at the microscopic level within the digestive tract to help increase surface area. The increased surface area aids in the absorption of nutrients as they pass through the small intestine. Microvilli allow for folds in the digestive tract, which in turn, increase the amount of area available for nutrient absorption.",
        "suggested_answer": "Increase surface area",
        "options": [
            "Decrease contracility",
            "Increase surface area",
            "Increase contractility",
            "Decrease surface area"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 946,
        "question": "How does inbreeding negatively impact a population?",
        "answer": "Breeding with genetically different individuals tends to \"scramble\" the alleles of a population. When genetically similar individuals breed, they tend to produce homozygous genotypes in their offspring, so potentially dangerous recessive alleles appear more frequently and beneficial alleles are not as efficiently selected for. Thus, inbred populations have a lower survival rate over time. This is called inbreeding depression.",
        "suggested_answer": "All of these",
        "options": [
            "It impedes evolution, because the frequency of alleles does not change within the population.",
            "None of these",
            "It increases the number of homozygous individuals, allowing potentially harmful recessive alleles to express themselves more frequently.",
            "All of these",
            "It reduces the number heterozygous individuals, preventing beneficial alleles from being efficiently selected for."
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 947,
        "question": "Hydrogen bonds form between RNA nucleotide residues and DNA nucleotide residues, forming a temporary DNA-RNA hybrid. This process is a part of which phase of prokaryotic transcription? ",
        "answer": "Prokaryotic transcription has three essential steps: initiation, elongation, and termination. The initiation process involves the binding of RNA polymerase to the correct region of DNA, and is characterized by the binding of the sigma factor to the RNA polymerase. Elongation occurs as the RNA strand is synthesized from the DNA template. Termination occurs when the RNA polymerase enzyme encounters a rho factor or particular DNA structure that causes it to release the DNA strand and cease RNA synthesis.",
        "suggested_answer": "Elongation",
        "options": [
            "Initiation",
            "Template recognition",
            "Termination",
            "Elongation"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 948,
        "question": "Cladograms provide what information?",
        "answer": "Cladograms show evolutionary relatedness, usually based on the similarity of the DNA sequences between different species. The closer two branches of the cladogram are to each other, the more closely related the organisms are to each other. The ends of the branches indicate a common ancestor shared by all of the species of that branch. Cladograms do not show geographic relationships. Although primitive cladograms were formulated based on physical characteristics of animals, now, DNA analysis provides a much more accurate comparison between species. Furthermore, cladograms are not limited to animals.",
        "suggested_answer": "Evolutionary relatedness among organisms",
        "options": [
            "Evolutionary relatedness among organisms",
            "Characteristics of certain animals",
            "The geographic distribution of a species",
            "Pedigrees of many generations of a species",
            "All of these"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 949,
        "question": "What is the primary structure of a protein?",
        "answer": "The primary structure of a protein is a linear sequence of amino acids. Amino acids are joined by peptide bonds between the N terminus of one amino acid and the C terminus of another amino acid through a condensation reaction, which results in the release of a water molecule. ",
        "suggested_answer": "Linear sequence of amino acids",
        "options": [
            "Multiple amino acid chains",
            "Alpha helix",
            "Linear sequence of amino acids",
            "Beta-pleated sheets"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 950,
        "question": "Crossing over is an event that contributes to the non-identical nature of gametes. Which of the following is true regarding crossing over?",
        "answer": "Crossing over occurs during prophase of meiosis I (prophase I). This process requires tetrad formation, where the homologous chromosomes (with their sister chromatids) pair with each other. Following tetrad formation, the genetic material from one homologous chromosome can be exchanged with that of the other. This exchange of genetic material leads to genetic recombination and results in production of non-identical gametes. Crossing over occurs only between homologous chromosomes. Sister chromatids are situated to form a single chromosome; crossing over does not include recombination of genetic material within a single chromosome.",
        "suggested_answer": "I and III",
        "options": [
            "II",
            "I and II",
            "I and III",
            "I"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 951,
        "question": "In this type of cellular transport, molecules move from an area of high concentration to an area of low concentration without utilizing energy to do so.",
        "answer": "This is the definition of passive transport. Active transport requires energy for molecules to move. Also, the Na+/K+ pump requires energy, and thus is a form of active transport. Vesicle transport, including endocytosis and exocytosis, also requires energy. ",
        "suggested_answer": "Passive transport",
        "options": [
            "Passive transport",
            "Na+/K+ pump",
            "Active transport",
            "Vesicles"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 952,
        "question": "Which of the following is true of osteoblasts?",
        "answer": "Osteoblasts contribute to the ossification process by working to build bone. They cannot do so as individual cells, but rather need to be arranged into osteons in the bone to function. Osteoblasts are regulated by a number of factors depending on the body's need for bone creation or resorption, including being inhibited by PTH.",
        "suggested_answer": "All answers are correct",
        "options": [
            "Osteoblasts are specialized cells that build bone",
            "Osteoblasts can only function when in functional osteons",
            "All answers are correct",
            "Osteoblasts can be inhibited when bound by parathyroid hormone (PTH)"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 953,
        "question": "In plants, leaves contain specialized pores used for gas exchange. Each pore is formed by a pair of cells that control its closing and opening. What are these cells called?",
        "answer": "For proper functioning, plants must take in carbon dioxide, expel oxygen, and limit the loss of water vapor. This gas exchange takes place via pores called stomata. These pores are formed by a pair of adjacent cells that can open and close in response to a number of factors. These cells are called guard cells.",
        "suggested_answer": "Guard cells",
        "options": [
            "Stoma cells",
            "Epidermal cells",
            "Cuticle cells",
            "Guard cells"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 954,
        "question": "Which one of the following proteins is found in the nucleus of eukaryotic cells?",
        "answer": "Helicase, gyrase, and DNA polymerase are all used in the process of DNA replication, which takes place in the nucleus. Helicase is responsible for \"unzipping\" DNA, separating its two strands and unwinding the double-helix. Gyrase is responsible for relaxing the DNA strands and relieving tensions during unwinding. DNA polymerase synthesizes the the new DNA strands by recruiting nitrogenous bases.",
        "suggested_answer": "All choices are found in the nucleus",
        "options": [
            "Helicase",
            "Gyrase",
            "DNA polymerase",
            "All choices are found in the nucleus"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 955,
        "question": "Which of the following can be found in the soma of a neuron?",
        "answer": "The soma is the cell body of a neuron. The nucleus and many organelles are located within the soma. The soma has a specialized region called the axon hillock, which is where the soma transitions into the axon.",
        "suggested_answer": "Nucleus and axon hillock",
        "options": [
            "Nodes of Ranvier",
            "Nucleus and axon hillock",
            "Nucleus",
            "Axon hillock"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 956,
        "question": "Which of the following choices causes an ion-gated channel to deactivate and close?",
        "answer": "The binding of ligands causes the activation and conformational change in the ion channel to open it. Then, ions are able to flow into the cell. After a short time, the ligand dissociates from the ion gated channel. This causes the channel to deactivate and close. ",
        "suggested_answer": "Ligand dissociation",
        "options": [
            "Dissociation of ion channel kinases",
            "Secondary messengers",
            "Ligand dissociation",
            "Change in the membrane potential"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 957,
        "question": "Which of these are structures within cells which contain digestive enzymes to eliminate waste and debris?",
        "answer": "Lysosomes are structures within cells which contain digestive enzymes to eliminate waste and debris.",
        "suggested_answer": "lysosomes",
        "options": [
            "eosinophils",
            "basophils",
            "neutrophils",
            "lysosomes",
            " ",
            "natural killer (NK) cells"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 958,
        "question": "From what germ layer does the nervous system develop?",
        "answer": "During gastrulation, the ectoderm begins to fold in on itself following the primitive streak. These neural folds form the basis of the neural tube, later developing into the adult nervous system.",
        "suggested_answer": "Ectoderm",
        "options": [
            "Somites",
            "Ectoderm",
            "Epiboly",
            "Mesoderm",
            "Endoderm"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 959,
        "question": "Sodium will react with oxygen and form an ionic compound. Which of the following is false concerning this interaction?",
        "answer": "Electronegativity is defined as the tendency of an atom to attract an electron in a bond that it shares with another atom. Because oxygen wants to receive two elctrons, while both sodiums wish to lose one electron, oxygen has a higher electronegativity than sodium. Typically, electronegativity can be seen as increasing as you go to the top right of the periodic table. For example, fluorine has a higher electronegativity than nitrogen.",
        "suggested_answer": "Sodium has a higher electronegativity than oxygen, causing it to give its electron to oxygen",
        "options": [
            "The electrons are not equally shared between sodium and oxygen",
            "Both oxygen and the two sodiums are given stable octets by creating the ionic compound",
            "In this compound, oxygen is the anion while the two sodium atoms are defined as cations",
            "Sodium has a higher electronegativity than oxygen, causing it to give its electron to oxygen",
            "The ionic compound has an overall neutral charge"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 960,
        "question": "Which of the following statements are true?",
        "answer": "Analogous structures are anatomical structures that have similar functions, but arose independently. Due to environmental stresses and natural selection, organisms of different species evolved and adapted independently, resulting in the existence of body parts with similar functions. This refers to convergent evolution. An example of analogous structures are the wings of several different animals. A moth, hummingbird, and a bat are only extremely distantly evolutionarily related, yet they all have wings of some sort, which serve the common purpose of transportation through air. Homologous structures are body parts that are anatomically similar and may share similar anatomical forms, but are not the result of convergent evolution, rather they are the result of divergent evolution. These commonalities within organisms show descent from a common ancestor. An example of homologous structures are the \"arms\" of three different mammals, a human, a bat, and a whale. Each \"arm\" has very similar anatomical organization of bones, but is used for very different functions.",
        "suggested_answer": "II and III",
        "options": [
            "I and IV",
            "I only",
            "II and III",
            "II only ",
            "III only"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 961,
        "question": "Hemoglobin is a protein comprised of four identical subunits, each subunit able to bind a single oxygen molecule. What level of structure allows for the hemoglobin to have subunits?",
        "answer": "Proteins have different levels of structure. Primary structure is the sequence of amino acids, joined by peptide bonds. Secondary structure is determined by hydrogen bonding in the amino acid chain backbone. Tertiary structure is the entire protein's shape, determined by R-group interaction and hydrophobic forces. Quaternary structure is only found in certain proteins, and results from the joining of multiple polypeptide subunits into a functional protein.",
        "suggested_answer": "Quaternary structure",
        "options": [
            "Quaternary structure",
            "Tertiary structure",
            "Primary structure",
            "Covalent bonding",
            "Secondary structure"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 962,
        "question": "Which of the following is not true about prokaryotic transcription?",
        "answer": "RNA polymerase does not interact with the Shine-Delgarno sequence. The Shine-Delgarno sequence is present on some prokaryotic mRNAs and serves as a ribosomal binding site for the initiation of translation. RNA polymerase is only involved in transcription and will bind to DNA, not RNA.",
        "suggested_answer": "RNA polymerase binds directly to the Shine-Delgarno sequence for some promoters",
        "options": [
            "RNA polymerase interacts directly with the DNA",
            "RNA polymerase binds directly to the Shine-Delgarno sequence for some promoters",
            "Transcription occurs in the cytoplasm",
            "mRNA is not subject to post-transcriptional modifications"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 963,
        "question": "Which of the following is true of mitochondria?",
        "answer": "Each of these answers correctly describes characteristics of mitochondria. Mitochondria are semi-autonomous: they are capable of synthesizing some of their own proteins with DNA and ribosomes. The proteins are then embedded in the inner mitochondrial membrane, and function in the electron transport chain. Mitochondria have two membranes, an inner membrane and an outer membrane. Mitochondria are the sites of cellular respiration and ATP production, often called the \"powerhouse\" of the cell. After glycolysis, pyruvate is transported to the mitochondrial matrix for the Krebs cycle and electron transport chain. Mitochondria are not involved in the endomembrane system; they are separate organelles.",
        "suggested_answer": "All of these are true statements",
        "options": [
            "Mitochondria are independent of the endomembrane system",
            "Mitochondria contain DNA and ribosomes",
            "All of these are true statements",
            "Mitochondria are involved in energy metabolism",
            "Mitochondria have more than one membrane"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 964,
        "question": "Enzymatic proteins work to do which of the following?",
        "answer": "Enzymatic proteins are essential to metabolic processes within the cell in that they speed up cellular reactions that would otherwise take too long. Defensive proteins help fight disease, receptor proteins respond to stimuli, motor proteins help with cell movement, and transport proteins move substances across the cell. ",
        "suggested_answer": "Accelerate cellular reactions",
        "options": [
            "Movement of the cell",
            "Respond to stimuli on the membrane of the cell",
            "Transport substances between organelles and other structures in the cell",
            "Fight diseases",
            "Accelerate cellular reactions"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 965,
        "question": "Ribosomes are macromolecular structures composed of both proteins and RNAs. Where are the ribosomal RNAs synthesized in eukaryotic cells?",
        "answer": "In eukarytoic cells, the nucleolus is located within the nucleus and is the site of ribosomal RNA synthesis",
        "suggested_answer": "Nucleolus",
        "options": [
            "Mitochondria",
            "Nucleolus",
            "Chloroplast",
            "Cytoplasm",
            "Nucleus"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 966,
        "question": "Which of the following statements about enzymes is false?",
        "answer": "Enzymes are not changed or consumed by the reactions they catalyze, but can be altered by environmental conditions. They work in three-dimensional active sites to bind specific substrates and lower the activation of certain reactions, subsequently increasing the reaction rate. Reaction rate can be further increased when enzymes react with cofactors or coenzymes, but decreased when enzymes are blocked from their specified active sites by competitive inhibitors.",
        "suggested_answer": "Enzymes are consumed during reactions",
        "options": [
            "Enzymes are consumed during reactions",
            "Competitive inhibitors can block enzymatic activity by binding in the active site",
            "Cofactors and coenzymes, such as metals and vitamins, can affect the ability of the enzyme",
            "Enzymes accelerate the rate of a chemical reaction by lowering the activation energy necessary for the reaction to proceed"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 967,
        "question": "Fertilization results in __________.",
        "answer": "The union of two gametes is called fertilization, which results in a zygote. The zygote is a diploid cell that will grow into the entire organism.",
        "suggested_answer": "a zygote",
        "options": [
            "a haploid cell",
            "a zygote",
            "a gamete",
            "an autosome"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 968,
        "question": "After an action potential occurs, the neuron goes through a refractory period, during which it cannot fire another action potential. Which of the following is not occurring during the refractory period?",
        "answer": "The refractory period is caused by two processes: the secondary gate closing the voltage-gated sodium channel to prevent sodium influx and the hyperpolarization of the neuron caused by potassium ion efflux.",
        "suggested_answer": "Sodium ions are entering the cell",
        "options": [
            "Sodium ions are entering the cell",
            "Calcium ions are leaving the cell",
            "Potassium ions are entering the cell",
            "Sodium ions are leaving the cell"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 969,
        "question": "Which of the following best defines interference competition?",
        "answer": "Interference competition is a type of competition that occurs directly between individuals through the use of aggression.",
        "suggested_answer": "Direct competition between individuals through aggression",
        "options": [
            "Members of the same species compete for the same resources",
            "Direct competition between individuals through aggression",
            "Indirect competition between two species hunted by the same predator",
            "Indirect competition through a limiting resource"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 970,
        "question": "Spermatogenesis is the production of sperm by the process of __________, followed by differentiation.",
        "answer": "During spermatogenesis, spermatogonia become primary spermatocytes, then seconday spermatocytes. These divide to form two spermatids, which transform into functional spermatozoa. Through the process of meiosis, the chromosome number is reduced from the diploid number (46) to the haploid number (23).",
        "suggested_answer": "meiosis",
        "options": [
            "gametogenesis",
            "migration",
            "mitosis",
            "meiosis",
            "proliferation"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 971,
        "question": "What type of bonds in water allow it to engage in cohesion?",
        "answer": "Hydrogen bonds are the intermolecular forces that allow it to engage in cohesion. Ionic bonds are strong bonds within a molecule between a cation and anion. Polar covalent bonds are bonds within a molecule in which there is a slight charge on the elements. Nonpolar covalent bonds are bonds within a molecule in which there is no charge on the elements.",
        "suggested_answer": "Hydrogen bonds",
        "options": [
            "Nonpolar covalent bonds",
            "Ionic bonds",
            "Hydrogen bonds",
            "Polar covalent bonds"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 972,
        "question": "Which of the following hormones is not released by the anterior pituitary gland?",
        "answer": "The anterior pituitary is responsible for releasing a large number of hormones; however, oxytocin is released by the posterior pituitary. Two of oxytocin's most important functions include stimulating uterus contractions and stimulating milk production by the mammary glands. ",
        "suggested_answer": "Oxytocin",
        "options": [
            "Prolactin",
            "Growth hormone (GH)",
            "Thyroid-stimulating hormone (TSH)",
            "Oxytocin"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 973,
        "question": "In aerobic bacteria, in what part of the cell is the electron transport chain located?",
        "answer": "In the mitochondria of eukaryotic cells, the electron transport chain is located in the inner membrane. Mitochondria are believed to be descended from bacteria that were long ago engulfed by other prokaryotic cells, producing the first eukaryotic cells. The outer membrane of mitochondria can be thought of being derived from the vesicle that was used to engulf the bacterial cell, and the inner membrane can be thought of being derived from the plasma membrane of the original bacterium.",
        "suggested_answer": "The plasma membrane",
        "options": [
            "The mitochondrial inner membrane",
            "The plasma membrane",
            "The cell wall",
            "The cytosol ",
            "The nucleoid"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 974,
        "question": "Which enzyme is correctly paired with the macromolecule that it digests?",
        "answer": "Amylase is an enzyme found in saliva and the small intestine that breaks starches down into sugars. The correct pairing for amylase would be carbohydrates.",
        "suggested_answer": "Peptidase catabolizes proteins",
        "options": [
            "Peptidase catabolizes proteins",
            "Amylase catabolizes proteins",
            "Lipase catabolizes carbohydrates",
            "Peptidase catabolizes lipids"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 975,
        "question": "The shorthorn cattle coat color exhibits codominance. If a homozygous red individual and homozygous white individual produce an offspring, what will the resulting coat color be?",
        "answer": "In cases of codominance, the offspring have both alleles expressed at the same time. Thus, the coat color will be roa, which contains both white and red hair.",
        "suggested_answer": "Roan",
        "options": [
            "White",
            "Pink",
            "Roan",
            "Red"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 976,
        "question": "Which of the following is a correct representation of a possible food chain?",
        "answer": "A flower in this example is a producer, which means it will make its own energy through photosynthesis. As a result, the flower must be the first element of our chain; food chains will always start with a producer. The caterpillar, frog, and snake are consumers, meaning they will need other organisms for energy.",
        "suggested_answer": "Flower -> Caterpillar -> Frog -> Snake",
        "options": [
            "Frog -> Flower -> Caterpillar -> Snake",
            "Flower -> Caterpillar -> Frog -> Snake",
            "Caterpillar -> Flower -> Frog -> Snake",
            "Snake -> Caterpillar -> Flower -> Frog"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 977,
        "question": "Which of the following compounds will require a carrier protein in order to cross the cellular membrane?",
        "answer": "Cellular membranes are considered semipermeable, and allow certain substances to pass through without assistance from proteins. We typically follow the rule of thumb that substances that are small or nonpolar will be able to pass through the membrane. Water and hydrogen gas are both very small and can pass through the membrane relatively easily. Steroid hormones are large, but nonpolar, so they can pass through. Glucose is both large and polar, so it requires a carrier protein in order to cross.",
        "suggested_answer": "Glucose",
        "options": [
            "Water",
            "A steroid hormone",
            "Glucose",
            "Hydrogen gas"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 978,
        "question": "What compound is present in both gram-positive and gram-negative bacteria?",
        "answer": "Only gram-negative bacteria have an outer membrane. This outer membrane contains lipopolysaccharides. Gram-positive bacteria have only one membrane.",
        "suggested_answer": "Peptidoglycan",
        "options": [
            "Lipopolysaccharides",
            "Outer membrane",
            "Teichoic acids",
            "Peptidoglycan"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 979,
        "question": "Which form of cellular transport is required to move a molecule against its concentration gradient?",
        "answer": "The natural flow of molecules is from areas of high concentration to areas of low concentration. To act against this gradient, energy must be input. Active transport requires the use of energy to move a molecule up its concentration gradient. Diffusion, osmosis, and facilitated diffusion are forms of passive transport that move a molecule down its concentration gradient.",
        "suggested_answer": "Active transport",
        "options": [
            "Facilitated diffusion",
            "Osmosis",
            "Diffusion",
            "Active transport"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 980,
        "question": "Which of these processes occurs in both photosynthesis and respiration?",
        "answer": "Chemiosmosis is the coupling of the movement of electrons down the electron transport chain with the formation of ATP by utilizing the force of the proton gradient. Think about it this way, the creation of ATP is needed in both photosynthesis and respiration and this is why it is involved in both.",
        "suggested_answer": "chemiosmosis",
        "options": [
            "Calvin cycle",
            "chemiosmosis",
            " ",
            "glycolysis",
            "Krebs cycle",
            "citric acid cycle"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 981,
        "question": "Which of the following intermolecular forces is broken when water is boiled?",
        "answer": "Intermolecular forces are transient forces between two separate molecules. Water is a polar molecule. The oxygen atom carries a slight positive charge, while the hydrogen atoms carry slight negative charges. This is the result of the large difference in electronegativity between oxygen and hydrogen. When two water molecules are next to each other, the partially positive hydrogen will be attracted to the partially negative oxygen. This attraction is known as a hydrogen bond.",
        "suggested_answer": "Hydrogen bonds",
        "options": [
            "Hydrogen bonds",
            "Ionic bonds",
            "None of these answers",
            "Covalent bonds",
            "Double bonds"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 982,
        "question": "Which of the following is not a function of connective tissue in the human body?",
        "answer": "In the human body, connective tissue has a variety of functions that allows proper function and survival. These functions include elasticity to resist tearing and stretching forces, loose tissue and ground substance that allow for the diffusion of nutrients and oxygen, and immune cells that can activate to protect the body.",
        "suggested_answer": "Transmission of electrical and chemical signals ",
        "options": [
            "Resistance of stretching and tearing forces",
            "Allows for the diffusion of nutrients and oxygen",
            "Transmission of electrical and chemical signals ",
            "Acts as a starting point for immune responses"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 983,
        "question": "A population of birds with varying beak size feeds on seeds. The birds with smaller beaks feed on small seeds, those with medium-sized beaks feeds on a medium-sized variety of seeds, and those with larger beaks feed on a large variety of seeds. Invasive insects wipe out all of the plants that produce medium-sized seeds, and the following generation of birds has no individuals with medium-sized beaks. This is an example of what type of selection?",
        "answer": "Quantifiable traits, like beak size, in a population tend to form a bell curve when graphed. Selection that leads to more individuals on either end of the graph compared to individuals in the center is disruptive, and in this case leads to a lack of medium sized birds since birds with either small or large beaks were favored. Selection that favors individuals on one side of the graph is directional, and selection that favors individuals in the center of the graph is stabilizing.",
        "suggested_answer": "Disruptive selection",
        "options": [
            "Stabilizing selection",
            "Artificial selection",
            "Directional selection ",
            "Sexual selection",
            "Disruptive selection"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 984,
        "question": "A type of natural selection in which genetic diversity decreases as the population stabilizes with respect to a particular trait value is __________.",
        "answer": "A type of natural selection in which genetic diversity decreases as the population stabilizes with respect to a particular trait value is stabilizing selection. In this mode of natural selection, the \"average\" phenotype is selected for and its proportion in the population increases over time. Directional selection is a type of natural selection in which a polarized phenotype is selected for over the other phenotypes, causing a shift toward the extremes with respect to proportion of individuals with that phenotype. Diversifying selection is another type of natural selection in which both extremes of a phenotype are selected for, causing an increase in the proportion of those alleles in the population. Nonspontaneous selection is not a type of natural selection.",
        "suggested_answer": "stabilizing selection",
        "options": [
            "None of these",
            "nonspontaneous selection",
            "directional selection",
            "diversifying selection",
            "stabilizing selection"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 985,
        "question": "Darwin described his theory of natural selection as which of the following?",
        "answer": "Darwin described his theory as \"descent with modification,\" meaning that offspring inherit their genetic material from their parents, but with slight modifications. Darwin proposed that those slight modifications which provided a fitness benefit (made it more likely that an organism would reproduce) were more likely to be passed on.",
        "suggested_answer": "Descent with modification",
        "options": [
            "Descent with modification",
            "Survival of the fittest",
            "Punctuated equilibrium",
            "Inheritance of acquired characteristics"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 986,
        "question": "Recently a nursing home had an increase in the number of Staph infections. Doctors began treating the infections with methicillin, but within a few months over 75% of infections were resisting this treatment. What is the most likely cause of this phenomenon?",
        "answer": "Initially, the population of bacteria was composed of mostly bacteria vulnerable to methicillin and a small number of resistant bacteria. Treatment with methicillin destroyed all of the vulnerable bacteria. leaving only the resistant alive to reproduce. Over time, the resistant bacteria became the majority.",
        "suggested_answer": "A small number of bacteria were resistant to methicillin from the start. These bacteria survived treatment with methicillin and reproduced, eventually comprising most of the bacteria.",
        "options": [
            "A small number of bacteria were resistant to methicillin from the start. These bacteria survived treatment with methicillin and reproduced, eventually comprising most of the bacteria.",
            "A new patient brought a methicillin-resistant strain of the bacteria to the nursing home. ",
            "Some bacteria were able to survive treatment by changing the structure of their membrane to keep methicillin from penetrating it.",
            "The bacteria built up an immunity to methicillin over time."
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 987,
        "question": "Of the following components, which cannot be recycled in a stable ecosystem?",
        "answer": "Energy, in an ecological context, refers to its flow in an ecosystem. Energy is passed from trophic level to trophic level; however, each time this occurs about ten percent of this energy is lost to the environment or as digested food. Energy is thus not recycled in this context.",
        "suggested_answer": "Energy",
        "options": [
            "Oxygen",
            "Water",
            "Hydrogen",
            "Energy",
            "Nitrogen"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 988,
        "question": "Which of the following best represents the specific type of information that afferent neurons transmit?",
        "answer": "Afferent neurons sensory neurons transmit sensory information. Sensory information includes stimuli received from sight, sound, and feeling.",
        "suggested_answer": "Sensory information",
        "options": [
            "Sensory information",
            "All of these",
            "Information that produces movement",
            "Enable communications between different parts of the nervous system"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 989,
        "question": "Which of the following is true about cells at resting potential?",
        "answer": "By pumping two positively-charged potassium molecules in for every three positively-charged sodium molecules that are pumped out of the cell, the sodium-potassium pump maintains a resting potential of 70mV relative to outside of the cell. This function is important for creating an electrochemical gradient along the neuron.",
        "suggested_answer": "A sodium-potassium pump keeps the membrane polarized",
        "options": [
            "They have a resting potential of 30mV relative to the outside of the cell",
            "There is a higher concentration of sodium inside of the cell",
            "There is a higher concentration of potassium outside of the cell",
            "A sodium-potassium pump keeps the membrane polarized"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 990,
        "question": "The major tropic action of the growth hormone is to signal the __________ to release __________.",
        "answer": "Growth hormone is released from the anterior pituitary, and has numerous effects on the body. One of its primary effects is to stimulate release of insulin-like growth factors (IGFs) from the liver. These compounds circulate in the blood and directly stimulate bone and cartilage growth.",
        "suggested_answer": "liver . . . insulin-like growth factors",
        "options": [
            "pancreas . . . glucagon",
            "liver . . . insulin-like growth factors",
            "adrenal medulla . . . epinephrine",
            "thyroid . . . T3 and T4",
            "hypothalamus . . . thyroid-stimulating hormone"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 991,
        "question": "Which of the following is false?",
        "answer": "Xylem is dead at maturity, while phloem is living. All other answer choices are true. Xylem is also thicker and more rigid, which allows for greater pressure during water transport. It provides a strong support structure for the plant, enabling taller growth.",
        "suggested_answer": "Phloem is dead at maturity, while xylem is living",
        "options": [
            "Phloem is responsible for food transfer, while xylem is responsible for water transfer",
            "Phloem can transport material bidirectionally, while xylem can only transport material unidirectionally",
            "Phloem consists of sieve tubes and companion cells, while xylem consists of tracheids and vessel elements",
            "All of these statements are true",
            "Phloem is dead at maturity, while xylem is living"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 992,
        "question": "Which is not a difference between prokaryotes and eukaryotes?",
        "answer": "All of these answers are true differences between prokaryotes and eukaryotes. Prokaryotes' DNA is double-stranded, but circular instead of linear, as in eukaryotes. This facilitates the expedient replication of multiple genes. Eukaryotes have membrane-bound organelles, but prokaryotes do not. The more complex a cell is, the greater number of reactions and processes that need to be separated. Eukaryotes also have multiple replication origins most likely due to the length and distribution of their genes. Eukaryotes also do not have operons (present in prokaryotes) that are analogous to \"gene packets,\" which are transcribed simultaneously. Since prokaryotic DNA is circular they have no \"ends\". Thus there are no need for telomeres which cover the ends of eukaryotic chromosomes. This loss of important genetic information at the end of transcription due to the antiparallel nature of DNA.",
        "suggested_answer": "None of these",
        "options": [
            "Prokaryotes have a circular chromosome",
            "Prokaryotes do not have telomeres",
            "Eukaryotes have membrane-bound organelles",
            "None of these",
            "Eukaryotes have multiple replication origins"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 993,
        "question": "Which of the following parts of the male reproductive system contracts to propel semen from the penis during ejaculation?",
        "answer": "During sexual intercourse, arousal and stimulation lead to male orgasm. Orgasm is commonly accompanied by ejaculation. During ejaculation, pulses of semen exit the urethra due to the muscle contractions initiated by spinal nerve signals. ",
        "suggested_answer": "Urethra",
        "options": [
            "Urethra",
            "Seminiferous tubules",
            "Vas deferens",
            "Scrotum"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 994,
        "question": "Which of the following best describes how genetic materials combine after the sperm and oocyte fuse?",
        "answer": "After the sperm cell and oocyte fuse, the sperm\u2019s pronucleus enters the oocyte. The oocyte completes meiosis II while the sperm\u2019s tail and mitochondria degrade. The pronuclei migrate towards one another within the oocyte and replicate genetic material in preparation for genome fusion and mitosis. The nuclear membranes of the sperm and egg pronuclei dissolve; however, a mitotic spindle tethers the chromosomes together and prevents the genetic material from diffusing. The genetic material then undergoes mitosis, which fuses the maternal and paternal chromosomes into one diploid genome.",
        "suggested_answer": "Chromosomes combine after nuclear membrane dissolution",
        "options": [
            "Chromosomes combine after nuclear membrane dissolution",
            "Pronuclei fusion",
            "Chromosomes freely diffuse in the oocyte cytoplasm",
            "A mitotic spindle links the pronuclei nuclear membranes "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 995,
        "question": "ATP phosphorylation (ADP+ Pi-> ATP+ H2O) is what type of reaction?",
        "answer": "ATP hydrolysis is exergonic. ATP phosphorylation is the reverse of ATP hydrolysis, and thus is endergonic.",
        "suggested_answer": "endergonic",
        "options": [
            "spontaneous",
            "endergonic",
            "catabolic",
            "exergonic"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 996,
        "question": "Which of the following includes populations of several different species in an area?",
        "answer": "A community includes populations of different species. The study of a community looks at interactions between species, such as competition, symbiotic relationships, or predation. ",
        "suggested_answer": "Community",
        "options": [
            "Landscape",
            "Community",
            "Biosphere",
            "Population",
            "Ecosystem"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 997,
        "question": "Which enzyme is not involved in DNA replication?",
        "answer": "Lipase is the general name for an enzyme that breaks down lipids. Ligase joins the Okazaki fragments on the lagging strand of the DNA during replication. DNA polymerase is the enzyme that catalyzes the polymerization of nucleotides in the 5' to 3' direction. Helicase separates the two strands of the double helix to facilitate formation of the replication bubble. Gyrase relieves strain on the DNA while it is being unwound by helicase. ",
        "suggested_answer": "Lipase",
        "options": [
            "Helicase",
            "Lipase",
            "DNA polymerase",
            "Gyrase",
            "Ligase"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 998,
        "question": "Diploid cells contain how many times more chromosomes than haploid (n) cells?",
        "answer": "Diploid cells contain two copies of each chromosome, therefore containing twice as many (2n) chromosomes as a haploid cell (n), which contain only one copy of each chromosome. In humans, diploid somatic cells contain 46 chromosomes, or 23 pairs of chromosomes.",
        "suggested_answer": "Twice as many (2n)",
        "options": [
            "Four times as many (4n)",
            "Twice as many (2n)",
            "The same amount (n)",
            "Three times as many (3n)"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 999,
        "question": "Which term is used to describe movement of an organism toward a light source?",
        "answer": "Phototaxis is movement (taxis) in response to light (photo). Movement towards a source is positive; movement away from a source is negative. \"Positive phototaxis\" would be used to describe movement toward a light source.",
        "suggested_answer": "Positive phototaxis",
        "options": [
            "Negative thermotaxis",
            "Positive phototaxis",
            "Negative phototaxis",
            "Positive chemotaxis",
            "Negative chemotaxis"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1000,
        "question": "What is the function of RNA polymerase in prokaryotes? ",
        "answer": "The correct answer is transcribe RNA from a DNA template. RNA polymerases are DNA-dependent, meaning that they require a DNA template; however, the new daughter strand that they create is composed of RNA. This RNA will then be translated into a functional protein by prokaryotic ribosomes. ",
        "suggested_answer": "Transcribe RNA from a DNA template",
        "options": [
            "Repress transcription by binding operators",
            "None of the other answers ",
            "Translate RNA to protein",
            "Transcribe RNA from a DNA template",
            "Replicate DNA during cell division"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1001,
        "question": "What is a similarity between all amino acids in the body?",
        "answer": "The amino acids, as denoted by the name, contain amino and carboxyl groups. Each amino acid has the amine group connected to a central carbon, which is then connected to a carboxyl group.",
        "suggested_answer": "All contain amino and carboxyl groups",
        "options": [
            "All can be used as the first amino acid in a protein structure",
            "All are used in every protein in the body",
            "All fit into the alpha-helix structure",
            "All contain amino and carboxyl groups",
            "All contain side chains"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1002,
        "question": "Dominant traits are __________.",
        "answer": "Dominant alleles are neither better, nor worse than recessive alleles. They are simply expressed in the phenotype of a heterozygous pair. Several deleterious, and even deadly disorders like Huntington's disease, are inherited via an autosomal dominant pattern.",
        "suggested_answer": "None of the other answers",
        "options": [
            "likely to show phenotypically 75% of the time no matter what trait it is",
            "None of the other answers",
            "usually more deleterious than recessive alleles",
            "always more beneficial than recessive ones"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1003,
        "question": "Which is false regarding cell signaling?",
        "answer": "Endocrine signals are signals from distance cells that move using the bloodstream, paracrine signals are signals used to communicate between cells in close proximity, and autocrine signals are signals that are received by the same cell in which the signal originated. Paracrine signals are signal are short-lasting, whereas endocrine signals are long-lasting.",
        "suggested_answer": "Paracrine signals are long-lasting",
        "options": [
            "Autocrine signals bind to receptors on the same cell that released the ligand",
            "Endocrine signals move via the bloodstream",
            "Paracrine signals are used to communicate the nearby cells",
            "Paracrine signals are long-lasting"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1004,
        "question": "Based on the concentrations of hydrogen ions in the mitochondria, where would you expect to find the most acidic environment?",
        "answer": "The most acidic environment, or the lowest pH, would be found in the intermembrane space. This is because as and pass their electrons to the enzymes in the electron transport chain, protons are pumped into the intermembrane space. This is where a high concentration protons is generated, which is considered acidic. The low concentration of protons is generated in the mitochondrial matrix rendering it basic.",
        "suggested_answer": "Intermembrane space",
        "options": [
            "Inner mitochondrial membrane",
            "Intermembrane space",
            "Cytoplasm",
            "Mitochondrial matrix",
            "Cytoplasm"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1005,
        "question": "Extrameduallary hematopoiesis is when hematopoiesis occurs in other places than in the bone marrow. Which of the following scenario(s) may present as extrameduallry hematophiesis? ",
        "answer": "A premature delivery, especially before the migration of the hematopoietic stem cells will result in extramedually hematopoiesis. ",
        "suggested_answer": "I only",
        "options": [
            "I only",
            "II only",
            "II and III",
            "III only",
            "I and III"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1006,
        "question": "Which phase of the cell cycle involves DNA replication?",
        "answer": "The S phase is when the cell replicates its DNA, resulting in chromatid pairs that will split apart during mitosis. The G phases are mainly dedicated to protein synthesis and cell growth. The M phase is the act of mitosis.",
        "suggested_answer": "The S phase",
        "options": [
            "The S phase",
            "The G1 phase",
            "The G2 phase",
            "The M phase"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1007,
        "question": "After a plasmid incorporates outside genes into its own genome, the plasmid may be cloned in which of the following ways?",
        "answer": "A plasmid replicates itself by being introduced to a bacterium and inserting its genetic information. The bacterium then undergoes its replication process with the genetic information that was injected by the plasmid and in this way the plasmid is cloned. Bacteria do not undergo mitosis, rather, they undergo a process called binary fission to produce identical daughter cells.",
        "suggested_answer": "Putting its genome into a bacterium that would begin to replicate the new genetic information",
        "options": [
            "Putting its genome into a bacterium that would begin to replicate the new genetic information",
            "Re-programing its DNA to replicate itself",
            "Treating it with a restriction enzyme",
            "Undergoing mitosis",
            "Putting its genome into a virus that would begin to replicate the new genetic information"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1008,
        "question": "Which of the following is the function of the nucleolus?",
        "answer": "The nucleolus is a specialized structure within the nucleus that is the site of ribosome synthesis and assembly. The nucleolus is not directly involved in DNA replication, translation, or ATP synthesis. While the DNA of eukaryotic cells is stored in the nucleus, it is not stored in the nucleolus since this part of the nucleus is specialized for ribosome assembly.",
        "suggested_answer": "Ribosome synthesis",
        "options": [
            "DNA replication",
            "Ribosome synthesis",
            "Storage of DNA",
            "ATP synthesis",
            "Translation"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1009,
        "question": "The most prevalent negative charge on DNA can be found on which of the following molecular components?",
        "answer": "The phosphate backbone of DNA is negatively charged due to the bonds created between the phosphorous atoms and the oxygen atoms. Each phosphate group contains one negatively charged oxygen atom, therefore the entire strand of DNA is negatively charged due to repeated phosphate groups. ",
        "suggested_answer": "Phosphate backbone",
        "options": [
            "Phosphate backbone",
            "Hydrogen bonds between base pairs",
            "Phosphodiester linkage",
            "Hydroxyl residues",
            "Ribose sugar ring"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1010,
        "question": "Which organelle works to synthesize an unstable form of energy?",
        "answer": "The unstable form of energy that is syntheisized refers to ATP. ATP is an unstable; its three phosphate groups are all negatively charged and repel one another. Remember that BOTH the mitochondria and chloroplast participate in ATP synthesis via chemiosmosis; however, only the chloroplast participates in glucose synthesis via photosynthesis. Note that glucose is a stable form of energy and is not readily usable until it is broken down in the process known as glycolysis.",
        "suggested_answer": "Two of the other answer choices are correct.",
        "options": [
            "Rough endoplasmic reticulum",
            "Two of the other answer choices are correct.",
            "Mitochondria",
            "Chloroplast",
            "None of the other answer choices is correct."
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1011,
        "question": "Which term refers to the relative proportions of a specific allele in relation to all alleles for the given gene in a population?",
        "answer": "The allele frequency for any given gene is the relative proportion of each allele of that gene in a population. This value can be found by dividing the number of a specific allele by the total number of alleles in a population.",
        "suggested_answer": "Allele frequency",
        "options": [
            "Allele amount",
            "Allele concentration",
            "Allele population",
            "Allele frequency",
            "Allele coefficient"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1012,
        "question": "Which term describes a one-way valve composed of smooth muscle that functions in elimination?",
        "answer": "A sphincter is a circular band of mucle that, when constricted, closes an orifice. For example, the pyloric sphincter prevents stomach contents from entering the small intestine before it has been properly digested.",
        "suggested_answer": "Sphincter",
        "options": [
            "Plica",
            "Vilus",
            "Cecum",
            "Sphincter",
            "Varix"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1013,
        "question": "Which of the following statements is true regarding glycogen?",
        "answer": "Recall that glucagon promotes the breakdown of glycogen to glucose, whereas insulin promotes formation of glycogen from glucose molecules. Both hormones function to regulate glucose levels. You can eliminate choices with galactose because glycogen is made up of glucose, not galactose.",
        "suggested_answer": "Increased levels of insulin will increase the amount of glycogen stored in the liver",
        "options": [
            "Decreased levels of glucagon promotes breakdown of glycogen into galactose",
            "Increased levels of insulin will increase the amount of glycogen stored in the liver",
            "Decreased levels of insulin promotes breakdown of glycogen into galactose",
            "Increased levels of insulin will increase the amount of glycogen stored in the pancreas"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1014,
        "question": "What is the ground substance of the connective tissue?",
        "answer": "In connective tissues, ground substance is an extracellular matrix that suspends and supports cells. Ground substance does not contain fibers and is composed mostly of water and glycoproteins.",
        "suggested_answer": "An extracellular matrix suspending cells",
        "options": [
            "An extracellular substance that supports fibers and cells",
            "An extracellular matrix suspending cells",
            "An extracellular substance suspending fibers",
            "Water transported by xylem tissue in plants"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1015,
        "question": "Which of the following are not produced during the reactions of glycolysis?",
        "answer": "The correct answer to this question is carbon dioxide.",
        "suggested_answer": "Carbon dioxide",
        "options": [
            "Pyruvate",
            "Heat",
            "Carbon dioxide",
            "ATP",
            "NADH"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1016,
        "question": "Smooth muscle tissue performs which of the following types of muscle contractions?",
        "answer": "In smooth muscle tissue, contraction is involuntary and is caused by the sliding of actin and myosin filaments over one another. The function and mechanics of these contractions are generally the same in every organ; however, the physiological impact of each contraction may differ.",
        "suggested_answer": "Involuntary",
        "options": [
            "Involuntary",
            "Contractions do not involve myosin and actin",
            "The mechanism and function of contraction is different in every organ",
            "Voluntary"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1017,
        "question": "A protein that ultimately functions in the plasma membrane of a cell is most likely to have been synthesized __________.",
        "answer": "The primary function of the ribosomes bound to the rough endoplasmic reticulum is to synthesize proteins for transport to the cell exterior or extracellular matrix. These ribosomes produce polypeptides that are packaged into vesicles by the Golgi apparatus and transported to the membrane. The vesicle then fuses with the membrane, either releasing proteins out of the cell or incorporating them into the cell membrane.",
        "suggested_answer": "in the rough endoplasmic reticulum",
        "options": [
            "by free cytoplasmic ribosomes",
            "in the mitochondria",
            "in the rough endoplasmic reticulum",
            "by ribosomes on the nuclear envelope",
            "in the plasma membrane"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1018,
        "question": "The sun emits thermal neutrons that can cause __________ to deprotonate into the radioactive isotope __________.",
        "answer": "When a thermal neutron collides with a nitrogen atom in the atmosphere, it causes a proton to fly off from the atom, reducing the atom from nitrogen to carbon while keeping the same number of neutrons, 14. Because the amount of matter in the universe is believed to be constant, we can measure the amount of carbon-14 left in a dead specimen to determine its age within approximately 50,000 years.",
        "suggested_answer": "nitrogen-14 . . . carbon-14",
        "options": [
            "uranium-237 . . . lead-207",
            "carbon-14 . . . carbon-13",
            "hydrogen-1 . . . hydride",
            "nitrogen-14 . . . carbon-14",
            "uranium-237 . . . uranium-236"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1019,
        "question": "Which of the following enzymes is responsible for unwinding the double-helix of DNA and separating the two strands for replication?",
        "answer": "Helicase is the enzyme responsible for breaking the helix and unwinding the DNA into two separate strands. This allows the polymerase enzyme to attach and start adding base pairs for replication. Primase is responsible for setting and synthesizing RNA primers for polymerase attachment.",
        "suggested_answer": "Helicase",
        "options": [
            "SSB",
            "DNA Polymerase III",
            "Primase",
            "Helicase",
            "DNA Polymerase I"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1020,
        "question": "Which of the following is not a difference between monocots and dicots?",
        "answer": "Both monocots and dicots are angiosperms which means that they both have true flowers. All of the other answer choices are true differences between monocots and dicots.",
        "suggested_answer": "Dicots have a true flower whereas monocots do not",
        "options": [
            "Dicots can have secondary (radial, horizontal) growth whereas monocots cannot",
            "Dicots have a true flower whereas monocots do not",
            "The vascular bundle tissue of monocots is arranged in a ring whereas in dicots the vascular bundle tissue has no particular pattern",
            "Monocot embryos have one cotyledon where dicot embryos' have two cotyledons",
            "Monocot veins are parallel whereas dicot veins are branched"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1021,
        "question": "Carbon leaves the atmosphere through what processes?",
        "answer": "Carbon leaves the atmospheric biosphere and enters the terrestrial biosphere through photosynthesis and dissolution into bodies of water. Recall that carbon dioxide is a reactant of photosynthesis, and it gets converted into glucose. ",
        "suggested_answer": "Both photosynthesis and dissolution",
        "options": [
            "Neither photosynthesis nor dissolution",
            "Both photosynthesis and dissolution",
            "Photosynthesis only",
            "Dissolution only"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1022,
        "question": "Which of the following describes diatoms?",
        "answer": "Diatoms are unicellular phytoplankton that are incased in silica cell walls. These silica shells fall to the bottom of the ocean. Dinoflagellates are the bioluminescent phytoplankton with two flagella and are responsible for red tides. Diatoms have clear silica (glass) cell walls to allow sunlight into them, where they contain different pigments that absorb sunlight at various wavelengths. Note that although diatoms may form colonies, they are unicellular.",
        "suggested_answer": "Unicellular plankton with silica cell walls",
        "options": [
            "Infectious animal parasites with complex life cycles",
            "Microscopic animals that feed on phytoplankton",
            "Bioluminescent phytoplankton with two flagella",
            "Multicellular algae that come in red, green, and brown colors",
            "Unicellular plankton with silica cell walls"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1023,
        "question": "Which base pair sequences would you expect to find near the replication origin? ",
        "answer": "An adenine-thymine sequence would be more likely to be found near the replication origin. Adenine and thymine pair with two hydrogen bonds, while cytosine and guanine pair with three hydrogen bonds. This makes adenine and thymine regions easier to break apart. Since helicase must break the hydrogen bonds in order to create the replication fork at the replication origin, it makes sense that this event would occur in a region where there were weaker forces between the two DNA strands.",
        "suggested_answer": "Adenine and thymine",
        "options": [
            "Cytosine and adenine",
            "Guanine and cytosine",
            "Adenine and guanine",
            "Guanine and thymine",
            "Adenine and thymine"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1024,
        "question": "The Endosymbiotic Theory posits that, before being engulfed by a eukaryotic cell, they were ",
        "answer": "The Endosymbiotic Theory states that the mitochondria and chloroplast in eukaryotic cells were once aerobic bacteria (prokaryote) that were ingested by a large anaerobic bacteria (prokaryote). The aerobic bacteria were initially free-living prokaryotes, before being ingested by anaerobic bacteria.",
        "suggested_answer": "Free-living prokaryotes",
        "options": [
            "Organelles",
            "Viruses",
            "Free-living prokaryotes",
            "Free-living eukaryotes"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1025,
        "question": "Plant root systems can be generally categorized as either taproot systems or fibrous root systems. Which of the following is a characteristic of a fibrous root system?",
        "answer": "Fibrous root systems are common in seedless vascular plants and in most monocots, such as grasses. Many small roots grow from the stem of the plant and are considered adventitious (a term describing a plant organ that grows in an unusual location). ",
        "suggested_answer": "Fibrous root systems do not penetrate deeply into the soil",
        "options": [
            "Fibrous root systems consist of a series of roots that spread out from a major single root",
            "Fibrous root systems do not penetrate deeply into the soil",
            "Fibrous root systems are well adapted to soil where ground water is not close to the surface",
            "Fibrous root systems usually penetrate deeply into the soil"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1026,
        "question": "In a case of recessive epistasis, A is epistatic to B, and B is dominant to b, which genotype will give the dominant phenotype?",
        "answer": "As this is recessive epistasis, only the genotype aa will mask gene B (Aa will not mask B). BB or Bb will give the dominant phenotype as B is dominant to b. Thus, AaBb will yield the dominant phenotype.",
        "suggested_answer": "AaBb",
        "options": [
            "AaBb",
            "aabb",
            "aaBB",
            "AAbb"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1027,
        "question": "Which of the following statements is correct about the differences between DNA and RNA?",
        "answer": "The only correct statement here is the one regarding the types of sugar in the two molecules. RNA stands for \"ribonucleic acid,\" which is a simple way to remember that it contains the sugar ribose. DNA, on the other hand, stands for \"deoxyribonucleic acid.\" Its sugar is deoxyribose, which is identical to ribose except it is missing a hydroxyl (-OH) group on its second carbon. In total, RNA contains three hydroxyl groups, while DNA contains only two.",
        "suggested_answer": "The sugar molecule in RNA has one more hydroxyl group than the sugar molecule in DNA",
        "options": [
            "RNA contains the same bases as DNA, except uracil is present instead of guanine",
            "DNA is present as a single-stranded molecule while RNA is double-stranded",
            "The sugar molecule in RNA has one more hydroxyl group than the sugar molecule in DNA",
            "RNA is synthesized from DNA during transcription, but DNA can never be synthesized from RNA",
            "DNA is not present in prokaryotes, while RNA is"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1028,
        "question": "Which characteristic is least likely to affect an organism's biological fitness in the tundra?",
        "answer": "Biological fitness is directly related to the ability of an organism to survive and produce future progeny. There are a lot of factors that will play into an organism's fitness, especially in a harsh place like the tundra. Small size can be beneficial, as smaller animals require less food and can survive better in harsh environments, but large size can be beneficial to help preserve body heat. The color of an animal will help it to hide from predators; many tundra animals are white to help them blend in to the snow. Thermoregulation is extremely important in a cold environment; endotherms are able to regulate their own internal temperature, and will survive better than exotherms, which would be affected by the cold temperature of the air. Finally, fur and feathers help to trap body heat close to the skin and would enhance the fitness of animals in a cold environment.",
        "suggested_answer": "Nocturnal versus diurnal activity",
        "options": [
            "Color",
            "Size",
            "Fur",
            "Exothermic versus endothermic regulation",
            "Nocturnal versus diurnal activity"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1029,
        "question": "Which of the following comparisons of cartilage and bone is false?",
        "answer": "Cartilage, rather than bone, forms the fetal skeleton and is softer and more flexible than bone. Unlike cartilage, bone has both vascular and nervous networks running through it.",
        "suggested_answer": "Cartilage develops later into childhood, while bone forms the fetal skeleton",
        "options": [
            "Cartilage develops later into childhood, while bone forms the fetal skeleton",
            "Cartilage is avascular, while bone contains blood vessels",
            "Cartilage is softer and more flexible than bone",
            "Cartilage is non-innervated, while bone is innervated"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1030,
        "question": "Along what structure do electrons in the electron transport chain (ETC) move?",
        "answer": "The events of the electron transport chain take place on the inner membrane of the mitochondria. The transmembrane proteins used to shuttle electrons through the electron transport chain are embedded on the inner membrane. Electrons are donated to these proteins and used to transfer protons into the intermembrane space from the matrix. After reaching the final inner membrane protein in the chain, the electron is transferred to oxygen to form water.",
        "suggested_answer": "The inner membrane of the mitochondria",
        "options": [
            "The outer membrane of the mitochondria",
            "The mitochondrial matrix",
            "The inner membrane of the mitochondria",
            "The cytoplasm"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1031,
        "question": "The first species to inhabit an area after a disruption area called",
        "answer": "The first species to inhabit an area that has been disrupted are called pioneer species. This is true in both primary and secondary succession. These pioneer species are hardy species that can withstand harsh conditions.",
        "suggested_answer": "pioneer species",
        "options": [
            "primary species",
            "pioneer species",
            "secondary species",
            "keystone species"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1032,
        "question": "In the mixotroph protist Euglena, which structural components indicate that is it an autotroph?",
        "answer": "The presence of chloroplasts indicate that Euglena is able to photosynthesize. These organelles contain pigments that are necessary for the conversion of light energy to chemical energy. The ability to use non-chemical energy (in this case light) to generate chemical energy is the defining characteristic of autotrophs.",
        "suggested_answer": "Chloroplasts",
        "options": [
            "Red eye spot",
            "Plasma membrane",
            "Flagellum",
            "Chloroplasts",
            "Oral groove"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1033,
        "question": "Which of the following hormones does not spike in concentration to trigger ovulation?",
        "answer": "Leading up to ovulation, the developing follicle secretes estrogen. Over time, this secretion increases estrogen concentration. This high concentration of estrogen triggers the secretion of luteinizing hormone (LH) and follicle-stimulating hormone (FSH) from the anterior pituitary gland. The spike in LH and FSH concentrations lead to the release of the secondary oocyte from the follicle. Progesterone, on the other hand, is only present at low concentrations at the time of ovulation. Progesterone levels rise after ovulation.",
        "suggested_answer": "Progesterone",
        "options": [
            "Progesterone",
            "Follicle-stimulating hormone (FSH)",
            "Estrogen",
            "Luteinizing hormone (LH)"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1034,
        "question": "Which of the following best describes the role of nervous tissue in the human body?",
        "answer": "Nervous tissue is responsible for the transmission of electrical impulses throughout the human body. This transmission occurs in nerve cells called neurons. It regulates sensory input, muscle control, homeostasis, and mental activity.",
        "suggested_answer": "Transmission of electrical impulses",
        "options": [
            "Protection",
            "Transmission of electrical impulses",
            "Support for tissues and organs",
            "Skeletal movement"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1035,
        "question": "What is the net production of energy from glycolysis?",
        "answer": "Glycolysis produces a total of four ATP molecules. The initial steps of glycolysis, however, include an energy investment phase in which two ATP are utilized. Since two ATP are used and four are produced, the net ATP yield for glycolysis is two ATP.",
        "suggested_answer": "Two ATP",
        "options": [
            "Three ATP",
            "There is no net production of energy",
            "Two ATP",
            "Four ATP",
            "One ATP"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1036,
        "question": "Which of the following terns best describes a molecule that contains three fatty acid chains bonded to a glycerol molecule?",
        "answer": "Structurally speaking, a triglyceride molecule contains three fatty acid chains covalently bonded to glycerol specifically where the hydroxyl groups are located on the glycerol molecule.",
        "suggested_answer": "Triglyceride",
        "options": [
            "Cellulose",
            "Glycogen",
            "Triglyceride",
            "None of these",
            "DNA"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1037,
        "question": "Which term best describes DNA replication?",
        "answer": "DNA is replicated in a semiconservative manner. This implies that each parental strand serves as the template for a newly replicated strand. Each daughter DNA helix is thus composed of one complete parental strand and one complete new strand.",
        "suggested_answer": "Semiconservative",
        "options": [
            "Conservative",
            "Dispersive",
            "Random",
            "Semiconservative"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1038,
        "question": "The function of the sodium-potassium pump is an example of __________.",
        "answer": "Sodium-potassium pumps are an example of membrane proteins that undergo active transport. They function by using ATP for energy to pump potassium and sodium against their respective concentration gradients. Specifically, two molecules of sodium is pumped out of the cell for every three molecules of potassium pumped into the cell. The sodium-potassium pump is of monumental importance to all animals that use it, and it is often the target of lethal injection since even slightly altering its function is always fatal. Passive transport does not use energy. Both facilitated diffusion and osmosis are examples of passive transport. ",
        "suggested_answer": "active transport",
        "options": [
            "passive transport",
            "active transport",
            "osmosis",
            "facilitated diffusion"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1039,
        "question": "Apart from the fact that they are all eukaryotes (and therefore found within the Eukarya domain), there is no single trait that is common to all protist species. Which of the following is true of MOST protists?",
        "answer": "Though all of these answers are true of at least some protists, the most generally applicable statement is that protists generally prefer to live in environments that contain plenty of water. Species within the kingdom Protista are believed to have given rise to the other kingdoms of Eukarya (these other kingdoms being Fungi, Animals and Land Plants), so the incredible diversity found within Protista is not particularly surprising. ",
        "suggested_answer": "They prefer to live in water-rich environments",
        "options": [
            "They reproduce sexually",
            "They can cause disease",
            "They can photosynthesize",
            "They prefer to live in water-rich environments"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1040,
        "question": "Which of the following describes the organization of living systems from most broad to most specific?",
        "answer": "The biosphere consists of all parts of Earth that supports life. An ecosystem consists of communities of organisms interacting with their environment. A community consists of populations of various species living in close proximity and interacting. A population consists of organisms of the same species living in close proximity and interacting. An organism is a living being that can reproduce, react to stimuli, grow, and maintain homeostasis. An organ system consists of a group of organs working together to achieve a specific function. An organ is a group of tissues that performs a specific function. A tissue is a group of cells with similar structure working together to perform a specific function. A cell is the fundamental unit of life, and composes all living things.",
        "suggested_answer": "Biosphere-> Ecosystem->Community->Population->Organism->Organ System-> Organ-> Tissue-> Cell",
        "options": [
            " Biosphere-> Community-> Ecosystem-> Population->Organism->Tissue->Organ->Organ System-> Cell",
            "Biosphere-> Ecosystem->Community->Population->Organism->Organ System-> Organ-> Tissue-> Cell",
            "Biosphere-> Ecosystem-> Community-> Population-> Organism-> Cell-> Tissue-> Organ System",
            "Ecosystem->Community->Biosphere-> Population->Organism->Organ system-> Organ-> Tissue-> Cell"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1041,
        "question": "The sodium potassium pump generates a high concentration of __________.",
        "answer": "Remember that the sodium potassium pump is an integral membrane protein that is essential in maintaining the resting membrane potential in neurons. This protein pumps three sodium ions out of the cell and two potassium ions into the cell, creating an electrochemical gradient. The activity of this pump creates a higher concentration of sodium ions outside the cell and a higher concentration of potassium ions inside the cell. This creates a chemical gradient for sodium to flow into the cell and potassium to flow out of the cell. The net transfer of three ions out of the cell and two ions into the cell also generates an electrical gradient, increasing the potential for positive ions to enter the cell. Together, the chemical gradient and electrical gradient are called an electrochemical gradient, and are responsible for the influx of sodium during the depolarization of an axon.",
        "suggested_answer": "sodium ions outside the cell",
        "options": [
            "both sodium and potassium ions inside the cell",
            "sodium ions outside the cell",
            "both sodium and potassium ions outside the cell",
            "potassium ions outside the cell"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1042,
        "question": "Which enzyme complex catalyzes the pyruvate decarboxylation reaction?",
        "answer": "The pyruvate dehydrogenase complex is an enzyme complex that consists of 3 enzymes, which work together to catalyze the pyruvate decarboxylation reaction, where pyruvate is converted to acetyl CoA.",
        "suggested_answer": "pyruvate dehydrogenase complex",
        "options": [
            "pyruvate dehydrogenase complex",
            "pyruvate oxidase",
            "pyruvate reductase",
            "acetyl dehydrogenase"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1043,
        "question": "Where are anticodons found in cells?",
        "answer": "Anticodons are found on molecules of tRNA. Their function is to base pair with the codon on a strand of mRNA during translation. This action ensures that the correct amino acid will be added to the growing polypeptide chain. A tRNA molecule will enter the ribosome bound to an amino acid. The anticodon sequence will bind to the codon of the mRNA, allowing the tRNA to release the attached amino acid. This amino acid is then added to the peptide chain by the ribosome.",
        "suggested_answer": "tRNA",
        "options": [
            "DNA",
            "mRNA",
            "rRNA",
            "tRNA"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1044,
        "question": "Which of the following would be an example of a catalyst?",
        "answer": "Enzymes are proteins that act as catalysts for reactions. Enzymes are essential in enabling the proper function of human metabolism. If enzymes were absent then chemical traffic throughout the metabolic pathways would become backed up.",
        "suggested_answer": "Enzymes",
        "options": [
            "Chloroplasts",
            "Mitochondria",
            "ATP",
            "Enzymes"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1045,
        "question": "Which of the following is not a possible effect of an antagonist binding to a receptor?",
        "answer": "Antagonists are ligands that inhibit receptors; thus, they create a receptor blockade. Some antagonists are able to bind irreversibly to the receptor by covalent bonds, blocking the receptor. ",
        "suggested_answer": "Dissociation",
        "options": [
            "All of these",
            "Inhibition",
            "Dissociation",
            "Receptor blockade"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1046,
        "question": "A substance that cannot be broken down by chemical reactions into other substances is __________.",
        "answer": "An element cannot be broken down into other substances. Compounds consist of two or more elements and thus can be broken down. Matter is simply anything that takes up space and has mass; both elements and compounds are matter. Subatomic particles are the building blocks of atoms. Though atoms are the units that make up elements and are the smallest units to have the properties of an element, subatomic particles themselves do not display characteristic of an element.",
        "suggested_answer": "an element",
        "options": [
            "an element",
            "a compound ",
            "a unit of matter",
            "a subatomic particle"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1047,
        "question": "Which of the following molecules is unique to arthropods and some types of fungi?",
        "answer": "Arthropods use the polysaccharide chitin to build their exoskeletons. Certain types of fungi also use chitin instead of cellulose for building their cell walls.",
        "suggested_answer": "Chitin",
        "options": [
            "Glycogen",
            "Chitin",
            "Starch",
            "Cellulose"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1048,
        "question": "Based on this family history, which statement is true?",
        "answer": "In order to solve the problem, trace the colorblind allele all the way to the grandparent's alleles. It is possible that the daughter is a carrier, but this is not a guarantee. Males can not be carriers, as they only have one X chromosome; they either have the allele or they do not. Finally, the maternal grandmother may be colorblind, but this is not guaranteed.",
        "suggested_answer": "The grandmother must carry at least one allele for colorblindness.",
        "options": [
            "The grandmother must carry at least one allele for colorblindness.",
            "The grandmother is colorblind.",
            "The father is a carrier for color blindness.",
            "The daughter is a carrier for color blindness."
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1049,
        "question": "Which of the following is not an example of endocytosis?",
        "answer": "Endocytosis is the process by which the cell takes in macromolecules by forming vesicles from the plasma membrane to carry the molecules into the cell. Exocytosis, on the other hand, is the opposite. This is the process by which cells use vesicles to secrete substances from the cell. Phagocytosis, pinocytosis, and receptor-mediated endocytosis are all types of endocytosis. ",
        "suggested_answer": "Exocytosis",
        "options": [
            "Exocytosis",
            "Receptor-mediated endocytosis",
            "Phagocytosis",
            "Pinocytosis"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1050,
        "question": "Which of the following explains why the folding of proteins is important in their functions in reactions?",
        "answer": "The folding of proteins is the second and third level of organization in a protein and determines the protein's shape and how it bonds to substrates. The sequence of amino acids does not determine the shape as much as the folding in the second and third levels. The folding does not determine how long the protein will last in a cell. It does, however, influence the shape of the enzyme, which dictates the types of interactions between the enzyme and substrate, which ultimately determines the rate of the reaction that it catalyzes.",
        "suggested_answer": "The folding of proteins determines its shape, which influences how it binds to substrates and catalyzes reactions",
        "options": [
            "The folding of proteins determines the primary protein structure",
            "The folding of proteins determines how long it will last in the cell",
            "The folding of proteins is only determined by the sequence of amino acids",
            "The folding of proteins determines its shape, which influences how it binds to substrates and catalyzes reactions"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1051,
        "question": "Which of the following best describes pseudostratified epithelial cells?",
        "answer": "Pseudostratified epithelial cells are single layers of cells simple epithelium that may be easily confused as a stratified epithelium. This confusion arises from the cross section, which shows nuclei located at different heights. As a result, it appears as if pseudostratified epithelial cells are multiple cell layers.",
        "suggested_answer": "Nuclei may be at different heights",
        "options": [
            "They are layers of squamous cells",
            "They can be easily confused with stratified epithelia",
            "Nuclei may be at different heights",
            "They may change morphology based on tension"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1052,
        "question": "Which of the following choices best identifies the method(s) used by scientists to study protein folding?",
        "answer": "There are a number of ways that scientists study protein folding and structure. They include the following processes: mutation studies, x-ray crystallography, and spectroscopy. Mutation studies compare the folding patterns of wild type proteins and those with targeted point mutations. X-ray crystallography is a form of high-resolution microscopy that uses x-rays to study the atomic structure of protein crystals through diffraction patterns. Last, a number of spectroscopy methods are employed to study protein folding by comparing unfolded, folded, and partially folded proteins.",
        "suggested_answer": "All of these",
        "options": [
            "Spectroscopy",
            "X-ray cyrstallography",
            "All of these",
            "Mutation studies"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1053,
        "question": "What is the bottleneck effect?",
        "answer": "The bottleneck effect describes the phenomenon when a population has a sudden reduction in the gene pool due to natural environmental events, natural disasters, disease, or human involvement. This reduction in the gene pool will likely cause a bias that did not exist in the original population. For example, suppose a population of birds has a small number with a mutation making them unable to fly. If a disease reaches this population that kills all birds when they reach an altitude above 50m, then the gene pool of the population will suddenly shift to favor the flightless birds.",
        "suggested_answer": "A decrease in genetic variety due to a sudden elimination of part of the population",
        "options": [
            "When a new species is introduced that has a higher fitness than the native populations",
            "A decrease in genetic variety due to a sudden elimination of part of the population",
            "A decrease in genetic variety due to a small number of individuals from a larger population establishing a new population",
            "The evolution of a new species"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1054,
        "question": "Which of the following forms of transport uses vesicles to transport large molecules?",
        "answer": "Endocytosis involves the use of vesicles to transport large molecules into the cell. Facilitated diffusion, osmosis, and active transport use diffusion or protein channels to transport molecules.",
        "suggested_answer": "Endocytosis",
        "options": [
            "Osmosis",
            "Endocytosis",
            "Active transport",
            "Facilitated diffusion"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1055,
        "question": "In DNA adenine (A) bonds to thymine (T) and guanine (G) bonds to cytosine (C). What is the bonding scheme in RNA?",
        "answer": "In RNA, thymine is substituted by uracil. So adenine will bond to uracil instead of thymine when RNA interacts with DNA and when RNA folds with itself to make a 3-dimensional structure.",
        "suggested_answer": "A-U and C-G",
        "options": [
            "A-U and C-G",
            "A-G and C-U",
            "A-C and G-U",
            "None of these",
            "A-T and C-G"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1056,
        "question": "What is the cellular compartment responsible for the assembly of proteins?",
        "answer": "The ribosomes are responsible for the assembly of proteins. These structures link amino acids together to form a polypeptide chain which gets folded into a functional protein. Note that ribosomes are not membrane-bound organelles.",
        "suggested_answer": "Ribosome",
        "options": [
            "Centrioles",
            "Ribosome",
            "Golgi apparatus",
            "Endoplasmic reticulum",
            "Mitochondria"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1057,
        "question": "The regulatory subunit of maturation-promoting factor __________.",
        "answer": "Cyclin controls the progression since cyclin-dependent kinases (CDK's) are inactive unless bound to cyclins, this is what controls the cell cycle progression. The cyclin levels in the cell will control the activity of CDK's. If they are bound, the CDK's are then phosphorylated by distinct kinases, which leads to their activation and subsequent downstream effects. Cyclins do not directly remove a phosphate from ATP to ADP. They also do not directly phosphorylate anything in the answer choices, they specially bind the CDK and that process regulates if the cell cycle is up or down regulated in the cell.",
        "suggested_answer": "is called cyclin because its concentration rises and falls predictably as the cell cycle progresses",
        "options": [
            "converts ADP to ATP",
            "is called cyclin because its concentration rises and falls predictably as the cell cycle progresses",
            "removes a phosphate group from certain tyrosine residues of specific protein substrates",
            "converts ATP to ADP",
            "transfers a phosphate group to certain serine and threonine residues of specific protein substrates"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1058,
        "question": "Which statement about iteroparity is false?",
        "answer": "Iteroparity refers to an organism's reproductive strategy that involves multiple reproductive cycles. Adults are likely to survive and breed, each time producing few offspring. They care for their young, who grow to adulthood and also reproduce. In these kinds of environments, competition for resources is very intense. Semelparity refers to an organism's reproductive strategy that involves a single reproductive cycle over the course of its lifetime. Semelparity in associated with mass reproduction, and is favored in highly variable and unpredictable environments. Offspring usually have a low survival rate, and reach sexual maturity quickly. ",
        "suggested_answer": "Few adults reproduce, but those that do reproduce only once in their lifetimes and in abundance ",
        "options": [
            "Adults are likely to survive and breed",
            "Competition for resources is intense ",
            "There are few offspring but they live to survive and reproduce ",
            "Iteroparity is favored in dependable environments",
            "Few adults reproduce, but those that do reproduce only once in their lifetimes and in abundance "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1059,
        "question": "Pharmaceutical researchers are often interested in blocking particular receptor proteins on cell surfaces. What chemical property of a molecule would be most important for it to bind a receptor active site?",
        "answer": "To block a receptor protein, a molecule must structurally resemble the natural ligand. The active sites of proteins are highly specific, and will only bind certain molecules. The chemical formula, electrons, and bonding in the molecule can all influence small regions of the molecule's structure, but the overall shape must ultimately match the active site of the target protein.",
        "suggested_answer": "The molecule's structural shape",
        "options": [
            "The molecule's chemical formula",
            "The number of double bonds in the molecule",
            "The type of bonding in the molecule",
            "The number of valence electrons in the molecule",
            "The molecule's structural shape"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1060,
        "question": "Which of the following is a reason that cytosine pairs more favorably with guanine than thymine or adenine?",
        "answer": "Cytosine and guanine, when base paired, have three hydrogen bonds between them. Adenine and thymine only have two. This extra hydrogen bond helps make the cytosine-guanine pair favorable because it increases stability, and reduces bond energy.",
        "suggested_answer": "Maximized number of hydrogen bonds",
        "options": [
            "Bond energy is maximized",
            "Maximized number of hydrogen bonds",
            "Ionic interactions between the bases",
            "A covalent bond is formed"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1061,
        "question": "What is the process by which a cell intakes extracellular fluid in \"gulps\" via tiny vesicles?",
        "answer": "Pinocytosis is the uptake of extracellular fluids. The cell accomplishes this by creating vesicles from the cell membrane around droplets of extracellular fluid and carrying them inside the cell. This process is unspecific in which substances it carries into the cell (not receptor-mediated). Exocytosis involves the same concept, but in the other direction (from inside the cell out). Simple diffusion does not involve the creation of vesicles, rather it involves substances freely diffusing through the plasma membrane from areas of high concentration to areas of low concentration without the net input of energy.  ",
        "suggested_answer": "Pinocytosis",
        "options": [
            "Pinocytosis",
            "Phagocytosis",
            "Exocytosis",
            "Simple diffusion"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1062,
        "question": "Most of the ATP produced in cellular respiration comes from which of the following processes?",
        "answer": "Cellular respiration typically follows three steps, under aerobic conditions. Glycolysis generates NADH and converts glucose to pyruvate, while producing small amounts of ATP through substrate-level phosphorylation. The citric acids cycle, or Krebs cycle, uses pyruvate to generate more NADH and FADH2. These NADH and FADH2 molecules donate electrons to the electron transport chain, which are used to pump protons into the intermembrane space of the mitochondrion. The protons in the intermembrane space then flow through ATP synthase to generate large amounts of ATP via oxidative phosphorylation.",
        "suggested_answer": "Oxidative phosphorylation",
        "options": [
            "Citric acid cycle",
            "Oxidative phosphorylation",
            "Glycolysis",
            "Substrate-level phosphorylation",
            "Krebs cycle"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1063,
        "question": "A breeder wants to know her dog\u2019s genotype. The breed she works with comes in two varieties: black and yellow. Black is dominant to yellow. She breeds her black dog with a yellow dog and gets a litter of three black dogs and three yellow dogs. What is the genotype of the parental black dog?",
        "answer": "We know that the yellow dog must be homozygous recessive and that the black dog must be either heterozygous or homozygous dominant. When crossed, their offspring can show either the dominant (black) phenotype or the recessive (yellow) phenotype. In order for offspring to show the recessive phenotype, they must inherit a recessive allele from each parent. To have this outcome, the black dog must carry a recessive allele even though it expresses the dominant trait; this makes the black dog heterozygous.",
        "suggested_answer": "Heterozygous",
        "options": [
            "Heterozygous",
            "Homozygous recessive",
            "Unable to determine from the given information",
            "Homozygous dominant"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1064,
        "question": "Blood with the highest oxygen content would be found where?",
        "answer": "The correct answer is pulmonary veins. The pulmonary veins transfer the newly oxygenated blood towards the heart. Blood in these veins is highly concentrated with oxygen unlike any of the other locations mentioned. The pulmonary arteries bring oxygen-poor blood towards the lungs to be oxygenized.",
        "suggested_answer": "Pulmonary veins",
        "options": [
            "Pulmonary arteries",
            "Aorta",
            "Pulmonary veins",
            "Coronary artery",
            "Vena cava"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1065,
        "question": "What is the first structure encountered as blood interacts with a nephron?",
        "answer": "Filtration of blood occurs in the renal corpuscle, which is composed of the glomerulus and Bowman's capsule. The glomerulus is a complex net of capillaries that carry blood adjacent to Bowman's capsule. Bowman's capsule collects fluid filtrate from the blood and transfers to the rest of the nephron. The glomerulus is the only portion of the nephron to contain blood rather than filtrate.",
        "suggested_answer": "Glomerulus",
        "options": [
            "Proximal convoluted tubule",
            "Loop of Henle",
            "Glomerulus",
            "Distal convoluted tubule",
            "Collecting duct"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1066,
        "question": "A(n) __________ fat contains no double bonds in the fatty acid chain.",
        "answer": "A saturated fat contains no double bonds in its fatty acid chain. Just remember that saturated means the fat is saturated with hydrogens. Double bonds eliminate two hydrogen atoms per occurrence, and are present in unsaturated fats.",
        "suggested_answer": "Saturated",
        "options": [
            "Trans",
            "Saturated",
            "Cis",
            "Phosphorylated",
            "Unsaturated"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1067,
        "question": "Which of the following is a complication associated with pregnancy?",
        "answer": "Pregnancy related medical conditions and complications are incredibly common due to strain on the mother and extreme biological changes associated with pregnancy. Examples include postpartum depression, anemia, preeclampsia, and ectopic pregnancy.",
        "suggested_answer": "All of these",
        "options": [
            "Ectopic pregnancy",
            "All of these",
            "Postpartum depression",
            "Anemia",
            "Preeclampsia"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1068,
        "question": "Which of the following molecules give rise to the most net ATP?",
        "answer": "This is because glucose can net 36 ATP, NADH actually nets 3, FADH2 can net 2, and pyruvate can net 15. This answer involves a careful examination of respiration processes.",
        "suggested_answer": "glucose",
        "options": [
            "NADH",
            "sucrose",
            "glucose",
            "pyruvate",
            "FADH2"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1069,
        "question": "A point mutation during translation would most likely incorporate which of the following amino acids?",
        "answer": "The effect of a point mutation is not dependent on the amino acid the amino acid's selection is entirely independent of its structure. The amino acid selection during translation depends only on the three base-pair codon read by the ribosome. For example, the start codon AUG recruits methionine. If a frameshift mutation lead to UGG as the codon instead, tryptophan would be recruited. No single amino acid is more likely to be incorporated after a point mutation.",
        "suggested_answer": "It depends on the newly created codon",
        "options": [
            "It depends on the newly created codon",
            "Tyrosine",
            "Valine",
            "Proline",
            "Isoleucine"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1070,
        "question": "The induced fit model better explains enzyme substrate binding than does the lock and key model. The induced fit model explains which of the following, that is not explained by the lock and key model",
        "answer": "The lock and key model states that the active site of an enzyme precisely fits a specific substrate. The induced fit model states that the active site of an enzyme will undergo a conformational change when binding a substrate, to improve the fit. The induced fit model accounts for the broad specificity of enzymes as the active site is not rigid, but can undergo a conformational change to better fit the substrate binding.",
        "suggested_answer": "Broad specificity of enzymes",
        "options": [
            "Broad specificity of enzymes",
            "Rigid structure of substrate",
            "Rigid structure of enzymes",
            "Narrow specificity of enzymes"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1071,
        "question": "Independent assortment of traits on different chromosomes is due to the random alignment of different pairs of homologues. This alignment occurs during which of the given phases?",
        "answer": "Remember that the law of independent assortment states that genes on different chromosomes are passed independently of one another to offspring. This phenomenon results from the random alignment of the chromosomes along the metaphase plate. This random alignment allows genes to be segregated independently, and occurs during metaphase I.",
        "suggested_answer": "Metaphase I",
        "options": [
            "Metaphase I",
            "Metaphase II",
            "Prophase I",
            "Prophase II"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1072,
        "question": "Which of the following is an example of a post-zygotic reproductive barrier?",
        "answer": "A post-zygotic reproductive barrier occurs after fertilization has occurred. That is, the two individuals have already mated but either the offspring has died or it is sterile. A pre-zygotic barrier occurs before fertilization occurs. Pre-zygotic barriers are those that prevent individuals of different species from mating in the first place.",
        "suggested_answer": "A horse and a donkey breed successfully to produce a mule, which is sterile. ",
        "options": [
            "Two species of beetle do not interbreed because the males have different pheromones.",
            "Two species of toads do not interbreed because one species breeds in the summer and the other breeds in the spring.",
            "A horse and a donkey breed successfully to produce a mule, which is sterile. ",
            "Two species of sticklebacks do not interbreed because one species lives in fresh water and the other lives in coastal estuaries."
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1073,
        "question": "What is the main purpose of glycoproteins on the cell surface?",
        "answer": "Glycoproteins are attached to the cell membrane's lipid bilayer. They serve a unique role at the cellular level to help with cell to cell recognition. Glycoproteins serve as unique \"markers\" allowing nearby cells to know they have reached their destination. Cholesterol is embedded in the cell membrane to maintain fluidity.",
        "suggested_answer": "They are involved in cell-to-cell recognition",
        "options": [
            "They are involved in steroid hormone binding",
            "They are involved in ATP synthesis and the electron transport chain",
            "They are involved in maintaining cellular membrane fluidity",
            "They are involved in cell-to-cell recognition"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1074,
        "question": "What are the two main products that result from the breakdown of glucose in cellular respiration?",
        "answer": "The two main products that result from the breakdown of glucose are carbon dioxide and water. Carbon dioxide is produced during pyruvate dehydrogenase and the Krebs cycle. Water is formed at the end of the electron transport chain where two electrons (hydrogens) are added to oxygen (the final electron acceptor).",
        "suggested_answer": "Carbon dioxide and water",
        "options": [
            "Carbon dioxide and nitrogen",
            "Carbon dioxide and adenine",
            "Carbon dioxide and oxygen",
            "Carbon dioxide and water"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1075,
        "question": "Which of the following muscles contra to help deliver the baby during labor?",
        "answer": "During labor, uterine contractions help dilate the cervix and descend the baby into the birth canal. As labor progresses, contractions become regular and occur more frequently. As the contractions continue to bear down on the fetus, the labor process is aided by maternal pushing.",
        "suggested_answer": "Uterine",
        "options": [
            "Cervical",
            "Vaginal",
            "Uterine",
            "Fallopian"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1076,
        "question": "The given reaction is an example of __________.",
        "answer": "This is an example of an oxidation-reduction reaction or redox reaction. In a redox reaction, a negatively charged electron from one substance (sodium in this case) is transferred to another substance (chlorine in this case). This results in a positively charge sodium ion and a negatively charge chloride ion.",
        "suggested_answer": "redox reaction",
        "options": [
            "electron transferral reaction",
            "redox reaction",
            "electron swapping reaction",
            "electron compensation reaction"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1077,
        "question": "Double fertilization in angiosperms results in a __________ zygote and a triploid __________.",
        "answer": "Double fertilization is the process by which two sperm cells are introduced to the ovule. One sperm () fertilizes the egg (), creating a zygote(). The other sperm combines with the two polar nuclei (), forming the endosperm () that will nourish the embryo.",
        "suggested_answer": "diploid . . . endosperm",
        "options": [
            "haploid . . . endosperm",
            "diploid . . . seed",
            "diploid . . . endosperm",
            "diploid . . . fruit",
            "haploid . . . fruit"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1078,
        "question": " is found in vinegar. This molecule is commonly known as __________.",
        "answer": "You may recognize that this molecule is very similar to the acetate ion, , which results upon deprotonation of acetic acid. Acetic acid is a commonly used weak acid in chemistry.",
        "suggested_answer": "acetic acid",
        "options": [
            "dicarbon trihydrogen dioxide acid",
            "acetate",
            "acetic acid",
            "hydrogen dicarbon trihydrogen dioxide",
            "carbonic acid"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1079,
        "question": "Which of the following is a type of stem?",
        "answer": "Stems are one of the three basic plant organs, and consist of an alternating system of nodes (where leaves attach) and internodes (regions of the stem that span between nodes).",
        "suggested_answer": "Rhizomes",
        "options": [
            "Lateral roots",
            "Rhizomes",
            "Pith",
            "Stele"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1080,
        "question": "Which of the following is true for female individual\u2019s with amenorrhea?",
        "answer": "Amenorrhea is a condition where menstrual cycles are irregular and rare, occurring every 3 to 6 months.",
        "suggested_answer": "Irregular menstrual cycles",
        "options": [
            "No ovulation",
            "Heavy menstrual flow",
            "Irregular menstrual cycles",
            "Light menstrual flow"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1081,
        "question": "When will resonance be possible in a molecule?",
        "answer": "Resonance is the movement of electrons from one bond to another. This helps to shift the electron distribution between multiple atoms, creating molecular stability. In order for resonance to occur, there must be a pi bond next to a sigma bond. A pi bond is a double bond or triple bond and a sigma bond is a single bond. During resonance, the electrons from the pi bond move around causing the double (or triple) bond to shift positions. This frequently occurs with oxygen and nitrogen because they have several valence electrons and can readily form pi bonds, but these elements are not required to form resonance structures.",
        "suggested_answer": "The molecule must contain a double or triple bond adjacent to a single bond",
        "options": [
            "The molecule must have a triple bond adjacent to a single bond",
            "The molecule must contain carbon atoms",
            "The molecule must only contain single bonds ",
            "The molecule must contain oxygen or nitrogen",
            "The molecule must contain a double or triple bond adjacent to a single bond"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1082,
        "question": "In non-physiological reactions an increase in temperature will increase the reaction rate; however, in physiological reactions there is an optimum temperature at which an enzyme operates. Increasing the temperature beyond this will not increase enzyme activity or reaction rate. What explains this phenomenon?",
        "answer": "There is an optimum temperature at which an enzyme is most effective. Decreasing or increasing the temperature from the optimum will lead to denaturation of proteins, which will affect their functionality. Most protein structure is dependent on non-covalent intermolecular forces, such as hydrogen bonding and hydrophobic interactions. Heat can disrupt these forces, causing the protein to lose its structure, which leads to a loss of functionality.",
        "suggested_answer": "High temperatures will change the shape and functionality of proteins",
        "options": [
            "Increasing the temperature will increase the activation energy",
            "Increasing the temperature will decrease the activation energy",
            "Heat will shift the equilibrium to the left, favoring the reactant side",
            "High temperatures will change the shape and functionality of proteins"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1083,
        "question": "When a substance is transported across the cell membrane across the concentrations gradient (from an area of low concentration to an area of high concentration), this is referred to as __________.",
        "answer": "In order for a material to be transported across the cell membrane, against its concentration gradient, energy is required. For example, the sodium-potassium pump hydrolyzes one molecule of ATP to pump 3 sodium ions out of the cell and 2 potassium ions into the cell. Since the potassium levels are much higher inside the cell than outside, energy is required. This is known as active transport.",
        "suggested_answer": "active transport",
        "options": [
            "diffusion",
            "passive transport",
            "active transport",
            "osmosis",
            "co-transport"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1084,
        "question": "What happens when electrons get transported along the electron transport chain?",
        "answer": "When electrons go through the electron transport chain, the protons in the matrix of the mitochondrion are pumped into the intermembrane space (the space between inner and outer membranes). This creates a proton gradient that is used by ATP synthase to create ATP through oxidative phosphorylation, not substrate-level phosphorylation. Remember that substrate-level phosphorylation is used by glycolysis and the Krebs cycle to generate ATP.",
        "suggested_answer": "Protons are pumped into the intermembrane space",
        "options": [
            "Protons are pumped into the matrix",
            "It produces a proton gradient that helps generate ATP using substrate-level phosphorylation",
            "Protons are pumped into the intermembrane space",
            "Electrons are transferred from a protein of high electron affinity to a protein of low electron affinity"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1085,
        "question": "When a repressor binds to the __________, the LacZ gene does not get expressed. ",
        "answer": "The region of a gene where activator/repressors bind in prokaryotic organisms is called an operator. The protein that's bound to the operon strongly influences the level of gene expression.",
        "suggested_answer": "operator",
        "options": [
            "operator",
            "terminator",
            "gene",
            "promoter"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1086,
        "question": "Where does primary succession occur?",
        "answer": "Primary succession is the colonizing of a new environment that lacks organisms and plant life. Primary succession is different from secondary succession; secondary succession is the colonizing of an environment that once supported life but was abandoned due to an ecological disturbance. An example of this is the colonizing of new land formed by lava flow.",
        "suggested_answer": "An environment lacking plant and animal life",
        "options": [
            "An environment lacking plant and animal life",
            "A currently inhabited environment",
            "None of these",
            "A previously inhabited environment "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1087,
        "question": "Which of the following choices best describes the event in tyrosine kinase receptor activation that transmits a signal that regulates cellular gene transcription?",
        "answer": "Tyrosine kinase receptors are fully activated when they bind to an extracellular ligand, dimerize, and then autophosphorylate at tyrosine residues on the C terminus. The signal is not transduced until relay proteins are phosphorylated by the tyrosine kinases. These relay proteins can then stimulate a phosphorylation cascade that initiates signaling pathways, which regulate nuclear gene transcription",
        "suggested_answer": "Binding of relay proteins to phosphorylated tyrosine residues",
        "options": [
            "Autophosphorylation of tyrosine residues",
            "Binding of a ligand to the N terminus ",
            "Binding of relay proteins to phosphorylated tyrosine residues",
            "Dimerization of tyrosine kinase receptors"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1088,
        "question": "Which of the following is false of sympatric populations?",
        "answer": "Allopatric literally means other fatherland, meaning that populations are geographically separate. Because populations are separate, they may use similar resources and be similar in structure. Allopatric populations come about due to geographic isolation, rather than behavioral isolation. Sympatric means same fatherland, meaning that populations are geographically overlapping and diverge due to behavioral isolation. As a result, they may also, show divergence from a common resource and original body structure. Where the two sympatric populations overlap, there is a hybrid zone. The two species may mate and produce hybrid offspring. ",
        "suggested_answer": "Sympatric populations diverge due to geographic isolation ",
        "options": [
            "Sympatric populations diverge due to geographic isolation ",
            "Sympatric populations are geographically overlapping ",
            "Sympatric populations show differences in body structure ",
            "Sympatric populations diverge due to behavioral isolation",
            "Sympatric populations may form hybrids "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1089,
        "question": "Which of the following terms can be defined as the initial investment of energy in a reaction that is required to break bonds in the reactant molecules?",
        "answer": "The investment of energy required to start a reaction is called the free energy of activation or activation energy. Activation energy is usually in the form of heat from the surrounding area that molecules in the reaction absorb.",
        "suggested_answer": "Activation energy",
        "options": [
            "Activation energy",
            "Consumed energy",
            "Reaction energy",
            "Initiation energy "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1090,
        "question": "The latin name (binomial nomenclature) of organisms consists of which two classifications?",
        "answer": "The latin name consists of the genus and species of the organism. These are the most specific of the taxonomic classifications. The taxonomic classifications are domain, kingdom, phylum, class, order, family, genus, and species.",
        "suggested_answer": "Genus and species",
        "options": [
            "Genus and species",
            "Phylum and species",
            "Order and family",
            "Class and order"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1091,
        "question": "Which of the following characteristics represent true features of dendrites?",
        "answer": "Dendrites are branched projections of the neuron that receive electrical signals from other cells. The dendrites are connected to the soma cell body.",
        "suggested_answer": "All of these",
        "options": [
            "They receive electrical signals from other cells",
            "All of these",
            "They are branched projections ",
            "They connect to the soma"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1092,
        "question": "What statement best defines the membrane potential?",
        "answer": "Membrane potential is a term used to describe the difference in electrical charge across a cell membrane. This difference is determined by ion concentration inside and outside of the cell and can change due to ion-gated channels and ion pumps, among others. Membrane potential allows cells to transmit electrical signals. During an action potential, the membrane is said to be depolarized, which means the membrane potential becomes less negative.",
        "suggested_answer": "Difference in electrical charge between the interior and exterior of the cell ",
        "options": [
            "The membrane potential in a cell never changes",
            "The difference in electrical charge across a membrane is determined only by sodium ion concentration",
            "Difference in electrical charge between the interior and exterior of the cell ",
            "It is the electrical charge reached by action potential"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1093,
        "question": "Cells containing only one homologue of each chromosome would be produced following which of the following processes?",
        "answer": "For this question, remember that a cell containing only one homologue is a haploid cell. Cells containing two homologous chromosomes are considered diploid.",
        "suggested_answer": "Meiosis I",
        "options": [
            "S phase",
            "Both meiosis I and mitosis",
            "Mitosis",
            "Meiosis I"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1094,
        "question": "Which of the following is indicative of the third trimester of pregnancy?",
        "answer": "The third trimester of pregnancy takes place during weeks 29 and 40. During this stage of pregnancy, women continue to gain weight, the fetus moves regularly, and the fetal head descends. The descent of the fetal head is important in easing maternal breathing and positioning the fetus for future delivery.",
        "suggested_answer": "Fetal head descent",
        "options": [
            "Implantation",
            "Initial fetal movement",
            "Morning sickness",
            "Fetal head descent"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1095,
        "question": "Which of the following is true of photosynthesis? ",
        "answer": "Photosynthesis occurs in the chloroplasts of plant cells, where carbon dioxide is reduced and water is oxidized to create sugar. During this process electrons demonstrate increased potential energy as they move from water to sugar. The process requires an initial energy input, derived from photons.",
        "suggested_answer": "Carbon dioxide is reduced",
        "options": [
            "The process requires no energy",
            "Water is reduced",
            "Carbon dioxide is oxidized",
            "Electrons decrease in potential energy as they move from water to sugar",
            "Carbon dioxide is reduced"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1096,
        "question": "The loss of which organelle would cripple the process of cellular division? ",
        "answer": "Microtubules make up the spindle apparatus and is responsible for the separation of homologous chromosomes in cell division. Microfilaments make up of actin which is responsible for muscle contraction. Ribosomes help make repairs in the cell and aid in protein synthesis. Nucleus stores DNA and the mitochondria produces ATP. Although all of these play a role in cell division, without microtubules cell division will not occur. ",
        "suggested_answer": "microtubules",
        "options": [
            "ribosomes",
            "nucleus",
            "microfilaments",
            "mitochondria",
            "microtubules"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1097,
        "question": "In the Krebs cycle, what compound is formed when two carbon atoms from pyruvic acid are joined to coenzyme A?",
        "answer": "Acetyl-CoA is formed when two carbon atoms join to coenzyme A. Ribose is a five carbon sugar in RNA. ATP is an energy compound, and FAD is an electron carrier in the Krebs cycle which gets reduced then passes its electron to an enzyme complex in the electron transport chain.",
        "suggested_answer": "Acetyl-CoA",
        "options": [
            "Ribose",
            "Acetyl-CoA",
            "ATP",
            "FAD"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1098,
        "question": "Homologous pairs of chromosomes separate depending on the random way they line up on the metaphase plate during metaphase I. What is this process called?",
        "answer": "Random rearranging of alleles on chromosomes that occurs as a result of homologous pairs lining up during metaphase is known as independent assortment and is a factor in genetic diversity. Cytokinesis is the physical process for cell division. Glycolysis is the process of breaking down sugars to generate ATP. G1 phase is the first of four phases of the cell cycle that takes place in eukaryotic cell division. Mitosis only has one metaphase, since the question stem indicates metaphase I, we know that the overall process is meiosis, during which there are two cell divisions.",
        "suggested_answer": "Independent assortment",
        "options": [
            "Independent assortment",
            "Glycolysis",
            "Mitosis",
            "G1 phase",
            "Cytokinesis"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1099,
        "question": "A __________ bond between the sugar of one nucleotide and the phosphate of an adjacent nucleotide stabilizes the backbone of the DNA. ",
        "answer": "The bond formed between the sugar of one nucleotide and the phosphate of an adjacent nucleotide is a covalent bond. A covalent bond is the sharing of electrons between atoms. A covalent bond is stronger than a hydrogen bond (hydrogen bonds hold pairs of nucleotides together on opposite strands in DNA). Thus, the covalent bond is crucial to the backbone of the DNA. ",
        "suggested_answer": "covalent",
        "options": [
            "weak",
            "hydrogen",
            "metallic",
            "ionic",
            "covalent"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1100,
        "question": "DNA with its associated protein scaffold forms a complex called __________.",
        "answer": "Chromatin is the DNA-protein complex and is organized as a long, thin fiber. Chromosomes are densely-packed chromatin, wrapped around proteins called histones. The centromere is the region of a condensed chromosome that connects sister chromatids to each other, and is the site at which the spindle fibers attach during mitosis in order to move them about the cytoplasm.",
        "suggested_answer": "chromatin",
        "options": [
            "nucleotide",
            "chromatid",
            "chromatin",
            "centromere",
            "chromosome"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1101,
        "question": "Which of the following correctly describes the contraction of a muscle?",
        "answer": "There are two compounds that are absolutely necessary for muscle contraction: ATP and calcium ions. Calcium ions bind to troponin, which removes tropomyosin from the binding site on actin. Only after this change occurs can myosin bind to actin and cause a contraction. ATP is hydrolyzed to alter myosin into a high energy state. This energy is released during the muscle contraction when myosin binds to actin. Even if ATP is present, the contraction cannot occur without calcium, and even if calcium is present it cannot occur without ATP.",
        "suggested_answer": "ADP is bound to myosin when the sarcomere shortens",
        "options": [
            "ADP is bound to myosin when the sarcomere shortens",
            "If ATP is present in the muscle cell, then it is always able to contract",
            "Calcium is a cofactor that allows myosin to bind ATP",
            "ATP is released from myosin when the sarcomere shortens",
            "Glucose must be present in the cytosol during muscle contraction"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1102,
        "question": "Which of the following describes the enzyme reverse transcriptase?",
        "answer": "Reverse transcriptase binds RNA and catalyzes the formation of a complementary DNA molecule. Since it needs an RNA template to work, this enzyme is RNA dependent. Since it forms a DNA molecule, this enzyme is a DNA polymerase. Recall the chemical difference between DNA and RNA - DNA lacks a hydroxide group at the 3' position of the ribose sugar, which makes it deoxyribose.",
        "suggested_answer": "An RNA dependent DNA polymerase",
        "options": [
            "An RNA dependent protein polymerase",
            "An RNA dependent RNA polymerase",
            "A DNA dependent RNA polymerase",
            "An RNA dependent DNA polymerase",
            "A DNA dependent DNA polymerase"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1103,
        "question": "What is the part of an amino acid that makes it different compared to other amino acids?",
        "answer": "All amino acids have an amino group and a carboxyl group. Amino acids do not have glycerol. The side chain called an R-group is what differentiates amino acids from each other in their chemical properties and functions.",
        "suggested_answer": "The side chain called the R-group.",
        "options": [
            "Amino group",
            "The side chain called the R-group.",
            "Carboxyl group",
            "glycerol"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1104,
        "question": "A process in which chance events are likely to change allele frequencies in a small population is known as __________.",
        "answer": "Genetic drift is a change in the allele frequencies of a small population purely by chance.",
        "suggested_answer": "genetic drift",
        "options": [
            "bottleneck effect",
            "natural selection",
            "allele fluctuation",
            "genetic drift",
            "evolution"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1105,
        "question": "Jellyfish possess a digestive sac with a single opening through which food is injested and wastes are ejected. What term describes this organ?",
        "answer": "Jellyfish possess a gastrovascular cavity, which serves as both mouth and anus. Food is captured and enters the gastrovascular cavity, where enzymes break it down and cells absorb the nutrients. The undigested remains leave through the same opening by which they entered.",
        "suggested_answer": "Gastrovascular cavity",
        "options": [
            "Tubular digestive tract",
            "Crop",
            "Gastrovascular cavity",
            "Food vacuole",
            "Gizzard"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1106,
        "question": "In the process of meiosis, what is the correct description of the gametes?",
        "answer": "In meiosis, the gametes are haploid having half the number of chromosomes of a diploid cell. The gametes are the egg or sperm cells that combine in sexual reproduction.",
        "suggested_answer": "The gametes are haploid, with half the number of chromosomes compared to the parent cell",
        "options": [
            "The gametes are haploid, with half the number of chromosomes compared to the parent cell",
            "The gametes are diploid, with half the number of chromosomes compared to the parent cell",
            "The gametes are polyploid",
            "The gametes are diploid, with twice the number of chromosomes compared to the parent cell"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1107,
        "question": "Which of the following best explains how epithelial cells are involved in sensory detection?",
        "answer": "Epithelial tissue has a sensory detection function that utilizes cilia to sense signals from the external environment. Once the signal is recognized and transduced, epithelial cells secrete messenger proteins that transmit the signal downstream.",
        "suggested_answer": "By sensing external signals through cilia",
        "options": [
            "Secretion of hormones",
            "Infolding into glands ",
            "By chemotaxis",
            "By sensing external signals through cilia"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1108,
        "question": "Which of the following groups include single-celled eukaryotes?",
        "answer": "Protists consist of single celled eukaryotes and a few closely related multicellular organisms. Plantae contain multicellular eukaryotic organisms. Archaea and Bacteria are all prokaryotic. Examples of a single-celled eukaryotic protist are species of the genus Plasmodium, which cause malaria.",
        "suggested_answer": "Protista",
        "options": [
            "Archaea",
            "Bacteria",
            "Plantae",
            "Protista"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1109,
        "question": "Which of the following is best defined as a chemical agent that changes the rate of a reaction without being consumed by that reaction?",
        "answer": "A catalyst is a substance that can change the rate of a reaction without being consumed by the reaction. Catalysts only affect the speed of the reaction. If a reaction would not occur naturally then the addition of a catalyst will have no effect.",
        "suggested_answer": "Catalyst",
        "options": [
            "Conductor",
            "Modifier",
            "Helper ",
            "Catalyst"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1110,
        "question": "Which of the three muscle types controls the muscles in your throat involved in swallowing?",
        "answer": "Swallowing involves both skeletal muscle and smooth muscle. While skeletal muscle is voluntary (somatic), smooth muscle is involuntary (autonomic). Swallowing involves both of these systems. One can voluntarily induce swallowing, but will also swallow involuntarily, such as while sleeping.",
        "suggested_answer": "Smooth muscle and skeletal muscle",
        "options": [
            "Neither smooth muscle, nor skeletal muscle",
            "Smooth muscle and skeletal muscle",
            "Skeletal muscle",
            "Smooth muscle"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1111,
        "question": "Which of the following is not a function of the cumulus oophorus?",
        "answer": "The cumulus oophorus is a cluster of cells surrounding the oocyte after ovulation. The cumulus oophorus protects the oocyte and provides it with the energy substrates needed for further development.",
        "suggested_answer": "Protect and provide energy substrates to the ooctye",
        "options": [
            "Protect the oocyte",
            "Provide energy substrates to the oocyte",
            "The cumulus oophorus neither protects the oocyte nor provides it with energy substrates",
            "Protect and provide energy substrates to the ooctye"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1112,
        "question": "During copulation, where does the penis release the ejaculate in the female reproductive tract?",
        "answer": "During copulation and sexual intercourse, the penis enters the female vagina. Ejaculation the release of semen from the penis also occurs in the vagina.",
        "suggested_answer": "Vagina",
        "options": [
            "Vagina",
            "Cervix",
            "Uterus",
            "Fallopian tubes"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1113,
        "question": "What is the name for the following compound: ?",
        "answer": "This is an ionic compound because there is a metal (iron) bound to a nonmetal (the polyatomic ion nitrate). Iron, is a transition metal, which means it can take on multiple different charges. To find the charge of iron in this compound we must not that the compound as a whole is neutral. Also we must know the charge on the nitrate ion, , is negative one. To balance the two nitrate ions with one iron atom, the iron must carry a positive two charge. In naming ionic compounds with transition metals, we must use Roman numerals to indicate the charge on the metal. Thus the name of this compound is iron(II) nitrate.",
        "suggested_answer": "Iron(II) nitrate",
        "options": [
            "Iron(I) nitrate",
            "Iron(I) nitrite",
            "Iron(II) nitrite",
            "Iron(II) nitrate",
            "Iron nitrate"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1114,
        "question": "Which of the following represents the primary features of white matter in the central nervous system?",
        "answer": "White matter is a component of the central nervous system that contains myelinated axons and is located in the deep parts of the brain and superficial spinal cord. The term white matter comes from the white color of the area due to the fatty myelin that surrounds the axons. White matter is involved in communication between the gray matter and other parts of the central nervous system.",
        "suggested_answer": "All of these",
        "options": [
            "The white color comes from the fatty myelin around the axons",
            "All of these",
            "It contains myelinated axons",
            "White matter is located in the deep parts of the brain and superficial spinal cord"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1115,
        "question": "Which of the following factors has an effect on the rate at which enzymes catalyze a reaction?",
        "answer": "The temperature and pH of the environment, as well as the concentration of the substrate and enzyme, all affect the rate at which an enzyme catalyzes a reaction. As a result, enzymes have optimal conditions in which they can work at peak efficiency.",
        "suggested_answer": "All of these factors have an effect on the rate at which enzymes catalyze a reaction",
        "options": [
            "pH of environment",
            "Concentration of substrate and enzyme",
            "All of these factors have an effect on the rate at which enzymes catalyze a reaction",
            "Temperature of environment"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1116,
        "question": "The entire collection of DNA in a cell is called the __________.",
        "answer": "The genome is defined as the total genetic library of a cell. It is estimate that in humans, the genome codes for about 25,000 different genes.",
        "suggested_answer": "genome",
        "options": [
            "genome",
            "chromatin",
            "chromosome",
            "nucleus",
            "DNA ladder"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1117,
        "question": "The lack of a nucleus in bacterial cells allows what two cellular processes to occur in tandem?",
        "answer": "Since DNA is not separated from the cytosol in bacterial cells, nascent messenger RNAs that are being transcribed by RNA polymerase can be bound by ribosomes and translation can be initiated before the entire mRNA is even transcribed. All other processes listed may occur simultaneously in prokaryotic and/or eukaryotic cells.",
        "suggested_answer": "Transcription and translation",
        "options": [
            "Glycolysis and the Krebs cycle",
            "Transcription and protein folding",
            "ATP synthesis and translation",
            "Transcription and translation",
            "Translation and protein export"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1118,
        "question": "In prokaryotic transcription, which of the following is recruited to facilitate the binding of the synthesis enzyme to the DNA template?",
        "answer": "Inactive RNA polymerase must bind to a specific sigma factor in order to become active in gene transcription. Sigma factors are specialized transcription factors involved in recruiting and activating RNA polymerase. Only once RNA polymerase has bound the sigma factor can it identify promoter sequences and initiate transcription.",
        "suggested_answer": "The sigma factor",
        "options": [
            "Messenger RNA",
            "A promoter sequence",
            "RNA polymerase",
            "The sigma factor"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1119,
        "question": "During cellular interphase, DNA appears to have regions of tight winding within its strands. This gives the appearance of \"beads\" within the DNA. Which of the following forms the \"beads\" seen during interphase?",
        "answer": "Nucleosomes are complexes of DNA wrapped around eight histone molecules, often resembling \"beads on a string\" during interphase. The tight wrapping of DNA around the histones prevents it from being transcribed, but also condenses it. Nucleosomes can be loosened to regulate the transcription of associate DNA and genes.",
        "suggested_answer": "Nucleosomes",
        "options": [
            "Looped domains",
            "Nucleosomes",
            "Heterochromatin",
            "Histone tails",
            "Nucleoids"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1120,
        "question": "What is the role of the vacuole in plant cells?",
        "answer": "Plant cells contain one very large vacuole that occupies a large percentage of the cell space. The central function of this vacuole is to maintain the turgor pressure of the cell through the continual transport of water and ions in and out of the cell, as needed. Vacuoles store and transport water, waste, and ions too.",
        "suggested_answer": "Stores water and wastes, and maintains turgidity",
        "options": [
            "Stores water and wastes, and maintains turgidity",
            "Stores water only",
            "Stores water and waste only",
            "Maintains turgidity only",
            "Stores waste only"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1121,
        "question": "What are extranuclear genes and what are their functions?",
        "answer": "Extranuclear genes, also known as cytoplasmic genes, are genes located outside the nucleus. They can be found in other parts of the cell, such as the mitochondrion, chloroplasts, and other plastids. Each of these organelles is thought to have originally been an independent prokaryotic cell, ingested by another cell, known as the endosymbiotic theory. Each of these organelles has its own DNA and works in cooperation within the nuclear DNA. ",
        "suggested_answer": "Extranuclear genes are genes located outside the nucleus that aid in DNA replication and protein synthesis",
        "options": [
            "Extranuclear genes are genes located outside the nucleus that do not play a significant role within the cell ",
            "None of these",
            "Extranuclear genes are genes located outside the nucleus that aid in DNA replication and protein synthesis",
            "Extranuclear genes are additional genes within the nucleus that do not play a significant role within the cell ",
            "Extranuclear genes are additional genes within the nucleus that play a role in DNA replication and protein synthesis"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1122,
        "question": "Chitin is what class of molecule?",
        "answer": "Chitin is a carbohydrate. Specifically, it is a polysaccharide used by arthopods to build exoskeletons, and is found in the cell walls of fungi. Waxes are types of lipids, and nucleic acids are DNA and RNA.  ",
        "suggested_answer": "Carbohydrate",
        "options": [
            "Nucleic acid",
            "Carbohydrate",
            "Lipid",
            "Wax"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1123,
        "question": "Which two major proteins are involved in the contraction of skeletal muscle?",
        "answer": "The contraction of muscle cells results from the controlled interaction between actin and myosin proteins. ",
        "suggested_answer": "Actin and myosin",
        "options": [
            "Actin and myosin",
            "Myosin and ATP",
            "Titin and actin",
            "Actin and ATP"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1124,
        "question": "Which of the following phase changes does water undergo during the process of condensation?",
        "answer": "Condensation is one of three stages of the water cycle. In the process of condensation, water converts from a gaseous phase to a liquid phase; thus, condensation is the opposite of the process of evaporation.",
        "suggested_answer": "Gas to liquid",
        "options": [
            "Solid to liquid",
            "Liquid to solid",
            "Gas to liquid",
            "Liquid to gas"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1125,
        "question": "Which heart chamber would you expect to have the thickest myocardial wall?",
        "answer": "The left ventricle is responsible for pumping blood to all body tissues. Because it needs to pump blood a farther distance than the right ventricle (which pumps blood to the lungs), it requires a thicker myocardial wall. This provides it with a more powerful contraction in order to send blood throughout the body. The left ventriclar wall is approximately three times thicker than the right ventricular wall.",
        "suggested_answer": "The left ventricle",
        "options": [
            "The left ventricle",
            "The right atrium",
            "The right ventricle",
            "The left atrium"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1126,
        "question": "The light reactions of photosynthesis are responsible for the production of which of the following molecules?",
        "answer": "The light reactions are the steps of the photosynthesis that function to convert solar energy into chemical energy that can be used during the Calvin cycle. The light reactions use solar energy to reduce  to and to convert to . ",
        "suggested_answer": "Converting solar energy to chemical energy",
        "options": [
            "Converting solar energy to chemical energy",
            "Producing sugars",
            "Producing carbon dioxide",
            "Producing water"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1127,
        "question": "When cooking an egg, opening the egg over a hot pan causes the non-yolk part of the egg to go from clear and viscous to white and solid. Adding heat to the egg white protein part of the egg is part of a process called what?",
        "answer": "Denaturation is the correct answer here. The denaturation of a protein occurs when a catalyst causes the disruption and/or destruction of the bonds in a protein structure. Heat is one of the ways to denature a protein because the heat causes the molecules to vibrate quickly and coagulate into the white substance we eat.",
        "suggested_answer": "Denaturation",
        "options": [
            "Thermodynamics",
            "Hybridization",
            "Naturalization",
            "Thermosolidification",
            "Denaturation"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1128,
        "question": "Eukaryotic DNA is packed in a chromatin structure, making it hard for DNA to be transcribed. What proteins are associated with the packing of DNA? ",
        "answer": "Histones are associated with DNA packed in chromatin. Acetylation of these histones allows for DNA transcription. Other proteins mentioned play no part in DNA chromatin structure.",
        "suggested_answer": "Histones",
        "options": [
            "Polymerases",
            "Myosin",
            "Actin",
            "Collagen",
            "Histones"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1129,
        "question": "Which of the following traits is shared by both red blood cells (erythrocytes) and white blood cells (leukocytes)?",
        "answer": "Erythrocytes and leukocytes are both produced and mature in bone marrow, with the exception of T cells, a type of white blood cell that matures in the thymus. Unlike leukocytes, red blood cells contain no nuclei or mitochondria, which could interfere with transporting oxygen, carbon dioxide, and nutrients.",
        "suggested_answer": "They are all produced in bone marrow",
        "options": [
            "They are all produced in bone marrow",
            "They all contain mitochondria",
            "They all mature in bone marrow",
            "They all contain a nucleus"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1130,
        "question": "What is the function of a helicase enzyme during DNA replication?",
        "answer": "Helicases are enzymes that separate annealed strands of nucleic acids. This function provides the single-stranded template used in replication.",
        "suggested_answer": "Unwind the double-stranded DNA",
        "options": [
            "Fix breaks in the sugar-phosphate backbone",
            "Unwind the double-stranded DNA",
            "Scan for mismatches",
            "Add primers to the lagging strand"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1131,
        "question": "In preparation for cellular division, the cell must first duplicate its chromosomes. Each duplicated chromosome consists of two __________.",
        "answer": "Sister chromatids are two identical copies of the same chromosome. They are attached to each other at a region called the centromere. Homologous chromosomes are not identical. Rather, they are chromosomes that look alike, and have the same genes at the same loci, but not necessarily the same versions of those genes. For example, we all have two copies of chromosome number 1. Assume eye color is coded on this chromosome. One copy may have the brown allele, while the other copy might have the green allele. Both chromosomes have loci that code for eye color, but they are not identical since one came from each parent.",
        "suggested_answer": "sister chromatids",
        "options": [
            "sister chromatids",
            "homologous chromosomes",
            "duplicates",
            "chromatins",
            "centromeres"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1132,
        "question": "Which of these is not a classification of epithelial cell?",
        "answer": "Epithilial tissue is usually classified in two ways: by the shape of the cells and by their organization. Cell shape can be flat and polygonal (squamous), elongated and rectangular (columnar), or short and rectangular (cuboidal). Cells can be found in a single layer (simple) or multiple layers (stratified). Pseudostratified epithelium is usually columnar in shape and consists of a single cell layer that has the appearance of multiple layers.",
        "suggested_answer": "Spirilli",
        "options": [
            "Pseudostratified",
            "Spirilli",
            "Cuboidal",
            "Simple",
            "Columnar"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1133,
        "question": "In which of the following leaf tissues does the most photosynthesis take place?",
        "answer": "The mesophyll of a leaf contains palisade parenchyma cells (along with other cell types) which contain a high concentration of chloroplasts and are located near the edge of the leaf. These parenchyma cells in the mesophyll perform the bulk of the photosynthesis in a plant, though any part of a plant that contains chlorophyll can photosynthesize, including the spongy mesophyll.",
        "suggested_answer": "Mesophyll",
        "options": [
            "Epidermis",
            "Mesophyll",
            "Stoma",
            "Guard cells",
            "Cuticle"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1134,
        "question": "Due to the abundance of blue-bellied lizards, they are easier for predators to find. Predators such as hawks become adapted to spotting these blue-bellied lizards and prey on them more frequently than yellow-bellied lizards. When the blue-bellied lizard population begins to decline, the yellow-bellied lizard population begins to rise (due to more abundant resources and the fact that they are preyed on less than the blue-bellies). Which of the following best describes the relationship between the two types of lizards?",
        "answer": "This relationship displays a frequency-dependent selection. Frequency-dependent selection occurs when the fitness of a phenotype depends on how common it is in the population. When blue-bellied lizards are preyed on the most because they are most abundant, the yellow-bellied lizard population grows quickly. However, as the blue-bellied lizard population declines, predators will begin to prey on the yellow-bellied lizards. As the yellow-bellied lizard population declines, the blue-bellied lizard population will increase, and so on. The number of blue or yellow-bellied lizards depends on the relative amount of lizards of a different phenotype, demonstrating frequency-dependent selection. ",
        "suggested_answer": "Frequency-dependent selection",
        "options": [
            "Frequency-dependent selection",
            "Disruptive selection ",
            "Relative fitness ",
            "Heterozygote advantage",
            "Intersexual selection"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1135,
        "question": "During primary succession, when are pioneer species likely to be found in an environment?",
        "answer": "Pioneer species are the first species to colonize an environment, and thus would be found in the early stages of succession.",
        "suggested_answer": "Near early stages of succession",
        "options": [
            "Near early stages of succession",
            "During the summer",
            "Near later stages of succession",
            "When many nutrients are available"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1136,
        "question": "Which of the following is not true regarding keratinized stratified epithelia?",
        "answer": "Keratinized epithelia are specialized stratified epithelia. They consist of a layer of dead exterior cells that contain the protein keratin. Keratin waterproofs the cells, offering protection. As a result, keratinized epithelia are commonly found in mammalian skin.",
        "suggested_answer": "Keratinized epithelia are found in tissues that stretch",
        "options": [
            "The exterior of the cell layers is dead",
            "The exterior of the cell layers contains keratin",
            "Keratinized epithelia are waterproof",
            "Keratinized epithelia are found in tissues that stretch"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1137,
        "question": "What is the hormone responsible for decreasing blood levels of calcium?",
        "answer": "The hormone responsible for the reduction of calcium in the blood is calcitonin. This hormone promotes the assimilation of calcium ions into the bone matrix from the blood. This reduces the concentration of calcium ions in the blood and increases the concentration found in the bone. Meanwhile, PTH has the opposite effect by taking calcium ions from the bone matrix and releasing them into the blood.",
        "suggested_answer": "Calcitonin",
        "options": [
            "Parathyroid hormone (PTH)",
            "Calcitonin",
            "Melatonin",
            "Thyroxine",
            "Triiodothyronine"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1138,
        "question": "What is the founder effect?",
        "answer": "The founder effect describes the phenomenon when a smaller group that originally came from a part of a larger population forms their own population. This new population will likely have a biased gene pool that will not be identical to the parent population. For example, if a certain species of bird gains a mutation such that some members are capable of flying farther, these birds may eventually separate to a different location and form their own unique population with a higher predominance of the \"sustained flight\" mutation than the original population.",
        "suggested_answer": "Loss of genetic variety due to a small number of individuals from a larger population establishing a new population",
        "options": [
            "Introduction of a new species that has a higher fitness than the native populations",
            "The evolution of a new species",
            "A decrease in genetic variety due to fluctuation in numbers of specific traits in a population",
            "Loss of genetic variety due to a small number of individuals from a larger population establishing a new population"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1139,
        "question": "Which of the following is not a phase of mitosis?",
        "answer": "The five phases of mitosis are prophase, prometaphase, metaphase, anaphase, and telophase in that order. Since mitosis involves the production of two genetically identical diploid daughter cells from one parent cell, there is only one cell division. Thus, all phases that dictate a Roman numeral refer to meiosis, which involves the production of four nonidentical haploid daughter cells from one parent cell as the result of two cell divisions. During anaphase II of meiosis, the sister chromatids are pulled to opposite poles of the cell, and the second round of cytokinesis begins.",
        "suggested_answer": "Anaphase II",
        "options": [
            "Prometaphase",
            "Anaphase II",
            "Prophase",
            "Telophase"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1140,
        "question": "Which of the following best describes the role of oxytocin in delivery?",
        "answer": "During childbirth, oxytocin stimulates fetal expulsion through cervical dilation and uterine contractions. Oxytocin is also important in the development of maternal feelings of attachment to the fetus and plays a role in lactation.",
        "suggested_answer": "Stimulates the expulsion of the fetus",
        "options": [
            "Prevents cervical dilation",
            "High levels increase the risk for postpartum depression",
            "Directly releases the cervical mucus plug",
            "Stimulates the expulsion of the fetus",
            "All of these"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1141,
        "question": "Which of the following is not a type of precipitation?",
        "answer": "Precipitation is when condensed water vapor falls to Earth. Common types of precipitation include rain, sleet, and snow. In contrast, runoff is water flow across the Earth\u2019s surface.",
        "suggested_answer": "Runoff",
        "options": [
            "Runoff",
            "Sleet",
            "Rain",
            "Snow"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1142,
        "question": "Which of the following is an example of a lipid?",
        "answer": "Cholesterol is a type of steroid, which is a lipid. Amino acids are part of proteins. Cellulose and disaccharides are examples of carbohydrates.",
        "suggested_answer": "Cholesterol",
        "options": [
            "Disaccharide",
            "Cholesterol",
            "Cellulose",
            "Amino acid"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1143,
        "question": "Which statement is true regarding DNA?",
        "answer": "DNA is a nucleic acid, made up of the nucleotides, which contain a deoxyribose sugar, a phosphate group, and one of four nitrogenous bases. These bases are adenine, cytosine, guanine, and thymine. DNA is double-stranded, but transiently single-stranded during DNA replication and transcription.",
        "suggested_answer": "It contains adenine, guanine, cytosine, and thymine",
        "options": [
            "It contains adenine, guanine, cytosine, and thymine",
            "It is a single-stranded molecule",
            "It contains adenine, guanine, cytosine, and uracil",
            "It cannot be replicated",
            "It is a protein"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1144,
        "question": "Which of the following best describes type IIx skeletal muscle contraction?",
        "answer": "Type IIx skeletal muscle tissue is the fastest muscle type in humans. This subtype of skeletal muscle tissue is characterized by short bursts of anaerobic contraction. It is also able to contract more quickly than aerobic muscles.",
        "suggested_answer": "All of these",
        "options": [
            "Sustains short bursts of anaerobic contraction",
            "It contracts more quickly than aerobic muscles ",
            "All of these",
            "It is the fastest muscle type in humans"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1145,
        "question": "What happens to a bacterial cell if you place it in a hypotonic solution?",
        "answer": "If you place a cell in a hypotonic solution it means that the solution contains less solute (and more water) than the cell\u2019s cytosol. Recall that osmosis causes water to go from a region of high water content (few solutes) to a region of low water content (more solutes).",
        "suggested_answer": "It swells because the cytosol contains more solute",
        "options": [
            "It swells because the cytosol contains less solute",
            "It shrivels because the cytosol contains more solute",
            "It shrivels because the cytosol contains less solute",
            "It swells because the cytosol contains more solute"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1146,
        "question": "Which of the following is true regarding a neuron?",
        "answer": "Neurons have many dendrites, one cell body, and a single axon with several terminal branches. A dendrite receives an external stimulus and causes an electrical disturbance in the cell body. This electrical disturbance is transmitted to the axon, where an action potential is generated if the stimulus is large enough. The action potential is propagated through the axon and is transmitted to a neighboring neuron at the synapse.",
        "suggested_answer": "The propagation of signal through a neuron initiates at the dendrite, enters the cell body, and is transmitted from the axon",
        "options": [
            "Neurons transmit electrical signals from one cell to the other at the cell body",
            "The propagation of signal through a neuron initiates at the dendrite, enters the cell body, and is transmitted from the axon",
            "Neurons contain multiple dendrites and cell bodies",
            "If an electrical disturbance is large enough in the cell body, the signal generates an action potential at the dendrite"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1147,
        "question": "Which of the following does not result from nondisjunction of homologous chromosomes during meiosis?",
        "answer": "All of the following are results of a nondisjunction event. Trisomy and monosomy disorders may occur as a result of improper separation in diploid human meiosis events.",
        "suggested_answer": "All of these result from nondisjunction",
        "options": [
            "Trisomy 21 (Down's Syndrome)",
            " Trisomy XXY (Klinefelter's)",
            "Monosomy X (Turner's Syndrome) ",
            "Trisomy 18 (Edward's Syndrome)",
            "All of these result from nondisjunction"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1148,
        "question": "In the food chain, which of the following is consumed by secondary consumers?",
        "answer": "Secondary consumers consume primary consumers in the food chain (i.e. a snake eating grasshoppers).",
        "suggested_answer": "Primary consumers",
        "options": [
            "Primary producers",
            "Autotrophs",
            "Tertiary consumers",
            "Primary consumers"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1149,
        "question": "Which of the following is an example of an somatic cell? ",
        "answer": "Somatic cells in humans have two copies of each chromosome (2n=46) as opposed to haploid cells which have only one set of chromosomes (n=23). Muscle cells are somatic cells, sperm and ova are haploid cells, since they are gametes. ",
        "suggested_answer": "Muscle cell",
        "options": [
            "Ovum only",
            "Sperm cell only",
            "Muscle cell",
            "Sperm cell and ovum"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1150,
        "question": "Which of the following is the key difference between the ascending limb of the loop of Henle from the descending limb?",
        "answer": "The loop of Henle serves the crucial function of creating an ion gradient in the renal medulla by fluctuating the reabsorption of water and ions. In the descending limb, water is removed from the filtrate and enters into the interstitium, resulting in a highly concentrated filtrate. This filtrate then enters the ascending limb. The ascending limb is not permeable to water, but is permeable to sodium ions. The result is a massive efflux of sodium ions, which exit the filtrate and enter the interstitium. Sodium pumps amplify this process by continuing to remove sodium from the filtrate. The filtrate becomes more dilute, but the interstitium in the renal medulla is highly concentrated. When the filtrate enters the collecting duct, this gradient helps pull water out of the filtrate, allowing it to reach a maximum concentration before being transported to the bladder.",
        "suggested_answer": "Permeability to water",
        "options": [
            "Sodium is concentrated in the filtrate of the ascending limb",
            "Water reabsorption in the ascending limb is under hormonal control",
            "Permeability to water",
            "Secretion of urea into the ascending limb"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1151,
        "question": "Life tables are created to show survival patterns in a population. They are created based on the observations of which of the following?",
        "answer": "Life tables are summaries of the survival patterns of a population. They are constructed using cohorts (groups of individuals of similar age). By following and observing these individuals from birth until death, researchers can make life tables and plot survivorship cures, showing the number of cohorts alive over their lifetimes.",
        "suggested_answer": "Cohorts",
        "options": [
            "Immigration ",
            "Cohorts",
            "Reproductive rates",
            "Dispersion ",
            "Emigration "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1152,
        "question": "Which of the following is an impact of snow precipitation?",
        "answer": "Snowmelt is a type of runoff that results from melting snow.",
        "suggested_answer": "Snowmelt",
        "options": [
            "Snowmelt",
            "Canopy interference",
            "Transpiration",
            "Advection"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1153,
        "question": "Which of the following is the best explanation for why we have a \"blind spot?\"",
        "answer": "In the blind spot, there is a lack of photoreceptors as the optic nerve exits the optic disk. We normally overcome this automatically when our brains use the information of the surrounding visual field and \"fills in\" the blind spot. ",
        "suggested_answer": "The optic nerve exits the optic disc through the blind spot",
        "options": [
            "There are only cones in the blind spot, but no rods",
            "There are both rods and cones present, but they do not contain opsins",
            "None of the choices are true",
            "The optic nerve exits the optic disc through the blind spot",
            "There are only rods in the blind spot, but no cones"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1154,
        "question": "Which of the following is NOT evidence for the Endosymbiotic Theory?",
        "answer": "The Endosymbiotic Theory states that the mitochondria and chloroplast in eukaryotic cells were once aerobic bacteria (prokaryote) that were ingested by a large anaerobic bacteria (prokaryote). This theory explains the origin of eukaryotic cells. Numerous lines of evidence exist, including that mitochondria and chloroplasts have their own circular DNA (prokaryotes also have circular DNA), mitochondria and chloroplasts have a double membrane (the inner membrane would have initially been the ingested prokaryote\u2019s single membrane, and the outer membrane initially would have come from the cell that engulfed it), mitochondria and chloroplasts have 70S ribosomes (prokaryotes 70S have ribosomes, whereas eukaryotes have 80S ribosomes).",
        "suggested_answer": "Mitochondria and chloroplasts have a single membrane",
        "options": [
            "Mitochondria and chloroplasts have 70S ribosomes",
            "Mitochondria and chloroplasts have circular DNA",
            "Mitochondria and chloroplasts have a single membrane",
            "Mitochondria and chloroplasts have a double membrane"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1155,
        "question": "Nitrogenous wastes are produced from the degradation of DNA and proteins, and released from the body via the excretory system. Which of the following is the primary nitrogenous waste produced by aquatic animals?",
        "answer": "Ammonia is highly water-soluble and can be toxic to cells at low concentrations due to presence of its ammonium ion, which can interfere with oxidative phosphorylation. Ammonia is small and can easily diffuse through cell membranes, making it easy to excrete. Essentially, there is a trade off of easy excretion and toxicity levels.",
        "suggested_answer": "Ammonia",
        "options": [
            "Ammonia",
            "Nitric acid",
            "Urea",
            "Uric acid"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1156,
        "question": "In reference to water, what to do cohesion, surface tension, and adhesion all have in common?",
        "answer": "These properties of water are all a result of hydrogen bonding. Hydrogen bonds result from the electrical attraction between partially positive hydrogen atoms and partially negative oxygen atoms of adjacent water molecules. The differences in electronegativity between hydrogen and oxygen give rise to the hydrogen bonding and associated properties.",
        "suggested_answer": "All are properties related to hydrogen bonding",
        "options": [
            "All are properties related to hydrogen bonding",
            "All are produced by covalent bonding",
            "All are produced by ionic bonding",
            "All are aspects of a crystalline structure",
            "All are results of the structure of hydrogen atoms"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1157,
        "question": "Which of the following organelles generates, stores, and releases energy for cell activities?",
        "answer": "The mitochondria are the powerhouses of the cell. They extract energy by breaking down nutrients and converting carbohydrates to ATP. The citric acid cycle and electron transport chain take place within the mitochondria.",
        "suggested_answer": "Mitochondria",
        "options": [
            "Endoplasmic reticulum",
            "Ribosomes",
            "Mitochondria",
            "Golgi apparatus",
            "Nucleus"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1158,
        "question": "Which of the following best describes the primary role of xylem in plants?",
        "answer": "Xylem is a type of tissue present in vascular plants that is responsible for water transport within the organism. Types of cells contained within xylem include tracheids, vessel elements, parenchyma cells, and fibers.",
        "suggested_answer": "Transport of water",
        "options": [
            "Structural support of plants",
            "Transport of nutrients",
            "Photosynthesis",
            "Transport of water"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1159,
        "question": "Which method of mutation has the greatest potential for evolution and variation?",
        "answer": "Duplication is a type of mutation in which a gene is duplicated within a genome. This creates the potential for one of the duplicates to evolve a new function, with the other copy retaining the original function. Deletions, inversions, and translocations are potentially harmful to the native function of a gene, whereas duplications of entire genes yields no potential for loss-of-function mutations.",
        "suggested_answer": "Duplication",
        "options": [
            "Deletion",
            "Translocation",
            "Inversion",
            "Duplication"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1160,
        "question": "Which of the following phases of the cell cycle make up what is known as interphase?",
        "answer": "Interphase in the cell cycle encompasses the G1, S, and G2 phases, as it shows the period of growth and DNA replication that a cell must go through to prepare for mitosis. Cell division, which occurs during the M phase, is the only portion of the cell cycle that is not included in interphase.",
        "suggested_answer": "G1, S, and G2",
        "options": [
            "G1, S, and G2",
            "G1, G2, and M",
            "G1, S, and M",
            "M, S, and G2"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1161,
        "question": "Which of these correctly matches the cellular junction with its function?",
        "answer": "Gap junctions allow the flow of water and ions. They are seen in the heart muscle cells, to allow quick electrical conduction from one cell to another, which is observed as a wavelike contraction of the heart. Tight junctions are networks of spot attachments that are impermeable to fluids. Desmosomes are random, spot attachments that are responsible for cell-cell adhesion.",
        "suggested_answer": "Gap junctions are connections that directly connect the cytoplasm of two cells",
        "options": [
            "Desmosomes are connections that directly connect the cytoplasm of two cells",
            "Gap junctions prevent the flow of ions and water from one part of the body to another",
            "Gap junctions are connections that directly connect the cytoplasm of two cells",
            "Tight junctions are random, spot attachments between cells that prevent tearing of tissue",
            "Desmosomes are branched network of proteins that are impermeable to fluids"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1162,
        "question": "Where do light independent reactions of photosynthesis occur?",
        "answer": "Light independent reactions occur in the stroma of the chloroplasts. Light dependent reactions occur in the thylakoid membrane.",
        "suggested_answer": "stroma of chloroplasts",
        "options": [
            "stroma of chloroplasts",
            "thylakoid of chloroplasts",
            "ribosome",
            "nucleus"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1163,
        "question": "Which of the following is not a method esed to test fertility?",
        "answer": "Common methods of fertility testing include: calendar tracking, ovulation predictor kits, and monitory physical symptoms. Calendar tracking determines fertility by cycle length. Ovulation predictor kits measure luteinizing hormone (LH) levels in an individual\u2019s urine. Monitoring physical symptoms such as cervical mucus and basal body temperature are also a good method of tracking fertility.",
        "suggested_answer": "Pituitary gland testing",
        "options": [
            "Calendar tracking",
            "Ovulation predictor kits",
            "Pituitary gland testing",
            "Monitoring the cervical mucus"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1164,
        "question": "A certain insect eats plants. If the plants are producers, what is the insect classified as?",
        "answer": "Organisms that eat producers are known as primary consumers. Organisms that eat primary consumers are secondary consumers, and organisms that eat secondary consumers are tertiary consumers and so on. Autotrophs are able to fix their own carbon from the air, converting it into food e.g., glucose, and need not eat other organisms. Predators are animals that eat prey animals, thus they are a type of secondary (or higher) consumer.",
        "suggested_answer": "Primary consumer",
        "options": [
            "Autotroph",
            "Predator",
            "Primary consumer",
            "Secondary consumer",
            "Tertiary consumer"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1165,
        "question": "What hormone causes ovulation to occur during the menstrual cycle?",
        "answer": "An increase in estrogen causes an increase in the luteinizing hormone. It is the luteinizing hormone that causes ovulation. Ovulation is the process of releasing an ovum. ",
        "suggested_answer": "Luteinizing hormone",
        "options": [
            "Luteinizing hormone",
            "Follicle-stimulating hormone",
            "Growth hormone",
            "Thyroid-stimulating hormone",
            "Prolactin"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1166,
        "question": "Which of the following is not involved in mitosis?",
        "answer": "Crossing over is the only answer choice that does not occur during mitosis. Crossing over occurs during prophase I of meiosis and involves swapping of genetic information between homologous chromosomes. This require the formation of tetrads, which does not occur during mitosis.",
        "suggested_answer": "Crossing over",
        "options": [
            "Nucleolus disappears",
            "Sister chromatids separate",
            "Nuclear membrane disintegrates",
            "Chromosome condensation",
            "Crossing over"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1167,
        "question": "All of the following bases are found in RNA except __________.",
        "answer": "Uracil is only found in RNA under normal conditions. It replaces thymine, which is only found in DNA. Therefore, thymine, adenine, cytosine, and guanine are the bases found in DNA, and uracil, adenine, cytosine, and guanine are the bases found in RNA.",
        "suggested_answer": "thymine",
        "options": [
            "uracil",
            "guanine",
            "adenine",
            "thymine",
            "cytosine"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1168,
        "question": "Which of the following is the term used to describe the rapid rise and fall in membrane potential required for signal transmission in neurons?",
        "answer": "In neurons and many other cells, electrical signal transmission requires action potential. Action potential can be defined as the rapid rise and fall of membrane potential that allows for signal propagation. In the case of neurons, reaching action potential allows the electrical signal to travel down to axon, which permits signal transduction.",
        "suggested_answer": "Action potential",
        "options": [
            "Threshold potential",
            "Action potential",
            "None of these",
            "Membrane potential"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1169,
        "question": "Which of the following is not true regarding tracheids?",
        "answer": "Tracheids are elongated cells that are contained within xylem tissue. Tracheids are responsible for water and mineral transport. The thick cell walls of tracheids allow them to hold water against gravitational pull due to the adhesion property of water.",
        "suggested_answer": "They are square-shaped",
        "options": [
            "They are a type of cell in xylem tissue",
            "They are square-shaped",
            "They hold water against gravitational force",
            "They have thick cell walls"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1170,
        "question": "Where does RNA polymerase bind during transcription?",
        "answer": "During transcription, RNA polymerase enzymes bind to DNA promoter sequences. This binding is facilitated by the pre-initiation complex (composed of transcription factors, the promoter sequence, activators, and repressors) as well as RNA polymerase. In eukaryotic cells, certain transcription factors must bind to the promoter sequence before RNA polymerase can. This is due to an inability of RNA polymerase to independently recognize the promoter sequence. Once RNA polymerase does bind, transcription can be initiated. ",
        "suggested_answer": "RNA polymerase binds to the promoter sequence within the DNA template.",
        "options": [
            "RNA polymerase binds to the activators.",
            "RNA polymerase binds to the promoter sequence within the DNA template.",
            "RNA polymerase binds to the sequence downstream of the promoter sequence.",
            "RNA polymerase binds to the transcription factors. "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1171,
        "question": "Which of the following physiological responses is not under hormonal control?",
        "answer": "In almost all animals, the endocrine and nervous systems are integrated to respond and control physiological responses. ",
        "suggested_answer": "Prostaglandin stimulation of uterine smooth muscle contraction",
        "options": [
            "Glucagon effect of increasing blood glucose levels",
            "Prostaglandin stimulation of uterine smooth muscle contraction",
            "An insect larva transitioning to the pupa stage",
            "Calcitonin lowering of blood calcium levels"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1172,
        "question": "What are the three major components that make up a nucleotide in DNA?",
        "answer": "The three major components of a nucleotide in DNA are phosphate, deoxyribose, and one of the four nitrogenous bases. The phosphate and deoxyribose alternate along the backbone, and the nitrogenous base codes for the type of protein made in transcription and translation.",
        "suggested_answer": "Phosphate, deoxyribose, and nitrogenous base",
        "options": [
            "Phosphate, deoxyribose, and nitrogenous base",
            "Protein unit, deoxyribose, and nitrogenous base",
            "Phosphate, ribose, and nitrogenous base",
            "Glucose, ribose, and nitrogenous base"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1173,
        "question": "Which of the following is true regarding the number of follicles in aging women?",
        "answer": "Follicles are packs of somatic cells that surround developing oocytes in the ovaries. Follicles develop through a process called folliculogenesis that occurs in tandem with oogenesis. At birth, women have all of their follicles; however, this number decreases as women age due to double stranded breaks in the DNA of primary oocytes contained within primordial follicles.",
        "suggested_answer": "Decreases substantially",
        "options": [
            "Decreases substantially",
            "Increases",
            "Remains the same",
            "Decreases slightly "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1174,
        "question": "Which type of transport is correctly paired with the energy requirement?",
        "answer": "Active transport is the movement of molecules against their concentration gradient (from an area of low concentration to an area of high concentration, requiring energy, usually in the form of ATP. Passive transport is the movement of molecules with their concentration gradient (from an area of high concentration to an area of low concentration), and does not require energy input. Facilitate diffusion is the movement of molecules with their concentration gradient across the cell membrane using transmembrane proteins (carrier proteins or channels), and does not require energy. Osmosis is the movement of a solvent (usually water), from an area with a lower concentration of solute to an area of higher concentration of solute; this process does not require energy. ",
        "suggested_answer": "Osmosis; no ATP required",
        "options": [
            "Passive transport; ATP required",
            "Osmosis; no ATP required",
            "Facilitated diffusion; ATP required",
            "Active transport; no ATP required"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1175,
        "question": "Which of the following does not describe a human activity that impacts the water cycle?",
        "answer": "Human activity has a large impact on eco-chemical cycles, including the water cycle. Human activities that impact the cycle include agriculture, industry, and deforestation.",
        "suggested_answer": "Cultural mixing",
        "options": [
            "Cultural mixing",
            "Agriculture",
            "Deforestation",
            "Industry"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1176,
        "question": "Consider the reaction:",
        "answer": "Enzymes are catalysts that help a reaction proceed faster. Increasing the concentration of carbonic anhydrase will not cause the reaction to go slower. Recall that catalysts (in this case carbonic anhydrase) do not alter the equilibrium of a reaction. They simply speed up the process so that equilibrium can be achieved more quickly. Increasing or decreasing the equilibrium constant means that there is a change in the equilibrium state of the reaction.",
        "suggested_answer": "It will have no effect on the equilibrium constant",
        "options": [
            "It will have no effect on the equilibrium constant",
            "It will increase the equilibrium constant",
            "It will decrease the equilibrium constant",
            "It will cause reaction to go slower"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1177,
        "question": "Which of the following male reproductive structures does not contribute to the composition of semen?",
        "answer": "Semen is a fluid that contains sperm cells, proteolytic enzymes, lipids, and fructose. The prostate gland, bulbourethral gland, and seminal vesicles all contribute to the makeup of semen. Semen protects and transports sperm cells inside the female reproductive tract as they seek out the egg cell.",
        "suggested_answer": "Glans penis",
        "options": [
            "Prostate gland ",
            "Glans penis",
            "Seminal vesicles ",
            "Bulbourethral gland "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1178,
        "question": "What is the purpose of the nucleolus in eukaryotic cells?",
        "answer": "The nucleolus is a structure found inside the nucleus. It is responsible for the production of rRNA, and also constructs the subunits of ribosomes.",
        "suggested_answer": "Create rRNA and the subunits of ribosomes",
        "options": [
            "Create rRNA and the subunits of ribosomes",
            "Provide ATP for the nucleus",
            "Receive substances entering the nucleus, and direct them to their appropriate locations",
            "Create mRNA and send it to the cytoplasm",
            "Modify pre-mRNA and send it to the cytoplasm"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1179,
        "question": "The polymer chitin is made up of what carbohydrate momoner?",
        "answer": "Chitin is a polymer that makes up the cell wall of fungal cells as well as the exoskeleton of arthropods. It itself is made up of N-Acetylglucosamine",
        "suggested_answer": "N-Acetylglucosamine",
        "options": [
            "Fructose",
            "N-Acetylglucosamine",
            "Glucose",
            "Glucopyranose",
            "N-Acetylneuraminic acid"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1180,
        "question": "Oxygen-poor blood returns to the heart via which structure?",
        "answer": "The venae cavae are the largest veins in the body. They return deoxygenated blood to the heart. The pulmonary veins bring oxygenated blood from the lungs to the left atrium. The pulmonary arteries bring deoxygenated blood from the right ventricle to the lungs to become oxygenated. The left ventricle hold oxygen-rich blood, and pumps it to the rest of the body. The left atrium is where freshly oxygenated blood is received via the pulmonary veins.",
        "suggested_answer": "Vena cava",
        "options": [
            "Pulmonary vein",
            "Vena cava",
            "Pulmonary artery",
            "Left ventricle",
            "Left atrium"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1181,
        "question": "In the connective tissue, what is the function of reticular fibers?",
        "answer": "Reticular fibers are composed of collagen and form thin mesh networks that supports cells, tissues, and organs. The reticular meshwork also acts as a scaffold for other structures to anchor into.",
        "suggested_answer": "Provide a scaffolding for cells",
        "options": [
            "Provide a scaffolding for cells",
            "Contract to create movement",
            "To bind bones together",
            "Allow for the transmission of chemical signals"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1182,
        "question": "Which of the following processes does not take place during glycolysis?",
        "answer": "Glycolysis, which occurs in the cytoplasm, is the first step of cellular respiration. Though it does not produce a large amount of ATP by itself, it incorporates several important steps that must take place to yield a much more significant amount of ATP later. Notable events that occur during this multi-step process include the breakdown of each glucose molecule into two 3-carbon pyruvate molecules, the production of two molecules of , and the net production of two ATP molecules.",
        "suggested_answer": "Via a complex chain of steps, and transfer electrons to the process's final electron acceptor, ",
        "options": [
            "An end result is the production of 3-carbon molecules that are later fed into the citric acid cycle",
            "Two molecules are produced",
            "Via a complex chain of steps, and transfer electrons to the process's final electron acceptor, ",
            "One molecule of glucose eventually yields two molecules of pyruvate",
            "Four ATP molecules are produced for every molecule of glucose, but the net production is only two molecules of ATP"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1183,
        "question": "In this metabolic pathway, Substrate C is acting as a(n) __________.",
        "answer": "Negative feedback interrupts a metabolic pathways by producing a substrate that inhibits enzymes in the beginning steps of the metabolic cycle. If a chemical is \"mimicking\" substrate C or causing Substrate C to be produced before other steps in a cycle, the enzyme is inhibited by the excess of substrate C thus the pathway can not continue. Most such molecules are proteins that interact with enzymes. ",
        "suggested_answer": "negative feedback inhibitor",
        "options": [
            "transcription factor",
            "competitive inhibitor",
            "nucleic acid",
            "noncompetitive inhibitor",
            "negative feedback inhibitor"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1184,
        "question": "How is the zymogen protease activated in the stomach?",
        "answer": "To become active, hydrochloric acid in the stomach reacts with pepsinogen to make pepsin.",
        "suggested_answer": "It reacts with the hydrochloric acid to become pepsin",
        "options": [
            "It gets cleaved by trypsin to become catalytically active",
            "It binds to parietal cells to become pepsin",
            "It binds to chief cells to become pepsin",
            "It is active upon secretion",
            "It reacts with the hydrochloric acid to become pepsin"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1185,
        "question": "Which of the following is true regarding postpartum depression?",
        "answer": "Postpartum depression is a condition that may occur in women that have recently given birth. It is a type of clinical depression that is recognized by the medical community and is found in mothers all over the world. Symptoms of postpartum depression include low energy, anxiety, and mood changes. Postpartum depression may interfere with mother-infant bonding. Methods to prevent postpartum depression include peer support and antidepressants.",
        "suggested_answer": "It can interfere with mother-infant bonding",
        "options": [
            "It can interfere with mother-infant bonding",
            "It only occurs during pregnancy",
            "None of these",
            "No clinical evidence of postpartum depression has been found",
            "It is only recognized by western civilizations"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1186,
        "question": "Humans regulate their internal body temperature within a very narrow range. This is an example of __________.",
        "answer": "Homeostasis is the tendency of the body to maintain stable, constant states. Homeostasis is often mediated by negative feedback systems, which prevent the measure from getting too high or too low. Regulation of body temperature within a narrow range would be an example of homeostatic regulation. Other examples include blood glucose concentration and blood calcium concentration.",
        "suggested_answer": "homeostasis",
        "options": [
            "homeostasis",
            "constancy",
            "evolution",
            "metabolism  "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1187,
        "question": "When a neuron reaches threshold and fires an action potential, __________ pass through channels into the axon of the cell, causing a __________ in the charge of the cell.",
        "answer": "Sodium ions are positively charged (Na+) and enter the neuron starting at the axon hillock, which is where the axon and cell body come together. An influx of positively charged ions will cause the charge of the cell to increase.",
        "suggested_answer": "sodium ions . . . increase",
        "options": [
            "potassium ions . . . decrease",
            "sodium ions . . . increase",
            "sodium ions . . . decrease",
            "potassium ions . . . increase"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1188,
        "question": "Which of the following structures of the vertebrate eye controls the amount of light entering the pupil?",
        "answer": "Light enters the eye through the pupil and is focused at the back of the eye to form an image on the retina. The retina contains rods and cones that can convert the image to nerual signals for the brain to interpret.",
        "suggested_answer": "Iris",
        "options": [
            "Ciliary muscles",
            "Ciliary body",
            "Lens",
            "Iris"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1189,
        "question": "Which of these statements best explains the major process that occurs in mitochondria.",
        "answer": "The major processes that occur in mitochondria are the citric acid cycle and the electron transport chain. The citric acid cycle forms electron carries that are used in the electron transport chain to reduce oxygen to water and produce ATP.",
        "suggested_answer": "Energy from the bonds of glucose molecules is transferred to the phosphate bonds in ATP and water is produced.",
        "options": [
            "Energy from sunlight is used to convert carbon dioxide into six-carbon sugars.",
            "Energy from oxygen molecules are released during combustion.",
            "Energy from the bonds of glucose molecules is transferred to the phosphate bonds in ATP and water is produced.",
            "Energy from sunlight is used to from DNA from nucleic acid molecules.",
            "Energy from the bonds of glucose molecules is transferred to the phosphate bonds in ATP and lactic acid is produced."
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1190,
        "question": "Which of the following is true regarding balancing selection?",
        "answer": "Balancing selection is a type of natural selection that maintains genetic variation in a population, making it the opposite of purifying selection. Examples of balancing selection include heterozygote selection, in which there is adaptive value for heterozygotes of an allele.",
        "suggested_answer": "All of these",
        "options": [
            "An example is heterozygote advantage",
            "It maintains variation in a population",
            "All of these",
            "It is the opposite of purifying selection"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1191,
        "question": "The accumulation of cyclin protein results in which of the following?",
        "answer": "Cyclin proteins bind to cyclin-dependent kinases and drive the cell division cycle. One mechanism to control this process is through the amount of cyclin protein present and available to bind and activate cyclin-dependent kinases (CDKs). The cyclin-CDK complexes provide signals for the cell to progress through cell cycle checkpoints. After completion of a stage of the cell cycle, the cyclin protein is rapidly degraded to prevent inappropriate signaling. Overproduction or accumulation of cyclin protein can cause inappropriate continuation through the cell cycle and unregulated cell division.",
        "suggested_answer": "Progression through cell cycle checkpoints",
        "options": [
            "Degradation of cyclin-dependent kinases",
            "Completion of DNA synthesis",
            "Growth factor synthesis",
            "Progression through cell cycle checkpoints"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1192,
        "question": "Which of the following describes polymerase chain reaction (PCR)? ",
        "answer": "Polymerase chain reaction (PCR) is a technique used to generate thousands to millions of copies of a specific segment of DNA. There are three major steps to PCR. Denaturation occurs when heat separates the original DNA strand. Annealing follows, in which the DNA is cooled and primers bind to each separated strand. The final step is extension, in which DNA polymerase adds nucleotides to the 3' end of each primer. These steps are repeated for each PCR cycle.",
        "suggested_answer": "A technique that quickly amplifies a segment of DNA, resulting in a large number of copies of that segment",
        "options": [
            "A technique that quickly amplifies a segment of DNA, resulting in a large number of copies of that segment",
            "A technique that detects proteins in the cells of tissue",
            "A technique that detects specific DNA sequences",
            "A technique that detects RNA in a sample",
            "A technique that separates DNA based on charge and size"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1193,
        "question": "Which of the following statements is false concerning the liver?",
        "answer": "The liver is found on the right side of the body in the abdomen. Remember the liver is on the right side of the abdominal cavity. The liver cells (hepatocytes) function to detoxify products such as alcohols and other drugs.",
        "suggested_answer": "The liver is found on the left side of the abdomen",
        "options": [
            "The gallbladder is found underneath the liver",
            "The liver is found on the left side of the abdomen",
            "Liver cells are called hepatocytes",
            "The liver plays an important role in the breakdown of alcohol and other toxic metabolites"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1194,
        "question": "Which of the following describe how a cell protects proteins from denaturation?",
        "answer": "Cells have certain mechanisms to protect proteins from denaturation and ensure proper folding. The cell uses two mechanisms to protect proteins: chaperones and heat shock proteins. Chaperones are a large class of proteins that aid with protein folding and prevent folding defects under normal and stressed conditions, during which chaperone expression is up regulated. Chaperones use ATP to induce a conformational change to provide an isolated environment for the protein to fold and prevent protein aggregation. Heat shock proteins are only produced under stress conditions. Heat shock proteins have a variety of functions including functioning as a chaperone, aiding in the binding of immune antigens, and preventing platelet aggregation in the cardiovascular tract. ",
        "suggested_answer": "Both heat shock proteins and chaperones",
        "options": [
            "Neither heat shock proteins nor chaperones",
            "Chaperones",
            "Heat shock proteins",
            "Both heat shock proteins and chaperones"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1195,
        "question": "Which of the muscle types is controlled by voluntary motion?",
        "answer": "Skeletal muscle is attached to bones by tendons, allowing you to move around. These are the muscles that you can consciously control.",
        "suggested_answer": "Skeletal muscle",
        "options": [
            "Skeletal muscle",
            "Connective muscle",
            "Smooth muscle",
            "Cardiac muscle"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1196,
        "question": "You are trying to perform in vitro DNA replication on a small circular piece of DNA. You have DNA polymerase, Primase, Helicase, DNA ligase and all of their accessory proteins. You can get DNA replication to initiate but it never goes for very long without stopping. You visualize your small piece of DNA under an electron microscope and notice that after the initiation of replication, it looks all knotted up. What enzyme can you add to remedy this problem?",
        "answer": "As the replication fork of DNA proceeds and continues to unwind the double helix, the DNA upstream of the fork gets over wound and knotted up which will eventually arrest replication as the fork will not be able to proceed any further. The enzyme topoisomerase corrects for this overwinding ahead of replication forks by swiveling and rejoining DNA strands",
        "suggested_answer": "Topoisomerase",
        "options": [
            "RNA polymerase",
            "Reverse Transcipase",
            "Knottase",
            "Telomerase",
            "Topoisomerase"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1197,
        "question": "A group of interdependent organisms living in close proximity is called a",
        "answer": "An ecosystem consists of communities of organisms interacting with their environment. A community consists of populations of various species living in close proximity and interacting. A population consists of organisms of the same species living in close proximity and interacting.",
        "suggested_answer": "community",
        "options": [
            "population",
            "ecosystem",
            "community",
            "family"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1198,
        "question": "Which of the following most accurately describes how a local cell signal is transmitted among nearby eukaryotic cells in animals?",
        "answer": "Local cell signaling in eukaryotic cells refers to the communication between nearby cells. This is done through direct contact between cells, namely via cell junctions and cell-cell recognition. Gap junctions are intercellular connections that allow cytoplasmic transfer in animal cells. The counterpart in plant cells is the plasmodesmata, which are channels penetrating the cell walls of cells, allowing communication. Cell-cell recognition is the ligand-receptor binding between two cells that elicits receptor cell response. Methods of local cell signaling allow nearby cells to communicate with each other and coordinate cellular responses and activities.",
        "suggested_answer": "Direct contact",
        "options": [
            "Unspecified diffusion of signal proteins",
            "Secretion of signaling molecules into the bloodstream",
            "Direct contact",
            "A pilus"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1199,
        "question": "Which of the following are properties of RNA?",
        "answer": "Remember that the sugar for DNA is deoxyribose, and that for RNA is ribose. The nitrogenous bases adenine, guanine, and cytosine in DNA and RNA are the same. However, DNA which contains thymine and RNA that contains uracil. The structure of DNA is a double helix. There are three different structures of RNA: linear, clover-leaf, and globular. Also note that RNA is single stranded and DNA is double stranded",
        "suggested_answer": "Ribose, uracil, and a linear structure",
        "options": [
            "Ribose, uracil, and a linear structure",
            "Deoxyribose, uracil, and a globular structure",
            "Deoxyribose, ribose, and uracil",
            "Ribose, thymine, and a linear structure",
            "Deoxyribose, thymine, and a linear structure"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1200,
        "question": "Which of the following best describes fungi?",
        "answer": "Like animals, fungi are chemoheterotrophs. They must get both their energy and carbon skeletons by absorbing pre-digested nutrients from the environment.",
        "suggested_answer": "Chemoheterotrophic",
        "options": [
            "Photoautotrophic",
            "Chemoheterotrophic",
            "Lithoautotrophic",
            "Chemoautotrophic",
            "Mixotrophic"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1201,
        "question": "Which of the following cells will undergo meiosis? ",
        "answer": "Germ cells give rise to gametes through meiosis. All of the other organisms listed are asexually reproducing organisms and therefore only undergo mitosis. ",
        "suggested_answer": "Germ cells",
        "options": [
            "Heart cells",
            "Germ cells",
            "Unicellular organisms",
            "Amoebae",
            "All of these"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1202,
        "question": "What does it mean to say that a neural cell is bipolar?",
        "answer": "Neurons can be unipolar, bipolar, or multipolar, depending on how many projections are coming off of the cell body. Bipolar neurons are found in the retina and inner ear, and have a single dendritic extension as well as a single axonal extension. Most neurons are multipolar; they have numerous dendrites and a single axon.",
        "suggested_answer": "It has two projections coming off of the cell body",
        "options": [
            "It sends signals to two other neurons",
            "It has two projections coming off of the cell body",
            "It can send signals in two opposing directions",
            "It has two cell bodies, but only one with a nucleus"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1203,
        "question": "What types of cells are a part of connective tissue?",
        "answer": "Cells are also a critical component of connective tissue and are typically suspended in the ground substance. Cells that are a part of connective tissue include fibroblasts (cells that produce fibers), mast cells (involved in histamine-mediated inflammatory response), and adipocytes (fat cells). Staining methods can show these cell types within the connective tissue. Other connective tissue cells include osteocytes (bone cells), and chondrocytes (cartilage cells).",
        "suggested_answer": "All of these",
        "options": [
            "Fibroblasts",
            "All of these",
            "Adipocytes",
            "Mast cells"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1204,
        "question": "In the evolutionary sense, which organism has the highest fitness?",
        "answer": "With regard to evolution and natural selection, fitness refers only to the ability of an organism to contribute to the next generation of its species. In other words, if an organism has a large number of viable offspring, its fitness is high, regardless of other factors like strength, size, and longevity.",
        "suggested_answer": "A prairie dog that, though smaller than the average member of her species, has twice as many healthy young in each litter",
        "options": [
            "A prairie dog that, though smaller than the average member of her species, has twice as many healthy young in each litter",
            "A turtle that lays hundreds of eggs each nesting season, although an unusually small number of these eggs hatch successfully",
            "A sterile mule that can pull over 800 pounds",
            "A childless human male who lives to be over one hundred years old",
            "A dog who cannot give birth due to a hip abnormality, but is healthy in all other respects"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1205,
        "question": "Which of the following is not an example of a eukaryotic cell?",
        "answer": "Examples of eukaryotic cells include plant, fungal, protist and animal cells. Myocytes (muscle cells) and human epithelial (skin) cells are both animal cells and are therefore eukaryotic. Parenchyma cells are plant cells that synthesize organic products, and are therefore eukaryotic. Examples of prokaryotic cells include bacteria. E. coli is a species of bacteria often found in the lower intestines of warm-blooded animals. All bacterial cells are prokaryotic, making an E. coli cell an example of a prokaryotic cell. ",
        "suggested_answer": "E. coli cell",
        "options": [
            "Myocyte",
            "Human epithelial cell",
            "E. coli cell",
            "Parenchyma cell"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1206,
        "question": "Which cell is responsible for stimulating differentiation of B-lymphocytes into specialized plasma cells?",
        "answer": "The differentiation of many acquired immune cells is largely dependent on helper T-cells. In the presence of a matching antigen, B-lymphocytes can be differentiated into plasma cells and memory B-cells with the assistance of helper T-cells. Memory B-cells are easily triggered if an antigen is presented during a second infection, while plasma cells are the final differentiated form of a B-cell responsible for mass-producing antibodies. Helper T-cells are also important in the activation of cytotoxic T-cells, which detect antibody-antigen complexes on cell membranes and help to destroy these tagged cells.",
        "suggested_answer": "Helper T-cells",
        "options": [
            "Antibodies",
            "Memory B-cells",
            "Helper T-cells",
            "Cytotoxic T-cells"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1207,
        "question": "Biological evolution can be defined as __________.",
        "answer": "Evolution occurs slowly over time as small changes accumulate in the DNA of a given population. Evolution is not a blueprint for perfection and does not create a hierarchy of developed and non-developed species.",
        "suggested_answer": "changes in the gene frequency of a population over time",
        "options": [
            "changes in the gene frequency of a population over time",
            "None of these definitions are correct",
            "a linear route from simple to complex",
            "a triangle with less developed organisms on the bottom"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1208,
        "question": "Starch belongs to which class of macromolecules?",
        "answer": "Starch is a carbohydrate. Specifically, it is a polysaccharide carbohydrate used in plants. The enzyme amylase breaks down starch in the mouth and small intestine, where it can is absorbed as monosaccharides and disaccharides.",
        "suggested_answer": "Carbohydrates",
        "options": [
            "Nucleic acids",
            "Polypeptides",
            "Lipids",
            "Carbohydrates"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1209,
        "question": "Which of the following elements does not exist as a diatomic molecule?",
        "answer": "A diatomic element is an element that will spontaneously bond with itself if it isn't already part of a compound. This is done to increase stability. The seven diatomic elements are hydrogen, oxygen, nitrogen, fluorine, chlorine, bromine, and iodine. Note that all of these except iodine are gasses at room temperature. Helium is a noble gas, which means it has a full valence shell. Helium does not need to bind to anything to increase its stability.",
        "suggested_answer": "Helium",
        "options": [
            "Oxygen",
            "Iodine",
            "Hydrogen",
            "Helium",
            "Chlorine"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1210,
        "question": "Which of the following statements about quaternary protein structure is true?",
        "answer": "Quaternary protein structure is distinguished by the fact that several polypeptide chains come together to make a functional protein. This is different than the first three levels of protein structure, which only involve one polypeptide chain. Quaternary structure is held together primarily by hydrophobic interactions between the polypeptide chains (ionic and/or hydrogen bonding is often seen as well). Each polypeptide chain forms a subunit of the protein.",
        "suggested_answer": "Proteins displaying quaternary structure are made up of two or more polypeptide chains",
        "options": [
            "Quaternary structure is primarily held together by disulfide bonds",
            "Proteins displaying quaternary structure are made up of two or more polypeptide chains",
            "All proteins display quaternary structure",
            "Quaternary structure is primarily held together by peptide bonds"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1211,
        "question": "Which enzyme is important in photosynthesis, and what is its function?",
        "answer": "During the first step of the light independent reaction, or Calvin Cycle, CO2 produced from the light dependent reaction reacts with RuBP. This reaction is catalyzed by Rubisco, or ribulose bisphosphate carboxylase.",
        "suggested_answer": "ribulose bisphosphate carboxylase; carbon fixation during the first step of light independent reaction",
        "options": [
            "oxidase; captures energy from the sun during light dependent reaction",
            "ribulose bisphosphate carboxylase; carbon fixation during the first step of light independent reaction",
            "amylase; carbon fixation during the first step of light independent reaction",
            "ribulose bisphosphate carboxylase; captures energy from the sun during light dependent reaction"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1212,
        "question": " According to Mendel\u2019s Law of Dominance, the phenotype of a heterozygote will be ______________________ . ",
        "answer": "According to the Law of Dominance, each individual has two alleles for each trait and only the dominant allele contributes to the phenotype.",
        "suggested_answer": "determined by the dominant allele",
        "options": [
            "a mixture of the phenotypes of both parents",
            "determined by the dominant allele",
            "determined by the recessive allele",
            "determined by the mother\u2019s genotype"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1213,
        "question": "The primary function of xylem is __________.",
        "answer": "Xylem transports water from the roots of a plant to the leaves. It does so through a passive process, negative water pressure created by transpiration in the leaves pulls water up from the roots through the xylem, similar to the action water moving up a paper towel when one corner of a paper towel is placed in a pool of water. Xylem cells are dead at maturity so they cannot perform photosynthesis. Sugars are transported by phloem (from the leaves to other areas of the plant). Stomata are structures that facilitate gas exchange.",
        "suggested_answer": "To transport water from the roots to the leaves",
        "options": [
            "To transport water from the roots to the leaves",
            "To perform photosynthesis",
            "To allow exchange of gasses such as carbon dioxide",
            "To attract pollinators",
            "To transport sugar throughout the plant"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1214,
        "question": "Which of the following best describes what the corpus luteum develops from in female humans?",
        "answer": "The corpus luteum is a structure in female humans that develops from the dominant follicle after ovulation. The corpus luteum is involved in the endocrine system and it secretes progesterone to maintain the endometrial lining if the released oocyte is fertilized. If the oocyte is not fertilized, it stops secreting progesterone, causing the shedding of the endometrial lining and degeneration of the corpus luteum.",
        "suggested_answer": "The dominant follicle ",
        "options": [
            "An unfertilized oocyte",
            "The fimbria of the fallopian tube",
            "The dominant follicle ",
            "The non-dominant follicles "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1215,
        "question": "Recycling is an important component of a functioning ecosystem. Which of the following is an example of nutrient recycling?",
        "answer": "Nutrient recycling specifically refers to the movement of materials within food webs and ecosystems that recycle them back into production. Nutrient recycling can be distinguished from biogeochemical cycles, like the water, nitrogen, and carbon cycles, which describe the exchange of matter through living and non-living components of the Earth.",
        "suggested_answer": "Decomposition",
        "options": [
            "Nitrogen cycle",
            "Decomposition",
            "Carbon cycle",
            "Water cycle"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1216,
        "question": "Which of the following are fibers found in the connective tissue?",
        "answer": "The connective tissue includes fibers that act as a matrix and support structure for cells suspended in ground substance. These fibers are categorized as collagenous fibers, elastic fibers, and reticular fibers. Collagenous fibers are the most abundant in the extracellular matrix, comprise tendons and ligaments, where play structural roles. Elastic fibers are present in areas of the body that undergo cycles of stretching and recoiling, such as arteries. Reticular fibers form meshlike networks that provide the base for other support structures. ",
        "suggested_answer": "All of these",
        "options": [
            "Reticular fibers",
            "All of these",
            "Collagenous fibers",
            "Elastic fibers"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1217,
        "question": "Which of the following cell layers both ingressions and invaginates during gastrulation?",
        "answer": "The epiblast is an inner cell layer in the blastocoel that sits above the hypoblast. The epiblast ingresses to form the mesoderm and invaginates to form the endoderm.",
        "suggested_answer": "Epiblast",
        "options": [
            "Blastocoel",
            "Trophoblast",
            "Hypoblast",
            "Epiblast"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1218,
        "question": "What amino acid is always the first one to be added during translation?",
        "answer": "There is only one codon that signals the start of translation: AUG. This codon codes for the amino acid methionine so this amino acid will also be at the N-terminus of all proteins, however it may be removed and/or modified later.",
        "suggested_answer": "Methionine",
        "options": [
            "Glycine",
            "Cysteine",
            "Tryptophan",
            "Alanine",
            "Methionine"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1219,
        "question": "A human's hand and a cat's paw are considered to be __________ to one another. ",
        "answer": "Homologous structures show the existence of a shared ancestry through the observation of structural similarities between different species; however, while a human's hand and a cat's paw are structurally similar, they have very different functions.",
        "suggested_answer": "homologous",
        "options": [
            "Allof these",
            "homologous",
            "analogous",
            "None of these",
            "vestigial"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1220,
        "question": "Which of the following best describes where epithelial columnar cells are not found in the human body?",
        "answer": "Epithelial columnar cells secrete digestive enzymes and absorb food in the digestive system and they also play a role in sensory reception. Epithelial columnar cells can be found in the tongue, ear, and the lining of the intestine.",
        "suggested_answer": "Lining of blood vessels",
        "options": [
            "Lining of blood vessels",
            "Lining of the intestine",
            "Ear",
            "Tongue"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1221,
        "question": "Why are gap junctions crucial to the anatomy and physiology of the heart?",
        "answer": "Gap junctions are formed by connexons rings of proteins that spans the membranes of two adjacent cells. The ring surrounds a pore that allows ions, among other substances, to pass between the cells uninterrupted. The result is a quick transmission of the electrochemical signal for cardiac muscle cell contraction, so that the cells contract essentially together. Gap junctions can also be found in numerous other tissue types, but not in skeletal muscle.",
        "suggested_answer": "They allow fast passage of ions from cell to cell, enabling the cells of the heart chambers to contract almost simultaneously",
        "options": [
            "They maintain a gap between the cardiac muscle cells, preventing crush injuries to the coronary arteries during regular contraction and relaxation",
            "They maintain a gap between cardiac muscle cells, preventing friction as the cells contract and relax",
            "They allow passage of glucose and oxygen into the cardiac cells, providing energy sources to cells that are not well perfused by coronary arteries",
            "They allow fast passage of ions from cell to cell, enabling the cells of the heart chambers to contract almost simultaneously"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1222,
        "question": "The chemical properties of which of the following elements are most similar to carbon?",
        "answer": "The chemical properties of an element are, in a large part, determined by the number of bonds that element can form with other elements. Silicon, like carbon, can form four bonds with other elements, and thus is the most similar. This can easily be seen on a periodic table as elements with similar properties are grouped together in the same column. Note that these similarities arise from having the same number of valence electrons. ",
        "suggested_answer": "Silicon",
        "options": [
            "Oxygen",
            "Iron",
            "Silicon",
            "Zinc",
            "Nitrogen"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1223,
        "question": "Which of the following is not a theory for the origins of life on Earth?",
        "answer": "There are many theories regarding the origins of life on Earth, many of which are scientifically supported and it is believed that several may have occurred in tandem. The most notable of these theories is the Big Bang theory others include: the arrival of organic compounds from meteoroids, the synthesis of compounds from Earth\u2019s atmosphere, and the RNA world hypothesis.",
        "suggested_answer": "All of these",
        "options": [
            "All of these",
            "RNA as the precursor for all life on Earth ",
            "Synthesis of organic compounds from Earth\u2019s early atmosphere",
            "Transport of microorganisms and organic compounds from meteoroids and asteroids"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1224,
        "question": "Which of the following terms can be described as the green pigment located within chloroplasts?",
        "answer": "Chlorophyll is what gives plants their green color. The chlorophyll located in the chloroplasts captures the light energy that drives the synthesis of food molecules in the chloroplasts photosynthesis.",
        "suggested_answer": "Chlorophyll",
        "options": [
            "Mesophyll",
            "Stomata",
            "Photoreceptors",
            "Chlorophyll"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1225,
        "question": "In the absence of oxygen, another process may be used after glycolysis in humans to small amounts of ATP. What is this process called?",
        "answer": "Without oxygen the final two steps of cellular respiration (Citric acid cycle and oxidative phosphorylation) can not be performed because it is used as the final electron acceptor. However, the first step, glycolysis produces a small amount of energy in the form of ATP as well as pyruvate and NADH. NADH can be recycle (to be used again in another round of glycolysis) by converting pyruvate to lactic acid. This process is known as lactic acid fermentation. ",
        "suggested_answer": "Lactic acid fermentation",
        "options": [
            "Citric acid cycle",
            "Cellular respiration",
            "Oxidative phosphorylation",
            "Photosynthesis",
            "Lactic acid fermentation"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1226,
        "question": "Which of the following is a haploid cell?",
        "answer": "In biology, the term ploidy refers to the number of chromosome sets per cell. Haploid cells have half of the number of chromosomes as parent cells, meaning that they only carry a single copy of each gene. Haploid cells are formed during meiosis and, in humans, produce gametes, which mature into sperm and egg cells.",
        "suggested_answer": "Sperm cell",
        "options": [
            "Epithelial cell",
            "Neuron",
            "Red blood cell",
            "Sperm cell"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1227,
        "question": "Influx of (sodium) ions into the neuron will cause which of the following?",
        "answer": "When sodium ions enter the neuron, the membrane begins to lose its negative charge and therefore become depolarized. Hyperpolarization, repolarization, and polarization all occur with the efflux of potassium ions out of the neuron. Note that an action potential stimulates the influx of sodium ions. The sodium potassium pump uses ATP to drive the establishment of the resting membrane potential by pumping three sodium ions out of the cell in exchange for two potassium ions into the cell. Both of these ions are being pumped against their concentration gradients. Neurotransmitter release is stimulated by the influx of calcium ions.",
        "suggested_answer": "Depolarization",
        "options": [
            "Hyperpolarization",
            "Polarization",
            "Repolarization",
            "Neurotransmitter release",
            "Depolarization"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1228,
        "question": "Fat is composed of which of the following smaller molecules?",
        "answer": "Fat is made up of glycerol and fatty acids. Specifically, a fat is composed of three fatty acids chains composed of 16 or 18 carbon atoms in length connected to a glycerol by way of an ester linkage.",
        "suggested_answer": "Glycerol and fatty acids",
        "options": [
            "Glycerol and fatty acids",
            "Glycerol",
            "Fatty acids",
            "None of these"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1229,
        "question": "When a cell's DNA has become damaged beyond repair, the cell undergoes which of the following processes?",
        "answer": "Apoptosis is programmed cell death, and it usually occurs when the DNA of the cell is damaged beyond repair.",
        "suggested_answer": "Apoptosis",
        "options": [
            "Glycolysis",
            "Photosynthesis",
            "Endocytosis",
            "Exocytosis",
            "Apoptosis"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1230,
        "question": "Which of the following is an example of exploitative competition?",
        "answer": "Exploitative competition is an indirect type of competition through a limiting resource. There was only one scenario in the listed options that illustrated an example of exploitive competition: the indirect competition that takes place in response to a limiting resource as seen in grass species competing for sunlight.",
        "suggested_answer": "Two species of grass competing for sunlight",
        "options": [
            "Flour beetles eating the eggs of a competitor species of flour beetle",
            "Caterpillars physically intimidating other caterpillars of the same species to guard leaf surface area",
            "Two species of grass competing for sunlight",
            "Lions chasing hyenas away from their prey"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1231,
        "question": "What structure is responsible for secreting progesterone following ovulation?",
        "answer": "Following ovulation, the remaining follicle previously containing the egg is called the corpus luteum. This structure will release progesterone, and continue to do so if the egg is fertilized and a zygote is formed. If no pregnancy occurs, the corpus luteum will degrade into the corpus albicans.",
        "suggested_answer": "Corpus luteum",
        "options": [
            "Zona pellucida",
            "Corpus albicans",
            "Secondary follicle",
            "Corpus luteum"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1232,
        "question": "What conclusion did Darwin draw from the finches he observed at the Galapagos Islands?",
        "answer": "Darwin observed that the finches found on the Galapagos had adapted different sized beaks to eat different diets. By doing this, the birds had evolved to eat diets that would be inedible by other finches. This concept of a \"niche\" means that different species will exploit and use their environment in different ways.",
        "suggested_answer": "Organisms will evolve in order to fill unoccupied niches in their environment.",
        "options": [
            "Organisms will evolve in order to fill unoccupied niches in their environment.",
            "Birds reserve a herd mentality, and will aspire to remain similar in appearance to one another.",
            "Finches will all live in the same area of a tree, regardless of species type.",
            "The larger organisms have a large advantage in nature and will out-compete the smaller organisms."
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1233,
        "question": "Which of the following explains why viruses are not considered alive?",
        "answer": "There are a multitude of reasons as to why viruses are not classified as living organisms. In addition to the reasons given, viruses require host reproductive organelles and proteins in order to multiply. They also are not separated from the external world by a membrane or cell wall.",
        "suggested_answer": "All of the choices are correct",
        "options": [
            "Viruses possess either RNA or DNA; never both",
            "Viruses do not metabolize ATP",
            "They take advantage of host ribosomes for protein synthesis",
            "All of the choices are correct"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1234,
        "question": "Which of the following is a characteristic that is not used to characterize biomes?",
        "answer": "Biomes are characterized by a number of factors including climate, patterns of ecological succession, and habitat type. It is important to note that biomes are not distinguished by genetic or taxonomic patterns of organisms.",
        "suggested_answer": "Taxonomy and genetic similarities of organisms",
        "options": [
            "Patterns of ecological succession",
            "Taxonomy and genetic similarities of organisms",
            "Climate",
            "Habitat type"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1235,
        "question": "Which of the following are not hydrophilic molecules?",
        "answer": "Hydrophilic means \"water loving.\" Hydrophobic means \"water fearing.\" Recall from your general chemistry, \"like dissolves like\" with regards to solubility. This means that hydrophobic molecules can be dissolved in hydrophobic solvents, and vice versa. Lipids such as oil and cholesterol are hydrophobic molecules and are thus insoluble in water. A good rule of thumb is that all acids are soluble in water. We know sugar is soluble in water, and starch has a similar molecular structure as sugar; it consists of many sugar molecules in a chain.",
        "suggested_answer": "Lipids",
        "options": [
            "Amino acids",
            "Nucleic acids",
            "Lipids",
            "Sugar",
            "Starch"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1236,
        "question": "Which of the following is a cellular response due to ligand binding and activation of intracellular receptors?",
        "answer": "Intracellular receptors are found in the cytoplasm of the cell. Ligands for intracellular receptors are usually small molecules that can pass through the cell membrane, and include substances such as steroid hormones. Upon binding and activation, intracellular receptors bind specific DNA motifs in the nucleus and function as transcription factors, directly changing expression of genes.",
        "suggested_answer": "The intracellular receptor acts as a transcription factor for gene expression",
        "options": [
            "Intracellular receptor binding to tyrosine kinase receptors",
            "The closing of ion channel receptors",
            "The intracellular receptor activates adenylyl cyclase",
            "The intracellular receptor acts as a transcription factor for gene expression"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1237,
        "question": "Which of the following structures is found within the lungs and helps facilitate gas exchange?",
        "answer": "Alveoli are at the end of the respiratory pathway in humans, and act as a site of gas exchange (carbon dioxide and oxygen).",
        "suggested_answer": "Alveoli",
        "options": [
            "Microvilli",
            "Trachea",
            "Secretory vesicles",
            "Bronchi",
            "Alveoli"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1238,
        "question": "Select the complementary strand of DNA for the following DNA segment.",
        "answer": "The complementary strand will be going in the opposite direction (3'-5'). As a result, you will need to flip the direction in order for it to be complementary to the original strand. When pairing bases, remember that guanine (G) and cytosine (C) are paired with one another, and adenine (A) and thymine (T) are paired.",
        "suggested_answer": "5'-AGTCAAGT-3'",
        "options": [
            "5'-TCAGTTCA-3'",
            "5'-AGTCAAGT-3'",
            "5'-AGUCAAGU-3'",
            "5'-TGAACTGA-3'"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1239,
        "question": "What is the name of the structure of the leaf that attaches the leaf to the stem?",
        "answer": "The petiole attaches the leaf to the stem. Xylem is a tissue that transports water and minerals from the roots to other areas of a plant. Phloem is a tissue that transport water and nutrients from the leaves down to other areas of the plant. A filament is a part of a flower, not a leaf. Mesophyll is the tissue that makes up much of the body of the leaf and is where most photosynthesis takes place, but does not attach the leaf to the stem.",
        "suggested_answer": "Petiole",
        "options": [
            "Mesophyll",
            "Petiole",
            "Phloem",
            "Filament",
            "Xylem"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1240,
        "question": "What is the role of release factors in the termination stage of translation?",
        "answer": "In translation, stop codons within the mRNA strand signal the termination of the protein sequence to be translated. The stop codon nucleotide triplets are UAG, UGA, and UAA. Stop codons do not bind to an anticodon within a tRNA molecule, but rather to release factors. Release factors are proteins that recognize stop codons. The binding of release factors triggers the disassembly of the translational apparatus.",
        "suggested_answer": "Recognize the stop codon ",
        "options": [
            "Bind to ribosomes to stimulate polypeptide chain release",
            "Recognize the stop codon ",
            "Assist the tRNA molecule carrying the terminator amino acid",
            "Repress translation"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1241,
        "question": "Which of the following best describes solutions of equal solute concentrations?",
        "answer": "Isotonic is the correct term to describe solutions with equal solute concentrations. The root word \"iso\" means same; therefore, isotonic solutions have the same concentration of solute. A hypotonic solution will have less solute in it. A hypertonic solution will have a higher concentration of solute between the two solutions.",
        "suggested_answer": "Isotonic",
        "options": [
            "Hypertonic",
            "Hypotonic",
            "None of these",
            "Isotonic"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1242,
        "question": "Which of the following functions is NOT performed by the lymphatic system?",
        "answer": "The lymphatic system is responsible for removing excess fluid from the interstitium and returning it to the bloodstream. The fluid will travel through lymph nodes, which monitor for the presence of microbes in the blood. If microbes are present, the lymph nodes will mount an immune response. The lymphatic system also reroutes fat digestates from the small intestine's capillaries and drains them into the veins of the neck. Lymphatic vessels do not assist in the transfer of oxygen to body tissues. ",
        "suggested_answer": "Provides additional oxygen for body tissues",
        "options": [
            "Elicits an immune response in the presence of an infection",
            "Provides additional oxygen for body tissues",
            "Returns interstitial fluid to the bloodstream",
            "Transports fat digestates and places them into the bloodstream"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1243,
        "question": "Water reabsorption occurs in which three regions of the nephron?",
        "answer": "Water is reabsorbed at various times during the excretion process as it passes through the nephron, in order to maintain proper ion levels. It is not, however, reabsorbed as urine ascends through the thin and thick ascending limbs in the loop of Henle. Rather, this region only involves ion reabsorption and urea secretion.",
        "suggested_answer": "Thin descending limb, distal convoluted tubule, collecting duct",
        "options": [
            "Thin descending limb, thin ascending limb, collecting duct",
            "Thin descending limb, distal convoluted tubule, collecting duct",
            "Thin ascending limb, thick ascending limb, collecting duct",
            "Thin descending limb, thin ascending limb, thick ascending limb"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1244,
        "question": "During the light dependent reaction, what is the purpose of creating a proton gradient in the thylakoid lumen?",
        "answer": "The ATP synthase protein is embedded in the thylakoid membrane. When sunlight is captured by photosystem II in the light dependent reaction, water is split, producing O2 and 2 H+ ions and 2 electrons. As the electrons flow down the electron transport chain, this energy is used to pump hydrogen ions from the stroma into the thylakoid space, producing a high concentration of hydrogen ions inside the thylakoid lumen. Eventually, the hydrogen ions then flow from the area of high concentration (in the thylakoid lumen) to an area of low concentration (in the stroma ), through the ATP synthase protein. The movement of the hydrogen ions through this structure combines ADP with phosphate, producing ATP. ",
        "suggested_answer": "To power ATP synthase",
        "options": [
            "To power ATP synthase",
            "To create ",
            "To generate ",
            "To power ATP hydrolysis"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1245,
        "question": "Which of the following is an example of a lipid?",
        "answer": "Triacylglycerol (also called a fat or triglyceride) is a type of lipid. Amino acids are the subunits that make up proteins. Chitin and cellulose are polysaccharides, or carbohydrates.",
        "suggested_answer": "triacylglycerol",
        "options": [
            "chitin",
            "triacylglycerol",
            "cellulose",
            "amino acid"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1246,
        "question": "A lipid that consists of two fatty acids and a phosphate group linked to glycerol is a __________.",
        "answer": "A phospholipid has two fatty acid tails, which consist of hydrocarbons with even numbers of carbons ranging from 4 to 28, and a phosphate group attached to a glycerol head, which is hydrophilic. Monoglycerides contain only one fatty acid chain bound to a glycerol head, and triglycerides contain three fatty acid chains. ",
        "suggested_answer": "phospholipid",
        "options": [
            "carbohydrate",
            "steroid",
            "monoglyceride",
            "phospholipid"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1247,
        "question": "Which of the following choices is a true characteristic of the process of pinocytosis?",
        "answer": "Pinocytosis is a type of endocytosis. During pinocytosis, the cell takes up extracellular fluid through plasma membrane invagination and vesicle formation. The process is energetically costly and requires many molecules of ATP. Pinocytosis is a non-specific process, meaning that materials are not selectively taken up. In other words, extracellular fluid is engulfed along with any or all particles within it. The fluid taken up by pinocytosis is already digested and degraded; therefore, the process is not accompanied by the action of lysosomes.",
        "suggested_answer": "Pinocytosis is non-specific",
        "options": [
            "Pinocytosis is non-specific",
            "Pinocytosis is the intake of solid particles into the cell",
            "Particles are broken down immediately in the pinocytic vesicles ",
            "Pinocytosis is not energetically costly "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1248,
        "question": "Water flows through which of the following in the process of percolation?",
        "answer": "Percolation is defined as the vertical flow of water through subsurface soil and rocks. Percolation is influenced by gravity.",
        "suggested_answer": "Soil and rocks",
        "options": [
            "Atmosphere",
            "Soil and rocks",
            "Ground surface",
            "None of these"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1249,
        "question": "Human immunodeficiency virus (HIV) principally attacks which type of cell?",
        "answer": "Human immunodeficiency virus (HIV), which is the causative agent of AIDS, primarily attacks the white blood cells. More specifically, it infects helper T-cells, macrophages, and dendritic cells. By damaging the cells of the immune system the virus creates an \"immunodeficiency,\" weakening the body's ability to fight infection.",
        "suggested_answer": "White blood cells",
        "options": [
            "Beta cells",
            "Microglial cells",
            "Red blood cells",
            "White blood cells",
            "Pancreatic acinar cells"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1250,
        "question": "The head of a giraffe is 5 meters above its heart. Compared to humans, giraffes have:",
        "answer": "Giraffe's have to pump blood to longer distances. In order to achieve this feat, they must have stronger heart contractions and therefore higher systolic blood pressure.",
        "suggested_answer": "Higher systolic blood pressure",
        "options": [
            "Lower systolic blood pressure.",
            "Less blood",
            "Higher systolic blood pressure",
            "Veins of smaller diameter"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1251,
        "question": "Which of the following types of seasons best characterize chaparral biomes?",
        "answer": "Chaparral biomes are primarily observed to exist in California. The seasons in chaparral areas include hot dry summers and mild wet winters.",
        "suggested_answer": "Hot dry summers and mild wet winters",
        "options": [
            "Hot dry summers",
            "Hot dry summers and mild wet winters",
            "Extremely cold winters with low precipitation",
            "Hot dry summers and very cold winters with low precipitation"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1252,
        "question": "What is a negative aspect of oxidative phosphorylation?",
        "answer": "During the reduction of oxygen to water, reactive oxygen species such as superoxide or hydrogen peroxide can be produced. These molecules are highly reactive and such can react with proteins or DNA to cause cellular damage or mutations. ",
        "suggested_answer": "Production of reactive oxygen species",
        "options": [
            "Production of carbon dioxide",
            "The is no negative aspect",
            "Production of reactive oxygen species",
            "Production of excessive ATP",
            "Oxidative phosphorylation does not produce as much energy as glycolysis and fermentation"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1253,
        "question": "Hyponatremia occurs when the sodium concentration in the blood is low. Which of the following best describes how this will affect muscle contractions?",
        "answer": "All muscle types (cardiac, skeletal, and smooth), require sodium to enter the cell to initiate an action potential. The action potential then travels down the axon, elicits neurotransmitters, activates calcium channels, and causes the muscle to contract. In order to initiate the action potential, sodium must enter the cell in large quantity. This depolarizes the cell above the action potential threshold. If the cell does not reach the action potential threshold, then there will be no action potential and no muscle contraction.",
        "suggested_answer": "Hyponatremia will make it harder to contract muscles",
        "options": [
            "Hyponatremia will make it harder to contract muscles",
            "None of these",
            "Hyponatremia will make the muscle easier to contract at first then more difficult over time",
            "Hyponatremia will make it easier to contract muscles",
            "Hyponatremia will not affect the difficulty in being able to contract muscles"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1254,
        "question": "What prevents backflow of blood in veins?",
        "answer": "Veins contain a series of one way valves that prevent blood from flowing backwards. This is particularly important in larger veins in the legs that are further below the heart, and must oppose gravity to get blood back to the heart. Almost all of the blood pressure produced by the heart is lost along capillaries, thus the blood pressure in the veins is almost zero. Blood is \"squished up\" a little at a time due to the contraction of the skeletal muscles around veins and the presence unidirectional valves.",
        "suggested_answer": "Veins contain a series of one-way valves that allow blood to only flow in one direction",
        "options": [
            "Veins contain cilia which push the blood forward",
            "Veins contain a series of one-way valves that allow blood to only flow in one direction",
            "Nothing; blood often flows backward in veins",
            "Blood pressure by itself is enough to keep blood flowing in a single direction"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1255,
        "question": "Which of the following structures is unique to plant cells?",
        "answer": "Chloroplasts are organelles in plant cells that conduct photosynthesis; therefore they are unique to plant cells. All the other mentioned organelles can be found in both animal and plant cells.",
        "suggested_answer": "Chloroplasts",
        "options": [
            "Chloroplasts",
            "Vacuoles",
            "Mitchondria",
            "Endoplasmic reticulum",
            "Lysosomes"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1256,
        "question": "What is the function of a chaperone?",
        "answer": "Chaperones are proteins that are vital for the proper folding of some polypeptide chains. Without chaperones, proteins may be folded incorrectly and become nonfunctional. Chaperones are particularly essential to tertiary and quaternary structure.",
        "suggested_answer": "Assist in protein folding",
        "options": [
            "Bring tRNA to the proper locations",
            "Assist in protein folding",
            "Assist in nuclear export ",
            "Bring mRNA to proper locations"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1257,
        "question": "Hemoglobin is an example of a __________.",
        "answer": "Hemoglobin is the protein in red blood cells that binds and transports oxygen to the body tissues. It contains an iron core, and each hemoglobin molecule can bind up to four oxygen molecules.",
        "suggested_answer": "protein",
        "options": [
            "nucleic acid",
            "steroid",
            "protein",
            "phospholipid"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1258,
        "question": "Which hormone is most likely to be produced in order to decrease plasma calcium levels?",
        "answer": "The two hormones known for their effect on plasma calcium levels are calcitonin and parathyroid hormone (PTH). When calcium levels are high, calcitonin is released by the thyroid gland to stimulate the uptake of serum calcium into bone. This effectively decreases calcium levels in the blood. PTH has the opposite effect and is released by the parathyroid gland.",
        "suggested_answer": "Calcitonin",
        "options": [
            "Calcitonin",
            "Melatonin",
            "Oxytocin",
            "Antidiuretic hormone",
            "Parathyroid hormone"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1259,
        "question": "Which of the following defines the identity of an element?",
        "answer": "The identity of an element is determined by the number of protons. One cannot alter the number of protons without altering the identity of the element. By adding a proton, the atomic number increases by one and the element identity changes.",
        "suggested_answer": "Number of protons",
        "options": [
            "Number of electron shells",
            "Number of nucleons",
            "Number of protons",
            "Number of electrons",
            "Number of neutrons"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1260,
        "question": "If a cell is dropped into a solution that is hypotonic to the cell, what happens?",
        "answer": "When the solution is hypotonic to the cell, the concentration of solute outside the cell is less. The water diffuses to the higher concentration inside the cell and causes the cell to swell with water, at it may burst. ",
        "suggested_answer": "Water diffuses into the cell.",
        "options": [
            "Water diffuses into the cell.",
            "The cell has an equilibrium of water in and water out.",
            "Water diffuses out of the cell.",
            "No change in diffusion occurs."
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1261,
        "question": "Which of the following best represent the building blocks of polymers?",
        "answer": "Polymers are built up from monomers. Polymers are placed into different classes based on the different natures of the monomers that make up their structure.",
        "suggested_answer": "Monomers",
        "options": [
            "Monomers",
            "Ions",
            "Unimers",
            "None of these"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1262,
        "question": "The functional units of the nervous system are __________.",
        "answer": "Neurons are the functional unit of the nervous system. Dendrites of the neuron accept incoming action potentials and stimuli, and propagate the signal down the cell axon to stimulate other nearby neurons.",
        "suggested_answer": "neurons",
        "options": [
            "nephrons",
            "gray matter",
            " ",
            "neurotransmitters",
            "neurons"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1263,
        "question": "If the chi square value is larger than the critical value at a given level of significance, what can be stated?",
        "answer": "If the chi square value is large, this indicates a large difference between the observed and expected values. This will subsequently result in a small p-value when using the critical value table. When the p-value is equal to or smaller than the significance level (alpha), the null hypothesis is rejected. ",
        "suggested_answer": "The null hypothesis is rejected",
        "options": [
            "The null hypothesis is rejected",
            "The alternative hypothesis is rejected",
            "The null hypothesis fails to be rejected",
            "The null hypothesis is accepted"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1264,
        "question": "Which of the following is a by-product of the light reactions in photosynthesis?",
        "answer": "During the light reactions water is split and the by-product is oxygen. Oxygen is then released back into the atmosphere. Sugars are produced during the Calvin cycle and are not a direct product of the light reactions.",
        "suggested_answer": "Oxygen",
        "options": [
            "Carbon dioxide",
            "Water",
            "Sugar",
            "Oxygen"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1265,
        "question": "Afferent neurons are often associated with __________ function, while efferent neurons are often associated with __________ function.",
        "answer": "A good way to remember afferent vs. efferent neurons is: Afferent Arrives, Efferent Exits.",
        "suggested_answer": "sensory or input . . . motor or effector ",
        "options": [
            "motor or input . . . sensory or effector",
            "sensory or input . . . motor or effector ",
            "motor or effector . . . sensory or input",
            "sensory or effector . . . motor or input"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1266,
        "question": "What happens to the electron transport chain when oxygen is not available?",
        "answer": "Oxygen is the final electron acceptor in the electron transport chain, which allows for oxidative phosphorylation. Without oxygen, the electrons will be backed up, eventually causing the electron transport chain to halt. This will cause the products of glycolysis to go through fermentation instead of going to the citric acid cycle. Without oxygen, oxidative phosphorylation (the electron transport chain) is impossible, but substrate-level phosphorylation (glycolysis) continues.",
        "suggested_answer": "Oxidative phosphorylation ceases and the cell reverts to fermentation after glycolysis",
        "options": [
            "Oxidative phosphorylation utilizes alternative fuel sources, such as fats",
            "Oxidative phosphorylation will utilize carbon dioxide instead of oxygen",
            "Oxidative phosphorylation ceases and the cell reverts to fermentation after glycolysis",
            "Oxidative phosphorylation will produce oxygen from available carbon dioxide",
            "Oxidative phosphorylation can continue without any noticeable impact"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1267,
        "question": "What occurs during exocytosis?",
        "answer": "In exocytosis the membrane of a vacuole surrounding some material fuses with the cell membrane, forcing the contents out of the cell wall. The substance is secreted out of the cell. The process by which foreign matter is engulfed by immune cells is phagocytosis. Water and salts are stored in the sarcoplasmic reticulum in certain cell types (muscle) but this is not exocytosis, which involves the removal/expulsion of substances from the cell into the extracellular environment.",
        "suggested_answer": "The membrane of the vacuole fuses with the cell membrane, forcing the contents out of the cell.",
        "options": [
            "Water and salts are stored in the sarcoplasmic reticulum.",
            "The cell receptor is activated and substances move into the cell.",
            "The membrane of the vacuole fuses with the cell membrane, forcing the contents out of the cell.",
            "Foreign substances are engulfed by cells of the immune system."
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1268,
        "question": "A population of geese migrates from Canada to Florida every winter. Individuals from the Canadian population sometimes breed with native Floridian geese. What is this an example of?",
        "answer": "This is an example of gene flow, because a small number of individuals from one population are passing some genes on to those in another population. Genetic drift occurs within a single population, so it does not apply here. This is not an example of speciation. There can't be a prezygotic barrier present if the geese are able to successfully mate.",
        "suggested_answer": "Gene flow",
        "options": [
            "Genetic drift",
            "A prezygotic barrier",
            "Sympatric speciation",
            "Gene flow"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1269,
        "question": "Which of the following is not an example of trisomy? ",
        "answer": "Trisomy is a condition of an extra chromosome copy, such as in Patau (trisomy 13), Edwards (trisomy 18), Kleinfelters (XXY), and Downs (trisomy 21). Turners syndrome is a condition of monosomy (X).",
        "suggested_answer": "Turners",
        "options": [
            "Downs",
            "Kleinfelters",
            "Turners",
            "Patau",
            "Edwards"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1270,
        "question": "Which of the following templates is used during translation?",
        "answer": "mRNA is the template used during translation. The mRNA strand is read and \"translated\" into a polypeptide by tRNA.",
        "suggested_answer": "mRNA",
        "options": [
            "tRNA",
            "rRNA",
            "mRNA",
            "DNA",
            "microRNA"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1271,
        "question": "Which of the following statements is true concerning DNA replication?",
        "answer": "DNA polymerase always reads DNA strands in the 3'-to-5' direction, creating a complimentary 5'-to-3' strand. As a result, the parent strand oriented in the 3'-to-5' can be replicated seamlessly, but the strand that is unraveled in the 5'-to-3' direction will require multiple attachment points for DNA polymerase so that the whole strand can be replicated in the reverse direction.",
        "suggested_answer": "The strand opened in the 5'-to-3' direction will be replicated more slowly than the 3'-to-5' strand",
        "options": [
            "DNA polymerase reads the template strands in the 5'-to-3' direction",
            "Replication takes place via a conservative mechanism",
            "The strand opened in the 3'-to-5' direction will be replicated more slowly than the 5'-to-3' strand",
            "The strand opened in the 5'-to-3' direction will be replicated more slowly than the 3'-to-5' strand"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1272,
        "question": "What would be the effect on urine by drugs that block sodium channel transporters in the kidney?",
        "answer": "Diuretic drugs promote the production of urine. One drug, known as furosemide, inhibits transport of both sodium and chloride in the ascending limb of the loop of Henle. This mechanism of action prevents the maintenance osmolarity gradients that promote the reabsorption of water, resulting in more dilute urine. If the gradient is lost, then water is not drawn out of the filtrate as it travels through the collecting duct and the result is a larger volume of dilute urine. Antidiuretic hormone, secreted from the posterior pituitary, works to promote the reabsorption of water in the collecting duct, which concentrates the urine and conserves water.",
        "suggested_answer": "Urine will be more dilute",
        "options": [
            "There is no effect",
            "Urine will be more dilute",
            "Urine will be more concentrated",
            "Less urine will be produced"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1273,
        "question": "Which of the following is unique to graded potentials?",
        "answer": "Graded potentials reflect stimulus strength because they do not propagate via saltatory conduction (as action potentials do), and thus decrease in amplitude down the axon. All other answers listed are characteristics of action potentials.",
        "suggested_answer": "Changes in membrane potential reflect stimulus strength",
        "options": [
            "The frequency of changes in membrane potential indicates the strength of the stimulus",
            "Changes in membrane potential reflect stimulus strength",
            "Changes in membrane potential maintain strength as they spread out from the point of origin",
            "Changes in membrane potential are conducted down the axon",
            "Changes in membrane potential travel long distances"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1274,
        "question": "Which of the following is not a function of connective tissue?",
        "answer": "Blood, bone, tendons, ligaments, and cartilage are all types of connective tissue. Bone and cartilage provide the framework for the body, as well as protect the internal organs of the body. Blood is responsible for transportation of oxygen and other nutrients throughout the body. Tendons transfer the force created by muscle tissue to the bones, allowing for movement.",
        "suggested_answer": "Communicating pain sensation to the brain",
        "options": [
            "Protecting the body's internal organs",
            "Transfer force between structures",
            "Communicating pain sensation to the brain",
            "Transporting molecules to muscle and other parts of the body",
            "Providing framework for the body"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1275,
        "question": "Which of the following is an example of a haploid cell? ",
        "answer": "A haploid cell is a cell that possesses only a single set of chromosomes (n=23) as opposed to the double set found in somatic cells (2n=46). The gametes (sperm and ova) in humans are examples of haploid cells. Also, the zygote is formed upon fusion of two gametes, and is thus diploid.  ",
        "suggested_answer": "Sperm cell",
        "options": [
            "Zygote",
            "Sperm cell",
            "Skin cell",
            "Brain cell"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1276,
        "question": "How do enzymes increase reaction rates?",
        "answer": "Enzymes increase the rate of reactions through stabilizing the transition state, which decreases activation energy.",
        "suggested_answer": "Decreasing activation energy",
        "options": [
            "Increasing activation energy",
            "Increasing temperature",
            "Decreasing activation energy",
            "Increasing pH"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1277,
        "question": "A mutation in the bacterial cell escherichia coli makes it unable to break down the sugar lactose. Which of the following is not a potential mutation that could result in this phenotype?",
        "answer": "DNA polymerase is not involved in the process of transcription. The incorrect answer could be corrected if it was rewritten as \"a mutation in the promoter region where the RNA polymerase binds to.\"",
        "suggested_answer": "A mutation in the region DNA polymerase binds to in the promoter",
        "options": [
            "A point mutation in the LacZ gene that abolishes enzyme activity",
            "A mutation in the operator that blocks the activator from binding",
            "A frameshift mutation in the LacZ coding region",
            "A mutation in the region DNA polymerase binds to in the promoter"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1278,
        "question": "The monomers of all biological macromolecules are combined by which type of bond?",
        "answer": "Macromolecules, such as proteins, nucleic acids, and polysaccharides, are composed of monomers. Each polymer is made from at least two smaller monomers. Protein monomers are amino acids, nucleic acid monomers are nucleotides, and polysaccharide monomers are monosaccharides. In order to form polymers, the monomers must form covalent bonds with one another.",
        "suggested_answer": "Covalent bond",
        "options": [
            "London dispersion forces",
            "Covalent bond",
            "Ionic bond",
            "Hydrogen bond"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1279,
        "question": "Which process is incorrectly matched with its location?",
        "answer": "During the light dependent reaction, protons are pumped from the thylakoid stroma into the lumen. Then, these protons flow down their electrochemical gradient (from high concentration to low concentration), through the ATP synthase protein, producing ATP.",
        "suggested_answer": "in the light dependent reaction, protons flow down their electrochemical gradient from the thylakoid lumen into the stroma, through the ATP synthase protein",
        "options": [
            "in the light independent reaction, G3P is produced in the thylakoid stroma",
            "carbon fixation during the light independent reaction occurs in the thylakoid stroma",
            "in the light dependent reaction, protons flow down their electrochemical gradient from the thylakoid lumen into the stroma, through the ATP synthase protein",
            "in the light dependent reaction, protons are pumped from the thylakoid stroma into the lumen"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1280,
        "question": "How many carbon dioxide molecules are produced during cellular respiration, starting with pyruvate?",
        "answer": "Three carbon dioxide molecules are produced during the cellular respiration if we start with one molecule of pyruvate. The first is made when pyruvate is converted to acetyl-CoA (intermediate step/pyruvate dehydrogenase). The second is produced when D-isocitrate is converted to alpha-ketoglutarate. The third and last is produced when alpha-ketoglutarate is converted to succinyl-CoA. The later two are produced in the citric acid cycle.",
        "suggested_answer": "three",
        "options": [
            "three",
            "two",
            "four",
            "six",
            "five"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1281,
        "question": "Which of the following is true regarding menstrual cycles in individuals living in the developing world?",
        "answer": "There are variations in the age of menarche by geographic location and environmental conditions. In developing countries, the age of menarche is later than in industrialized nations.",
        "suggested_answer": "Age of menarche is later",
        "options": [
            "Thicker cervical mucus",
            "Age of menarche is earlier",
            "Higher hormone levels",
            "Age of menarche is later"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1282,
        "question": "What happens during anaphase I of meiosis?",
        "answer": "During the anaphase I stage of meiosis, homologous chromosome pairs separate to opposite poles of the cell, and the cell elongates. The sister chromatids remain attached at the centromeres. This maintenance of sister chromatids is the key difference between meiosis and mitosis. In mitosis, anaphase features the separation of sister chromatids, which is what will happen during anaphase II of meiosis. Note however, that the sister chromatids in meiosis are not identical, due to crossing over in prophase I. ",
        "suggested_answer": "Separation of homologous chromosome pairs",
        "options": [
            "Crossing over",
            "Separation of homologous chromosome pairs",
            "Mitotic spindles attach to kinetochores",
            "Nuclear membrane breakdown"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1283,
        "question": "Under anaerobic conditions (excluding bacteria and yeast), pyruvate is converted to __________ to produce __________.",
        "answer": "Under anaerobic conditions, the 2 molecules of pyruvate produced from glycolysis cannot undergo further oxidation; therefore, they are reduced to lactate (lactic acid) and . This allows glycolysis to continue in order for cells to produce ATP under anaerobic conditions. Once is cycled back through glycolysis, glucose metabolism can continue to produce 2 net molecules of ATP for cellular energy.",
        "suggested_answer": "lactic acid . . . ",
        "options": [
            "lactic acid . . . ",
            "Ethanol . . . ",
            "ethanol . . . ",
            "lactic acid . . . ",
            "lactic acid . . . "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1284,
        "question": "A purple flower sprouts in the garden one day, and the gardener would like to know if it will only create purple flowers if it is pollinated. If she wants to use only one generation to determine its genotype, how should she pollinate the flower?",
        "answer": "When attempting to determine if an organism is heterozygous or homozygous for a dominant trait, it is best to use a test cross. A test cross involves crossing the flower in question with a homozygous recessive flower. Since a white flower can only contribute a white allele, we can determine if the purple flower in question is heterozygous or homozygous. Any white flowers in the next generation will confirm that the purple flower is heterozygous. If they are all purple, we can confirm that the flower is homozygous for the trait.",
        "suggested_answer": "Pollinate the purple flower with a white flower",
        "options": [
            "Pollinate the purple flower with a homozygous purple flower",
            "Pollinate the purple flower with a white flower",
            "Self-pollinate the purple flower",
            "Pollinate the purple flower with any other purple flower"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1285,
        "question": "Which of the following is responsible for creating myelin, for the myelin sheaths of axons?",
        "answer": "Schwann cells produce myelin for neurons of the peripheral nervous system, while oligodendrocytes produce myelin for neurons of the central nervous system.",
        "suggested_answer": "Schwann cells and oligodendrocytes",
        "options": [
            "Schwann cells only",
            "Schwann cells and oligodendrocytes",
            "None of the other answers are correct",
            "Oligodendrocytes only"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1286,
        "question": "What happens when the ribosome encounters a stop codon?",
        "answer": "Stop codons are a signal for the ribosome to recruit a release factor. Release factors are proteins that dissociate the translation complex and release the polypeptide chain.",
        "suggested_answer": "A release factor is recruited, which dissociates the translation complex and releases the completed polypeptide chain",
        "options": [
            "A special terminal amino acid is placed on the polypeptide chain ",
            "A release factor is recruited, which dissociates the translation complex and releases the completed polypeptide chain",
            "An anticodon-codon pairing is made with a final tRNA that releases the completed polypeptide chain",
            "A chaperone is recruited to help fold the polypeptide chain"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1287,
        "question": "Which neurotransmitter is involved in muscle movement?",
        "answer": "The correct answer is ACh (acetylcholine) because it is involved with muscle contraction. It is released at the neuromuscular junction, the site where the neuron and muscle meet.",
        "suggested_answer": "Acetylcholine",
        "options": [
            "Glutamate",
            "Serotonin",
            "GABA",
            "Dopamine",
            "Acetylcholine"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1288,
        "question": "Which of the following transportation vessels carries deoxygenated blood away from the heart?",
        "answer": "Arteries carry blood away from the heart, while veins transport blood towards the heart. Because the pulmonary arteries transport blood from the right ventricle towards the lungs to exchange carbon dioxide for oxygen, they contain deoxygenated blood.",
        "suggested_answer": "Pulmonary arteries",
        "options": [
            "Pulmonary veins",
            "Vena cavae",
            "Aorta",
            "Pulmonary arteries"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1289,
        "question": "Which of the following best characterize desert biomes?",
        "answer": "Low rainfalls as well as the organisms that are specifically adapted to inhabit these conditions characterize desert biomes. Desert biomes can be further characterized based on seasons.",
        "suggested_answer": "Low rainfall and animal adaptations to a water-poor environment",
        "options": [
            "Animals are adapted to a water-poor environment",
            "Neither low rainfall nor animal adaptations to a water-poor environment",
            "Low rainfall",
            "Low rainfall and animal adaptations to a water-poor environment"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1290,
        "question": "Which of the following is not an example of eukaryotic post-transcriptional modification?",
        "answer": "Post-transcriptional modifications are changes that are made to the mRNA transcript before it is translated into a protein. The first of these changes is cleavage at the 3' end to separate the new strand, or \"primary transcript,\" from the transcription machinery. Next, a protective 5' cap is added, as is a string of adenine nucleotides at the 3' end. Finally, noncoding regions, called introns, are spliced out and the exons, or coding regions, are reconnected.",
        "suggested_answer": "Exons, or non-coding regions, are removed via splicing",
        "options": [
            "Exons, or non-coding regions, are removed via splicing",
            "The 3' end of the growing strand is cleaved",
            "After splicing, the exons are rejoined to form a final mRNA transcript",
            "A methylguanosine cap is added to the 5' end",
            "A poly-A tail is added to the 3' end"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1291,
        "question": "A researcher is examining a portion of an endoplasmic reticulum (ER) under an electroscope and finds that the surface lacks ribosomes. What type of ER is the researcher examining?",
        "answer": "Smooth ER lacks ribosomes and functions in diverse metabolic process such as synthesis of lipids, metabolism of carbohydrates, and detoxification of drugs and poisons. Rough ER contains ribosomes on its surface and functions in protein synthesis. Bacteria lack endoplasmic reticulum since it is membrane-bound.",
        "suggested_answer": "Smooth ER",
        "options": [
            "More than one of these",
            "Bacterial ER",
            "Smooth ER",
            "Rough ER"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1292,
        "question": "Which of the following is true regarding the process of stabilizing selection?",
        "answer": "Stabilizing selection is a type of selection that decreases genetic diversity and stabilizes an optimum trait. In this situation, there is selection against extreme phenotypes. It is the opposite of disruptive selection and similar to purifying selection.",
        "suggested_answer": "It decreases genetic diversity",
        "options": [
            "It favors individuals with extreme phenotypes",
            "It has the same impact as disruptive selection",
            "It increases genetic diversity",
            "It decreases genetic diversity"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1293,
        "question": "The heads of phospholipids are __________.",
        "answer": "The heads of phospholipids contain a glycerol group and a phosphate group. Both of these groups contain atoms other than hydrogen and carbon, so they are not hydrocarbons. Since phosphate is negatively charged, and glycerol contains oxygens, this part of the molecule is water soluble. In other words, it is hydrophilic, lipophobic, and polar. ",
        "suggested_answer": "hydrophilic",
        "options": [
            "lipophilic",
            "hydrophobic",
            "hydrophilic",
            "hydrocarbons"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1294,
        "question": "A large difference in which atomic property between two atoms will result in an ionic bond?",
        "answer": "Electronegativity is the tendency of an atom to attract an electron. Elements with very high electronegativities are found at the upper right of the periodic table, while those with low electronegativities are found at the lower left. When two atoms have a very large difference in electronegativity, one atom will have a much greater tendency to attract an electron than the other. As a result, the more electronegative element will pull an electron away from the less electronegative element, creating an electron transfer. This transfer is an ionic bond.",
        "suggested_answer": "Electronegativity",
        "options": [
            "Atomic mass",
            "Electronegativity",
            "Ionization energy",
            "Ionic radius",
            "Atomic charge"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1295,
        "question": "What cellular features are duplicated during prophase II of meiosis?",
        "answer": "Prophase II marks the beginning of the second cell division of meiosis, called meiosis II. Unlike prophase I, the chromosomes are not duplicated prior to the start of prophase II. However, during prophase II, the centrioles replicate, resulting in two pairs of centrioles per cell. The two centriole pairs separate to opposite cell poles. Other features of prophase II are chromosome condensation, the degradation of the nuclear membrane, and meiotic spindle formation.",
        "suggested_answer": "Centrioles",
        "options": [
            "Plasma membrane",
            "Centrosomes",
            "Genetic material",
            "Centrioles"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1296,
        "question": "What type of bonds are primarily responsible for developing secondary protein structures?",
        "answer": "Hydrogen bonding is responsible for giving shape to the secondary structures of proteins. The amino acids in the protein all carry carboxyl and amino termini, which are capable of forming hydrogen bonds. Secondary protein structure refers to the formation of alpha-helices and beta-pleated sheets through hydrogen bonding in the amino acid backbone. The R-groups are not involved in secondary structure.",
        "suggested_answer": "Hydrogen bonds",
        "options": [
            "Hydrogen bonds",
            "Covalent bonds",
            "Peptide bonds",
            "Ionic bonds"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1297,
        "question": "A promoter sequence is a stretch of DNA upstream of a gene that helps initiate transcription. Which base pairs, if any, would you expect to find in high proportions in promotor sequences?",
        "answer": "Promoters help the transcription machinery and associated proteins (like DNA helicase) find the correct spot to start transcription and facilitate opening of the DNA. When transcription takes place, DNA helicase must open up or \"unzip\" the double helix. Te fewer the hydrogen bonds the easier it is for DNA to be denatured. Adenine and thymine only have two hydrogen bonds between them, while cytosine and guanine have 3. Thymine and adenine are the best candidates for promoter sequences based on their fewer number of hydrogen bonds which is evidenced by a common promoter sequence called \"TATA box\".",
        "suggested_answer": "Adenine and thymine",
        "options": [
            "Cytosine and adenine",
            "The type of nitrogenous base does not matter",
            "Adenine and thymine",
            "Cytosine and guanine",
            "Adenine and uracil"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1298,
        "question": "Which of the following properties of water are beneficial to life?",
        "answer": "The high specific heat of water means that it requires a large amount of heat to raise the temperature of water. This helps the temperature of the environment from becoming too hot or too cold. Also, humans are about 66% water, thus this property of water helps us regulate our body temperature too. Evaporative cooling helps prevent bodies from overheating. We have evolved to take advantage of this property of water, perspiring through our skin to cool it down during exercise. The fact that ice floats protects lakes from further cooling by cold wind. The fact that water is such a good solvent for polar molecules allows the chemistry of life to occur. Recall that life uses four main biomolecules, and dehydration synthesis and hydrolysis reactions occur constantly.",
        "suggested_answer": "I, II, III, and IV",
        "options": [
            "I and IV",
            "I, II, and IV",
            "I, II, III, and IV",
            "II and III",
            "III and IV"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1299,
        "question": "In humans, blood type is determined by three alleles: A, B, and O. Both the A and B alleles are dominant to the O allele. When both A and B are present, however, a combined phenotype of AB results. In AB individuals, both A and B antigens are fully expressed on the cell surface. This is an example of __________.",
        "answer": "Since the A and B alleles both seem to exert a form of dominance, this is clearly not our common example of a complete dominance scenario. We can conclude that blood type is determined by either incomplete dominance or codominance.",
        "suggested_answer": "codominance",
        "options": [
            "codominance",
            "nondominance",
            "complete dominance",
            "incomplete dominance",
            "Mendelian dominance"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1300,
        "question": "Snapdragons\u2019 color is controlled by recessive epistasis, with the B locus controlling color and the A locus determining expression of B locus. B (red) is dominant to b (yellow) Which of the following is false?",
        "answer": "Epistasis describes the interaction of genes, where the epistatic locus masks the effects of a gene at another locus. In this example, locus A is epistatic. As this is stated to be recessive epistasis, when the A locus is aa this locus will mask the effect of the B locus (color). Thus, any combination of B/b with aa will result in white snapdragon. Thus, bbaa will result in white snapdragon, as the epistatic locus will mask the effect of the B locus.",
        "suggested_answer": "bbaa results in yellow snapdragon",
        "options": [
            "bbAA results in yellow snapdragon",
            "BbAA results in red snapdragon",
            "bbAa results in yellow snapdragon",
            "bbaa results in yellow snapdragon"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1301,
        "question": "What is the primary function of phloem in plants?",
        "answer": "Phloem is a type of tissue in vascular plants that transports organic nutrients. Among other components, phloem contains sieve elements, parenchyma cells, and supportive cells.",
        "suggested_answer": "Transport of organic nutrients",
        "options": [
            "Secretion of hormones",
            "Wound repair",
            "Transport of organic nutrients",
            "Photosynthesis"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1302,
        "question": "What does the principle of dominance in genetics state?",
        "answer": "The principle of dominance in genetics states that some alleles are dominant and are expressed instead of the recessive allele. Hybrids are crosses between parents with different traits, and genotypes for different eye colors are different.",
        "suggested_answer": "Some alleles are dominant and are expressed instead of the recessive allele.",
        "options": [
            "The genotype for eye color is the same for all eye colors.",
            "A recessive allele will be expressed instead of a dominant allele.",
            "Crosses between parents with different traits are called hybrids.",
            "Some alleles are dominant and are expressed instead of the recessive allele."
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1303,
        "question": "Which of the following is a polysaccharide stored in plants?",
        "answer": "Starch is a polysaccharide stored in plants. Glycogen is a polymer of glucose in animals stored in the liver and muscle cells. Lipids are molecules made mostly of carbon and hydrogen that store energy.",
        "suggested_answer": "starch",
        "options": [
            "starch",
            "lipids",
            "glucose",
            "glycogen"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1304,
        "question": "Scientists have characterized a new genetic disorder that only affects males. What is the most likely explanation of how this disorder is passed from generation to generation?",
        "answer": "If only males display the disorder, it is most likely a Y-linked genetic disorder. The only possible way to inherit this disease, then, would be through the inheritance of the father's Y-chromosome.",
        "suggested_answer": "Inheritance of the father's Y-chromosome",
        "options": [
            "Abnormal testosterone levels",
            "Epigenetic inheritance",
            "Inheritance of the mother's Y-chromosome",
            "Inheritance of the father's Y-chromosome"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1305,
        "question": "When an action potential is fired in a neuron, it results in neurotransmitters being released from the axon terminal into the synapse. We know that sodium ions drive the action potential down the axon via depolarization. What causes neurotransmitters to be released into the synapse?",
        "answer": "It is the influx of calcium ions into the axon terminal that causes vesicles to fuse with the cell membrane, releasing the neurotransmitters held within the vesicles into the synapse.",
        "suggested_answer": "Calcium ion influx",
        "options": [
            "Potassium ion influx",
            "Sodium ion influx",
            "Chloride influx",
            "Calcium ion influx"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1306,
        "question": "Enzymes are a type which of the following categories?",
        "answer": "Enzymes are catalytic proteins. Enzymes are essential in proper functioning of metabolism because without them chemical traffic throughout the metabolic pathways would become backed up.",
        "suggested_answer": "Catalyst",
        "options": [
            "Energy source",
            "Metabolite",
            "Catalyst",
            "Sugar"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1307,
        "question": "Which is not a method that endotherms may use to regulate their body temperature in a cold environment?",
        "answer": "Vasodilation allows more heat to escape via the blood vessels. Thus, in a cold environment, this would be counterproductive. Vasoconstriction shrinks the blood vessels so that less heat escape. Shivering thermogenesis produces heat by converting ATP to kinetic energy. Thermoregulation is the term describing organisms maintaining their internal body temperature.",
        "suggested_answer": " Vasodilation",
        "options": [
            "Shivering thermogenesis",
            "Thermoregulation",
            "Vasoconstriction ",
            " Vasodilation"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1308,
        "question": "Which of the following characteristics is true regarding follicle development in the ovaries?",
        "answer": "During the follicular phase of the menstrual cycle, follicle-stimulating hormone (FSH) stimulates the development of multiple follicles. The follicles compete with each other and the most developed follicle becomes the dominant follicle. The dominant follicle will release an oocyte during ovulation.",
        "suggested_answer": "There are competing follicles and hormones that promote the development of the dominant follicle ",
        "options": [
            "Only one follicle develops per menstrual cycle ",
            "Genetic damage causes all other follicles to arrest development, allowing the dominant follicle to form",
            "Multiple follicles develop and release oocytes each menstrual cycle ",
            "There are competing follicles and hormones that promote the development of the dominant follicle "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1309,
        "question": "Which of the following types of molecules could potentially be a neurotransmitter?",
        "answer": "All of the choices could potentially be neurotransmitters.",
        "suggested_answer": "I, II, and III",
        "options": [
            "III only",
            "I and III",
            "I, II, and III",
            "I and II"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1310,
        "question": "What is heterochromatin?",
        "answer": "Heterochromatin is dark chromatin that represents DNA that is not active in transcription. The fact that it is dark implies that it is condensed and inaccessible by polymerases. Heterochromatin is created when DNA is tightly wound around histones. This tight winding prevents transcription proteins from interacting with the DNA. Heterochromatin is most common in the nucleus during mitosis, when no transcription is taking place. In contrast, euchromatin is capable of being transcribed and is most common during interphase, when most cellular growth and production occurs.",
        "suggested_answer": "DNA that cannot be transcribed",
        "options": [
            "DNA that is being translated",
            "DNA that is not being translated",
            "DNA that can be transcribed",
            "DNA that cannot be transcribed"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1311,
        "question": "How do steroid hormones elicit a response from their target cells?",
        "answer": "Steroids are nonpolar, which means that they are able to pass easily through cell membranes, but require a transport protein through the hydrophilic blood in order to do so. The steroid hormone then enters the nucleus and attaches to the DNA in order to increase the desired product at the level of transcription.",
        "suggested_answer": "Steroid hormones enter the target cell and alter the products of the cell at the transcription level.",
        "options": [
            "Steroid hormones enter the nucleus of the target cell and increase the creation of ribosomes by the nucleolus. This results in more proteins being made by the target cell.",
            "Steroid hormones move freely throughout the bloodstream and attach to their target cells. This interaction increases ion permeability in the cell.",
            "Steroid hormones attach to a membrane-bound receptor on the cell. This creates an intracellular second messenger which leads to a reaction cascade.",
            "Steroid hormones enter the target cell and alter the products of the cell at the transcription level."
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1312,
        "question": "Synapsis allows which of the following to occur?",
        "answer": "Synapsis is the pairing of homologous chromosomes during prophase I of meiosis. Due to synapsis, pairs of homologous chromosomes are arranged in a way that allows for crossing over to occur, which leads to genetic recombination, and genetic diversity.",
        "suggested_answer": "Crossing over",
        "options": [
            "The shortening of the mitotic spindles",
            "Nuclear membrane dissolution",
            "Crossing over",
            "Chromosome condensation"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1313,
        "question": "What is the major enzyme in saliva that acts on carbohydrates?",
        "answer": "Salivary amylase in the mouth targets large starch molecules and begins the digestion process to a monosaccharide (i.e. glucose). Salivary amylase secretion is stimulated by the recognition of starch on sweet taste receptors, and is carried out by the salivary glands (parotid, submandibular, and sublingual). ",
        "suggested_answer": "Salivary amylase",
        "options": [
            "Salivary trypsin",
            "Lingual amylase",
            "Lingual lipase",
            "Lingual pepsin",
            "Salivary amylase"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1314,
        "question": "Alcohol consumption generally leads to greater urine volume. How does alcohol affect urine concentration in the body?",
        "answer": "Antidiuretic hormone (ADH) is responsible for concentrating the urine. This is accomplished by making the collecting duct permeable to water, and allowing it to passively diffuse into the renal medulla. Alcohol will inhibit the function of ADH, which means that the urine will be less concentrated because water is unable to leave the collecting duct, thus also increasing the volume.",
        "suggested_answer": "Alcohol inhibits ADH, and prevents water from leaving the collecting duct and entering the renal medulla.",
        "options": [
            "Alcohol inhibits ADH, which results in the movement of ions from the collecting duct into the renal medulla.",
            "Alcohol stimulates ADH and causes the urine to become very concentrated.",
            "Alcohol inhibits ADH, and causes water to diffuse into the renal medulla from the collecting duct.",
            "Alcohol stimulates ADH, which causes an increase in renin secretion",
            "Alcohol inhibits ADH, and prevents water from leaving the collecting duct and entering the renal medulla."
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1315,
        "question": "Which of the following best describes the overall impact of humans on the nitrogen cycle?",
        "answer": "Human activities such as chemical fertilizers, industrialization, and pollution significantly increase the amount of nitrogen that is biologically available in an ecosystem.",
        "suggested_answer": "Increase in nitrogen",
        "options": [
            "Increase in nitrogen",
            "Sequestered nitrogen",
            "Depletion of nitrogen",
            "Slight decrease in nitrogen"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1316,
        "question": "Crossing over during prophase occurs during which cycle of division?",
        "answer": "Crossing over ensures genetic variability as it results in daughter cells with different genetic material than their parent cells. This occurs during meiosis I, but is not seen in mitosis or meiosis II.",
        "suggested_answer": "Meiosis I",
        "options": [
            "Mitosis",
            "All answer choices are correct",
            "Meiosis II",
            "Meiosis I"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1317,
        "question": "Which of the following terms is best defined as the tendency of molecules to spread out into available space?",
        "answer": "Diffusion is defined as the spread of molecules into available space. The random nature of the movement of molecules in an open area means that molecules will seek to spread out diffuse evenly within a given area. This means that molecules will evenly distribute themselves when random assortment is permitted within a space.",
        "suggested_answer": "Diffusion",
        "options": [
            "Migration",
            "Molecular motion ",
            "Molecular spread",
            "Diffusion"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1318,
        "question": "Which stomach cell type secretes gastrin?",
        "answer": "G cells secrete the hormone gastrin. Gastrin further stimulates stomach acid secretions via parietal (oxyntic) cells. Chief cells secrete pepsinogen, the inactive form of the enzyme pepsin, which degrades proteins. Goblet cells secrete mucous to protect the cells of the stomach from the acid.",
        "suggested_answer": "G cells",
        "options": [
            "Parietal cells",
            "G cells",
            "Goblet cells",
            "Chief cells",
            "Oxyntic cells"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1319,
        "question": "Which of the following is true regarding red blood cells?",
        "answer": "Red blood cells are made in the bone marrow, and live for about 3-4 months. They are enucleated (lacking a nucleus), which makes more space available for hemoglobin molecules, which function to carry oxygen to the tissues. HIV infects T-helper cells, which are white blood cells, not red blood cells. Thus, the name makes sense since the virus infects cells of the immune system (white blood cells) and causes immunodeficiency. The liver and spleen play roles in recycling the red blood cells once they have carried out their function for about 120 days.",
        "suggested_answer": "They have no nucleus",
        "options": [
            "They live for about 1 month",
            "They produce antibodies",
            "They have no nucleus",
            "They are the target of human immunodeficiency virus (HIV)",
            "They are made by the liver"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1320,
        "question": "Which answer best explains why each primary spermatocyte cell (2n) results in the eventual production of four spermatids (n), while each oogonium cell (2n) results in the eventual production of only one active egg cell (n)?",
        "answer": "Spermatogenesis is a continuous process starting at puberty. Sperm production takes place in the seminiferous tubules. There, each spermatogonium cell (2n) divides equally via mitosis to produce two identical primary spermatocytes (2n). Each primary spermatocyte undergoes meiosis I to produce two equally sized secondary spermatocytes (n). Each secondary spermatocyte then undergoes meiosis II to produce a total of four spermatids (n). The spermatids differentiate before moving to the epididymis.",
        "suggested_answer": "During spermatogenesis, the cellular material is divided evenly during meiosis I and II, resulting in four equally sized spermatids. During oogenesis, both meiosis I and meiosis II involve the uneven distribution of cytoplasm, which results in only one active egg cell and two or three polar bodies.",
        "options": [
            "None of the other answers are correct",
            "During spermatogenesis, the cellular material is divided evenly during meiosis I and II, resulting in four equally sized spermatids. During oogenesis, only one of the two daughter cells that results from meiosis I passes through meiosis II; the other daughter cell remains dormant. Of the daughter cells that result from meiosis II, one becomes an active egg cell while the other disintegrates.",
            "During spermatogenesis, the cellular material is divided evenly during meiosis I and II, resulting in four equally sized spermatids. During oogenesis, both meiosis I and meiosis II involve the uneven distribution of cytoplasm, which results in only one active egg cell and two or three polar bodies.",
            "During spermatogenesis, the cellular material is divided evenly during meiosis I and II, resulting in four equally sized spermatids. During oogenesis, the process of meiosis results in four potential active egg cells of equal size and functionality; arbitrarily, three of these cells are degraded, leaving only active egg cell."
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1321,
        "question": "If the enzyme, aminoacyl-tRNA synthetase malfunctions, which of the following processes will be impeded?",
        "answer": "Aminoacyl-tRNA synthetase is responsible for \"charging\" tRNA with amino acids. During translation, tRNA molecules that are bound to specific amino acids are fed into the ribosome in a specific order that is complementary to the mRNA strand. Once a tRNA is used up, it loses its amino acid. As a result, it must interact with aminoacyl-tRNA synthetase before it can be used again in translation.",
        "suggested_answer": "Translation",
        "options": [
            "Transcription",
            "None of the other answers",
            "Translation",
            "DNA replication",
            "Post-transcriptional RNA processing"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1322,
        "question": "Which is not one of the three domains?",
        "answer": "Domain is the broadest taxonomic category, and includes bacteria, archaea, and eukarya; all living organisms can be classified into one of these three domains. ",
        "suggested_answer": "prokarya",
        "options": [
            "archaea",
            "bacteria",
            "eukarya",
            "prokarya"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1323,
        "question": "Which cellular structure is involved in producing ATP during aerobic respiration?",
        "answer": "The mitochondrion is known as the powerhouse of the cell, because it creates so much of the energy needed for cellular processes. ATP is another source of energy. The mitochondrion would be responsible for producing the mitochondrion. The other structures are responsible for other processes, like storing DNA or photosynthesis.",
        "suggested_answer": "mitochondrion",
        "options": [
            "chloroplast",
            "mitochondrion",
            "nucleus",
            "endoplasmic Reticulum",
            "nucleolus"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1324,
        "question": "How is oxygen carried after entering the blood of the pulmonary capillaries?",
        "answer": "Hemoglobin is the main oxygen carrier in the human body. Each hemoglobin protein is able to carry four oxygen molecules. As the hemoglobin travels through the blood vessels of the body, the oxygen is released to tissues and used in the electron transport chain.",
        "suggested_answer": "Oxygen is carried by hemoglobin",
        "options": [
            "Oxygen is carried by white blood cells",
            "Oxygen is carried by hemoglobin",
            "Oxygen is free floating in the plasma of the blood",
            "Oxygen diffuses through all the cells in the body",
            "Oxygen diffuses into the cell membranes of red blood cells"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1325,
        "question": "Reproduction of chloroplasts and mitochondria occurs via",
        "answer": "Chloroplasts and mitochondria reproduce through fission, the same process through which bacteria reproduce. Eukaryotes reproduce through mitosis or meiosis, depending upon the type of cell.",
        "suggested_answer": "Fission-like process, similar to the reproduction process of bacteria",
        "options": [
            "Fission-like process, similar to the reproduction process of bacteria",
            "Fission-like process, similar to the reproduction process of eukaryotes",
            "Mitosis",
            "Meiosis"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1326,
        "question": "Nucleoids are found in __________.",
        "answer": "Nucleoids are found in prokaryotic cells. The nucleoid is the area of a cell that contains the DNA, and unlike the nucleus of eukaryotic cells, the nucleoid lacks a membrane to separate the DNA from the rest of the cell. The nucleolus is a structure within the nucleus of eukaryotic cells, and is the site of ribosome assembly.",
        "suggested_answer": "prokaryotic cells",
        "options": [
            "the nucleolus",
            "the nucleus",
            "prokaryotic cells",
            "eukaryotic cells"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1327,
        "question": "Mutation, gene flow, nonrandom mating, and natural selection combine to cause which of the following?",
        "answer": "Evolution is any change in the proportions of different genotypes in a population from one generation to the next. Mutation, geneflow, nonrandom mating, and natural selection all contribute toward favoring certain alleles over others within a population. This leads to changes in allele frequency, and subsequent evolution.",
        "suggested_answer": "Evolution",
        "options": [
            "Reproduction",
            "Evolution",
            "Segregation",
            "Mutations",
            "Genetic drift"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1328,
        "question": "Supporting the Endosymbiotic Theory, mitochondria and chloroplasts have a _________ membrane.",
        "answer": "The Endosymbiotic Theory states that the mitochondria and chloroplast in eukaryotic cells were once aerobic bacteria (prokaryote) that were ingested by a large anaerobic bacteria (prokaryote). Mitochondria and chloroplasts have a double membrane (the inner membrane would have initially been the ingested prokaryote\u2019s single membrane).",
        "suggested_answer": "double",
        "options": [
            "thin",
            "single",
            "thick",
            "double"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1329,
        "question": "In which region of the upper digestive tract does food transition into chyme?",
        "answer": "Chyme is formed during the initial breakdown of food. Food is turned into chyme in the stomach due to the combination of smooth muscle contractions and the chemical action of hydrochloric acid. These processes serve to digest the food both mechanically and chemically.",
        "suggested_answer": "The stomach",
        "options": [
            "The mouth",
            "The esophagus",
            "The stomach",
            "The liver",
            "The sublingual salivary glands"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1330,
        "question": "Which of the following does not occur during mitosis?",
        "answer": "Mitosis is the process that results in two identical daughter cells. The separation of sister chromatids is essential to ensure that both daughter cells receive a copy of each chromosome. The maintenance of ploidy is a way of describing that the daughter cells will have the same number of each chromosome as the parent cells.",
        "suggested_answer": "Crossing over",
        "options": [
            "Maintenance of ploidy ",
            "Crossing over",
            "Formation of two identical daughter cells",
            "Separation of the sister chromatids"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1331,
        "question": "A reaction between an alpha-carboxylic acid and an alpha-amino group creates a peptide bond. Which of the following describes this process?",
        "answer": "Hydrolysis reactions involve breakdown of molecules (lysis) in the presence of water. Water is a reactant in hydrolysis reactions. Dehydration synthesis reactions involve formation of bonds between molecules (synthesis) and removal of water at the end of the reaction (dehydration). Water is a product in dehydration synthesis reactions.",
        "suggested_answer": "Dehydration synthesis",
        "options": [
            "Dehydration synthesis",
            "Hydrolysis",
            "Esterification",
            "Hydrogenation"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1332,
        "question": "How is protein primary structure formed?",
        "answer": "Peptide bonds form between the amine group of one amino acid and the carboxylic acid of another via a covalent linkage. The formation of a polypeptide chain from amino acid residues constitutes the protein primary structure.",
        "suggested_answer": "Peptide bonds between the amine group of one amino acid and the carboxylic acid group of another",
        "options": [
            "Hydrogen bonds between the amine group of one amino acid and the side chain of another",
            "Peptide bonds between the amine group of one amino acid and the carboxylic acid group of another",
            "Peptide bonds between side chains (R-groups)",
            "Hydrogen bonding between the hydrogen of one amino acid and the carboxylic acid of another"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1333,
        "question": "The spleen is an organ of the __________.",
        "answer": "The spleen plays a key role in the immune system as one of the blood filtration centers of the body. It is also involved as a lymphatic organ, allowing filtrates from the blood to be distributed to the body via lymph.",
        "suggested_answer": "immune system",
        "options": [
            "immune system",
            "respiratory system",
            "digestive system",
            "circulatory system"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1334,
        "question": "Carnivores can occupy which of the following places in the food chain?",
        "answer": "Carnivores are organisms that consume meat animal tissue as a part of their diet; therefore, on the food chain, carnivores may occupy the secondary, tertiary, or quaternary consumer levels.",
        "suggested_answer": "Secondary consumer",
        "options": [
            "Decomposer",
            "Primary consumer",
            "Primary producer",
            "Secondary consumer"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1335,
        "question": "Which is true of ADP ",
        "answer": "When ATP is hydrolyzed and a phosphoanhydride bond is broken, ADP and a phosphate are the products. ADP then has 2 phosphate groups.",
        "suggested_answer": "It contains 2 phosphate groups",
        "options": [
            "It contains 2 phosphate groups",
            "ATP is produced when ADP is broken down",
            "It contains 3 phosphate groups",
            "It contains more energy than ATP"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1336,
        "question": "List the phases of mitosis in order from first to last.",
        "answer": "Mitosis is the period of division for somatic cells and results in two identical copies of the parent cell. In contrast, meiosis is the formation of unique gametes from germ cells. Mitosis is broken into prophase, metaphase, anaphase, and telophase. Meiosis is broken into meiosis I and meiosis II, each of which contains all four phases of division.",
        "suggested_answer": "Prophase, metaphase, anaphase, telophase",
        "options": [
            "Prophase I, metaphase I, anaphase I, telophase I",
            "Prophase, anaphase, metaphase, telophase",
            "Prophase, metaphase, anaphase, telophase",
            "Prophase II, metaphase II, anaphase II, telophase II",
            "Anaphase, prophase, metaphase, telophase"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1337,
        "question": "What might be an unintended consequence of excess stimulation of hematopoietic stem cells? ",
        "answer": "One of the purposes of stimulating hematopoietic stem cells is to to increase red blood cells production. However, excess red blood cells in the blood will increase the viscosity of the blood. With an increase in viscosity, the blood will not flow as fluid and will be prone to clots. ",
        "suggested_answer": "Blood clot",
        "options": [
            "Blood clot",
            "Decrease hematocrit ",
            "None of these",
            "Decrease red blood cell count",
            "Decrease white blood cell count"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1338,
        "question": "Which leukocyte releases histamine in order to dilate blood vessels and increase blood flow to infected areas?",
        "answer": "Basophils are the least common leukocyte found in the body, but play a key role in the inflammatory response. They contain histamine, which is a potent vasodilator. Upon release, histamine will increase blood flow to infected areas. Mast cells are another immune cell that is involved in histamine release, but are generally localized to various regions of the body rather than found in circulation.",
        "suggested_answer": "Basophils",
        "options": [
            "Neutrophils",
            "Plasma cells",
            "Basophils",
            "Eosinophils"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1339,
        "question": "Which of the following statements about binary ionic compounds is correct?",
        "answer": "In binary ionic compounds, the cation is always written before the anion in the name and formula. This means that metal elements are typically followed by non-metal elements in ionic compounds. Examples are sodium chloride and calcium phosphide.",
        "suggested_answer": "The cation is written before the anion in the name and formula",
        "options": [
            "The anion is written before the cation in the name and formula",
            "The prefix mono-is only used in front of the second element in the name",
            "The prefix mono- is used when any element in the compound has no subscript",
            "The cation is written before the anion in the name and formula",
            "They can contain polyatomic ions, such as the chlorate ion"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1340,
        "question": "Which of the following is the shortest part of the cell cycle?",
        "answer": "Mitosis is the shortest part of the cell cycle. The cell spends most of its time growing in interphase which includes G1, S, and G2 phases. G0 phase involves cells that either very rarely divide, or do not divide at all. An example of a cell type in G0 phase is a neuron.",
        "suggested_answer": "Mitosis",
        "options": [
            "Interphase",
            "G1 phase",
            "Mitosis",
            "S phase",
            "G0 phase"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1341,
        "question": "Which of the following best explains how atmospheric nitrogen enters the nitrogen cycle in marine ecosystems?",
        "answer": "The marine nitrogen cycle is very similar to the terrestrial nitrogen cycle. In marine ecosystems, atmospheric nitrogen enters the cycle through precipitation.",
        "suggested_answer": "Through precipitation",
        "options": [
            "Through plant decomposition",
            "Through precipitation",
            "Through algae waste",
            "Through animal excretions"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1342,
        "question": "Prokaryotic genes are often grouped together based on function. What are these groups of genes called?",
        "answer": "Bacteria organize some of their genes into operons. Operons contain genes of a similar function grouped together, and these genes are all transcribed together. For example, the lac operon involves the three genes required for breaking down lactose. There is no point in only transcribing one or two of the three genes since they are all required to break down lactose. Thus, they are under the control of a single operator and are all transcribed when the operator is active.",
        "suggested_answer": "Operons",
        "options": [
            "Promoter sequences",
            "Linked genes",
            "Operator sequences",
            "Operons",
            "Clusters"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1343,
        "question": "Which of the following best describes the difficulties associated with the study of phylogenetics?",
        "answer": "Phylogenetics has several difficulties that complicate the development of accurate phylogenetic trees. Relationships between organisms on a genetic level are incredibly complicated, making it difficult for scientist to create clean and precise phylogenetic trees. These difficulties include hybridization, horizontal gene transfer, and convergent evolution.",
        "suggested_answer": "Horizontal gene transfer and hybridization",
        "options": [
            "Hybridization",
            "Horizontal gene transfer",
            "Horizontal gene transfer and hybridization",
            "Neither horizontal gene transfer nor hybridization"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1344,
        "question": "Which of the following is a quality that distinguishes angiosperms from other plants?",
        "answer": "The production of a true flower is one of the defining characteristics of an angiosperm. In fact, angiosperms are also called \"flowering plants.\" Groups of plants that are not angiosperms (think ferns, ginkgos, or pine trees) can have roots and spores and are able to survive in aquatic environments and reproduce vegetative.",
        "suggested_answer": "Angiosperms have true flowers",
        "options": [
            "Angiosperms have true roots",
            "Angiosperms are able to reproduce vegetatively",
            "Angiosperms are able to survive in aquatic environments",
            "Angiosperms have true flowers",
            "Angiosperms produce spores"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1345,
        "question": "Bile salts make fat droplets more accessible to the enzyme __________.",
        "answer": "Once large fat clusters are broken down by bile salts to become smaller micelles, pancreatic lipase has more surface area to access fats. The enzymes are able to reach the fat droplet through the gaps between bile salts. Fats are not emulsified by bile yet in the mouth, so lingual lipase acts upstream of bile to hydrolize long chain hydrocarbons. The other answer choices are proteases. ",
        "suggested_answer": "pancreatic lipase",
        "options": [
            "lingual lipase",
            "chymotrypsin",
            "pepsin",
            "trypsin",
            "pancreatic lipase"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1346,
        "question": "Which of the following correctly pairs each kind of RNA with its function?",
        "answer": "The three types of RNA discussed are messenger RNA (mRNA), transfer RNA (tRNA), and ribosomal RNA (rRNA). mRNA forms during transcription when RNA polymerase synthesizes RNA from the DNA template. Post-transcriptional modification is required for the mRNA to mature and exit the nucleus. Once in the cytoplasm, the mRNA will bind to a ribosome composed of rRNA and proteins. The ribosome will then recruit tRNA molecules to the complex in order to synthesize the protein product. Each amino acid binds to a specific kind of tRNA. tRNA brings the amino acids to the growing end of the newly forming polypeptide at the ribosome by binding to the codon of the mRNA.",
        "suggested_answer": "mRNA carries genetic information from DNA",
        "options": [
            "mRNA carries amino acids to ribosomes",
            "tRNA associates with proteins to form the ribosome",
            "rRNA carries genetic information from DNA",
            "mRNA carries genetic information from DNA",
            "tRNA carries proteins to ribosomes",
            "rRNA associates with proteins to form the ribosome",
            "mRNA carries proteins to ribosomes",
            "tRNA carries amino acids to ribosomes",
            "rRNA associates with proteins to form the ribosome ",
            "mRNA carries amino acids to ribosomes",
            "tRNA carries genetic information from DNA",
            "rRNA associates with proteins to form the ribosome",
            "mRNA carries genetic information from DNA",
            "tRNA carries amino acids to ribosomes",
            "rRNA associates with proteins to form the ribosome "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1347,
        "question": "Which of the following is true regarding simple epithelia?",
        "answer": "Simple epithelia are epithelial tissue that is only one cell layer thick; therefore, each cell has direct contact with the basement membrane. All epithelial cell morphologies can be found in simple epithelia.",
        "suggested_answer": "Simple epithelia are only one cell layer thick",
        "options": [
            "Only cuboidal cells can be present in simple epithelia",
            "Simple epithelia are only one cell layer thick",
            "Not all cells have contact with the basement membrane",
            "Simple epithelia change morphology based on tension"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1348,
        "question": "In animal cells, the process of cytokinesis occurs due to which of the following factors?",
        "answer": "Cytokinesis begins shortly after anaphase, when a contractile ring assembles near the equator of the cell. A cleavage furrow is formed and eventually, cytokinesis is completed. Actin filaments are capable of contraction, while tubulin polymers (microtubules) are generally used for cell structure. Microtubules form the spindle fibers used to separate sister chromatids during anaphase, but do not play a significant role in cytokinesis.",
        "suggested_answer": "Actin filaments contracting around the circumference of the cell",
        "options": [
            "The sliding of microtubules around the circumference of the cell",
            "The movement of spindle fibers",
            "Actin filaments contracting around the circumference of the cell",
            "The sliding of microtubules near the center of the cell",
            "The fusion of vesicles near the center of the cell"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1349,
        "question": "What is the final electron acceptor in the electron transport chain?",
        "answer": "Electrons from electron carriers, such as NADH and FADH2, go through the electron transport chain, which involves a series of molecules that accept and donate electrons. Transfer to the electron through these proteins results in the net movement of protons across the inner mitochondrial membrane and into the intermembrane space, generating the proton gradient that will drive ATP synthase.",
        "suggested_answer": "Oxygen",
        "options": [
            "Oxygen",
            "Water",
            "NADH",
            "CO2"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1350,
        "question": "Which of the following describes enzymes?",
        "answer": "Enzymes are catalysts that function to increase the rate of a reaction. All enzymes are proteins and they are never consumed in a reaction. Another form of biological catalyst is ribozymes, which are made of protein and RNA, but are not classified as enzymes.",
        "suggested_answer": "Catalytic proteins",
        "options": [
            "Anabolic lipids",
            "Anabolic proteins",
            "Catalytic proteins",
            "Degradative nucleic acids"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1351,
        "question": "The nephron is the functional unit of the mammalian kidney. Which of the following structures is not considered part of the nephron?",
        "answer": "The function of the kidney is to filter wastes out of the blood and concentrate them into a filtrate that can be excreted from the body. Nephrons are the functional unit of the excretory system, meaning that each nephron is capable of concentrating wastes into filtrate. Each nephron is made of a single long tubule, with different regions modified to transport different ions and wastes into or out of the filtrate. The proximal convoluted tubule, the loop of Henle, and the distal convoluted tubule are the principle regions of the nephron.",
        "suggested_answer": "Glomerulus",
        "options": [
            "Glomerulus",
            "Proximal tubule",
            "Distal tubule",
            "All of these structures are part of the nephron",
            "Loop of Henle"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1352,
        "question": "What is the composition of the contractile ring that forms during cytokinesis?",
        "answer": "During cytokinesis, the cell physically divides into two daughter cells. In animals, this is done through the formation of a contractile ring, composed of myosin and actin filaments, that forms at the cell equator. The filaments contract to form a cleavage furrow, where the cell membrane begins to invaginate and eventually pinches off to form two daughter cells. Note that the myosin found in the contractile ring in different from that in muscle cells. Also note that in animals cells, cytokinesis involves the formation of a cell plate, rather than a contractile ring.",
        "suggested_answer": "Myosin and actin",
        "options": [
            "Actin only",
            "Myosin, actin, and kinesin",
            "Myosin only",
            "Myosin and actin"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1353,
        "question": "The cell cycle has many checkpoints to ensure the cell is ready for division. Which of the following can occur if a cell skips cell cycle checkpoints?",
        "answer": "The checkpoints in the cell cycle are necessary to make sure the cell is ready to divide. Certain checkpoints will check the size of the cell, the integrity of the DNA, and the proper attachment of spindle fibers. If a cell fails to use these checkpoints, it can continue to divide even when it is not ready.",
        "suggested_answer": "The cell can become cancerous",
        "options": [
            "The cell can become cancerous",
            "The cell can have inhibited growth",
            "The cell can grow in size, but never divide",
            "The cell can divide into three daughter cells instead of two",
            "None of these could occur"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1354,
        "question": "A scientist is interested in studying the event of crossing over (recombination) in mouse gametes. She wants to label some of the mouse DNA with fluorescent dyes to better visualize it. During what phase of the cell cycle should she look at these fluorescent DNA strands in order to most effectively study recombination?",
        "answer": "Recombination or crossing over happens primarily in Prophase I. In anaphase and telophase, the DNA strands are separated and cannot recombine. In metaphase, homologous chromosomes line up with each other, but do not recombine.",
        "suggested_answer": "Prophase I",
        "options": [
            "Anaphase I",
            "Metaphase II",
            "Telophase I",
            "Anaphase II",
            "Prophase I"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1355,
        "question": "During the light dependent reaction, the proton gradient is established. Where is the concentration of protons the greatest?",
        "answer": "During the light dependent reaction, protons are pumped from the thylakoid stroma into the lumen. Then, these protons flow down their electrochemical gradient (from high concentration to low concentration), through the ATP synthase protein, producing ATP.",
        "suggested_answer": "thylakoid lumen",
        "options": [
            "thylakoid membrane",
            "intermembrane space",
            "thylakoid lumen",
            "thylakoid stroma"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1356,
        "question": "Mitosis and meiosis differ in that mitosis produces __________ cells with __________ genetic material, whereas meiosis produces __________ cells with __________ genetic material.",
        "answer": "During mitosis cells divide a single time and retain the exact same genetic material, producing two identical copies of the parent cell. During meiosis, cells divide twice and cross over during anaphase I. This produces a unique combination of chromosomes (recombinants) not seen in the parent cell.",
        "suggested_answer": "2 . . . identical . . . 4 . . . a unique mixture of",
        "options": [
            "2 . . . identical . . . 4 . . . a unique mixture of",
            "2 . . . identical . . . 4 . . . identical",
            "4 . . . a unique mixture of . . . 2 . . . identical",
            "2 . . . a unique mixture of . . . 2 . . . identical"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1357,
        "question": "In a certain food web, rabbits and turtles both feed off of grass. To what trophic level do the rabbits belong?",
        "answer": "Producers are always the lowest level of a trophic hierarchy, and consist of the autotrophs in the system. Organisms that feed off of producers are considered primary consumers.",
        "suggested_answer": "Primary consumer",
        "options": [
            "Mid-level consumer",
            "Prey",
            "Primary consumer",
            "Secondary consumer"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1358,
        "question": "Small populations are especially at risk when a bottleneck occurs, caused by the greater effect __________ can have on small populations.",
        "answer": "Small populations tend to have less genetic variation to begin with. Introducing a bottleneck effect further reduces variation and population size, amplifying the effect of genetic drift. This leaves them susceptible to changes in the environment that they may not be capable of adapting to due to limited differences among individuals.",
        "suggested_answer": "genetic drift",
        "options": [
            "migrations",
            "mutations",
            "genetic drift",
            "natural selection"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1359,
        "question": "Leukocytes are classified as either granulocytes or agranulocytes. Which of the following are granulocytes?",
        "answer": "Leukocytes can be broken down into the following categories and cells types.",
        "suggested_answer": " Basophils, eosinophils, and neutrophils",
        "options": [
            " Eosinophils and basophils",
            " Macrophages and lymphocytes",
            " Basophils, eosinophils, and neutrophils",
            " Basophils",
            " T-cells and B-cells"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1360,
        "question": "Which of the following is the correct order for an ecosystem, from smallest to largest?",
        "answer": "This question requires careful reading of the correct order of an ecosystem on earth. The correct order, from smallest to largest, for an ecosystem is individual, population, community, ecosystem. Individual organisms make up populations, which interact with other populations in communities, which in turn belong to ecosystems that have a particular environment, such as desert or rainforest.",
        "suggested_answer": "Individual, population, community, and ecosystem",
        "options": [
            "Ecosystem, individual, community, and population",
            "Community, individual, population, and ecosystem",
            "Individual, population, community, and ecosystem",
            "Population, individual, community, and ecosystem"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1361,
        "question": "Which portion of the spinal cord sends sensory information to the brain?",
        "answer": "The spinal cord consists of functional horns that help send information to the brain, as well as to the parts of the body. The dorsal horns send sensory information to the brain, while the ventral horns contain motor neurons.",
        "suggested_answer": "Dorsal horn",
        "options": [
            "Dorsal horn",
            "Central canal",
            "Lateral horn",
            "Ventral horn"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1362,
        "question": "Which is not an example of symbiosis?",
        "answer": "Symbiosis is when two organisms live in close proximity and usually benefit each other, such as in mutualism. However, this is not always the case. Commensalism is a type of symbiosis and only benefits one of the organisms or has neutral effects. Parasitism is another form of symbiosis, where one organism benefits at the expense of its host. ",
        "suggested_answer": "One organism preys on another organism ",
        "options": [
            "Two organisms live in close proximity and benefit each other",
            "Two organisms live in close proximity and one organism benefits while the other does not",
            "A parasite gets nourishment from its host organism ",
            "One organism preys on another organism ",
            "Two organism live intimately but only one organism benefits "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1363,
        "question": "Which of the following is true regarding the fluid mosaic model?",
        "answer": "The fluid mosaic model is used to explain the fluidity of the plasma membrane. This allows proteins to move along and within the membrane structure and promotes cell motility and flexibility. The fluid mosaic model is based on the idea of the hydrophilic heads facing the exterior of the membrane and hydrophobic tails facing the interior. Transmembrane proteins contain hydrophilic and hydrophobic regions in the same orientation, while peripheral proteins are able to adhere to the membrane exterior. Cholesterol is found within the hydrophobic region of the membrane and promotes fluidity and movement by preventing fatty acid tails from adhering to one another.",
        "suggested_answer": "The lipid bilayer contains hydrophilic heads facing outward and hydrophobic tails facing inward",
        "options": [
            "The lipid bilayer contains hydrophilic heads facing outward and hydrophobic tails facing inward",
            "There are only peripheral proteins present in the cell membrane",
            "There is no cholesterol present in the cell membrane",
            "There are only transmembrane proteins present in the cell membrane",
            "The lipid bilayer contains hydrophilic heads facing inward and hydrophobic tails facing outward"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1364,
        "question": "Which of the following is not a product formed during the citric acid cycle?",
        "answer": "NAD+ and FADH are used as reactants in the citric acid cycle to make NADH and FADH2, which are used in the electron transport chain to convert additional ADP into ATP. All of the other selections are products in the citric acid cyclce. Protons (H+) are a byproduct when NAD+ is converted to NADH. Carbon dioxide (CO2) is produced during carbohydrate conversions in the cycle. One GTP molecule is produced by the cycle, and contains almost equivalent energy to ATP.",
        "suggested_answer": "NAD+",
        "options": [
            "H+",
            "GTP",
            "FADH2",
            "CO2",
            "NAD+"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1365,
        "question": "Which of the following is evidence of a common ancestor?",
        "answer": "It is believed that all organisms are descended from a common ancestor. Evidence of this includes morphological similarities, vestigial characteristics, and observations made within the fossil record. Recently, phylogenetic trees and cladograms are made using DNA sequence analysis to determine the similarity in the genomes of organisms. ",
        "suggested_answer": "All of these",
        "options": [
            "Morphological similarities",
            "Vestigial characteristics",
            "All of these",
            "Fossil record"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1366,
        "question": "Which of the following is true of amino acids, but not of carbohydrates?",
        "answer": "Amino acids get the first half of their names from the presence of an amine group , which contains the nitrogen atom. All of the other answer choices are true for both amino acids and carbohydrates.",
        "suggested_answer": "The presence of nitrogen atoms",
        "options": [
            "The presence of nitrogen atoms",
            "The presence of carbon atoms",
            "They are linked together by covalent bonds through dehydration synthesis",
            "The presence of oxygen atoms",
            "The presence of hydrogen atoms"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1367,
        "question": "If an antibiotic binds the active site of an enzyme but does not change the structure of that enzyme, once removed, the enzyme returns to normal function. In this case, the antibiotic is acting via what enzyme interaction?",
        "answer": "Competitive inhibition occurs when an substrate or inhibitor compete with the normal substrate for binding the active sight of an enzyme. The proper functioning of the enzyme depends on the concentration ratio of inhibitor to enzyme or substrate to enzyme. The competitive inhibition of the enzyme in this case by the antibiotic has potentially bactericidal or bacteriostatic effect on the bacteria until that antibiotic concentration decreases. Negative feedback involves the product of a set of metabolic reactions inhibiting the formation of a precursor of that metabolic pathway, thereby decreasing its own production.",
        "suggested_answer": "Competitive inhibition",
        "options": [
            "Noncompetitive inhibition",
            "Positive feedback",
            "Negative feedback",
            "Competitive inhibition",
            "Denaturation"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1368,
        "question": "Meiosis in animals occurs in __________.",
        "answer": "Meiosis is the form of cell division that results in gametes and so meiosis takes place in both ovaries and testes, which are the primary sex organs. Somatic cells undergo mitosis for cell division, which yields identical daughter cells that are diploid. ",
        "suggested_answer": "both ovaries and testes",
        "options": [
            "both ovaries and testes",
            "all cells in the animal's body",
            "ovaries only",
            "testes only",
            "all somatic cells"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1369,
        "question": "Which enzyme is important in the initiation stage of DNA replication?",
        "answer": "In the initiation stage of DNA replication, a number of enzymes are involved. These include the initiator proteins in the pre-replication complex, DNA helicase, single stranded binding proteins, and topoisomerase. Topoisomerase is an enzyme that helps relieve winding and unwinding tension in DNA that arise from the helical structure of the DNA molecule. The DNA ahead of the replication fork often becomes tangled and/or supercoiled. Topoisomerase cuts the DNA to relieve the stress and allow the DNA to relax by unwinding a few times.Later during the replication process, the DNA rewinds and these breaks are resealed.",
        "suggested_answer": "Topoisomerase",
        "options": [
            "Telomerase",
            "DNA primase",
            "Topoisomerase",
            "DNA ligase"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1370,
        "question": "A certain grassland ecosystem consists of grasses that grow on the plains, mice that eat the grains the grasses produce, weasels that eat both the grass and the mice, and hawks that eat the weasels.",
        "answer": "Trophic energy levels show reduced biomass as you go up a food chain from producer, to consumer, to secondary consumer, and so on due to the loss of energy as heat. The hawks, as a secondary consumer, will have the smallest amount of proportional biomass compared to the other species.",
        "suggested_answer": "The hawks",
        "options": [
            "The grass",
            "The bacteria in the soil",
            "The hawks",
            "The mice",
            "The weasels"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1371,
        "question": "Why are most cells microscopic in size?",
        "answer": "Cells obtain all of their nutrients across their plasma membranes. To ensure cellular function it is essential to maximize surface area, which corresponds to the amount of plasma membrane. This creates a large area available for diffusion of the necessary nutrients. While it may be more efficient for an organism to produce a few large cells instead of many small cells, the advantage of a large area for diffusion outweighs the cost of energy. Essentially, the surface area is the region of nutrient source and the volume (cytoplasm) is the region of nutrient consumption. For the cell to survive, it must maximize the amount of source nutrients and minimize the amount of nutrient consumption.",
        "suggested_answer": "The ratio of cell surface area to volume is maximized",
        "options": [
            "Smaller cells require fewer nutrients, which increases their probability for survival",
            "More cells are compacted into an organ when maintaining a reasonable size",
            "The amount of plasma membrane is minimized relative to the more stable cytoplasm",
            "The ratio of cell surface area to volume is maximized",
            "Smaller cells allow unicellular organisms to be undetected by predators"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1372,
        "question": "If a male is heterozygous for the dimples allele and marries a female who does not possess the allele needed for dimples, what is the chance their child will have dimples? Dimples are an autosomal dominant trait.",
        "answer": "By creating a Punnett square in which the male carries the label Dd and the female has the label dd, one can see the possible combinations of alleles that the child may have. D will symbolize the allele for dimples, and d will symbolize the allele for no dimples. The cross will be Dd x dd.",
        "suggested_answer": "50%",
        "options": [
            "25%",
            "0%",
            "50%",
            "100%"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1373,
        "question": "What does not occur in the presynaptic terminal?",
        "answer": "In the presynaptic terminal, the action potential causes an influx of calcium. Rising calcium levels stimulates exocytosis of neurotransmitters, which bind to post-synaptic receptors. Unused neurotransmitters are recycled by the pre-synaptic cell or degraded in the synaptic cleft.",
        "suggested_answer": "Binding of neurotransmitters to receptors ",
        "options": [
            "Exocytosis of neurotransmitters",
            "Calcium influx",
            "Recycling of unused neurotransmitters",
            "Binding of neurotransmitters to receptors "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1374,
        "question": "Darwin's Theory of evolution by natural selection is based on all of the following postulates except __________.",
        "answer": "According to Darwin's theory, the survival and reproduction of individuals is not random. Survival and reproduction is tied to the variations among individuals. Those with the most favorable variations are more fit; therefore, they are naturally selected.",
        "suggested_answer": "the survival and reproduction of individuals is random",
        "options": [
            "individuals within a population are variable",
            "at least in part, variations among individuals are passed on from parents to offspring",
            "the survival and reproduction of individuals is random",
            "some individuals are more successful in surviving and reproduction than others",
            "the survival and reproduction of individuals is not random"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1375,
        "question": "In a population of flowers, red is dominant to white. A true-breeding white flower is crossed with a heterozygous flower. Determine the expected ratios of this cross. Given observed values: 63 white flowers, 37 red flowers, determine:",
        "answer": "First, determine the expected ratios using a Punnet Square. Given that red is dominant to white, the genotype of a true-breeding white flower can be denoted rr, and the genotype of a heterozygous flower can be denoted Rr. This cross will produce 50% Rr, 50% rr. Then, converting these percentages to decimals (50%= 0.50), and multiplying by the total population size of the observed population (0.50*100), gives expected values of 50 Rr (red flowers) and 50 rr (white flowers). These expected values and the observed values can then be plugged into the chi square equation . The equation will be . Degrees of freedom is n-1, so 2-1= 1. To determine p-value, use critical values table. The chi square value of 6.76 with 1 degrees of freedom will fall between a critical value corresponding with a p-value of 0.05 and 0.025. Thus, p>0.025",
        "suggested_answer": "1) 6.76  ",
        "options": [
            "1) 6.76  ",
            "2) 1  ",
            "3) p>0.025",
            "1) 3.35  ",
            "2) 4  ",
            "3) p=0.25",
            "1) 14.44  ",
            "2) 2  ",
            "3) p>0.005",
            "1) 6.76  ",
            "2) 2  ",
            "3) p=0.05"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1376,
        "question": "Which of the following is not considered a lipid?",
        "answer": "There are several types of lipids, including fatty acids, phospholipids, sphingolipids, and sterol lipids as well as many more.",
        "suggested_answer": "Glucose",
        "options": [
            "Triglyceride",
            "Steroids ",
            "Phospholipids",
            "Cholesterol",
            "Glucose"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1377,
        "question": "In humans, fertilization involves the addition of chromosomes from the sperm and the egg. The resulting cell is called a __________.",
        "answer": "The fusion of haploid gametes (sperm and egg) produces a zygote. The addition of the chromosomes from sperm and egg restores the diploid state of the cell and gives rise to a diploid organism.",
        "suggested_answer": "zygote",
        "options": [
            "blastocyst",
            "zygote",
            "gamete",
            "embryo"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1378,
        "question": "Which of the following does not happen during the termination phase of transcription?",
        "answer": "Termination is the final stage of transcription. During this stage, RNA polymerase reaches a terminator signal in the template DNA strand. This triggers the release of the transcriptional complex and the cleavage or release of the RNA transcript. Post cleavage, a sequence of adenines is added to the 3\u2019 end of the new transcript through a process called polyadenylation. The resulting poly-A tail is important in translation, stability, and export of the transcript.",
        "suggested_answer": "Final proofreading by RNA polymerase ",
        "options": [
            "Final proofreading by RNA polymerase ",
            "The new transcript is cleaved ",
            "The addition of adenine nucleotides to the 3' end of the new transcript",
            "RNA polymerase reaches a terminator signal in the DNA template strand"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1379,
        "question": "Which of the following is not a function of the sympathetic nervous system?",
        "answer": "The sympathetic nervous system is responsible for regulating the body's \"fight-or-flight\" response. Physiological changes produced by the sympathetic nervous system include accelerated heartbeat, dilated bronchi and pupils, glucose breakdown, and inhibited digestion. As a result, when the sympathetic nervous system is active, digestion and its associated involuntary contractions are inhibited not stimulated.",
        "suggested_answer": "Stimulation of digestion/peristalsis",
        "options": [
            "Breakdown of glycogen to glucose",
            "Dilation of bronchi",
            "Dilation of pupils",
            "Acceleration of heartbeat",
            "Stimulation of digestion/peristalsis"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1380,
        "question": "Which two protein structure takes advantage of hydrogen bonding, ionic bonds, hydrophobic interactions, and van der Waals interactions?",
        "answer": "Primary structure takes advantage of covalent bonding to form a peptide bond between amino acids. Secondary structure takes advantage of hydrogen bonding to form alpha helices and beta-pleated sheets. The tertiary structure takes advantage of all these forms of bonding and interactions to fold into the overall shape of the protein and the quaternary structure is formed by using these interactions to connect multiple polypeptides.",
        "suggested_answer": "Quaternary Structure and Tertiary Structure",
        "options": [
            "Primary Structure and Quaternary Structure ",
            "Secondary Structure and Tertiary Structure",
            "Quaternary Structure and Tertiary Structure",
            "Tertiary Structure and Primary Structure",
            "Primary Structure and Secondary Structure"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1381,
        "question": "Which of the following choices best describes the chemical change that occurs when kinases bind to substrates?",
        "answer": "Kinases are enzymes that phosphorylate the substrates they bind to, meaning that the kinase transfers a phosphate group to the substrate. ATP donates the phosphate group used in this process. Phosphorylation by kinases can lead to a variety of effects including: activation, inhibition, stabilization, destabilization, and localization. Kinases are very important in cellular activities and metabolic processes. ",
        "suggested_answer": "Phosphorylation",
        "options": [
            "Acetylation",
            "Methylation",
            "Adenylation",
            "Phosphorylation"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1382,
        "question": "The energetic hypothesis states that __________.",
        "answer": "The energetic hypothesis states that the length of a food chain is limited by the inefficiency of energy transfer along the chain. Only ten percent of energy stored in organic matter at each trophic level is actually converted to organic matter at the next trophic level. This keeps trophic structures in check and limits the abundance of predatory organisms at the top of the trophic structure. ",
        "suggested_answer": "The length of a food chain is limited by insufficient energy transfer through the food chain ",
        "options": [
            "Several food chains linked together create food webs",
            "The abundance of organisms at higher trophic levels are supported by lower trophic energy ",
            "Biomass increases with energy and trophic level",
            "The lower trophic levels provide sufficient energy for higher trophic levels",
            "The length of a food chain is limited by insufficient energy transfer through the food chain "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1383,
        "question": "Which of the following is contained in the carpel?",
        "answer": "The carpel is a part of the female angiosperm reproductive system. The carpel includes the ovary, ovules, style, and stigma. Flowers may have several carpels clustered into a pistil.",
        "suggested_answer": "Ovules",
        "options": [
            "Flower",
            "Ovules",
            "Stamen",
            "Anther"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1384,
        "question": "Ganglia are a part of which of the following subunits of the nervous system?",
        "answer": "Ganglia are clusters of nerve cells that are a part of the autonomic nervous system, which controls the function of organs.",
        "suggested_answer": "Autonomic nervous system",
        "options": [
            "All of these",
            "Somatic nervous system",
            "Central nervous system",
            "Autonomic nervous system"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1385,
        "question": "In eukaryotes the organelles that convert energy to forms the cells can use for work are __________.",
        "answer": "Both mitochondria and chloroplasts convert energy to work in eukaryotes. Mitochondria are the sites for the catabolic process that creates ATP in animals. In plants, chloroplasts are the sites of photosynthesis where solar energy is converted to chemical energy. ",
        "suggested_answer": "both mitochondria and chloroplasts",
        "options": [
            "mitochondria only",
            "chloroplasts only",
            "both mitochondria and chloroplasts",
            "rough endoplasmic reticulum only"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1386,
        "question": "Which of the following statements about blood vessels is true?",
        "answer": "Arteries have thick, muscular walls that allow for constriction and flow direction, while veins have thin walls to carry blood.",
        "suggested_answer": "Arteries have thicker walls than veins, which have thicker walls than capillaries",
        "options": [
            "Pressure in veins is always higher than it is in arteries",
            "Capillaries contain oxygenated blood only",
            "Arteries have thicker walls than veins, which have thicker walls than capillaries",
            "Arteries contain valves to prevent backflow of blood"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1387,
        "question": "A white homozygous recessive plant is crossed with a homozygous dominant red plant. The plants produced from this cross are all pink. What kind of inheritance does this plant's color follow?",
        "answer": "In incomplete dominance, heterozygotes express an intermediate phenotype. Since neither parent expresses the pink phenotype, we know that plant color follows incomplete dominance since neither red nor white is fully expressed as would be the case with complete dominance; rather they are both incompletely expressed.",
        "suggested_answer": "Incomplete dominance",
        "options": [
            "Multiple alleles",
            "Complete dominance",
            "Incomplete dominance",
            "Sex-linked dominance",
            "Codominance"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1388,
        "question": "This model of enzyme and substrate interaction posits that the active site of the enzyme undergoes conformational change when the correct substrate binds",
        "answer": "The lock and key model states that the active site of an enzyme precisely fits a specific substrate. The induced fit model states that the active site of an enzyme will undergo a conformational change when binding a substrate, to improve the fit.",
        "suggested_answer": "Induced fit",
        "options": [
            "Lock and key model",
            "Enzyme substrate model",
            "Conformation model",
            "Induced fit"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1389,
        "question": "What is the name for a genome that contains an abnormal number of chromosomes?",
        "answer": "If cell division occurs incorrectly, and the resulting cell contains an abnormal number of chromosomes, that cell is considered to be aneuploid. Polyploidy is the presence of an entire extra copy of the genome in a cell. Euploid (true ploid) refers to a cell with the correct number of chromosomes.",
        "suggested_answer": "Aneuploid",
        "options": [
            "Aneuploid",
            "Inversion",
            "Euploid",
            "Translocation",
            "Duplication"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1390,
        "question": "Which of the following statements about axons is true?",
        "answer": "The axon carries action potentials away from the cell body of a neuron via a sequence of continuous depolarization. The cell body, or soma, however, is the site of neurotransmitter production and the location of the nucleus and other organelles. Every single neuron contains only one axon.",
        "suggested_answer": "The axon carries action potentials away from the cell body of a neuron",
        "options": [
            "The axon contains the cell nucleus",
            "There are often numerous axons for each neuron",
            "The axon carries action potentials away from the cell body of a neuron",
            "The axon is the site of neurotransmitter production"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1391,
        "question": "Which of the following hormones is secreted by the corpus luteum and helps to build up the endometrial lining?",
        "answer": "The corpus luteum develops from the dominant follicle after ovulation. It secretes progesterone to build up the endometrial lining and promote oocyte implantation. If the oocyte does not get fertilized, then the corpus luteum stops secreting progesterone. This causes the degeneration of the corpus luteum and menstruation.",
        "suggested_answer": "Progesterone",
        "options": [
            "Luteinizing hormone (LH)",
            "Progesterone",
            "Follicle-stimulating hormone (FSH)",
            "Estrogen"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1392,
        "question": "Which of the following are true of glycogen?",
        "answer": "The pancreas secretes glucagon to stimulate the breakdown of glycogen; insulin is secreted to stimulate its assembly in liver and muscle. Glycogen is, in fact, a polysaccharide. However, it is made up of glucose not fructose. Finally, plant cell walls contain cellulose. While similar to glycogen, cellulose is made of beta glucose linkages instead of alpha glucose linkages, causing cellulose to be a more linear polysaccharide while glycogen contains curving branches.",
        "suggested_answer": "I only",
        "options": [
            "I only",
            "II only ",
            "III only",
            "I and II",
            "I, II, and III"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1393,
        "question": "During pyruvate decarboxylation reaction, pyruvate is converted to _______________ compound, through a reaction called ______________ .",
        "answer": "Pyruvate decarboxylation is an oxidative decarboxylation reaction, or an oxidation reaction where a carboxylate group is removed. This reaction converts pyruvate which was produced through glycolysis to acetyl CoA to be used in the Citric Acid Cycle.",
        "suggested_answer": "acetyl CoA; oxidative decarboxylation",
        "options": [
            "acetyl CoA; reductive decarboxylation",
            "acetyl CoA; oxidative decarboxylation",
            "NADH; oxidative decarboxylation",
            "ATP; decomposition"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1394,
        "question": "How would production of vasopressin be affected after consuming a large amount of water?",
        "answer": "Vasopressin, also called antidiuretic hormone (ADH), is part of the hormonal control of urine excretion and functions to enhance water reabsorption and limit the excretion of water in urine. Vasopressin is released when osmoreceptor cells in the hypothalamus detect a rise in the osmolarity or solute concentration of the blood above a threshold level.",
        "suggested_answer": "It would decrease",
        "options": [
            "Vasopressin would be inactivated by renin",
            "None of the other answers",
            "It would decrease",
            "It would increase"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1395,
        "question": "Most hormone regulatory signaling is based on negative feedback control mechanisms. What hormone operates through a positive feedback mechanism?",
        "answer": "The oxytocin pathway is an example of a positive feedback mechanism. Unlike negative feedback, which counteracts a stimulation, positive feedback reinforces a stimulus, leading to an even greater response.",
        "suggested_answer": "Oxytocin ",
        "options": [
            "Calcitonin",
            "Oxytocin ",
            "Insulin",
            "Vasopressin"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1396,
        "question": "RNA polymerase transcribes the following sequence of DNA:",
        "answer": "RNA polymerase transcribes a DNA template in the 3' to 5' direction, creating an RNA molecule 5' to 3'. The DNA sequence given in the question therefore needs to be flipped around and read in the 3' to 5' direction in order to determine the resulting what the RNA sequence will be 5' to 3'. Additionally, the nitrogenous base thymine (T) is replaced by uracil (U) in RNA, so every location where a T would go in the RNA sequence needs to be replaced by a U.",
        "suggested_answer": "5'-AUGGGCAU-3'",
        "options": [
            "5'-UACGGGUA-3'",
            "5'-AUGGGCAU-3'",
            "None of these",
            "5'-TACGGGTA-3'",
            "5'-ATGGGCAT-3'"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1397,
        "question": "Which of the following is not a type of selection?",
        "answer": "Stabilizing selection favors the intermediate phenotypes in a population while disruptive selection favors extreme phenotypes. Artificial selection is frequently practiced by humans in breeding livestock, pets, and crops. Sexual selection is dependent on interaction between males and females within a population. Domestic selection is not a real term.",
        "suggested_answer": "Domestic selection",
        "options": [
            "Disruptive selection",
            "Stabilizing selection",
            "Artificial selection",
            "Domestic selection",
            "Sexual selection"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1398,
        "question": "Which of the following is true of a bacteriophage?",
        "answer": "A bacteriophage is a virus which attacks bacteria and consists of a head, tail, and tail fibers all made of protein. The head contains genetic material (usually linear DNA) which is injected into the host bacterium upon infection. The protein parts do not enter the bacterium, and it does not have any ribosomes or organelles of its own.",
        "suggested_answer": "Only its DNA enters the host cell",
        "options": [
            "It is engulfed entirely by the host cell",
            "It always contain a circular DNA plasmid",
            "It contains its own ribosomes",
            "Only its DNA enters the host cell",
            "It can infect only plants or fungi"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1399,
        "question": "Which of the following is a correct statement about the difference between meiosis and mitosis? ",
        "answer": "Mitosis is used by somatic cells throughout the body. The goal of mitosis is to replace older cells with newer, healthier cells. In order for this replacement to be effective, the daughter cells must be identical to the parent cell. Somatic cells, or \"body cells,\" are diploid, meaning that they carry two copies of each allele. Each round of mitosis produces two daughter cells after one division.",
        "suggested_answer": "Mitosis produces two identical, diploid daughter cells after one division; meiosis produces four non-identical, haploid daughter cells after two divisions",
        "options": [
            "Mitosis produces two identical, diploid daughter cells after two divisions; meiosis produces four non-identical, haploid daughter cells after one division",
            "Mitosis produces two non-identical, diploid daughter cells after one division; meiosis produces four identical, haploid daughter cells after two divisions",
            "Mitosis produces four identical, diploid daughter cells after one division; meiosis produces two non-identical, haploid daughter cells after two divisions",
            "Mitosis produces two identical, haploid daughter cells after one division; meiosis produces four non-identical, diploid daughter cells after two divisions",
            "Mitosis produces two identical, diploid daughter cells after one division; meiosis produces four non-identical, haploid daughter cells after two divisions"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1400,
        "question": "Successful reproduction of a lytic virus requires all of the following except which of these?",
        "answer": "A lytic virus does not require incorporation into the host cell's DNA. Instead, a lytic virus must attach to it's host cell and enter. Once the virus enters, it can take over the hosts' cellular proteins to replication. After replication, the copies will burst from the host cell and spread throughout the extracellular environment.",
        "suggested_answer": "Incorporation of viral DNA into host DNA",
        "options": [
            "Translation of viral DNA",
            "Incorporation of viral DNA into host DNA",
            "Entry of the virus into the host cell",
            "Binding of the virus to the host cell",
            "Replication of the viral genome"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1401,
        "question": "Which of the following is not a component of connective tissue?",
        "answer": "Connective tissue has three main components: fibers, ground substance, and cells (fibroblasts, adipocytes, macrophages, mast cells, and leukocytes). Typically, connective tissue contains a relatively low proportion of cells, and a very high proportion of ground substance and fibrous proteins, such as collagen. Blood and lymph is sometimes considered a component of connective tissue as well.",
        "suggested_answer": "Skeletal muscles",
        "options": [
            "Ground substance",
            "Skeletal muscles",
            "Fibers",
            "Adipocytes"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1402,
        "question": "Which of the following statements concerning translation is true?",
        "answer": "In translation, the mRNA is positioned in the ribosome and read in the 5'-to-3' direction. Initiation of translation is triggered by a tRNA attached to a methionine entering the P site of the ribosome. The mRNA will then be read, and additional amino acids will be added to the chain, which grows in the P site. New tRNA enters the A site and old tRNA exits the E site, but the amino acid chain is always anchored to the tRNA in the P site.",
        "suggested_answer": "The growing amino acid chain is found in the P site of the ribosome",
        "options": [
            "mRNA is read by the ribosome in 3'-to-5' direction",
            "The growing amino acid chain is found in the P site of the ribosome",
            "The poly A tail serves as an attachment site for the ribosome",
            "The first amino acid, methionine, is positioned in the A site of the ribosome"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1403,
        "question": "Which of the following best describes the main functions of simple epithelium?",
        "answer": "Simple epithelia are layers of epithelial cells that are one cell thick. Simple epithelia are only composed of a monolayer of cells; therefore, their main functions are absorption and filtration.",
        "suggested_answer": "Absorption and filtration",
        "options": [
            "Absorption and filtration",
            "Protection from mechanical forces",
            "None of these",
            "Protection from chemical forces"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1404,
        "question": "Epistasis controls the color of squash, with the B locus controlling color (yellow is dominant to green), and E locus determining expression of B locus. This is a case of dominant epistasis. Which of the following is true?",
        "answer": "Epistasis describes the interaction of genes, where the epistatic locus masks the effects of a gene at another locus. In this example, locus E is epistatic. As this is stated to be dominant epistasis, when the E locus is either Ee or EE this locus will mask the effect of the B locus (color). Thus, any combination of B/b with Ee or EE will result in white squash. When the E locus is homozygous recessive (ee), the effect of the B locus will not be masked. Thus, BbEe will result in yellow squash as ee will not mask the color, and B (yellow) is dominant.",
        "suggested_answer": "Bbee results in yellow squash",
        "options": [
            "Bbee results in yellow squash",
            "bbEe results in green squash",
            "bbEe results in yellow squash",
            "bbee results in yellow squash "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1405,
        "question": "A human cell from the ovary has 22 chromosomes and an X chromosome. It is __________.",
        "answer": "A cell with 22 chromosomes and 1 sex chromosome is clearly haploid (n=23) and so it must be a sex cell and not a somatic cell (2n=46). Since both male and female gametes may contain an X chromosome, that information is not enough to tell us whether this cell comes from a male of female. However, since we are told the cell came from the ovary, we know it is the female gamete, an ovum. A muscle cell is a type of somatic cell, all of which are diploid.",
        "suggested_answer": "an ovum ",
        "options": [
            "a sperm",
            "an ovum ",
            "a somatic cell",
            "a muscle cell"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1406,
        "question": "Which of the following is established by the primitive streak during gastrula development?",
        "answer": "The primitive streak is an arrangement of cells that forms during gastrula development. The location of the primitive streak establishes the left/right axes, cranial/caudal axes, and bilateral symmetry of the developing embryo.",
        "suggested_answer": "All of these",
        "options": [
            "All of these",
            "Bilateral symmetry",
            "Cranial/caudal axes",
            "Left/right axes"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1407,
        "question": "Which of the following statements about neurons is incorrect?",
        "answer": "Neuron function is highly dependent on ion concentrations. Sodium is required for depolarization, potassium for hyperpolarization and repolarization, and calcium is responsible for initiating neurotransmitter release. Vesicles of neurotransmitter are stored in the terminal end of the axon, opposite from the end with the axon hillock and cell body. When voltage-gated calcium channels open, the binding of calcium to these vesicles causes them to exocytose the neurotransmitter.",
        "suggested_answer": "Axons transmit information to neighboring dendrites via direct contact",
        "options": [
            "Axons transmit information to neighboring dendrites via direct contact",
            "While the axon hillock is the part of the cell body that connects to the axon, the axon terminal is the region from which neurotransmitters can be released",
            "Myelin is a fatty material that insulates axons, allowing faster transmission of action potentials",
            "The presence of calcium, sodium, and potassium ions is vital for the neurons of the brain to function properly"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1408,
        "question": "When the chromosomes align themselves along a plane that is equidistant between the two poles of the cell, which phase of mitosis is the cell in?",
        "answer": "The plane along which the chromosomes align is called the metaphase plate and this event occurs during metaphase. At this point, the sister chromatids are ready to be pulled apart during anaphase. Ultimately, the products of mitosis are two identical (since sister chromatids are identical) diploid daughter cells. ",
        "suggested_answer": "Metaphase",
        "options": [
            "Telophase",
            "Metaphase",
            "Prophase",
            "Anaphase"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1409,
        "question": "Which structures did not evolve after plants emerged onto land?",
        "answer": "Cell walls were present in plant cells before the transition to land. Seeds, stomata, waxy cuticles, and vascular transport all evolved to reduce water loss and circulate water to all areas of the plant. Water loss and circulation were not an issue before the transition to land; plants were forced to adapt these traits in order to survive in a terrestrial environment.",
        "suggested_answer": "Cell walls",
        "options": [
            "Stomata",
            "Cell walls",
            "Seeds",
            "Vascular transport",
            "Waxy cuticles"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1410,
        "question": "What stage of mitosis involves the lining up of chromosomes at the equatorial plane of the cell?",
        "answer": "The correct answer is metaphase because this is the stage of mitosis where the chromosomes come together and line up in the middle forming the metaphase plate. These chromosomes then get pulled to either side in the anaphase stage.",
        "suggested_answer": "Metaphase",
        "options": [
            "Anaphase",
            "Cytokinesis",
            "Metaphase",
            "Telophase",
            "Interphase"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1411,
        "question": "Which of the following best represents the effect of high testosterone concentrations within the seminiferous tubules that occurs during spermiogenesis?",
        "answer": "There is a high concentration of testosterone within the seminiferous tubules. The secretion of androgen-binding protein by Sertoli cells concentrates testosterone around cells undergoing spermiogenesis, The testosterone aids in the process of sperm maturation by removing excess organelles from the developing spermatozoa. ",
        "suggested_answer": "Removes excess organelles",
        "options": [
            "Removes excess organelles",
            "Aids in axoneme formation",
            "Removes excess cytoplasm",
            "Aids in the development of sperm motility"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1412,
        "question": "If an autosomal trait skips a generation, it ___________; however, if an autosomal trait does not skip a generation, it ___________.",
        "answer": "If an autosomal trait skips a generation, it must be recessive; however, if an autosomal trait does not skip a generation, it can be either recessive or dominant.",
        "suggested_answer": "must be recessive . . . can be either recessive or dominant",
        "options": [
            "must be recessive . . . can be either recessive or dominant",
            "can be either recessive or dominant . . . must be dominant",
            "must be dominant . . . can be either recessive or dominant",
            "can be either recessive or dominant . . . must be recessive"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1413,
        "question": "Which of the following is not true regarding the morphology of epithelial cuboidal cells?",
        "answer": "Cuboidal epithelial cells are one category of epithelial cell types. These cells have equal height and width; therefore, they are cube-shaped. They possess round nuclei and are typically involved in secretion and absorption. Cuboidal epithelial cells are located in the exocrine system.",
        "suggested_answer": "Nuclei are elongated",
        "options": [
            "The height and width are equal",
            "Nuclei are elongated",
            "They are cube-shaped",
            "Nuclei are round"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1414,
        "question": "Which of the following is responsible for the red appearance of some muscles?",
        "answer": "Muscle tissue can be divided into red fast twitch and white slow twitch muscle types. Red muscles have many capillaries, mitochondria, and myoglobin. Red muscles also perform aerobic activity.",
        "suggested_answer": "They contain large quantities of myoglobin",
        "options": [
            "They are anaerobic",
            "They have few mitochondria",
            "They contain large quantities of myoglobin",
            "They are fast twitch fast contraction muscles"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1415,
        "question": "A strand of DNA was measured to contain 22% adenine. What is the DNA's composition of the other bases?",
        "answer": "We can use Chargaff's rule to find the remaining compositional percentages. Adenine always pairs with thymine, so their percentages will be equal. Cytosine always pairs with guanine, so their percentages will also be equal. The sum of all four percentages must equal 100%.",
        "suggested_answer": "22% Adenine, 22% Thymine, 28% Cytosine, 28% Guanine",
        "options": [
            "22% Adenine, 22% Guanine, 28% Cytosine, 28% Thymine",
            "22% Adenine, 22% Uracil, 28% Cytosine, 28% Guanine",
            "22% Adenine, 22% Thymine, 28% Cytosine, 28% Guanine",
            "22% Adenine, 22% Cytosine, 28% Guanine, 28% Thymine ",
            "22% Adenine, 22% Guanine, 28% Cytosine, 28% Uracil"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1416,
        "question": "Which of the following describes the state of genetic material in prokaryotic cells?",
        "answer": "The state of genetic material differs between eukaryotic and prokaryotic cells. Eukaryotic cells contain multiple linear chromosomes, called \"true\" chromosomes, while prokaryotic cells contain circular DNA molecules that are not true chromosomes, since they lack histones. Prokaryotic DNA molecules have only one copy of each gene and little repetitive and non-coding DNA, contributing to the compact nature of the prokaryotic genome. ",
        "suggested_answer": "Plasmids",
        "options": [
            "Multiple chromosomes",
            "No genetic material",
            "Plasmids",
            "One linear chromosome"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1417,
        "question": "Which pattern of dispersion is a result of territoriality?",
        "answer": "A clumped pattern of dispersion occurs when individuals aggregate in patches. For example, a herd of cows all graze in a field together, as it is their only source of food within five miles. Uniform dispersion is when organisms are evenly spaced throughout a given area. This results from territoriality, or when organisms defend their physical space against other organisms. Random dispersion is the unpredictable spacing of organisms throughout a given area. There are no strong attractions or repulsions among individuals that would result in clumped or uniform dispersion; the animals are scattered randomly. ",
        "suggested_answer": "Uniform ",
        "options": [
            "Uniform ",
            "Demography ",
            "Emigration ",
            "Clumped",
            "Random dispersion "
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1418,
        "question": "What is the zone of inhibition in this method?",
        "answer": "The zone of inhibition is the zone around the disc that the antibiotic killed the bacteria.",
        "suggested_answer": "The area of clearing of the bacteria surrounding the disc",
        "options": [
            "The periphery where the bacteria is growing",
            "The area of clearing of the bacteria surrounding the disc",
            "The growth media under the antibiotic disc",
            "The number of bacteria per milliliter of broth culture spread onto the plate",
            "The total area of the petri dish"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1419,
        "question": "Which of the following cellular organelles generates ATP for the cell?",
        "answer": "The mitochondria is often referred to as the powerhouse of the cell because it is the location of cellular respiration cycles (Krebs cycle and electron transport chain). Thus, mitochondria is responsible for providing most of the energy for the cell in the form of ATP.",
        "suggested_answer": "Mitochondria",
        "options": [
            "Nucleus",
            "Lysosome",
            "Peroxisome",
            "Golgi",
            "Mitochondria"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1420,
        "question": "Where in the cell does glycolysis take place?",
        "answer": "Glycolysis occurs in the cytosol of cells. Once finished, the two pyruvate products are transported into the mitochondria to go through the citric acid cycle, at a cost of 1 ATP per pyruvate. Neither the nucleus, nor the endoplasmic reticulum have any function in glycolysis or the citric acid cycle.",
        "suggested_answer": "Cytosol",
        "options": [
            "Nucleus",
            "Cytosol",
            "Mitochondria",
            "Endoplasmic reticulum"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1421,
        "question": "Which disorder is correctly matched with a probable cause?",
        "answer": "To answer this question, it is necessary to understand the prefixes \"hyper\" and \"hypo.\" \"Hyper\" refers to excessive synthesis, while \"hypo\" refers to reduced synthesis.",
        "suggested_answer": "Pituitary dwarfism: hyposecretion of growth hormone",
        "options": [
            "Diabetes: hypersecretion of insulin",
            "Low blood calcium: hypersecretion of parathyroid hormone",
            "Pituitary dwarfism: hyposecretion of growth hormone",
            "Enlargement of face and extremities: hypersecretion of thyroxine",
            "Enlargement of face and extremities: hyposecretion of growth hormone"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    },
    {
        "id": 1422,
        "question": "The Trendelenburg Sign is a clinical sign in which a person's hip droops to the opposite side when standing on one foot. Which of the following nerves might be damaged?",
        "answer": "The Gluteus Medius and Gluteus Minimus are very important in stabalizing the hip, and keeping it level when walking (note: not the Gluteus Maximus). Thus if the Superior Gluteal Nerve, which innervates the Gluteus Medius and Minimus was damaged, a person would have their hip droop to the other side because these muscles would no longer 'pull down' on the hip on the ipsilateral side. ",
        "suggested_answer": "Superior Gluteal Nerve",
        "options": [
            "Sciatic Nerve",
            "Inferior Gluteal Nerve",
            "Superior Gluteal Nerve",
            "Femoral Nerve",
            "Ilioinguinal Nerve"
        ],
        "topic": "AP Biology",
        "toLink": "ap_biology"
    }
]