import React, { useEffect, useState } from "react";
import Modules from "../components/Modules";
import AppLayout from "../components/AppLayout";
import { Button, Divider, Input, Menu, Select } from "antd";
import {
    addItem,
    getById,
    getCount,
    getCurrentUser,
    getData,
    getFormattedCurrentDate,
    getNextId,
} from "../data/storage";
import "./Forum.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const Forum = () => {
    const navigate = useNavigate();
    const { moduleId, forumTopicId, forumPostId } = useParams();
    const module = moduleId
        ? getData("modules").find((m) => (m.id = moduleId))
        : undefined;
    const forumTopic = forumTopicId
        ? getData("forum_topics").find((f) => f.id === forumTopicId)
        : undefined;
    const forumPost = forumPostId
        ? getData("forum_posts").find((p) => p.id === Number(forumPostId))
        : undefined;
    const [editorHtml, setEditorHtml] = useState("");
    const [editorOpened, setEditorOpened] = useState(false);
    const [editorPostTitle, setEditorPostTitle] = useState("");
    const [sortBy, setSortBy] = useState(1);

    useEffect(() => {
        // Cannot find module based on module code in path
        if (moduleId !== undefined && module === undefined) navigate("/forum");

        // Cannot find forum topic based on forum topic id in path
        if (forumTopicId !== undefined && forumTopic === undefined)
            navigate("/forum/" + moduleId);

        // Cannot find forum post based on forum post id in path
        if (forumPostId !== undefined && forumPost === undefined)
            navigate("/forum/" + moduleId + "/" + forumTopicId);
    });

    const clickForumTopic = (e) => {
        setEditorOpened(false);
        navigate("/forum/" + moduleId + "/" + e.key, { replace: true });
    };

    const getForumTopics = () => {
        const forumTopicItems = getData("forum_topics")
            .filter((f) => f.module_id === moduleId)
            .map((ft) => ({ key: ft.id, label: ft.name }));
        return (
            <Menu
                items={forumTopicItems}
                onClick={clickForumTopic}
                selectedKeys={[forumTopicId]}
            />
        );
    };

    const savePost = () => {
        if (editorHtml === "" || editorPostTitle === "") {
            toggleEditor();
            return;
        }

        const post = {
            id: getNextId("forum_posts"),
            forum_topic_id: forumTopicId,
            title: editorPostTitle,
            content: editorHtml,
            posted_on: getFormattedCurrentDate(),
            user_id: getCurrentUser().id,
        };

        addItem("forum_posts", post);

        toggleEditor();
    };

    const comparePostedDate = (p1, p2) => {
        if (p1.posted_on === p2.posted_on) return 0;
        if (p1.posted_on > p2.posted_on) return sortBy;
        if (p1.posted_on < p2.posted_on) return -sortBy;
    };

    const getForumPosts = () => {
        const forumPosts = getData("forum_posts")
            .filter((p) => p.forum_topic_id === forumTopicId)
            .sort(comparePostedDate);
        return (
            <div className="forum-posts">
                <div className="forum-posts-header">
                    <p>{forumTopic.name}</p>
                    <Button
                        type="primary"
                        className="create-post-button"
                        onClick={toggleEditor}
                    >
                        {editorOpened ? "Close" : "Create Post"}
                    </Button>
                </div>
                <div
                    className={
                        "create-post-form" + (editorOpened ? "" : " editor-hidden")
                    }
                >
                    <div className="create-post-control">
                        <Input
                            placeholder="Post Title"
                            value={editorPostTitle}
                            onChange={(e) => setEditorPostTitle(e.target.value)}
                        />
                        <Button type="primary" onClick={savePost}>
                            Save
                        </Button>
                    </div>
                    <ReactQuill
                        value={editorHtml}
                        onChange={setEditorHtml}
                        className={editorOpened ? "editor" : "editor editor-hidden"}
                    />
                </div>
                {forumPosts.map((forumPost) => (
                    <>
                        <Link
                            key={forumPost.id}
                            className="forum-post"
                            to={
                                "/forum/" + moduleId + "/" + forumTopicId + "/" + forumPost.id
                            }
                        >
                            <p>
                                {getById("users", forumPost.user_id).name} |{" "}
                                {forumPost.posted_on}
                            </p>
                            <p className="post-title">{forumPost.title}</p>
                            <div
                                className="post-preview"
                                dangerouslySetInnerHTML={{ __html: forumPost.content }}
                            ></div>
                            <div className="comment-count">
                                <p>
                                    {getCount("forum_replies", "forum_post_id", forumPost.id)}{" "}
                                    replies
                                </p>
                            </div>
                        </Link>
                        <Divider className="post-divider" />
                    </>
                ))}
            </div>
        );
    };

    const toggleEditor = () => {
        // Open editor if closed
        if (!editorOpened) {
            setEditorOpened(true);
            return;
        }

        setEditorOpened(false);
        setEditorHtml("");
        setEditorPostTitle("");
    };

    const saveReply = () => {
        if (editorHtml !== "") {
            // To update user id after login implemented
            const reply = {
                id: getNextId("forum_replies"),
                forum_post_id: Number(forumPostId),
                content: editorHtml,
                posted_on: getFormattedCurrentDate(),
                user_id: getCurrentUser().id,
            };

            addItem("forum_replies", reply);
        }

        toggleEditor();
    };

    const getForumPost = () => {
        const postReplies = getData("forum_replies")
            .filter((r) => r.forum_post_id === Number(forumPostId))
            .sort(comparePostedDate);
        if (forumPost.content.includes("href"))
            forumPost.content = forumPost.content.replace(
                "href",
                'target="_blank" href'
            );
        return (
            <div className="post">
                <div className="post-header">
                    <Button
                        className="post-back-button"
                        type="primary"
                        onClick={() =>
                            navigate("/forum/" + moduleId + "/" + forumTopicId, {
                                replace: true,
                            })
                        }
                    >
                        Back
                    </Button>
                    <p>{forumTopic.name}</p>
                </div>
                <div className="post-main">
                    <p>
                        {getById("users", forumPost.user_id).name} | {forumPost.posted_on}
                    </p>
                    <p className="post-main-title">{forumPost.title}</p>
                    <div
                        className="post-main-content"
                        dangerouslySetInnerHTML={{ __html: forumPost.content }}
                    ></div>
                </div>
                <div className="post-replies">
                    <div className="post-reply-header">
                        <p>Replies</p>
                        <div className="reply-control">
                            <Button type="primary" onClick={toggleEditor}>
                                {editorOpened ? "Cancel" : "Reply"}
                            </Button>
                            {editorOpened ? (
                                <Button type="primary" onClick={saveReply}>
                                    Save
                                </Button>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                    {editorOpened ? (
                        <div className={"post-reply-input"}>
                            <ReactQuill
                                value={editorHtml}
                                onChange={setEditorHtml}
                                className={editorOpened ? "editor" : "editor editor-hidden"}
                            />
                        </div>
                    ) : (
                        ""
                    )}
                    <Select
                        className="post-reply-sort"
                        options={[
                            { value: -1, label: "Newest First" },
                            { value: 1, label: "Oldest First" },
                        ]}
                        style={{ width: 200 }}
                        value={sortBy}
                        onChange={(value) => setSortBy(value)}
                    />
                    {postReplies.length !== 0 || editorOpened ? (
                        postReplies.map((reply) => (
                            <div key={reply.id} className="post-reply">
                                <Divider style={{ margin: "2px 0" }} />
                                <p>
                                    {getById("users", reply.user_id).name} | {reply.posted_on}
                                </p>
                                <div
                                    className="post-reply-content"
                                    dangerouslySetInnerHTML={{ __html: reply.content }}
                                ></div>
                            </div>
                        ))
                    ) : (
                        <div className="post-reply-empty">
                            <p>Be the first to reply.</p>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const getPageContent = () => {
        // Select module page
        if (module === undefined) return <Modules />;

        // Select topic
        if (forumTopic === undefined)
            return (
                <div className="forum">
                    {getForumTopics()}
                    <div className="forum-posts-empty">
                        <p>Select a topic on the left to continue.</p>
                    </div>
                </div>
            );

        // List posts
        if (forumPost === undefined)
            return (
                <div className="forum">
                    {getForumTopics()}
                    {getForumPosts()}
                </div>
            );

        // Post page
        return getForumPost();
    };

    return <AppLayout menuPath="/forum">{getPageContent()}</AppLayout>;
};

export default Forum;
