import React, { useEffect } from 'react';
import AppLayout from "../components/AppLayout";
import Modules from "../components/Modules";
import { List, message } from "antd"; // Import message from antd
import {getCurrentUser, getData} from "../data/storage";
import "./Revision.css";
import { Link, useNavigate, useParams } from "react-router-dom";

const Revision = () => {
    const navigate = useNavigate();
    const { moduleCode } = useParams();
    const module = getData("modules").find(mod => mod.code === moduleCode);
    const revision = getData("revision").filter(r => r.module_code === moduleCode);
    const user = getCurrentUser();

    // Click event handler to show an alert
    const handleModuleClick = (moduleCode) => {
        if (moduleCode !== 'INF2002' && !user.experiment_group) {
            message.warning('You can only navigate to INF2002 Human Computer Interaction.');
            navigate("/revision")
        }
    };

    const getExperimentSuffix = (revisionTopic) => {
        const group = user.experiment_group
        const topicCode = revisionTopic.code;
        const type = revisionTopic.type;
        if (!group || !["nervous_system", "immune_system", "ap_biology"].includes(topicCode))
            return;
        if ((["A", "B"].includes(group) && topicCode === "nervous_system") || (["C", "D"].includes(group) && topicCode === "immune_system"))
            return " (Experiment 1)"
        if (type && ((["A", "C"].includes(group) && type === "static") || (["B", "D"].includes(group) && type === "flip")))
            return " (Experiment 1)"
        return " (Experiment 2)"
    }

    const getFlashcardStyle = (revisionTopic) => {
        const group = user.experiment_group;
        const topicCode = revisionTopic.code;
        const type = revisionTopic.type;
        if (!group || !["nervous_system", "immune_system", "ap_biology"].includes(topicCode))
            return "flip/";
        if ((["A", "D"].includes(group) && topicCode === "nervous_system") || (["B", "C"].includes(group) && topicCode === "immune_system"))
            return "static/";
        if (type)
            return type + "/";
        return "flip/";
    }

    useEffect(() => {
        // Invalid module code in path
        if (moduleCode && !module) {
            navigate("/revision");
        }
    }, [moduleCode, module, navigate]);

    return (
        <AppLayout menuPath="/revision">
            {moduleCode === undefined ? (
                <Modules />
            ) : (
                <List
                    className="revision"
                    style={{ backgroundColor: '#DAD0CA' }}
                    header={<div className="revision-header">{module.code} {module.name}</div>}
                    onClick={() => handleModuleClick(module.code)}
                >
                    {revision.map(revisionTopic => (
                        <List.Item
                            style={{ color: 'white' }}
                            className="revision-topic"
                            key={revisionTopic.code + (revisionTopic.type || "")}
                        >
                            <Link to={getFlashcardStyle(revisionTopic) + revisionTopic.code}>{revisionTopic.topic}{getExperimentSuffix(revisionTopic)}</Link>
                        </List.Item>
                    ))}
                </List>
            )}
        </AppLayout>
    );
};

export default Revision;
