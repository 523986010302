import { forum_topic_data } from "./forum_topic_data";
import { module_data } from "./module_data";
import { user_data } from "./user_data";
import { forum_post_data } from "./forum_post_data";
import { forum_reply_data } from "./forum_reply_data";
import { revision_data } from "./revision_data";
import { flashcard_data } from "./flashcard_data";
import { examination_questions_data } from "./examination_questions";
import { examination_feedback_data } from "./examination_feedback_data";
import examination_data from "./examination_data";

export const data = {
  users: user_data,
  forum_topics: forum_topic_data,
  forum_posts: forum_post_data,
  forum_replies: forum_reply_data,
  revision: revision_data,
  flashcard: flashcard_data,
  modules: module_data,
  exam_questions: examination_questions_data,
  exam_feedback: examination_feedback_data,
  exam_data: examination_data,
};
