import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import AppLayout from '../components/AppLayout';
import {Button, Form, Radio, Input, Modal, Space} from 'antd';
import "./ExamQuestion.css";
import {addItem, getCurrentUser, getData, getNextId} from "../data/storage";
import examination_data from '../data/examination_data';

// const config = require('../components/config');
//
// const openai = new OpenAI({
//     apiKey: config.apiKey,
//     dangerouslyAllowBrowser: true // looking for alternative
// });
//
// const sendMessageToChatbot = async (message) => {
//     try {
//         const response = await openai.chat.completions.create({
//             model: 'gpt-3.5-turbo',
//             messages: [{role: 'user', content: message}]
//         });
//         return response.choices[0].message.content;
//     } catch (error) {
//         console.error('Error sending message to chat bot:', error);
//         return 'An error occurred while communicating with the chat bot.';
//     }
// };

const ExamQuestion = () => {

    const { examId } = useParams();

    // Find the exam name based on examId
    const exam = getData("exam_data").find(quiz => quiz.id === examId);

    // Fetch questions for the specified examId
    const examQuestions = getData("exam_questions").filter(question => question.examination_id === examId);

    // State to store user answers
    const [answers, setAnswers] = useState({});

    // State to track whether answers have been submitted
    const [submitted, setSubmitted] = useState(false);
    
    // timer 3600 seconds = 1 hour
    const [countdown, setCountdown] = useState(examQuestions.length > 0 ? 300 : 0);

    // State for the confirmation modal
    const [isConfirmationVisible, setConfirmationVisible] = useState(false); 

    //To get to exam results
    const navigate  = useNavigate();


    // Function to handle answer input changes
    const handleAnswerChange = (question_id, value) => {
        setAnswers({...answers, [question_id]: value});
    };

    const showConfirmation = () => {
        setConfirmationVisible(true);
    };

    const hideConfirmation = () => {
        setConfirmationVisible(false);
    };

    // const updateQuizStatus = (quizId) => {
    //     const quizIndex = examination_data.findIndex(quiz => quiz.id === quizId);
    //     if (quizIndex !== -1) {
    //         examination_data[quizIndex].status = "completed";
    //     }
    // };

    const submitAnswers = () => {
        console.log("Submit answers");
        if (submitted)
            return;
        hideConfirmation(); // Close the confirmation dialog
        // Set the submitted state to true
        setSubmitted(true);
        // Update the status of the quiz to "Completed"
        // updateQuizStatus(examId);

        examQuestions.forEach(question => answers[question.id] ? "": answers[question.id] = "");

        // Save answer first, query chat bot in results page
        Object.entries(answers).forEach(([question_id, answer]) => {
            const examination_feedback = {
                "id": getNextId("exam_feedback") || 1,
                "user_id": getCurrentUser().id,
                "exam_question_id": Number(question_id),
                "response": "",
                "student_answer": answers[question_id]
            }
            addItem("exam_feedback", examination_feedback);
        });

        navigate(`/examination/${examId}/result`);
    };

    
    useEffect(() => {
        let interval;

        if (countdown > 0 && !submitted) {
            interval = setInterval(() => {
                setCountdown(prevCountdown => {
                    if (prevCountdown <= 0) {
                        clearInterval(interval);
                        submitAnswers(); // Auto-submit when the timer reaches 0
                        return 0;
                    }
                    return prevCountdown - 1;
                });
            }, 1000);
        }
    
        return () => clearInterval(interval);
    }, [countdown, submitted]);
    
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    return (
        <AppLayout menuPath={`/examination`} hideNavigation={true}>
            <div className="exam-questions">
            <h2 className="question-heading">{exam ? exam.title : "Exam Title"}</h2>
                <p className="countdown-timer"> Time Remaining: {formatTime(countdown)} </p>
                <Form>
                    {examQuestions.map((question, index) => (
                        <div key={question.id} className="question-container">
                            <h3 className="question-text">{`${index + 1}. ${question.question}`}</h3>
                            <Form.Item className="answer-input">
                                {question.type === 'MCQ' ? <Radio.Group
                                    onChange={(e) => handleAnswerChange(question.id, e.target.value)}
                                    value={answers[question.id]}>
                                    <Space direction="vertical">
                                        {question.option.map((option, index) => (
                                            <Radio key={index} value={option}>{option}</Radio>
                                            ))}
                                    </Space>
                                </Radio.Group>
                                    :
                                <Input.TextArea
                                    autoSize={{ minRows: 3, maxRows: 6 }}
                                    placeholder="Enter your answer here"
                                    value={answers[question.id]} // Display the user's answer
                                    onChange={(e) => handleAnswerChange(question.id, e.target.value)}
                                />}
                            </Form.Item>
                        </div>
                    ))}
                    <div className="button-container">
                    {/*<Link to="/examination"> /!* Use Link to navigate back *!/*/}
                    {/*    <Button type="default" icon={<LeftOutlined />}>*/}
                    {/*        Back*/}
                    {/*    </Button>*/}
                    {/*</Link>*/}
                    {examQuestions.length > 0 && !submitted && ( // Check for questions and whether they are submitted
                        <Button type="primary" onClick={showConfirmation}>Submit Answers</Button>
                    )}
                    </div>
                </Form>
            </div>
            {submitted && (
                <div className="submitted-answers">
                    <h2>Your Answers</h2>
                    {answers.map((answer, index) => (
                        answer && <p key={index}>Question {index + 1}: {answer}</p>
                    ))}
                </div>
            )}
            <Modal
                title="Submit Answers Confirmation"
                centered
                open={isConfirmationVisible}
                onOk={submitAnswers}
                onCancel={hideConfirmation}
            >
                Are you sure you want to submit your answers?
            </Modal>
        </AppLayout>
    );
};

export default ExamQuestion;
