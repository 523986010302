import React, {useState} from 'react';
import {Tabs} from "antd";
import {getCurrentUser, getData} from "../data/storage";
import "./Modules.css";
import {Link} from "react-router-dom";

const Modules = () => {

    // State to track current year and trimester
    const [year, setYear] = useState(1);
    const [trimester, setTrimester] = useState(1);

    // List of possible years and trimesters
    const years = ["Year 1", "Year 2", "Year 3", "Year 4"]
    const trimesters = ["Trimester 1", "Trimester 2", "Trimester 3"]

    // Objects for year and trimester tabs
    const yearTabs = years.map((y, i) => ({key: i + 1, label: y}));
    const trimesterTabs = trimesters.map((t, i) => ({key: i + 1, label: t}));

    // Get modules for selected year and trimester
    // For experiment users, show only experiment modules
    const modules = getData("modules").filter(module => module.trimesters.find(tri => tri.year === year && tri.trimester === trimester) !== undefined && (getCurrentUser().experiment_group ? module.experiment : !module.experiment));

    return (
        <>
            <Tabs centered activeKey={year} items={yearTabs} onChange={key => {setYear(key); setTrimester(1);}} ></Tabs>
            <Tabs centered activeKey={trimester} items={trimesterTabs} onChange={key => setTrimester(key)}></Tabs>
            <div className="modules">
                {modules.map(module =>
                    <Link className="module" to={module.code} key={module.code}>
                        <div className="module-details">
                            <p className='module-code'>{module.code}</p>
                            <p className='module-name'>{module.name}</p>
                        </div>
                    </Link>
                )}
            </div>
        </>
    );
};

export default Modules;