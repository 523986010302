import React, {useEffect, useRef, useState} from 'react';
import { useParams, Link } from 'react-router-dom';
import AppLayout from '../components/AppLayout';
import {Spin ,Button } from 'antd';
import "./ExamResult.css";
import {getCurrentUser, getData, setData} from "../data/storage";
import examination_data from '../data/examination_data';
import OpenAI from "openai";

const ExamResult = () => {

    //const [loaded, setLoaded] = useState(0);
    const { examId } = useParams(); // Read the examId from the URL parameter
    const exam = examination_data.find(quiz => quiz.id === examId);
    const userId = getCurrentUser().id;
    const questions = getData("exam_questions").filter(q => q.examination_id === examId);
    const question_ids = questions.map(question => question.id);
    const [feedbacks, setFeedbacks] = useState(getData("exam_feedback").filter(f => question_ids.includes(f.exam_question_id) && f.user_id === userId));
    const initialised = useRef(false);

    // Retrieve the answers from some method
    // const answers = retrieveAnswers(); //

    // useEffect(() => {
    //     if (loaded !== questions.length) {
    //         const interval = setInterval(() => {
    //             let count = 0;
    //             const feedbackData = Object.fromEntries(questions.map(question => {
    //                 const feedbackItem = getData("exam_feedback").find(f => f.exam_question_id === question.id && f.user_id === getCurrentUser().id);
    //                 if (feedbackItem !== undefined)
    //                     count++;
    //                 return [question.id, feedbackItem];
    //             }));
    //             console.log("Not yet loaded");
    //             setLoaded(count);
    //             setFeedbacks(feedbackData);
    //         }, 1000);
    //         return () => clearInterval(interval);
    //     }
    // }, [loaded]);

    // New way of getting AI feedback

    const config = require('../components/config');

    const openai = new OpenAI({
        apiKey: config.apiKey,
        dangerouslyAllowBrowser: true // looking for alternative
    });

    const sendMessageToChatbot = async (message) => {
        try {
            const response = await openai.chat.completions.create({
                model: 'gpt-4',
                messages: [{role: 'user', content: message}]
            });
            return response.choices[0].message.content;
        } catch (error) {
            console.error('Error sending message to chat bot:', error);
            return 'An error occurred while communicating with the chat bot.';
        }
    };

    useEffect(() => {
        console.log("Inside use effect");
        if (initialised.current)
            return;
        initialised.current = true;
        console.log("Running use effect");
        for (const feedback of feedbacks) {
            (async () => {
                const question = questions.find(q => q.id === feedback.exam_question_id);
                if (feedback.response === "") {
                    let response = ""
                    if (question.type !== "MCQ") {
                        console.log("Asking AI");
                        const prompt =
                            "This is the question: " + question.question
                            + "This is the suggested answer: " + question.suggested_answer
                            + "\nThis is the student's answer: " + feedback.student_answer
                            + "Grade the student out of 10 and provide a summary feedback\n"
                            + "If the student's answer is missing, please grade 0/10";
                        response = await sendMessageToChatbot(prompt);
                    } else {
                        response = question.suggested_answer === feedback.student_answer ? "Correct": "Wrong";
                    }
                    // Save feedback into local storage
                    setData("exam_feedback", getData("exam_feedback").map(f => {
                        if (f.exam_question_id === question.id && f.user_id === userId)
                            f.response = response
                        return f;
                    }));
                    // Update feedback state
                    setFeedbacks(feedbacks.map(f => {
                        if (f.exam_question_id === question.id && f.user_id === userId)
                            f.response = response
                        return f;
                    }));
                }
            })();
        }
    });



    return (
        <AppLayout menuPath={`/examination`}>
            <div className="exam-results">
                <h2 className="exam-results-header">Quiz Results For {exam ? exam.title : "Exam Title"}</h2>
                <div className="result-questions">
                    {questions.map(question =>
                        <div key={question.id} className="result-question">
                            <p>{question.question}</p>
                            <p>Suggested Answer</p>
                            <p>{question.suggested_answer}</p>
                            <p>Your Answer</p>
                            <p>{feedbacks.find(f => f.exam_question_id === question.id).student_answer}</p>
                            <p>AI Feedback</p>
                            <p>{feedbacks.find(f => f.exam_question_id === question.id).response || <Spin/>}</p>
                        </div>
                    )}
                </div>
                <div>
                    <Link to="/examination"> {/* Use Link to navigate back */}
                        <Button type="primary">
                            Return to Home
                        </Button>
                    </Link>
                </div>
            </div>
        </AppLayout>
    );
};

 export default ExamResult;
