
export const revision_data = [
    {
        "module_code": "LSM2212",
        "code": "ap_biology",
        "topic": "AP Biology",
        "type": "static",
        "module_name": "Human Anatomy"
    },
    {
        "module_code": "LSM2212",
        "code": "ap_biology",
        "topic": "AP Biology",
        "type": "flip",
        "module_name": "Human Anatomy"
    },
    {
        "module_code": "LSM2212",
        "code": "nervous_system",
        "topic": "Nervous System",
        "module_name": "Human Anatomy"
    },
    {
        "module_code": "LSM2212",
        "code": "immune_system",
        "topic": "Immune System",
        "module_name": "Human Anatomy"
    },
    {
        "module_code": "ICT1011",
        "code": "forum_ICT1011_1",
        "topic": "ICT1011 Week 1",
        "module_name": "Computer Organization & Architecture"
    },
    {
        "module_code": "ICT1011",
        "code": "forum_ICT1011_2",
        "topic": "ICT1011 Week 2",
        "module_name": "Computer Organization & Architecture"
    },
    {
        "module_code": "ICT1011",
        "code": "forum_ICT1011_3",
        "topic": "ICT1011 Week 3",
        "module_name": "Computer Organization & Architecture"
    },
    {
        "module_code": "ICT1011",
        "code": "forum_ICT1011_4",
        "topic": "ICT1011 Week 4",
        "module_name": "Computer Organization & Architecture"
    },
    {
        "module_code": "ICT1011",
        "code": "forum_ICT1011_5",
        "topic": "ICT1011 Week 5",
        "module_name": "Computer Organization & Architecture"
    },
    {
        "module_code": "ICT1011",
        "code": "forum_ICT1011_6",
        "topic": "ICT1011 Week 6",
        "module_name": "Computer Organization & Architecture"
    },
    {
        "module_code": "ICT1011",
        "code": "forum_ICT1011_7",
        "topic": "ICT1011 Week 7",
        "module_name": "Computer Organization & Architecture"
    },
    {
        "module_code": "ICT1011",
        "code": "forum_ICT1011_8",
        "topic": "ICT1011 Week 8",
        "module_name": "Computer Organization & Architecture"
    },
    {
        "module_code": "ICT1011",
        "code": "forum_ICT1011_9",
        "topic": "ICT1011 Week 9",
        "module_name": "Computer Organization & Architecture"
    },
    {
        "module_code": "ICT1011",
        "code": "forum_ICT1011_10",
        "topic": "ICT1011 Week 10",
        "module_name": "Computer Organization & Architecture"
    },
    {
        "module_code": "ICT1011",
        "code": "forum_ICT1011_11",
        "topic": "ICT1011 Week 11",
        "module_name": "Computer Organization & Architecture"
    },
    {
        "module_code": "ICT1011",
        "code": "forum_ICT1011_12",
        "topic": "ICT1011 Week 12",
        "module_name": "Computer Organization & Architecture"
    },
    {
        "module_code": "ICT1011",
        "code": "forum_ICT1011_13",
        "topic": "ICT1011 Week 13",
        "module_name": "Computer Organization & Architecture"
    },
    {
        "module_code": "INF1001",
        "code": "forum_INF1001_1",
        "topic": "INF1001 Week 1",
        "module_name": "Introduction to Computing"
    },
    {
        "module_code": "INF1001",
        "code": "forum_INF1001_2",
        "topic": "INF1001 Week 2",
        "module_name": "Introduction to Computing"
    },
    {
        "module_code": "INF1001",
        "code": "forum_INF1001_3",
        "topic": "INF1001 Week 3",
        "module_name": "Introduction to Computing"
    },
    {
        "module_code": "INF1001",
        "code": "forum_INF1001_4",
        "topic": "INF1001 Week 4",
        "module_name": "Introduction to Computing"
    },
    {
        "module_code": "INF1001",
        "code": "forum_INF1001_5",
        "topic": "INF1001 Week 5",
        "module_name": "Introduction to Computing"
    },
    {
        "module_code": "INF1001",
        "code": "forum_INF1001_6",
        "topic": "INF1001 Week 6",
        "module_name": "Introduction to Computing"
    },
    {
        "module_code": "INF1001",
        "code": "forum_INF1001_7",
        "topic": "INF1001 Week 7",
        "module_name": "Introduction to Computing"
    },
    {
        "module_code": "INF1001",
        "code": "forum_INF1001_8",
        "topic": "INF1001 Week 8",
        "module_name": "Introduction to Computing"
    },
    {
        "module_code": "INF1001",
        "code": "forum_INF1001_9",
        "topic": "INF1001 Week 9",
        "module_name": "Introduction to Computing"
    },
    {
        "module_code": "INF1001",
        "code": "forum_INF1001_10",
        "topic": "INF1001 Week 10",
        "module_name": "Introduction to Computing"
    },
    {
        "module_code": "INF1001",
        "code": "forum_INF1001_11",
        "topic": "INF1001 Week 11",
        "module_name": "Introduction to Computing"
    },
    {
        "module_code": "INF1001",
        "code": "forum_INF1001_12",
        "topic": "INF1001 Week 12",
        "module_name": "Introduction to Computing"
    },
    {
        "module_code": "INF1001",
        "code": "forum_INF1001_13",
        "topic": "INF1001 Week 13",
        "module_name": "Introduction to Computing"
    },
    {
        "module_code": "INF1002",
        "code": "forum_INF1002_1",
        "topic": "INF1002 Week 1",
        "module_name": "Programming Fundamentals"
    },
    {
        "module_code": "INF1002",
        "code": "forum_INF1002_2",
        "topic": "INF1002 Week 2",
        "module_name": "Programming Fundamentals"
    },
    {
        "module_code": "INF1002",
        "code": "forum_INF1002_3",
        "topic": "INF1002 Week 3",
        "module_name": "Programming Fundamentals"
    },
    {
        "module_code": "INF1002",
        "code": "forum_INF1002_4",
        "topic": "INF1002 Week 4",
        "module_name": "Programming Fundamentals"
    },
    {
        "module_code": "INF1002",
        "code": "forum_INF1002_5",
        "topic": "INF1002 Week 5",
        "module_name": "Programming Fundamentals"
    },
    {
        "module_code": "INF1002",
        "code": "forum_INF1002_6",
        "topic": "INF1002 Week 6",
        "module_name": "Programming Fundamentals"
    },
    {
        "module_code": "INF1002",
        "code": "forum_INF1002_7",
        "topic": "INF1002 Week 7",
        "module_name": "Programming Fundamentals"
    },
    {
        "module_code": "INF1002",
        "code": "forum_INF1002_8",
        "topic": "INF1002 Week 8",
        "module_name": "Programming Fundamentals"
    },
    {
        "module_code": "INF1002",
        "code": "forum_INF1002_9",
        "topic": "INF1002 Week 9",
        "module_name": "Programming Fundamentals"
    },
    {
        "module_code": "INF1002",
        "code": "forum_INF1002_10",
        "topic": "INF1002 Week 10",
        "module_name": "Programming Fundamentals"
    },
    {
        "module_code": "INF1002",
        "code": "forum_INF1002_11",
        "topic": "INF1002 Week 11",
        "module_name": "Programming Fundamentals"
    },
    {
        "module_code": "INF1002",
        "code": "forum_INF1002_12",
        "topic": "INF1002 Week 12",
        "module_name": "Programming Fundamentals"
    },
    {
        "module_code": "INF1002",
        "code": "forum_INF1002_13",
        "topic": "INF1002 Week 13",
        "module_name": "Programming Fundamentals"
    },
    {
        "module_code": "INF1003",
        "code": "forum_INF1003_1",
        "topic": "INF1003 Week 1",
        "module_name": "Mathematics 1"
    },
    {
        "module_code": "INF1003",
        "code": "forum_INF1003_2",
        "topic": "INF1003 Week 2",
        "module_name": "Mathematics 1"
    },
    {
        "module_code": "INF1003",
        "code": "forum_INF1003_3",
        "topic": "INF1003 Week 3",
        "module_name": "Mathematics 1"
    },
    {
        "module_code": "INF1003",
        "code": "forum_INF1003_4",
        "topic": "INF1003 Week 4",
        "module_name": "Mathematics 1"
    },
    {
        "module_code": "INF1003",
        "code": "forum_INF1003_5",
        "topic": "INF1003 Week 5",
        "module_name": "Mathematics 1"
    },
    {
        "module_code": "INF1003",
        "code": "forum_INF1003_6",
        "topic": "INF1003 Week 6",
        "module_name": "Mathematics 1"
    },
    {
        "module_code": "INF1003",
        "code": "forum_INF1003_7",
        "topic": "INF1003 Week 7",
        "module_name": "Mathematics 1"
    },
    {
        "module_code": "INF1003",
        "code": "forum_INF1003_8",
        "topic": "INF1003 Week 8",
        "module_name": "Mathematics 1"
    },
    {
        "module_code": "INF1003",
        "code": "forum_INF1003_9",
        "topic": "INF1003 Week 9",
        "module_name": "Mathematics 1"
    },
    {
        "module_code": "INF1003",
        "code": "forum_INF1003_10",
        "topic": "INF1003 Week 10",
        "module_name": "Mathematics 1"
    },
    {
        "module_code": "INF1003",
        "code": "forum_INF1003_11",
        "topic": "INF1003 Week 11",
        "module_name": "Mathematics 1"
    },
    {
        "module_code": "INF1003",
        "code": "forum_INF1003_12",
        "topic": "INF1003 Week 12",
        "module_name": "Mathematics 1"
    },
    {
        "module_code": "INF1003",
        "code": "forum_INF1003_13",
        "topic": "INF1003 Week 13",
        "module_name": "Mathematics 1"
    },
    {
        "module_code": "UDC1001",
        "code": "forum_UDC1001_1",
        "topic": "UDC1001 Week 1",
        "module_name": "Digital Competency Essentials"
    },
    {
        "module_code": "UDC1001",
        "code": "forum_UDC1001_2",
        "topic": "UDC1001 Week 2",
        "module_name": "Digital Competency Essentials"
    },
    {
        "module_code": "UDC1001",
        "code": "forum_UDC1001_3",
        "topic": "UDC1001 Week 3",
        "module_name": "Digital Competency Essentials"
    },
    {
        "module_code": "UDC1001",
        "code": "forum_UDC1001_4",
        "topic": "UDC1001 Week 4",
        "module_name": "Digital Competency Essentials"
    },
    {
        "module_code": "UDC1001",
        "code": "forum_UDC1001_5",
        "topic": "UDC1001 Week 5",
        "module_name": "Digital Competency Essentials"
    },
    {
        "module_code": "UDC1001",
        "code": "forum_UDC1001_6",
        "topic": "UDC1001 Week 6",
        "module_name": "Digital Competency Essentials"
    },
    {
        "module_code": "UDC1001",
        "code": "forum_UDC1001_7",
        "topic": "UDC1001 Week 7",
        "module_name": "Digital Competency Essentials"
    },
    {
        "module_code": "UDC1001",
        "code": "forum_UDC1001_8",
        "topic": "UDC1001 Week 8",
        "module_name": "Digital Competency Essentials"
    },
    {
        "module_code": "UDC1001",
        "code": "forum_UDC1001_9",
        "topic": "UDC1001 Week 9",
        "module_name": "Digital Competency Essentials"
    },
    {
        "module_code": "UDC1001",
        "code": "forum_UDC1001_10",
        "topic": "UDC1001 Week 10",
        "module_name": "Digital Competency Essentials"
    },
    {
        "module_code": "UDC1001",
        "code": "forum_UDC1001_11",
        "topic": "UDC1001 Week 11",
        "module_name": "Digital Competency Essentials"
    },
    {
        "module_code": "UDC1001",
        "code": "forum_UDC1001_12",
        "topic": "UDC1001 Week 12",
        "module_name": "Digital Competency Essentials"
    },
    {
        "module_code": "UDC1001",
        "code": "forum_UDC1001_13",
        "topic": "UDC1001 Week 13",
        "module_name": "Digital Competency Essentials"
    },
    {
        "module_code": "ICT4012A",
        "code": "forum_ICT4012A_1",
        "topic": "ICT4012A Week 1",
        "module_name": "Integrated Work Study Programme (Career Skills)"
    },
    {
        "module_code": "ICT4012A",
        "code": "forum_ICT4012A_2",
        "topic": "ICT4012A Week 2",
        "module_name": "Integrated Work Study Programme (Career Skills)"
    },
    {
        "module_code": "ICT4012A",
        "code": "forum_ICT4012A_3",
        "topic": "ICT4012A Week 3",
        "module_name": "Integrated Work Study Programme (Career Skills)"
    },
    {
        "module_code": "ICT4012A",
        "code": "forum_ICT4012A_4",
        "topic": "ICT4012A Week 4",
        "module_name": "Integrated Work Study Programme (Career Skills)"
    },
    {
        "module_code": "ICT4012A",
        "code": "forum_ICT4012A_5",
        "topic": "ICT4012A Week 5",
        "module_name": "Integrated Work Study Programme (Career Skills)"
    },
    {
        "module_code": "ICT4012A",
        "code": "forum_ICT4012A_6",
        "topic": "ICT4012A Week 6",
        "module_name": "Integrated Work Study Programme (Career Skills)"
    },
    {
        "module_code": "ICT4012A",
        "code": "forum_ICT4012A_7",
        "topic": "ICT4012A Week 7",
        "module_name": "Integrated Work Study Programme (Career Skills)"
    },
    {
        "module_code": "ICT4012A",
        "code": "forum_ICT4012A_8",
        "topic": "ICT4012A Week 8",
        "module_name": "Integrated Work Study Programme (Career Skills)"
    },
    {
        "module_code": "ICT4012A",
        "code": "forum_ICT4012A_9",
        "topic": "ICT4012A Week 9",
        "module_name": "Integrated Work Study Programme (Career Skills)"
    },
    {
        "module_code": "ICT4012A",
        "code": "forum_ICT4012A_10",
        "topic": "ICT4012A Week 10",
        "module_name": "Integrated Work Study Programme (Career Skills)"
    },
    {
        "module_code": "ICT4012A",
        "code": "forum_ICT4012A_11",
        "topic": "ICT4012A Week 11",
        "module_name": "Integrated Work Study Programme (Career Skills)"
    },
    {
        "module_code": "ICT4012A",
        "code": "forum_ICT4012A_12",
        "topic": "ICT4012A Week 12",
        "module_name": "Integrated Work Study Programme (Career Skills)"
    },
    {
        "module_code": "ICT4012A",
        "code": "forum_ICT4012A_13",
        "topic": "ICT4012A Week 13",
        "module_name": "Integrated Work Study Programme (Career Skills)"
    },
    {
        "module_code": "ICT1012",
        "code": "forum_ICT1012_1",
        "topic": "ICT1012 Week 1",
        "module_name": "Operating Systems"
    },
    {
        "module_code": "ICT1012",
        "code": "forum_ICT1012_2",
        "topic": "ICT1012 Week 2",
        "module_name": "Operating Systems"
    },
    {
        "module_code": "ICT1012",
        "code": "forum_ICT1012_3",
        "topic": "ICT1012 Week 3",
        "module_name": "Operating Systems"
    },
    {
        "module_code": "ICT1012",
        "code": "forum_ICT1012_4",
        "topic": "ICT1012 Week 4",
        "module_name": "Operating Systems"
    },
    {
        "module_code": "ICT1012",
        "code": "forum_ICT1012_5",
        "topic": "ICT1012 Week 5",
        "module_name": "Operating Systems"
    },
    {
        "module_code": "ICT1012",
        "code": "forum_ICT1012_6",
        "topic": "ICT1012 Week 6",
        "module_name": "Operating Systems"
    },
    {
        "module_code": "ICT1012",
        "code": "forum_ICT1012_7",
        "topic": "ICT1012 Week 7",
        "module_name": "Operating Systems"
    },
    {
        "module_code": "ICT1012",
        "code": "forum_ICT1012_8",
        "topic": "ICT1012 Week 8",
        "module_name": "Operating Systems"
    },
    {
        "module_code": "ICT1012",
        "code": "forum_ICT1012_9",
        "topic": "ICT1012 Week 9",
        "module_name": "Operating Systems"
    },
    {
        "module_code": "ICT1012",
        "code": "forum_ICT1012_10",
        "topic": "ICT1012 Week 10",
        "module_name": "Operating Systems"
    },
    {
        "module_code": "ICT1012",
        "code": "forum_ICT1012_11",
        "topic": "ICT1012 Week 11",
        "module_name": "Operating Systems"
    },
    {
        "module_code": "ICT1012",
        "code": "forum_ICT1012_12",
        "topic": "ICT1012 Week 12",
        "module_name": "Operating Systems"
    },
    {
        "module_code": "ICT1012",
        "code": "forum_ICT1012_13",
        "topic": "ICT1012 Week 13",
        "module_name": "Operating Systems"
    },
    {
        "module_code": "INF1004",
        "code": "forum_INF1004_1",
        "topic": "INF1004 Week 1",
        "module_name": "Mathematics 2"
    },
    {
        "module_code": "INF1004",
        "code": "forum_INF1004_2",
        "topic": "INF1004 Week 2",
        "module_name": "Mathematics 2"
    },
    {
        "module_code": "INF1004",
        "code": "forum_INF1004_3",
        "topic": "INF1004 Week 3",
        "module_name": "Mathematics 2"
    },
    {
        "module_code": "INF1004",
        "code": "forum_INF1004_4",
        "topic": "INF1004 Week 4",
        "module_name": "Mathematics 2"
    },
    {
        "module_code": "INF1004",
        "code": "forum_INF1004_5",
        "topic": "INF1004 Week 5",
        "module_name": "Mathematics 2"
    },
    {
        "module_code": "INF1004",
        "code": "forum_INF1004_6",
        "topic": "INF1004 Week 6",
        "module_name": "Mathematics 2"
    },
    {
        "module_code": "INF1004",
        "code": "forum_INF1004_7",
        "topic": "INF1004 Week 7",
        "module_name": "Mathematics 2"
    },
    {
        "module_code": "INF1004",
        "code": "forum_INF1004_8",
        "topic": "INF1004 Week 8",
        "module_name": "Mathematics 2"
    },
    {
        "module_code": "INF1004",
        "code": "forum_INF1004_9",
        "topic": "INF1004 Week 9",
        "module_name": "Mathematics 2"
    },
    {
        "module_code": "INF1004",
        "code": "forum_INF1004_10",
        "topic": "INF1004 Week 10",
        "module_name": "Mathematics 2"
    },
    {
        "module_code": "INF1004",
        "code": "forum_INF1004_11",
        "topic": "INF1004 Week 11",
        "module_name": "Mathematics 2"
    },
    {
        "module_code": "INF1004",
        "code": "forum_INF1004_12",
        "topic": "INF1004 Week 12",
        "module_name": "Mathematics 2"
    },
    {
        "module_code": "INF1004",
        "code": "forum_INF1004_13",
        "topic": "INF1004 Week 13",
        "module_name": "Mathematics 2"
    },
    {
        "module_code": "INF1005",
        "code": "forum_INF1005_1",
        "topic": "INF1005 Week 1",
        "module_name": "Web Systems & Technologies"
    },
    {
        "module_code": "INF1005",
        "code": "forum_INF1005_2",
        "topic": "INF1005 Week 2",
        "module_name": "Web Systems & Technologies"
    },
    {
        "module_code": "INF1005",
        "code": "forum_INF1005_3",
        "topic": "INF1005 Week 3",
        "module_name": "Web Systems & Technologies"
    },
    {
        "module_code": "INF1005",
        "code": "forum_INF1005_4",
        "topic": "INF1005 Week 4",
        "module_name": "Web Systems & Technologies"
    },
    {
        "module_code": "INF1005",
        "code": "forum_INF1005_5",
        "topic": "INF1005 Week 5",
        "module_name": "Web Systems & Technologies"
    },
    {
        "module_code": "INF1005",
        "code": "forum_INF1005_6",
        "topic": "INF1005 Week 6",
        "module_name": "Web Systems & Technologies"
    },
    {
        "module_code": "INF1005",
        "code": "forum_INF1005_7",
        "topic": "INF1005 Week 7",
        "module_name": "Web Systems & Technologies"
    },
    {
        "module_code": "INF1005",
        "code": "forum_INF1005_8",
        "topic": "INF1005 Week 8",
        "module_name": "Web Systems & Technologies"
    },
    {
        "module_code": "INF1005",
        "code": "forum_INF1005_9",
        "topic": "INF1005 Week 9",
        "module_name": "Web Systems & Technologies"
    },
    {
        "module_code": "INF1005",
        "code": "forum_INF1005_10",
        "topic": "INF1005 Week 10",
        "module_name": "Web Systems & Technologies"
    },
    {
        "module_code": "INF1005",
        "code": "forum_INF1005_11",
        "topic": "INF1005 Week 11",
        "module_name": "Web Systems & Technologies"
    },
    {
        "module_code": "INF1005",
        "code": "forum_INF1005_12",
        "topic": "INF1005 Week 12",
        "module_name": "Web Systems & Technologies"
    },
    {
        "module_code": "INF1005",
        "code": "forum_INF1005_13",
        "topic": "INF1005 Week 13",
        "module_name": "Web Systems & Technologies"
    },
    {
        "module_code": "INF1009",
        "code": "forum_INF1009_1",
        "topic": "INF1009 Week 1",
        "module_name": "Object-Oriented Programming"
    },
    {
        "module_code": "INF1009",
        "code": "forum_INF1009_2",
        "topic": "INF1009 Week 2",
        "module_name": "Object-Oriented Programming"
    },
    {
        "module_code": "INF1009",
        "code": "forum_INF1009_3",
        "topic": "INF1009 Week 3",
        "module_name": "Object-Oriented Programming"
    },
    {
        "module_code": "INF1009",
        "code": "forum_INF1009_4",
        "topic": "INF1009 Week 4",
        "module_name": "Object-Oriented Programming"
    },
    {
        "module_code": "INF1009",
        "code": "forum_INF1009_5",
        "topic": "INF1009 Week 5",
        "module_name": "Object-Oriented Programming"
    },
    {
        "module_code": "INF1009",
        "code": "forum_INF1009_6",
        "topic": "INF1009 Week 6",
        "module_name": "Object-Oriented Programming"
    },
    {
        "module_code": "INF1009",
        "code": "forum_INF1009_7",
        "topic": "INF1009 Week 7",
        "module_name": "Object-Oriented Programming"
    },
    {
        "module_code": "INF1009",
        "code": "forum_INF1009_8",
        "topic": "INF1009 Week 8",
        "module_name": "Object-Oriented Programming"
    },
    {
        "module_code": "INF1009",
        "code": "forum_INF1009_9",
        "topic": "INF1009 Week 9",
        "module_name": "Object-Oriented Programming"
    },
    {
        "module_code": "INF1009",
        "code": "forum_INF1009_10",
        "topic": "INF1009 Week 10",
        "module_name": "Object-Oriented Programming"
    },
    {
        "module_code": "INF1009",
        "code": "forum_INF1009_11",
        "topic": "INF1009 Week 11",
        "module_name": "Object-Oriented Programming"
    },
    {
        "module_code": "INF1009",
        "code": "forum_INF1009_12",
        "topic": "INF1009 Week 12",
        "module_name": "Object-Oriented Programming"
    },
    {
        "module_code": "INF1009",
        "code": "forum_INF1009_13",
        "topic": "INF1009 Week 13",
        "module_name": "Object-Oriented Programming"
    },
    {
        "module_code": "UCS1001",
        "code": "forum_UCS1001_1",
        "topic": "UCS1001 Week 1",
        "module_name": "Critical Thinking and Communicating"
    },
    {
        "module_code": "UCS1001",
        "code": "forum_UCS1001_2",
        "topic": "UCS1001 Week 2",
        "module_name": "Critical Thinking and Communicating"
    },
    {
        "module_code": "UCS1001",
        "code": "forum_UCS1001_3",
        "topic": "UCS1001 Week 3",
        "module_name": "Critical Thinking and Communicating"
    },
    {
        "module_code": "UCS1001",
        "code": "forum_UCS1001_4",
        "topic": "UCS1001 Week 4",
        "module_name": "Critical Thinking and Communicating"
    },
    {
        "module_code": "UCS1001",
        "code": "forum_UCS1001_5",
        "topic": "UCS1001 Week 5",
        "module_name": "Critical Thinking and Communicating"
    },
    {
        "module_code": "UCS1001",
        "code": "forum_UCS1001_6",
        "topic": "UCS1001 Week 6",
        "module_name": "Critical Thinking and Communicating"
    },
    {
        "module_code": "UCS1001",
        "code": "forum_UCS1001_7",
        "topic": "UCS1001 Week 7",
        "module_name": "Critical Thinking and Communicating"
    },
    {
        "module_code": "UCS1001",
        "code": "forum_UCS1001_8",
        "topic": "UCS1001 Week 8",
        "module_name": "Critical Thinking and Communicating"
    },
    {
        "module_code": "UCS1001",
        "code": "forum_UCS1001_9",
        "topic": "UCS1001 Week 9",
        "module_name": "Critical Thinking and Communicating"
    },
    {
        "module_code": "UCS1001",
        "code": "forum_UCS1001_10",
        "topic": "UCS1001 Week 10",
        "module_name": "Critical Thinking and Communicating"
    },
    {
        "module_code": "UCS1001",
        "code": "forum_UCS1001_11",
        "topic": "UCS1001 Week 11",
        "module_name": "Critical Thinking and Communicating"
    },
    {
        "module_code": "UCS1001",
        "code": "forum_UCS1001_12",
        "topic": "UCS1001 Week 12",
        "module_name": "Critical Thinking and Communicating"
    },
    {
        "module_code": "UCS1001",
        "code": "forum_UCS1001_13",
        "topic": "UCS1001 Week 13",
        "module_name": "Critical Thinking and Communicating"
    },
    {
        "module_code": "ICT4012A",
        "code": "forum_ICT4012A_1",
        "topic": "ICT4012A Week 1",
        "module_name": "Integrated Work Study Programme (Career Skills)"
    },
    {
        "module_code": "ICT4012A",
        "code": "forum_ICT4012A_2",
        "topic": "ICT4012A Week 2",
        "module_name": "Integrated Work Study Programme (Career Skills)"
    },
    {
        "module_code": "ICT4012A",
        "code": "forum_ICT4012A_3",
        "topic": "ICT4012A Week 3",
        "module_name": "Integrated Work Study Programme (Career Skills)"
    },
    {
        "module_code": "ICT4012A",
        "code": "forum_ICT4012A_4",
        "topic": "ICT4012A Week 4",
        "module_name": "Integrated Work Study Programme (Career Skills)"
    },
    {
        "module_code": "ICT4012A",
        "code": "forum_ICT4012A_5",
        "topic": "ICT4012A Week 5",
        "module_name": "Integrated Work Study Programme (Career Skills)"
    },
    {
        "module_code": "ICT4012A",
        "code": "forum_ICT4012A_6",
        "topic": "ICT4012A Week 6",
        "module_name": "Integrated Work Study Programme (Career Skills)"
    },
    {
        "module_code": "ICT4012A",
        "code": "forum_ICT4012A_7",
        "topic": "ICT4012A Week 7",
        "module_name": "Integrated Work Study Programme (Career Skills)"
    },
    {
        "module_code": "ICT4012A",
        "code": "forum_ICT4012A_8",
        "topic": "ICT4012A Week 8",
        "module_name": "Integrated Work Study Programme (Career Skills)"
    },
    {
        "module_code": "ICT4012A",
        "code": "forum_ICT4012A_9",
        "topic": "ICT4012A Week 9",
        "module_name": "Integrated Work Study Programme (Career Skills)"
    },
    {
        "module_code": "ICT4012A",
        "code": "forum_ICT4012A_10",
        "topic": "ICT4012A Week 10",
        "module_name": "Integrated Work Study Programme (Career Skills)"
    },
    {
        "module_code": "ICT4012A",
        "code": "forum_ICT4012A_11",
        "topic": "ICT4012A Week 11",
        "module_name": "Integrated Work Study Programme (Career Skills)"
    },
    {
        "module_code": "ICT4012A",
        "code": "forum_ICT4012A_12",
        "topic": "ICT4012A Week 12",
        "module_name": "Integrated Work Study Programme (Career Skills)"
    },
    {
        "module_code": "ICT4012A",
        "code": "forum_ICT4012A_13",
        "topic": "ICT4012A Week 13",
        "module_name": "Integrated Work Study Programme (Career Skills)"
    },
    {
        "module_code": "INF1006",
        "code": "forum_INF1006_1",
        "topic": "INF1006 Week 1",
        "module_name": "Computer Networks"
    },
    {
        "module_code": "INF1006",
        "code": "forum_INF1006_2",
        "topic": "INF1006 Week 2",
        "module_name": "Computer Networks"
    },
    {
        "module_code": "INF1006",
        "code": "forum_INF1006_3",
        "topic": "INF1006 Week 3",
        "module_name": "Computer Networks"
    },
    {
        "module_code": "INF1006",
        "code": "forum_INF1006_4",
        "topic": "INF1006 Week 4",
        "module_name": "Computer Networks"
    },
    {
        "module_code": "INF1006",
        "code": "forum_INF1006_5",
        "topic": "INF1006 Week 5",
        "module_name": "Computer Networks"
    },
    {
        "module_code": "INF1006",
        "code": "forum_INF1006_6",
        "topic": "INF1006 Week 6",
        "module_name": "Computer Networks"
    },
    {
        "module_code": "INF1006",
        "code": "forum_INF1006_7",
        "topic": "INF1006 Week 7",
        "module_name": "Computer Networks"
    },
    {
        "module_code": "INF1006",
        "code": "forum_INF1006_8",
        "topic": "INF1006 Week 8",
        "module_name": "Computer Networks"
    },
    {
        "module_code": "INF1006",
        "code": "forum_INF1006_9",
        "topic": "INF1006 Week 9",
        "module_name": "Computer Networks"
    },
    {
        "module_code": "INF1006",
        "code": "forum_INF1006_10",
        "topic": "INF1006 Week 10",
        "module_name": "Computer Networks"
    },
    {
        "module_code": "INF1006",
        "code": "forum_INF1006_11",
        "topic": "INF1006 Week 11",
        "module_name": "Computer Networks"
    },
    {
        "module_code": "INF1006",
        "code": "forum_INF1006_12",
        "topic": "INF1006 Week 12",
        "module_name": "Computer Networks"
    },
    {
        "module_code": "INF1006",
        "code": "forum_INF1006_13",
        "topic": "INF1006 Week 13",
        "module_name": "Computer Networks"
    },
    {
        "module_code": "INF1007",
        "code": "forum_INF1007_1",
        "topic": "INF1007 Week 1",
        "module_name": "Ethics and Professional Conducts"
    },
    {
        "module_code": "INF1007",
        "code": "forum_INF1007_2",
        "topic": "INF1007 Week 2",
        "module_name": "Ethics and Professional Conducts"
    },
    {
        "module_code": "INF1007",
        "code": "forum_INF1007_3",
        "topic": "INF1007 Week 3",
        "module_name": "Ethics and Professional Conducts"
    },
    {
        "module_code": "INF1007",
        "code": "forum_INF1007_4",
        "topic": "INF1007 Week 4",
        "module_name": "Ethics and Professional Conducts"
    },
    {
        "module_code": "INF1007",
        "code": "forum_INF1007_5",
        "topic": "INF1007 Week 5",
        "module_name": "Ethics and Professional Conducts"
    },
    {
        "module_code": "INF1007",
        "code": "forum_INF1007_6",
        "topic": "INF1007 Week 6",
        "module_name": "Ethics and Professional Conducts"
    },
    {
        "module_code": "INF1007",
        "code": "forum_INF1007_7",
        "topic": "INF1007 Week 7",
        "module_name": "Ethics and Professional Conducts"
    },
    {
        "module_code": "INF1007",
        "code": "forum_INF1007_8",
        "topic": "INF1007 Week 8",
        "module_name": "Ethics and Professional Conducts"
    },
    {
        "module_code": "INF1007",
        "code": "forum_INF1007_9",
        "topic": "INF1007 Week 9",
        "module_name": "Ethics and Professional Conducts"
    },
    {
        "module_code": "INF1007",
        "code": "forum_INF1007_10",
        "topic": "INF1007 Week 10",
        "module_name": "Ethics and Professional Conducts"
    },
    {
        "module_code": "INF1007",
        "code": "forum_INF1007_11",
        "topic": "INF1007 Week 11",
        "module_name": "Ethics and Professional Conducts"
    },
    {
        "module_code": "INF1007",
        "code": "forum_INF1007_12",
        "topic": "INF1007 Week 12",
        "module_name": "Ethics and Professional Conducts"
    },
    {
        "module_code": "INF1007",
        "code": "forum_INF1007_13",
        "topic": "INF1007 Week 13",
        "module_name": "Ethics and Professional Conducts"
    },
    {
        "module_code": "INF1008",
        "code": "forum_INF1008_1",
        "topic": "INF1008 Week 1",
        "module_name": "Data Structures and Algorithms"
    },
    {
        "module_code": "INF1008",
        "code": "forum_INF1008_2",
        "topic": "INF1008 Week 2",
        "module_name": "Data Structures and Algorithms"
    },
    {
        "module_code": "INF1008",
        "code": "forum_INF1008_3",
        "topic": "INF1008 Week 3",
        "module_name": "Data Structures and Algorithms"
    },
    {
        "module_code": "INF1008",
        "code": "forum_INF1008_4",
        "topic": "INF1008 Week 4",
        "module_name": "Data Structures and Algorithms"
    },
    {
        "module_code": "INF1008",
        "code": "forum_INF1008_5",
        "topic": "INF1008 Week 5",
        "module_name": "Data Structures and Algorithms"
    },
    {
        "module_code": "INF1008",
        "code": "forum_INF1008_6",
        "topic": "INF1008 Week 6",
        "module_name": "Data Structures and Algorithms"
    },
    {
        "module_code": "INF1008",
        "code": "forum_INF1008_7",
        "topic": "INF1008 Week 7",
        "module_name": "Data Structures and Algorithms"
    },
    {
        "module_code": "INF1008",
        "code": "forum_INF1008_8",
        "topic": "INF1008 Week 8",
        "module_name": "Data Structures and Algorithms"
    },
    {
        "module_code": "INF1008",
        "code": "forum_INF1008_9",
        "topic": "INF1008 Week 9",
        "module_name": "Data Structures and Algorithms"
    },
    {
        "module_code": "INF1008",
        "code": "forum_INF1008_10",
        "topic": "INF1008 Week 10",
        "module_name": "Data Structures and Algorithms"
    },
    {
        "module_code": "INF1008",
        "code": "forum_INF1008_11",
        "topic": "INF1008 Week 11",
        "module_name": "Data Structures and Algorithms"
    },
    {
        "module_code": "INF1008",
        "code": "forum_INF1008_12",
        "topic": "INF1008 Week 12",
        "module_name": "Data Structures and Algorithms"
    },
    {
        "module_code": "INF1008",
        "code": "forum_INF1008_13",
        "topic": "INF1008 Week 13",
        "module_name": "Data Structures and Algorithms"
    },
    {
        "module_code": "UDE1001",
        "code": "forum_UDE1001_1",
        "topic": "UDE1001 Week 1",
        "module_name": "Introduction to Design Innovation"
    },
    {
        "module_code": "UDE1001",
        "code": "forum_UDE1001_2",
        "topic": "UDE1001 Week 2",
        "module_name": "Introduction to Design Innovation"
    },
    {
        "module_code": "UDE1001",
        "code": "forum_UDE1001_3",
        "topic": "UDE1001 Week 3",
        "module_name": "Introduction to Design Innovation"
    },
    {
        "module_code": "UDE1001",
        "code": "forum_UDE1001_4",
        "topic": "UDE1001 Week 4",
        "module_name": "Introduction to Design Innovation"
    },
    {
        "module_code": "UDE1001",
        "code": "forum_UDE1001_5",
        "topic": "UDE1001 Week 5",
        "module_name": "Introduction to Design Innovation"
    },
    {
        "module_code": "UDE1001",
        "code": "forum_UDE1001_6",
        "topic": "UDE1001 Week 6",
        "module_name": "Introduction to Design Innovation"
    },
    {
        "module_code": "UDE1001",
        "code": "forum_UDE1001_7",
        "topic": "UDE1001 Week 7",
        "module_name": "Introduction to Design Innovation"
    },
    {
        "module_code": "UDE1001",
        "code": "forum_UDE1001_8",
        "topic": "UDE1001 Week 8",
        "module_name": "Introduction to Design Innovation"
    },
    {
        "module_code": "UDE1001",
        "code": "forum_UDE1001_9",
        "topic": "UDE1001 Week 9",
        "module_name": "Introduction to Design Innovation"
    },
    {
        "module_code": "UDE1001",
        "code": "forum_UDE1001_10",
        "topic": "UDE1001 Week 10",
        "module_name": "Introduction to Design Innovation"
    },
    {
        "module_code": "UDE1001",
        "code": "forum_UDE1001_11",
        "topic": "UDE1001 Week 11",
        "module_name": "Introduction to Design Innovation"
    },
    {
        "module_code": "UDE1001",
        "code": "forum_UDE1001_12",
        "topic": "UDE1001 Week 12",
        "module_name": "Introduction to Design Innovation"
    },
    {
        "module_code": "UDE1001",
        "code": "forum_UDE1001_13",
        "topic": "UDE1001 Week 13",
        "module_name": "Introduction to Design Innovation"
    },
    {
        "module_code": "ICT4012A",
        "code": "forum_ICT4012A_1",
        "topic": "ICT4012A Week 1",
        "module_name": "Integrated Work Study Programme (Career Skills)"
    },
    {
        "module_code": "ICT4012A",
        "code": "forum_ICT4012A_2",
        "topic": "ICT4012A Week 2",
        "module_name": "Integrated Work Study Programme (Career Skills)"
    },
    {
        "module_code": "ICT4012A",
        "code": "forum_ICT4012A_3",
        "topic": "ICT4012A Week 3",
        "module_name": "Integrated Work Study Programme (Career Skills)"
    },
    {
        "module_code": "ICT4012A",
        "code": "forum_ICT4012A_4",
        "topic": "ICT4012A Week 4",
        "module_name": "Integrated Work Study Programme (Career Skills)"
    },
    {
        "module_code": "ICT4012A",
        "code": "forum_ICT4012A_5",
        "topic": "ICT4012A Week 5",
        "module_name": "Integrated Work Study Programme (Career Skills)"
    },
    {
        "module_code": "ICT4012A",
        "code": "forum_ICT4012A_6",
        "topic": "ICT4012A Week 6",
        "module_name": "Integrated Work Study Programme (Career Skills)"
    },
    {
        "module_code": "ICT4012A",
        "code": "forum_ICT4012A_7",
        "topic": "ICT4012A Week 7",
        "module_name": "Integrated Work Study Programme (Career Skills)"
    },
    {
        "module_code": "ICT4012A",
        "code": "forum_ICT4012A_8",
        "topic": "ICT4012A Week 8",
        "module_name": "Integrated Work Study Programme (Career Skills)"
    },
    {
        "module_code": "ICT4012A",
        "code": "forum_ICT4012A_9",
        "topic": "ICT4012A Week 9",
        "module_name": "Integrated Work Study Programme (Career Skills)"
    },
    {
        "module_code": "ICT4012A",
        "code": "forum_ICT4012A_10",
        "topic": "ICT4012A Week 10",
        "module_name": "Integrated Work Study Programme (Career Skills)"
    },
    {
        "module_code": "ICT4012A",
        "code": "forum_ICT4012A_11",
        "topic": "ICT4012A Week 11",
        "module_name": "Integrated Work Study Programme (Career Skills)"
    },
    {
        "module_code": "ICT4012A",
        "code": "forum_ICT4012A_12",
        "topic": "ICT4012A Week 12",
        "module_name": "Integrated Work Study Programme (Career Skills)"
    },
    {
        "module_code": "ICT4012A",
        "code": "forum_ICT4012A_13",
        "topic": "ICT4012A Week 13",
        "module_name": "Integrated Work Study Programme (Career Skills)"
    },
    {
        "module_code": "INF2001",
        "code": "forum_INF2001_1",
        "topic": "INF2001 Week 1",
        "module_name": "Introduction to Software Engineering"
    },
    {
        "module_code": "INF2001",
        "code": "forum_INF2001_2",
        "topic": "INF2001 Week 2",
        "module_name": "Introduction to Software Engineering"
    },
    {
        "module_code": "INF2001",
        "code": "forum_INF2001_3",
        "topic": "INF2001 Week 3",
        "module_name": "Introduction to Software Engineering"
    },
    {
        "module_code": "INF2001",
        "code": "forum_INF2001_4",
        "topic": "INF2001 Week 4",
        "module_name": "Introduction to Software Engineering"
    },
    {
        "module_code": "INF2001",
        "code": "forum_INF2001_5",
        "topic": "INF2001 Week 5",
        "module_name": "Introduction to Software Engineering"
    },
    {
        "module_code": "INF2001",
        "code": "forum_INF2001_6",
        "topic": "INF2001 Week 6",
        "module_name": "Introduction to Software Engineering"
    },
    {
        "module_code": "INF2001",
        "code": "forum_INF2001_7",
        "topic": "INF2001 Week 7",
        "module_name": "Introduction to Software Engineering"
    },
    {
        "module_code": "INF2001",
        "code": "forum_INF2001_8",
        "topic": "INF2001 Week 8",
        "module_name": "Introduction to Software Engineering"
    },
    {
        "module_code": "INF2001",
        "code": "forum_INF2001_9",
        "topic": "INF2001 Week 9",
        "module_name": "Introduction to Software Engineering"
    },
    {
        "module_code": "INF2001",
        "code": "forum_INF2001_10",
        "topic": "INF2001 Week 10",
        "module_name": "Introduction to Software Engineering"
    },
    {
        "module_code": "INF2001",
        "code": "forum_INF2001_11",
        "topic": "INF2001 Week 11",
        "module_name": "Introduction to Software Engineering"
    },
    {
        "module_code": "INF2001",
        "code": "forum_INF2001_12",
        "topic": "INF2001 Week 12",
        "module_name": "Introduction to Software Engineering"
    },
    {
        "module_code": "INF2001",
        "code": "forum_INF2001_13",
        "topic": "INF2001 Week 13",
        "module_name": "Introduction to Software Engineering"
    },
    {
        "module_code": "INF2002",
        "code": "History_of_HCI",
        "topic": "History of HCI",
        "module_name": "Human Computer Interaction"
    },
    {
        "module_code": "INF2002",
        "code": "User_Centered_Design",
        "topic": "User Centered Design",
        "module_name": "Human Computer Interaction"
    },
    {
        "module_code": "INF2002",
        "code": "Needfinding",
        "topic": "Needfinding",
        "module_name": "Human Computer Interaction"
    },
    {
        "module_code": "INF2002",
        "code": "Task_Analysis",
        "topic": "Task Analysis",
        "module_name": "Human Computer Interaction"
    },
    {
        "module_code": "INF2002",
        "code": "Usability_Principles",
        "topic": "Usability Principles",
        "module_name": "Human Computer Interaction"
    },
    {
        "module_code": "INF2002",
        "code": "Ethics",
        "topic": "Ethics",
        "module_name": "Human Computer Interaction"
    },
    {
        "module_code": "INF2002",
        "code": "Prototyping",
        "topic": "Prototyping",
        "module_name": "Human Computer Interaction"
    },
    {
        "module_code": "INF2003",
        "code": "forum_INF2003_1",
        "topic": "INF2003 Week 1",
        "module_name": "Database Systems"
    },
    {
        "module_code": "INF2003",
        "code": "forum_INF2003_2",
        "topic": "INF2003 Week 2",
        "module_name": "Database Systems"
    },
    {
        "module_code": "INF2003",
        "code": "forum_INF2003_3",
        "topic": "INF2003 Week 3",
        "module_name": "Database Systems"
    },
    {
        "module_code": "INF2003",
        "code": "forum_INF2003_4",
        "topic": "INF2003 Week 4",
        "module_name": "Database Systems"
    },
    {
        "module_code": "INF2003",
        "code": "forum_INF2003_5",
        "topic": "INF2003 Week 5",
        "module_name": "Database Systems"
    },
    {
        "module_code": "INF2003",
        "code": "forum_INF2003_6",
        "topic": "INF2003 Week 6",
        "module_name": "Database Systems"
    },
    {
        "module_code": "INF2003",
        "code": "forum_INF2003_7",
        "topic": "INF2003 Week 7",
        "module_name": "Database Systems"
    },
    {
        "module_code": "INF2003",
        "code": "forum_INF2003_8",
        "topic": "INF2003 Week 8",
        "module_name": "Database Systems"
    },
    {
        "module_code": "INF2003",
        "code": "forum_INF2003_9",
        "topic": "INF2003 Week 9",
        "module_name": "Database Systems"
    },
    {
        "module_code": "INF2003",
        "code": "forum_INF2003_10",
        "topic": "INF2003 Week 10",
        "module_name": "Database Systems"
    },
    {
        "module_code": "INF2003",
        "code": "forum_INF2003_11",
        "topic": "INF2003 Week 11",
        "module_name": "Database Systems"
    },
    {
        "module_code": "INF2003",
        "code": "forum_INF2003_12",
        "topic": "INF2003 Week 12",
        "module_name": "Database Systems"
    },
    {
        "module_code": "INF2003",
        "code": "forum_INF2003_13",
        "topic": "INF2003 Week 13",
        "module_name": "Database Systems"
    },
    {
        "module_code": "INF2004",
        "code": "forum_INF2004_1",
        "topic": "INF2004 Week 1",
        "module_name": "Embedded Systems Programming"
    },
    {
        "module_code": "INF2004",
        "code": "forum_INF2004_2",
        "topic": "INF2004 Week 2",
        "module_name": "Embedded Systems Programming"
    },
    {
        "module_code": "INF2004",
        "code": "forum_INF2004_3",
        "topic": "INF2004 Week 3",
        "module_name": "Embedded Systems Programming"
    },
    {
        "module_code": "INF2004",
        "code": "forum_INF2004_4",
        "topic": "INF2004 Week 4",
        "module_name": "Embedded Systems Programming"
    },
    {
        "module_code": "INF2004",
        "code": "forum_INF2004_5",
        "topic": "INF2004 Week 5",
        "module_name": "Embedded Systems Programming"
    },
    {
        "module_code": "INF2004",
        "code": "forum_INF2004_6",
        "topic": "INF2004 Week 6",
        "module_name": "Embedded Systems Programming"
    },
    {
        "module_code": "INF2004",
        "code": "forum_INF2004_7",
        "topic": "INF2004 Week 7",
        "module_name": "Embedded Systems Programming"
    },
    {
        "module_code": "INF2004",
        "code": "forum_INF2004_8",
        "topic": "INF2004 Week 8",
        "module_name": "Embedded Systems Programming"
    },
    {
        "module_code": "INF2004",
        "code": "forum_INF2004_9",
        "topic": "INF2004 Week 9",
        "module_name": "Embedded Systems Programming"
    },
    {
        "module_code": "INF2004",
        "code": "forum_INF2004_10",
        "topic": "INF2004 Week 10",
        "module_name": "Embedded Systems Programming"
    },
    {
        "module_code": "INF2004",
        "code": "forum_INF2004_11",
        "topic": "INF2004 Week 11",
        "module_name": "Embedded Systems Programming"
    },
    {
        "module_code": "INF2004",
        "code": "forum_INF2004_12",
        "topic": "INF2004 Week 12",
        "module_name": "Embedded Systems Programming"
    },
    {
        "module_code": "INF2004",
        "code": "forum_INF2004_13",
        "topic": "INF2004 Week 13",
        "module_name": "Embedded Systems Programming"
    },
    {
        "module_code": "UDE2001",
        "code": "forum_UDE2001_1",
        "topic": "UDE2001 Week 1",
        "module_name": "Interdisciplinary Design Innovation"
    },
    {
        "module_code": "UDE2001",
        "code": "forum_UDE2001_2",
        "topic": "UDE2001 Week 2",
        "module_name": "Interdisciplinary Design Innovation"
    },
    {
        "module_code": "UDE2001",
        "code": "forum_UDE2001_3",
        "topic": "UDE2001 Week 3",
        "module_name": "Interdisciplinary Design Innovation"
    },
    {
        "module_code": "UDE2001",
        "code": "forum_UDE2001_4",
        "topic": "UDE2001 Week 4",
        "module_name": "Interdisciplinary Design Innovation"
    },
    {
        "module_code": "UDE2001",
        "code": "forum_UDE2001_5",
        "topic": "UDE2001 Week 5",
        "module_name": "Interdisciplinary Design Innovation"
    },
    {
        "module_code": "UDE2001",
        "code": "forum_UDE2001_6",
        "topic": "UDE2001 Week 6",
        "module_name": "Interdisciplinary Design Innovation"
    },
    {
        "module_code": "UDE2001",
        "code": "forum_UDE2001_7",
        "topic": "UDE2001 Week 7",
        "module_name": "Interdisciplinary Design Innovation"
    },
    {
        "module_code": "UDE2001",
        "code": "forum_UDE2001_8",
        "topic": "UDE2001 Week 8",
        "module_name": "Interdisciplinary Design Innovation"
    },
    {
        "module_code": "UDE2001",
        "code": "forum_UDE2001_9",
        "topic": "UDE2001 Week 9",
        "module_name": "Interdisciplinary Design Innovation"
    },
    {
        "module_code": "UDE2001",
        "code": "forum_UDE2001_10",
        "topic": "UDE2001 Week 10",
        "module_name": "Interdisciplinary Design Innovation"
    },
    {
        "module_code": "UDE2001",
        "code": "forum_UDE2001_11",
        "topic": "UDE2001 Week 11",
        "module_name": "Interdisciplinary Design Innovation"
    },
    {
        "module_code": "UDE2001",
        "code": "forum_UDE2001_12",
        "topic": "UDE2001 Week 12",
        "module_name": "Interdisciplinary Design Innovation"
    },
    {
        "module_code": "UDE2001",
        "code": "forum_UDE2001_13",
        "topic": "UDE2001 Week 13",
        "module_name": "Interdisciplinary Design Innovation"
    },
    {
        "module_code": "ICT4012A",
        "code": "forum_ICT4012A_1",
        "topic": "ICT4012A Week 1",
        "module_name": "Integrated Work Study Programme (Career Skills)"
    },
    {
        "module_code": "ICT4012A",
        "code": "forum_ICT4012A_2",
        "topic": "ICT4012A Week 2",
        "module_name": "Integrated Work Study Programme (Career Skills)"
    },
    {
        "module_code": "ICT4012A",
        "code": "forum_ICT4012A_3",
        "topic": "ICT4012A Week 3",
        "module_name": "Integrated Work Study Programme (Career Skills)"
    },
    {
        "module_code": "ICT4012A",
        "code": "forum_ICT4012A_4",
        "topic": "ICT4012A Week 4",
        "module_name": "Integrated Work Study Programme (Career Skills)"
    },
    {
        "module_code": "ICT4012A",
        "code": "forum_ICT4012A_5",
        "topic": "ICT4012A Week 5",
        "module_name": "Integrated Work Study Programme (Career Skills)"
    },
    {
        "module_code": "ICT4012A",
        "code": "forum_ICT4012A_6",
        "topic": "ICT4012A Week 6",
        "module_name": "Integrated Work Study Programme (Career Skills)"
    },
    {
        "module_code": "ICT4012A",
        "code": "forum_ICT4012A_7",
        "topic": "ICT4012A Week 7",
        "module_name": "Integrated Work Study Programme (Career Skills)"
    },
    {
        "module_code": "ICT4012A",
        "code": "forum_ICT4012A_8",
        "topic": "ICT4012A Week 8",
        "module_name": "Integrated Work Study Programme (Career Skills)"
    },
    {
        "module_code": "ICT4012A",
        "code": "forum_ICT4012A_9",
        "topic": "ICT4012A Week 9",
        "module_name": "Integrated Work Study Programme (Career Skills)"
    },
    {
        "module_code": "ICT4012A",
        "code": "forum_ICT4012A_10",
        "topic": "ICT4012A Week 10",
        "module_name": "Integrated Work Study Programme (Career Skills)"
    },
    {
        "module_code": "ICT4012A",
        "code": "forum_ICT4012A_11",
        "topic": "ICT4012A Week 11",
        "module_name": "Integrated Work Study Programme (Career Skills)"
    },
    {
        "module_code": "ICT4012A",
        "code": "forum_ICT4012A_12",
        "topic": "ICT4012A Week 12",
        "module_name": "Integrated Work Study Programme (Career Skills)"
    },
    {
        "module_code": "ICT4012A",
        "code": "forum_ICT4012A_13",
        "topic": "ICT4012A Week 13",
        "module_name": "Integrated Work Study Programme (Career Skills)"
    },
    {
        "module_code": "ICT2112",
        "code": "forum_ICT2112_1",
        "topic": "ICT2112 Week 1",
        "module_name": "Software Design"
    },
    {
        "module_code": "ICT2112",
        "code": "forum_ICT2112_2",
        "topic": "ICT2112 Week 2",
        "module_name": "Software Design"
    },
    {
        "module_code": "ICT2112",
        "code": "forum_ICT2112_3",
        "topic": "ICT2112 Week 3",
        "module_name": "Software Design"
    },
    {
        "module_code": "ICT2112",
        "code": "forum_ICT2112_4",
        "topic": "ICT2112 Week 4",
        "module_name": "Software Design"
    },
    {
        "module_code": "ICT2112",
        "code": "forum_ICT2112_5",
        "topic": "ICT2112 Week 5",
        "module_name": "Software Design"
    },
    {
        "module_code": "ICT2112",
        "code": "forum_ICT2112_6",
        "topic": "ICT2112 Week 6",
        "module_name": "Software Design"
    },
    {
        "module_code": "ICT2112",
        "code": "forum_ICT2112_7",
        "topic": "ICT2112 Week 7",
        "module_name": "Software Design"
    },
    {
        "module_code": "ICT2112",
        "code": "forum_ICT2112_8",
        "topic": "ICT2112 Week 8",
        "module_name": "Software Design"
    },
    {
        "module_code": "ICT2112",
        "code": "forum_ICT2112_9",
        "topic": "ICT2112 Week 9",
        "module_name": "Software Design"
    },
    {
        "module_code": "ICT2112",
        "code": "forum_ICT2112_10",
        "topic": "ICT2112 Week 10",
        "module_name": "Software Design"
    },
    {
        "module_code": "ICT2112",
        "code": "forum_ICT2112_11",
        "topic": "ICT2112 Week 11",
        "module_name": "Software Design"
    },
    {
        "module_code": "ICT2112",
        "code": "forum_ICT2112_12",
        "topic": "ICT2112 Week 12",
        "module_name": "Software Design"
    },
    {
        "module_code": "ICT2112",
        "code": "forum_ICT2112_13",
        "topic": "ICT2112 Week 13",
        "module_name": "Software Design"
    },
    {
        "module_code": "ICT2113",
        "code": "forum_ICT2113_1",
        "topic": "ICT2113 Week 1",
        "module_name": "Software Modelling and Analysis"
    },
    {
        "module_code": "ICT2113",
        "code": "forum_ICT2113_2",
        "topic": "ICT2113 Week 2",
        "module_name": "Software Modelling and Analysis"
    },
    {
        "module_code": "ICT2113",
        "code": "forum_ICT2113_3",
        "topic": "ICT2113 Week 3",
        "module_name": "Software Modelling and Analysis"
    },
    {
        "module_code": "ICT2113",
        "code": "forum_ICT2113_4",
        "topic": "ICT2113 Week 4",
        "module_name": "Software Modelling and Analysis"
    },
    {
        "module_code": "ICT2113",
        "code": "forum_ICT2113_5",
        "topic": "ICT2113 Week 5",
        "module_name": "Software Modelling and Analysis"
    },
    {
        "module_code": "ICT2113",
        "code": "forum_ICT2113_6",
        "topic": "ICT2113 Week 6",
        "module_name": "Software Modelling and Analysis"
    },
    {
        "module_code": "ICT2113",
        "code": "forum_ICT2113_7",
        "topic": "ICT2113 Week 7",
        "module_name": "Software Modelling and Analysis"
    },
    {
        "module_code": "ICT2113",
        "code": "forum_ICT2113_8",
        "topic": "ICT2113 Week 8",
        "module_name": "Software Modelling and Analysis"
    },
    {
        "module_code": "ICT2113",
        "code": "forum_ICT2113_9",
        "topic": "ICT2113 Week 9",
        "module_name": "Software Modelling and Analysis"
    },
    {
        "module_code": "ICT2113",
        "code": "forum_ICT2113_10",
        "topic": "ICT2113 Week 10",
        "module_name": "Software Modelling and Analysis"
    },
    {
        "module_code": "ICT2113",
        "code": "forum_ICT2113_11",
        "topic": "ICT2113 Week 11",
        "module_name": "Software Modelling and Analysis"
    },
    {
        "module_code": "ICT2113",
        "code": "forum_ICT2113_12",
        "topic": "ICT2113 Week 12",
        "module_name": "Software Modelling and Analysis"
    },
    {
        "module_code": "ICT2113",
        "code": "forum_ICT2113_13",
        "topic": "ICT2113 Week 13",
        "module_name": "Software Modelling and Analysis"
    },
    {
        "module_code": "ICT2215",
        "code": "forum_ICT2215_1",
        "topic": "ICT2215 Week 1",
        "module_name": "Mobile and Embedded Security"
    },
    {
        "module_code": "ICT2215",
        "code": "forum_ICT2215_2",
        "topic": "ICT2215 Week 2",
        "module_name": "Mobile and Embedded Security"
    },
    {
        "module_code": "ICT2215",
        "code": "forum_ICT2215_3",
        "topic": "ICT2215 Week 3",
        "module_name": "Mobile and Embedded Security"
    },
    {
        "module_code": "ICT2215",
        "code": "forum_ICT2215_4",
        "topic": "ICT2215 Week 4",
        "module_name": "Mobile and Embedded Security"
    },
    {
        "module_code": "ICT2215",
        "code": "forum_ICT2215_5",
        "topic": "ICT2215 Week 5",
        "module_name": "Mobile and Embedded Security"
    },
    {
        "module_code": "ICT2215",
        "code": "forum_ICT2215_6",
        "topic": "ICT2215 Week 6",
        "module_name": "Mobile and Embedded Security"
    },
    {
        "module_code": "ICT2215",
        "code": "forum_ICT2215_7",
        "topic": "ICT2215 Week 7",
        "module_name": "Mobile and Embedded Security"
    },
    {
        "module_code": "ICT2215",
        "code": "forum_ICT2215_8",
        "topic": "ICT2215 Week 8",
        "module_name": "Mobile and Embedded Security"
    },
    {
        "module_code": "ICT2215",
        "code": "forum_ICT2215_9",
        "topic": "ICT2215 Week 9",
        "module_name": "Mobile and Embedded Security"
    },
    {
        "module_code": "ICT2215",
        "code": "forum_ICT2215_10",
        "topic": "ICT2215 Week 10",
        "module_name": "Mobile and Embedded Security"
    },
    {
        "module_code": "ICT2215",
        "code": "forum_ICT2215_11",
        "topic": "ICT2215 Week 11",
        "module_name": "Mobile and Embedded Security"
    },
    {
        "module_code": "ICT2215",
        "code": "forum_ICT2215_12",
        "topic": "ICT2215 Week 12",
        "module_name": "Mobile and Embedded Security"
    },
    {
        "module_code": "ICT2215",
        "code": "forum_ICT2215_13",
        "topic": "ICT2215 Week 13",
        "module_name": "Mobile and Embedded Security"
    },
    {
        "module_code": "INF2007",
        "code": "forum_INF2007_1",
        "topic": "INF2007 Week 1",
        "module_name": "Mobile Application Development"
    },
    {
        "module_code": "INF2007",
        "code": "forum_INF2007_2",
        "topic": "INF2007 Week 2",
        "module_name": "Mobile Application Development"
    },
    {
        "module_code": "INF2007",
        "code": "forum_INF2007_3",
        "topic": "INF2007 Week 3",
        "module_name": "Mobile Application Development"
    },
    {
        "module_code": "INF2007",
        "code": "forum_INF2007_4",
        "topic": "INF2007 Week 4",
        "module_name": "Mobile Application Development"
    },
    {
        "module_code": "INF2007",
        "code": "forum_INF2007_5",
        "topic": "INF2007 Week 5",
        "module_name": "Mobile Application Development"
    },
    {
        "module_code": "INF2007",
        "code": "forum_INF2007_6",
        "topic": "INF2007 Week 6",
        "module_name": "Mobile Application Development"
    },
    {
        "module_code": "INF2007",
        "code": "forum_INF2007_7",
        "topic": "INF2007 Week 7",
        "module_name": "Mobile Application Development"
    },
    {
        "module_code": "INF2007",
        "code": "forum_INF2007_8",
        "topic": "INF2007 Week 8",
        "module_name": "Mobile Application Development"
    },
    {
        "module_code": "INF2007",
        "code": "forum_INF2007_9",
        "topic": "INF2007 Week 9",
        "module_name": "Mobile Application Development"
    },
    {
        "module_code": "INF2007",
        "code": "forum_INF2007_10",
        "topic": "INF2007 Week 10",
        "module_name": "Mobile Application Development"
    },
    {
        "module_code": "INF2007",
        "code": "forum_INF2007_11",
        "topic": "INF2007 Week 11",
        "module_name": "Mobile Application Development"
    },
    {
        "module_code": "INF2007",
        "code": "forum_INF2007_12",
        "topic": "INF2007 Week 12",
        "module_name": "Mobile Application Development"
    },
    {
        "module_code": "INF2007",
        "code": "forum_INF2007_13",
        "topic": "INF2007 Week 13",
        "module_name": "Mobile Application Development"
    },
    {
        "module_code": "ICT2114",
        "code": "forum_ICT2114_1",
        "topic": "ICT2114 Week 1",
        "module_name": "Integrative Team Project"
    },
    {
        "module_code": "ICT2114",
        "code": "forum_ICT2114_2",
        "topic": "ICT2114 Week 2",
        "module_name": "Integrative Team Project"
    },
    {
        "module_code": "ICT2114",
        "code": "forum_ICT2114_3",
        "topic": "ICT2114 Week 3",
        "module_name": "Integrative Team Project"
    },
    {
        "module_code": "ICT2114",
        "code": "forum_ICT2114_4",
        "topic": "ICT2114 Week 4",
        "module_name": "Integrative Team Project"
    },
    {
        "module_code": "ICT2114",
        "code": "forum_ICT2114_5",
        "topic": "ICT2114 Week 5",
        "module_name": "Integrative Team Project"
    },
    {
        "module_code": "ICT2114",
        "code": "forum_ICT2114_6",
        "topic": "ICT2114 Week 6",
        "module_name": "Integrative Team Project"
    },
    {
        "module_code": "ICT2114",
        "code": "forum_ICT2114_7",
        "topic": "ICT2114 Week 7",
        "module_name": "Integrative Team Project"
    },
    {
        "module_code": "ICT2114",
        "code": "forum_ICT2114_8",
        "topic": "ICT2114 Week 8",
        "module_name": "Integrative Team Project"
    },
    {
        "module_code": "ICT2114",
        "code": "forum_ICT2114_9",
        "topic": "ICT2114 Week 9",
        "module_name": "Integrative Team Project"
    },
    {
        "module_code": "ICT2114",
        "code": "forum_ICT2114_10",
        "topic": "ICT2114 Week 10",
        "module_name": "Integrative Team Project"
    },
    {
        "module_code": "ICT2114",
        "code": "forum_ICT2114_11",
        "topic": "ICT2114 Week 11",
        "module_name": "Integrative Team Project"
    },
    {
        "module_code": "ICT2114",
        "code": "forum_ICT2114_12",
        "topic": "ICT2114 Week 12",
        "module_name": "Integrative Team Project"
    },
    {
        "module_code": "ICT2114",
        "code": "forum_ICT2114_13",
        "topic": "ICT2114 Week 13",
        "module_name": "Integrative Team Project"
    },
    {
        "module_code": "ICT2216",
        "code": "forum_ICT2216_1",
        "topic": "ICT2216 Week 1",
        "module_name": "Secure Software Development"
    },
    {
        "module_code": "ICT2216",
        "code": "forum_ICT2216_2",
        "topic": "ICT2216 Week 2",
        "module_name": "Secure Software Development"
    },
    {
        "module_code": "ICT2216",
        "code": "forum_ICT2216_3",
        "topic": "ICT2216 Week 3",
        "module_name": "Secure Software Development"
    },
    {
        "module_code": "ICT2216",
        "code": "forum_ICT2216_4",
        "topic": "ICT2216 Week 4",
        "module_name": "Secure Software Development"
    },
    {
        "module_code": "ICT2216",
        "code": "forum_ICT2216_5",
        "topic": "ICT2216 Week 5",
        "module_name": "Secure Software Development"
    },
    {
        "module_code": "ICT2216",
        "code": "forum_ICT2216_6",
        "topic": "ICT2216 Week 6",
        "module_name": "Secure Software Development"
    },
    {
        "module_code": "ICT2216",
        "code": "forum_ICT2216_7",
        "topic": "ICT2216 Week 7",
        "module_name": "Secure Software Development"
    },
    {
        "module_code": "ICT2216",
        "code": "forum_ICT2216_8",
        "topic": "ICT2216 Week 8",
        "module_name": "Secure Software Development"
    },
    {
        "module_code": "ICT2216",
        "code": "forum_ICT2216_9",
        "topic": "ICT2216 Week 9",
        "module_name": "Secure Software Development"
    },
    {
        "module_code": "ICT2216",
        "code": "forum_ICT2216_10",
        "topic": "ICT2216 Week 10",
        "module_name": "Secure Software Development"
    },
    {
        "module_code": "ICT2216",
        "code": "forum_ICT2216_11",
        "topic": "ICT2216 Week 11",
        "module_name": "Secure Software Development"
    },
    {
        "module_code": "ICT2216",
        "code": "forum_ICT2216_12",
        "topic": "ICT2216 Week 12",
        "module_name": "Secure Software Development"
    },
    {
        "module_code": "ICT2216",
        "code": "forum_ICT2216_13",
        "topic": "ICT2216 Week 13",
        "module_name": "Secure Software Development"
    },
    {
        "module_code": "ICT3219",
        "code": "forum_ICT3219_1",
        "topic": "ICT3219 Week 1",
        "module_name": "Industry Certification Module"
    },
    {
        "module_code": "ICT3219",
        "code": "forum_ICT3219_2",
        "topic": "ICT3219 Week 2",
        "module_name": "Industry Certification Module"
    },
    {
        "module_code": "ICT3219",
        "code": "forum_ICT3219_3",
        "topic": "ICT3219 Week 3",
        "module_name": "Industry Certification Module"
    },
    {
        "module_code": "ICT3219",
        "code": "forum_ICT3219_4",
        "topic": "ICT3219 Week 4",
        "module_name": "Industry Certification Module"
    },
    {
        "module_code": "ICT3219",
        "code": "forum_ICT3219_5",
        "topic": "ICT3219 Week 5",
        "module_name": "Industry Certification Module"
    },
    {
        "module_code": "ICT3219",
        "code": "forum_ICT3219_6",
        "topic": "ICT3219 Week 6",
        "module_name": "Industry Certification Module"
    },
    {
        "module_code": "ICT3219",
        "code": "forum_ICT3219_7",
        "topic": "ICT3219 Week 7",
        "module_name": "Industry Certification Module"
    },
    {
        "module_code": "ICT3219",
        "code": "forum_ICT3219_8",
        "topic": "ICT3219 Week 8",
        "module_name": "Industry Certification Module"
    },
    {
        "module_code": "ICT3219",
        "code": "forum_ICT3219_9",
        "topic": "ICT3219 Week 9",
        "module_name": "Industry Certification Module"
    },
    {
        "module_code": "ICT3219",
        "code": "forum_ICT3219_10",
        "topic": "ICT3219 Week 10",
        "module_name": "Industry Certification Module"
    },
    {
        "module_code": "ICT3219",
        "code": "forum_ICT3219_11",
        "topic": "ICT3219 Week 11",
        "module_name": "Industry Certification Module"
    },
    {
        "module_code": "ICT3219",
        "code": "forum_ICT3219_12",
        "topic": "ICT3219 Week 12",
        "module_name": "Industry Certification Module"
    },
    {
        "module_code": "ICT3219",
        "code": "forum_ICT3219_13",
        "topic": "ICT3219 Week 13",
        "module_name": "Industry Certification Module"
    },
    {
        "module_code": "INF2005",
        "code": "forum_INF2005_1",
        "topic": "INF2005 Week 1",
        "module_name": "Cyber Security Fundamentals"
    },
    {
        "module_code": "INF2005",
        "code": "forum_INF2005_2",
        "topic": "INF2005 Week 2",
        "module_name": "Cyber Security Fundamentals"
    },
    {
        "module_code": "INF2005",
        "code": "forum_INF2005_3",
        "topic": "INF2005 Week 3",
        "module_name": "Cyber Security Fundamentals"
    },
    {
        "module_code": "INF2005",
        "code": "forum_INF2005_4",
        "topic": "INF2005 Week 4",
        "module_name": "Cyber Security Fundamentals"
    },
    {
        "module_code": "INF2005",
        "code": "forum_INF2005_5",
        "topic": "INF2005 Week 5",
        "module_name": "Cyber Security Fundamentals"
    },
    {
        "module_code": "INF2005",
        "code": "forum_INF2005_6",
        "topic": "INF2005 Week 6",
        "module_name": "Cyber Security Fundamentals"
    },
    {
        "module_code": "INF2005",
        "code": "forum_INF2005_7",
        "topic": "INF2005 Week 7",
        "module_name": "Cyber Security Fundamentals"
    },
    {
        "module_code": "INF2005",
        "code": "forum_INF2005_8",
        "topic": "INF2005 Week 8",
        "module_name": "Cyber Security Fundamentals"
    },
    {
        "module_code": "INF2005",
        "code": "forum_INF2005_9",
        "topic": "INF2005 Week 9",
        "module_name": "Cyber Security Fundamentals"
    },
    {
        "module_code": "INF2005",
        "code": "forum_INF2005_10",
        "topic": "INF2005 Week 10",
        "module_name": "Cyber Security Fundamentals"
    },
    {
        "module_code": "INF2005",
        "code": "forum_INF2005_11",
        "topic": "INF2005 Week 11",
        "module_name": "Cyber Security Fundamentals"
    },
    {
        "module_code": "INF2005",
        "code": "forum_INF2005_12",
        "topic": "INF2005 Week 12",
        "module_name": "Cyber Security Fundamentals"
    },
    {
        "module_code": "INF2005",
        "code": "forum_INF2005_13",
        "topic": "INF2005 Week 13",
        "module_name": "Cyber Security Fundamentals"
    },
    {
        "module_code": "ICT3112",
        "code": "forum_ICT3112_1",
        "topic": "ICT3112 Week 1",
        "module_name": "Software Verification and Validation"
    },
    {
        "module_code": "ICT3112",
        "code": "forum_ICT3112_2",
        "topic": "ICT3112 Week 2",
        "module_name": "Software Verification and Validation"
    },
    {
        "module_code": "ICT3112",
        "code": "forum_ICT3112_3",
        "topic": "ICT3112 Week 3",
        "module_name": "Software Verification and Validation"
    },
    {
        "module_code": "ICT3112",
        "code": "forum_ICT3112_4",
        "topic": "ICT3112 Week 4",
        "module_name": "Software Verification and Validation"
    },
    {
        "module_code": "ICT3112",
        "code": "forum_ICT3112_5",
        "topic": "ICT3112 Week 5",
        "module_name": "Software Verification and Validation"
    },
    {
        "module_code": "ICT3112",
        "code": "forum_ICT3112_6",
        "topic": "ICT3112 Week 6",
        "module_name": "Software Verification and Validation"
    },
    {
        "module_code": "ICT3112",
        "code": "forum_ICT3112_7",
        "topic": "ICT3112 Week 7",
        "module_name": "Software Verification and Validation"
    },
    {
        "module_code": "ICT3112",
        "code": "forum_ICT3112_8",
        "topic": "ICT3112 Week 8",
        "module_name": "Software Verification and Validation"
    },
    {
        "module_code": "ICT3112",
        "code": "forum_ICT3112_9",
        "topic": "ICT3112 Week 9",
        "module_name": "Software Verification and Validation"
    },
    {
        "module_code": "ICT3112",
        "code": "forum_ICT3112_10",
        "topic": "ICT3112 Week 10",
        "module_name": "Software Verification and Validation"
    },
    {
        "module_code": "ICT3112",
        "code": "forum_ICT3112_11",
        "topic": "ICT3112 Week 11",
        "module_name": "Software Verification and Validation"
    },
    {
        "module_code": "ICT3112",
        "code": "forum_ICT3112_12",
        "topic": "ICT3112 Week 12",
        "module_name": "Software Verification and Validation"
    },
    {
        "module_code": "ICT3112",
        "code": "forum_ICT3112_13",
        "topic": "ICT3112 Week 13",
        "module_name": "Software Verification and Validation"
    },
    {
        "module_code": "ICT3113",
        "code": "forum_ICT3113_1",
        "topic": "ICT3113 Week 1",
        "module_name": "Performance Testing and Optimisation"
    },
    {
        "module_code": "ICT3113",
        "code": "forum_ICT3113_2",
        "topic": "ICT3113 Week 2",
        "module_name": "Performance Testing and Optimisation"
    },
    {
        "module_code": "ICT3113",
        "code": "forum_ICT3113_3",
        "topic": "ICT3113 Week 3",
        "module_name": "Performance Testing and Optimisation"
    },
    {
        "module_code": "ICT3113",
        "code": "forum_ICT3113_4",
        "topic": "ICT3113 Week 4",
        "module_name": "Performance Testing and Optimisation"
    },
    {
        "module_code": "ICT3113",
        "code": "forum_ICT3113_5",
        "topic": "ICT3113 Week 5",
        "module_name": "Performance Testing and Optimisation"
    },
    {
        "module_code": "ICT3113",
        "code": "forum_ICT3113_6",
        "topic": "ICT3113 Week 6",
        "module_name": "Performance Testing and Optimisation"
    },
    {
        "module_code": "ICT3113",
        "code": "forum_ICT3113_7",
        "topic": "ICT3113 Week 7",
        "module_name": "Performance Testing and Optimisation"
    },
    {
        "module_code": "ICT3113",
        "code": "forum_ICT3113_8",
        "topic": "ICT3113 Week 8",
        "module_name": "Performance Testing and Optimisation"
    },
    {
        "module_code": "ICT3113",
        "code": "forum_ICT3113_9",
        "topic": "ICT3113 Week 9",
        "module_name": "Performance Testing and Optimisation"
    },
    {
        "module_code": "ICT3113",
        "code": "forum_ICT3113_10",
        "topic": "ICT3113 Week 10",
        "module_name": "Performance Testing and Optimisation"
    },
    {
        "module_code": "ICT3113",
        "code": "forum_ICT3113_11",
        "topic": "ICT3113 Week 11",
        "module_name": "Performance Testing and Optimisation"
    },
    {
        "module_code": "ICT3113",
        "code": "forum_ICT3113_12",
        "topic": "ICT3113 Week 12",
        "module_name": "Performance Testing and Optimisation"
    },
    {
        "module_code": "ICT3113",
        "code": "forum_ICT3113_13",
        "topic": "ICT3113 Week 13",
        "module_name": "Performance Testing and Optimisation"
    },
    {
        "module_code": "INF2006",
        "code": "forum_INF2006_1",
        "topic": "INF2006 Week 1",
        "module_name": "Cloud Computing and Big Data"
    },
    {
        "module_code": "INF2006",
        "code": "forum_INF2006_2",
        "topic": "INF2006 Week 2",
        "module_name": "Cloud Computing and Big Data"
    },
    {
        "module_code": "INF2006",
        "code": "forum_INF2006_3",
        "topic": "INF2006 Week 3",
        "module_name": "Cloud Computing and Big Data"
    },
    {
        "module_code": "INF2006",
        "code": "forum_INF2006_4",
        "topic": "INF2006 Week 4",
        "module_name": "Cloud Computing and Big Data"
    },
    {
        "module_code": "INF2006",
        "code": "forum_INF2006_5",
        "topic": "INF2006 Week 5",
        "module_name": "Cloud Computing and Big Data"
    },
    {
        "module_code": "INF2006",
        "code": "forum_INF2006_6",
        "topic": "INF2006 Week 6",
        "module_name": "Cloud Computing and Big Data"
    },
    {
        "module_code": "INF2006",
        "code": "forum_INF2006_7",
        "topic": "INF2006 Week 7",
        "module_name": "Cloud Computing and Big Data"
    },
    {
        "module_code": "INF2006",
        "code": "forum_INF2006_8",
        "topic": "INF2006 Week 8",
        "module_name": "Cloud Computing and Big Data"
    },
    {
        "module_code": "INF2006",
        "code": "forum_INF2006_9",
        "topic": "INF2006 Week 9",
        "module_name": "Cloud Computing and Big Data"
    },
    {
        "module_code": "INF2006",
        "code": "forum_INF2006_10",
        "topic": "INF2006 Week 10",
        "module_name": "Cloud Computing and Big Data"
    },
    {
        "module_code": "INF2006",
        "code": "forum_INF2006_11",
        "topic": "INF2006 Week 11",
        "module_name": "Cloud Computing and Big Data"
    },
    {
        "module_code": "INF2006",
        "code": "forum_INF2006_12",
        "topic": "INF2006 Week 12",
        "module_name": "Cloud Computing and Big Data"
    },
    {
        "module_code": "INF2006",
        "code": "forum_INF2006_13",
        "topic": "INF2006 Week 13",
        "module_name": "Cloud Computing and Big Data"
    },
    {
        "module_code": "USI2001",
        "code": "forum_USI2001_1",
        "topic": "USI2001 Week 1",
        "module_name": "Social Innovation Project"
    },
    {
        "module_code": "USI2001",
        "code": "forum_USI2001_2",
        "topic": "USI2001 Week 2",
        "module_name": "Social Innovation Project"
    },
    {
        "module_code": "USI2001",
        "code": "forum_USI2001_3",
        "topic": "USI2001 Week 3",
        "module_name": "Social Innovation Project"
    },
    {
        "module_code": "USI2001",
        "code": "forum_USI2001_4",
        "topic": "USI2001 Week 4",
        "module_name": "Social Innovation Project"
    },
    {
        "module_code": "USI2001",
        "code": "forum_USI2001_5",
        "topic": "USI2001 Week 5",
        "module_name": "Social Innovation Project"
    },
    {
        "module_code": "USI2001",
        "code": "forum_USI2001_6",
        "topic": "USI2001 Week 6",
        "module_name": "Social Innovation Project"
    },
    {
        "module_code": "USI2001",
        "code": "forum_USI2001_7",
        "topic": "USI2001 Week 7",
        "module_name": "Social Innovation Project"
    },
    {
        "module_code": "USI2001",
        "code": "forum_USI2001_8",
        "topic": "USI2001 Week 8",
        "module_name": "Social Innovation Project"
    },
    {
        "module_code": "USI2001",
        "code": "forum_USI2001_9",
        "topic": "USI2001 Week 9",
        "module_name": "Social Innovation Project"
    },
    {
        "module_code": "USI2001",
        "code": "forum_USI2001_10",
        "topic": "USI2001 Week 10",
        "module_name": "Social Innovation Project"
    },
    {
        "module_code": "USI2001",
        "code": "forum_USI2001_11",
        "topic": "USI2001 Week 11",
        "module_name": "Social Innovation Project"
    },
    {
        "module_code": "USI2001",
        "code": "forum_USI2001_12",
        "topic": "USI2001 Week 12",
        "module_name": "Social Innovation Project"
    },
    {
        "module_code": "USI2001",
        "code": "forum_USI2001_13",
        "topic": "USI2001 Week 13",
        "module_name": "Social Innovation Project"
    },
    {
        "module_code": "ICT3217",
        "code": "forum_ICT3217_1",
        "topic": "ICT3217 Week 1",
        "module_name": "Specialised R&D Project"
    },
    {
        "module_code": "ICT3217",
        "code": "forum_ICT3217_2",
        "topic": "ICT3217 Week 2",
        "module_name": "Specialised R&D Project"
    },
    {
        "module_code": "ICT3217",
        "code": "forum_ICT3217_3",
        "topic": "ICT3217 Week 3",
        "module_name": "Specialised R&D Project"
    },
    {
        "module_code": "ICT3217",
        "code": "forum_ICT3217_4",
        "topic": "ICT3217 Week 4",
        "module_name": "Specialised R&D Project"
    },
    {
        "module_code": "ICT3217",
        "code": "forum_ICT3217_5",
        "topic": "ICT3217 Week 5",
        "module_name": "Specialised R&D Project"
    },
    {
        "module_code": "ICT3217",
        "code": "forum_ICT3217_6",
        "topic": "ICT3217 Week 6",
        "module_name": "Specialised R&D Project"
    },
    {
        "module_code": "ICT3217",
        "code": "forum_ICT3217_7",
        "topic": "ICT3217 Week 7",
        "module_name": "Specialised R&D Project"
    },
    {
        "module_code": "ICT3217",
        "code": "forum_ICT3217_8",
        "topic": "ICT3217 Week 8",
        "module_name": "Specialised R&D Project"
    },
    {
        "module_code": "ICT3217",
        "code": "forum_ICT3217_9",
        "topic": "ICT3217 Week 9",
        "module_name": "Specialised R&D Project"
    },
    {
        "module_code": "ICT3217",
        "code": "forum_ICT3217_10",
        "topic": "ICT3217 Week 10",
        "module_name": "Specialised R&D Project"
    },
    {
        "module_code": "ICT3217",
        "code": "forum_ICT3217_11",
        "topic": "ICT3217 Week 11",
        "module_name": "Specialised R&D Project"
    },
    {
        "module_code": "ICT3217",
        "code": "forum_ICT3217_12",
        "topic": "ICT3217 Week 12",
        "module_name": "Specialised R&D Project"
    },
    {
        "module_code": "ICT3217",
        "code": "forum_ICT3217_13",
        "topic": "ICT3217 Week 13",
        "module_name": "Specialised R&D Project"
    },
    {
        "module_code": "INF2008",
        "code": "forum_INF2008_1",
        "topic": "INF2008 Week 1",
        "module_name": "Machine Learning"
    },
    {
        "module_code": "INF2008",
        "code": "forum_INF2008_2",
        "topic": "INF2008 Week 2",
        "module_name": "Machine Learning"
    },
    {
        "module_code": "INF2008",
        "code": "forum_INF2008_3",
        "topic": "INF2008 Week 3",
        "module_name": "Machine Learning"
    },
    {
        "module_code": "INF2008",
        "code": "forum_INF2008_4",
        "topic": "INF2008 Week 4",
        "module_name": "Machine Learning"
    },
    {
        "module_code": "INF2008",
        "code": "forum_INF2008_5",
        "topic": "INF2008 Week 5",
        "module_name": "Machine Learning"
    },
    {
        "module_code": "INF2008",
        "code": "forum_INF2008_6",
        "topic": "INF2008 Week 6",
        "module_name": "Machine Learning"
    },
    {
        "module_code": "INF2008",
        "code": "forum_INF2008_7",
        "topic": "INF2008 Week 7",
        "module_name": "Machine Learning"
    },
    {
        "module_code": "INF2008",
        "code": "forum_INF2008_8",
        "topic": "INF2008 Week 8",
        "module_name": "Machine Learning"
    },
    {
        "module_code": "INF2008",
        "code": "forum_INF2008_9",
        "topic": "INF2008 Week 9",
        "module_name": "Machine Learning"
    },
    {
        "module_code": "INF2008",
        "code": "forum_INF2008_10",
        "topic": "INF2008 Week 10",
        "module_name": "Machine Learning"
    },
    {
        "module_code": "INF2008",
        "code": "forum_INF2008_11",
        "topic": "INF2008 Week 11",
        "module_name": "Machine Learning"
    },
    {
        "module_code": "INF2008",
        "code": "forum_INF2008_12",
        "topic": "INF2008 Week 12",
        "module_name": "Machine Learning"
    },
    {
        "module_code": "INF2008",
        "code": "forum_INF2008_13",
        "topic": "INF2008 Week 13",
        "module_name": "Machine Learning"
    },
    {
        "module_code": "INF2009",
        "code": "forum_INF2009_1",
        "topic": "INF2009 Week 1",
        "module_name": "Edge Computing and Analytics"
    },
    {
        "module_code": "INF2009",
        "code": "forum_INF2009_2",
        "topic": "INF2009 Week 2",
        "module_name": "Edge Computing and Analytics"
    },
    {
        "module_code": "INF2009",
        "code": "forum_INF2009_3",
        "topic": "INF2009 Week 3",
        "module_name": "Edge Computing and Analytics"
    },
    {
        "module_code": "INF2009",
        "code": "forum_INF2009_4",
        "topic": "INF2009 Week 4",
        "module_name": "Edge Computing and Analytics"
    },
    {
        "module_code": "INF2009",
        "code": "forum_INF2009_5",
        "topic": "INF2009 Week 5",
        "module_name": "Edge Computing and Analytics"
    },
    {
        "module_code": "INF2009",
        "code": "forum_INF2009_6",
        "topic": "INF2009 Week 6",
        "module_name": "Edge Computing and Analytics"
    },
    {
        "module_code": "INF2009",
        "code": "forum_INF2009_7",
        "topic": "INF2009 Week 7",
        "module_name": "Edge Computing and Analytics"
    },
    {
        "module_code": "INF2009",
        "code": "forum_INF2009_8",
        "topic": "INF2009 Week 8",
        "module_name": "Edge Computing and Analytics"
    },
    {
        "module_code": "INF2009",
        "code": "forum_INF2009_9",
        "topic": "INF2009 Week 9",
        "module_name": "Edge Computing and Analytics"
    },
    {
        "module_code": "INF2009",
        "code": "forum_INF2009_10",
        "topic": "INF2009 Week 10",
        "module_name": "Edge Computing and Analytics"
    },
    {
        "module_code": "INF2009",
        "code": "forum_INF2009_11",
        "topic": "INF2009 Week 11",
        "module_name": "Edge Computing and Analytics"
    },
    {
        "module_code": "INF2009",
        "code": "forum_INF2009_12",
        "topic": "INF2009 Week 12",
        "module_name": "Edge Computing and Analytics"
    },
    {
        "module_code": "INF2009",
        "code": "forum_INF2009_13",
        "topic": "INF2009 Week 13",
        "module_name": "Edge Computing and Analytics"
    },
    {
        "module_code": "UCM3001",
        "code": "forum_UCM3001_1",
        "topic": "UCM3001 Week 1",
        "module_name": "Change Management"
    },
    {
        "module_code": "UCM3001",
        "code": "forum_UCM3001_2",
        "topic": "UCM3001 Week 2",
        "module_name": "Change Management"
    },
    {
        "module_code": "UCM3001",
        "code": "forum_UCM3001_3",
        "topic": "UCM3001 Week 3",
        "module_name": "Change Management"
    },
    {
        "module_code": "UCM3001",
        "code": "forum_UCM3001_4",
        "topic": "UCM3001 Week 4",
        "module_name": "Change Management"
    },
    {
        "module_code": "UCM3001",
        "code": "forum_UCM3001_5",
        "topic": "UCM3001 Week 5",
        "module_name": "Change Management"
    },
    {
        "module_code": "UCM3001",
        "code": "forum_UCM3001_6",
        "topic": "UCM3001 Week 6",
        "module_name": "Change Management"
    },
    {
        "module_code": "UCM3001",
        "code": "forum_UCM3001_7",
        "topic": "UCM3001 Week 7",
        "module_name": "Change Management"
    },
    {
        "module_code": "UCM3001",
        "code": "forum_UCM3001_8",
        "topic": "UCM3001 Week 8",
        "module_name": "Change Management"
    },
    {
        "module_code": "UCM3001",
        "code": "forum_UCM3001_9",
        "topic": "UCM3001 Week 9",
        "module_name": "Change Management"
    },
    {
        "module_code": "UCM3001",
        "code": "forum_UCM3001_10",
        "topic": "UCM3001 Week 10",
        "module_name": "Change Management"
    },
    {
        "module_code": "UCM3001",
        "code": "forum_UCM3001_11",
        "topic": "UCM3001 Week 11",
        "module_name": "Change Management"
    },
    {
        "module_code": "UCM3001",
        "code": "forum_UCM3001_12",
        "topic": "UCM3001 Week 12",
        "module_name": "Change Management"
    },
    {
        "module_code": "UCM3001",
        "code": "forum_UCM3001_13",
        "topic": "UCM3001 Week 13",
        "module_name": "Change Management"
    },
    {
        "module_code": "ICT4011",
        "code": "forum_ICT4011_1",
        "topic": "ICT4011 Week 1",
        "module_name": "Capstone Project"
    },
    {
        "module_code": "ICT4011",
        "code": "forum_ICT4011_2",
        "topic": "ICT4011 Week 2",
        "module_name": "Capstone Project"
    },
    {
        "module_code": "ICT4011",
        "code": "forum_ICT4011_3",
        "topic": "ICT4011 Week 3",
        "module_name": "Capstone Project"
    },
    {
        "module_code": "ICT4011",
        "code": "forum_ICT4011_4",
        "topic": "ICT4011 Week 4",
        "module_name": "Capstone Project"
    },
    {
        "module_code": "ICT4011",
        "code": "forum_ICT4011_5",
        "topic": "ICT4011 Week 5",
        "module_name": "Capstone Project"
    },
    {
        "module_code": "ICT4011",
        "code": "forum_ICT4011_6",
        "topic": "ICT4011 Week 6",
        "module_name": "Capstone Project"
    },
    {
        "module_code": "ICT4011",
        "code": "forum_ICT4011_7",
        "topic": "ICT4011 Week 7",
        "module_name": "Capstone Project"
    },
    {
        "module_code": "ICT4011",
        "code": "forum_ICT4011_8",
        "topic": "ICT4011 Week 8",
        "module_name": "Capstone Project"
    },
    {
        "module_code": "ICT4011",
        "code": "forum_ICT4011_9",
        "topic": "ICT4011 Week 9",
        "module_name": "Capstone Project"
    },
    {
        "module_code": "ICT4011",
        "code": "forum_ICT4011_10",
        "topic": "ICT4011 Week 10",
        "module_name": "Capstone Project"
    },
    {
        "module_code": "ICT4011",
        "code": "forum_ICT4011_11",
        "topic": "ICT4011 Week 11",
        "module_name": "Capstone Project"
    },
    {
        "module_code": "ICT4011",
        "code": "forum_ICT4011_12",
        "topic": "ICT4011 Week 12",
        "module_name": "Capstone Project"
    },
    {
        "module_code": "ICT4011",
        "code": "forum_ICT4011_13",
        "topic": "ICT4011 Week 13",
        "module_name": "Capstone Project"
    },
    {
        "module_code": "ICT4012B",
        "code": "forum_ICT4012B_1",
        "topic": "ICT4012B Week 1",
        "module_name": "Integrated Work Study Programme (Work Attachment)"
    },
    {
        "module_code": "ICT4012B",
        "code": "forum_ICT4012B_2",
        "topic": "ICT4012B Week 2",
        "module_name": "Integrated Work Study Programme (Work Attachment)"
    },
    {
        "module_code": "ICT4012B",
        "code": "forum_ICT4012B_3",
        "topic": "ICT4012B Week 3",
        "module_name": "Integrated Work Study Programme (Work Attachment)"
    },
    {
        "module_code": "ICT4012B",
        "code": "forum_ICT4012B_4",
        "topic": "ICT4012B Week 4",
        "module_name": "Integrated Work Study Programme (Work Attachment)"
    },
    {
        "module_code": "ICT4012B",
        "code": "forum_ICT4012B_5",
        "topic": "ICT4012B Week 5",
        "module_name": "Integrated Work Study Programme (Work Attachment)"
    },
    {
        "module_code": "ICT4012B",
        "code": "forum_ICT4012B_6",
        "topic": "ICT4012B Week 6",
        "module_name": "Integrated Work Study Programme (Work Attachment)"
    },
    {
        "module_code": "ICT4012B",
        "code": "forum_ICT4012B_7",
        "topic": "ICT4012B Week 7",
        "module_name": "Integrated Work Study Programme (Work Attachment)"
    },
    {
        "module_code": "ICT4012B",
        "code": "forum_ICT4012B_8",
        "topic": "ICT4012B Week 8",
        "module_name": "Integrated Work Study Programme (Work Attachment)"
    },
    {
        "module_code": "ICT4012B",
        "code": "forum_ICT4012B_9",
        "topic": "ICT4012B Week 9",
        "module_name": "Integrated Work Study Programme (Work Attachment)"
    },
    {
        "module_code": "ICT4012B",
        "code": "forum_ICT4012B_10",
        "topic": "ICT4012B Week 10",
        "module_name": "Integrated Work Study Programme (Work Attachment)"
    },
    {
        "module_code": "ICT4012B",
        "code": "forum_ICT4012B_11",
        "topic": "ICT4012B Week 11",
        "module_name": "Integrated Work Study Programme (Work Attachment)"
    },
    {
        "module_code": "ICT4012B",
        "code": "forum_ICT4012B_12",
        "topic": "ICT4012B Week 12",
        "module_name": "Integrated Work Study Programme (Work Attachment)"
    },
    {
        "module_code": "ICT4012B",
        "code": "forum_ICT4012B_13",
        "topic": "ICT4012B Week 13",
        "module_name": "Integrated Work Study Programme (Work Attachment)"
    },
    {
        "module_code": "ICT4011",
        "code": "forum_ICT4011_1",
        "topic": "ICT4011 Week 1",
        "module_name": "Capstone Project"
    },
    {
        "module_code": "ICT4011",
        "code": "forum_ICT4011_2",
        "topic": "ICT4011 Week 2",
        "module_name": "Capstone Project"
    },
    {
        "module_code": "ICT4011",
        "code": "forum_ICT4011_3",
        "topic": "ICT4011 Week 3",
        "module_name": "Capstone Project"
    },
    {
        "module_code": "ICT4011",
        "code": "forum_ICT4011_4",
        "topic": "ICT4011 Week 4",
        "module_name": "Capstone Project"
    },
    {
        "module_code": "ICT4011",
        "code": "forum_ICT4011_5",
        "topic": "ICT4011 Week 5",
        "module_name": "Capstone Project"
    },
    {
        "module_code": "ICT4011",
        "code": "forum_ICT4011_6",
        "topic": "ICT4011 Week 6",
        "module_name": "Capstone Project"
    },
    {
        "module_code": "ICT4011",
        "code": "forum_ICT4011_7",
        "topic": "ICT4011 Week 7",
        "module_name": "Capstone Project"
    },
    {
        "module_code": "ICT4011",
        "code": "forum_ICT4011_8",
        "topic": "ICT4011 Week 8",
        "module_name": "Capstone Project"
    },
    {
        "module_code": "ICT4011",
        "code": "forum_ICT4011_9",
        "topic": "ICT4011 Week 9",
        "module_name": "Capstone Project"
    },
    {
        "module_code": "ICT4011",
        "code": "forum_ICT4011_10",
        "topic": "ICT4011 Week 10",
        "module_name": "Capstone Project"
    },
    {
        "module_code": "ICT4011",
        "code": "forum_ICT4011_11",
        "topic": "ICT4011 Week 11",
        "module_name": "Capstone Project"
    },
    {
        "module_code": "ICT4011",
        "code": "forum_ICT4011_12",
        "topic": "ICT4011 Week 12",
        "module_name": "Capstone Project"
    },
    {
        "module_code": "ICT4011",
        "code": "forum_ICT4011_13",
        "topic": "ICT4011 Week 13",
        "module_name": "Capstone Project"
    },
    {
        "module_code": "ICT4012B",
        "code": "forum_ICT4012B_1",
        "topic": "ICT4012B Week 1",
        "module_name": "Integrated Work Study Programme (Work Attachment)"
    },
    {
        "module_code": "ICT4012B",
        "code": "forum_ICT4012B_2",
        "topic": "ICT4012B Week 2",
        "module_name": "Integrated Work Study Programme (Work Attachment)"
    },
    {
        "module_code": "ICT4012B",
        "code": "forum_ICT4012B_3",
        "topic": "ICT4012B Week 3",
        "module_name": "Integrated Work Study Programme (Work Attachment)"
    },
    {
        "module_code": "ICT4012B",
        "code": "forum_ICT4012B_4",
        "topic": "ICT4012B Week 4",
        "module_name": "Integrated Work Study Programme (Work Attachment)"
    },
    {
        "module_code": "ICT4012B",
        "code": "forum_ICT4012B_5",
        "topic": "ICT4012B Week 5",
        "module_name": "Integrated Work Study Programme (Work Attachment)"
    },
    {
        "module_code": "ICT4012B",
        "code": "forum_ICT4012B_6",
        "topic": "ICT4012B Week 6",
        "module_name": "Integrated Work Study Programme (Work Attachment)"
    },
    {
        "module_code": "ICT4012B",
        "code": "forum_ICT4012B_7",
        "topic": "ICT4012B Week 7",
        "module_name": "Integrated Work Study Programme (Work Attachment)"
    },
    {
        "module_code": "ICT4012B",
        "code": "forum_ICT4012B_8",
        "topic": "ICT4012B Week 8",
        "module_name": "Integrated Work Study Programme (Work Attachment)"
    },
    {
        "module_code": "ICT4012B",
        "code": "forum_ICT4012B_9",
        "topic": "ICT4012B Week 9",
        "module_name": "Integrated Work Study Programme (Work Attachment)"
    },
    {
        "module_code": "ICT4012B",
        "code": "forum_ICT4012B_10",
        "topic": "ICT4012B Week 10",
        "module_name": "Integrated Work Study Programme (Work Attachment)"
    },
    {
        "module_code": "ICT4012B",
        "code": "forum_ICT4012B_11",
        "topic": "ICT4012B Week 11",
        "module_name": "Integrated Work Study Programme (Work Attachment)"
    },
    {
        "module_code": "ICT4012B",
        "code": "forum_ICT4012B_12",
        "topic": "ICT4012B Week 12",
        "module_name": "Integrated Work Study Programme (Work Attachment)"
    },
    {
        "module_code": "ICT4012B",
        "code": "forum_ICT4012B_13",
        "topic": "ICT4012B Week 13",
        "module_name": "Integrated Work Study Programme (Work Attachment)"
    },
    {
        "module_code": "ICT4011",
        "code": "forum_ICT4011_1",
        "topic": "ICT4011 Week 1",
        "module_name": "Capstone Project"
    },
    {
        "module_code": "ICT4011",
        "code": "forum_ICT4011_2",
        "topic": "ICT4011 Week 2",
        "module_name": "Capstone Project"
    },
    {
        "module_code": "ICT4011",
        "code": "forum_ICT4011_3",
        "topic": "ICT4011 Week 3",
        "module_name": "Capstone Project"
    },
    {
        "module_code": "ICT4011",
        "code": "forum_ICT4011_4",
        "topic": "ICT4011 Week 4",
        "module_name": "Capstone Project"
    },
    {
        "module_code": "ICT4011",
        "code": "forum_ICT4011_5",
        "topic": "ICT4011 Week 5",
        "module_name": "Capstone Project"
    },
    {
        "module_code": "ICT4011",
        "code": "forum_ICT4011_6",
        "topic": "ICT4011 Week 6",
        "module_name": "Capstone Project"
    },
    {
        "module_code": "ICT4011",
        "code": "forum_ICT4011_7",
        "topic": "ICT4011 Week 7",
        "module_name": "Capstone Project"
    },
    {
        "module_code": "ICT4011",
        "code": "forum_ICT4011_8",
        "topic": "ICT4011 Week 8",
        "module_name": "Capstone Project"
    },
    {
        "module_code": "ICT4011",
        "code": "forum_ICT4011_9",
        "topic": "ICT4011 Week 9",
        "module_name": "Capstone Project"
    },
    {
        "module_code": "ICT4011",
        "code": "forum_ICT4011_10",
        "topic": "ICT4011 Week 10",
        "module_name": "Capstone Project"
    },
    {
        "module_code": "ICT4011",
        "code": "forum_ICT4011_11",
        "topic": "ICT4011 Week 11",
        "module_name": "Capstone Project"
    },
    {
        "module_code": "ICT4011",
        "code": "forum_ICT4011_12",
        "topic": "ICT4011 Week 12",
        "module_name": "Capstone Project"
    },
    {
        "module_code": "ICT4011",
        "code": "forum_ICT4011_13",
        "topic": "ICT4011 Week 13",
        "module_name": "Capstone Project"
    },
    {
        "module_code": "ICT4012B",
        "code": "forum_ICT4012B_1",
        "topic": "ICT4012B Week 1",
        "module_name": "Integrated Work Study Programme (Work Attachment)"
    },
    {
        "module_code": "ICT4012B",
        "code": "forum_ICT4012B_2",
        "topic": "ICT4012B Week 2",
        "module_name": "Integrated Work Study Programme (Work Attachment)"
    },
    {
        "module_code": "ICT4012B",
        "code": "forum_ICT4012B_3",
        "topic": "ICT4012B Week 3",
        "module_name": "Integrated Work Study Programme (Work Attachment)"
    },
    {
        "module_code": "ICT4012B",
        "code": "forum_ICT4012B_4",
        "topic": "ICT4012B Week 4",
        "module_name": "Integrated Work Study Programme (Work Attachment)"
    },
    {
        "module_code": "ICT4012B",
        "code": "forum_ICT4012B_5",
        "topic": "ICT4012B Week 5",
        "module_name": "Integrated Work Study Programme (Work Attachment)"
    },
    {
        "module_code": "ICT4012B",
        "code": "forum_ICT4012B_6",
        "topic": "ICT4012B Week 6",
        "module_name": "Integrated Work Study Programme (Work Attachment)"
    },
    {
        "module_code": "ICT4012B",
        "code": "forum_ICT4012B_7",
        "topic": "ICT4012B Week 7",
        "module_name": "Integrated Work Study Programme (Work Attachment)"
    },
    {
        "module_code": "ICT4012B",
        "code": "forum_ICT4012B_8",
        "topic": "ICT4012B Week 8",
        "module_name": "Integrated Work Study Programme (Work Attachment)"
    },
    {
        "module_code": "ICT4012B",
        "code": "forum_ICT4012B_9",
        "topic": "ICT4012B Week 9",
        "module_name": "Integrated Work Study Programme (Work Attachment)"
    },
    {
        "module_code": "ICT4012B",
        "code": "forum_ICT4012B_10",
        "topic": "ICT4012B Week 10",
        "module_name": "Integrated Work Study Programme (Work Attachment)"
    },
    {
        "module_code": "ICT4012B",
        "code": "forum_ICT4012B_11",
        "topic": "ICT4012B Week 11",
        "module_name": "Integrated Work Study Programme (Work Attachment)"
    },
    {
        "module_code": "ICT4012B",
        "code": "forum_ICT4012B_12",
        "topic": "ICT4012B Week 12",
        "module_name": "Integrated Work Study Programme (Work Attachment)"
    },
    {
        "module_code": "ICT4012B",
        "code": "forum_ICT4012B_13",
        "topic": "ICT4012B Week 13",
        "module_name": "Integrated Work Study Programme (Work Attachment)"
    }
]