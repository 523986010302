
export const forum_post_data = [
    {
        "id": 1,
        "forum_topic_id": "ICT1011_1",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 2,
        "forum_topic_id": "ICT1011_2",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 3,
        "forum_topic_id": "ICT1011_3",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 4,
        "forum_topic_id": "ICT1011_4",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 5,
        "forum_topic_id": "ICT1011_5",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 6,
        "forum_topic_id": "ICT1011_6",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 7,
        "forum_topic_id": "ICT1011_7",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 8,
        "forum_topic_id": "ICT1011_8",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 9,
        "forum_topic_id": "ICT1011_9",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 10,
        "forum_topic_id": "ICT1011_10",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 11,
        "forum_topic_id": "ICT1011_11",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 12,
        "forum_topic_id": "ICT1011_12",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 13,
        "forum_topic_id": "ICT1011_13",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 14,
        "forum_topic_id": "INF1001_1",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 15,
        "forum_topic_id": "INF1001_2",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 16,
        "forum_topic_id": "INF1001_3",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 17,
        "forum_topic_id": "INF1001_4",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 18,
        "forum_topic_id": "INF1001_5",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 19,
        "forum_topic_id": "INF1001_6",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 20,
        "forum_topic_id": "INF1001_7",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 21,
        "forum_topic_id": "INF1001_8",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 22,
        "forum_topic_id": "INF1001_9",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 23,
        "forum_topic_id": "INF1001_10",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 24,
        "forum_topic_id": "INF1001_11",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 25,
        "forum_topic_id": "INF1001_12",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 26,
        "forum_topic_id": "INF1001_13",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 27,
        "forum_topic_id": "INF1002_1",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 28,
        "forum_topic_id": "INF1002_2",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 29,
        "forum_topic_id": "INF1002_3",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 30,
        "forum_topic_id": "INF1002_4",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 31,
        "forum_topic_id": "INF1002_5",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 32,
        "forum_topic_id": "INF1002_6",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 33,
        "forum_topic_id": "INF1002_7",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 34,
        "forum_topic_id": "INF1002_8",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 35,
        "forum_topic_id": "INF1002_9",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 36,
        "forum_topic_id": "INF1002_10",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 37,
        "forum_topic_id": "INF1002_11",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 38,
        "forum_topic_id": "INF1002_12",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 39,
        "forum_topic_id": "INF1002_13",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 40,
        "forum_topic_id": "INF1003_1",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 41,
        "forum_topic_id": "INF1003_2",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 42,
        "forum_topic_id": "INF1003_3",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 43,
        "forum_topic_id": "INF1003_4",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 44,
        "forum_topic_id": "INF1003_5",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 45,
        "forum_topic_id": "INF1003_6",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 46,
        "forum_topic_id": "INF1003_7",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 47,
        "forum_topic_id": "INF1003_8",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 48,
        "forum_topic_id": "INF1003_9",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 49,
        "forum_topic_id": "INF1003_10",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 50,
        "forum_topic_id": "INF1003_11",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 51,
        "forum_topic_id": "INF1003_12",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 52,
        "forum_topic_id": "INF1003_13",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 53,
        "forum_topic_id": "UDC1001_1",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 54,
        "forum_topic_id": "UDC1001_2",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 55,
        "forum_topic_id": "UDC1001_3",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 56,
        "forum_topic_id": "UDC1001_4",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 57,
        "forum_topic_id": "UDC1001_5",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 58,
        "forum_topic_id": "UDC1001_6",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 59,
        "forum_topic_id": "UDC1001_7",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 60,
        "forum_topic_id": "UDC1001_8",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 61,
        "forum_topic_id": "UDC1001_9",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 62,
        "forum_topic_id": "UDC1001_10",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 63,
        "forum_topic_id": "UDC1001_11",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 64,
        "forum_topic_id": "UDC1001_12",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 65,
        "forum_topic_id": "UDC1001_13",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 66,
        "forum_topic_id": "ICT4012A_1",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 67,
        "forum_topic_id": "ICT4012A_2",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 68,
        "forum_topic_id": "ICT4012A_3",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 69,
        "forum_topic_id": "ICT4012A_4",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 70,
        "forum_topic_id": "ICT4012A_5",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 71,
        "forum_topic_id": "ICT4012A_6",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 72,
        "forum_topic_id": "ICT4012A_7",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 73,
        "forum_topic_id": "ICT4012A_8",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 74,
        "forum_topic_id": "ICT4012A_9",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 75,
        "forum_topic_id": "ICT4012A_10",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 76,
        "forum_topic_id": "ICT4012A_11",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 77,
        "forum_topic_id": "ICT4012A_12",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 78,
        "forum_topic_id": "ICT4012A_13",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 79,
        "forum_topic_id": "ICT1012_1",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 80,
        "forum_topic_id": "ICT1012_2",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 81,
        "forum_topic_id": "ICT1012_3",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 82,
        "forum_topic_id": "ICT1012_4",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 83,
        "forum_topic_id": "ICT1012_5",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 84,
        "forum_topic_id": "ICT1012_6",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 85,
        "forum_topic_id": "ICT1012_7",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 86,
        "forum_topic_id": "ICT1012_8",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 87,
        "forum_topic_id": "ICT1012_9",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 88,
        "forum_topic_id": "ICT1012_10",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 89,
        "forum_topic_id": "ICT1012_11",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 90,
        "forum_topic_id": "ICT1012_12",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 91,
        "forum_topic_id": "ICT1012_13",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 92,
        "forum_topic_id": "INF1004_1",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 93,
        "forum_topic_id": "INF1004_2",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 94,
        "forum_topic_id": "INF1004_3",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 95,
        "forum_topic_id": "INF1004_4",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 96,
        "forum_topic_id": "INF1004_5",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 97,
        "forum_topic_id": "INF1004_6",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 98,
        "forum_topic_id": "INF1004_7",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 99,
        "forum_topic_id": "INF1004_8",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 100,
        "forum_topic_id": "INF1004_9",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 101,
        "forum_topic_id": "INF1004_10",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 102,
        "forum_topic_id": "INF1004_11",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 103,
        "forum_topic_id": "INF1004_12",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 104,
        "forum_topic_id": "INF1004_13",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 105,
        "forum_topic_id": "INF1005_1",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 106,
        "forum_topic_id": "INF1005_2",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 107,
        "forum_topic_id": "INF1005_3",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 108,
        "forum_topic_id": "INF1005_4",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 109,
        "forum_topic_id": "INF1005_5",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 110,
        "forum_topic_id": "INF1005_6",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 111,
        "forum_topic_id": "INF1005_7",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 112,
        "forum_topic_id": "INF1005_8",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 113,
        "forum_topic_id": "INF1005_9",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 114,
        "forum_topic_id": "INF1005_10",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 115,
        "forum_topic_id": "INF1005_11",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 116,
        "forum_topic_id": "INF1005_12",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 117,
        "forum_topic_id": "INF1005_13",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 118,
        "forum_topic_id": "INF1009_1",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 119,
        "forum_topic_id": "INF1009_2",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 120,
        "forum_topic_id": "INF1009_3",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 121,
        "forum_topic_id": "INF1009_4",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 122,
        "forum_topic_id": "INF1009_5",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 123,
        "forum_topic_id": "INF1009_6",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 124,
        "forum_topic_id": "INF1009_7",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 125,
        "forum_topic_id": "INF1009_8",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 126,
        "forum_topic_id": "INF1009_9",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 127,
        "forum_topic_id": "INF1009_10",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 128,
        "forum_topic_id": "INF1009_11",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 129,
        "forum_topic_id": "INF1009_12",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 130,
        "forum_topic_id": "INF1009_13",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 131,
        "forum_topic_id": "UCS1001_1",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 132,
        "forum_topic_id": "UCS1001_2",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 133,
        "forum_topic_id": "UCS1001_3",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 134,
        "forum_topic_id": "UCS1001_4",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 135,
        "forum_topic_id": "UCS1001_5",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 136,
        "forum_topic_id": "UCS1001_6",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 137,
        "forum_topic_id": "UCS1001_7",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 138,
        "forum_topic_id": "UCS1001_8",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 139,
        "forum_topic_id": "UCS1001_9",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 140,
        "forum_topic_id": "UCS1001_10",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 141,
        "forum_topic_id": "UCS1001_11",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 142,
        "forum_topic_id": "UCS1001_12",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 143,
        "forum_topic_id": "UCS1001_13",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 144,
        "forum_topic_id": "INF1006_1",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 145,
        "forum_topic_id": "INF1006_2",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 146,
        "forum_topic_id": "INF1006_3",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 147,
        "forum_topic_id": "INF1006_4",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 148,
        "forum_topic_id": "INF1006_5",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 149,
        "forum_topic_id": "INF1006_6",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 150,
        "forum_topic_id": "INF1006_7",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 151,
        "forum_topic_id": "INF1006_8",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 152,
        "forum_topic_id": "INF1006_9",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 153,
        "forum_topic_id": "INF1006_10",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 154,
        "forum_topic_id": "INF1006_11",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 155,
        "forum_topic_id": "INF1006_12",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 156,
        "forum_topic_id": "INF1006_13",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 157,
        "forum_topic_id": "INF1007_1",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 158,
        "forum_topic_id": "INF1007_2",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 159,
        "forum_topic_id": "INF1007_3",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 160,
        "forum_topic_id": "INF1007_4",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 161,
        "forum_topic_id": "INF1007_5",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 162,
        "forum_topic_id": "INF1007_6",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 163,
        "forum_topic_id": "INF1007_7",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 164,
        "forum_topic_id": "INF1007_8",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 165,
        "forum_topic_id": "INF1007_9",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 166,
        "forum_topic_id": "INF1007_10",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 167,
        "forum_topic_id": "INF1007_11",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 168,
        "forum_topic_id": "INF1007_12",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 169,
        "forum_topic_id": "INF1007_13",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 170,
        "forum_topic_id": "INF1008_1",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 171,
        "forum_topic_id": "INF1008_2",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 172,
        "forum_topic_id": "INF1008_3",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 173,
        "forum_topic_id": "INF1008_4",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 174,
        "forum_topic_id": "INF1008_5",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 175,
        "forum_topic_id": "INF1008_6",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 176,
        "forum_topic_id": "INF1008_7",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 177,
        "forum_topic_id": "INF1008_8",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 178,
        "forum_topic_id": "INF1008_9",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 179,
        "forum_topic_id": "INF1008_10",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 180,
        "forum_topic_id": "INF1008_11",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 181,
        "forum_topic_id": "INF1008_12",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 182,
        "forum_topic_id": "INF1008_13",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 183,
        "forum_topic_id": "UDE1001_1",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 184,
        "forum_topic_id": "UDE1001_2",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 185,
        "forum_topic_id": "UDE1001_3",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 186,
        "forum_topic_id": "UDE1001_4",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 187,
        "forum_topic_id": "UDE1001_5",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 188,
        "forum_topic_id": "UDE1001_6",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 189,
        "forum_topic_id": "UDE1001_7",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 190,
        "forum_topic_id": "UDE1001_8",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 191,
        "forum_topic_id": "UDE1001_9",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 192,
        "forum_topic_id": "UDE1001_10",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 193,
        "forum_topic_id": "UDE1001_11",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 194,
        "forum_topic_id": "UDE1001_12",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 195,
        "forum_topic_id": "UDE1001_13",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 196,
        "forum_topic_id": "INF2001_1",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 197,
        "forum_topic_id": "INF2001_2",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 198,
        "forum_topic_id": "INF2001_3",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 199,
        "forum_topic_id": "INF2001_4",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 200,
        "forum_topic_id": "INF2001_5",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 201,
        "forum_topic_id": "INF2001_6",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 202,
        "forum_topic_id": "INF2001_7",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 203,
        "forum_topic_id": "INF2001_8",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 204,
        "forum_topic_id": "INF2001_9",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 205,
        "forum_topic_id": "INF2001_10",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 206,
        "forum_topic_id": "INF2001_11",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 207,
        "forum_topic_id": "INF2001_12",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 208,
        "forum_topic_id": "INF2001_13",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 209,
        "forum_topic_id": "INF2002_1",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 210,
        "forum_topic_id": "INF2002_2",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 211,
        "forum_topic_id": "INF2002_3",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 212,
        "forum_topic_id": "INF2002_4",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 213,
        "forum_topic_id": "INF2002_5",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 214,
        "forum_topic_id": "INF2002_6",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 215,
        "forum_topic_id": "INF2002_7",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 216,
        "forum_topic_id": "INF2002_8",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 217,
        "forum_topic_id": "INF2002_9",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 218,
        "forum_topic_id": "INF2002_10",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 219,
        "forum_topic_id": "INF2002_11",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 220,
        "forum_topic_id": "INF2002_12",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 221,
        "forum_topic_id": "INF2002_13",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 222,
        "forum_topic_id": "INF2003_1",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 223,
        "forum_topic_id": "INF2003_2",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 224,
        "forum_topic_id": "INF2003_3",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 225,
        "forum_topic_id": "INF2003_4",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 226,
        "forum_topic_id": "INF2003_5",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 227,
        "forum_topic_id": "INF2003_6",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 228,
        "forum_topic_id": "INF2003_7",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 229,
        "forum_topic_id": "INF2003_8",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 230,
        "forum_topic_id": "INF2003_9",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 231,
        "forum_topic_id": "INF2003_10",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 232,
        "forum_topic_id": "INF2003_11",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 233,
        "forum_topic_id": "INF2003_12",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 234,
        "forum_topic_id": "INF2003_13",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 235,
        "forum_topic_id": "INF2004_1",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 236,
        "forum_topic_id": "INF2004_2",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 237,
        "forum_topic_id": "INF2004_3",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 238,
        "forum_topic_id": "INF2004_4",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 239,
        "forum_topic_id": "INF2004_5",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 240,
        "forum_topic_id": "INF2004_6",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 241,
        "forum_topic_id": "INF2004_7",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 242,
        "forum_topic_id": "INF2004_8",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 243,
        "forum_topic_id": "INF2004_9",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 244,
        "forum_topic_id": "INF2004_10",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 245,
        "forum_topic_id": "INF2004_11",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 246,
        "forum_topic_id": "INF2004_12",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 247,
        "forum_topic_id": "INF2004_13",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 248,
        "forum_topic_id": "UDE2001_1",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 249,
        "forum_topic_id": "UDE2001_2",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 250,
        "forum_topic_id": "UDE2001_3",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 251,
        "forum_topic_id": "UDE2001_4",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 252,
        "forum_topic_id": "UDE2001_5",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 253,
        "forum_topic_id": "UDE2001_6",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 254,
        "forum_topic_id": "UDE2001_7",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 255,
        "forum_topic_id": "UDE2001_8",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 256,
        "forum_topic_id": "UDE2001_9",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 257,
        "forum_topic_id": "UDE2001_10",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 258,
        "forum_topic_id": "UDE2001_11",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 259,
        "forum_topic_id": "UDE2001_12",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 260,
        "forum_topic_id": "UDE2001_13",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 261,
        "forum_topic_id": "ICT2112_1",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 262,
        "forum_topic_id": "ICT2112_2",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 263,
        "forum_topic_id": "ICT2112_3",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 264,
        "forum_topic_id": "ICT2112_4",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 265,
        "forum_topic_id": "ICT2112_5",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 266,
        "forum_topic_id": "ICT2112_6",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 267,
        "forum_topic_id": "ICT2112_7",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 268,
        "forum_topic_id": "ICT2112_8",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 269,
        "forum_topic_id": "ICT2112_9",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 270,
        "forum_topic_id": "ICT2112_10",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 271,
        "forum_topic_id": "ICT2112_11",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 272,
        "forum_topic_id": "ICT2112_12",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 273,
        "forum_topic_id": "ICT2112_13",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 274,
        "forum_topic_id": "ICT2113_1",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 275,
        "forum_topic_id": "ICT2113_2",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 276,
        "forum_topic_id": "ICT2113_3",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 277,
        "forum_topic_id": "ICT2113_4",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 278,
        "forum_topic_id": "ICT2113_5",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 279,
        "forum_topic_id": "ICT2113_6",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 280,
        "forum_topic_id": "ICT2113_7",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 281,
        "forum_topic_id": "ICT2113_8",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 282,
        "forum_topic_id": "ICT2113_9",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 283,
        "forum_topic_id": "ICT2113_10",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 284,
        "forum_topic_id": "ICT2113_11",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 285,
        "forum_topic_id": "ICT2113_12",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 286,
        "forum_topic_id": "ICT2113_13",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 287,
        "forum_topic_id": "ICT2215_1",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 288,
        "forum_topic_id": "ICT2215_2",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 289,
        "forum_topic_id": "ICT2215_3",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 290,
        "forum_topic_id": "ICT2215_4",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 291,
        "forum_topic_id": "ICT2215_5",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 292,
        "forum_topic_id": "ICT2215_6",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 293,
        "forum_topic_id": "ICT2215_7",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 294,
        "forum_topic_id": "ICT2215_8",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 295,
        "forum_topic_id": "ICT2215_9",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 296,
        "forum_topic_id": "ICT2215_10",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 297,
        "forum_topic_id": "ICT2215_11",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 298,
        "forum_topic_id": "ICT2215_12",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 299,
        "forum_topic_id": "ICT2215_13",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 300,
        "forum_topic_id": "INF2007_1",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 301,
        "forum_topic_id": "INF2007_2",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 302,
        "forum_topic_id": "INF2007_3",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 303,
        "forum_topic_id": "INF2007_4",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 304,
        "forum_topic_id": "INF2007_5",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 305,
        "forum_topic_id": "INF2007_6",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 306,
        "forum_topic_id": "INF2007_7",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 307,
        "forum_topic_id": "INF2007_8",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 308,
        "forum_topic_id": "INF2007_9",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 309,
        "forum_topic_id": "INF2007_10",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 310,
        "forum_topic_id": "INF2007_11",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 311,
        "forum_topic_id": "INF2007_12",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 312,
        "forum_topic_id": "INF2007_13",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 313,
        "forum_topic_id": "ICT2114_1",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 314,
        "forum_topic_id": "ICT2114_2",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 315,
        "forum_topic_id": "ICT2114_3",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 316,
        "forum_topic_id": "ICT2114_4",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 317,
        "forum_topic_id": "ICT2114_5",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 318,
        "forum_topic_id": "ICT2114_6",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 319,
        "forum_topic_id": "ICT2114_7",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 320,
        "forum_topic_id": "ICT2114_8",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 321,
        "forum_topic_id": "ICT2114_9",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 322,
        "forum_topic_id": "ICT2114_10",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 323,
        "forum_topic_id": "ICT2114_11",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 324,
        "forum_topic_id": "ICT2114_12",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 325,
        "forum_topic_id": "ICT2114_13",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 326,
        "forum_topic_id": "ICT2216_1",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 327,
        "forum_topic_id": "ICT2216_2",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 328,
        "forum_topic_id": "ICT2216_3",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 329,
        "forum_topic_id": "ICT2216_4",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 330,
        "forum_topic_id": "ICT2216_5",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 331,
        "forum_topic_id": "ICT2216_6",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 332,
        "forum_topic_id": "ICT2216_7",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 333,
        "forum_topic_id": "ICT2216_8",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 334,
        "forum_topic_id": "ICT2216_9",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 335,
        "forum_topic_id": "ICT2216_10",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 336,
        "forum_topic_id": "ICT2216_11",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 337,
        "forum_topic_id": "ICT2216_12",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 338,
        "forum_topic_id": "ICT2216_13",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 339,
        "forum_topic_id": "ICT3219_1",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 340,
        "forum_topic_id": "ICT3219_2",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 341,
        "forum_topic_id": "ICT3219_3",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 342,
        "forum_topic_id": "ICT3219_4",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 343,
        "forum_topic_id": "ICT3219_5",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 344,
        "forum_topic_id": "ICT3219_6",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 345,
        "forum_topic_id": "ICT3219_7",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 346,
        "forum_topic_id": "ICT3219_8",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 347,
        "forum_topic_id": "ICT3219_9",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 348,
        "forum_topic_id": "ICT3219_10",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 349,
        "forum_topic_id": "ICT3219_11",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 350,
        "forum_topic_id": "ICT3219_12",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 351,
        "forum_topic_id": "ICT3219_13",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 352,
        "forum_topic_id": "INF2005_1",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 353,
        "forum_topic_id": "INF2005_2",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 354,
        "forum_topic_id": "INF2005_3",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 355,
        "forum_topic_id": "INF2005_4",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 356,
        "forum_topic_id": "INF2005_5",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 357,
        "forum_topic_id": "INF2005_6",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 358,
        "forum_topic_id": "INF2005_7",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 359,
        "forum_topic_id": "INF2005_8",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 360,
        "forum_topic_id": "INF2005_9",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 361,
        "forum_topic_id": "INF2005_10",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 362,
        "forum_topic_id": "INF2005_11",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 363,
        "forum_topic_id": "INF2005_12",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 364,
        "forum_topic_id": "INF2005_13",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 365,
        "forum_topic_id": "ICT3112_1",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 366,
        "forum_topic_id": "ICT3112_2",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 367,
        "forum_topic_id": "ICT3112_3",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 368,
        "forum_topic_id": "ICT3112_4",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 369,
        "forum_topic_id": "ICT3112_5",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 370,
        "forum_topic_id": "ICT3112_6",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 371,
        "forum_topic_id": "ICT3112_7",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 372,
        "forum_topic_id": "ICT3112_8",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 373,
        "forum_topic_id": "ICT3112_9",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 374,
        "forum_topic_id": "ICT3112_10",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 375,
        "forum_topic_id": "ICT3112_11",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 376,
        "forum_topic_id": "ICT3112_12",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 377,
        "forum_topic_id": "ICT3112_13",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 378,
        "forum_topic_id": "ICT3113_1",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 379,
        "forum_topic_id": "ICT3113_2",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 380,
        "forum_topic_id": "ICT3113_3",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 381,
        "forum_topic_id": "ICT3113_4",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 382,
        "forum_topic_id": "ICT3113_5",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 383,
        "forum_topic_id": "ICT3113_6",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 384,
        "forum_topic_id": "ICT3113_7",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 385,
        "forum_topic_id": "ICT3113_8",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 386,
        "forum_topic_id": "ICT3113_9",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 387,
        "forum_topic_id": "ICT3113_10",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 388,
        "forum_topic_id": "ICT3113_11",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 389,
        "forum_topic_id": "ICT3113_12",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 390,
        "forum_topic_id": "ICT3113_13",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 391,
        "forum_topic_id": "INF2006_1",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 392,
        "forum_topic_id": "INF2006_2",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 393,
        "forum_topic_id": "INF2006_3",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 394,
        "forum_topic_id": "INF2006_4",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 395,
        "forum_topic_id": "INF2006_5",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 396,
        "forum_topic_id": "INF2006_6",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 397,
        "forum_topic_id": "INF2006_7",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 398,
        "forum_topic_id": "INF2006_8",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 399,
        "forum_topic_id": "INF2006_9",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 400,
        "forum_topic_id": "INF2006_10",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 401,
        "forum_topic_id": "INF2006_11",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 402,
        "forum_topic_id": "INF2006_12",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 403,
        "forum_topic_id": "INF2006_13",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 404,
        "forum_topic_id": "USI2001_1",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 405,
        "forum_topic_id": "USI2001_2",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 406,
        "forum_topic_id": "USI2001_3",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 407,
        "forum_topic_id": "USI2001_4",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 408,
        "forum_topic_id": "USI2001_5",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 409,
        "forum_topic_id": "USI2001_6",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 410,
        "forum_topic_id": "USI2001_7",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 411,
        "forum_topic_id": "USI2001_8",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 412,
        "forum_topic_id": "USI2001_9",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 413,
        "forum_topic_id": "USI2001_10",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 414,
        "forum_topic_id": "USI2001_11",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 415,
        "forum_topic_id": "USI2001_12",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 416,
        "forum_topic_id": "USI2001_13",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 417,
        "forum_topic_id": "ICT3217_1",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 418,
        "forum_topic_id": "ICT3217_2",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 419,
        "forum_topic_id": "ICT3217_3",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 420,
        "forum_topic_id": "ICT3217_4",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 421,
        "forum_topic_id": "ICT3217_5",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 422,
        "forum_topic_id": "ICT3217_6",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 423,
        "forum_topic_id": "ICT3217_7",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 424,
        "forum_topic_id": "ICT3217_8",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 425,
        "forum_topic_id": "ICT3217_9",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 426,
        "forum_topic_id": "ICT3217_10",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 427,
        "forum_topic_id": "ICT3217_11",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 428,
        "forum_topic_id": "ICT3217_12",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 429,
        "forum_topic_id": "ICT3217_13",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 430,
        "forum_topic_id": "INF2008_1",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 431,
        "forum_topic_id": "INF2008_2",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 432,
        "forum_topic_id": "INF2008_3",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 433,
        "forum_topic_id": "INF2008_4",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 434,
        "forum_topic_id": "INF2008_5",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 435,
        "forum_topic_id": "INF2008_6",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 436,
        "forum_topic_id": "INF2008_7",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 437,
        "forum_topic_id": "INF2008_8",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 438,
        "forum_topic_id": "INF2008_9",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 439,
        "forum_topic_id": "INF2008_10",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 440,
        "forum_topic_id": "INF2008_11",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 441,
        "forum_topic_id": "INF2008_12",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 442,
        "forum_topic_id": "INF2008_13",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 443,
        "forum_topic_id": "INF2009_1",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 444,
        "forum_topic_id": "INF2009_2",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 445,
        "forum_topic_id": "INF2009_3",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 446,
        "forum_topic_id": "INF2009_4",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 447,
        "forum_topic_id": "INF2009_5",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 448,
        "forum_topic_id": "INF2009_6",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 449,
        "forum_topic_id": "INF2009_7",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 450,
        "forum_topic_id": "INF2009_8",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 451,
        "forum_topic_id": "INF2009_9",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 452,
        "forum_topic_id": "INF2009_10",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 453,
        "forum_topic_id": "INF2009_11",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 454,
        "forum_topic_id": "INF2009_12",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 455,
        "forum_topic_id": "INF2009_13",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 456,
        "forum_topic_id": "UCM3001_1",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 457,
        "forum_topic_id": "UCM3001_2",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 458,
        "forum_topic_id": "UCM3001_3",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 459,
        "forum_topic_id": "UCM3001_4",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 460,
        "forum_topic_id": "UCM3001_5",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 461,
        "forum_topic_id": "UCM3001_6",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 462,
        "forum_topic_id": "UCM3001_7",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 463,
        "forum_topic_id": "UCM3001_8",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 464,
        "forum_topic_id": "UCM3001_9",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 465,
        "forum_topic_id": "UCM3001_10",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 466,
        "forum_topic_id": "UCM3001_11",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 467,
        "forum_topic_id": "UCM3001_12",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 468,
        "forum_topic_id": "UCM3001_13",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 469,
        "forum_topic_id": "ICT4011_1",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 470,
        "forum_topic_id": "ICT4011_2",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 471,
        "forum_topic_id": "ICT4011_3",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 472,
        "forum_topic_id": "ICT4011_4",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 473,
        "forum_topic_id": "ICT4011_5",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 474,
        "forum_topic_id": "ICT4011_6",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 475,
        "forum_topic_id": "ICT4011_7",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 476,
        "forum_topic_id": "ICT4011_8",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 477,
        "forum_topic_id": "ICT4011_9",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 478,
        "forum_topic_id": "ICT4011_10",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 479,
        "forum_topic_id": "ICT4011_11",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 480,
        "forum_topic_id": "ICT4011_12",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 481,
        "forum_topic_id": "ICT4011_13",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 482,
        "forum_topic_id": "ICT4012B_1",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 483,
        "forum_topic_id": "ICT4012B_2",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 484,
        "forum_topic_id": "ICT4012B_3",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 485,
        "forum_topic_id": "ICT4012B_4",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 486,
        "forum_topic_id": "ICT4012B_5",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 487,
        "forum_topic_id": "ICT4012B_6",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 488,
        "forum_topic_id": "ICT4012B_7",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 489,
        "forum_topic_id": "ICT4012B_8",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 490,
        "forum_topic_id": "ICT4012B_9",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 491,
        "forum_topic_id": "ICT4012B_10",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 492,
        "forum_topic_id": "ICT4012B_11",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 493,
        "forum_topic_id": "ICT4012B_12",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 494,
        "forum_topic_id": "ICT4012B_13",
        "title": "Useful Resource",
        "content": "<p>I found a useful resource that helped in my understanding of this topic greatly: <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>https://www.youtube.com/watch?v=dQw4w9WgXcQ</a></p>",
        "posted_on": "2023-11-01 01:18:49",
        "user_id": "alain"
    },
    {
        "id": 495,
        "forum_topic_id": "INF2002_11",
        "title": "Help we cannot finish",
        "content": "<p><strong>Hi prof,</strong></p><p><br></p><p><u>We think we need more time for the implementation!</u></p><p><br></p><p><em>Thank you!</em></p><p><br></p><p><img src=\"https://4.bp.blogspot.com/-CAz_Js2Niek/Tt4s06n7PoI/AAAAAAAACIU/ejdCNDSjEzA/s1600/HDHuT.BlogSpot.com+%252817%2529.jpg\" alt=\"Top 29 Wallpapers OF Sad And Crying Babies In HD\"></p>",
        "posted_on": "2023-11-05 21:56:03",
        "user_id": "alain"
    },
    {
        "id": 496,
        "forum_topic_id": "INF2002_11",
        "title": "Test",
        "content": "<p>Am I logged in?</p>",
        "posted_on": "2023-11-05 22:51:36",
        "user_id": "cheehean"
    }
]