export const examination_questions_data = [
  {
    id: 1,
    question: "What is User Centred Design (UCD)?",
    suggested_answer:
      "User Centred Design is an iterative process, where the product is modified and tested repeatedly with users throughout the lifecycle.",
    examination_id: "inf2002-quiz1",
  },
  {
    id: 2,
    question: "What are the User Centred Design principles?",
    suggested_answer:
      "The User Centred Design principles are early focus on users and tasks, empirical measurement and testing of product usage, " +
      "as well as iterative design with product designed, modified and tested repeatedly.",
    examination_id: "inf2002-quiz1",
  },
  {
    id: 3,
    question:
      "What are the usability activities at each stage of the User Centred Design process?",
    suggested_answer:
      "Concept: usability goals and objectives, user profiles and personas, interviews, field studies, task analysis\n" +
      "Design: lo-fi prototypes, heuristic evaluations, focus groups, interviews\n" +
      "Develop: preparation, planning and execution of pre product release heuristic evaluations, usability testing\n" +
      "Release: formal usability testing, surveys, interviews for feedback on system, site visits\n",
    examination_id: "inf2002-quiz1",
  },
  {
    id: 4,
    question:
      "Why is it important to include the user during the design process?",
    suggested_answer:
      "Including the user during the design process helps to identify user needs and requirements, as well as allowing for early user feedback.",
    examination_id: "inf2002-quiz1",
  },
  {
    id: 5,
    question: "What is a persona? Why is it useful?",
    suggested_answer:
      "A persona is a representation of the goals and behaviours of a hypothesised group of users, synthesised from data collected through research. It is not a real user.\n" +
      "Personas are useful in focusing and guiding design decisions about the product by taking into consideration the target group to design for.\n",
    examination_id: "inf2002-quiz1",
  },
  {
    id: 6,
    question:
      "What is a Database Management System (DBMS) and why is it essential in software engineering?",
    suggested_answer:
      "A DBMS is a software suite designed to manage and manipulate databases. It acts as an interface between the database and the end-users or application programs," +
      " ensuring efficient, secure, and organized data storage and retrieval.\n",
    examination_id: "inf2003-quiz1",
  },
  {
    id: 7,
    question:
      "Explain the difference between SQL and NoSQL databases. When would you choose one over the other?",
    suggested_answer:
      "SQL databases are relational databases, while NoSQL databases are non-relational and can handle unstructured data. The choice between them depends on the specific project requirements," +
      " such as scalability, flexibility, and data structure.\n",
    examination_id: "inf2003-quiz1",
  },
  {
    id: 8,
    question: "What is a primary key in a database, and why is it important?",
    suggested_answer:
      "A primary key is a unique identifier for each record in a database table. It ensures that each record can be uniquely identified and " +
      "helps maintain data integrity by preventing duplicate or null values in the key field.\n",
    examination_id: "inf2003-quiz1",
  },
  {
    id: 9,
    question:
      "Explain the concept of normalization in the context of database design. Why is it crucial for efficient data storage and retrieval?",
    suggested_answer:
      "Normalization is the process of organizing data in a database to reduce redundancy and improve data integrity. It involves dividing large tables into smaller, related tables and defining relationships between them. " +
      "Normalization ensures efficient storage, minimizes data duplication, and enhances query performance.\n",
    examination_id: "inf2003-quiz1",
  },
  {
    id: 10,
    question:
      "What is the Entity-Relationship (ER) model, and how does it represent the relationships between entities in a database?",
    suggested_answer:
      "The ER model is a conceptual data model used to represent the relationships between entities in a database. It uses entities to represent real-world objects and relationships to depict the associations between these entities. " +
      "Entities have attributes that describe their properties, and relationships define how entities interact with each other.\n",
    examination_id: "inf2003-quiz1",
  },
  {
    id: 11,
    question:
      "What is an embedded system, and how does it differ from general-purpose computer systems?",
    suggested_answer:
      "Embedded systems are specialized computing systems designed to perform dedicated functions or tasks within larger systems. They are typically embedded into devices, making them specific to particular applications. " +
      "Unlike general-purpose computers, embedded systems are optimized for efficiency, reliability, and real-time operation.\n",
    examination_id: "inf2004-quiz1",
  },
  {
    id: 12,
    question:
      "Explain the components of an embedded system. What role do microcontrollers and microprocessors play in embedded systems?",
    suggested_answer:
      "Embedded systems consist of a microcontroller/microprocessor, memory, input/output interfaces, and software. Microcontrollers are integrated circuits that contain a processor core, memory, and input/output peripherals, while microprocessors are central processing units (CPUs) used for more complex tasks. " +
      "Microcontrollers are commonly used in embedded systems due to their low cost, low power consumption, and integration capabilities.\n",
    examination_id: "inf2004-quiz1",
  },
  {
    id: 13,
    question:
      "What is Free Running Mode in microcontrollers, and how does it differ from Periodic Timer Mode?",
    suggested_answer:
      "Free Running Mode allows a timer/counter in a microcontroller to continuously count up or down without external intervention until it reaches its maximum or minimum value. " +
      "In contrast, Periodic Timer Mode enables the timer/counter to generate interrupts or trigger specific actions at regular intervals set by a predefined period.\n",
    examination_id: "inf2004-quiz1",
  },
  {
    id: 14,
    question:
      "What is Analog-to-Digital Conversion (ADC) and why is it necessary in microcontroller applications?",
    suggested_answer:
      "ADC is the process of converting analog signals, such as voltage or current, into digital values that can be processed by a microcontroller. It is essential because microcontrollers work with digital data, " +
      "and many real-world signals, like temperature or light intensity, are analog in nature.\n",
    examination_id: "inf2004-quiz1",
  },
  {
    id: 15,
    question:
      "Explain the concept of input range in ADC. How is it determined, and what happens if the input signal exceeds the specified input range?",
    suggested_answer:
      "The input range of an ADC defines the minimum and maximum analog input voltages that it can accurately convert to digital values. If the input signal exceeds this range, the ADC may saturate, resulting in clipped or distorted digital output. " +
      "It is crucial to ensure the input signal remains within the specified range to obtain accurate conversions.\n",
    examination_id: "inf2004-quiz1",
  },
  {
    id: 16,
    question:
      "What are ACID properties in the context of database transactions? Why are they important for ensuring data consistency and reliability?",
    suggested_answer:
      "ACID stands for Atomicity, Consistency, Isolation, and Durability, which are a set of properties that guarantee the reliability of database transactions. Atomicity ensures that transactions are treated as a single, " +
      "indivisible unit; Consistency maintains the database in a valid state before and after the transaction; Isolation ensures transactions are executed independently without interference, and Durability ensures that committed transactions are permanent and survive system failures.\n",
    examination_id: "inf2003-quiz2",
  },
  {
    id: 17,
    question:
      "What is a transaction in the context of a database, and why are transactions important in SQL databases?",
    suggested_answer:
      "A transaction in a database is a sequence of one or more SQL statements that are executed as a single unit of work. Transactions are crucial in SQL databases to ensure data consistency, integrity, and reliability. " +
      "They allow a set of operations to be executed atomically, meaning either all of them are completed, or none of them are, ensuring that the database remains in a valid state.\n",
    examination_id: "inf2003-quiz2",
  },
  {
    id: 18,
    question:
      "What is the purpose of the SAVEPOINT statement in SQL transactions? How can SAVEPOINTs be used to manage and control the rollback process in nested transactions?",
    suggested_answer:
      "SAVEPOINTs in SQL transactions allow you to set points within a transaction to which you can later roll back. They are especially useful in managing rollback behavior within nested transactions. SAVEPOINTs can be set at different stages of a transaction, and if an error occurs, " +
      "you can roll back to a specific SAVEPOINT without affecting the entire transaction, providing more granular control over the rollback process.\n",
    examination_id: "inf2003-quiz2",
  },
  {
    id: 19,
    question:
      "What are the advantages and challenges of using NoSQL databases in comparison to traditional SQL databases? Provide examples of scenarios where NoSQL databases excel and where they might be less suitable.",
    suggested_answer:
      "Advantages of NoSQL databases include flexibility in handling unstructured data, horizontal scalability, and better performance for certain use cases like real-time analytics or IoT applications. Challenges include eventual consistency trade-offs, " +
      "lack of ACID transactions in some cases, and a steeper learning curve for developers accustomed to SQL databases. NoSQL databases are excellent for applications requiring rapid development, scalability, " +
      "and handling large volumes of varied data. However, they might be less suitable for applications requiring strict ACID transactions and complex joins.\n",
    examination_id: "inf2003-quiz2",
  },
  {
    id: 20,
    question: "What are the differences between a storyboard and a prototype?",
    suggested_answer:
      "Storyboards serve as visual narratives, depicting the sequence of a user's interaction with an interface. They consist of illustrated frames, showcasing different states of the interface and aiding in conceptualization. " +
      "Prototypes, on the other hand, are interactive models allowing users to engage directly. They are clickable, facilitating usability testing and refining design choices based on real interactions.\n",
    examination_id: "inf2002-quiz2",
  },
  {
    id: 21,
    question:
      "What are the differences between empirical and predictive evaluation? What are their pros and cons?",
    suggested_answer:
      "Empirical methods involve observing real users for insights, offering depth but time intensity. Predictive methods, like expert reviews, rely on expert judgment, providing quick insights but lacking user depth.\n",
    examination_id: "inf2002-quiz2",
  },
  {
    id: 22,
    question:
      "How do users navigate and read pages? What are the implications of such user navigation?",
    suggested_answer:
      "Users navigate through menus, links, and buttons, following reading patterns like F-pattern. Understanding these patterns helps optimize visual hierarchy, placing essential content where users are likely to look first.\n",
    examination_id: "inf2002-quiz2",
  },
  {
    id: 23,
    question: "How do you overcome these limits during UI design?",
    suggested_answer:
      "To overcome design limitations, user research, usability testing, and iterative design are essential. User-centered principles, accessibility guidelines, and responsive design techniques ensure effective interfaces.\n",
    examination_id: "inf2002-quiz2",
  },
  {
    id: 24,
    question: "What are the main effects of cognitive load?",
    suggested_answer:
      "Cognitive load, the mental effort required for processing information, has significant effects on individuals' abilities and decision-making processes. When cognitive load is high, it overwhelms the working memory, limiting its ability to process new information effectively. " +
      "This overload impairs individuals' focus, learning, and decision-making abilities. It diminishes working memory capacity, impairs decision-making abilities, and hampers the learning process\n",
    examination_id: "inf2002-quiz2",
  },
  {
    id: 25,
    question:
      "What is software engineering, and why is it important in the field of computer science",
    suggested_answer:
      "Software engineering is the systematic design, development, testing, and maintenance of software applications and systems. It employs engineering principles and methods to ensure the reliability, efficiency, and quality of software products. " +
      "Software engineering is essential because it provides structured approaches to develop software solutions, meeting user requirements and industry standards.\n",
    examination_id: "inf2001-quiz1",
  },
  {
    id: 26,
    question:
      "Explain the software development life cycle (SDLC) and its phases. How does SDLC contribute to the successful development of software projects?",
    suggested_answer:
      "SDLC is a systematic process for planning, creating, testing, deploying, and maintaining software applications. Its phases include requirements gathering, design, implementation, testing, deployment, and maintenance. SDLC provides a structured approach, " +
      "ensuring that software projects are well-organized, predictable, and meet customer requirements, leading to successful and high-quality software products.\n",
    examination_id: "inf2001-quiz1",
  },
  {
    id: 27,
    question:
      "What are software requirements, and why is gathering and analyzing requirements crucial in the software development process?",
    suggested_answer:
      "Software requirements are detailed descriptions of the software system's functionality, performance, constraints, and interfaces. Gathering and analyzing requirements are critical because they form the foundation for the entire software development process. " +
      "Clear, well-defined requirements help developers understand what needs to be built, reduce misunderstandings, and guide the design and implementation phases, ensuring that the software meets users' needs and expectations.\n",
    examination_id: "inf2001-quiz1",
  },
  {
    id: 28,
    question:
      "Explain the concept of software documentation and its significance in software engineering projects. What types of documentation are typically created during the software development process?",
    suggested_answer:
      "Software documentation includes various written materials that provide information about the software product, its design, implementation, and usage. It is essential for communication, knowledge sharing, and maintenance. " +
      "Types of documentation include requirements documents, design documents, technical specifications, user manuals, and API documentation. " +
      "Proper documentation ensures that developers, stakeholders, and users have a clear understanding of the software, leading to effective collaboration and successful project outcomes.\n",
    examination_id: "inf2001-quiz1",
  },
  {
    id: 29,
    type: "MCQ",
    question:
      "Which enzyme, present in tears, facilitates the degradation of bacterial cell walls?",
    option: [
      "Lipase",
      "Catalase",
      "Lysozyme",
      "Protease",
      "Amylase",
      "Nuclease",
    ],
    suggested_answer: "Lysozyme",
    examination_id: "LSM2212-quiz1",
  },
  {
    id: 30,
    type: "MCQ",
    question:
      "What structures release a dense fluid that aids in safeguarding the digestive and respiratory tract from pathogens?",
    option: [
      "Sweat glands",
      "Mucous glands",
      "Sebaceous glands",
      "Pancreatic glands",
      "Alveolar cells",
      "Goblet cells",
    ],
    suggested_answer: "Mucous glands",
    examination_id: "LSM2212-quiz1",
  },
  {
    id: 31,
    type: "MCQ",
    question:
      "Name the extensive organ that encompasses most of the body and functions as a primary barrier against pathogens",
    option: [
      "Small intestine",
      "Lungs",
      "Stomach",
      "Skin",
      "Spleen",
      "Pancreas",
    ],
    suggested_answer: "Skin",
    examination_id: "LSM2212-quiz1",
  },
  {
    id: 32,
    type: "MCQ",
    question: "What is the primary function of the human immune system?",
    option: [
      "Fighting off infections and pathogens",
      "Filtering waste from the blood",
      "Maintaining electrolyte balance",
      "Transmitting nerve signals",
      "Regulating hormone levels",
      "Digesting food particles",
    ],
    suggested_answer: "Fighting off infections and pathogens",
    examination_id: "LSM2212-quiz1",
  },
  {
    id: 33,
    type: "MCQ",
    question: "Identify the predominant leukocyte present in the bloodstream",
    option: [
      "Eosinophil",
      "Neutrophils",
      "Hematocyte",
      "Platelet",
      "Monocyte",
      "Basophil",
    ],
    suggested_answer: "Neutrophils",
    examination_id: "LSM2212-quiz1",
  },
  {
    id: 34,
    type: "MCQ",
    question: "What is the primary purpose of the nervous system?",
    option: [
      "To provide sensory and motor functions, as well as the control of basic life processes",
      "Digesting food for energy",
      "Producing hormones for growth",
      "Transmitting and processing information",
      "Regulating blood sugar levels",
      "Filtering toxins from the bloodstream",
    ],
    suggested_answer:
      "To provide sensory and motor functions, as well as the control of basic life processes",
    examination_id: "LSM2212-quiz2",
  },
  {
    id: 35,
    type: "MCQ",
    question:
      "What distinctive characteristics delineate a neuron as the fundamental unit within the intricate network of the nervous system, orchestrating the intricate interplay of electrical and chemical signals among its synaptic connections?",
    option: [
      "They propagate electrical impulses along their axons",
      "They integrate and process incoming signals",
      "They synapse with other neurons and transmit electrical and chemical signals",
      "They modulate the strength of synaptic connections",
      "They store energy for long-term use",
      "They produce enzymes for digestion",
    ],
    suggested_answer:
      "They synapse with other neurons and transmit electrical and chemical signals",
    examination_id: "LSM2212-quiz2",
  },
  {
    id: 36,
    type: "MCQ",
    question:
      "What defines a synapse in the context of nerve cells interacting, facilitating the passage of signals from the presynaptic cell's axon to the postsynaptic cell's dendrite?",
    option: [
      "A type of muscle contraction",
      "The junction between two bones",
      "A synapse is a region where one nerve cell adjoins another",
      "A structure in the digestive system",
      "A process in cellular respiration",
      "A component of blood clotting",
    ],
    suggested_answer:
      "A synapse is a region where one nerve cell adjoins another",
    examination_id: "LSM2212-quiz2",
  },
  {
    id: 37,
    type: "MCQ",
    question:
      "In the intricate network of neurons, pinpoint the segment responsible for receiving incoming signals in the form of neurotransmitter-containing vesicles from preceding neurons?",
    option: [
      "Axon",
      "Myelin sheath",
      "Receptor sites",
      "Dendrites",
      "Schwann cells",
      "Axon terminals",
    ],
    suggested_answer: "Dendrites",
    examination_id: "LSM2212-quiz2",
  },
  {
    id: 38,
    type: "MCQ",
    question:
      "Identify the specific region within a neuron housing essential components such as the nucleus, endoplasmic reticulum, and mitochondria?",
    option: [
      "Soma",
      "Synaptic terminals",
      "Nodes of Ranvier",
      "Dendrites",
      "Axon hillock",
      "Golgi apparatus",
    ],
    suggested_answer: "Soma",
    examination_id: "LSM2212-quiz2",
  },
];
