
export const module_data = [
    {
        "id": "LSM2212",
        "code": "LSM2212",
        "name": "Human Anatomy",
        "trimesters": [
            {
                "trimester": 1,
                "year": 1
            }
        ],
        "experiment": true
    },
    {
        "id": "ICT1011",
        "code": "ICT1011",
        "name": "Computer Organization & Architecture",
        "trimesters": [
            {
                "trimester": 1,
                "year": 1
            }
        ]
    },
    {
        "id": "INF1001",
        "code": "INF1001",
        "name": "Introduction to Computing",
        "trimesters": [
            {
                "trimester": 1,
                "year": 1
            }
        ]
    },
    {
        "id": "INF1002",
        "code": "INF1002",
        "name": "Programming Fundamentals",
        "trimesters": [
            {
                "trimester": 1,
                "year": 1
            }
        ]
    },
    {
        "id": "INF1003",
        "code": "INF1003",
        "name": "Mathematics 1",
        "trimesters": [
            {
                "trimester": 1,
                "year": 1
            }
        ]
    },
    {
        "id": "UDC1001",
        "code": "UDC1001",
        "name": "Digital Competency Essentials",
        "trimesters": [
            {
                "trimester": 1,
                "year": 1
            }
        ]
    },
    {
        "id": "ICT4012A",
        "code": "ICT4012A",
        "name": "Integrated Work Study Programme (Career Skills)",
        "trimesters": [
            {
                "trimester": 1,
                "year": 1
            },
            {
                "trimester": 2,
                "year": 1
            },
            {
                "trimester": 3,
                "year": 1
            },
            {
                "trimester": 1,
                "year": 2
            }
        ]
    },
    {
        "id": "ICT1012",
        "code": "ICT1012",
        "name": "Operating Systems",
        "trimesters": [
            {
                "trimester": 2,
                "year": 1
            }
        ]
    },
    {
        "id": "INF1004",
        "code": "INF1004",
        "name": "Mathematics 2",
        "trimesters": [
            {
                "trimester": 2,
                "year": 1
            }
        ]
    },
    {
        "id": "INF1005",
        "code": "INF1005",
        "name": "Web Systems & Technologies",
        "trimesters": [
            {
                "trimester": 2,
                "year": 1
            }
        ]
    },
    {
        "id": "INF1009",
        "code": "INF1009",
        "name": "Object-Oriented Programming",
        "trimesters": [
            {
                "trimester": 2,
                "year": 1
            }
        ]
    },
    {
        "id": "UCS1001",
        "code": "UCS1001",
        "name": "Critical Thinking and Communicating",
        "trimesters": [
            {
                "trimester": 2,
                "year": 1
            }
        ]
    },
    {
        "id": "INF1006",
        "code": "INF1006",
        "name": "Computer Networks",
        "trimesters": [
            {
                "trimester": 3,
                "year": 1
            }
        ]
    },
    {
        "id": "INF1007",
        "code": "INF1007",
        "name": "Ethics and Professional Conducts",
        "trimesters": [
            {
                "trimester": 3,
                "year": 1
            }
        ]
    },
    {
        "id": "INF1008",
        "code": "INF1008",
        "name": "Data Structures and Algorithms",
        "trimesters": [
            {
                "trimester": 3,
                "year": 1
            }
        ]
    },
    {
        "id": "UDE1001",
        "code": "UDE1001",
        "name": "Introduction to Design Innovation",
        "trimesters": [
            {
                "trimester": 3,
                "year": 1
            }
        ]
    },
    {
        "id": "INF2001",
        "code": "INF2001",
        "name": "Introduction to Software Engineering",
        "trimesters": [
            {
                "trimester": 1,
                "year": 2
            }
        ]
    },
    {
        "id": "INF2002",
        "code": "INF2002",
        "name": "Human Computer Interaction",
        "trimesters": [
            {
                "trimester": 1,
                "year": 2
            }
        ]
    },
    {
        "id": "INF2003",
        "code": "INF2003",
        "name": "Database Systems",
        "trimesters": [
            {
                "trimester": 1,
                "year": 2
            }
        ]
    },
    {
        "id": "INF2004",
        "code": "INF2004",
        "name": "Embedded Systems Programming",
        "trimesters": [
            {
                "trimester": 1,
                "year": 2
            }
        ]
    },
    {
        "id": "UDE2001",
        "code": "UDE2001",
        "name": "Interdisciplinary Design Innovation",
        "trimesters": [
            {
                "trimester": 1,
                "year": 2
            }
        ]
    },
    {
        "id": "ICT2112",
        "code": "ICT2112",
        "name": "Software Design",
        "trimesters": [
            {
                "trimester": 2,
                "year": 2
            }
        ]
    },
    {
        "id": "ICT2113",
        "code": "ICT2113",
        "name": "Software Modelling and Analysis",
        "trimesters": [
            {
                "trimester": 2,
                "year": 2
            }
        ]
    },
    {
        "id": "ICT2215",
        "code": "ICT2215",
        "name": "Mobile and Embedded Security",
        "trimesters": [
            {
                "trimester": 2,
                "year": 2
            }
        ]
    },
    {
        "id": "INF2007",
        "code": "INF2007",
        "name": "Mobile Application Development",
        "trimesters": [
            {
                "trimester": 2,
                "year": 2
            }
        ]
    },
    {
        "id": "ICT2114",
        "code": "ICT2114",
        "name": "Integrative Team Project",
        "trimesters": [
            {
                "trimester": 3,
                "year": 2
            }
        ]
    },
    {
        "id": "ICT2216",
        "code": "ICT2216",
        "name": "Secure Software Development",
        "trimesters": [
            {
                "trimester": 3,
                "year": 2
            }
        ]
    },
    {
        "id": "ICT3219",
        "code": "ICT3219",
        "name": "Industry Certification Module",
        "trimesters": [
            {
                "trimester": 3,
                "year": 2
            }
        ]
    },
    {
        "id": "INF2005",
        "code": "INF2005",
        "name": "Cyber Security Fundamentals",
        "trimesters": [
            {
                "trimester": 3,
                "year": 2
            }
        ]
    },
    {
        "id": "ICT3112",
        "code": "ICT3112",
        "name": "Software Verification and Validation",
        "trimesters": [
            {
                "trimester": 1,
                "year": 3
            }
        ]
    },
    {
        "id": "ICT3113",
        "code": "ICT3113",
        "name": "Performance Testing and Optimisation",
        "trimesters": [
            {
                "trimester": 1,
                "year": 3
            }
        ]
    },
    {
        "id": "INF2006",
        "code": "INF2006",
        "name": "Cloud Computing and Big Data",
        "trimesters": [
            {
                "trimester": 1,
                "year": 3
            }
        ]
    },
    {
        "id": "USI2001",
        "code": "USI2001",
        "name": "Social Innovation Project",
        "trimesters": [
            {
                "trimester": 1,
                "year": 3
            }
        ]
    },
    {
        "id": "ICT3217",
        "code": "ICT3217",
        "name": "Specialised R&D Project",
        "trimesters": [
            {
                "trimester": 2,
                "year": 3
            }
        ]
    },
    {
        "id": "INF2008",
        "code": "INF2008",
        "name": "Machine Learning",
        "trimesters": [
            {
                "trimester": 2,
                "year": 3
            }
        ]
    },
    {
        "id": "INF2009",
        "code": "INF2009",
        "name": "Edge Computing and Analytics",
        "trimesters": [
            {
                "trimester": 2,
                "year": 3
            }
        ]
    },
    {
        "id": "UCM3001",
        "code": "UCM3001",
        "name": "Change Management",
        "trimesters": [
            {
                "trimester": 2,
                "year": 3
            }
        ]
    },
    {
        "id": "ICT4011",
        "code": "ICT4011",
        "name": "Capstone Project",
        "trimesters": [
            {
                "trimester": 3,
                "year": 3
            },
            {
                "trimester": 1,
                "year": 4
            },
            {
                "trimester": 2,
                "year": 4
            }
        ]
    },
    {
        "id": "ICT4012B",
        "code": "ICT4012B",
        "name": "Integrated Work Study Programme (Work Attachment)",
        "trimesters": [
            {
                "trimester": 3,
                "year": 3
            },
            {
                "trimester": 1,
                "year": 4
            },
            {
                "trimester": 2,
                "year": 4
            }
        ]
    }
]