import React, {useState} from 'react';
import {Alert, Button, Form, Input} from "antd";
import "./Login.css"
import {useForm} from "antd/es/form/Form";
import {getData} from "../data/storage";
import {useNavigate} from "react-router-dom";

const Login = () => {

    const [form] = useForm();
    const navigate = useNavigate();
    const [error, setError] = useState(false);

    const handleLogin = () => {
        const username = form.getFieldValue("username");
        const password = form.getFieldValue("password");
        const user = getData("users").find(u => u.username === username && u.password === password);
        console.log(user);
        if (user === undefined) {
            setError(true);
            return;
        }
        sessionStorage.setItem("loggedInUser", user.id);
        switch (user.Experiment_Group) {
            case "A":
                navigate("/experiment-group-a");
                break;
            case "B":
                navigate("/experiment-group-b");
                break;
            case "C":
                navigate("/experiment-group-c");
                break;
            case "D":
                navigate("/experiment-group-d");
                break;
            default:
                navigate("/");
        }
        navigate("/");

    }

    return (
        <div className="login">
            <div className="login-form">
                <h1>Xamination Learning Platform</h1>
                <Form
                    labelCol={{span: 6}}
                    wrapperCol={{span: 16}}
                    style={{maxWidth: 600}}
                    form={form}
                >
                    <Form.Item
                        label="Username"
                        name="username"
                        rules={[{required: true, message: "Username is required"}]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[{required: true, message: "Password is required"}]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{offset: 6, span: 16}}
                        className={!error ? "error-hidden": ""}
                    >
                        <div className="login-error">Invalid username or password</div>
                    </Form.Item>
                    <Form.Item
                        className={"login-button"}
                    >
                        <Button type="primary" onClick={handleLogin}>
                            Login
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default Login;