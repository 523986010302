import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import { useParams } from "react-router-dom";
import { Button } from "antd";
import "./RevisionTopic2.css";
import AppLayout from "../components/AppLayout";
import {addItem, getData} from "../data/storage";
import { Alert } from 'antd';
import CustomRate from "../components/CustomRate";

const RevisionTopic2 = () => {
    const { revisionTopic } = useParams();

    const [flashcardToShow, setFlashcardToShow] = useState(getData("flashcard").filter(t => t.toLink === revisionTopic));
    const randomised = useRef(false);

    useEffect(() => {
        if (revisionTopic === "ap_biology" && !randomised.current) {
            randomised.current = true;
            const startIndex = Math.ceil(Math.random() * (flashcardToShow.length - 21))
            console.log(startIndex);
            const filteredFlashcardToShow = flashcardToShow.filter(f => f.id >= startIndex && f.id < startIndex + 20);
            setFlashcardToShow(filteredFlashcardToShow);

            const examination_id = "ap_biology_quiz_" + startIndex;

            // Create exam
            const examination_data = {
                title: "AP Biology Quiz " + startIndex,
                description: 'Date & Time: Wed, 27 Sep 2023, 1100 Hrs',
                id: examination_id,
                status: "new",
                experiment: true
            }

            // Create exam questions
            const exam_questions = filteredFlashcardToShow.map(flashcard => ({
                id: flashcard.id,
                question: flashcard.question,
                suggested_answer: flashcard.suggested_answer,
                examination_id: examination_id,
                type: "MCQ",
                option: flashcard.options
            }));

            addItem("exam_data", examination_data);
            exam_questions.forEach(exam_question => addItem("exam_questions", exam_question));
        }
    })

    const [currentCardIndex, setCurrentCardIndex] = useState(0);
    const [showAnswer, setShowAnswer] = useState(false);
    const [ratings, setRatings] = useState(Array(flashcardToShow.length).fill(null)); // Initialize ratings array with null
    const [doneClicked, setDoneClicked] = useState(false);
    const [showAlert, setShowAlert] = useState(false);


    const handleNextCard = () => {
        if (ratings[currentCardIndex] === null) {
            // setShowAlert(true);
            const nextIndex = (currentCardIndex + 1) % flashcardToShow.length;
            setShowAnswer(false);
            setCurrentCardIndex(nextIndex);
            setShowAlert(false);
        } else {
            // const nextIndex = (currentCardIndex + 1) % flashcardToShow.length;
            // setShowAnswer(false);
            // setCurrentCardIndex(nextIndex);
            // setShowAlert(false);
        }
    };


    // const handlePreviousCard = () => {
    //     setCurrentCardIndex((prevIndex) => (prevIndex - 1 + flashcardToShow.length) % flashcardToShow.length);
    //     setShowAnswer(false);
    // };

    const currentFlashcard = flashcardToShow[currentCardIndex];

    const handleRatingChange = (rating) => {
        const updatedRatings = [...ratings];
        updatedRatings[currentCardIndex] = rating;
        setRatings(updatedRatings);
    };

    const handleDoneClick = () => {
        if (ratings.includes(null)) {
            // setShowAlert(true);
            setDoneClicked(true);
            setShowAlert(false);
        } else {
            // setDoneClicked(true);
            // setShowAlert(false);
        }

    };

    const handleRetake = () => {
        setDoneClicked(false);
        setCurrentCardIndex(0);
        setShowAnswer(false);
        setRatings(Array(flashcardToShow.length).fill(null));
    };

    const calculateMasteryPercentage = () => {
        const totalRatings = ratings.reduce((acc, rating) => acc + (rating !== null ? rating : 0), 0);
        const numberOfRatedQuestions = ratings.filter((rating) => rating !== null).length;
        if (numberOfRatedQuestions === 0) {
            return 0; // No questions rated, set to 0%
        } else {
            const averageRating = totalRatings / numberOfRatedQuestions;
            return (averageRating / 5) * 100; // Assuming the rating scale is from 1 to 5
        }
    };

    const masteryPercentage = calculateMasteryPercentage().toFixed(1);

    return (
        <AppLayout menuPath="/revision">

            {showAlert && (
                <div className="alert-message">
                    <Alert
                        message="Please rate the flashcard before moving on."
                        type="warning"
                        showIcon
                        closable
                    />
                </div>

            )}
            <div>
                <div className="module-topic">
                    <h1>{currentFlashcard.topic}</h1>
                </div>

                <div className="flashcard-content">
                    <div className={`flashcard ${showAnswer ? 'flashcard-flip' : ''}`} onClick={() => setShowAnswer(!showAnswer)}>
                        <div className="front">
                            <h2>Question:</h2>
                            <div className="flashcard-question-text">
                                <p>{currentFlashcard.question}</p>
                            </div>
                            <div className="flashcard-reveal-prompt">
                                <p>Click to reveal</p>
                            </div>
                        </div>
                        <div className="back">
                            <h2>Question:</h2>
                            <div className="flashcard-question-text">
                                <p>{currentFlashcard.question}</p>
                            </div>
                            <h2>Answer:</h2>
                            <div className="flashcard-answer-text">
                                <p>{currentFlashcard.answer}</p>

                                {/*<div className="rate-question">*/}
                                {/*    <b>How well did you know this?</b>*/}
                                {/*</div>*/}
                                {/*<div className="number-scale" onClick={e => e.stopPropagation()}>*/}
                                {/*    <CustomRate*/}
                                {/*        defaultValue={2}*/}
                                {/*        value={ratings[currentCardIndex] || 0}*/}
                                {/*        onChange={(value) => handleRatingChange(value)}*/}
                                {/*        style={{color:'white', fontSize: '24px'}}*/}
                                {/*    />*/}
                                {/*</div>*/}
                            </div>
                            <div className="flashcard-reveal-prompt">
                                <p>Click to hide</p>
                            </div>
                        </div>
                    </div>
                </div>


                {/*<div className="flashcard-reveal">*/}
                {/*    <Button type="default" onClick={() => setShowAnswer(!showAnswer)}>*/}
                {/*        {showAnswer ? 'Hide Answer' : 'Reveal Answer'}*/}
                {/*    </Button>*/}
                {/*</div>*/}

                {showAnswer && !doneClicked && (
                    <div>
                        <div className="flashcard-navigation">
                            {currentCardIndex === flashcardToShow.length - 1 ? (
                                <Button type="primary" onClick={handleDoneClick}>
                                    Done
                                </Button>
                            ) : (
                                <Button onClick={handleNextCard} type="primary">
                                    Next
                                </Button>
                            )}
                        </div>
                    </div>
                )}

                {doneClicked && masteryPercentage !== null && (
                    <div className="mastery-percentage">
                        {/*<b>Mastery: {masteryPercentage}% </b>*/}
                        <div className="mastery-message">
                            <b>You have completed this topic's flashcards!</b>
                        </div>
                        <div className="retake">
                            <Button onClick={handleRetake} type="primary">
                                Retake
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </AppLayout>
    );
};

export default RevisionTopic2;
